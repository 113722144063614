<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="multiple-uploads-tooltip-modal-label">
                {{ $root.text('New feature for batch uploads', null, true) }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="onClose()"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <p>
                {{ $root.text(
                    'You can save time by tagging several files with the same common details first - '
                        + 'before adding unique details for each {resource}.',
                    { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                    true
                ) }}
            </p>
            <a href="#" class="btn btn-primary btn-sm" @click.prevent="resetSupportForm()">
                {{ $root.text('Ask for a quick demo', null, true) }}
            </a>
        </div>
        <div v-if="!do_not_show_again" class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="doNotShowAgain()">
                {{ $root.text('Do not show again', null, true) }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                do_not_show_again: false
            }
        },
        mounted () {
            const cookieName = this.$root.app.client_id + '_mutt' + this.$root.user.id;
            if (this.$cookie.get(cookieName)) {
                this.do_not_show_again = true;
            }
        },
        methods: {
            onClose: function () {
                this.$parent.resetModalBackdrop();
            },
            resetSupportForm() {
                $('#multiple-uploads-tooltip-modal').modal('hide');
                this.onClose();
                $('#asset-add-form-modal').modal('hide');
                const component = this.$root.findComponent(this.$parent, 'email-support');
                if (component) {
                    component.resetForm(
                        'upload',
                        this.$root.text('I would like a quick demo', null, true),
                        'asset-add-form'
                    );
                }
                //setTimeout(function () {
                    $('#email-support-modal').modal('show');
                //}, 500);
            },
            doNotShowAgain: function () {
                this.$root.setCookie(this.$root.app.client_id + '_mutt' + this.$root.user.id, 1, '1Y');
                this.onClose();
                var vm = this;
                setTimeout(function () {
                    vm.do_not_show_again = true;
                }, 200);
            }
        }
    }
</script>
