var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"template-archive-modal-label"}},[_vm._v("\n            "+_vm._s(_vm.$root.text(
                'Archive {template}',
                { template: _vm.$root.text(_vm.$root.contents.template_title, null, false, 'template_title') },
                true
            ))+"\n        ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body",style:((_vm.$root.hasPermission('templates_delete') ? 'padding-bottom: 200px' : ''))},[(_vm.sent)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col",domProps:{"innerHTML":_vm._s(_vm.sent)}})])]):_c('div',[_c('form',[(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),_c('p',[_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'Archiving the {template} removes it from view for all users.',
                        { template: _vm.$root.text(_vm.$root.contents.template_title, null, false, 'template_title') },
                        true
                    ))+" \n                    "+_vm._s(_vm.$root.text('Users previous versions will no longer be available.', null, true))+"\n                ")]),_vm._v(" "),_c('p',[_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'You can make this {template} live again by visiting the archive.',
                        { template: _vm.$root.text(_vm.$root.contents.template_title, null, false, 'template_title') },
                        true
                    ))+" \n                    "+_vm._s(_vm.$root.text('Users will then have access to their previous versions.', null, true))+"\n                ")]),_vm._v(" "),(_vm.$root.hasPermission('templates_delete'))?_c('div',{staticClass:"form-group"},[_c('searchable-option-list',{key:'auto-delete-period-' + (_vm.$root.current_language || 0),attrs:{"value":_vm.auto_delete_period,"options":_vm.getAutoDeleteOptions(),"field_id":"auto-delete-period","readonly":_vm.sending},model:{value:(_vm.auto_delete_period),callback:function ($$v) {_vm.auto_delete_period=$$v},expression:"auto_delete_period"}})],1):_vm._e()])])]),_vm._v(" "),(!_vm.sent)?_c('div',{staticClass:"modal-footer"},[(!_vm.sending)?_c('span',[_c('span',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSubmit()}}},[_c('i',{staticClass:"far fa-archive mr-1"}),_vm._v(" "+_vm._s(_vm.$root.text('Archive now', null, true))+"\n                ")])])]):_c('span',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fal fa-spinner-third fa-spin mr-1"}),_vm._v(" "),_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$root.text('Archive now', null, true)))])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-light ml-2",attrs:{"type":"button","data-dismiss":"modal","disabled":_vm.sending}},[_c('i',{staticClass:"far fa-times mr-1"}),_vm._v(" "+_vm._s(_vm.$root.text('Cancel', null, true))+"\n        ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1",staticStyle:{"max-width":"30px"}},[_c('i',{staticClass:"far fa-check"})])
}]

export { render, staticRenderFns }