<template>
    <div :class="assetPreviewClass()">
        <div
            v-if="canViewAsset()"
            class="thumbnail"
            style="cursor: pointer"
            @click="onThumbnailClick()"
        >
            <img :src="image_src" :alt="title">
            <div v-if="!is_visible" class="preview-loading">
                <loading size="1"></loading>
            </div>
            <div v-if="canSelect()" :key="'select-overlay'" class="asset-select-overlay">
                <div class="triangle"></div>
                <div
                    class="tick"
                    @click="(isCollection() || !collection) ? selectAsset() : addToCollection()"
                    v-b-tooltip.hover
                    :title="$root.text((isCollection() || !collection) ? 'Select' : 'Collect', null, true)"
                >
                    <i class="fa-regular fa-check"></i>
                </div>
                <div class="ghost"></div>
            </div>
            <div v-if="canDeselect()" :key="'deselect-overlay'" class="asset-select-overlay deselect">
                <div class="triangle"></div>
                <div
                    class="tick"
                    @click="(isCollection() || !collection) ? selectAsset(false) : removeFromCollection()"
                    v-b-tooltip.hover
                    :title="$root.text((isCollection() || !collection) ? 'Deselect' : 'Remove', null, true)"
                >
                    <span :key="'check-icon'" class="check-icon">
                        <i class="fa-regular fa-check"></i>
                    </span>
                    <span :key="'minus-icon'" class="minus-icon">
                        <i class="fa-regular fa-minus"></i>
                    </span>
                </div>
                <div class="ghost"></div>
            </div>
        </div>
        <div v-else class="thumbnail">
            <img :src="image_src" :alt="title">
            <div v-if="!is_visible" class="preview-loading">
                <loading size="1"></loading>
            </div>
        </div>

        <div class="card-body" style="position: relative">
            <div
                v-if="canViewAsset()"
                class="title mb-2"
                :id="prefix + 'asset-title' + id"
                @click="viewAsset()"
                style="cursor: pointer"
            >
                {{ short_title }}
            </div>
            <div v-else class="title mb-2" :id="prefix + 'asset-title' + id">
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="prefix + 'asset-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <div>
                <span
                    :class="(!downloadOnlyAccess() ? 'asset-info' : 'asset-info stay')"
                    :id="prefix + 'asset-info' + id"
                    :style="assetInfoStyle()"
                >
                    <span v-if="$root.isLoggedIn()" class="float-right" style="position: relative; top: 2px">
                        {{ id }}
                    </span>
                    <span
                        v-if="data.suspended"
                        class="badge badge-danger font-size-11"
                        style="text-transform: uppercase"
                    >{{ $root.text('SUSPENDED') }}</span>
                    <span v-else>
                        <span
                            v-if="isTemplate()"
                            class="badge bg-secondary text-secondary font-size-11"
                            style="text-transform: uppercase"
                        >{{ $root.text('VARIABLE') }}</span>
                        <span v-if="!isTemplate() && data">
                            <span
                                v-if="extension && (!data.print_order || data.downloadable)"
                                :class="'badge badge-primary font-size-11'
                                    + (data.archived ? ' d-lg-inline-block d-none' : '')"
                                style="text-transform: uppercase"
                            >{{ extension }}</span>
                            <span v-if="data">
                                <span
                                    v-if="data.print_order"
                                    class="badge bg-secondary text-secondary font-size-11"
                                    style="text-transform: uppercase"
                                >
                                    {{ $root.text(data.downloadable ? 'ACTIONS' : 'ORDER ITEM') }}
                                </span>
                            </span>
                        </span>
                    </span>
                </span>
            </div>

            <div v-if="!downloadOnlyAccess()" class="font-size-22 asset-menu" :id="prefix + 'asset-menu' + id">
                <span class="float-right">
                    <span v-if="!asset_menu_mobile_full" class="asset-tools-mobile-toggle">
                        <a
                            href="#"
                            @click.prevent="toggleAssetTools()"
                            :style="selected ? 'position: relative; top: -3px' : ''"
                        >
                            <span v-b-tooltip.hover title="Actions">
                                <i class="far fa-ellipsis-h"></i>
                            </span>
                        </a>
                    </span>
                    <span v-if="restricted" class="asset-restricted ml-1">
                        <i class="fal fa-lock-alt"></i>
                    </span>
                </span>

                <span class="asset-tools">
                    <span v-if="data.suspended">
                        <span
                            class="font-size-12 text-secondary mr-1"
                            style="position: relative; top: -4px; text-transform: uppercase"
                        >{{ $root.text('SUSPENDED') }}</span>
                    </span>
                    <span
                        v-if="(isTemplate() && !data.suspended && !data.archived
                            && $root.hasPermission('templates_use'))"
                        :key="'customise'"
                    >
                        <a
                            v-if="!showing_template_options"
                            href="#"
                            class="asset-tool mr-1"
                            @click.prevent="showTemplateOptions()"
                        >
                            <span>
                                <span
                                    class="font-size-12"
                                    style="position: relative; top: -4px; text-transform: uppercase"
                                >{{ $root.text('CUSTOMISE') }}</span>
                            </span>
                        </a>
                        <span v-else class="asset-tool mr-1">
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </span>
                    </span>
                    <span v-if="canViewPricePanel()" :key="'view-price-panel'">
                        <a
                            href="#"
                            class="asset-tool mr-1"
                            data-toggle="modal"
                            data-target="#price-panel-view-modal"
                            @click.prevent="resetViewPricePanel()"
                        >
                            <span v-b-tooltip.hover :title="$root.text('View prices', null, true)">
                                <i v-if="($root.user.currency == 'pound')" class="fal fa-sterling-sign"></i>
                                <i v-if="($root.user.currency == 'euro')" class="fal fa-euro-sign"></i>
                                <i v-if="($root.user.currency == 'dollar')" class="fal fa-dollar-sign"></i>
                            </span>
                        </a>
                    </span>
                    <span v-if="canOrderPrint()" :key="'order-print'">
                        <a
                            v-if="!ordering"
                            href="#"
                            class="asset-tool mr-1"
                            @click.prevent="orderPrint()"
                        >
                            <span v-b-tooltip.hover :title="$root.text('Order item', null, true)">
                                <i class="fal fa-shopping-cart"></i>
                            </span>
                        </a>
                        <span v-else class="asset-tool mr-1">
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </span>
                    </span>
                    <span v-if="canDownload()" :key="'download'">
                        <a
                            v-if="!downloading"
                            href="#"
                            class="asset-tool mr-1"
                            @click.prevent="initDownload()"
                        >
                            <span v-b-tooltip.hover :title="$root.text('Download', null, true)">
                                <i class="fal fa-download"></i>
                            </span>
                        </a>
                        <span v-else class="asset-tool mr-1">
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </span>
                    </span>
                    <a
                        v-if="canShare()"
                        :key="'share'"
                        href="#"
                        class="asset-tool mr-1"
                        data-toggle="modal"
                        data-target="#asset-share-modal"
                        @click.prevent="shareAsset()"
                    >
                        <span v-b-tooltip.hover :title="$root.text('Share', null, true)">
                            <i class="fal fa-share-alt"></i>
                        </span>
                    </a>
                    <a
                        v-if="canEditImage()"
                        :key="'edit-image'"
                        href="#"
                        class="asset-tool mr-1"
                        @click.prevent="viewAsset('edit-image')"
                    >
                        <span v-b-tooltip.hover :title="$root.text('Edit image', null, true)">
                            <i class="fal fa-crop-alt"></i>
                        </span>
                    </a>
                    <span
                        v-if="isCollection() && isCollaborator()
                            && (!isTemplate() || $root.hasPermission('templates_view'))"
                    >
                        <a
                            v-if="!removing_from_collection"
                            href="#"
                            class="asset-tool"
                            @click.prevent="removeFromCollection()"
                        >
                            <span
                                v-b-tooltip.hover
                                :title="$root.text('Remove from collection', null, true)"
                                style="position: relative"
                            >
                                <i class="fa-light fa-rectangle-history"></i>
                                <span :id="prefix + '-rfc-minus-' + id">
                                    <i
                                        class="far fa-minus"
                                        style="position: absolute; left: 6px; bottom: 8px; font-size: 11px"
                                    ></i>
                                </span>
                            </span>
                        </a>
                        <span v-else class="asset-tool">
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </span>
                    </span>
                    <span v-if="asset_menu_mobile_full" class="asset-tools-mobile-toggle ml-1">
                        <a
                            href="#"
                            @click.prevent="toggleAssetTools()"
                        >
                            <span class="font-size-16" v-b-tooltip.hover :title="$root.text('Hide actions', null, true)">
                                <i class="fal fa-times"></i>
                            </span>
                        </a>
                    </span>

                    <span class="float-right">
                        <span
                            v-if="$root.isLoggedIn()"
                            class="asset-tool font-size-13"
                            style="position: relative; top: -2px"
                        >
                            {{ id }}
                        </span>
                        <span
                            v-if="restricted"
                            class="asset-tool ml-2"
                            v-b-tooltip.hover
                            :title="$root.text('Restricted', null, true)"
                        >
                            <i class="fal fa-lock-alt"></i>
                        </span>
                    </span>
                </span>
            </div>

            <div
                v-if="(downloadOnlyAccess() && canDownload(true))"
                class="font-size-22 contact-links"
                style="position: absolute; right: 15px; bottom: 6px"
            >
                <a
                    v-if="!downloading"
                    href="#"
                    @click.prevent="initDownload()"
                >
                    <span v-b-tooltip.hover :title="$root.text('Download', null, true)">
                        <i class="fal fa-download"></i>
                    </span>
                </a>
                <span v-else>
                    <i class="fal fa-spinner-third fa-spin"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'selected', 'collections', 'collection', 'in_selection', 'is_visible', 'in_share', 'email'],
        data () {
            return {
                id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                extension: null,
                file_type: null,
                archived: false,
                restricted: false,
                prefix: '',
                asset_menu_mobile_full: false,
                adding_to_collection: false,
                add_to_collection_errors: false,
                added_to_collection: null,
                removing_from_collection: false,
                downloading: false,
                showing_template_options: false,
                ordering: false,
                order_item: null,
                block_thumbnail_click: false
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                if (!vm.in_selection || vm.is_visible) {
                    vm.short_title = vm.title;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            });

            $(document).on('click', '.asset-preview-action-dropdown', function (e) {
                e.stopPropagation();
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            },
            is_visible: function (val) {
                if (val) {
                    if (this.image_src == '/images/file-icon.png') {
                        this.getPreviewImage();
                    }
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            },
            block_thumbnail_click: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.block_thumbnail_click = false;
                    }, 200);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.id;
                    this.title = data.title;
                    this.short_title = data.title;
                    this.extension = data.file_extension;
                    this.file_type = data.file_type;
                    this.archived = data.archived;
                    this.restricted = data.restricted;
                    this.prefix = this.in_selection ? 'selection-' : '';
                    if (this.is_visible && (this.image_src == '/images/file-icon.png')) {
                        this.getPreviewImage();
                    }
                    var vm = this;
                    if (!this.in_selection || this.is_visible) {
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 500);
                    }
                }
            },
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                let size = (this.extension == 'svg') ? 'svg' : 'lowres';
                if (this.file_type == 'image/gif') {
                    size = 'highres';
                }
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.id + '/preview/' + size;
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/0/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: (!this.$root.isLoggedIn() && this.in_share)
                        ? null : { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#' + this.prefix + 'asset-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#' + this.prefix + 'asset-title' + this.id)[0].scrollHeight > 20) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            viewAsset: function (tab = null) {
                if (!tab) {
                    if (this.isTemplate()) {
                        tab = this.$root.client.template_details_default_tab;
                        if ((tab == 'template-options') && !this.$root.hasPermission('templates_use')) {
                            tab = 'info';
                        }
                    } else {
                        tab = this.$root.client.asset_details_default_tab;
                        if ((tab == 'download') && !this.canDownload(this.in_share)) {
                            tab = 'info';
                        }
                    }
                }
                if (!this.in_selection) {
                    this.$parent.view_asset_from_selection = false;
                }
                this.$parent.view_asset_tab = tab;
                this.$parent.view_asset = this.data;
            },
            showTemplateOptions: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'template-options');
                    if (component) {
                        this.showing_template_options = true;
                        component.reset(this.data, null, null, this);
                    }
                }
            },
            selectAsset: function (select = true) {
                this.block_thumbnail_click = true;
                if (select) {
                    if (this.isCollection()) {
                        this.$root.collection_selected_assets.push(this.id);

                        return null;
                    }
                    if (this.collection) {
                        this.$root.current_collection = null;
                        this.$root.selected_assets = [this.id];

                        return null;
                    }
                    this.$root.selected_assets.push(this.id);

                    return null;
                }

                let selectedAssets = this.isCollection() ? this.$root.collection_selected_assets
                    : this.$root.selected_assets;
                
                const itemIndex = selectedAssets.indexOf(this.id);
                if (itemIndex > -1) {
                    selectedAssets.splice(itemIndex, 1);
                }
            },
            addToCollection: function () {
                this.block_thumbnail_click = true;
                if (this.collection) {
                    this.$root.selected_assets.push(this.id);

                    return null;
                }
                const assetSelectionComponent = this.in_selection ? this.$parent
                    : this.$root.findComponent(this.$parent, 'asset-selection');
                if (!assetSelectionComponent) {
                    return null;
                }
                const component = this.$root.findComponent(assetSelectionComponent, 'add-to-collection');
                if (component) {
                    component.reset([this.id]);
                    $('#add-to-collection-modal').modal('show');
                }
            },
            removeFromCollection: function () {
                this.block_thumbnail_click = true;
                if (this.isCollection()) {
                    this.removing_from_collection = true;
                    const assets = [this.id];
                    this.$parent.removeAssetsFromCollection(assets, true);

                    return null;
                }
                this.selectAsset(false);
            },
            initDownload: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'asset-download');
                    if (component) {
                        this.downloading = true;
                        component.initData(
                            this,
                            ((!this.$root.isLoggedIn() && this.in_share) ? this.in_share : null),
                            ((!this.$root.isLoggedIn() && this.email) ? this.email : null)
                        );
                    }
                }
            },
            shareAsset: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'asset-share');
                    if (component) {
                        component.reset(this.data);
                    }
                }
            },
            toggleAssetTools: function () {
                this.asset_menu_mobile_full = !this.asset_menu_mobile_full;
                if (this.asset_menu_mobile_full) {
                    $('#' + this.prefix + 'asset-info' + this.id).addClass('hide');
                    $('#' + this.prefix + 'asset-menu' + this.id).addClass('full');

                    return null;
                }
                $('#' + this.prefix + 'asset-info' + this.id).removeClass('hide');
                $('#' + this.prefix + 'asset-menu' + this.id).removeClass('full');
            },
            isCollection: function () {
                return (this.$route.name == 'view-collection');
            },
            canEditImage: function () {
                if (!this.data) {
                    return false;
                }
                if (this.archived || this.data.suspended) {
                    return false;
                }
                if (this.isTemplate()) {
                    return false;
                }
                if (!this.$root.hasPermission('assets_edit_image')) {
                    return false;
                }
                const editable = [
                    'image/jpeg',
                    'image/pjpeg',
                    'image/png',
                    'image/heif',
                    'image/heic',
                    'image/DNG',
                    'image/dng',
                    'image/tiff'
                ];

                return (editable.indexOf(this.file_type) > -1);
            },
            assetPreviewClass: function () {
                let className = 'card asset-preview';
                if (this.in_selection) {
                    className += ' in-selection';
                }
                if (this.isCollection() || this.collection) {
                    className += ' in-collection';
                }
                if (this.canSelect()) {
                    className += ' selectable'
                }
                if (this.selected) {
                    className += ' selected';
                }
                if (this.asset_menu_mobile_full) {
                    className += ' highlighted'
                }

                return className;
            },
            isTemplate: function () {
                if (!this.data) {
                    return false;
                }

                return this.data.template;
            },
            hasTemplateAdminPermission: function () {
                if (!this.data) {
                    return false;
                }
                if (!this.data.template) {
                    return false;
                }
                if (!this.$root.hasPermission('templates_view')) {
                    return false;
                }
                if (this.$root.hasPermission('templates_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_replace')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_manage_suspension')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_manage_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_delete')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_download_original')) {
                    return true;
                }

                return false;
            },
            canViewAsset: function () {
                if (this.in_share) {
                    if (this.isTemplate() && this.$root.isLoggedIn()) {
                        return this.$root.hasPermission('templates_view');
                    }

                    return true;
                }
                if (!this.isTemplate()) {
                    return this.$root.hasPermission('assets_view');
                }

                return this.$root.hasPermission('templates_view');
            },
            onThumbnailClick: function () {
                if (this.block_thumbnail_click) {
                    return null;
                }
                if (!this.isTemplate()) {
                    this.viewAsset();

                    return null;
                }
                if (this.hasTemplateAdminPermission()) {
                    this.viewAsset(/*'admin'*/);

                    return null;
                }
                if (!this.$root.hasPermission('templates_use')) {
                    if (this.canViewAsset()) {
                        this.viewAsset();
                    }

                    return null;
                }
                if (!this.showing_template_options) {
                    this.showTemplateOptions();
                }
            },
            canViewPricePanel: function () {
                if (!this.$root.hasPermission('assets_view') && !this.$root.hasPermission('templates_use')) {
                    return false;
                }
                if (!this.data) {
                    return false;
                }
                if (this.archived) {
                    return false;
                }
                if (!this.$root.user) {
                    return false;
                }
                if (!this.$root.user.currency) {
                    return false;
                }
                if (!this.$root.isFeatured('printing')) {
                    return false;
                }
                if (!this.data.price_panels) {
                    return false;
                }

                return (this.data.price_panels.length > 0);
            },
            resetViewPricePanel: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'price-panel-view');
                if (component) {
                    component.reset(this.data.price_panels);
                }
            },
            canOrderPrint: function () {
                if (!this.$root.hasPermission('assets_view')) {
                    return false;
                }
                if (!this.data) {
                    return false;
                }
                if (this.archived || this.data.suspended) {
                    return false;
                }
                if (this.isTemplate()) {
                    return false;
                }
                if (!this.$root.isFeatured('printing')) {
                    return false;
                }

                return this.data.print_order;
            },
            orderPrint: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'print-order-form');
                if (component) {
                    component.resetForm(this.data);
                    $('#print-order-modal').modal('show');
                }
            },
            canDownload: function (inShare = false) {
                if (!inShare && !this.$root.hasPermission('assets_download')) {
                    return false;
                }
                if (inShare) {
                    if (this.$root.share_info.external_view_only && this.downloadOnlyAccess()) {
                        return false;
                    }
                }
                if (!this.data) {
                    return false;
                }
                if (this.data.suspended || this.isTemplate()) {
                    return false;
                }

                return (this.data.downloadable || this.data.web_image);
            },
            canShare: function () {
                if (!this.$root.hasPermission('assets_send')) {
                    return false;
                }
                if (!this.data) {
                    return false;
                }
                if (this.archived) {
                    return false;
                }
                if (this.isTemplate() && !this.$root.hasPermission('templates_view')) {
                    return false;
                }

                return true;
            },
            assetInfoStyle: function () {
                let paddingRight = 0;
                if (this.downloadOnlyAccess() && this.canDownload(true)) {
                    paddingRight += 30;
                }
                if (!this.downloadOnlyAccess()) {
                    if (!this.asset_menu_mobile_full && !this.$root.is_lg_screen) {
                        paddingRight += 30;
                    }
                    if (this.restricted) {
                        paddingRight += 30;
                    }
                    /*if (paddingRight) {
                        paddingRight += 5;
                    }*/
                }

                return 'display: inline-block; width: calc(100% - ' + paddingRight + 'px)';
            },
            isCollaborator: function () {
                if (!this.isCollection()) {
                    return false;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'view-collection');
                if (!component) {
                    return false;
                }
                if (component.collaborators.length == 0) {
                    return false;
                }
                let found = false;
                component.collaborators.forEach((item) => {
                    if (item.user_id == this.$root.user.id) {
                        found = true;
                    }
                });

                return found;
            },
            downloadOnlyAccess: function () {
                if (!this.$root.isLoggedIn() || !this.data) {
                    return true;
                }
                if (
                    this.data.template
                    && !this.$root.hasPermission('templates_view')
                    && !this.$root.hasPermission('templates_use')
                ) {
                    return true;
                }

                return this.data.download_only ? true : false;
            },
            canSelect: function () {
                if (this.archived || this.in_share || this.selected) {
                    return false;
                }
                if (this.isTemplate() && !this.$root.hasPermission('templates_view')) {
                    return false;
                }

                return true;
            },
            canDeselect: function () {
                if (this.archived || !this.selected) {
                    return false;
                }
                if (this.isTemplate() && !this.$root.hasPermission('templates_view')) {
                    return false;
                }

                return true;
            }
        }
    }
</script>
<style scoped>
    .asset-preview {
        border: 2px solid #e4e4e4;
        border-radius: 0.35rem;
    }

    .asset-preview.in-selection {
        border-color: #ced4da;
    }

    .asset-preview .thumbnail {
        padding: 0;
        height: 198px;
    }

    .asset-preview .thumbnail img {
        max-width: 100%;
    }

    .asset-preview.in-selection .thumbnail {
        height: 128px;
    }

    .asset-preview.in-selection .thumbnail img {
        max-height: 128px;
    }

    .asset-preview:hover .card-body .asset-info.stay {
        opacity: 1;
    }

    .preview-loading {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 2;
        height: 20px;
    }
</style>
