<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-share-modal-label">{{ $root.text('Email proof', null, true) }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-on:submit.prevent="onSubmit()">
                    <div class="form-group dropdown">
                        <label id="proof-share-recipients-label" class="mandatory-label">
                            {{ $root.text('Recipient email(s)', null, true) }} *
                        </label>
                        <div
                            class="form-control mandatory-field pb-0"
                            id="proof-share-recipients"
                            @click="focusRecipientKeyword()"
                            style="height: auto; min-height: 38px"
                        >
                            <span
                                v-for="item in recipients"
                                :key="item.email"
                                class="badge badge-soft-secondary contact-links font-size-12 mr-2 mb-2"
                                v-b-tooltip.hover
                                title=""
                            >
                                <span v-if="!item.name">{{ item.email }}</span>
                                <span v-else v-b-tooltip.hover :title="item.email">{{ item.name }}</span>
                                <a
                                    v-if="!sending"
                                    href="#"
                                    v-b-tooltip.hover
                                    :title="$root.text('Remove', null, true)"
                                    @click.prevent="removeRecipient(item)"
                                >
                                    <i class="far fa-times"></i>
                                </a>
                                <span v-if="sending">
                                    <i class="far fa-times"></i>
                                </span>
                            </span>

                            <div style="display: inline-block; position: relative">
                                <input
                                    v-model="recipient_keyword"
                                    type="text"
                                    id="proof-share-recipient-keyword"
                                    class="form-control px-1 mb-2"
                                    v-on:focus="searchRecipient(true)"
                                    v-on:blur="focusRecipientKeyword(false)"
                                    style="width: 280px; max-width: calc(100% - 40px)"
                                >
                                <button
                                    v-if="recipient_keyword"
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    v-b-tooltip.hover
                                    :title="$root.text('Add recipient', null, true)"
                                    @click="addRecipient()"
                                    :disabled="sending"
                                    style="padding-top: 1px; padding-bottom: 1px; position: absolute; top: 0"
                                >
                                    <i class="far fa-plus"></i>
                                </button>
                            </div>
                        </div>

                        <div
                            class="dropdown-menu proof-share-recipient-dropdown"
                            style="width: 280px; max-height: 230px; overflow-y: auto"
                        >
                            <div v-for="item in recipient_results" :key="item.id">
                                <a
                                    class="dropdown-item"
                                    href="#"
                                    @click.prevent="addRecipient(item)"
                                >
                                    {{ item.first_name }} {{ item.last_name }}
                                    <p class="small mb-1">{{ item.email }}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="proof-share-message">{{ $root.text('Message', null, true) }}</label>
                        <textarea
                            v-model="message"
                            id="proof-share-message"
                            class="form-control"
                            :readonly="sending"
                            style="height: 190px"
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>

        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <button :key="'send-button'" type="button" class="btn btn-primary" @click="onSubmit()">
                    <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Send', null, true) }}
                </button>
            </span>
            <span v-else>
                <button :key="'send-button-spinning'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Send', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof'],
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                recipients: [],
                message: null,
                recipient_keyword: '',
                block_recipient_keyword_auto_process: false,
                recipient_results: [],
                keypress_time: 0
            }
        },
        mounted () {
            //
        },
        watch: {
            errors: function (val) {
                if (!val) {
                    $('.mandatory-label').removeClass('text-danger');
                    $('.mandatory-field').removeClass('field-error');
                }
            },
            recipient_keyword: function (val) {
                this.$root.unHighlightErrors('proof-share-recipients');
                if (val) {
                    this.searchRecipient(true);

                    return null;
                }
                $('.proof-share-recipient-dropdown').dropdown('hide');
            }
        },
        methods: {
            reset: function () {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.message = null;
                this.recipients = [];
                this.recipient_keyword = '';
                this.block_recipient_keyword_auto_process = false;
            },
            getRecipientEmails: function () {
                let recipients = [];
                this.recipients.forEach((item, index) => {
                    recipients.push({ id: index, email: item.email });
                });
                if (this.recipient_keyword) {
                    recipients.push({ id: 'last', email: this.recipient_keyword });
                }

                return recipients;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                let data = {
                    type: 'email',
                    subject: this.$root.text(
                        'Proof from {user_name}',
                        { user_name :this.$root.user.first_name + ' ' + this.$root.user.last_name },
                        true
                    ),
                    proof_id: this.proof.id,
                    recipients: this.recipients,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                if (this.recipient_keyword) {
                    data.recipients.push({email: this.recipient_keyword});
                }
                if (this.message) {
                    data.message = this.message;
                }

                /**
                 * Send share proof request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/share';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = vm.$root.text('The proof has been sent.', null, true);

                    // Close after 2 seconds.
                    setTimeout(function () {
                        $('#proof-share-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#proof-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // Show error.
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#proof-share-' + key + '-label').addClass('text-danger');
                                $('#proof-share-' + key).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            focusRecipientKeyword: function (focus = true, delayed = false) {
                if (focus) {
                    $('#proof-share-recipient-keyword').css('display', 'inline');
                    $('#proof-share-recipient-keyword').focus();
                    var vm = this;
                    setTimeout(function () {
                        vm.block_recipient_keyword_auto_process = false;
                    }, 500);

                    return null;
                }

                if (!delayed) {
                    var vm = this;
                    setTimeout(function () {
                        vm.focusRecipientKeyword(false, true);
                    }, 500);

                    return null;
                }
                if (this.block_recipient_keyword_auto_process) {
                    return null;
                }
                if (this.recipient_keyword) {
                    this.addRecipient(null, false);
                }
                $('#proof-share-recipient-keyword').css('display', 'none');
                $('.proof-share-recipient-dropdown').dropdown('hide');
            },
            searchRecipient: function (delay = false) {
                if (!this.recipient_keyword) {
                    return null;
                }
                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.searchRecipient();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|!invited|kw::'
                    + btoa(this.recipient_keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.recipient_results = [];
                    response.data.users.forEach((item) => {
                        if ((item.email != vm.$root.user.email) && !vm.isRecipientAdded(item.email)) {
                            vm.recipient_results.push(item);
                        }
                    });
                    if (vm.recipient_results.length > 0) {
                        setTimeout(function () {
                            $('.proof-share-recipient-dropdown').dropdown('show');
                        }, 200);

                        return null;
                    }
                    $('.proof-share-recipient-dropdown').dropdown('hide');
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#proof-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                });
            },
            isRecipientAdded: function (email) {
                if (!email || (this.recipients.length == 0)) {
                    return false;
                }
                let found = false;
                this.recipients.forEach((item) => {
                    if (item.email.toLowerCase() == email.toLowerCase()) {
                        found = true;
                    }
                });

                return found;
            },
            addRecipient: function (user = null, addNew = true) {
                if (!user && !this.recipient_keyword) {
                    return null;
                }
                const email = user ? user.email.trim() : this.recipient_keyword.trim();

                this.recipient_keyword = '';
                this.recipient_results = [];
                setTimeout(function () {
                    $('.proof-share-recipient-dropdown').dropdown('hide');
                }, 250);

                if ((email.length > 0) && !this.isRecipientAdded(email)) {
                    const recipient = {
                        email: email,
                        name: user ? user.first_name + ' ' + user.last_name : null,
                        user_id : user ? user.id : null
                    };
                    this.recipients.push(recipient);
                }

                if (addNew) {
                    this.block_recipient_keyword_auto_process = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.focusRecipientKeyword();
                    }, 200);
                }
            },
            removeRecipient: function (recipient) {
                this.$root.unHighlightErrors('proof-share-recipients');
                this.block_recipient_keyword_auto_process = true;
                let itemIndex = -1;
                this.recipients.forEach((item, index) => {
                    if (item == recipient) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.recipients.splice(itemIndex, 1);
                }
            }
        }
    }
</script>
<style scoped>
    #proof-share-recipient-keyword {
        display: none;
        width: auto;
        height: 20px;
        border: none;
    }

    #proof-share-recipient-keyword:focus {
        background-color: #f8f9fa;
    }
</style>
