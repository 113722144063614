<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            {{ $root.text('There are no POD codes that match your selection', null, true) }}
        </div>

        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-3">{{ $root.text('POD code', null, true) }}</div>
                                        <div class="col-8">{{ $root.text('Title', null, true) }}</div>
                                        <div class="col-1">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'pod-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-3" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditPod(item.id)">
                                                    {{ $root.text('POD {x}', { x: item.id }, true) }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-8" style="padding-top: 3px">
                                            {{ item.title }}
                                        </div>
                                        <div class="col-1 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditPod(item.id)"
                                            >
                                                <span
                                                    v-if="(pod_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'pod-details' + item.id">
                                            <pod-code-edit-form :data="item"></pod-code-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="pod-code-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="pod-code-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <pod-code-add-form></pod-code-add-form>
            </div>
        </div>

        <pod-code-filters></pod-code-filters>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'pod-codes',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                pod_details_open: []
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getPodCodes();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        methods: {
            getPodCodes: function (page = 1) {
                if (!this.$root.hasPermission('podcodes_access')) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;

                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.pod_details_open = [];
                }

                let filters = [];
                this.$root.pod_code_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                });
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/pod-codes' + filtersString
                    + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.pod_codes.total;
                    vm.current_page = response.data.pod_codes.current_page;
                    vm.last_page = response.data.pod_codes.last_page;
                    response.data.pod_codes.data.forEach((item) => {
                        vm.items.push(item);
                    });

                    if (vm.current_page == 1) {
                        vm.$root.status_info.right = vm.total_items + ' '
                            + vm.$root.text((vm.total_items == 1) ? 'POD code' : 'POD codes', null, true);
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            toggleEditPod: function (id) {
                if (this.pod_details_open.indexOf(id) < 0) {
                    this.pod_details_open.push(id);
                    $('#pod-row' + id).addClass('highlighted');
                    $('#pod-details' + id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#pod-details' + id).slideUp('slow', function () {
                        $('#pod-row' + id).removeClass('highlighted');
                        for (var n = 0; n < vm.pod_details_open.length; n++) { 
                            if (vm.pod_details_open[n] == id) { 
                                vm.pod_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getPodCodes(this.current_page + 1);
                    }
                }
            }
        }
    }
</script>
