<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6>
                                <span v-if="($route.name == 'folders-archived')">
                                    {{ $root.text(
                                        'Archived {folders} and {subfolders}',
                                        {
                                            folders: $root.text(
                                                $root.contents.folders_title,
                                                null,
                                                false,
                                                'folders_title'
                                            ),
                                            subfolders: $root.text(
                                                $root.contents.subfolders_title,
                                                null,
                                                false,
                                                'subfolders_title'
                                            )
                                        },
                                        true
                                    ) }}
                                </span>
                                <span v-else>
                                    {{ $root.text(
                                        'Live {folders} and {subfolders}',
                                        {
                                            folders: $root.text(
                                                $root.contents.folders_title,
                                                null,
                                                false,
                                                'folders_title'
                                            ),
                                            subfolders: $root.text(
                                                $root.contents.subfolders_title,
                                                null,
                                                false,
                                                'subfolders_title'
                                            )
                                        },
                                        true
                                    ) }}
                                </span>
                            </h6>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <!--<li class="nav-item d-none d-md-inline">
                            <breadcrumbs :items="breadcrumbs()"></breadcrumbs>
                        </li>-->
                        <li class="nav-item" style="width: 100px; height: 36.5px">
                            <div class="btn-group" style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {{ $root.text('Actions', null, true) }}
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#folder-add-form-modal"
                                        @click.prevent="resetFolderAddForm()"
                                    >
                                        <i class="far fa-folder-plus mr-1"></i>
                                        {{ $root.text(
                                            'Add {folder}',
                                            {
                                                folder: $root.text(
                                                    $root.contents.folder_title,
                                                    null,
                                                    false,
                                                    'folder_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </a>
                                    <router-link
                                        v-if="($route.name == 'folders')"
                                        class="dropdown-item"
                                        :to="{ name: 'folders-archived' }"
                                    >
                                        <i class="far fa-archive mr-1"></i>
                                        {{ $root.text(
                                            'View archived {folders}',
                                            {
                                                folders: $root.text(
                                                    $root.contents.folders_title,
                                                    null,
                                                    false,
                                                    'folders_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </router-link>
                                    <span v-if="($route.name == 'folders-archived')">
                                        <router-link
                                            class="dropdown-item"
                                            :to="{ name: 'folders' }"
                                        >
                                            <i class="far fa-folders mr-1"></i>
                                            {{ $root.text(
                                                'View live {folders}',
                                                {
                                                    folders: $root.text(
                                                        $root.contents.folders_title,
                                                        null,
                                                        false,
                                                        'folders_title'
                                                    )
                                                },
                                                true
                                            ) }}
                                        </router-link>
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        methods: {
            resetFolderAddForm: function () {
                const component = this.$root.findComponent(this.$parent.$parent, 'folder-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            /*breadcrumbs: function() {
                if (this.$route.name == 'folders-archived') {
                    return [
                        {
                            id: 0,
                            text: "Settings",
                            link: 'settings'
                        },
                        {
                            id: 1,
                            text: "Folders and subfolders",
                            link: 'folders'
                        },
                        {
                            id: 2,
                            text: "Archived",
                            active: true
                        }
                    ];
                }
                
                return [
                    {
                        id: 0,
                        text: "Settings",
                        link: 'settings'
                    },
                    {
                        id: 1,
                        text: "Folders and subfolders",
                        active: true
                    }
                ];
            }*/
        }
    }
</script>
