<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="folder-de-archive-modal-label">
                {{ $root.text(
                    'De-archive {folder}:',
                    { folder: $root.text($root.contents.folder_title, null, false, 'folder_title') },
                    true
                ) }}
                {{ folder_name }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="archived" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="archived"></div>
                </div>
            </div>
            <form v-else v-on:submit.prevent="deArchiveFolder()">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-else>
                    <p v-html="additionalThings()"></p>
                    <p v-if="(assets_archived_count > 0)">
                        {{ $root.text('If you need to manage {resources} you should do this first.',
                            { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title') },
                            true) }}
                    </p>
                </div>
            </form>
        </div>
        <div v-if="!archived" class="modal-footer">
            <span v-if="!archiving">
                <span>
                    <button type="button" class="btn btn-primary mr-2" @click="deArchiveFolder()" :disabled="loading">
                        <i class="far fa-archive mr-1"></i>
                        {{ $root.text(
                            'De-archive {folder}',
                            { folder: $root.text($root.contents.folder_title, null, false, 'folder_title') },
                            true
                        ) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">
                        {{ $root.text(
                            'De-archive {folder}',
                            { folder: $root.text($root.contents.folder_title, null, false, 'folder_title') },
                            true
                        ) }}
                    </span>
                </button>
            </span>
            <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="archiving">
                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                folder_id: 0,
                folder_name: '',
                subfolders_archived_count: 0,
                assets_archived_count: 0,
                archiving: false,
                archived: false
            }
        },
        mounted () {
            //
        },
        methods: {
            reset: function (folder, subfoldersArchivedCount) {
                this.loading = true;
                this.folder_id = folder.id;
                this.folder_name = folder.name;
                this.subfolders_archived_count = subfoldersArchivedCount;
                this.assets_archived_count = 0;
                this.archiving = false;
                this.archived = false;
                this.getAssetInfo();
            },
            getAssetInfo: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/de-archive/resource-info';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_archived_count = response.data.archived;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#folder-de-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            additionalThings: function () {
                // This action de-archives the {{ $root.contents.folder_title }}{{ additionalThings() }}.
                if ((this.subfolders_archived_count + this.assets_archived_count) == 0) {
                    return this.$root.text(
                        'This action de-archives the {folder}.',
                        { folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title') },
                        true
                    );
                }
                if ((this.subfolders_archived_count > 0) && (this.assets_archived_count > 0)) {
                    if ((this.subfolders_archived_count > 1) && (this.assets_archived_count > 1)) {
                        return this.$root.text(
                            'This action de-archives the {folder}, {x} {subfolders} with {y} {resources}.',
                            {
                                folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title'),
                                x: this.subfolders_archived_count,
                                subfolders: this.$root.text(
                                    this.$root.contents.subfolders_title,
                                    null,
                                    false,
                                    'subfolders_title'
                                ),
                                y: this.assets_archived_count,
                                resources: this.$root.text(
                                    this.$root.contents.asset_title + 's',
                                    null,
                                    false,
                                    'assets_title'
                                )
                            },
                            true
                        );
                    } else if (this.subfolders_archived_count > 1) {
                        return this.$root.text(
                            'This action de-archives the {folder}, {x} {subfolders} with 1 {resource}.',
                            {
                                folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title'),
                                x: this.subfolders_archived_count,
                                subfolders: this.$root.text(
                                    this.$root.contents.subfolders_title,
                                    null,
                                    false,
                                    'subfolders_title'
                                ),
                                resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title')
                            },
                            true
                        );
                    } else if (this.assets_archived_count > 1) {
                        return this.$root.text(
                            'This action de-archives the {folder}, 1 {subfolder} with {x} {resources}.',
                            {
                                folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title'),
                                subfolder: this.$root.text(
                                    this.$root.contents.subfolder_title,
                                    null,
                                    false,
                                    'subfolder_title'
                                ),
                                x: this.assets_archived_count,
                                resources: this.$root.text(
                                    this.$root.contents.asset_title + 's',
                                    null,
                                    false,
                                    'assets_title'
                                )
                            },
                            true
                        );
                    }
                    return this.$root.text(
                        'This action de-archives the {folder}, 1 {subfolder} with 1 {resource}.',
                        {
                            folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title'),
                            subfolder: this.$root.text(
                                this.$root.contents.subfolder_title,
                                null,
                                false,
                                'subfolder_title'
                            ),
                            resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title')
                        },
                        true
                    );
                } else if (this.subfolders_archived_count > 1) {
                    return this.$root.text(
                        'This action de-archives the {folder}, {x} {subfolders}.',
                        {
                            folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title'),
                            x: this.subfolders_archived_count,
                            subfolders: this.$root.text(
                                this.$root.contents.subfolders_title,
                                null,
                                false,
                                'subfolders_title'
                            )
                        },
                        true
                    );
                } else if (this.subfolders_archived_count == 1) {
                    return this.$root.text(
                        'This action de-archives the {folder}, 1 {subfolder}.',
                        {
                            folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title'),
                            subfolder: this.$root.text(
                                this.$root.contents.subfolder_title,
                                null,
                                false,
                                'subfolder_title'
                            )
                        },
                        true
                    );
                } else if (this.assets_archived_count > 1) {
                    return this.$root.text(
                        'This action de-archives the {folder} with {x} {resources}.',
                        {
                            folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title'),
                            x: this.assets_archived_count,
                            resources: this.$root.text(
                                this.$root.contents.asset_title + 's',
                                null,
                                false,
                                'assets_title'
                            )
                        },
                        true
                    );
                }

                return this.$root.text(
                    'This action de-archives the {folder} with 1 {resource}.',
                    {
                        folder: this.$root.text(this.$root.contents.folder_title, null, false, 'folder_title'),
                        resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title')
                    },
                    true
                );
            },
            deArchiveFolder: function () {
                this.archiving = true;
                /**
                 * Send de-archive folder request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/de-archive';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.archived = response.data.message;
                    vm.$parent.getFolders();
                    vm.$root.folders_changed = true;

                    setTimeout(function () {
                        $('#folder-de-archive-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#folder-de-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.archiving = false;
                });
            }
        }
    }
</script>
