<template>
    <div class="card asset-preview">
        <div class="thumbnail">
            <img :src="image_src" :alt="title">
        </div>

        <div class="card-body" style="position: relative">
            <div
                class="title mb-2"
                :id="'proof-title' + id"
            >
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="'proof-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <div>
                <span
                    class="asset-info stay"
                    :id="'proof-info' + id"
                >
                    <span v-if="data">
                        <span v-if="data.pdf">{{ $root.formatFileSize(data.pdf.size) }}</span>
                    </span>
                </span>
                &nbsp;
            </div>

            <div
                class="font-size-22 contact-links"
                style="position: absolute; right: 15px; bottom: 6px"
            >
                <a
                    v-if="!downloading"
                    href="#"
                    @click.prevent="initDownload()"
                >
                    <span v-b-tooltip.hover :title="$root.text('Download', null, true)">
                        <i class="fal fa-download"></i>
                    </span>
                </a>
                <span v-else>
                    <i class="fal fa-spinner-third fa-spin"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'in_share', 'email'],
        data () {
            return {
                id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                downloading: false
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                vm.short_title = vm.title;
                setTimeout(function () {
                    vm.fitTitle();
                }, 500);
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.original_id;
                    this.title = data.title;
                    this.short_title = data.title;
                    this.getPreviewImage();
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            },
            getPreviewImage: function () {
                /**
                 * Send request to API.
                 */
                let path = '/proof/' + this.id + '/preview';
                if (!this.$root.isLoggedIn() && this.in_share) {
                    path += '/' + this.in_share;
                    if (this.email) {
                        path += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + path,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            fitTitle: function () {
                if (!$('#proof-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#proof-title' + this.id)[0].scrollHeight > 20) {
                    let separator = ' ';
                    let words = this.short_title.split(separator);
                    if (words.length == 1) {
                        separator = '-';
                        words = this.short_title.split(separator);
                    }
                    if (words.length == 1) {
                        separator = '_';
                        words = this.short_title.split(separator);
                    }
                    if (words.length == 1) {
                        separator = '.';
                        words = this.short_title.split(separator);
                    }
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(separator) + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            initDownload: function () {
                if (!this.data.file) {
                    return null;
                }
                this.downloading = true;
                /**
                 * Send request to API.
                 */
                const download = this.data.file;
                const data = {
                    item_type: 'proof_file',
                    item_id: this.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();

                        return null;
                    }
                    vm.showError(
                        vm.$root.text('Download', null, true),
                        error.response.data.error || vm.$root.text('Something went wrong, please try again', null, true)
                    );
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            showError: function (title, text) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: 'error',
                    title: title,
                    text: text
                };
                $('#message-modal').modal('show');
            }
        }
    }
</script>
<style scoped>
    .asset-preview:hover .card-body .asset-info.stay {
        opacity: 1;
    }

    .asset-preview .title {
        overflow-wrap: anywhere;
    }
</style>
