var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"subfolder-archive-modal-label"}},[_vm._v("\n            "+_vm._s(_vm.$root.text(
                'Archive {subfolder}:',
                { subfolder: _vm.$root.text(_vm.$root.contents.subfolder_title, null, false, 'subfolder_title') },
                true
            ))+"\n            "+_vm._s(_vm.subfolder_name)+"\n        ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.archived)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col",domProps:{"innerHTML":_vm._s(_vm.archived)}})])]):_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.archiveSubfolder()}}},[(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',[_c('loading')],1):_c('div',[_c('p',[_vm._v(_vm._s(_vm.additionalThings()))]),_vm._v(" "),((_vm.assets_live_count > 0))?_c('p',[_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'If you need to manage {resources} you should do this first.',
                        {
                            resources: _vm.$root.text(this.$root.contents.asset_title + 's', null, false, 'assets_title')
                        },
                        true
                    ))+"\n                    "+_vm._s(_vm.$root.text(
                        'You can move {resources} to other {folders} by adding them to a collection and '
                            + 'using the Bulk actions function.',
                        {
                            resources: _vm.$root.text(
                                this.$root.contents.asset_title + 's',
                                null,
                                false,
                                'assets_title'
                            ),
                            folders: _vm.$root.text(this.$root.contents.folders_title, null, false, 'folders_title')
                        }
                    ))+"\n                ")]):_vm._e()])])]),_vm._v(" "),(!_vm.archived)?_c('div',{staticClass:"modal-footer"},[(!_vm.archiving)?_c('span',[_c('button',{key:'archive-subfolder-button',staticClass:"btn btn-primary mr-2",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.archiveSubfolder()}}},[_c('i',{staticClass:"far fa-archive mr-1"}),_vm._v(" "),(_vm.assets_live_count > 0)?_c('span',[(_vm.assets_live_count > 1)?_c('span',[_vm._v("\n                        "+_vm._s(_vm.$root.text(
                            'Archive {subfolder} and {resources}',
                            {
                                subfolder: _vm.$root.text(
                                    _vm.$root.contents.subfolder_title,
                                    null,
                                    false,
                                    'subfolder_title'
                                ),
                                resources: _vm.$root.text(
                                    this.$root.contents.asset_title + 's',
                                    null,
                                    false,
                                    'assets_title'
                                )
                            },
                            true
                        ))+"\n                    ")]):_c('span',[_vm._v("\n                        "+_vm._s(_vm.$root.text(
                            'Archive {subfolder} and {resource}',
                            {
                                subfolder: _vm.$root.text(
                                    _vm.$root.contents.subfolder_title,
                                    null,
                                    false,
                                    'subfolder_title'
                                ),
                                resource: _vm.$root.text(
                                    this.$root.contents.asset_title,
                                    null,
                                    false,
                                    'asset_title'
                                )
                            },
                            true
                        ))+"\n                    ")])]):_c('span',[_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'Archive {subfolder}',
                        { subfolder: _vm.$root.text(_vm.$root.contents.subfolder_title, null, false, 'subfolder_title') },
                        true
                    ))+"\n                ")])]),_vm._v(" "),((_vm.assets_live_count > 0))?_c('button',{key:'archive-assets-only-button',staticClass:"btn btn-primary mr-2",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.archiveSubfolder(true)}}},[_c('i',{staticClass:"far fa-archive mr-1"}),_vm._v(" "),(_vm.assets_live_count > 1)?_c('span',[_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'Archive {resources} only',
                        {
                            resources: _vm.$root.text(this.$root.contents.asset_title + 's', null, false, 'assets_title')
                        },
                        true
                    ))+"\n                ")]):_c('span',[_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'Archive {resource} only',
                        { resource: _vm.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                        true
                    ))+"\n                ")])]):_vm._e()]):_c('span',[_c('button',{key:'archiving-button',staticClass:"btn btn-primary mr-2",attrs:{"type":"button"}},[_c('i',{staticClass:"fal fa-spinner-third fa-spin mr-1"}),_vm._v(" "),_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$root.text('Archiving', null, true)))])])]),_vm._v(" "),_c('button',{key:'cancel-button',staticClass:"btn btn-light",attrs:{"type":"button","data-dismiss":"modal","disabled":_vm.archiving}},[_c('i',{staticClass:"far fa-times mr-1"}),_vm._v(" "+_vm._s(_vm.$root.text('Cancel', null, true))+"\n        ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1",staticStyle:{"max-width":"30px"}},[_c('i',{staticClass:"far fa-check"})])
}]

export { render, staticRenderFns }