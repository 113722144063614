var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),((!_vm.errors && !_vm.loading && (_vm.total_items == 0)))?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("\n        "+_vm._s(_vm.$root.text('There are no downloads', null, true))+"\n    ")]):_vm._e(),_vm._v(" "),((_vm.total_items > 0))?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-centered table-hover"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-sm-3 col-12 pb-sm-0 pb-1"},[_vm._v("\n                                        "+_vm._s(_vm.$root.text(
                                            '{resource} No.',
                                            {
                                                resource: _vm.$root.text(
                                                    _vm.$root.contents.asset_title,
                                                    null,
                                                    false,
                                                    'asset_title'
                                                )
                                            },
                                            true
                                        ))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-md-7 col-sm-5 col-12 pb-sm-0 pb-1"},[_vm._v("\n                                        "+_vm._s(_vm.$root.text('Title', null, true))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-md-2 col-sm-3 col-12"},[_vm._v("\n                                        "+_vm._s(_vm.$root.text('Downloaded on', null, true))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-sm-1 d-none d-sm-block"},[_vm._v(" ")])])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id + '-' + item.download_id},[_c('td',{staticClass:"pb-0",attrs:{"scope":"row","id":'download-row' + item.id + '-' + item.download_id}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2 col-3 d-none d-sm-block",staticStyle:{"padding-top":"3px"}},[_vm._v("\n                                        "+_vm._s(item.id)+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-md-7 col-5 d-none d-sm-block",staticStyle:{"padding-top":"3px"}},[_c('h5',{staticClass:"font-size-14 mb-0"},[_c('a',{staticClass:"text-dark",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleDownloadDetails(item.id + '-'
                                                    + item.download_id)}}},[_vm._v("\n                                                "+_vm._s(item.title)+"\n                                            ")])])]),_vm._v(" "),_c('div',{staticClass:"col-md-2 col-3 d-none d-sm-block",staticStyle:{"padding-top":"3px"}},[_c('span',{attrs:{"id":'dasm' + item.id + '-' + item.download_id}},[_vm._v("\n                                            "+_vm._s(_vm.formatDate(item.downloaded_at))+"\n                                        ")])]),_vm._v(" "),_c('div',{staticClass:"col-10 d-sm-none"},[_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(item.id))]),_vm._v(" "),_c('h5',{staticClass:"font-size-14 mb-1"},[_c('a',{staticClass:"text-dark",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleDownloadDetails(item.id + '-'
                                                    + item.download_id)}}},[_vm._v("\n                                                "+_vm._s(item.title)+"\n                                            ")])]),_vm._v(" "),_c('p',{staticClass:"mb-0"},[_c('span',{attrs:{"id":'daxs' + item.id + '-' + item.download_id}},[_vm._v("\n                                                "+_vm._s(_vm.formatDate(item.downloaded_at))+"\n                                            ")])])]),_vm._v(" "),_c('div',{staticClass:"col-sm-1 col-2 font-size-18 contact-links"},[_c('a',{staticClass:"float-right",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleDownloadDetails(item.id + '-' + item.download_id)}}},[((_vm.download_details_open.indexOf(item.id + '-'
                                                    + item.download_id) < 0))?_c('span',{key:'close-' + item.id + '-' + item.download_id},[_c('i',{staticClass:"fa-regular fa-chevron-circle-down"})]):_c('span',{key:'open-' + item.id + '-' + item.download_id},[_c('i',{staticClass:"fa-regular fa-chevron-circle-up"})])])])]),_vm._v(" "),_c('div',{staticClass:"row list-details-td mt-2"},[_c('div',{staticClass:"col-12 list-details",attrs:{"id":'download-details' + item.id + '-' + item.download_id}},[_c('my-account-download-details',{attrs:{"data":item}})],1)])])])}),0)])])])]):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"position":"relative","top":"-20px","height":"40px","overflow":"visible"}},[(_vm.loading)?_c('loading'):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }