var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topnav page-topnav",staticStyle:{"padding":"0 12px","max-height":"none","overflow-y":"visible"}},[_c('div',{staticClass:"container-fluid"},[_c('nav',{staticClass:"navbar navbar-light navbar-expand topnav-menu"},[_c('div',{staticClass:"navbar-collapse align-items-center justify-content-between"},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item"},[((_vm.$route.name == 'profile-groups'))?_c('h6',{key:'profile-groups'},[_c('i',{staticClass:"fa-light fa-building font-size-16 mr-1"}),_vm._v("\n                            "+_vm._s(_vm.$root.text(_vm.$root.contents.profile_groups_title, null, true, 'profile_groups_title'))+"\n                        ")]):_c('h6',{key:'profile-group'},[_vm._v("\n                            "+_vm._s(_vm.$root.text(_vm.$root.contents.profile_group_title, null, true, 'profile_group_title'))+"\n                            ")])])]),_vm._v(" "),(_vm.hasActions())?_c('ul',{staticClass:"navbar-nav"},[((_vm.hasActions() == 1))?_c('li',{staticClass:"nav-item",staticStyle:{"height":"36.5px"}},[_c('div',{staticStyle:{"position":"absolute","height":"36.5px","right":"0"}},[((_vm.$route.name == 'profile-groups'))?_c('button',{key:'add-profile-group-button',staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#profile-group-add-form-modal"},on:{"click":function($event){$event.preventDefault();return _vm.resetProfileGroupAddForm()}}},[_c('i',{staticClass:"far fa-building mr-1"}),_vm._v("\n                                "+_vm._s(_vm.$root.text(
                                    'Add {profile group}',
                                    {
                                        'profile group': _vm.$root.text(
                                            _vm.$root.contents.profile_group_title,
                                            null,
                                            false,
                                            'profile_group_title'
                                        )
                                    },
                                    true
                                ))+"\n                            ")]):_c('span',[(_vm.isProfileGroupsAdmin())?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){return _vm.viewProfileGroups()}}},[_c('i',{staticClass:"far fa-bars mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'View all {profile groups}',
                                        {
                                            'profile groups': _vm.$root.text(
                                                _vm.$root.contents.profile_groups_title,
                                                null,
                                                false,
                                                'profile_groups_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]):_vm._e(),_vm._v(" "),(_vm.$root.hasPermission('profiles_groups_edit')
                                        || (_vm.$root.is_own_profile_group
                                        && _vm.$root.hasPermission('profiles_own_group_edit')))?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#profile-group-edit-form-modal"},on:{"click":function($event){return _vm.resetProfileGroupEditForm()}}},[_c('i',{staticClass:"far fa-edit mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'Edit {profile group}',
                                        {
                                            'profile group': _vm.$root.text(
                                                _vm.$root.contents.profile_group_title,
                                                null,
                                                false,
                                                'profile_group_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]):_vm._e(),_vm._v(" "),(_vm.$root.hasPermission('profiles_groups_delete'))?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","data-toggle":"modal","data-target":"#profile-group-edit-form-modal"},on:{"click":function($event){return _vm.deleteProfileGroup()}}},[_c('i',{staticClass:"far fa-trash-alt mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'Delete {profile group}',
                                        {
                                            'profile group': _vm.$root.text(
                                                _vm.$root.contents.profile_group_title,
                                                null,
                                                false,
                                                'profile_group_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]):_vm._e(),_vm._v(" "),(_vm.showMyAccountButton())?_c('router-link',{key:'my-account',attrs:{"to":{ name: 'my-account' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate }){return [_c('button',{staticClass:"btn btn-primary",on:{"click":navigate}},[_c('i',{staticClass:"far fa-user mr-1"}),_vm._v(" "+_vm._s(_vm.$root.text('My account', null, true))+"\n                                    ")])]}}],null,false,3908009371)}):_vm._e()],1)])]):_vm._e(),_vm._v(" "),((_vm.hasActions() > 1))?_c('li',{staticClass:"nav-item",staticStyle:{"width":"100px","height":"36.5px"}},[_c('div',{staticClass:"btn-group",staticStyle:{"position":"absolute","height":"36.5px","right":"0"}},[_c('button',{key:'actions-button',staticClass:"btn btn-primary dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false","disabled":!_vm.$root.view_profile_group_name}},[_vm._v("\n                                "+_vm._s(_vm.$root.text('Actions', null, true))+"\n                                "),_c('i',{staticClass:"fas fa-chevron-down",staticStyle:{"font-size":"9px","position":"relative","top":"-1px","left":"2px"}})]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[(_vm.$root.hasPermission('profiles_groups_edit')
                                        || (_vm.$root.is_own_profile_group
                                        && _vm.$root.hasPermission('profiles_own_group_edit')))?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-toggle":"modal","data-target":"#profile-group-edit-form-modal"},on:{"click":function($event){$event.preventDefault();return _vm.resetProfileGroupEditForm()}}},[_c('i',{staticClass:"far fa-edit mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'Edit {profile group}',
                                        {
                                            'profile group': _vm.$root.text(
                                                _vm.$root.contents.profile_group_title,
                                                null,
                                                false,
                                                'profile_group_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]):_vm._e(),_vm._v(" "),(_vm.$root.hasPermission('profiles_groups_delete'))?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteProfileGroup()}}},[_c('i',{staticClass:"far fa-trash-alt mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'Delete {profile group}',
                                        {
                                            'profile group': _vm.$root.text(
                                                _vm.$root.contents.profile_group_title,
                                                null,
                                                false,
                                                'profile_group_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"dropdown-divider"}),_vm._v(" "),(_vm.isProfileGroupsAdmin())?_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.viewProfileGroups()}}},[_c('i',{staticClass:"far fa-bars mr-1",staticStyle:{"position":"relative","top":"1px"}}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'View all {profile groups}',
                                        {
                                            'profile groups': _vm.$root.text(
                                                _vm.$root.contents.profile_groups_title,
                                                null,
                                                false,
                                                'profile_groups_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]):_vm._e()])])]):_vm._e()]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }