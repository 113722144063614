<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            <span v-if="($route.name == 'my-account-artworks-awaiting-approval')">
                {{ $root.text('There are no items awaiting approval', null, true) }}
            </span>
            <span
                v-if="($route.name == 'my-account-artworks-approved') || ($route.name == 'my-account-artwork-approved')"
            >{{ $root.text('There are no approved items', null, true) }}</span>
            <span
                v-if="($route.name == 'my-account-artworks-declined') || ($route.name == 'my-account-artwork-declined')"
            >{{ $root.text('There are no declined items', null, true) }}</span>
        </div>
        <div v-if="(total_items > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Submitted on', null, true) }}
                                        </div>
                                        <div class="col-sm-8 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Your title', null, true) }}
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'approval-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ formatDate(item.finished_at) }}
                                        </div>
                                        <div class="col-8 d-none d-sm-block" style="padding-top: 3px">
                                            <span>
                                                <h5 class="font-size-14 mb-0">
                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        @click.prevent="toggleViewApproval(item)"
                                                    >
                                                        {{ item.title }}
                                                    </a>
                                                </h5>
                                            </span>
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <p class="mb-1">{{ formatDate(item.finished_at) }}</p>
                                            <span>
                                                <h5 class="font-size-14 mb-0">
                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        @click.prevent="toggleViewApproval(item)"
                                                    >
                                                        {{ item.title }}
                                                    </a>
                                                </h5>
                                            </span>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewApproval(item)"
                                            >
                                                <span
                                                    v-if="(approval_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'approval-details' + item.id">
                                            <artwork-approval-details
                                                :data="item"
                                                :details="(approval_details_open.indexOf(item.id) > -1)
                                                    ? approval_details[item.id] : null"
                                            ></artwork-approval-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="($route.params.id && !loading)" class="text-center mb-4">
            <router-link :to="{ name: $route.name.replace(/artwork/g, 'artworks') }">
                {{ $root.text('View all ' + (($route.name.indexOf('approved') > -1) ? 'approved' : 'declined')
                    + ' items', null, true) }}
            </router-link>
        </div>

        <div v-if="loading" style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading></loading>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'my-account-artwork-approvals',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                approval_details_open: [],
                approval_details: {}
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            
            if (!this.$root.hasPermission('templates_use')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            this.getArtworks();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            //
        },
        methods: {
            getArtworks: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }

                let filters = ['finished', '!deleted', 'cb::' + this.$root.user.id];
                switch (this.$route.name) {
                    case 'my-account-artworks-approved':
                    case 'my-account-artwork-approved':
                        filters.push('approved');
                        break;
                    case 'my-account-artworks-declined':
                    case 'my-account-artwork-declined':
                        filters.push('declined');
                        break;
                    default:
                        filters.push('!approved');
                        filters.push('!declined');
                }
                if (this.$route.params.id) {
                    filters.push('kw::' + btoa(this.$route.params.id));
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                if (page == 1) {
                    this.items = [];
                    this.approval_details = {};
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.approval_details_open = [];
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/0/artworks'
                    + filtersString + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.artworks.total;
                    vm.current_page = response.data.artworks.current_page;
                    vm.last_page = response.data.artworks.last_page;
                    if (response.data.artworks.data.length == 0) {
                        vm.loading = false;
                    } else {
                        response.data.artworks.data.forEach((item) => {
                            vm.items.push(item);
                            vm.getArtworkDetails(item);
                        });
                    }
                    if (vm.$route.params.id && (vm.total_items == 1)) {
                        setTimeout(function () {
                            vm.toggleViewApproval(vm.items[0]);
                        }, 1000);
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                    vm.loading = false;
                })
                .finally(() => {
                    //vm.loading = false;
                });
            },
            getArtworkDetails: function (item) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + item.template_id
                    + '/artwork/' + item.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.approval_details[response.data.artwork.id] = {
                        comments_html: response.data.artwork.comments_html,
                        name: response.data.artwork.name,
                        email: response.data.artwork.email,
                        telephone: response.data.artwork.telephone,
                        resource_id: response.data.artwork.resource_id,
                        resource_title: response.data.artwork.resource_title
                    }
                    if (response.data.artwork.last_chased_at_formatted) {
                        vm.approval_details[response.data.artwork.id].last_chased_at_formatted =
                            response.data.artwork.last_chased_at_formatted;
                    }
                    if (response.data.artwork.declined) {
                        vm.approval_details[response.data.artwork.id].fields =
                            response.data.artwork.fields;
                    }
                    if (response.data.artwork.approved_on) {
                        vm.approval_details[response.data.artwork.id].approved_on =
                            response.data.artwork.approved_on;
                    }
                    if (response.data.artwork.declined_on) {
                        vm.approval_details[response.data.artwork.id].declined_on =
                            response.data.artwork.declined_on;
                    }
                    if (response.data.artwork.approver_name) {
                        vm.approval_details[response.data.artwork.id].approver_name =
                            response.data.artwork.approver_name;
                    }
                    if (response.data.artwork.reason_for_decline_html) {
                        vm.approval_details[response.data.artwork.id].reason_for_decline_html =
                            response.data.artwork.reason_for_decline_html;
                    }
                    if (response.data.artwork.pdf) {
                        vm.approval_details[response.data.artwork.id].pdf =
                            response.data.artwork.pdf;
                    }
                    if (response.data.artwork.pdf_ncm) {
                        vm.approval_details[response.data.artwork.id].pdf_ncm =
                            response.data.artwork.pdf_ncm;
                    }
                    if (response.data.artwork.pdf_commented) {
                        vm.approval_details[response.data.artwork.id].pdf_commented =
                            response.data.artwork.pdf_commented;
                    }
                    if (response.data.artwork.images) {
                        vm.approval_details[response.data.artwork.id].images =
                            response.data.artwork.images;
                    }

                    let allLoaded = true;
                    vm.items.forEach((item) => {
                        if (!vm.hasDetails(item.id)) {
                            allLoaded = false;
                        }
                    });
                    if (allLoaded) {
                        this.loading = false;
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() => {
                    //
                });
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getArtworks(this.current_page + 1);
                    }
                }
            },
            toggleViewApproval: function (item) {
                if (this.approval_details_open.indexOf(item.id) < 0) {
                    this.approval_details_open.push(item.id);
                    $('#approval-row' + item.id).addClass('highlighted');
                    $('#approval-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#approval-details' + item.id).slideUp('slow', function () {
                        $('#approval-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.approval_details_open.length; n++) { 
                            if (vm.approval_details_open[n] == item.id) { 
                                vm.approval_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            hasDetails: function (itemId) {
                if (itemId in this.approval_details) {
                    return true;
                }

                return false;
            },
            removeApproval: function (approvalId) {
                for (let n = (this.items.length - 1); n >= 0; n--) {
                    if (this.items[n].id == approvalId) {
                        this.items.splice(n, 1);
                        this.total_items--;
                    }
                }
            }
        }
    }
</script>
