<template>
    <div>
        
    </div>
</template>

<script>
    export default {
        data () {
            return {
                //
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.checkEmail();
        },
        methods: {
            checkEmail: function () {
                const email = (this.$route.params.email || null);

                if (email == this.$root.user.email) {
                    this.$parent.message = {
                        type: null,
                        title: this.$root.text('Confirm availability', null, true),
                        text: this.$root.text(
                            'Thank you for confirming that you are still available as an Administrator on this site',
                            null,
                            true
                        )
                    };
                    $('#message-modal').modal('show');

                    var vm = this;
                    setTimeout(function () {
                        $('#message-modal').modal('hide');
                        vm.$router.push({ name: 'home' });
                    }, 4000);

                    return null;
                }

                this.$root.login_email = this.$route.params.email.toString();
                this.$root.doLogout();
            }
        }
    }
</script>
