<template>
    <div class="main-nav-container">
        <header id="page-topbar">
            <div
                class="navbar-header"
                id="main-nav"
                :style="($root.isLoggedIn() ? 'position: relative' : 'height: 2px')"
            >
                <div v-if="$root.isLoggedIn()" class="d-flex">
                    <div class="navbar-brand-box">
                        <a
                            class="logo logo-light"
                            href="#"
                            @click.prevent="navigateTo({ name: $root.isProofsGuest() ? 'proofs' : 'home' })"
                        >
                            <span v-if="$root.app.favicon" class="logo-sm">
                                <img :src="$root.app.favicon" :alt="$root.client.client_name">
                            </span>
                            <span v-if="$root.app.main_nav_logo" class="logo-lg">
                                <img :src="$root.app.main_nav_logo" :alt="$root.client.client_name">
                            </span>
                        </a>
                    </div>

                    <button
                        v-if="!isFullScreenPanel()"
                        type="button"
                        class="btn btn-sm px-3 font-size-16 d-md-none header-item collapsed"
                        data-toggle="collapse"
                        data-target="#main-nav-small-content"
                        aria-expanded="false"
                    >
                        <i class="fa fa-fw fa-bars"></i>
                    </button>

                    <b-dropdown
                        v-if="canSearchAssets()"
                        variant="black"
                        class="dropdown-mega d-none d-md-block"
                        toggle-class="header-item"
                        menu-class="dropdown-megamenu"
                        v-on:shown="focusKeywordSearchField()"
                        ref="search_dropdown"
                    >
                        <template v-slot:button-content>
                            <span class="search-our-resources">
                                <span v-if="isArchive()">{{ $root.text('search our archive', null, true) }}</span>
                                <span v-else>
                                    {{ $root.text('search our {resources}',
                                        { resources: $root.text($root.contents.asset_title + 's', null, false,
                                        'assets_title') }, true) }}
                                </span>
                                <i
                                    class="fas fa-chevron-down"
                                    style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                ></i>
                            </span>
                        </template>
                        <search-dropdown-content
                            :folders_changed="$root.folders_changed"
                            :assets_info="$root.assets_info"
                            :small="false"
                        ></search-dropdown-content>
                    </b-dropdown>

                    <div
                        v-if="canViewCollections()"
                        class="d-none d-md-inline-block ml-1"
                    >
                        <router-link :to="{ name: 'collections' }" custom v-slot="{ navigate }">
                            <button class="btn header-item noti-icon left" @click="navigate">
                                {{ $root.text('My collections', null, true) }}
                            </button>
                        </router-link>
                    </div>

                    <div
                        v-if="canViewProofs()"
                        class="d-none d-md-inline-block ml-1"
                    >
                        <button
                            type="button"
                            class="btn header-item noti-icon left"
                            @click="viewProofs()"
                        >{{ $root.text('Proof tracker', null, true) }}</button>
                    </div>

                    <div
                        v-if="!isFullScreenPanel() && $root.isPrintSupplier()"
                        class="d-none d-md-inline-block ml-1"
                    >
                        <router-link :to="{ name: 'my-account-print-orders' }" custom v-slot="{ navigate }">
                            <button class="btn header-item noti-icon left" @click="navigate">
                                {{ $root.text('Orders', null, true) }}
                            </button>
                        </router-link>
                    </div>

                    <div
                        v-if="canViewPodCodes()"
                        class="d-none d-md-inline-block ml-1"
                    >
                        <router-link :to="{ name: 'pod-codes' }" custom v-slot="{ navigate }">
                            <button class="btn header-item noti-icon left" @click="navigate">
                                {{ $root.text('POD codes', null, true) }}
                            </button>
                        </router-link>
                    </div>

                    <div
                        v-if="canSendFlyte()"
                        class="d-none d-md-inline-block ml-1"
                    >
                        <button
                            type="button"
                            class="btn header-item noti-icon left"
                            data-toggle="modal"
                            data-target="#flyte-form-modal"
                            @click="resetFlyteForm()"
                        >{{ $root.text('Flyte', null, true) }}</button>
                    </div>

                    <div
                        v-if="canEmailSupport()"
                        class="d-none d-md-inline-block ml-1"
                    >
                        <button
                            type="button"
                            class="btn header-item noti-icon left"
                            data-toggle="modal"
                            data-target="#email-support-modal"
                            @click="resetSupportForm()"
                        >{{ $root.text('Support', null, true) }}</button>
                    </div>
                </div>

                <div v-if="$root.isLoggedIn()" class="d-flex">
                    <div v-if="!$root.isProofsGuest()" class="dropdown">
                        <button
                            class="btn header-item noti-icon"
                            @click="navigateTo({ name: 'home' })"
                        >
                            <span
                                v-b-tooltip.hover
                                :title="$root.text('Home', null, true)"
                            >
                                <i class="far fa-home" style="font-size: 19px"></i>
                            </span>
                        </button>
                    </div>

                    <b-dropdown
                        v-if="canAddSomething()"
                        :key="'add-something'"
                        class="noti-icon"
                        menu-class="dropdown-menu-lg"
                        right
                        toggle-class="header-item"
                        variant="black"
                    >
                        <template v-slot:button-content>
                            <span
                                v-b-tooltip.hover
                                :title="$root.text('Add...', null, true)"
                            >
                                <i class="far fa-plus-circle" style="font-size: 19px"></i>
                            </span>
                        </template>

                        <add-something></add-something>
                    </b-dropdown>

                    <b-dropdown
                        v-if="!isFullScreenPanel()"
                        :key="'notifications'"
                        class="noti-icon"
                        menu-class="dropdown-menu-lg py-0"
                        right
                        toggle-class="header-item"
                        variant="black"
                        ref="user_notifications_dropdown"
                        v-on:show="markAllNotificationsAsRead('user')"
                    >
                        <template v-slot:button-content>
                            <span
                                v-b-tooltip.hover
                                :title="$root.text('Notifications', null, true)"
                            >
                                <i class="far fa-bell" style="font-size: 19px"></i>
                            </span>
                            <span
                                v-if="(notifications.user_unread > 0)"
                                class="badge badge-danger badge-pill d-none d-md-block"
                                style="right: 3px"
                            >
                                <span v-if="(notifications.user_unread > 9)">9+</span>
                                <span v-else>{{ notifications.user_unread }}</span>
                            </span>
                        </template>

                        <quick-notifications
                            type="user"
                            :notifications="notifications.user"
                            :unread="notifications.user_unread"
                        ></quick-notifications>
                    </b-dropdown>

                    <b-dropdown
                        :key="'my-account'"
                        right
                        variant="black"
                        toggle-class="header-item"
                        ref="account_dropdown"
                    >
                        <template v-slot:button-content>
                            <img
                                v-if="$root.user.profile_image"
                                class="rounded-circle header-profile-user"
                                :src="$root.user.profile_image"
                                alt="Header Avatar"
                            >
                            <div v-else class="avatar-xs" style="display: inline-block; width: 36px; height: 36px">
                                <span
                                    class="avatar-title rounded-circle font-size-14"
                                >{{ $root.initial(userFirstName(), userLastName()) }}</span>
                            </div>
                            <span class="d-none d-xl-inline-block ml-1">{{ userFirstName() }}</span>
                            <i
                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                            ></i>
                        </template>
                        <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="navigateTo({ name: 'my-account' })"
                        >
                            <i class="far fa-user mr-1"></i> {{ $root.text('My account', null, true) }}
                        </a>
                        <a
                            v-if="hasProfileGroup()"
                            class="dropdown-item"
                            href="#"
                            @click.prevent="navigateTo({ name: 'view-profile-group', params: { id: profile_group_id } })"
                        >
                            <i class="far fa-building mr-1"></i>
                            {{ $root.text(
                                'Manage my {profile group}',
                                {
                                    'profile group': $root.text(
                                        $root.contents.profile_group_title,
                                        null,
                                        false,
                                        'profile_group_title'
                                    )
                                },
                                true
                            ) }}
                        </a>
                        <a
                            class="dropdown-item"
                            href="#"
                            data-toggle="modal"
                            data-target="#edit-my-details-modal"
                            @click="resetMyDetailsForm()"
                        >
                            <i class="far fa-edit mr-1"></i> {{ $root.text('Edit personal info', null, true) }}
                        </a>
                        <a
                            class="dropdown-item"
                            href="#"
                            data-toggle="modal"
                            data-target="#change-password-modal"
                            @click="resetChangePasswordForm()"
                        >
                            <i class="far fa-key mr-1"></i> {{ $root.text('Change password', null, true) }}
                        </a>
                        <div class="dropdown-divider"></div>
                        <span v-if="$root.imitate">
                            <a
                                class="dropdown-item text-danger"
                                href="#"
                                @click.prevent="$root.stopImitating()"
                            >
                                <i class="far fa-times mr-1"></i> {{ $root.text('Stop imitating', null, true) }} 
                            </a>
                        </span>
                        <span v-else>
                            <span v-if="!logging_out">
                                <a
                                    class="dropdown-item text-danger"
                                    href="#"
                                    @click.prevent="navigateTo({ name: 'logout' })"
                                >
                                    <i class="far fa-power-off mr-1"></i> {{ $root.text('Logout', null, true) }} 
                                </a>
                            </span>
                            <span v-else>
                                <span style="padding-left: 24px; line-height: 30px">
                                    <i class="far fa-spinner-third fa-spin mr-1"></i> 
                                    <span style="position: relative; top: 1px">
                                        {{ $root.text('Logout', null, true) }}
                                    </span>
                                </span>
                            </span>
                        </span>
                    </b-dropdown>

                    <b-dropdown
                        v-if="$root.getAvailableLanguages().length > 1"
                        :key="'language'"
                        right
                        variant="black"
                        toggle-class="header-item"
                        ref="language_dropdown"
                    >
                        <template v-slot:button-content>
                            {{ $root.getCurrentLanguageCode() }}
                            <i
                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                            ></i>
                        </template>
                        <a
                            v-for="language in $root.getAvailableLanguages()"
                            :key="'lang-' + language.id"
                            :class="'dropdown-item' + ($root.isCurrentLanguage(language.id) ? ' active' : '')"
                            href="#"
                            @click.prevent="$root.setLanguage(language.id)"
                        >
                            {{ language.code.substring(0, 2).toUpperCase() }} - {{ language.name }}
                        </a>
                    </b-dropdown>

                    <div v-if="showSettings()" class="dropdown d-inline-block">
                        <button
                            :class="'btn header-item noti-icon'
                                + (((notifications.admin_unread > 0) && !isFullScreenPanel()) ? ' pr-0' : '')"
                            @click="navigateTo({ name: 'settings' })"
                        >
                            <span
                                v-b-tooltip.hover
                                :title="$root.text('Settings', null, true)"
                            >
                                <i class="far fa-cog" style="font-size: 19px"></i>
                            </span>
                        </button>
                    </div>
                    <b-dropdown
                        v-if="(showSettings() && (notifications.admin_unread > 0) && !isFullScreenPanel())"
                        :key="'admin-notifications'"
                        class="noti-icon"
                        menu-class="dropdown-menu-lg py-0"
                        right
                        toggle-class="header-item"
                        variant="black"
                        ref="admin_notifications_dropdown"
                    >
                        <template v-slot:button-content>
                            <span
                                v-b-tooltip.hover
                                :title="$root.text('Admin notifications', null, true)"
                                style="position: relative"
                            >
                                <!--<i class="fas fa-circle text-danger" style="font-size: 19px"></i>-->
                                <span class="font-size-12 bg-danger text-white admin-notifications">
                                    {{ (notifications.admin_unread > 9) ? '9+' : notifications.admin_unread }}
                                </span>
                            </span>
                            <!--<span
                                v-if="(notifications.admin_unread > 0)"
                                class="badge badge-danger badge-pill d-none d-md-block"
                                style="right: 3px"
                            >
                                <span v-if="(notifications.admin_unread > 9)">9+</span>
                                <span v-else>{{ notifications.admin_unread }}</span>
                            </span>-->
                        </template>

                        <quick-notifications
                            type="admin"
                            :notifications="notifications.admin"
                            :unread="notifications.admin_unread"
                        ></quick-notifications>
                    </b-dropdown>

                    <button
                        v-if="$root.adding_asset || $root.adding_template"
                        class="btn btn-dark header-item noti-icon mr-2"
                        @click="closeFullScreenPanel()"
                    >
                        <i class="far fa-times mr-1" style="font-size: 25px"></i>
                        <span style="position: relative; top: -5px">{{ $root.text('Close', null, true) }}</span>
                    </button>
                </div>
            </div>
        </header>

        <main-nav-small v-if="$root.isLoggedIn()" :is_archive="isArchive()"></main-nav-small>

        <home-top-nav v-if="(($route.name == 'home') && $root.editing_homepage)"></home-top-nav>
        <assets-top-nav
            v-if="isAssetsPage()"
            :asset_filters="$root.asset_filters"
            :is_archive="isArchive()"
        ></assets-top-nav>
        <settings-top-nav
            v-if="isSettingsPage()"
            :csv_download="$root.report_csv_download"
            :xls_download="$root.report_xls_download"
        ></settings-top-nav>
        <users-top-nav
            v-if="isUsersPage()"
            :expired_invites="$root.num_expired_invites"
            :user_filters="$root.user_filters"
            :xls_download="$root.report_xls_download"
        ></users-top-nav>
        <folders-top-nav v-if="isFoldersPage()"></folders-top-nav>
        <asset-types-top-nav v-if="isAssetTypesPage()"></asset-types-top-nav>
        <collections-top-nav v-if="isCollectionsPage()" :current_route="$route.name"></collections-top-nav>
        <shared-top-nav v-if="isSharedPage()" :flyte_downloading="$root.share_info.flyte_downloading"></shared-top-nav>
        <my-account-top-nav v-if="isMyAccountPage()"></my-account-top-nav>
        <notifications-top-nav
            v-if="($route.name == 'notifications')"
            :notifications="notifications"
        ></notifications-top-nav>
        <pod-codes-top-nav v-if="($route.name == 'pod-codes')"></pod-codes-top-nav>
        <print-orders-top-nav v-if="isPrintOrdersPage()"></print-orders-top-nav>
        <profile-groups-top-nav v-if="isProfileGroupsPage()"></profile-groups-top-nav>
        <proofs-top-nav v-if="isProofsPage()" :current_route="$route.name"></proofs-top-nav>

        <div v-if="($root.status_info.left || $root.status_info.right || hasFilters())" :class="statusBarClass()">
            <div class="container-fluid">
                <div class="row no-gutters" style="line-height: 1.7">
                    <div
                        :class="statusInfoLeftClass()"
                        v-if="$root.status_info.left || getFilters() || ($route.name == 'view-collection')
                            || hasFilterBar()"
                    >
                        <div v-if="getFilters('custom_field')">
                            <div v-for="cfid in getFilters('custom_field')" :key="cfid">
                                <span :class="'filters-bar custom_field' + cfid">
                                    <span v-for="filter in getFilters('custom_field', 'cf' + cfid)" :key="filter.id">
                                        <span class="contact-links mr-4">
                                            <span
                                                v-html="filter.text"
                                                :class="filterClass(filter)"
                                                @click="filterAction(filter)"
                                            ></span>
                                            <a
                                                href="#"
                                                @click.prevent="removeFilter(filter)"
                                            >
                                                <i class="fal fa-times-circle"></i>
                                            </a>
                                        </span>
                                    </span>
                                </span>
                                <span
                                    :class="'scroll-left custom_field' + cfid + ' contact-links font-size-18'"
                                    @click="scrollFilters('left', 'custom_field' + cfid)"
                                    :style="filtersScrollStyle('custom_field', cfid)"
                                >
                                    <a href="#" @click.prevent="doNothing()"><i class="far fa-chevron-left"></i></a>
                                </span>
                                <span
                                    :class="'scroll-right custom_field' + cfid + ' contact-links font-size-18'"
                                    @click="scrollFilters('right', 'custom_field' + cfid)"
                                    :style="filtersScrollStyle('custom_field', cfid)"
                                >
                                    <a href="#" @click.prevent="doNothing()"><i class="far fa-chevron-right"></i></a>
                                </span>
                                <div
                                    v-if="(!isLastCustomFieldFilter(cfid) || getFilters('asset_type')
                                        || getFilters('manual'))"
                                    style="border-bottom: 1px solid #fff; position: relative; top: -4px"
                                ></div>
                            </div>
                        </div>

                        <span v-if="getFilters('asset_type')" class="filters-bar asset_type">
                            <span v-for="filter in getFilters('asset_type')" :key="filter.id">
                                <span class="contact-links mr-4">
                                    <span
                                        v-html="filter.text"
                                        :class="filterClass(filter)"
                                        @click="filterAction(filter)"
                                    ></span>
                                    <a
                                        href="#"
                                        @click.prevent="removeFilter(filter)"
                                    >
                                        <i class="fal fa-times-circle"></i>
                                    </a>
                                </span>
                            </span>
                        </span>
                        <span v-if="getFilters('asset_type')">
                            <span
                                class="scroll-left asset_type contact-links font-size-18"
                                @click="scrollFilters('left', 'asset_type')"
                                :style="filtersScrollStyle('asset_type')"
                            >
                                <a href="#" @click.prevent="doNothing()"><i class="far fa-chevron-left"></i></a>
                            </span>
                            <span
                                class="scroll-right asset_type contact-links font-size-18"
                                @click="scrollFilters('right', 'asset_type')"
                                :style="filtersScrollStyle('asset_type')"
                            >
                                <a href="#" @click.prevent="doNothing()"><i class="far fa-chevron-right"></i></a>
                            </span>
                        </span>
                        <div
                            v-if="getFilters('asset_type') && getFilters('manual')"
                            style="border-bottom: 1px solid #fff; position: relative; top: -4px"
                        ></div>
                        <span v-if="!hasFilterBar()">
                            <span v-if="getFilters('manual')" class="filters-bar manual">
                                <span v-for="filter in getFilters('manual')" :key="filter.id">
                                    <span
                                        v-if="((filter.key != 'folder') && (filter.key != 'subfolder'))"
                                        class="contact-links mr-4"
                                    >
                                        <span
                                            v-if="filter.key == 'reverse-image-search'"
                                            style="position: relative; display: inline-block; width: 42px"
                                        >
                                            <span class="text-center reverse-image-search-thumbnail">
                                                <img :src="filter.text" class="img-fluid">
                                            </span>
                                        </span>
                                        <span
                                            v-else
                                            v-html="filter.text"
                                            :class="filterClass(filter)"
                                            @click="filterAction(filter)"
                                        ></span>
                                        <a
                                            href="#"
                                            @click.prevent="removeFilter(filter)"
                                        >
                                            <i class="fal fa-times-circle"></i>
                                        </a>
                                    </span>
                                </span>
                            </span>
                            <span v-if="getFilters('manual')">
                                <span
                                    class="scroll-left manual contact-links font-size-18"
                                    @click="scrollFilters('left', 'manual')"
                                    :style="filtersScrollStyle('manual')"
                                >
                                    <a href="#" @click.prevent="doNothing()"><i class="far fa-chevron-left"></i></a>
                                </span>
                                <span
                                    class="scroll-right manual contact-links font-size-18"
                                    @click="scrollFilters('right', 'manual')"
                                    :style="filtersScrollStyle('manual')"
                                >
                                    <a href="#" @click.prevent="doNothing()"><i class="far fa-chevron-right"></i></a>
                                </span>
                            </span>
                        </span>

                        <collection-sub-nav v-if="($route.name == 'view-collection')"></collection-sub-nav>
                        <user-filters
                            v-if="(($route.name == 'users') || ($route.name == 'deletion-requests'))
                                && isComponentLoaded($route.name)"
                            :user_filters="$root.user_filters"
                        ></user-filters>
                        <profile-group-filters
                            v-if="($route.name == 'profile-groups') && isComponentLoaded($route.name)"
                            :profile_group_filters="$root.profile_group_filters"
                        ></profile-group-filters>
                        <artwork-approval-filters
                            v-if="($route.name == 'artwork-approvals') && isComponentLoaded($route.name)"
                            :artwork_approval_filters="$root.artwork_approval_filters"
                        ></artwork-approval-filters>
                        <print-order-filters
                            v-if="(($route.name == 'print-orders') || ($route.name == 'my-account-print-orders'))
                                && isComponentLoaded('print-orders')"
                            :print_order_filters="$root.print_order_filters"
                            :result_approvers="$root.print_order_result_approvers"
                        ></print-order-filters>
                        <asset-approval-filters
                            v-if="($route.name == 'asset-approvals') && isComponentLoaded($route.name)"
                            :asset_approval_filters="$root.asset_approval_filters"
                        ></asset-approval-filters>
                        <div v-if="($route.name == 'view-collection')" style="height: 30px"></div>

                        <span v-html="$root.status_info.left"></span>
                    </div>
                    <div
                        :class="'col text-' + ((canSelectAll() || canShareFilters()) ? 'md' : 'sm') + '-right pr-3 py-2'"
                        v-if="$root.status_info.right || hasFilters()"
                        style="white-space: nowrap"
                    >
                        <span v-if="canShareFilters()" style="margin-right: 133px; position: relative">
                            <button
                                class="btn btn-light"
                                style="position: absolute; top: -9px; white-space: nowrap"
                                data-toggle="modal"
                                data-target="#filter-share-modal"
                                @click="resetShareFilters()"
                            >
                                <span style="display: inline-block; vertical-align: middle">
                                    <i class="fal fa-link font-size-20 mr-1"></i>
                                </span>
                                {{ $root.text('Get live link', null, true) }}
                            </button>
                        </span>
                        <span v-html="$root.status_info.right" style="margin-left: 10px"></span>
                        <span
                            v-if="canSelectAll()"
                            :style="'margin-left: 10px; margin-right: ' + (isAllSelected() ? '115px' : '97px')
                                + '; position: relative'"
                        >
                            <button
                                class="btn btn-light select-all-button"
                                style="position: absolute; top: -9px; white-space: nowrap"
                                @click="selectAll()"
                            >
                                <span
                                    v-if="!isAllSelected()"
                                    :key="'select-all'"
                                    style="display: inline-block; vertical-align: middle"
                                >
                                    <i class="fal fa-square font-size-20 mr-1"></i>
                                </span>
                                <span v-else :key="'deselect-all'" style="display: inline-block; vertical-align: middle">
                                    <i class="fal fa-check-square font-size-20 mr-1"></i>
                                </span>
                                <span v-if="isAllSelected()">{{ $root.text('deselect all', null, true) }}</span>
                                <span v-else>{{ $root.text('select all', null, true) }}</span>
                            </button>
                        </span>
                        <span v-if="hasFilters() && !hasFilterBar()" style="margin-left: 95px; position: relative">
                            <button
                                class="btn btn-light"
                                style="position: absolute; right: -2px; top: -9px; white-space: nowrap"
                                @click="showFilters()"
                                :disabled="(($route.name == 'view-collection') && !$root.view_collection_name)"
                            >
                                <span style="display: inline-block; vertical-align: middle">
                                    <i class="fal fa-sliders-v-square font-size-20 mr-1"></i>
                                </span>
                                {{ $root.text('Filters', null, true) }}
                            </button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import simplebar from "simplebar-vue";

    export default {
        components: {
            simplebar
        },
        props: ['imitate'],
        data () {
            return {
                logging_out: false,
                keyword: '',
                landing_route: null,
                notifications: {
                    user: [],
                    admin: [],
                    user_unread: 0,
                    admin_unread: 0
                },
                scroll_filters_check_interval: null,
                get_notifications_interval: null,
                profile_group_id: null,
                profile_group_loading: true,
                reverse_image_search_tooltip_shown: false
            }
        },
        mounted () {
            this.landing_route = this.$route;

            var vmMainNav = this;
            vmMainNav.scroll_filters_check_interval = setInterval(function () {
                vmMainNav.scrollFiltersCheck();
            }, 999);
            if (this.$root.isLoggedIn()) {
                this.getNotifications();
            }
            vmMainNav.get_notifications_interval = setInterval(function () {
                if (vmMainNav.$root.isLoggedIn()) {
                    vmMainNav.getNotifications();
                }
            }, 60000);

            if (this.hasProfile()) {
                this.getProfileGroup();
            }
        },
        beforeDestroy() {
            clearInterval(this.scroll_filters_check_interval);
            clearInterval(this.get_notifications_interval);
        },
        watch: {
            imitate: function (val) {
                this.notifications = {
                    user: [],
                    admin: [],
                    user_unread: 0,
                    admin_unread: 0
                };
                if (this.$root.isLoggedIn()) {
                    this.getNotifications();
                }
            }
        },
        methods: {
            showSearchDropdown: function () {
                if (this.$refs.search_dropdown) {
                    this.$refs.search_dropdown.show(true);
                }
            },
            hideSearchDropdown: function () {
                if (this.$refs.search_dropdown) {
                    this.$refs.search_dropdown.hide(true);
                }
            },
            hideAllDropdowns: function () {
                this.hideSearchDropdown();
                if (this.$refs.account_dropdown) {
                    this.$refs.account_dropdown.hide(true);
                }
                if (this.$refs.user_notifications_dropdown) {
                    this.$refs.user_notifications_dropdown.hide(true);
                }
                if (this.$refs.admin_notifications_dropdown) {
                    this.$refs.admin_notifications_dropdown.hide(true);
                }
                if (this.$refs.language_dropdown) {
                    this.$refs.language_dropdown.hide(true);
                }
            },
            focusKeywordSearchField: function () {
                setTimeout(function () {
                    $('.search-by-keyword').focus();
                }, 200);
                // Show reverse image search tooltip?
                var vm = this;
                setTimeout(function () {
                    vm.showReverseImageSearchTooltip();
                }, 500);
            },
            showReverseImageSearchTooltip: function () {
                if (!this.$root.client.similar_assets_search || !this.$root.hasPermission('assets_access')) {
                    return null;
                }
                if (this.reverse_image_search_tooltip_shown) {
                    return null;
                }
                const cookieName = this.$root.app.client_id + '_ristt' + this.$root.user.id;
                if (this.$cookie.get(cookieName)) {
                    this.reverse_image_search_tooltip_shown = true;

                    return null;
                }
                //$('#reverse-image-search-tooltip-modal').modal('show');
                $('body').append('<div class="modal-backdrop fade show"></div>');
                $('body').addClass('modal-open');
                $('#reverse-image-search-tooltip-modal').css('display', 'block');
                $('#reverse-image-search-tooltip-modal').addClass('show');
                this.reverse_image_search_tooltip_shown = true;
            },
            toggleMenu: function () {
                let element = document.getElementById('main-nav-small-content');
                element.classList.toggle('show');
            },
            logout: function () {
                this.logging_out = true;
                this.$parent.loading = true;
                const path = '/' + this.$root.app.client_id + '/user/logout'
                const url = this.$root.app.api_url + path;
                const data = {};
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.doLogout();
                })
                .catch(function (error) {
                    console.log(error);
                    alert('Sorry, something went wrong. Please try again.');
                })
                .finally(() => {
                    vm.logging_out = false;
                    vm.$parent.loading = false;
                });
            },
            resetMyDetailsForm: function () {
                const component = this.$root.findComponent(this.$parent, 'my-account-edit');
                if (component) {
                    component.resetForm();
                }
            },
            resetFlyteForm: function () {
                const component = this.$root.findComponent(this.$parent, 'flyte-form');
                if (component) {
                    component.resetForm();
                }
            },
            resetChangePasswordForm: function () {
                const component = this.$root.findComponent(this.$parent, 'change-password');
                if (component) {
                    component.resetForm();
                }
            },
            userFirstName: function () {
                if (this.$root.user) {
                    return this.$root.user.first_name;
                }

                return '';
            },
            userLastName: function () {
                if (this.$root.user) {
                    return this.$root.user.last_name;
                }

                return '';
            },
            isAssetsPage: function (routeName = null) {
                const pages = ['assets', 'assets-filtered'];
                if (!routeName) {
                    routeName = this.$route.name;
                }

                return (pages.indexOf(routeName) > -1);
            },
            isSettingsPage: function () {
                const pages = [
                    'settings',
                    'custom-fields',
                    'keyword-categories',
                    'user-groups',
                    'pre-approved-email-domains',
                    'asset-approvals',
                    'asset-reviews',
                    'alerts',
                    'reports-overview',
                    'reports-registrations',
                    'reports-visits',
                    'reports-asset-uploads',
                    'reports-asset-downloads',
                    'reports-artwork-created',
                    'reports-templates',
                    'reports-orders',
                    'reports-flytes-sent',
                    'profile-fields',
                    'artwork-approvals',
                    'print-order-copy-recipients',
                    'detected-label-exclusions',
                    'languages'
                ];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isUsersPage: function () {
                const pages = ['new-users', 'users', 'deletion-requests'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isAssetTypesPage: function () {
                const pages = ['asset-types', 'asset-types-inactive'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isFoldersPage: function () {
                const pages = ['folders', 'folders-archived'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isCollectionsPage: function () {
                const pages = ['collections', 'view-collection'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isSharedPage: function () {
                const pages = ['shared', 'shared-email'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isMyAccountPage: function () {
                const pages = [
                    'my-account',
                    'my-account-uploads',
                    'my-account-downloads',
                    'my-account-assets-awaiting-approval',
                    'my-account-assets-approved',
                    'my-account-collections-shared',
                    'my-account-flytes-sent',
                    'my-account-print-orders',
                    'my-account-artworks-awaiting-approval',
                    'my-account-artworks-approved',
                    'my-account-artwork-approved',
                    'my-account-artworks-declined',
                    'my-account-artwork-declined',
                    'my-account-artwork-actions',
                    'my-account-user-groups'
                ];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isReportsPage: function () {
                const pages = [
                    'reports-overview',
                    'reports-registrations',
                    'reports-visits',
                    'reports-asset-uploads',
                    'reports-asset-downloads',
                    'reports-artwork-created',
                    'reports-templates',
                    'reports-orders',
                    'reports-flytes-sent'
                ];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isPrintOrdersPage: function () {
                const pages = ['print-orders', 'price-panel-types', 'print-order-suppliers'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isProfileGroupsPage: function () {
                const pages = ['profile-groups', 'view-profile-group'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isProofsPage: function () {
                const pages = ['proofs', 'view-proof', 'view-proof-tab'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            hasFilters: function () {
                if (this.isAssetsPage() || this.isUsersPage() || this.isCollectionsPage() || this.isReportsPage()) {
                    return true;
                }
                if ((this.$route.name == 'asset-approvals') || (this.$route.name == 'pod-codes')) {
                    return true;
                }
                if ((this.$route.name == 'profile-groups') || (this.$route.name == 'artwork-approvals')) {
                    return true;
                }
                if ((this.$route.name == 'print-orders') || (this.$route.name == 'my-account-print-orders')) {
                    return true;
                }
                if (this.$route.name == 'proofs') {
                    return true;
                }

                return false;
            },
            showFilters: function () {
                setTimeout(function () {
                    $('body').addClass('right-bar-enabled');
                    setTimeout(function () {
                        $('#filter-by-keyword').focus();
                    }, 200);
                }, 100);
            },
            getFilters: function (group = null, filterKey = null) {
                if (this.isAssetsPage()) {
                    if (group == 'manual') {
                        let filters = [];
                        this.$root.asset_filters.forEach((item) => {
                            if (
                                (!this.$root.client.asset_type_filter_menu || (item.key != 'asset_type'))
                                && (!item.filter_menu || (item.key.substr(0, 2) != 'cf'))
                                && (item.key != 'folder')
                                && (item.key != 'subfolder')
                                && (item.key != 'sort_by')
                            ) {
                                filters.push(item);
                            }
                        });

                        return (filters.length > 0) ? filters : null;
                    }
                    if (group == 'asset_type') {
                        let filters = [];
                        this.$root.asset_filters.forEach((item) => {
                            if (this.$root.client.asset_type_filter_menu && (item.key == 'asset_type')) {
                                filters.push(item);
                            }
                        });

                        return (filters.length > 0) ? filters : null;
                    }
                    if (group == 'custom_field') {
                        if (filterKey) {
                            let filters = [];
                            this.$root.asset_filters.forEach((item) => {
                                if (item.filter_menu && (item.key == filterKey)) {
                                    filters.push(item);
                                }
                            });

                            return (filters.length > 0) ? filters : null;
                        }
                        let customFieldIds = [];
                        this.$root.asset_filters.forEach((item) => {
                            if (item.filter_menu && (item.key.substr(0, 2) == 'cf')) {
                                let customFieldId = parseInt(item.key.substr(2));
                                if (customFieldIds.indexOf(customFieldId) < 0) {
                                    customFieldIds.push(customFieldId);
                                }
                            }
                        });

                        return (customFieldIds.length > 0) ? customFieldIds : null;
                    }

                    return (this.$root.asset_filters.length > 0) ? this.$root.asset_filters : null;
                }
                if (group && (group != 'manual')) {
                    return null;
                }
                if (this.isUsersPage()) {
                    let filters = [];
                    this.$root.user_filters.forEach((item) => {
                        if (!item.hidden) {
                            filters.push(item);
                        }
                    });

                    return (filters.length > 0) ? filters : null;
                }
                if (this.$route.name == 'asset-approvals') {
                    return (this.$root.asset_approval_filters.length > 0) ? this.$root.asset_approval_filters : null;
                }
                if (this.$route.name == 'collections') {
                    let filters = [];
                    this.$root.collection_filters.forEach((item) => {
                        if (item.key != 'sort_by') {
                            filters.push(item);
                        }
                    });

                    return (filters.length > 0) ? filters : null;
                }
                if (this.isReportsPage()) {
                    return (this.$root.report_filters.length > 0) ? this.$root.report_filters : null;
                }
                if (this.$route.name == 'pod-codes') {
                    return (this.$root.pod_code_filters.length > 0) ? this.$root.pod_code_filters : null;
                }
                if (this.$route.name == 'profile-groups') {
                    return (this.$root.profile_group_filters.length > 0) ? this.$root.profile_group_filters : null;
                }
                if ((this.$route.name == 'print-orders') || (this.$route.name == 'my-account-print-orders')) {
                    return (this.$root.print_order_filters.length > 0) ? this.$root.print_order_filters : null;
                }
                if (this.$route.name == 'artwork-approvals') {
                    return (this.$root.artwork_approval_filters.length > 0) ? this.$root.artwork_approval_filters : null;
                }
                if (this.$route.name == 'proofs') {
                    let filters = [];
                    this.$root.proof_filters.forEach((item) => {
                        if (!item.hidden) {
                            filters.push(item);
                        }
                    });

                    return (filters.length > 0) ? filters : null;
                }

                return null;
            },
            isLastCustomFieldFilter: function (customFieldId) {
                const customFieldFilters = this.getFilters('custom_field');
                if (!customFieldFilters) {
                    return false;
                }

                return (customFieldFilters.indexOf(customFieldId) == (customFieldFilters.length - 1));
            },
            removeFilter: function(filter, inverse = false) {
                const filtersKey = filter.filters_key;
                const filterKey = filter.key;
                const filterId = filter.id;
                let filters = this.$root.$data[filtersKey];
                for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                    if (inverse) {
                        if ((filters[filterIndex].key == filterKey) && (filters[filterIndex].id != filterId)) {
                            filters.splice(filterIndex, 1);
                        }
                    } else {
                        if (filters[filterIndex].id == filterId) {
                            filters.splice(filterIndex, 1);
                        }
                    }
                }

                if (filtersKey == 'asset_filters') {
                    // If folder or subfolder remove any auto filters
                    if ((filterKey == 'folder') || (filterKey == 'subfolder')) {
                        for (let filterIndex = (filters.length - 1); filterIndex >= 0; filterIndex--) {
                            if (filters[filterIndex].auto) {
                                filters.splice(filterIndex, 1);
                            }
                        }
                    }

                    // If folder remove any subfolders
                    if (filterKey == 'folder') {
                        this.removeFilter({ id: -1, key: 'subfolder', filters_key: 'asset_filters' }, true);

                        return null;
                    }

                    // If asset type make the rest manual
                    if (filterKey == 'asset_type') {
                        filters.forEach((item) => {
                            if (item.key == 'asset_type') {
                                item.auto = false;
                            }
                        });
                    }

                    // If custom field make the rest manual
                    if (filterKey.substr(0, 2) == 'cf') {
                        filters.forEach((item) => {
                            if (item.key == filterKey) {
                                item.auto = false;
                            }
                        });
                    }
                }

                if (this.isAssetsPage()) {
                    const component = this.$root.findComponent(this.$parent, 'assets');
                    if (component) {
                        component.getAssets();
                        console.log('get assets: filter removed:', filterKey);
                    }
                }

                if (this.isUsersPage() && (filterKey != 'status')) {
                    const component = this.$root.findComponent(this.$parent, this.$route.name);
                    if (component) {
                        component.getUsers();
                    }
                }

                if (this.isCollectionsPage()) {
                    if (this.$route.name == 'collections') {
                        const component = this.$root.findComponent(this.$parent, 'collections');
                        if (component) {
                            component.getCollections();
                        }
                    } else {
                        const component = this.$root.findComponent(this.$parent, 'view-collection');
                        if (component) {
                            component.getAssets();
                        }
                    }
                }

                if ((this.$route.name == 'asset-approvals') && (filterKey != 'status')) {
                    const component = this.$root.findComponent(this.$parent, this.$route.name);
                    if (component) {
                        component.getApprovals();
                    }
                }

                if ((this.$route.name == 'artwork-approvals') && (filterKey != 'status')) {
                    const component = this.$root.findComponent(this.$parent, this.$route.name);
                    if (component) {
                        component.getArtworks();
                    }
                }

                if (this.$route.name == 'pod-codes') {
                    const component = this.$root.findComponent(this.$parent, this.$route.name);
                    if (component) {
                        component.getPodCodes();
                    }
                }

                if (this.$route.name == 'profile-groups') {
                    const component = this.$root.findComponent(this.$parent, this.$route.name);
                    if (component) {
                        component.getProfileGroups();
                    }
                }

                if (
                    (filterKey != 'archived')
                    && ((this.$route.name == 'print-orders') || (this.$route.name == 'my-account-print-orders'))
                ) {
                    const component = this.$root.findComponent(this.$parent, 'print-orders');
                    if (component) {
                        component.getOrders();
                    }
                }

                if (
                    this.isReportsPage()
                    && ((filterKey == 'id') || (filterKey == 'report_pg') || (filterKey == 'report_ug'))
                ) {
                    const component = this.$root.findComponent(this.$parent, 'reports');
                    if (component) {
                        component.getReport();
                    }
                }

                if (this.$route.name == 'proofs') {
                    if (filterKey == 'can_review') {
                        this.$cookie.delete(this.$root.$data.app.client_id + '_crp' + this.$root.$data.user.id);
                    }
                    const component = this.$root.findComponent(this.$parent, this.$route.name);
                    if (component) {
                        component.getProofs();
                    }
                }
            },
            filterClass: function (filter) {
                if (
                    (this.$root.client.asset_type_filter_menu && (filter.key == 'asset_type'))
                    || (filter.filter_menu && (filter.key.substr(0, 2) == 'cf'))
                ) {
                    return 'filter-text interactive';
                }
                return 'filter-text';
            },
            filterAction: function (filter) {
                if (
                    (this.$root.client.asset_type_filter_menu && (filter.key == 'asset_type'))
                    || (filter.filter_menu && (filter.key.substr(0, 2) == 'cf'))
                ) {
                    filter.auto = false;
                    this.removeFilter(filter, true);
                }
            },
            scrollFilters: function (direction, group) {
                const steps = Math.ceil($('.filters-bar.' + group).innerWidth() - 200);
                if (direction == 'right') {
                    $('.filters-bar.' + group).animate({ scrollLeft: '+=' + steps }, 700);

                    return null;
                }
                $('.filters-bar.' + group).animate({ scrollLeft: '-=' + steps }, 700);
            },
            scrollFiltersCheck: function () {
                const customFieldFilters = this.getFilters('custom_field');
                if (customFieldFilters) {
                    customFieldFilters.forEach((customFieldId) => {
                        if ($('.filters-bar.custom_field' + customFieldId)[0]) {
                            const a = $('.filters-bar.custom_field' + customFieldId)[0].scrollWidth;
                            const b = ($('.filters-bar.custom_field' + customFieldId).outerWidth() + 2);
                            if (a > b) {
                                $('.filters-bar.custom_field' + customFieldId).css({
                                    'padding-left': '15px',
                                    'padding-right': '15px'
                                });
                                $('.scroll-left.custom_field' + customFieldId).fadeIn('slow');
                                $('.scroll-right.custom_field' + customFieldId).fadeIn('slow');

                                return null;
                            }
                        }
                        $('.filters-bar.custom_field' + customFieldId).css({ 'padding-left': 0, 'padding-right': 0 });
                        $('.scroll-left.custom_field' + customFieldId).fadeOut('slow');
                        $('.scroll-right.custom_field' + customFieldId).fadeOut('slow');
                    });
                }
                if (this.getFilters('asset_type')) {
                    if ($('.filters-bar.asset_type')[0]) {
                        const a = $('.filters-bar.asset_type')[0].scrollWidth;
                        const b = ($('.filters-bar.asset_type').outerWidth() + 2);
                        if (a > b) {
                            $('.filters-bar.asset_type').css({ 'padding-left': '15px', 'padding-right': '15px' });
                            $('.scroll-left.asset_type').fadeIn('slow');
                            $('.scroll-right.asset_type').fadeIn('slow');

                            return null;
                        }
                    }
                    $('.filters-bar.asset_type').css({ 'padding-left': 0, 'padding-right': 0 });
                    $('.scroll-left.asset_type').fadeOut('slow');
                    $('.scroll-right.asset_type').fadeOut('slow');
                }
                if (this.getFilters('manual')) {
                    if ($('.filters-bar.manual')[0]) {
                        if ($('.filters-bar.manual')[0].scrollWidth > ($('.filters-bar.manual').outerWidth() + 2)) {
                            $('.filters-bar.manual').css({ 'padding-left': '15px', 'padding-right': '15px' });
                            $('.scroll-left.manual').fadeIn('slow');
                            $('.scroll-right.manual').fadeIn('slow');

                            return null;
                        }
                    }
                    $('.filters-bar.manual').css({ 'padding-left': 0, 'padding-right': 0 });
                    $('.scroll-left.manual').fadeOut('slow');
                    $('.scroll-right.manual').fadeOut('slow');
                }

                return false;
            },
            filtersScrollStyle: function (group, customFieldId = null) {
                if (group == 'custom_field') {
                    const customFieldFilterIndex = this.getFilters('custom_field').indexOf(customFieldId);
                    if (customFieldFilterIndex > 0) {
                        return 'top: ' + (customFieldFilterIndex * 30 + 5) + 'px';
                    }

                    return '';
                }
                if (group == 'asset_type') {
                    const customFieldFilters = this.getFilters('custom_field');
                    if (customFieldFilters) {
                        return 'top: ' + (customFieldFilters.length * 30 + 5) + 'px';
                    }

                    return '';
                }
                let top = 0;
                const customFieldFilters = this.getFilters('custom_field');
                if (customFieldFilters) {
                    top += (customFieldFilters.length * 30);
                }
                if (this.getFilters('asset_type')) {
                    top += 30;
                }
                if (top > 0) {
                    return 'top: ' + (top + 5) + 'px';
                }

                return '';
            },
            hasAddPermission: function () {
                if (
                    this.$root.hasPermission('assets_upload')
                    || (this.$root.hasPermission('templates_view') && this.$root.hasPermission('templates_upload'))
                ) {
                    return true;
                }
                if (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_view')) {
                    return true;
                }
                if (this.$root.hasPermission('proofs_create')) {
                    return true;
                }

                return false;
            },
            showSettings: function () {
                if (this.$root.hasPermission('content_edit') || this.$root.hasPermission('setting_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('homepage_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_asset_types')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_custom_fields')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_keyword_assistant')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_manage_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_manage_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_approval_system')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_review')) {
                    return true;
                }
                if (this.$root.hasPermission('users_invite')) {
                    return true;
                }
                if (this.$root.hasPermission('users_view_details')) {
                    return true;
                }
                if (this.$root.hasPermission('users_user_groups')) {
                    return true;
                }
                if (this.$root.hasPermission('users_pre_approved_email_domains')) {
                    return true;
                }
                if (this.$root.hasPermission('users_manage_alerts')) {
                    return true;
                }
                if (this.$root.hasPermission('reports_users') || this.$root.hasPermission('reports_overview')) {
                    return true;
                }
                if (this.$root.hasPermission('reports_assets') || this.$root.hasPermission('reports_flyte')) {
                    return true;
                }
                if (this.$root.hasPermission('reports_templates') || this.$root.hasPermission('reports_print_orders')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_manage_resourcepage')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_delete')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_delete')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_delete')) {
                    return true;
                }
                if (this.$root.isFeatured('printing')) {
                    if (this.$root.hasPermission('printing_manage_suppliers') ) {
                        return true;
                    }
                    if (this.$root.hasPermission('printing_manage_price_panels')) {
                        return true;
                    }
                    if (this.$root.hasPermission('printing_manage_custom_fields')) {
                        return true;
                    }
                    if (this.$root.hasPermission('printing_orders_view')) {
                        return true;
                    }
                    if (this.$root.hasPermission('printing_view_archived_orders')) {
                        return true;
                    }
                    if (this.$root.hasPermission('printing_financial_approvals')) {
                        return true;
                    }
                    if (this.$root.hasPermission('printing_onscreen_confirmation')) {
                        return true;
                    }
                    if (this.$root.hasPermission('printing_email_recipients')) {
                        return true;
                    }
                }
                if (this.$root.hasPermission('templates_brand_approvals')) {
                    return true;
                }
                if (this.$root.hasPermission('proofs_view_archive')) {
                    return true;
                }

                return false;
            },
            statusBarClass: function () {
                // No longer need to colour active collection bar. R2-232
                /*if (
                    (this.$route.name == 'view-collection')
                    && (this.$root.current_collection == this.$route.params.id)
                ) {
                    return 'status-bar active';
                }*/

                return 'status-bar';
            },
            doNothing: function () {
                return null;
            },
            getNotifications: function (type = 'user') {
                /**
                 * Send get notifications request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/notifications/tp::' + type;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.notifications[response.data.type] = response.data.notifications.data;
                    if (response.data.notifications.total == 0) {
                        vm.notifications[response.data.type + '_unread'] = 0;

                        return null;
                    }
                    vm.getUnreadNotificationsCount(response.data.type);
                    /*if (response.data.type == 'user') {
                        vm.notifications.user = response.data.notifications.data;

                        return null;
                    }
                    vm.notifications.admin = response.data.notifications.data;*/
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 503) {
                            vm.$root.showMaintenanceMessage(error.response.data.message);
                        }
                    }
                });

                if ((type == 'user') && this.showSettings()) {
                    this.getNotifications('admin');
                }
            },
            getUnreadNotificationsCount: function (type) {
                /**
                 * Send get notifications request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/notifications/count|!read|tp::'
                    + type;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.notifications[response.data.type + '_unread'] = response.data.notifications;
                    /*if (response.data.type == 'user') {
                        vm.notifications.user_unread = response.data.notifications;

                        return null;
                    }
                    vm.notifications.admin_unread = response.data.notifications;*/
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            clearNotification: function (type, id, action = null, itemId = null) {
                if ((id == 0) && !action) {
                    if (type != 'user') {
                        this.notifications.admin = [];
                        this.notifications.admin_unread = 0;
                    }
                    if (type != 'admin') {
                        this.notifications.user = [];
                        this.notifications.user_unread = 0;
                    }
                    /**
                     * Send delete all notifications request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/notifications/' + type;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        if (vm.$route.name == 'notifications') {
                            const component = vm.$root.findComponent(vm.$parent, 'notifications');
                            if (component) {
                                component.getNotifications();
                            }
                        }
                    })
                    .catch(function (error) {
                        // Do anything?
                    });

                    return null;
                }
                const notifications = this.notifications[type];
                let notificationId = null;
                for (let n = (notifications.length - 1); n >= 0; n--) {
                    if (
                        (notifications[n].id == id)
                        || ((notifications[n].action == action) && (notifications[n].item_id == itemId))
                    ) {
                        notificationId = notifications[n].id;
                        if (!notifications[n].read) {
                            this.notifications[type + '_unread']--;
                        }
                        notifications.splice(n, 1);
                    }
                }
                if (notificationId && (this.$route.name == 'notifications')) {
                    const component = this.$root.findComponent(this.$parent, 'notifications');
                    if (component) {
                        for (let n = (component.items.length - 1); n >= 0; n--) {
                            if (component.items[n].id == notificationId) {
                                component.items.splice(n, 1);
                                component.total_items--;
                            }
                        }
                    }
                }

                /**
                 * Send delete notification request to API
                 */
                const path = notificationId ? notificationId : '0/' + action + '/' + itemId;
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/notification/' + path;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    /*if (vm.$route.name == 'notifications') {
                        const component = vm.$root.findComponent(vm.$parent, 'notifications');
                        if (component) {
                            component.getNotifications();
                        }
                    }*/
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            markNotificationAsRead: function (type, id, action = null, itemId = null) {
                const notifications = this.notifications[type];
                if (notifications.length == 0) {
                    return null;
                }
                let notificationId = null;
                let alreadyRead = false;
                for (let n = (notifications.length - 1); n >= 0; n--) {
                    if (
                        (notifications[n].id == id)
                        || ((notifications[n].action == action) && (notifications[n].item_id == itemId))
                    ) {
                        notificationId = notifications[n].id;
                        if (!notifications[n].read) {
                            this.notifications[type + '_unread']--;
                            notifications[n].read = 1;
                        } else {
                            alreadyRead = true;
                        }
                    }
                }
                if (alreadyRead) {
                    return null;
                }
                if (notificationId && (this.$route.name == 'notifications')) {
                    const component = this.$root.findComponent(this.$parent, 'notifications');
                    if (component) {
                        for (let n = (component.items.length - 1); n >= 0; n--) {
                            if (component.items[n].id == notificationId) {
                                component.items[n].read = 1;
                            }
                        }
                    }
                }

                /**
                 * Send mark notification as read request to API.
                 */
                const path = notificationId ? notificationId : '0/' + action + '/' + itemId;
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/notification/mark-as-read/'
                    + path;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    /*if (vm.$route.name == 'notifications') {
                        const component = vm.$root.findComponent(vm.$parent, 'notifications');
                        if (component) {
                            component.getNotifications();
                        }
                    }*/
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            markAllNotificationsAsRead: function (type) {
                if (!this.notifications[type]) {
                    return null;
                }
                this.notifications[type].forEach((item) => {
                    if (!item.read) {
                        this.markNotificationAsRead(type, item.id);
                    }
                    //console.log('mark as read', item);
                });
            },
            resetSupportForm: function () {
                const component = this.$root.findComponent(this.$parent, 'email-support');
                if (component) {
                    component.resetForm();
                }
            },
            isArchive: function () {
                if (!this.$route.params.filters) {
                    return false;
                }
                let archivedAssets = false;
                const params = this.$route.params.filters.split('|');
                params.forEach((item) => {
                    if (item == 'archived') {
                        archivedAssets = true;
                    }
                });

                return archivedAssets;
            },
            closeFullScreenPanel: function (redirectTo = null) {
                if (this.$root.editing_artwork) {
                    this.$parent.hideArtworkEditor();

                    return null;
                }
                if (this.$root.adding_asset) {
                    const component = this.$root.findComponent(this.$parent, 'asset-add-form');
                    if (component) {
                        component.onClose(false, redirectTo);
                    }

                    return null;
                }
                if (this.$root.adding_template) {
                    const component = this.$root.findComponent(this.$parent, 'template-add-form');
                    if (component) {
                        component.onClose(false, redirectTo);
                    }

                    return null;
                }
            },
            navigateTo: function (route) {
                if (this.$route.name == route.name) {
                    // Already there - do nothing.

                    return null;
                }
                if (this.$root.adding_asset || this.$root.adding_template) {
                    this.closeFullScreenPanel(route);

                    return null;
                }
                if (this.$root.editing_artwork) {
                    this.$parent.hideArtworkEditor();
                }
                if (route.name == 'logout') {
                    this.logout();

                    return null;
                }
                this.$router.push(route);
            },
            canSearchAssets: function () {
                if (this.isFullScreenPanel()) {
                    return false;
                }
                if (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_access')) {
                    return true;
                }

                return false;
            },
            canViewCollections: function () {
                if (this.isFullScreenPanel()) {
                    return false;
                }
                
                if (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_view')) {
                    return true;
                }

                return false;
            },
            canViewProofs: function () {
                if (this.isFullScreenPanel()) {
                    return false;
                }
                
                return this.$root.hasPermission('proofs_access');
            },
            viewProofs: function () {
                this.$root.proof_filters = [];
                if (this.$route.name == 'proofs') {
                    const component = this.$root.findComponent(this.$parent, 'proofs');
                    if (component) {
                        component.getProofs();
                        this.$root.hideMainNavDropdowns();
                    }

                    return null;
                }
                this.$router.push({ name: 'proofs' });
            },
            canViewPodCodes: function () {
                if (this.isFullScreenPanel()) {
                    return false;
                }
                
                return this.$root.hasPermission('podcodes_access');
            },
            canSendFlyte: function () {
                if (this.isFullScreenPanel()) {
                    return false;
                }
                
                return this.$root.hasPermission('flyte_access');
            },
            canEmailSupport: function () {
                if (this.isFullScreenPanel()) {
                    return false;
                }
                
                return this.$root.client.show_support_link;
            },
            canAddSomething: function () {
                if (this.isFullScreenPanel()) {
                    return false;
                }
                if (this.$root.hasPermission('homepage_edit') || this.$root.hasPermission('assets_upload')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_view') && this.$root.hasPermission('templates_upload')) {
                    return true;
                }
                if (this.$root.hasPermission('proofs_create')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_view')) {
                    return true;
                }
                if (this.$root.hasPermission('users_invite') || this.$root.hasPermission('profiles_groups_add')) {
                    return true;
                }
                
                return false;
            },
            isFullScreenPanel: function () {
                if (this.$root.adding_asset || this.$root.adding_template || this.$root.editing_artwork) {
                    return true;
                }

                return false;
            },
            canShareFilters: function () {
                if (this.$route.name !== 'assets') {
                    return false;
                }
                if (!this.$root.status_info.right || !this.hasFilters()) {
                    return false;
                }
                if (this.$root.asset_filters.length == 0) {
                    return false;
                }
                let hasManualFilter = false;
                let hasReverseImageSearch = false;
                this.$root.asset_filters.forEach((item) => {
                    if (!item.auto && ((item.key != 'sort_by') || (item.value != 'newest'))) {
                        hasManualFilter = true;
                    }
                    if (item.key == 'reverse-image-search') {
                        hasReverseImageSearch = true;
                    }
                });
                if (hasReverseImageSearch) {
                    return false;
                }

                return hasManualFilter;
            },
            resetShareFilters: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const assetsComponent = this.$root.findComponent(appComponent, 'assets');
                const component = this.$root.findComponent(assetsComponent, 'filter-share');
                component.reset();
            },
            canSelectAll: function (returnComponent = false) {
                if (this.$route.name !== 'assets') {
                    return false;
                }
                if (!this.$root.status_info.right || !this.hasFilters()) {
                    return false;
                }
                if (this.$root.current_collection) {
                    return false;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'assets');
                if (!component) {
                    return false;
                }
                if (component.select_all_page || (component.select_all_asset_ids.length == 0)) {
                    return (component.total_items > 1000);
                }
                if (component.total_items < 2) {
                    return false;
                }

                return returnComponent ? component : true;
            },
            isAllSelected: function () {
                let assetsComponent = this.canSelectAll(true);
                if (!assetsComponent) {
                    return false;
                }
                if (!assetsComponent.select_all_asset_ids) {
                    return false;
                }
                let allSelected = true;
                assetsComponent.select_all_asset_ids.forEach((assetId) => {
                    if (this.$root.selected_assets.indexOf(assetId) < 0) {
                        allSelected = false;
                    }
                });

                return allSelected;
            },
            selectAll: function () {
                $('.select-all-button').blur();
                let assetsComponent = this.canSelectAll(true);
                if (!assetsComponent) {
                    return null;
                }
                const deselect = this.isAllSelected();
                if (!deselect && (assetsComponent.total_items > 1000)) {
                    this.$parent.message = {
                        type: null,
                        title: null,
                        text: this.$root.text(
                            'The number of results you are trying to select exceeds the maximum 1000.',
                            null,
                            true
                        )
                    };
                    $('#message-modal').modal('show');

                    return null;
                }
                assetsComponent.selectAll(deselect);
            },
            statusInfoLeftClass: function () {
                let className = 'col-lg-1' + (this.$root.is_xl_screen ? '1' : '0') + ' col-md-10 col-sm-9';
                if (this.hasFilters() && !this.hasFilterBar()) {
                    className = 'col-lg-9 col-md-8 col-sm-7';
                }
                if (this.canSelectAll() && this.canShareFilters()) {
                    className = 'col-lg-6 col-md-5';
                } else if (this.canSelectAll() || this.canShareFilters()) {
                    className = 'col-lg-7 col-md-6';
                }

                return className + ' pl-3 pr-2 pt-' + (this.hasFilterBar() ? '1' : '2');
            },
            hasFilterBar: function () {
                const pages = [
                    'users',
                    'deletion-requests',
                    'profile-groups',
                    'artwork-approvals',
                    'print-orders',
                    'my-account-print-orders',
                    'asset-approvals'
                ];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isComponentLoaded: function (componentName) {
                if (this.$root.findComponent(this.$parent, componentName)) {
                    return true;
                }

                return false;
            },
            hasProfile: function () {
                if (!this.$root.user) {
                    return false;
                }
                if (!this.$root.isFeatured('profiles')) {
                    return false;
                }
                if (!this.$root.user.profile_id) {
                    return false;
                }
                if (!this.profile_group_loading && !this.profile_group_id) {
                    return false;
                }

                return true;
            },
            hasProfileGroup: function () {
                return (this.hasProfile() && this.profile_group_id);
            },
            getProfileGroup: function () {
                this.profile_group_loading = true;
                /**
                 * Send get profile request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile/'
                    + this.$root.user.profile_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_group_id = response.data.profile.profile_group_id;
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() =>
                    vm.profile_group_loading = false
                );
            }
        }
    }
</script>
<style scoped>
    .btn-light:disabled {
        background-color: transparent !important;
    }

    span.interactive {
        cursor: pointer;
    }

    span.filters-bar {
        position: relative;
        max-width: 100%;
        overflow-x: hidden;
        /*height: 29px;*/
        white-space: nowrap;
        display: inline-block;
    }

    span.scroll-left {
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: rgba(239, 242, 247, 0.8);
        padding-left: 5px;
        padding-right: 10px;
        cursor: pointer;
        display: none;
    }
    
    span.scroll-right {
        position: absolute;
        top: 5px;
        right: 0px;
        background-color: rgba(239, 242, 247, 0.8);
        padding-left: 10px;
        padding-right: 5px;
        cursor: pointer;
        display: none;
    }

    .admin-notifications {
        display: block;
        min-width: 19px;
        height: 19px;
        padding-left: 2px;
        padding-right: 2px;
        border-radius: 50%;
        position: relative;
        top: -2px;
        line-height: 1.6;
    }

    .search-our-resources {
        background-color: rgba(255,255,255, 0.18);
        padding: 4px 10px;
        border-radius: 10px;
    }

    .reverse-image-search-thumbnail {
        position: absolute;
        top: -16px;
        left: 0;
        width: 40px;
        height: 23px;
        background-color: white;
    }

    .reverse-image-search-thumbnail .img-fluid {
        max-height: 23px;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
    }

    @media (max-width: 767px) {
        span.filters-bar {
            overflow-x: auto;
        }
    }
</style>
