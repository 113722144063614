<template>
    <div>
        <div class="proofs-container">
            <div class="proofs-grid">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
                    {{ $root.text('There are no proofs that match your selection', null, true) }}
                </div>

                <div v-if="(items.length > 0)" class="row">
                    <div
                        v-for="item in items"
                        :key="item.id"
                        :class="($root.is_3xl_screen ? 'col-xl-3 ' : '') + ($root.is_xl_screen ? 'col-lg-4 ' : '')
                            +'col-md-6'"
                    >
                        <proof-preview v-if="(!isFoldersView() || !item.proof_folder_id)" :data="item"></proof-preview>
                        <proof-folder-preview v-else :data="item"></proof-folder-preview>
                    </div>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <proof-filters :proof_filters="$root.proof_filters" :proof_users="result_proof_users"></proof-filters>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'proofs',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                result_proof_users: []
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            
            if (!this.$root.hasPermission('proofs_access')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            this.getProofs();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            total_items: function (val) {
                this.$root.status_info.right = (val == 1)
                    ? this.$root.text('1 proof')
                    : this.$root.text('{x} proofs', {x: val });
            }
        },
        methods: {
            getProofs: function (page = 1, force = false) {
                if ((this.loading && !force) || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                var vm = this;
                this.current_page = page;
                this.loading = true;
                this.errors = false;
                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.result_proof_users = [];
                }

                let filters = [this.isArchive() ? 'archived' : '!archived'];
                if (this.isFoldersView()) {
                    filters.push('folders');
                }
                let filteredByUser = false;
                let filteredByCanReview = false;
                let sorted = false;
                this.$root.proof_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                    if (item.key == 'folder') {
                        filters.push('fr::' + item.value);
                    }
                    if (item.key == 'user') {
                        if (item.value > 0) {
                            filters.push('pu::' + item.value);
                        }
                        filteredByUser = true;
                    }
                    if (item.key == 'can_review') {
                        filteredByCanReview = item.value;
                        if (item.value == 1) {
                            filters.push('can-review');
                        }
                    }
                    if (item.key == 'date_range') {
                        filters.push('dr::' + item.value);
                    }
                    if (item.key == 'sort_by') {
                        filters.push('sb::' + item.value);
                        sorted = true;
                    }
                });
                if (this.$root.hasPermission('proofs_view_others') && !filteredByUser) {
                    // Let the filters bar load proofs.
                    // Jira R2-632 - don't do this any more.
                    setTimeout(function () {
                        vm.$root.addFilter('proof_filters', {
                            key: 'user',
                            value: vm.$root.user.id,
                            text: vm.$root.user.first_name.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + ' '
                                + vm.$root.user.last_name.replace(/"/g, '&quot;').replace(/'/g, '&apos;'),
                            filters_key: 'proof_filters',
                            hidden: true
                        });
                    }, 200);
                    
                    return null;
                }
                if (filteredByCanReview === false) {
                    filteredByCanReview = parseInt(
                        this.$cookie.get(this.$root.$data.app.client_id + '_crp' + this.$root.$data.user.id) || 2
                    );
                    if (filteredByCanReview == 1) {
                        filters.push('can-review');
                        this.$root.addFilter('proof_filters', {
                            key: 'can_review',
                            value: 1,
                            text: this.$root.text('Proofs awaiting my review', null, true),
                            filters_key: 'proof_filters'
                        });
                    }
                }
                this.$root.setCookie(
                    this.$root.app.client_id + '_crp' + this.$root.$data.user.id,
                    filteredByCanReview,
                    '1Y'
                );
                if (!sorted) {
                    filters.push('sb::created_desc');
                }
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                /**
                 * Send get proofs request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proofs' + filtersString
                    + '?page=' + page;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.proofs.total;
                    vm.current_page = response.data.proofs.current_page;
                    vm.last_page = response.data.proofs.last_page;
                    response.data.proofs.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.items.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.items.push(item);
                        }
                    });
                    if (response.data.proof_users) {
                        vm.result_proof_users = response.data.proof_users;
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getProofs(this.current_page + 1);
                    }
                }
            },
            isArchive: function () {
                let found = false;
                this.$root.proof_filters.forEach((item) => {
                    if (item.key == 'archived') {
                        found = true;
                    }
                });

                return found;
            },
            isFolderView: function () {
                let found = false;
                this.$root.proof_filters.forEach((item) => {
                    if (item.key == 'folder') {
                        found = true;
                    }
                });

                return found;
            },
            isFoldersView: function () {
                if (this.isFolderView()) {
                    return false;
                }
                if (!this.$root.client) {
                    return false;
                }

                return (this.$root.client.proofs_view != 'proofs');
            }
        }
    }
</script>
