<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <loading v-if="loading"></loading>

        <div v-if="(!errors && !loading)">
            <div v-if="(report == 'overview')" class="row">
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-center">{{ report_title.registrations }} = {{ total.registrations }}</p>
                            <bar-chart
                                :data="chart_data.registrations"
                                :options="chart_options"
                                style="height: 200px"
                            ></bar-chart>
                            <p v-if="date_range_display.registrations" class="text-center mt-3 mb-0">
                                {{ date_range_display.registrations }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-center">{{ report_title.visits }} = {{ total.visits }}</p>
                            <bar-chart
                                :data="chart_data.visits"
                                :options="chart_options"
                                style="height: 200px"
                            ></bar-chart>
                            <p v-if="date_range_display.visits" class="text-center mt-3 mb-0">
                                {{ date_range_display.visits }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-center">{{ report_title.uploads }} = {{ total.uploads }}</p>
                            <bar-chart
                                :data="chart_data.uploads"
                                :options="chart_options"
                                style="height: 200px"
                            ></bar-chart>
                            <p v-if="date_range_display.uploads" class="text-center mt-3 mb-0">
                                {{ date_range_display.uploads }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="card">
                        <div class="card-body">
                            <p class="text-center">{{ report_title.downloads }} = {{ total.downloads }}</p>
                            <bar-chart
                                :data="chart_data.downloads"
                                :options="chart_options"
                                style="height: 200px"
                            ></bar-chart>
                            <p v-if="date_range_display.downloads" class="text-center mt-3 mb-0">
                                {{ date_range_display.downloads }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-lg-2 col-md-3 col-4">
                            <button v-if="prev_date" class="btn btn-link p-0" @click="setDateRange(prev_date)">
                                <i class="far fa-chevron-left"></i>
                                <span style="position: relative; top: -1px">
                                    {{ $root.text('Previous', null, true) }}
                                </span>
                            </button>
                        </div>
                        <div class="col">
                            <p v-if="report == 'templates'" class="text-center">
                                {{ $root.text('Total', null, true) }}
                                <span v-if="hasType('orders')">
                                    {{ report_title.orders }} = {{ total.orders }}<span v-if="!hasType()">,</span>
                                </span>
                                <span v-if="hasType('downloads')">
                                    {{ report_title.downloads }} = {{ total.downloads }}<span v-if="!hasType()">,</span>
                                </span>
                                <span v-if="hasType('shares')">
                                    {{ report_title.shares }} = {{ total.shares }}
                                </span>
                                {{ report_title.period }}
                            </p>
                            <p v-else class="text-center">{{ report_title }} = {{ total }}</p>
                        </div>
                        <div class="col-lg-2 col-md-3 col-4 text-right">
                            <button v-if="next_date" class="btn btn-link p-0" @click="setDateRange(next_date)">
                                <span style="position: relative; top: -1px">{{ $root.text('Next', null, true) }}</span>
                                <i class="far fa-chevron-right"></i>
                            </button>
                        </div>
                    </div>
                    <bar-chart :data="chart_data" :options="chart_options" style="height: 300px"></bar-chart>
                    <p v-if="date_range_display" class="text-center mt-3 mb-0">{{ date_range_display }}</p>
                </div>
            </div>
        </div>

        <div v-if="(report_data.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table v-if="(report == 'registrations')" class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Name', null, true) }}
                                        </div>
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text($root.contents.company_title, null, true, 'company_title') }}
                                        </div>
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Date registered', null, true) }}
                                        </div>
                                        <div class="col-sm-2 col-12">{{ $root.text('Role', null, true) }}</div>
                                        <div class="col-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in report_data" :key="item.id">
                                <td scope="row" :id="'report-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.id)"
                                                >
                                                    {{ item.first_name + ' ' + item.last_name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.company }}
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.created_at_formatted }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.role_name }}
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.id)"
                                                >
                                                    {{ item.first_name + ' ' + item.last_name }}
                                                </a>
                                            </h5>
                                            <p class="mb-1">{{ item.company }}</p>
                                            <p class="mb-1">{{ item.created_at_formatted }}</p>
                                            <p class="mb-1">{{ item.role_name }}</p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewDetails(item.id)"
                                            >
                                                <span
                                                    v-if="(report_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'report-details' + item.id">
                                            <report-details
                                                :report="report"
                                                :details="item.id"
                                                :visible="(report_details_open.indexOf(item.id) > -1)"
                                                :profile_groups="profile_groups"
                                            ></report-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-if="(report == 'visits')" class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-4 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Name', null, true) }}
                                        </div>
                                        <div class="col-sm-5 col-12 pb-sm-0 pb-1">
                                            {{ $root.text($root.contents.company_title, null, true, 'company_title') }}
                                        </div>
                                        <div :class="'col-sm-' + (show_details ? '2' : '3') + ' col-12'">
                                            {{ $root.text(show_details ? 'Visits' : 'Date', null, true) }}
                                        </div>
                                        <div v-if="show_details" class="col-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="group_item in report_data" :key="group_item.id">
                                <td scope="row" :id="'report-row' + group_item.id" class="pb-0">
                                    <div class="row">
                                        <div
                                            class="col-4 d-none d-sm-block"
                                            :style="'padding-top: 3px' + (show_details ? '' : '; padding-bottom: 3px')"
                                        >
                                            <h5 v-if="show_details" class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(group_item.id)"
                                                >
                                                    {{ group_item.first_name + ' ' + group_item.last_name }}
                                                </a>
                                            </h5>
                                            <span v-else>{{ group_item.first_name + ' ' + group_item.last_name }}</span>
                                        </div>
                                        <div
                                            class="col-5 d-none d-sm-block"
                                            :style="'padding-top: 3px' + (show_details ? '' : '; padding-bottom: 3px')"
                                        >
                                            {{ group_item.company }}
                                        </div>
                                        <div
                                            :class="'col-' + (show_details ? '2' : '3') + ' d-none d-sm-block'"
                                            :style="'padding-top: 3px' + (show_details ? '' : '; padding-bottom: 3px')"
                                        >
                                            {{ show_details ? group_item.items.length
                                                : group_item.items[0].created_at_formatted }}
                                        </div>
                                        <div :class="'col-' + (show_details ? '10' : '12') + ' d-sm-none'">
                                            <h5 v-if="show_details" class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(group_item.id)"
                                                >
                                                    {{ group_item.first_name + ' ' + group_item.last_name }}
                                                </a>
                                            </h5>
                                            <p v-else class="mb-1">
                                                {{ group_item.first_name + ' ' + group_item.last_name }}
                                            </p>
                                            <p class="mb-1">{{ group_item.company }}</p>
                                            <p class="mb-1">{{ group_item.items.length }}</p>
                                        </div>
                                        <div v-if="show_details" class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewDetails(group_item.id)"
                                            >
                                                <span
                                                    v-if="(report_details_open.indexOf(group_item.id) < 0)"
                                                    :key="'close-' + group_item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + group_item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'report-details' + group_item.id">
                                            <report-details
                                                v-if="show_details"
                                                :report="report"
                                                :details="group_item.items"
                                                :visible="(report_details_open.indexOf(group_item.id) > -1)"
                                            ></report-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-if="(report == 'asset-uploads')" class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Title', null, true) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                '{resource} No.',
                                                {
                                                    resource: $root.text(
                                                        $root.contents.asset_title,
                                                        null,
                                                        true,
                                                        'asset_title'
                                                    )
                                                },
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                '{resource} type',
                                                {
                                                    resource: $root.text(
                                                        $root.contents.asset_title,
                                                        null,
                                                        true,
                                                        'asset_title'
                                                    )
                                                },
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Added by', null, true) }}
                                        </div>
                                        <div class="col-sm-2 col-12">{{ $root.text('Date added', null, true) }}</div>
                                        <div class="col-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in report_data" :key="item.id">
                                <td scope="row" :id="'report-row' + item.id">
                                    <div class="row">
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.title }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.id }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.asset_type_name }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.first_name }} {{ item.last_name }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.created_at_formatted }}
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <p class="mb-1">{{ item.title }}</p>
                                            <p class="mb-1">{{ item.id }}</p>
                                            <p class="mb-1">{{ item.asset_type_name }}</p>
                                            <p class="mb-1">{{ item.first_name }} {{ item.last_name }}</p>
                                            <p class="mb-1">{{ item.created_at_formatted }}</p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                v-if="!item.deleted
                                                    && (!item.archived || $root.hasPermission('assets_manage_archive')
                                                    || $root.hasPermission('templates_manage_archive'))"
                                                href="#"
                                                class="float-right"
                                                @click.prevent="viewAsset(item)"
                                                v-b-tooltip.hover
                                                :title="$root.text(
                                                    'View {resource}',
                                                    {
                                                        resource: $root.text(
                                                            $root.contents.asset_title,
                                                            null,
                                                            true,
                                                            'asset_title'
                                                        )
                                                    },
                                                    true
                                                )"
                                            >
                                                <i class="fa-regular fa-eye"></i>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-if="(report == 'asset-downloads')" class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-4 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Title', null, true) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                '{resource} No.',
                                                {
                                                    resource: $root.text(
                                                        $root.contents.asset_title,
                                                        null,
                                                        true,
                                                        'asset_title'
                                                    )
                                                },
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                '{resource} type',
                                                {
                                                    resource: $root.text(
                                                        $root.contents.asset_title,
                                                        null,
                                                        true,
                                                        'asset_title'
                                                    )
                                                },
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-sm-2 col-12">{{ $root.text('Downloads', null, true) }}</div>
                                        <div class="col-2 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in report_data" :key="item.id">
                                <td scope="row" :id="'report-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-4 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.id)"
                                                >
                                                    {{ item.title }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.id }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.asset_type_name }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            <span v-if="item.total == 1">{{ $root.text('1 download') }}</span>
                                            <span v-else>{{ $root.text('{x} downloads', { x: item.total }) }}</span>
                                        </div>
                                        <div class="col-9 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.id)"
                                                >
                                                    {{ item.title }}
                                                </a>
                                            </h5>
                                            <p class="mb-1">{{ item.id }}</p>
                                            <p class="mb-1">{{ item.asset_type_name }}</p>
                                            <p class="mb-1">
                                                <span v-if="item.total == 1">{{ $root.text('1 download') }}</span>
                                                <span v-else>{{ $root.text('{x} downloads', { x: item.total }) }}</span>
                                            </p>
                                        </div>
                                        <div class="col-sm-2 col-3 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewDetails(item.id)"
                                            >
                                                <span
                                                    v-if="(report_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                            <a
                                                v-if="!item.deleted
                                                    && (!item.archived || $root.hasPermission('assets_archive'))"
                                                href="#"
                                                class="float-right mr-3"
                                                @click.prevent="viewAsset(item)"
                                                v-b-tooltip.hover
                                                :title="$root.text(
                                                    'View {resource}',
                                                    {
                                                        resource: $root.text(
                                                            $root.contents.asset_title,
                                                            null,
                                                            true,
                                                            'asset_title'
                                                        )
                                                    },
                                                    true
                                                )"
                                            >
                                                <i class="fa-regular fa-eye"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'report-details' + item.id">
                                            <report-details
                                                :report="report"
                                                :details="item.items"
                                                :visible="(report_details_open.indexOf(item.id) > -1)"
                                            ></report-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-if="(report == 'artwork-created')" class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-4 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                '{template} title',
                                                $root.text($root.contents.template_title, null, false, 'template_title'),
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                '{template} No.',
                                                $root.text($root.contents.template_title, null, false, 'template_title'),
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                '{resource} type',
                                                $root.text($root.contents.asset_title, null, false, 'asset_title'),
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-sm-2 col-12">
                                            {{ $root.text(
                                                '{templates} created',
                                                $root.text(
                                                    $root.contents.template_title + 's',
                                                    null,
                                                    false,
                                                    'templates_title'
                                                ),
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in report_data" :key="item.asset_id">
                                <td scope="row" :id="'report-row' + item.asset_id" class="pb-0">
                                    <div class="row">
                                        <div class="col-4 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.asset_id)"
                                                >
                                                    {{ item.asset_title }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.asset_id }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.asset_type_name }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ $root.text('{x} created', { x: item.total }) }}
                                        </div>
                                        <div class="col-9 d-sm-none">
                                            <h5 v-if="show_details" class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.asset_id)"
                                                >
                                                    {{ item.asset_title }}
                                                </a>
                                            </h5>
                                            <p class="mb-1">{{ item.asset_id }}</p>
                                            <p class="mb-1">{{ item.asset_type_name }}</p>
                                            <p class="mb-1">{{ $root.text('{x} created', { x: item.total }) }}</p>
                                        </div>
                                        <div class="col-sm-2 col-3 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewDetails(item.asset_id)"
                                            >
                                                <span
                                                    v-if="(report_details_open.indexOf(item.asset_id) < 0)"
                                                    :key="'close-' + item.asset_id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.asset_id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                            <a
                                                v-if="!item.asset_deleted && 
                                                    (!item.asset_archived || $root.hasPermission('templates_archive'))"
                                                href="#"
                                                class="float-right mr-3"
                                                @click.prevent="viewAsset(
                                                    { id: item.asset_id, archived: item.asset_archived }
                                                )"
                                                v-b-tooltip.hover
                                                :title="$root.text(
                                                    'View {template}',
                                                    {
                                                        template: $root.text(
                                                            $root.contents.template_title,
                                                            null,
                                                            false,
                                                            'template_title'
                                                        )
                                                    }
                                                )"
                                            >
                                                <i class="fa-regular fa-eye"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'report-details' + item.asset_id">
                                            <report-details
                                                :report="report"
                                                :details="item.items"
                                                :visible="(report_details_open.indexOf(item.asset_id) > -1)"
                                            ></report-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-if="(report == 'templates')" class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Type', null, true) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Date', null, true) }}
                                        </div>
                                        <div v-if="$root.isFeatured('profiles')" class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                $root.contents.profile_group_title,
                                                null,
                                                true,
                                                'profile_group_title'
                                            ) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('User', null, true) }}
                                        </div>
                                        <div
                                            :class="'col-sm-' + ($root.isFeatured('profiles') ? 2 : 3)
                                                + ' col-12 pb-sm-0 pb-1'"
                                        >
                                            {{ $root.text($root.contents.template_title, null, true, 'template_title') }}
                                        </div>
                                        <div :class="'col-sm-' + ($root.isFeatured('profiles') ? 2 : 3) + ' col-12'">
                                            {{ $root.text($root.contents.folder_title, null, true, 'folder_title') }}
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in report_data" :key="item.type + item.id">
                                <td scope="row">
                                    <div class="row">
                                        <div class="col-2 d-none d-sm-block">
                                            {{ (item.type == 'share')
                                                ? $root.text('Email', null, true)
                                                : $root.text(item.type, null, true)
                                            }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block">
                                            {{ formatDate(item.created_at) }}
                                        </div>
                                        <div v-if="$root.isFeatured('profiles')" class="col-2 d-none d-sm-block">
                                            {{ item.profile_group_name }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block">
                                            {{ item.first_name + ' ' + item.last_name }}
                                        </div>
                                        <div
                                            :class="'col-' + ($root.isFeatured('profiles') ? 2 : 3)
                                                + ' d-none d-sm-block'"
                                        >
                                            {{ $root.text('No.', null, true) }} {{ item.asset_id }}
                                            <br>{{ item.resource_title }}
                                        </div>
                                        <div
                                            :class="'col-' + ($root.isFeatured('profiles') ? 2 : 3)
                                                + ' d-none d-sm-block'"
                                        >
                                            <p class="mb-0">{{ item.folder_name }}</p>
                                            <p v-if="item.subfolders" class="small mb-0">
                                                {{ $root.text(
                                                    '{subfolder}:',
                                                    { 
                                                        subfolder: $root.text(
                                                            $root.contents.subfolder_title,
                                                            null,
                                                            false,
                                                            'subfolder_title'
                                                        )
                                                    },
                                                    true
                                                ) }}
                                                {{ item.subfolders }}
                                            </p>
                                        </div>
                                        <div class="col-12 d-sm-none">
                                            <p class="mb-1">
                                                {{ (item.type == 'share')
                                                    ? $root.text('Email', null, true)
                                                    : $root.text(item.type, null, true)
                                                }}
                                            </p>
                                            <p class="mb-1">{{ formatDate(item.created_at) }}</p>
                                            <p v-if="$root.isFeatured('profiles')" class="mb-1">
                                                {{ item.profile_group_name }}
                                            </p>
                                            <p class="mb-1">{{ item.first_name + ' ' + item.last_name }}</p>
                                            <p class="mb-1">
                                                {{ $root.text('No.', null, true) }} {{ item.asset_id }}
                                                <br>{{ item.resource_title }}
                                            </p>
                                            <p class="mb-0">{{ item.folder_name }}</p>
                                            <p v-if="item.subfolders" class="small mb-0">
                                                {{ $root.text(
                                                    '{subfolder}:',
                                                    { 
                                                        subfolder: $root.text(
                                                            $root.contents.subfolder_title,
                                                            null,
                                                            false,
                                                            'subfolder_title'
                                                        )
                                                    },
                                                    true
                                                ) }}
                                                {{ item.subfolders }}
                                            </p>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-if="(report == 'orders')" class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Order number', null, true) }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Date', null, true) }}
                                        </div>
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text($root.contents.asset_title, null, true, 'asset_title') }}
                                        </div>
                                        <div class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Cost', null, true) }}
                                        </div>
                                        <div class="col-sm-2 col-12">{{ $root.text('Ordered by', null, true) }}</div>
                                        <div class="col-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in report_data" :key="item.id">
                                <td scope="row" :id="'report-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.id)"
                                                >
                                                    {{ item.id }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ formatDate(item.created_at) }}
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.resource_title }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            <span v-if="(item.currency == 'pound')">
                                                &pound;{{ item.total_price.toFixed(2) }}
                                            </span>
                                            <span v-if="(item.currency == 'euro')">
                                                &euro;{{ item.total_price.toFixed(2) }}
                                            </span>
                                            <span v-if="(item.currency == 'dollar')">
                                                &dollar;{{ item.total_price.toFixed(2) }}
                                            </span>
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.created_by_name }}
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.id)"
                                                >
                                                    {{ item.id }}
                                                </a>
                                            </h5>
                                            <p class="mb-1">{{ formatDate(item.created_at) }}</p>
                                            <p class="mb-1">{{ item.resource_title }}</p>
                                            <p class="mb-1">
                                                <span v-if="(item.currency == 'pound')">
                                                    &pound;{{ item.total_price.toFixed(2) }}
                                                </span>
                                                <span v-if="(item.currency == 'euro')">
                                                    &euro;{{ item.total_price.toFixed(2) }}
                                                </span>
                                                <span v-if="(item.currency == 'dollar')">
                                                    &dollar;{{ item.total_price.toFixed(2) }}
                                                </span>
                                            </p>
                                            <p class="mb-1">{{ item.ordered_by_name }}</p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewDetails(item.id)"
                                            >
                                                <span
                                                    v-if="(report_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'report-details' + item.id">
                                            <report-details
                                                :report="report"
                                                :details="item"
                                                :visible="(report_details_open.indexOf(item.id) > -1)"
                                            ></report-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table v-if="(report == 'flytes-sent')" class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-4 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Name', null, true) }}
                                        </div>
                                        <div class="col-sm-4 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Date sent', null, true) }}
                                        </div>
                                        <div class="col-sm-3 col-12">
                                            {{ $root.text('Number of files', null, true) }}
                                        </div>
                                        <div class="col-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in report_data" :key="item.id">
                                <td scope="row" :id="'report-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-4 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.id)"
                                                >
                                                    {{ item.first_name + ' ' + item.last_name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-4 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.created_at_formatted }}
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            <span v-if="item.total == 1">{{ $root.text('1 file') }}</span>
                                            <span v-else>{{ $root.text('{x} files', { x: item.total }) }}</span>
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleViewDetails(item.id)"
                                                >
                                                    {{ item.first_name + ' ' + item.last_name }}
                                                </a>
                                            </h5>
                                            <p class="mb-1">{{ item.created_at_formatted }}</p>
                                            <p class="mb-1">
                                                <span v-if="item.total == 1">{{ $root.text('1 file') }}</span>
                                                <span v-else>{{ $root.text('{x} files', { x: item.total }) }}</span>
                                            </p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleViewDetails(item.id)"
                                            >
                                                <span
                                                    v-if="(report_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'report-details' + item.id">
                                            <report-details
                                                :report="report"
                                                :details="item"
                                                :visible="(report_details_open.indexOf(item.id) > -1)"
                                            ></report-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="details_loading"></loading>
        </div>

        <div v-if="(report == 'registrations')">
            <div
                v-if="$root.hasPermission('users_reset')"
                class="modal fade"
                id="reset-user-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="reset-user-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable">
                    <reset-user></reset-user>
                </div>
            </div>

            <div
                v-if="$root.hasPermission('users_approve')"
                class="modal fade"
                id="decline-user-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="decline-user-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable">
                    <decline-user></decline-user>
                </div>
            </div>

            <div
                v-if="$root.hasPermission('users_invite')"
                class="modal fade"
                id="cancel-invite-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="cancel-invite-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable">
                    <cancel-invite></cancel-invite>
                </div>
            </div>
        </div>

        <report-filters :report_filters="$root.report_filters" :current_report="report"></report-filters>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'reports',
                loading: true,
                errors: false,
                report: null,
                current_period: null,
                date_from: null,
                date_to: null,
                group_by: 'day',
                prev_date: null,
                next_date: null,
                total: 0,
                date_range_display: null,
                chart_data: null,
                chart_options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                userCallback: function(label, index, labels) {
                                    // Show only whole numbers.
                                    if (Math.floor(label) === label) {
                                        return label;
                                    }
                                }
                            }
                        }]
                    },
                    onClick: this.handleClick
                },
                bar_date_ranges: [],
                details_loading: false,
                report_data: [],
                report_details_open: [],
                show_details: true,
                report_title: '',
                csv_filename: '',
                overview: 'registrations',
                current_page: 1,
                last_page: 1,
                date_range: null,
                profile_groups: []
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            //
        },
        mounted () {
            $('body').removeClass('login-body');

            this.report = this.$route.name.substr(8);
            if ((this.report == 'overview') && !this.$root.hasPermission('reports_overview')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            if ((this.report == 'registrations') && !this.$root.hasPermission('reports_users')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            if ((this.report == 'visits') && !this.$root.hasPermission('reports_users')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            if ((this.report == 'asset-uploads') && !this.$root.hasPermission('reports_assets')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            if ((this.report == 'asset-downloads') && !this.$root.hasPermission('reports_assets')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            if ((this.report == 'artwork-created') && !this.$root.hasPermission('reports_templates')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            if ((this.report == 'templates') && !this.$root.hasPermission('reports_templates')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            if ((this.report == 'orders') && !this.$root.hasPermission('reports_print_orders')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            if ((this.report == 'flytes-sent') && !this.$root.hasPermission('reports_flyte')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }

            this.overview = 'registrations';
            if (this.$route.query.period) {
                if (this.$route.query.from) {
                    this.$root.report_start_date = this.$route.query.from;
                }
                this.$root.addFilter('report_filters', {
                    key: 'period',
                    value: this.$route.query.period,
                    text: this.$root.text(this.$route.query.period, null, true),
                    filters_key: 'report_filters'
                });
                this.$router.push({
                    name: this.$route.name,
                    params: this.$route.params
                });

                return null;
            }
            this.getReport();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        methods: {
            getReport: function () {
                if ((this.report == 'registrations') && this.$root.isFeatured('profiles')) {
                    this.getProfiles();
                }
                this.errors = false;
                this.loading = true;
                this.bar_date_ranges = [];
                this.report_data = [];
                this.$root.report_csv_download = null;
                this.$root.report_xls_download = null;
                this.current_page = 1;
                this.last_page = 1;
                this.setPeriod();
                if (!this.date_from) {
                    return null;
                }

                /**
                 * Send get report counts request to API.
                 */
                let report = this.report;
                let adminFilter = '';
                if (report == 'asset-uploads') {
                    report = 'resource-uploads';
                    adminFilter = 'admin|';
                }
                if (report == 'asset-downloads') {
                    report = 'resource-downloads';
                    adminFilter = 'admin|';
                }
                if (report == 'artwork-created') {
                    adminFilter = 'admin|';
                }
                if ((report == 'visits') || (report == 'flytes-sent')) {
                    adminFilter = 'admin|';
                }
                let overview = this.overview;
                if ((report == 'overview') && (overview == 'asset-uploads')) {
                    overview = 'resource-uploads';
                    adminFilter = 'admin|';
                }
                if ((report == 'overview') && (overview == 'asset-downloads')) {
                    overview = 'resource-downloads';
                    adminFilter = 'admin|';
                }
                if ((report == 'overview') && (overview == 'visits')) {
                    adminFilter = 'admin|';
                }
                let path = (report == 'overview')
                    ? '/report/' + overview + '/' + adminFilter + 'count|gr::' + this.group_by + '|dr::'
                        + this.date_from + '-' + this.date_to
                    : '/report/' + report + '/' + adminFilter + 'count|gr::' + this.group_by + '|dr::'
                        + this.date_from + '-' + this.date_to + '|cf::' + btoa(this.csv_filename) + '|ct::';
                if (report == 'templates') {
                    let title = 'Total ';
                    if (this.hasType()) {
                        title += ((this.hasType() == 'shares') ? 'emails' : this.hasType()) + ' = {total_'
                            + this.hasType() + '}';
                    } else {
                        if (this.hasType('orders')) {
                            title += 'orders = {total_orders}, ';
                        }
                        title += 'downloads = {total_downloads}, emails = {total_shares}';
                    }
                    title += ' '
                        + ((this.current_period == 'custom') ? this.report_title.periodCt : this.report_title.period);
                    path += btoa(title);
                    if (this.hasType()) {
                        path += '|tp::' + this.hasType();
                    }
                    this.$root.report_filters.forEach((item) => {
                        if (item.key == 'id') {
                            path += '|id::' + item.value;
                        }
                        if (item.key == 'report_folder') {
                            path += '|fr::' + item.value;
                        }
                        if (item.key == 'report_pg') {
                            path += '|pg::' + item.value;
                        }
                    });
                } else {
                    path += btoa(this.report_title + ' = {total}');
                }
                if (report != 'overview') {
                    this.$root.report_filters.forEach((item) => {
                        if (item.key == 'report_ug') {
                            path += '|ug::' + item.value;
                        }
                    });
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + path;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.setUpChart(response.data);
                })
                .catch(function (error) {
                    vm.loading = false;
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            setUpChart: function (data) {
                this.chart_options.legend.display = false;
                let labels = [];
                let chartData = [];
                if (this.report == 'overview') {
                    if (this.overview == 'registrations') {
                        this.total = {
                            registrations: data.registrations.total
                        };
                        this.date_range_display = {
                            registrations: data.registrations.date_range
                        };
                        data.registrations.data.forEach((item) => {
                            labels.push(item.label);
                            chartData.push(item.value);
                        });
                        this.chart_data = {
                            registrations: {
                                labels: labels,
                                datasets: [
                                    {
                                        label: this.$root.text('Registrations', null, true),
                                        backgroundColor: this.$root.client.third_colour,
                                        data: chartData
                                    }
                                ]
                            }
                        };
                    }
                    if (this.overview == 'visits') {
                        this.total.visits = data.visits.total;
                        this.date_range_display.visits = data.visits.date_range;
                        data.visits.data.forEach((item) => {
                            labels.push(item.label);
                            chartData.push(item.value);
                        });
                        this.chart_data.visits = {
                            labels: labels,
                            datasets: [
                                {
                                    label: this.$root.text('Unique visits', null, true),
                                    backgroundColor: this.$root.client.third_colour,
                                    data: chartData
                                }
                            ]
                        };
                    }
                    if (this.overview == 'asset-uploads') {
                        this.total.uploads = data.uploads.total;
                        this.date_range_display.uploads = data.uploads.date_range;
                        data.uploads.data.forEach((item) => {
                            labels.push(item.label);
                            chartData.push(item.value);
                        });
                        this.chart_data.uploads = {
                            labels: labels,
                            datasets: [
                                {
                                    label: this.$root.text('Uploads', null, true),
                                    backgroundColor: this.$root.client.third_colour,
                                    data: chartData
                                }
                            ]
                        };
                    }
                    if (this.overview == 'asset-downloads') {
                        this.total.downloads = data.downloads.total;
                        this.date_range_display.downloads = data.downloads.date_range;
                        data.downloads.data.forEach((item) => {
                            labels.push(item.label);
                            chartData.push(item.value);
                        });
                        this.chart_data.downloads = {
                            labels: labels,
                            datasets: [
                                {
                                    label: this.$root.text('Downloads', null, true),
                                    backgroundColor: this.$root.client.third_colour,
                                    data: chartData
                                }
                            ]
                        };
                    }
                }
                if (this.report == 'registrations') {
                    this.total = data.registrations.total;
                    this.date_range_display = data.registrations.date_range;
                    this.$root.report_csv_download = data.registrations.csv_download;
                    data.registrations.data.forEach((item) => {
                        labels.push(item.label);
                        chartData.push(item.value);
                        this.bar_date_ranges.push(item.date_range);
                    });
                    this.chart_data = {
                        labels: labels,
                        datasets: [
                            {
                                label: this.$root.text('Registrations', null, true),
                                backgroundColor: this.$root.client.third_colour,
                                data: chartData
                            }
                        ]
                    };
                }
                if (this.report == 'visits') {
                    this.total = data.visits.total;
                    this.date_range_display = data.visits.date_range;
                    this.$root.report_csv_download = data.visits.csv_download;
                    data.visits.data.forEach((item) => {
                        labels.push(item.label);
                        chartData.push(item.value);
                        this.bar_date_ranges.push(item.date_range);
                    });
                    this.chart_data = {
                        labels: labels,
                        datasets: [
                            {
                                label: this.$root.text('Unique visits', null, true),
                                backgroundColor: this.$root.client.third_colour,
                                data: chartData
                            }
                        ]
                    };
                }
                if (this.report == 'asset-uploads') {
                    this.total = data.uploads.total;
                    this.date_range_display = data.uploads.date_range;
                    this.$root.report_csv_download = data.uploads.csv_download;
                    data.uploads.data.forEach((item) => {
                        labels.push(item.label);
                        chartData.push(item.value);
                        this.bar_date_ranges.push(item.date_range);
                    });
                    this.chart_data = {
                        labels: labels,
                        datasets: [
                            {
                                label: this.$root.text('Uploads', null, true),
                                backgroundColor: this.$root.client.third_colour,
                                data: chartData
                            }
                        ]
                    };
                }
                if (this.report == 'asset-downloads') {
                    this.total = data.downloads.total;
                    this.date_range_display = data.downloads.date_range;
                    this.$root.report_csv_download = data.downloads.csv_download;
                    data.downloads.data.forEach((item) => {
                        labels.push(item.label);
                        chartData.push(item.value);
                        this.bar_date_ranges.push(item.date_range);
                    });
                    this.chart_data = {
                        labels: labels,
                        datasets: [
                            {
                                label: this.$root.text('Downloads', null, true),
                                backgroundColor: this.$root.client.third_colour,
                                data: chartData
                            }
                        ]
                    };
                }
                if (this.report == 'artwork-created') {
                    this.total = data.artworks.total;
                    this.date_range_display = data.artworks.date_range;
                    this.$root.report_csv_download = data.artworks.csv_download;
                    data.artworks.data.forEach((item) => {
                        labels.push(item.label);
                        chartData.push(item.value);
                        this.bar_date_ranges.push(item.date_range);
                    });
                    this.chart_data = {
                        labels: labels,
                        datasets: [
                            {
                                label: this.$root.text(
                                    '{templates} created',
                                    {
                                        templates: this.$root.text(
                                            this.$root.contents.template_title + 's',
                                            null,
                                            false,
                                            'templates_title'
                                        )
                                    },
                                    true
                                ),
                                backgroundColor: this.$root.client.third_colour,
                                data: chartData
                            }
                        ]
                    };
                }
                if (this.report == 'templates') {
                    if (!this.hasType()) {
                        this.chart_options.legend.display = true;
                    }
                    chartData = {};
                    if (this.hasType('orders')) {
                        chartData.orders = [];
                    }
                    if (this.hasType('downloads')) {
                        chartData.downloads = [];
                    }
                    if (this.hasType('shares')) {
                        chartData.shares = [];
                    }
                    this.total = data.templates.total;
                    this.date_range_display = data.templates.date_range;
                    this.$root.report_xls_download = data.templates.xls_download;
                    data.templates.data.forEach((item) => {
                        labels.push(item.label);
                        if (this.hasType('orders')) {
                            chartData.orders.push(item.value.orders);
                        }
                        if (this.hasType('downloads')) {
                            chartData.downloads.push(item.value.downloads);
                        }
                        if (this.hasType('shares')) {
                            chartData.shares.push(item.value.shares);
                        }
                        this.bar_date_ranges.push(item.date_range);
                    });
                    let datasets = [];
                    if (this.hasType('orders')) {
                        datasets.push({
                            label: this.$root.text('Orders', null, true),
                            backgroundColor: this.$root.client.third_colour,
                            data: chartData.orders
                        });
                    }
                    if (this.hasType('downloads')) {
                        datasets.push({
                            label: this.$root.text('Downloads', null, true),
                            backgroundColor: this.hasType() ? this.$root.client.third_colour
                                : this.$root.client.secondary_colour,
                            data: chartData.downloads
                        });
                    }
                    if (this.hasType('shares')) {
                        datasets.push({
                            label: this.$root.text('Emails', null, true),
                            backgroundColor: this.hasType() ? this.$root.client.third_colour
                                : this.$root.client.primary_colour,
                            data: chartData.shares
                        });
                    }
                    this.chart_data = {
                        labels: labels,
                        datasets: datasets
                    };
                }
                if (this.report == 'orders') {
                    this.total = data.orders.total;
                    this.date_range_display = data.orders.date_range;
                    this.$root.report_xls_download = data.orders.xls_download;
                    data.orders.data.forEach((item) => {
                        labels.push(item.label);
                        chartData.push(item.value);
                        this.bar_date_ranges.push(item.date_range);
                    });
                    this.chart_data = {
                        labels: labels,
                        datasets: [
                            {
                                label: this.$root.text('Orders', null, true),
                                backgroundColor: this.$root.client.third_colour,
                                data: chartData
                            }
                        ]
                    };
                }
                if (this.report == 'flytes-sent') {
                    this.total = data.flytes.total;
                    this.date_range_display = data.flytes.date_range;
                    //this.$root.report_csv_download = data.flytes.csv_download;
                    data.flytes.data.forEach((item) => {
                        labels.push(item.label);
                        chartData.push(item.value);
                        this.bar_date_ranges.push(item.date_range);
                    });
                    this.chart_data = {
                        labels: labels,
                        datasets: [
                            {
                                label: this.$root.text('Files sent', null, true),
                                backgroundColor: this.$root.client.third_colour,
                                data: chartData
                            }
                        ]
                    };
                }
                
                if (this.report == 'overview') {
                    if (this.overview == 'registrations') {
                        this.overview = 'visits';
                        this.getReport();

                        return null;
                    }
                    if (this.overview == 'visits') {
                        this.overview = 'asset-uploads';
                        this.getReport();

                        return null;
                    }
                    if (this.overview == 'asset-uploads') {
                        this.overview = 'asset-downloads';
                        this.getReport();

                        return null;
                    }
                    this.loading = false;

                    return null;
                }
                this.loading = false;
                this.getReportDetails();
            },
            getReportDetails: function (dateRange = null, page = 1, type = null) {
                let report = this.report;
                this.date_range = dateRange;
                if (this.details_loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.details_loading = true;
                this.errors = false;
                if (page == 1) {
                    this.current_page = 1;
                    this.last_page = 1;
                    this.report_details_open = [];
                    this.report_data = [];
                }
                if ((report == 'templates') && !type && this.hasType()) {
                    type = this.hasType();
                }

                /**
                 * Send request to API.
                 */
                let adminFilter = '';
                let addPage = '';
                if (report == 'asset-uploads') {
                    report = 'resource-uploads';
                    adminFilter = 'admin|';
                    addPage = '?page=' + page;
                }
                if (report == 'asset-downloads') {
                    report = 'resource-downloads';
                    adminFilter = 'admin|';
                    addPage = '?page=' + page;
                }
                if (report == 'artwork-created') {
                    adminFilter = 'admin|';
                    addPage = '?page=' + page;
                }
                if ((report == 'orders') || (report == 'templates')) {
                    addPage = '?page=' + page;
                }
                if ((report == 'visits') || (report == 'flytes-sent')) {
                    adminFilter = 'admin|';
                }
                let path =  '/report/' + report + '/' + adminFilter + 'dr::'
                    + (dateRange ? dateRange : (this.date_from + '-' + this.date_to));
                if (type) {
                    path += '|tp::' + type;
                }
                if (report == 'templates') {
                    this.$root.report_filters.forEach((item) => {
                        if (item.key == 'id') {
                            path += '|id::' + item.value;
                        }
                        if (item.key == 'report_folder') {
                            path += '|fr::' + item.value;
                        }
                        if (item.key == 'report_pg') {
                            path += '|pg::' + item.value;
                        }
                    });
                }
                if (report != 'overview') {
                    this.$root.report_filters.forEach((item) => {
                        if (item.key == 'report_ug') {
                            path += '|ug::' + item.value;
                        }
                    });
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + path + addPage;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.show_details = false;
                    vm.setUpDetails(response.data);
                })
                .catch(function (error) {
                    vm.details_loading = false;
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            setUpDetails: function (data) {
                if (this.report == 'registrations') {
                    data.registrations.forEach((item) => {
                        this.report_data.push(item);
                    });
                }
                if (this.report == 'visits') {
                    data.visits.forEach((item) => {
                        item.id = item.user_id;
                        this.report_data.push(item);
                        if (item.items.length > 1) {
                            this.show_details = true;
                        }
                    });
                }
                if (this.report == 'asset-uploads') {
                    this.current_page = data.uploads.current_page;
                    this.last_page = data.uploads.last_page;
                    data.uploads.data.forEach((item) => {
                        this.report_data.push(item);
                    });
                }
                if (this.report == 'asset-downloads') {
                    this.current_page = data.downloads.current_page;
                    this.last_page = data.downloads.last_page;
                    data.downloads.data.forEach((item) => {
                        this.report_data.push(item);
                    });
                }
                if (this.report == 'artwork-created') {
                    this.current_page = data.artworks.current_page;
                    this.last_page = data.artworks.last_page;
                    data.artworks.data.forEach((item) => {
                        this.report_data.push(item);
                    });
                }
                if (this.report == 'templates') {
                    this.current_page = data.templates.current_page;
                    this.last_page = data.templates.last_page;
                    data.templates.data.forEach((item) => {
                        this.report_data.push(item);
                    });
                }
                if (this.report == 'orders') {
                    this.current_page = data.orders.current_page;
                    this.last_page = data.orders.last_page;
                    data.orders.data.forEach((item) => {
                        item.total_price = parseFloat(item.total_price);
                        this.report_data.push(item);
                    });
                }
                if (this.report == 'flytes-sent') {
                    data.flytes.forEach((item) => {
                        this.report_data.push(item);
                    });
                }
                this.details_loading = false;
            },
            setPeriod: function () {
                // Set period filter.
                let found = null;
                this.$root.report_filters.forEach((item) => {
                    if (item.key == 'period') {
                        found = item.value;
                    }
                });
                if (!found) {
                    this.$root.addFilter('report_filters', {
                        key: 'period',
                        value: 'month',
                        text: this.$root.text('Month', null, true),
                        filters_key: 'report_filters'
                    });
                    found = 'month';
                }
                if (found == 'custom') {
                    this.current_period = found;
                    this.$root.report_start_date = null;
                    this.setDateRange();

                    return null;
                }
                if (this.current_period != found) {
                    this.current_period = found;
                    this.setDateRange(this.$root.report_start_date);
                    this.$root.report_start_date = null;
                }
            },
            setDateRange: function (startDate = null) {
                Date.prototype.getWeek = function() {
                    const date1 = new Date(this.getTime());
                    date1.setHours(0, 0, 0, 0);
                    // Thursday in current week decides the year.
                    date1.setDate(date1.getDate() + 3 - (date1.getDay() + 6) % 7);
                    // January 4 is always in week 1.
                    const week1 = new Date(date1.getFullYear(), 0, 4);
                    // Adjust to Thursday in week 1 and count number of weeks from date to week 1.
                    const weekNumber = 1 + Math.round(
                        ((date1.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7
                    );

                    return weekNumber;
                }
                const monthNames = [
                    this.$root.text('Jan', null, true),
                    this.$root.text('Feb', null, true),
                    this.$root.text('Mar', null, true),
                    this.$root.text('Apr', null, true),
                    this.$root.text('May', null, true),
                    this.$root.text('Jun', null, true),
                    this.$root.text('Jul', null, true),
                    this.$root.text('Aug', null, true),
                    this.$root.text('Sep', null, true),
                    this.$root.text('Oct', null, true),
                    this.$root.text('Nov', null, true),
                    this.$root.text('Dec', null, true)
                ];
                const quarterNames = [
                    this.$root.text('Q1', null, true),
                    this.$root.text('Q1', null, true),
                    this.$root.text('Q1', null, true),
                    this.$root.text('Q2', null, true),
                    this.$root.text('Q2', null, true),
                    this.$root.text('Q2', null, true),
                    this.$root.text('Q3', null, true),
                    this.$root.text('Q3', null, true),
                    this.$root.text('Q3', null, true),
                    this.$root.text('Q4', null, true),
                    this.$root.text('Q4', null, true),
                    this.$root.text('Q4', null, true)
                ];
                const todaysDate = new Date();
                let month = ('0' + (todaysDate.getMonth() + 1)).slice(-2);
                let day = ('0' + todaysDate.getDate()).slice(-2);
                const today = todaysDate.getFullYear() + '-' + month + '-' + day;

                const currentDate = startDate ? new Date(startDate) : new Date();
                let prevDate = null;
                let nextDate = null;

                let reportTitle = null;
                let csvFilename = null;
                switch (this.report) {
                    case 'registrations':
                        reportTitle = this.$root.text('Total registrations', null, true);
                        csvFilename = this.$root.text('Registrations', null, true);
                        break;
                    case 'visits':
                        reportTitle = this.$root.text('Total unique visits', null, true);
                        csvFilename = this.$root.text('Visits', null, true);
                        break;
                    case 'asset-uploads':
                        reportTitle = this.$root.text('Total uploads', null, true);
                        csvFilename = this.$root.text('Uploads', null, true);
                        break;
                    case 'asset-downloads':
                        reportTitle = this.$root.text('Total downloads', null, true);
                        csvFilename = this.$root.text('Downloads', null, true);
                        break;
                    case 'artwork-created':
                        reportTitle = this.$root.text(
                            'Total {templates} created',
                            {
                                templates: this.$root.text(
                                    this.$root.contents.template_title + 's',
                                    null,
                                    false,
                                    'templates_title'
                                )
                            },
                            true
                        );
                        csvFilename = this.$root.text(
                            '{templates}-created',
                            {
                                templates: this.$root.text(
                                    this.$root.contents.template_title + 's',
                                    null,
                                    false,
                                    'templates_title'
                                )
                            }
                        );
                        break;
                    case 'templates':
                        this.report_title = {};
                        reportTitle = {};
                        if (this.hasType('orders')) {
                            reportTitle.orders = this.$root.text('orders');
                        }
                        if (this.hasType('downloads')) {
                            reportTitle.downloads = this.$root.text('downloads');
                        }
                        if (this.hasType('shares')) {
                            reportTitle.shares = this.$root.text('emails');
                        }
                        csvFilename = this.$root.text(
                            this.$root.contents.template_title + 's',
                            null,
                            false,
                            'templates_title'
                        ) + '-';
                        if (this.hasType()) {
                            csvFilename += this.$root.text((this.hasType() == 'shares') ? 'emails' : this.hasType());
                        } else {
                            csvFilename += this.$root.text('all');
                        }
                        break;
                    case 'orders':
                        reportTitle = this.$root.text('Total orders', null, true);
                        csvFilename = this.$root.text('Orders', null, true);
                        break;
                    case 'flytes-sent':
                        reportTitle = this.$root.text('Flyte activity', null, true);
                        //csvFilename = 'Files-sent';
                        break;
                    default:
                        // Overview
                        this.report_title = {};
                        reportTitle = {
                            registrations: this.$root.text('Total registrations', null, true),
                            visits: this.$root.text('Total unique visits', null, true),
                            uploads: this.$root.text('Total uploads', null, true),
                            downloads: this.$root.text('Total downloads', null, true)
                        };
                }

                switch (this.current_period) {
                    case 'week':
                        const dayOfWeek = (currentDate.getDay() || 7);
                        if (dayOfWeek > 1) {
                            currentDate.setDate(currentDate.getDate() - (dayOfWeek - 1));
                        }
                        month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
                        day = ('0' + currentDate.getDate()).slice(-2);
                        this.date_from = currentDate.getFullYear() + '-' + month + '-' + day;

                        const nextSundayDate = new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            currentDate.getDate()
                        );
                        nextSundayDate.setDate(nextSundayDate.getDate() + 6);
                        month = ('0' + (nextSundayDate.getMonth() + 1)).slice(-2);
                        day = ('0' + nextSundayDate.getDate()).slice(-2);
                        let nextSunday = nextSundayDate.getFullYear() + '-' + month + '-' + day;

                        if (nextSunday < today) {
                            this.date_to = nextSunday;

                            nextDate = new Date(
                                nextSundayDate.getFullYear(),
                                nextSundayDate.getMonth(),
                                nextSundayDate.getDate()
                            );
                            nextDate.setDate(nextDate.getDate() + 1);
                            month = ('0' + (nextDate.getMonth() + 1)).slice(-2);
                            day = ('0' + nextDate.getDate()).slice(-2);
                            this.next_date = nextDate.getFullYear() + '-' + month + '-' + day;
                        } else {
                            this.date_to = today;
                            this.next_date = null;
                        }

                        if (reportTitle) {
                            if (this.report == 'overview') {
                                this.report_title.registrations = this.$root.text(
                                    '{something} for week {x}, {year}',
                                    {
                                        something: reportTitle.registrations,
                                        x: currentDate.getWeek(),
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.visits = this.$root.text(
                                    '{something} for week {x}, {year}',
                                    {
                                        something: reportTitle.visits,
                                        x: currentDate.getWeek(),
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.uploads = this.$root.text(
                                    '{something} for week {x}, {year}',
                                    {
                                        something: reportTitle.uploads,
                                        x: currentDate.getWeek(),
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.downloads = this.$root.text(
                                    '{something} for week {x}, {year}',
                                    {
                                        something: reportTitle.downloads,
                                        x: currentDate.getWeek(),
                                        year: currentDate.getFullYear()
                                    }
                                );
                            } else if (this.report == 'templates') {
                                reportTitle.period = this.$root.text(
                                    'in week {x}, {year}',
                                    { x: currentDate.getWeek(), year: currentDate.getFullYear() }
                                );
                                this.report_title = reportTitle;
                            } else {
                                this.report_title = this.$root.text(
                                    '{something} for week {x}, {year}',
                                    {
                                        something: reportTitle,
                                        x: currentDate.getWeek(),
                                        year: currentDate.getFullYear()
                                    }
                                );
                            }
                            this.csv_filename = csvFilename + '-' + this.$root.text('Week', null, true) + '-'
                                + currentDate.getWeek() + '-' + currentDate.getFullYear();
                        }
                        this.group_by = 'day';

                        prevDate = new Date(
                            currentDate.getFullYear(),
                            currentDate.getMonth(),
                            currentDate.getDate()
                        );
                        prevDate.setDate(prevDate.getDate() - 7);
                        month = ('0' + (prevDate.getMonth() + 1)).slice(-2);
                        day = ('0' + prevDate.getDate()).slice(-2);
                        this.prev_date = prevDate.getFullYear() + '-' + month + '-' + day;
                        break;
                    case 'month':
                        month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
                        this.date_from = currentDate.getFullYear() + '-' + month + '-01';

                        const lastDayOfMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
                        day = ('0' + lastDayOfMonthDate.getDate()).slice(-2);
                        const lastDayOfMonth = currentDate.getFullYear() + '-' + month + '-' + day;

                        if (lastDayOfMonth < today) {
                            this.date_to = lastDayOfMonth;

                            nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                            nextDate.setMonth(nextDate.getMonth() + 1);
                            month = ('0' + (nextDate.getMonth() + 1)).slice(-2);
                            this.next_date = nextDate.getFullYear() + '-' + month + '-01';
                        } else {
                            this.date_to = today;
                            this.next_date = null;
                        }

                        if (reportTitle) {
                            if (this.report == 'overview') {
                                this.report_title.registrations = this.$root.text(
                                    '{something} for {month} {year}',
                                    {
                                        something: reportTitle.registrations,
                                        month: monthNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.visits = this.$root.text(
                                    '{something} for {month} {year}',
                                    {
                                        something: reportTitle.visits,
                                        month: monthNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.uploads = this.$root.text(
                                    '{something} for {month} {year}',
                                    {
                                        something: reportTitle.uploads,
                                        month: monthNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.downloads = this.$root.text(
                                    '{something} for {month} {year}',
                                    {
                                        something: reportTitle.downloads,
                                        month: monthNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                            } else if (this.report == 'templates') {
                                reportTitle.period = this.$root.text(
                                    'in {month} {year}',
                                    { month: monthNames[currentDate.getMonth()], year: currentDate.getFullYear() }
                                );
                                this.report_title = reportTitle;
                            } else {
                                this.report_title = this.$root.text(
                                    '{something} for {month} {year}',
                                    {
                                        something: reportTitle,
                                        month: monthNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                            }
                            this.csv_filename = csvFilename + '-' + monthNames[currentDate.getMonth()] + '-'
                                + currentDate.getFullYear();
                        }
                        this.group_by = 'day';

                        prevDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                        prevDate.setMonth(prevDate.getMonth() - 1);
                        month = ('0' + (prevDate.getMonth() + 1)).slice(-2);
                        this.prev_date = prevDate.getFullYear() + '-' + month + '-01';
                        break;
                    case 'quarter':
                        const plusOneMonths = [1, 4, 7, 10];
                        const plusTwoMonths = [2, 5, 8, 11];
                        if (plusOneMonths.indexOf(currentDate.getMonth()) > -1) {
                            currentDate.setMonth(currentDate.getMonth() - 1);
                        }
                        if (plusTwoMonths.indexOf(currentDate.getMonth()) > -1) {
                            currentDate.setMonth(currentDate.getMonth() - 2);
                        }
                        month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
                        this.date_from = currentDate.getFullYear() + '-' + month + '-01';

                        const lastDayOfQuarterDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, 0);
                        month = ('0' + (lastDayOfQuarterDate.getMonth() + 1)).slice(-2);
                        day = ('0' + lastDayOfQuarterDate.getDate()).slice(-2);
                        const lastDayOfQuarter = lastDayOfQuarterDate.getFullYear() + '-' + month + '-' + day;

                        if (lastDayOfQuarter < today) {
                            this.date_to = lastDayOfQuarter;

                            nextDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                            nextDate.setMonth(nextDate.getMonth() + 3);
                            month = ('0' + (nextDate.getMonth() + 1)).slice(-2);
                            this.next_date = nextDate.getFullYear() + '-' + month + '-01';
                        } else {
                            this.date_to = today;
                            this.next_date = null;
                        }

                        if (reportTitle) {
                            if (this.report == 'overview') {
                                this.report_title.registrations = this.$root.text(
                                    '{something} for {quarter}, {year}',
                                    {
                                        something: reportTitle.registrations,
                                        quarter: quarterNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.visits = this.$root.text(
                                    '{something} for {quarter}, {year}',
                                    {
                                        something: reportTitle.visits,
                                        quarter: quarterNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.uploads = this.$root.text(
                                    '{something} for {quarter}, {year}',
                                    {
                                        something: reportTitle.uploads,
                                        quarter: quarterNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                                this.report_title.downloads = this.$root.text(
                                    '{something} for {quarter}, {year}',
                                    {
                                        something: reportTitle.downloads,
                                        quarter: quarterNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                            } else if (this.report == 'templates') {
                                reportTitle.period = this.$root.text(
                                    'in {quarter}, {year}',
                                    { quarter: quarterNames[currentDate.getMonth()], year: currentDate.getFullYear() }
                                );
                                this.report_title = reportTitle;
                            } else {
                                this.report_title = this.$root.text(
                                    '{something} for {quarter}, {year}',
                                    {
                                        something: reportTitle,
                                        quarter: quarterNames[currentDate.getMonth()],
                                        year: currentDate.getFullYear()
                                    }
                                );
                            }
                            this.csv_filename = csvFilename + '-' + quarterNames[currentDate.getMonth()] + '-'
                                + currentDate.getFullYear();
                        }
                        this.group_by = 'month';

                        prevDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                        prevDate.setMonth(prevDate.getMonth() - 3);
                        month = ('0' + (prevDate.getMonth() + 1)).slice(-2);
                        this.prev_date = prevDate.getFullYear() + '-' + month + '-01';
                        break;
                    case 'year':
                        this.date_from = currentDate.getFullYear() + '-01-01';
                        const lastDayOfYear = currentDate.getFullYear() + '-12-31';

                        if (lastDayOfYear < today) {
                            this.date_to = lastDayOfYear;

                            nextDate = new Date(currentDate.getFullYear(), 0, 1);
                            nextDate.setFullYear(nextDate.getFullYear() + 1);
                            this.next_date = nextDate.getFullYear() + '-01-01';
                        } else {
                            this.date_to = today;
                            this.next_date = null;
                        }

                        if (reportTitle) {
                            if (this.report == 'overview') {
                                this.report_title.registrations = this.$root.text(
                                    '{something} for {year}',
                                    { something: reportTitle.registrations, year: currentDate.getFullYear() }
                                );
                                this.report_title.visits = this.$root.text(
                                    '{something} for {year}',
                                    { something: reportTitle.visits, year: currentDate.getFullYear() }
                                );
                                this.report_title.uploads = this.$root.text(
                                    '{something} for {year}',
                                    { something: reportTitle.uploads, year: currentDate.getFullYear() }
                                );
                                this.report_title.downloads = this.$root.text(
                                    '{something} for {year}',
                                    { something: reportTitle.downloads, year: currentDate.getFullYear() }
                                );
                            } else if (this.report == 'templates') {
                                reportTitle.period = this.$root.text(
                                    'in {year}',
                                    { year: currentDate.getFullYear() }
                                );
                                this.report_title = reportTitle;
                            } else {
                                this.report_title = this.$root.text(
                                    '{something} for {year}',
                                    { something: reportTitle, year: currentDate.getFullYear() }
                                );
                            }
                            this.csv_filename = csvFilename + '-' + currentDate.getFullYear();
                        }
                        this.group_by = 'month';

                        prevDate = new Date(currentDate.getFullYear(), 0, 1);
                        prevDate.setFullYear(prevDate.getFullYear() - 1);
                        this.prev_date = prevDate.getFullYear() + '-01-01';
                        break;
                    case 'custom':
                        this.date_from = null;
                        this.date_to = null;
                        this.$root.report_filters.forEach((item) => {
                            if (item.key == 'date_range') {
                                this.date_from = item.value.substring(0, 10);
                                this.date_to = item.value.substring(11);
                            }
                        });
                        this.prev_date = null;
                        this.next_date = null;

                        if (reportTitle && this.date_from) {
                            if (this.report == 'overview') {
                                this.report_title.registrations = reportTitle.registrations;
                                this.report_title.visits = reportTitle.visits;
                                this.report_title.uploads = reportTitle.uploads;
                                this.report_title.downloads = reportTitle.downloads;
                            } else if (this.report == 'templates') {
                                reportTitle.period = '';
                                reportTitle.periodCt = 'from ' + this.date_from.substring(8, 10)
                                    + '/' + this.date_from.substring(5, 7)
                                    + '/' + this.date_from.substring(0, 4)
                                    + ' to ' + this.date_to.substring(8, 10)
                                    + '/' + this.date_to.substring(5, 7)
                                    + '/' + this.date_to.substring(0, 4);
                                this.report_title = reportTitle;
                            } else {
                                this.report_title = reportTitle;
                            }
                            this.csv_filename = csvFilename + '_' + this.date_from.substring(8, 10)
                                + '-' + this.date_from.substring(5, 7)
                                + '-' + this.date_from.substring(0, 4)
                                + '_' + this.date_to.substring(8, 10)
                                + '-' + this.date_to.substring(5, 7)
                                + '-' + this.date_to.substring(0, 4);
                        }

                        if (this.date_from) {
                            const date1 = new Date(this.date_from);
                            const date2 = new Date(this.date_to);
                            const diff = date2.getTime() - date1.getTime();
                            const days = diff / (1000 * 3600 * 24);
                            this.group_by = (days > 366) ? 'year' : ((days > 61) ? 'month' : 'day');
                        }
                        break;
                }
                if (startDate) {
                    this.getReport();
                }
            },
            handleClick: function (point, event) {
                if (this.report == 'overview') {
                    return null;
                }
                if (!event[0]) {
                    return null;
                }
                if (!this.bar_date_ranges[event[0]._index]) {
                    return null;
                }
                let type = null;
                if ((this.report == 'templates') && !this.hasType()) {
                    const clickX = point.x - $('#bar-chart').offset().left + ($('#bar-chart').width() / 70);
                    if (clickX < event[1]._model.x) {
                        type = this.hasType('orders') ? 'orders' : 'downloads';
                    } else {
                        if (this.hasType('orders')) {
                            type = (clickX < event[2]._model.x) ? 'downloads' : 'shares';
                        } else {
                            type = 'shares';
                        }
                    }
                }
                this.getReportDetails(this.bar_date_ranges[event[0]._index], 1, type);
            },
            toggleViewDetails: function (id) {
                if (this.report_details_open.indexOf(id) < 0) {
                    this.report_details_open.push(id);
                    $('#report-row' + id).addClass('highlighted');
                    $('#report-details' + id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#report-details' + id).slideUp('slow', function () {
                        $('#report-row' + id).removeClass('highlighted');
                        for (var n = 0; n < vm.report_details_open.length; n++) { 
                            if (vm.report_details_open[n] == id) { 
                                vm.report_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            getUsers: function () {
                this.getReport();
            },
            viewAsset: function (data) {
                this.$root.asset_filters = [];
                this.$root.addFilter('asset_filters', {
                    key: 'resource_id',
                    value: data.id,
                    text: '&quot;' + data.id + '&quot;',
                    filters_key: 'asset_filters'
                });
                if (data.archived) {
                    this.$router.push({ name: 'assets-filtered', params: { filters: 'archived' } });

                    return null;
                }
                this.$router.push({ name: 'assets' });
            },
            handleScroll(event) {
                const paginatedReports = ['asset-uploads', 'asset-downloads', 'artwork-created', 'templates', 'orders'];
                if (
                    (paginatedReports.indexOf(this.report) < 0)
                    || (this.current_page >= this.last_page)
                    || this.details_loading
                ) {
                    return null;
                }
                const pageScrollTop = $('html, body').scrollTop();
                const appHeight = $('#app').outerHeight();
                const windowHeight = $(window).height();
                const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                if (scrollPosition < 200) {
                    this.getReportDetails(this.date_range, this.current_page + 1);
                }
            },
            getProfiles: function () {
                if (this.profile_groups.length > 0) {
                    return null;
                }
                /**
                 * Send get profile groups request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-groups/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_groups = response.data.profile_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            hasType: function (type = null) {
                if ((type == 'orders') && !this.$root.isFeatured('printing')) {
                    return false;
                }
                let found = false;
                this.$root.report_filters.forEach((item) => {
                    if ((item.key == 'type') && (item.value != 'all')) {
                        found = item.value;
                    }
                });
                if (!found) {
                    return type ? true : false;
                }
                if (!type) {
                    return found;
                }

                return (type == found);
            }
        }
    }
</script>
