<template>
    <div>
        <div class="p-3">
            <div class="row align-items-center">
                <div class="col">
                    <h6 class="m-0">{{ $root.text('Notifications', null, true) }}</h6>
                </div>
                <div class="col-auto">
                    <router-link
                        v-if="((notifications.length > 0) || (unread > 0))"
                        class="small"
                        :to="{ name: 'notifications' }"
                        v-on:click.native="reloadNotifications()"
                    >
                        {{ $root.text('View all', null, true) }}
                    </router-link>
                    <a
                        v-if="((type != 'admin') && ((notifications.length > 0) || (unread > 0)))"
                        href="#"
                        class="small ml-3"
                        @click.prevent="clearNotification(0)"
                    >
                        {{ $root.text('Remove all', null, true) }}
                    </a>
                </div>
            </div>
        </div>
        <div class="notifications-list">
            <p v-if="(items.length == 0)" class="text-muted text-center">
                {{ $root.text('There are no notifications at the moment', null, true) }}
            </p>
            <div v-else>
                <a
                    v-for="item in items"
                    :key="item.id"
                    href="#"
                    class="dropdown-item px-3 py-2"
                    @click.prevent="openLink(item)"
                >
                    <quick-notification-item :item="item"></quick-notification-item>
                </a>
            </div>
            <loading v-if="((items.length < 5) && (unread > items.length))"></loading>
        </div>
        <div v-if="((type == 'user') && ((notifications.length > 5) || (unread > 5)))" class="p-2 border-top d-grid">
            <router-link :to="{ name: 'notifications' }" custom v-slot="{ navigate }">
                <button class="btn btn-link btn-block" @click="navigate">
                    <i class="far fa-arrow-circle-right mr-1"></i> {{ $root.text('View more...', null, true) }}
                </button>
            </router-link>
        </div>
        <div v-if="(type == 'admin')" class="p-2 border-top d-grid">
            <router-link :to="{ name: 'settings' }" custom v-slot="{ navigate }">
                <button class="btn btn-link btn-block" @click="navigate">
                    <i class="far fa-arrow-circle-right mr-1"></i> {{ $root.text('View settings', null, true) }}
                </button>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['type', 'notifications', 'unread'],
        data () {
            return {
                items: [],
                block_open_link: false
            }
        },
        mounted () {
            this.updateNotifications(this.notifications);
        },
        watch: {
            notifications: function (val) {
                this.updateNotifications(val);
            },
            block_open_link: function (val) {
                var vm = this;
                setTimeout(function () {
                    vm.block_open_link = false;
                }, 100);
            }
        },
        methods: {
            updateNotifications: function (notifications) {
                if (notifications.length == 0) {
                    this.items = [];

                    return null;
                }
                // Add new notifications
                let notificationIds = [];
                const lastIndex = (notifications.length < 5) ? notifications.length - 1 : 4;
                for (let n = 0; n <= lastIndex; n++) {
                    let notification = notifications[n];
                    let itemIndex = -1;
                    this.items.forEach((item, index) => {
                        if (item.id == notification.id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        if (this.items.length == 0) {
                            this.items.push(notification);
                        } else {
                            const lastItem = this.items[this.items.length - 1];
                            if (notification.id < lastItem.id) {
                                this.items.push(notification);
                            } else {
                                this.items.unshift(notification);
                            }
                        }
                    } else {
                        this.items[itemIndex].read = notification.read;
                    }
                    notificationIds.push(notification.id);
                }
                // Remove deleted notifications
                for (let n = (this.items.length - 1); n >= 0; n--) {
                    if (notificationIds.indexOf(this.items[n].id) < 0) {
                        this.items.splice(n, 1);
                    }
                }
                var vm = this;
                setTimeout(function () {
                    vm.reorderNotifications();
                }, 200);
            },
            reorderNotifications: function () {
                if (this.items.length == 0) {
                    return null;
                }
                for (let i = 0; i < this.items.length - 1; i++) {
                    for (let j = i + 1; j < this.items.length; j++) {
                        if (this.items[j].id > this.items[i].id) {
                            let temp = this.items[j];
                            this.items[j] = this.items[i];
                            this.items[i] = temp;
                        }
                    }
                }
            },
            openLink: function (item) {
                if (this.block_open_link) {
                    return null;
                }
                let route = null;
                let linkParts = null;
                switch (item.action) {
                    case 'proof_review':
                    case 'proof_return':
                    case 'proof_target_date_alert':
                        if (
                            (this.$route.name == 'view-proof')
                            && (this.$route.params.id == item.item_id)
                        ) {
                            const appComponent = this.$root.findComponent(this.$root, 'main-app');
                            const component = this.$root.findComponent(appComponent, 'view-proof');
                            component.loading = true;
                            component.getProof();
                        } else {
                            route = {
                                name: 'view-proof',
                                params: {
                                    id: item.item_id
                                }
                            };
                        }
                        break;
                    case 'proof_comment':
                        if (
                            (this.$route.name == 'view-proof-tab')
                            && (this.$route.params.id == item.item_id)
                        ) {
                            const appComponent = this.$root.findComponent(this.$root, 'main-app');
                            const component = this.$root.findComponent(appComponent, 'view-proof');
                            component.getProof();
                        } else {
                            route = {
                                name: 'view-proof-tab',
                                params: {
                                    id: item.item_id,
                                    tab: 'comments'
                                }
                            };
                        }
                        break;
                    case 'collaborate':
                    case 'collaborate_comment':
                        linkParts = item.link.split('/');
                        if (
                            (this.$route.name == 'view-collection')
                            && (this.$route.params.id == (linkParts[linkParts.length - 1]))
                        ) {
                            const appComponent = this.$root.findComponent(this.$root, 'main-app');
                            const component = this.$root.findComponent(appComponent, 'view-collection');
                            component.getCollaborators();
                            component.getComments();
                        } else {
                            route = {
                                name: 'view-collection',
                                params: {
                                    id: linkParts[linkParts.length - 1]
                                }
                            };
                        }
                        break;
                    case 'share':
                        linkParts = item.link.split('/');
                        if (
                            (this.$route.name != 'shared-email')
                            || (this.$route.params.code != linkParts[linkParts.length - 2])
                            || (this.$route.params.email != linkParts[linkParts.length - 1])
                        ) {
                            route = {
                                name: 'shared-email',
                                params: {
                                    code: linkParts[linkParts.length - 2],
                                    email: linkParts[linkParts.length - 1]
                                }
                            };
                        }
                        break;
                    case 'registration':
                        route = {
                            name: 'new-users',
                        };
                        break;
                    case 'deletion_request':
                        if (this.$route.name == 'deletion-requests') {
                            const appComponent = this.$root.findComponent(this.$root, 'main-app');
                            const component = this.$root.findComponent(appComponent, 'deletion-requests');
                            component.getUsers();
                        } else {
                            route = {
                                name: 'deletion-requests',
                            };
                        }
                        break;
                    case 'resource_access_request':
                        if (this.$route.name == 'asset-approvals') {
                            this.$root.asset_approval_filters = [];
                        } else {
                            route = {
                                name: 'asset-approvals',
                            };
                        }
                        break;
                    case 'resource_approved':
                    case 'resource_declined':
                        linkParts = item.link.split('::');
                        const assetId = parseInt(linkParts[1]);
                        this.$root.asset_filters = [];
                        this.$root.addFilter('asset_filters', {
                            key: 'resource_id',
                            value: assetId,
                            text: '&quot;' + assetId + '&quot;',
                            filters_key: 'asset_filters'
                        });
                        if (
                            (item.action == 'resource_approved')
                            && (this.$root.assets_from_approval_email.indexOf(assetId) < 0)
                        ) {
                            this.$root.assets_from_approval_email.push(assetId);
                        }
                        if (this.$route.name == 'assets') {
                            const appComponent = this.$root.findComponent(this.$root, 'main-app');
                            const component = this.$root.findComponent(appComponent, 'assets');
                            component.getAssets();
                        } else {
                            route = {
                                name: 'assets',
                            };
                        }
                        break;
                    case 'review_date_alert':
                        if (this.$route.name == 'asset-reviews') {
                            const appComponent = this.$root.findComponent(this.$root, 'main-app');
                            const component = this.$root.findComponent(appComponent, 'asset-reviews');
                            component.getAssets();
                        } else {
                            route = {
                                name: 'asset-reviews',
                            };
                        }
                        break;
                    case 'print_order':
                        if (item.type == 'admin') {
                            route = {
                                name: 'print-orders-awaiting-approval',
                            };
                        } else {
                            linkParts = item.link.split('::');
                            route = {
                                name: 'my-account-print-orders-filtered',
                                params: {
                                    filters: 'id::' + linkParts[1]
                                }
                            };
                        }
                        break;
                    case 'print_order_approved':
                    case 'print_order_declined':
                        this.$root.print_order_filters = [];
                        if (this.$route.name == 'my-account-print-orders') {
                            const appComponent = this.$root.findComponent(this.$root, 'main-app');
                            const component = this.$root.findComponent(appComponent, 'print-orders');
                            component.getOrders();
                        } else {
                            route = {
                                name: 'my-account-print-orders',
                            };
                        }
                        break;
                    case 'artwork_approval_request':
                        if (this.$route.name == 'artwork-approvals') {
                            this.$root.artwork_approval_filters = [];
                        } else {
                            route = {
                                name: 'artwork-approvals',
                            };
                        }
                        break;
                    case 'artwork_approved':
                        linkParts = item.link.split('artwork-approved/');
                        if (linkParts.length == 2) {
                            route = {
                                name: 'my-account-artwork-approved',
                                params: {
                                    id: linkParts[1]
                                }
                            };
                        } else {
                            route = {
                                name: 'my-account-artworks-approved',
                            };
                        }
                        break;
                    case 'artwork_declined':
                        linkParts = item.link.split('artwork-declined/');
                        if (linkParts.length == 2) {
                            route = {
                                name: 'my-account-artwork-declined',
                                params: {
                                    id: linkParts[1]
                                }
                            };
                        } else {
                            route = {
                                name: 'my-account-artworks-declined',
                            };
                        }
                        break;
                }
                if (!item.read && (this.type != 'admin')) {
                    this.$parent.$parent.markNotificationAsRead(this.type, item.id);
                }
                if (route) {
                    this.$router.push(route);
                }
            },
            clearNotification: function (itemId, reOpenQuickNotifications = true) {
                this.block_open_link = true;
                this.$parent.$parent.clearNotification(this.type, itemId);

                if (reOpenQuickNotifications) {
                    var vm = this;
                    setTimeout(function () {
                        if (vm.type == 'user') {
                            vm.$parent.$parent.$refs.user_notifications_dropdown.show(true);

                            return null;
                        }
                        vm.$parent.$parent.$refs.admin_notifications_dropdown.show(true);
                    }, 100);
                }
            },
            reloadNotifications: function () {
                if (this.$route.name == 'notifications') {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    const component = this.$root.findComponent(appComponent, 'notifications');
                    component.getNotifications();
                    if (this.$parent.$parent.$refs.user_notifications_dropdown) {
                        this.$parent.$parent.$refs.user_notifications_dropdown.hide(true);
                    }
                    if (this.$parent.$parent.$refs.admin_notifications_dropdown) {
                        this.$parent.$parent.$refs.admin_notifications_dropdown.hide(true);
                    }
                }
            }
        }
    }
</script>
<style scoped>
    .notifications-list {
        max-height: 350px;
        overflow-y: auto;
    }

    .dropdown-item {
        white-space: normal;
    }
</style>
