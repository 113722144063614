<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label
                            :for="'domain-name' + domain.id"
                            :id="'domain-name-label' + domain.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Domain name', null, true) }} *
                        </label>
                        <input
                            v-model="domain.domain"
                            type="text"
                            :id="'domain-name' + domain.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('domain-name', domain.id)"
                            v-on:change="$root.unHighlightErrors('domain-name', domain.id)"
                        >
                        <small class="form-text text-muted">
                            {{ $root.text('For example gmail.com', null, true) }}
                        </small>
                    </div>

                    <span v-if="!updating">
                        <button
                            type="submit"
                            class="btn btn-primary mr-2"
                            :disabled="updating"
                        >
                            <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                        </button>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                        </button>
                    </span>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="deleteDomain()"
                    >
                        <i class="far fa-trash-alt mr-1"></i> {{ $root.text('Delete', null, true) }}
                    </button>
                </form>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-6">
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                errors: false,
                updating: false,
                domain: {},
            }
        },
        mounted () {
            if (this.data) {
                this.domain = this.data;
            }
        },
        watch: {
            data: function (val) {
                this.domain = val;
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                    + '/pre-approved-email-domain/' + this.domain.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.domain,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('Update domain', null, true),
                        text: vm.$root.text('Domain updated', null, true)
                    };
                    $('#message-modal').modal('show');

                    // close after 2 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            let errorHtml = null;
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                errorHtml = value.join('<br>') + '<br>';
                                vm.errors += errorHtml.replace(/domain/g, 'domain name');
                                $('#' + key + '-name-label' + vm.domain.id).addClass('text-danger');
                                $('#' + key + '-name' + vm.domain.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            deleteDomain: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;

                    /**
                     * Send delete request to API
                     */
                    const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                        + '/pre-approved-email-domain/' + this.domain.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                    })
                    .then(response => {
                        vm.$parent.getDomains();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: this.$root.text('Delete domain', null, true),
                    text: this.$root.text(
                        'Are you sure you want to delete: {name}?',
                        { name: this.domain.domain },
                        true
                    ),
                    component: this,
                    action: 'delete-domain'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteDomain(true);
            }
        }
    }
</script>
