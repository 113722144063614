<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            {{ $root.text('There are no users that match your selection', null, true) }}
        </div>
        <div v-if="((total_items > 0) || sorting)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-1 col-2">&nbsp;</div>
                                        <div class="col-sm-3 col-10 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('name') }, true)"
                                                @click.prevent="sortBy('name')"
                                            >
                                                {{ $root.text('Name', null, true) }}
                                                <span v-if="(sorted_by == 'name_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'name_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-2 d-sm-none">&nbsp;</div>
                                        <div class="col-sm-3 col-10 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text(
                                                    'Sort by {sort_field}',
                                                    {
                                                        sort_field: $root.text(
                                                            $root.contents.company_title,
                                                            null,
                                                            false,
                                                            'company_title'
                                                        )
                                                    },
                                                    true
                                                )"
                                                @click.prevent="sortBy('company')"
                                            >
                                                {{ $root.text(
                                                    $root.contents.company_title,
                                                    null,
                                                    true,
                                                    'company_title'
                                                ) }}
                                                <span v-if="(sorted_by == 'company_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'company_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-2 d-sm-none">&nbsp;</div>
                                        <div class="col-sm-2 col-10 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('role') }, true)"
                                                @click.prevent="sortBy('role')"
                                            >
                                                {{ $root.text('User role', null, true) }}
                                                <span v-if="(sorted_by == 'role_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'role_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-2 d-sm-none">&nbsp;</div>
                                        <div v-if="(getUsersStatus() == -1)" class="col-sm-2 col-10">
                                            {{ $root.text('Status', null, true) }}
                                        </div>
                                        <div v-if="(getUsersStatus() == 'active')" class="col-sm-2 col-10">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('status') }, true)"
                                                @click.prevent="sortBy('created')"
                                            >
                                                {{ $root.text('Status', null, true) }}
                                                <span v-if="(sorted_by == 'created_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'created_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div v-if="(getUsersStatus() == 'invited')" class="col-sm-2 col-10">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('status') }, true)"
                                                @click.prevent="sortBy('updated')"
                                            >
                                                {{ $root.text('Status', null, true) }}
                                                <span v-if="(sorted_by == 'updated_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'updated_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div v-if="(getUsersStatus() == 'applied')" class="col-sm-2 col-10">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('status') }, true)"
                                                @click.prevent="sortBy('created')"
                                            >
                                                {{ $root.text('Status', null, true) }}
                                                <span v-if="(sorted_by == 'created_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'created_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div v-if="(getUsersStatus() == 'deleted')" class="col-sm-2 col-10">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('status') }, true)"
                                                @click.prevent="sortBy('deleted')"
                                            >
                                                {{ $root.text('Status', null, true) }}
                                                <span v-if="(sorted_by == 'deleted_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'deleted_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'user-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-sm-1 col-2">
                                            <div v-if="hasProfileImage(item)" class="avatar-xs">
                                                <img
                                                    class="rounded-circle avatar-xs"
                                                    :src="item.profile_image"
                                                    alt="avatar"
                                                >
                                            </div>
                                            <div v-else class="avatar-xs">
                                                <span class="avatar-title rounded-circle">
                                                    {{ $root.initial(item.first_name, item.last_name) }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-3 d-none d-sm-block">
                                            <h5 class="font-size-14 mb-1">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditUser(item)">
                                                    {{ item.first_name + ' ' + item.last_name }}
                                                </a>
                                            </h5>
                                            <p class="text-muted mb-0">{{ item.job_title }}</p>
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.company }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ getRoleName(item.role) }}
                                        </div>
                                        <div
                                            class="col-2 d-none d-sm-block"
                                            style="padding-top: 3px"
                                            :key="'user-status-sm' + item.id + user_details_loaded"
                                        >
                                            <span v-if="(!item.deleted && item.approved && item.email_verified_at)">
                                                {{ $root.text('Registered on {date}',
                                                    { date: formatDate(item.created_at) }, true) }}
                                            </span>
                                            <span v-if="(!item.deleted && !item.approved)">
                                                {{ $root.text('Applied on {date}',
                                                    { date: formatDate(item.created_at) }, true) }}
                                            </span>
                                            <span v-if="(!item.deleted && item.created_by && !item.email_verified_at)">
                                                <span
                                                    v-if="inviteExpired(item)"
                                                    class="badge badge-danger font-size-12"
                                                >{{ $root.text('Invite expired', null, true) }}</span>
                                                <span v-else style="margin-top: 1px">
                                                    {{ $root.text('Invited on {date}',
                                                        { date: formatDate(item.created_at) }, true) }}
                                                </span>
                                            </span>
                                            <span v-if="item.deleted">
                                                {{ $root.text('Deactivated on {date}',
                                                    { date: formatDate(item.deleted_at) }, true) }}
                                            </span>
                                        </div>
                                        <div class="col-8 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditUser(item)">
                                                    {{ item.first_name + ' ' + item.last_name }}
                                                </a>
                                            </h5>
                                            <p class="text-muted mb-1">{{ item.job_title }}</p>
                                            <p class="mb-1">{{ item.company }}</p>
                                            <p class="mb-1">{{ getRoleName(item.role) }}</p>
                                            <p class="mb-1" :key="'user-status-xs' + item.id + user_details_loaded">
                                                <span v-if="(getUsersStatus() == 'active')">
                                                    {{ $root.text('Registered on {date}',
                                                        { date: formatDate(item.created_at) }, true) }}
                                                </span>
                                                <span v-if="(getUsersStatus() == 'applied')">
                                                    {{ $root.text('Applied on {date}',
                                                        { date: formatDate(item.created_at) }, true) }}
                                                </span>
                                                <span v-if="(getUsersStatus() == 'invited')">
                                                    <span
                                                        v-if="inviteExpired(item)"
                                                        class="badge badge-danger font-size-12"
                                                    >{{ $root.text('Invite expired', null, true) }}</span>
                                                    <span v-else style="margin-top: 1px">
                                                        {{ $root.text('Invited on {date}',
                                                            { date: formatDate(item.created_at) }, true) }}
                                                    </span>
                                                </span>
                                                <span v-if="(getUsersStatus() == 'deleted')">
                                                    {{ $root.text('Deactivated on {date}',
                                                        { date: formatDate(item.deleted_at) }, true) }}
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditUser(item)"
                                            >
                                                <span
                                                    v-if="(user_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'user-details' + item.id">
                                            <user-edit-form
                                                :data="item"
                                                :company_name="client_company_name"
                                                :roles="roles"
                                                :groups="user_groups"
                                                :profiles="profile_groups"
                                                :is_visible="(user_details_open.indexOf(item.id) > -1)"
                                            ></user-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            v-if="$root.hasPermission('users_reset')"
            class="modal fade"
            id="reset-user-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="reset-user-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <reset-user></reset-user>
            </div>
        </div>

        <div
            v-if="$root.hasPermission('users_approve')"
            class="modal fade"
            id="decline-user-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="decline-user-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <decline-user></decline-user>
            </div>
        </div>

        <div
            v-if="$root.hasPermission('users_invite')"
            class="modal fade"
            id="cancel-invite-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="cancel-invite-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <cancel-invite></cancel-invite>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'users',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                user_details_open: [],
                get_content_response: null,
                client_company_name: '',
                roles: [],
                user_groups: [],
                sorted_by: 'name_asc',
                sorting: false,
                user_details_loaded: 0,
                profile_groups: []
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            if (this.$route.name == 'expired-invites') {
                this.$root.user_filters = [];
                this.$root.addFilter('user_filters', {
                    key: 'expired',
                    value: 1,
                    text: this.$root.text('Expired invites', null, true),
                    filters_key: 'user_filters'
                });
                this.$root.addFilter('user_filters', {
                    key: 'status',
                    value: 'invited',
                    text: this.$root.text('Invited', null, true),
                    filters_key: 'user_filters'
                });
                this.$router.push({ name: 'users' });

                return null;
            }
            if (this.$route.name == 'new-users') {
                this.$root.user_filters = [];
                this.$root.addFilter('user_filters', {
                    key: 'status',
                    value: 'applied',
                    text: this.$root.text('Applied', null, true),
                    filters_key: 'user_filters'
                });
                this.$router.push({ name: 'users' });

                return null;
            }
            if (this.$root.user_filters.length == 0) {
                this.$root.addFilter('user_filters', {
                    key: 'status',
                    value: -1,
                    text: null,
                    filters_key: 'user_filters',
                    hidden: true
                });
            }
            $('body').removeClass('login-body');

            if (
                !this.$root.hasPermission('users_view_details')
                && !this.$root.hasPermission('users_view_deleted_users')
                && !this.$root.hasPermission('users_invite')
            ) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            this.loading = true;
            this.getRoles();
            //this.getUsers();
            this.getUserGroups();
            if (this.$root.isFeatured('profiles')) {
                this.getProfiles();
            }
            this.client_company_name = this.$root.client.client_name;
            if (this.$root.client.company_contact_details) {
                this.$root.getContent(this, 'company_name');
            }
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'company_name') {
                            this.client_company_name = val.content;
                        }
                    }
                }
            },
            total_items: function (val) {
                if (val == 1) {
                    this.$root.status_info.right = this.$root.text('1 people');

                    return null;
                }
                this.$root.status_info.right = this.$root.text('{x} people', { x: val });
            }
        },
        methods: {
            getUsers: function (page = 1, getExport = false) {
                const status = this.getUsersStatus();
                if (
                    !getExport
                    && ((this.loading && !status) || (page < 1) || ((page > 1) && (page <= this.current_page)))
                ) {
                    return false;
                }
                if (!getExport) {
                    this.current_page = page;
                    this.loading = true;
                    this.errors = false;
                }

                // Update sorted by.
                const currentSort = this.sorted_by.split('_');
                if (
                    ((status == -1) ||(status == 'active') || (status == 'applied'))
                    && ((currentSort[0] == 'updated') || (currentSort[0] == 'deleted'))
                ) {
                    this.sorted_by = 'created_' + currentSort[1];
                }
                if ((status == 'invited') && ((currentSort[0] == 'created') || (currentSort[0] == 'deleted'))) {
                    this.sorted_by = 'updated_' + currentSort[1];
                }
                if ((status == 'deleted') && ((currentSort[0] == 'updated') || (currentSort[0] == 'created'))) {
                    this.sorted_by = 'deleted_' + currentSort[1];
                }

                let filters = ['sb::' + this.sorted_by];
                if (!getExport) {
                    filters.push('!export');
                }
                this.$root.user_filters.forEach((item) => {
                    if ((item.key == 'expired') || (item.key == '!expired')) {
                        filters.push(item.key);
                    }
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                    if (item.key == 'role') {
                        filters.push('ur::' + item.value);
                    }
                });
                switch (status) {
                    case 'active':
                        filters.push('approved');
                        filters.push('!deleted');
                        filters.push('!invited');
                        break;
                    case 'invited':
                        filters.push('invited');
                        filters.push('!deleted');
                        break;
                    case 'applied':
                        filters.push('applied');
                        filters.push('!deleted');
                        filters.push('!approved');
                        break;
                    case 'deleted':
                        filters.push('deleted');
                        break;
                }
                const filtersString = (filters.length > 0) ? '/' + filters.join('|') : '';

                if (page == 1 && !getExport) {
                    this.$root.report_xls_download = null;
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.user_details_open = [];
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/users'
                    + filtersString + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if ('xls_download' in response.data) {
                        this.$root.report_xls_download = response.data.xls_download;

                        return null;
                    }
                    vm.total_items = response.data.users.total;
                    vm.current_page = response.data.users.current_page;
                    vm.last_page = response.data.users.last_page;
                    response.data.users.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.items.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.items.push(item);
                        }
                    });
                    // If first page get Excel export.
                    if (vm.current_page == 1) {
                        vm.getUsers(1, true);
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                    vm.sorting = false;
                });
            },
            formatDate: function (rawDate) {
                if (!rawDate) {
                    return '';
                }
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getUsers(this.current_page + 1);
                    }
                }
            },
            toggleEditUser: function (user) {
                if (this.user_details_open.indexOf(user.id) < 0) {
                    this.user_details_open.push(user.id);
                    $('#user-row' + user.id).addClass('highlighted');
                    $('#user-details' + user.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#user-details' + user.id).slideUp('slow', function () {
                        $('#user-row' + user.id).removeClass('highlighted');
                        for (var n = 0; n < vm.user_details_open.length; n++) { 
                            if (vm.user_details_open[n] == user.id) { 
                                vm.user_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            doNothing: function () {
                return false;
            },
            getRoles: function () {
                /**
                 * Send get roles request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.roles = response.data.roles;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroups: function () {
                /**
                 * Send get user groups request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getProfiles: function () {
                /**
                 * Send get profile groups request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-groups/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_groups = response.data.profile_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getRoleName: function (roleId) {
                let name = '';
                this.roles.forEach((item) => {
                    if (item.id == roleId) {
                        name = item.name;
                    }
                });

                return name;
            },
            sortBy: function (field) {
                const currentSort = this.sorted_by.split('_');
                let newSort = currentSort;
                if (currentSort[0] == field) {
                    newSort[1] = (currentSort[1] == 'asc') ? 'desc' : 'asc';
                } else {
                    newSort[0] = field;
                }
                this.sorted_by = newSort.join('_');
                this.sorting = true;
                this.getUsers();
            },
            inviteExpired: function (item) {
                if (!item.password_reset_expires_at) {
                    return false;
                }
                if ('password_reset_expires_seconds' in item) {
                    if (item.password_reset_expires_seconds < 0) {
                        return true;
                    }
                }
                const date = new Date();
                const month = ('0' + (date.getMonth() + 1)).slice(-2);
                const day = ('0' + date.getDate()).slice(-2);
                const today = date.getFullYear() + '-' + month + '-' + day;

                return (today > item.password_reset_expires_at.substr(0, 10));
            },
            getUsersStatus: function () {
                const mainNavComponent = this.$root.findComponent(this.$parent, 'main-nav');
                const filtersComponent = this.$root.findComponent(mainNavComponent, 'user-filters');

                return filtersComponent.status;
            },
            hasProfileImage: function (item) {
                if (!item.profile_image) {
                    return false;
                }

                return (item.profile_image.indexOf('data:') > -1);
            }
        }
    }
</script>
