var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"price-panel-view-assets-modal-label"}},[_vm._v("\n            "+_vm._s(_vm.$root.text('{resources} linked to price panel: {panel_title}',
                { resources: _vm.$root.text(_vm.$root.contents.asset_title + 's', null, false, 'assets_title'),
                panel_title: _vm.panel_title }, true))+"\n        ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.loading)?_c('loading'):_c('div',[(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_c('div',[(_vm.assets.length == 0)?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("\n                    "+_vm._s(_vm.$root.text('No {resources} found', { resources: _vm.$root.text(_vm.$root.contents.asset_title + 's',
                        null, false, 'assets_title') }, true))+"\n                ")]):_c('div',{staticClass:"price-panel-assets"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-centered table-hover"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2"},[_vm._v(_vm._s(_vm.$root.text('No.', null, true)))]),_vm._v(" "),_c('div',{staticClass:"col-8"},[_vm._v(_vm._s(_vm.$root.text('Title', null, true)))]),_vm._v(" "),_c('div',{staticClass:"col-2"})])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.assets),function(item){return _c('tr',{key:item.id},[_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2",staticStyle:{"padding-top":"3px"}},[_vm._v("\n                                                "+_vm._s(item.id)+"\n                                            ")]),_vm._v(" "),_c('div',{staticClass:"col-8",staticStyle:{"padding-top":"3px"}},[_vm._v("\n                                                "+_vm._s(item.title)+"\n                                            ")]),_vm._v(" "),_c('div',{staticClass:"col-2 font-size-18 contact-links"},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip"}],staticClass:"float-right",attrs:{"href":"#","title":_vm.$root.text(
                                                        'View {resource}',
                                                        {
                                                            resource: _vm.$root.text(
                                                                _vm.$root.contents.asset_title,
                                                                null,
                                                                false,
                                                                'asset_title'
                                                            )
                                                        },
                                                        true
                                                    )},on:{"click":function($event){$event.preventDefault();return _vm.viewAsset(item.id)}}},[_c('i',{staticClass:"fa-regular fa-eye"})])])])])])}),0)])])])]),_vm._v(" "),(_vm.assets_loading)?_c('div',{staticStyle:{"position":"absolute","left":"0","bottom":"7px","width":"100%"}},[_c('loading')],1):_vm._e()])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
}]

export { render, staticRenderFns }