<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="folder-add-form-modal-label">
                {{ $root.text(
                    'Add {folder}',
                    { folder: $root.text($root.contents.folder_title, null, false, 'folder_title') },
                    true
                ) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="$root.hasPermission('assets_manage_folders')" v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label for="folder-name" id="folder-name-label" class="mandatory-label">
                            {{ $root.text('Name', null, true) }} *
                        </label>
                        <input
                            v-model="folder.name"
                            type="text"
                            id="folder-name"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('folder-name')"
                            v-on:change="$root.unHighlightErrors('folder-name')"
                        >
                    </div>
                </form>
            </div>
        </div>
        <div v-if="($root.hasPermission('assets_manage_folders') && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> {{ $root.text('Add', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Add', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                folder: {}
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                if (!this.$root.hasPermission('assets_manage_folders')) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.folder = {};
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                setTimeout(function () {
                    $('#folder-name').focus();
                }, 500);
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = this.folder;
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                        if (vm.$route.name == 'folders') {
                            const component = vm.$root.findComponent(vm.$parent, 'folders');
                            if (component) {
                                component.getFolders();
                            }
                        }
                    }

                    setTimeout(function () {
                        $('#folder-add-form-modal').modal('hide');
                        if (vm.$route.name != 'folders') {
                            vm.$router.push({ name: 'folders' });
                        }
                    }, 2000);
                    vm.$root.folders_changed = true;
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#folder-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#folder-' + key + '-label').addClass('text-danger');
                                    $('#folder-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
