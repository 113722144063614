<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            {{ $root.text('There are no suppliers added.', null, true) }}
        </div>

        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-5 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Company', null, true) }}
                                        </div>
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Contact', null, true) }}
                                        </div>
                                        <div class="col-sm-3 col-12">
                                            {{ $root.text('Telephone', null, true) }}
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'supplier-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-5 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleEditSupplier(item)"
                                                >
                                                    {{ item.company }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.first_name }} {{ item.last_name }}
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.telephone }}
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleEditSupplier(item)"
                                                >
                                                    {{ item.company }}
                                                </a>
                                            </h5>
                                            <p class="text-muted mb-1">{{ item.first_name }} {{ item.last_name }}</p>
                                            <p class="mb-0">{{ item.telephone }}</p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditSupplier(item)"
                                            >
                                                <span
                                                    v-if="(supplier_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'supplier-details' + item.id">
                                            <print-order-supplier-edit-form :data="item">
                                            </print-order-supplier-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <!--<div
            class="modal fade"
            id="print-order-supplier-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="print-order-supplier-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <print-order-supplier-add-form></print-order-supplier-add-form>
            </div>
        </div>-->
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'print-order-suppliers',
                loading: false,
                errors: false,
                items: [],
                supplier_details_open: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            
            if (!this.$root.hasPermission('printing_manage_suppliers')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            this.getSuppliers();
        },
        watch: {
            //
        },
        methods: {
            getSuppliers: function() {
                this.supplier_details_open = [];
                this.items = [];
                this.loading = true;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-suppliers';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items = response.data.suppliers;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            toggleEditSupplier: function (supplier) {
                if (this.supplier_details_open.indexOf(supplier.id) < 0) {
                    this.supplier_details_open.push(supplier.id);
                    $('#supplier-row' + supplier.id).addClass('highlighted');
                    $('#supplier-details' + supplier.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#supplier-details' + supplier.id).slideUp('slow', function () {
                        $('#supplier-row' + supplier.id).removeClass('highlighted');
                        for (var n = 0; n < vm.supplier_details_open.length; n++) { 
                            if (vm.supplier_details_open[n] == supplier.id) { 
                                vm.supplier_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            }
        }
    }
</script>
