<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-metadata-modal-label">{{ $root.text('Metadata', null, true) }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="loading"><loading></loading></div>

            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>

            <div v-if="(!loading && !updated)">
                <form>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="row">
                        <div
                            class="col-lg-2 col-md-3 col-5"
                            style="height: 400px; overflow-y: auto; border-right: 1px solid #eff2f7"
                        >
                            <div
                                v-for="category in getCategories()"
                                :key="category.index"
                                :class="categoryClass(category.name)"
                                @click="current_category = category.name"
                                style="padding-top: 2px; padding-bottom: 2px"
                            >
                                {{ category.name }}
                                <span class="chevron-right ml-2">
                                    <i class="far fa-chevron-right"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col" style="height: 400px; overflow-y: auto">
                            <div
                                v-for="tag in getTags()"
                                :key="tag.name"
                                class="form-group"
                            >
                                <div v-if="canEdit(tag)">
                                    <label :for="'tag-' + tag.name">{{ tag.label }}</label>
                                    <textarea
                                        v-if="tag.textarea"
                                        v-model="basic_tags[tag.name]"
                                        :id="'tag-' + tag.name"
                                        class="form-control"
                                        :readonly="updating"
                                        style="height: 69px"
                                    ></textarea>
                                    <div v-if="(!tag.textarea && tag.link)" class="input-group">
                                        <input
                                            v-model="basic_tags[tag.name]"
                                            type="text"
                                            :id="'tag-' + tag.name"
                                            class="form-control"
                                            :readonly="updating"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-secondary big-icon"
                                                type="button"
                                                @click="openLink(basic_tags[tag.name])"
                                                v-b-tooltip.hover
                                                :title="$root.text('Open URL in new tab', null, true)"
                                            >
                                                <i class="fal fa-external-link"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <input
                                        v-if="(!tag.textarea && !tag.link)"
                                        v-model="basic_tags[tag.name]"
                                        type="text"
                                        :id="'tag-' + tag.name"
                                        class="form-control"
                                        :readonly="updating"
                                    >
                                    <small v-if="tag.csv" class="form-text text-muted">
                                        <i class="far fa-info-circle mr-1"></i>
                                        {{ $root.text('Semicolons or commas can be used to separate multiple values',
                                            null, true) }}
                                    </small>
                                </div>
                                <div v-else>
                                    <label>{{ tag.label }}</label>
                                    <div v-if="(tag.link && tag.value)" class="input-group">
                                        <div
                                            class="form-control bg-light" 
                                            style="overflow-wrap: break-word; height: auto"
                                            v-html="(tag.value || (tag.value === 0)) ? tag.value : '&nbsp;'"
                                        ></div>
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-secondary big-icon"
                                                type="button"
                                                @click="openLink(tag.value)"
                                                v-b-tooltip.hover
                                                :title="$root.text('Open URL in new tab', null, true)"
                                            >
                                                <i class="fal fa-external-link"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div
                                        v-else
                                        class="form-control bg-light" 
                                        style="overflow-wrap: break-word; height: auto"
                                        v-html="(tag.value || (tag.value === 0)) ? tag.value : '&nbsp;'"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!asset.locked && $root.hasPermission('assets_edit') && !updated && !loading" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="onSubmit()"
                        :disabled="current_category != 'Basic'"
                    >
                        <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['asset'],
        data () {
            return {
                loading: true,
                errors: false,
                updating: false,
                updated: false,
                asset_id: null,
                metadata: {},
                basic_tags: {},
                current_category: 'Basic'
            }
        },
        mounted () {
            if (this.asset) {
                this.resetModal(this.asset.id);
            }
        },
        watch: {
            asset: function (val) {
                if (val) {
                    this.resetModal(val.id);
                }
            }
        },
        methods: {
            resetModal: function (assetId) {
                this.loading = true;
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.asset_id = assetId;
                this.metadata = {};
                this.basic_tags = {};
                this.current_category = 'Basic';
                this.getMetadata();
            },
            getMetadata: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/resource/'
                    + this.asset_id + '/metadata';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.metadata = response.data.metadata;
                    const tags = vm.getTags();
                    tags.forEach((item) => {
                        vm.basic_tags[item.name] = item.value;
                    });
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-metadata-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    }
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            getCategories: function () {
                let categories = [];
                for (const [key, value] of Object.entries(this.metadata)) {
                    categories.push({ index: categories.length, name: key });
                }

                return categories;
            },
            categoryClass: function (category) {
                if (category == this.current_category) {
                    return 'metadata-category active pl-2 pr-2';
                }

                return 'metadata-category pl-2 pr-4';
            },
            getTags: function () {
                let tags = [];
                for (const [key, data] of Object.entries(this.metadata)) {
                    if (key == this.current_category) {
                        for (const [tagName, tagData] of Object.entries(data)) {
                            tagData.name = tagName;
                            tags.push(tagData);
                        }
                    }
                }

                return tags;
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;

                /**
                 * Send update metadata request to API
                 */
                let data = {};
                for (const [key, value] of Object.entries(this.basic_tags)) {
                    if (!this.metadata.Basic[key].readonly) {
                        data[key] = value;
                    }
                }

                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/resource/'
                    + this.asset_id + '/metadata';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = vm.$root.text(
                        '{resource} metadata updated.',
                        { resource: vm.$root.text(vm.$root.contents.asset_title, null, false, 'asset_title') },
                        true
                    );

                    // Close after 2 seconds automatically
                    setTimeout(function () {
                        $('#asset-metadata-modal').modal('hide');
                    }, 2000);

                    setTimeout(function () {
                        vm.resetModal(vm.asset_id);
                    }, 2600);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-metadata-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            onClose: function () {
                var vm = this;
                setTimeout(function () {
                    vm.resetModal(vm.asset_id);
                }, 600);
            },
            canEdit: function (tag) {
                if (this.asset.locked || tag.readonly) {
                    return false;
                }

                return ((this.current_category == 'Basic') && this.$root.hasPermission('assets_edit'));
            },
            openLink: function (link) {
                let linkTrimmed = link.trim();
                if (!linkTrimmed) {
                    return null;
                }
                if ((linkTrimmed.indexOf('http://') < 0) && (linkTrimmed.indexOf('https://') < 0)) {
                    linkTrimmed = 'https://' + linkTrimmed;
                }
                window.open(linkTrimmed, '_blank').focus();
            }
        }
    }
</script>
<style scoped>
    .metadata-category {
        cursor: pointer;
        border-bottom: 1px solid #fff;
    }

    .metadata-category.active, .metadata-category:hover {
        background-color: #eff2f7;
    }

    .metadata-category .chevron-right {
        float: right;
        display: none;
    }

    .metadata-category.active .chevron-right {
        display: block;
        position: relative;
        top: 1px;
    }
</style>
