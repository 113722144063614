<template>
    <div :class="'card asset-preview select-item' + (selected ? ' selected' : '')" @click="selectImage()">
        <div class="thumbnail">
            <img :src="image_src" :alt="title">
        </div>

        <div class="card-body" style="position: relative">
            <div :class="'title' + (canDelete() ? ' pr-4' : '')" :id="'template-image-title' + id">
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="'template-image-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <!--<div>
                <span
                    class="asset-info stay"
                    :id="'template-image-info' + id"
                >
                    {{ id }}
                    <span
                        v-if="extension"
                        class="badge badge-primary font-size-11 ml-1"
                        style="text-transform: uppercase"
                    >
                        {{ extension }}
                    </span>
                </span>
                &nbsp;
            </div>

            <div
                v-if="!image_loading"
                class="font-size-18 contact-links select-asset"
                style="position: absolute; right: 15px; bottom: 6px"
            >
                <a
                    v-if="!selected"
                    :key="'select-image' + id"
                    href="#"
                    @click.prevent="doNothing()"
                    v-b-tooltip.hover
                    title="Select image"
                >
                    <i class="fal fa-circle"></i>
                </a>
                <a
                    v-else
                    :key="'deselect-image' + id"
                    href="#"
                    @click.prevent="doNothing()"
                    v-b-tooltip.hover
                    title="Deselect image"
                >
                    <i class="fas fa-dot-circle"></i>
                </a>
            </div>-->

            <div
                v-if="canDelete()"
                class="font-size-22 contact-links"
                style="position: absolute; right: 15px; bottom: 6px"
            >
                <a
                    href="#"
                    v-b-tooltip.hover
                    :title="$root.text('Delete', null, true)"
                    @click.prevent="deleteImage()"
                >
                    <i class="fal fa-trash-alt"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'selected', 'user_collection_id'],
        data () {
            return {
                id: 0,
                is_asset: false,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                extension: null,
                file_type: null,
                image_loading: true,
                block_select_image: false
            }
        },
        mounted () {
            if (this.data) {
                this.updateData(this.data);
            }
            var vm = this;
            $(window).resize(function () {
                if (!vm.in_selection || vm.is_visible) {
                    vm.short_title = vm.title;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            },
            block_select_image: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.block_select_image = false;
                    }, 200);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = (data.is_asset ? 'asset_' : '') + data.id,
                    this.is_asset = data.is_asset || false;
                    this.title = data.title;
                    this.short_title = data.title;
                    this.extension = data.file_extension || null;
                    this.file_type = data.file_type || null;

                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);

                    if (data.id == 'original') {
                        this.image_src = data.preview;
                        this.image_loading = false;

                        return null;
                    }
                    if (this.is_asset) {
                        this.getAssetPreview();

                        return;
                    }
                    this.getCollectionLinkedFilePreview();
                }
            },
            getAssetPreview: function () {
                /**
                 * Send request to API
                 */
                let size = (this.extension == 'svg') ? 'svg' : 'lowres';
                if (this.file_type == 'image/gif') {
                    size = 'highres';
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.id.substr(6) + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                        vm.image_loading = false;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            getCollectionLinkedFilePreview: function () {
                /**
                 * Send request to API
                 */
                const size = (this.extension == 'svg') ? 'svg' : 'lowres';
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.data.collection_id + '/linked-file/' + this.id + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                        vm.image_loading = false;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#template-image-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#template-image-title' + this.id)[0].scrollHeight > 20) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            selectImage: function () {
                if (this.selected || this.image_loading || this.block_select_image) {
                    return null;
                }
                this.$emit('change', {
                    id: this.is_asset ? parseInt(this.id.substr(6))
                        : (isNaN(this.id) ? this.id : parseInt(this.id)),
                    is_asset: this.is_asset,
                    preview: this.image_src,
                    file_path: this.data.file_path
                });
            },
            doNothing: function () {
                return null;
            },
            canDelete: function () {
                if (!this.data) {
                    return false;
                }
                if (!this.data.is_own_collection) {
                    return false;
                }

                return true;
            },
            deleteImage: function (confirmed = false) {
                if (confirmed) {
                    this.$parent.loading = true;
                    /**
                     * Send request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                        + this.user_collection_id + (this.is_asset ? '/resource/' : '/linked-file/') + this.data.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        if (vm.selected) {
                            // Need to auto-select original image.
                            vm.$parent.selectOriginalImage();

                            return null;
                        }
                        vm.$parent.getImages(null, true);
                    })
                    .catch(function (error) {
                        vm.$parent.errors = error.response.data.error
                            || vm.$root.text('Something went wrong, please try again', null, true);
                        vm.$parent.loading = false;
                    });

                    return null;
                }
                this.block_select_image = true;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: this.$root.text('Delete image', null, true),
                    text: this.$root.text('Are you sure you want to delete: {title}?', {title: this.title }, true),
                    component: this,
                    action: 'delete-image'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                this.deleteImage(true);
            }
        }
    }
</script>
<style scoped>
    .asset-preview.select-item {
        border: 1px solid #ffffff;
        cursor: pointer;
    }

    /*.asset-preview.select-item .thumbnail {
        height: 132px;
    }

    .asset-preview.select-item .thumbnail img {
        max-height: 130px;
    }

    .asset-preview:hover .card-body .asset-info.stay {
        opacity: 1;
    }*/
</style>
