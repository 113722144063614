var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),(_vm.asset_details_loading)?_c('div',[_c('loading')],1):_vm._e(),_vm._v(" "),(_vm.asset_details)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-3 col-sm-4 mb-3"},[_c('div',{staticClass:"bg-light text-center",staticStyle:{"padding":"1px"}},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.image_src,"alt":_vm.data.title}})])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-0"},[_vm._v("\n                            "+_vm._s(_vm.$root.text(_vm.$root.contents.folder_title, null, true, 'folder_title'))+"\n                        ")]),_vm._v(" "),_c('div',[_vm._v(_vm._s((_vm.asset_details ? _vm.asset_details.folder_name : '')))])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-0"},[(_vm.subfoldersCount() == 1)?_c('span',[_vm._v("\n                                "+_vm._s(_vm.$root.text(_vm.$root.contents.subfolder_title, null, true, 'subfolder_title'))+"\n                            ")]):_c('span',[_vm._v("\n                                "+_vm._s(_vm.$root.text(_vm.$root.contents.subfolders_title, null, true, 'subfolders_title'))+"\n                            ")])]),_vm._v(" "),_c('div',[_vm._v(_vm._s((_vm.asset_details ? _vm.asset_details.subfolder_names : '')))])])]),_vm._v(" "),_c('div',{staticClass:"col"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-0"},[_vm._v("\n                            "+_vm._s(_vm.$root.text(
                                '{resource} type',
                                { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
                                true
                            ))+"\n                        ")]),_vm._v(" "),_c('div',[_vm._v(_vm._s((_vm.asset_details ? _vm.asset_details.resource_type_name : '')))])]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-0"},[_vm._v("\n                            "+_vm._s(_vm.$root.text((_vm.keywordsCount() == 1) ? 'Keyword' : 'Keywords', null, true))+"\n                        ")]),_vm._v(" "),_c('div',_vm._l((_vm.getKeywords()),function(item){return _c('span',{key:item.index},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.keywordSearch(item.keyword)}}},[_vm._v("\n                                    "+_vm._s(item.keyword))]),((item.index < (_vm.keywordsCount() - 1)))?_c('span',[_vm._v(",\n                                ")]):_vm._e()])}),0)])])]),_vm._v(" "),(_vm.data.archived)?_c('div',{staticClass:"form-group text-muted"},[_vm._v("\n                "+_vm._s(_vm.$root.text(
                    'This {resource} is archived',
                    { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
                    true
                ))+"\n            ")]):_vm._e()])]):_vm._e(),_vm._v(" "),(_vm.asset_details)?_c('button',{staticClass:"btn btn-secondary mr-sm-2 mb-2 mb-sm-0",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.viewAsset()}}},[_c('i',{staticClass:"far fa-eye mr-1"}),_vm._v("\n        "+_vm._s(_vm.$root.text(
            'View {resource}',
            { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
            true
        ))+"\n    ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }