<template>
    <div>
        <div class="float-right">
            <span v-if="proof.version > 1" class="text-muted" style="position: relative; top: 3px">
                <i class="fal fa-clock-rotate-left mr-1 font-size-18"></i>
            </span>
            <div v-if="proof.version > 1" class="btn-group mr-2">
                <button
                    type="button"
                    class="btn btn-light btn-sm dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style="text-align: left; width: 80px"
                >
                    <span v-if="version">{{ $root.text('Cycle {x}', { x: version }, true) }}</span>
                    <span v-else>{{ $root.text('All', null, true) }}</span>
                    <i
                        class="fas fa-chevron-down"
                        style="font-size: 9px; position: absolute; top: 8px; right: 10px"
                    ></i>
                </button>
                <div class="dropdown-menu">
                    <a
                        class="dropdown-item"
                        :key="'version-all'"
                        href="#"
                        @click.prevent="version = null"
                    >{{ $root.text('All', null, true) }}</a>
                    <a
                        v-for="ver in getProofVersions()"
                        class="dropdown-item"
                        :key="'version-' + ver"
                        href="#"
                        @click.prevent="version = ver"
                    >{{ $root.text('Cycle {x}', { x: ver }, true) }}</a>
                </div>
            </div>
            <button
                v-if="!isDownloadingXls()"
                :key="'download-history'"
                type="button"
                class="btn btn-primary btn-sm"
                @click.prevent="downloadHistory(xls_download)"
                :disabled="loading || !xls_download || downloading"
            >
                <i class="far fa-download mr-1"></i> Excel
            </button>
            <button v-else :key="'downloading-history'" type="button" class="btn btn-primary btn-sm">
                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                <span style="opacity: 0.5">Excel</span>
            </button>
        </div>
        <h5 class="mb-4">{{ $root.text('History', null, true) }}</h5>
        <loading v-if="loading"></loading>
        <div v-else class="table-responsive">
            <table class="table table-centered table-hover">
                <thead class="thead-light">
                    <tr>
                        <th scope="col">
                            <div class="row">
                                <div class="col-5">{{ $root.text('User', null, true) }}</div>
                                <div class="col">{{ $root.text('Action', null, true) }}</div>
                                <div class="col-1 pl-0">&nbsp;</div>
                                <div class="col-3">{{ $root.text('Date/time', null, true) }}</div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody v-for="item in history" :key="'version-' + item.version">
                    <tr>
                        <td scope="row" class="bg-light py-2">
                            <div class="row">
                                <div class="col-sm-4 col-12" style="padding-top: 2px; padding-bottom: 2px">
                                    {{ $root.text('Cycle {x}', { x: item.version }, true) }}
                                </div>
                                <div class="col">
                                    <span v-if="item.version in version_files" class="float-right">
                                        <span v-if="version_files[item.version]" class="ml-2">
                                            <button
                                                v-if="downloading != version_files[item.version].file_path"
                                                :key="'download-version' + item.version"
                                                type="button"
                                                class="btn btn-secondary btn-sm"
                                                @click.prevent="downloadHistory(version_files[item.version])"
                                                :disabled="downloading"
                                            >
                                                <i class="far fa-download mr-1"></i>
                                                {{ $root.text('Last review', null, true) }}
                                            </button>
                                            <button
                                                v-else
                                                :key="'downloading-version' + item.version"
                                                type="button"
                                                class="btn btn-secondary btn-sm"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">
                                                    {{ $root.text('Last review', null, true) }}
                                                </span>
                                            </button>
                                        </span>
                                    </span>
                                    <span v-if="item.version in original_files" class="float-right">
                                        <span v-if="original_files[item.version]">
                                            <button
                                                v-if="downloading != original_files[item.version].file_path"
                                                :key="'download-original' + item.version"
                                                type="button"
                                                class="btn btn-secondary btn-sm"
                                                @click.prevent="downloadHistory(original_files[item.version])"
                                                :disabled="downloading"
                                            >
                                                <i class="far fa-download mr-1"></i>
                                                {{ $root.text('Original proof', null, true) }}
                                            </button>
                                            <button
                                                v-else
                                                :key="'downloading-original' + item.version"
                                                type="button"
                                                class="btn btn-secondary btn-sm"
                                            >
                                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                                <span style="opacity: 0.5">
                                                    {{ $root.text('Original proof', null, true) }}
                                                </span>
                                            </button>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr v-for="action in item.actions" :key="'action-' + action.id">
                        <td scope="row">
                            <div class="row">
                                <div class="col-1">
                                    <div v-if="getProfileImage(action.user_id)" class="avatar-xs">
                                        <img
                                            class="rounded-circle avatar-xs"
                                            :src="getProfileImage(action.user_id)"
                                            alt="avatar"
                                        >
                                    </div>
                                    <div v-else class="avatar-xs">
                                        <span class="avatar-title rounded-circle">
                                            {{ $root.initial(action.first_name, action.last_name) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <p class="mb-0">
                                        {{ action.first_name + ' ' + action.last_name }}
                                    </p>
                                    <p class="small text-muted mb-0" style="overflow-wrap: anywhere;">
                                        {{ action.email }}
                                    </p>
                                </div>
                                <div class="col" style="padding-top: 3px">
                                    {{ getStatusText(action.status) }}<span
                                        v-if="action.comments && (action.status == 'SK')"
                                    >: {{ action.comments }}</span>
                                </div>
                                <div class="col-1 pl-0 font-size-18 contact-links" style="padding-top: 3px">
                                    <span v-if="action.file">
                                        <span
                                            v-if="downloading == action.file.file_path"
                                            :key="'downloading' + action.id"
                                        >
                                            <i class="fal fa-spinner-third fa-spin"></i>
                                        </span>
                                        <span
                                            v-else
                                            :key="'download' + action.id"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('Download returned proof', null, true)"
                                            @click="downloadHistory(action.file)"
                                            style="cursor: pointer"
                                        >
                                            <i class="far fa-download"></i>
                                        </span>
                                    </span>
                                </div>
                                <div class="col-3" style="padding-top: 3px">
                                    {{ action.created_at_formatted }}
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof'],
        data () {
            return {
                loading: true,
                errors: false,
                history: [],
                user_profile_images: [],
                version: null,
                downloading: null,
                xls_download: null,
                original_files: {},
                version_files: {}
            }
        },
        mounted () {
            this.version = this.proof.version;
        },
        watch: {
            version: function (val) {
                this.loading = true;
                this.getHistory();
            }
        },
        methods: {
            getHistory: function () {
                /**
                 * Send get proof actions request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/actions' + (this.version ? '/' + this.version : '');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let history = [];
                    let version = 0;
                    let actions = [];
                    response.data.actions.forEach((item) => {
                        if (item.version != version) {
                            if (version) {
                                history.push({
                                    version: version,
                                    actions: actions
                                });
                                actions = [];
                            }
                            version = item.version;
                        }
                        actions.push(item);
                    });
                    if (version) {
                        history.push({
                            version: version,
                            actions: actions
                        });
                    }
                    vm.history = history;
                    vm.user_profile_images = response.data.user_profile_images;
                    vm.xls_download = response.data.xls_download;
                    vm.original_files = response.data.original_files;
                    vm.version_files = response.data.version_files;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getProofVersions: function () {
                let versions = [];
                for (let version = 1; version <= this.proof.version; version++) {
                    versions.push(version);
                }

                return versions;
            },
            getProfileImage: function (userId) {
                if (!this.user_profile_images) {
                    return null;
                }
                let itemIndex = -1;
                this.user_profile_images.forEach((item, index) => {
                    if (userId == item.user_id) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return this.user_profile_images[itemIndex].image_data;
                }

                return null;
            },
            getStatusText: function (status) {
                let text = '';
                switch (status) {
                    case 'DL':
                        text = this.$root.text('Downloaded', null, true);
                        break;
                    case 'NC':
                        text = this.$root.text('Approved with no changes', null, true);
                        break;
                    case 'UR':
                        text = this.$root.text('Not reviewed', null, true);
                        break;
                    case 'NP':
                        text = this.$root.text('New proof', null, true);
                        break;
                    case 'SP':
                        text = this.$root.text('Proof suspended', null, true);
                        break;
                    case 'RT':
                        text = this.$root.text('Proof made available', null, true);
                        break;
                    case 'AP':
                        text = this.$root.text('Proof approved final', null, true);
                        break;
                    case 'WC':
                        text = this.$root.text('Returned with changes', null, true);
                        break;
                    case 'MC':
                        text = this.$root.text('Approved - minor changes', null, true);
                        break;
                    case 'SK':
                        text = this.$root.text('Skipped by the leader', null, true);
                }

                return text;
            },
            downloadHistory: function (download) {
                if (this.downloading) {
                    return null;
                }
                this.downloading = download.file_path;
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'proof_actions',
                    item_id: this.proof.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/' + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            isDownloadingXls: function () {
                if (!this.downloading) {
                    return false;
                }
                if (!this.xls_download) {
                    return false;
                }

                return (this.downloading == this.xls_download.file_path);
            }
        }
    }
</script>
