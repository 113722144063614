var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),((!_vm.errors && !_vm.loading && (_vm.items.length == 0)))?_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_vm._v("\n        "+_vm._s(_vm.$root.text(
            'There are no administrators added. Currently all alerts go to {support_email}.',
            { support_email: _vm.$root.client.support_email },
            true
        ))+".\n    ")]):_vm._e(),_vm._v(" "),((_vm.items.length > 0))?_c('p',[_vm._v("\n        "+_vm._s(_vm.$root.text(
            'Configure which administrators will receive notifications. Tick the notification type(s) '
                + 'and then select the {folders} from the drop down menu.',
            { folders: _vm.$root.text(_vm.$root.contents.folders_title, null, false, 'folders_title') },
            true
        ))+"  \n    ")]):_vm._e(),_vm._v(" "),((_vm.items.length > 0))?_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive",staticStyle:{"padding-bottom":"170px"}},[_c('table',{staticClass:"table table-centered table-hover"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3 col-12 pb-sm-0 pb-1"},[_vm._v("\n                                        "+_vm._s(_vm.$root.text('Administrator', null, true))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-sm-5 col-12 pb-sm-0 pb-1"},[_vm._v("\n                                        "+_vm._s(_vm.$root.text('Alerts', null, true))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-sm-3 col-12"},[_vm._v("\n                                        "+_vm._s(_vm.$root.text(
                                            'Assigned to {folders}',
                                            {
                                                folders: _vm.$root.text(
                                                    _vm.$root.contents.folders_title,
                                                    null,
                                                    false,
                                                    'folders_title'
                                                )
                                            },
                                            true
                                        ))+"\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"col-sm-1 d-none d-sm-block"},[_vm._v(" ")])])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"pb-0",attrs:{"scope":"row","id":'alert-row' + item.id}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 d-none d-sm-block",staticStyle:{"padding-top":"3px"}},[_c('h5',{staticClass:"font-size-14 mb-1"},[_c('a',{staticClass:"text-dark",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleEditAlert(item)}}},[_vm._v("\n                                                "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+"\n                                            ")])]),_vm._v(" "),_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(item.email))])]),_vm._v(" "),_c('div',{staticClass:"col-5 d-none d-sm-block",staticStyle:{"padding-top":"2px"}},[(!_vm.hasAlert(item))?_c('span',{staticStyle:{"margin-top":"1px"}},[_vm._v("\n                                            "+_vm._s(_vm.$root.text('None', null, true))+"\n                                        ")]):_vm._e(),_vm._v(" "),(_vm.hasAlert(item) && (_vm.alert_details_open.indexOf(item.id) < 0))?_c('span',_vm._l((_vm.getAlertOptions()),function(option){return _c('span',{key:option.key},[(item[option.key])?_c('span',{staticClass:"badge badge-info font-size-12 mr-2 mb-2"},[_vm._v(_vm._s(option.name))]):_vm._e()])}),0):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-3 d-none d-sm-block",staticStyle:{"padding-top":"3px"}},[((item.folders.length == 0))?_c('span',[_vm._v("\n                                            "+_vm._s(_vm.$root.text(
                                                'All {folders}',
                                                {
                                                    folders: _vm.$root.text(
                                                        _vm.$root.contents.folders_title,
                                                        null,
                                                        false,
                                                        'folders_title'
                                                    )
                                                },
                                                true
                                            ))+"\n                                        ")]):_c('span',[((_vm.folders.length > 0))?_c('span',[_vm._v("\n                                                "+_vm._s(_vm.getFolderNames(item.folders))+"\n                                            ")]):_c('span',[_c('loading',{attrs:{"align":'left',"size":1}})],1)])]),_vm._v(" "),_c('div',{staticClass:"col-10 d-sm-none"},[_c('h5',{staticClass:"font-size-14 mb-1"},[_c('a',{staticClass:"text-dark",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleEditAlert(item)}}},[_vm._v("\n                                                "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+"\n                                            ")])]),_vm._v(" "),_c('p',{staticClass:"text-muted mb-1"},[_vm._v(_vm._s(item.email))]),_vm._v(" "),(!_vm.hasAlert(item))?_c('p',{staticClass:"mb-1"},[_vm._v("\n                                            "+_vm._s(_vm.$root.text('None', null, true))+"\n                                        ")]):_vm._e(),_vm._v(" "),(_vm.hasAlert(item) && (_vm.alert_details_open.indexOf(item.id) < 0))?_c('p',{staticClass:"mb-0"},_vm._l((_vm.getAlertOptions()),function(option){return _c('span',{key:option.key},[(item[option.key])?_c('span',{staticClass:"badge badge-info font-size-12 mr-2 mb-1"},[_vm._v(_vm._s(option.name))]):_vm._e()])}),0):_vm._e(),_vm._v(" "),_c('p',{staticClass:"mb-0"},[((item.folders.length == 0))?_c('span',[_vm._v("\n                                                "+_vm._s(_vm.$root.text(
                                                    'All {folders}',
                                                    {
                                                        folders: _vm.$root.text(
                                                            _vm.$root.contents.folders_title,
                                                            null,
                                                            false,
                                                            'folders_title'
                                                        )
                                                    },
                                                    true
                                                ))+"\n                                            ")]):_c('span',[((_vm.folders.length > 0))?_c('span',_vm._l((_vm.folders),function(folder){return _c('span',{key:folder.id},[((item.folders.indexOf(folder.id) > -1))?_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(folder.name))]):_vm._e()])}),0):_c('span',[_c('loading',{attrs:{"align":'left',"size":1}})],1)])])]),_vm._v(" "),_c('div',{staticClass:"col-sm-1 col-2 font-size-18 contact-links"},[_c('a',{staticClass:"float-right",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleEditAlert(item)}}},[((_vm.alert_details_open.indexOf(item.id) < 0))?_c('span',{key:'close-' + item.id},[_c('i',{staticClass:"fa-regular fa-chevron-circle-down"})]):_c('span',{key:'open-' + item.id},[_c('i',{staticClass:"fa-regular fa-chevron-circle-up"})])])])]),_vm._v(" "),_c('div',{staticClass:"row list-details-td mt-2"},[_c('div',{staticClass:"col-12 list-details",attrs:{"id":'alert-details' + item.id}},[_c('alert-edit-form',{attrs:{"data":item,"alert_options":_vm.getAlertOptions(),"folders":_vm.folders}})],1)])])])}),0)])])])]):_vm._e(),_vm._v(" "),_c('div',{staticStyle:{"position":"relative","top":"-20px","height":"40px","overflow":"visible"}},[(_vm.loading)?_c('loading'):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"modal fade",attrs:{"id":"alert-add-form-modal","data-backdrop":"static","data-keyboard":"false","tabindex":"-1","aria-labelledby":"alert-add-form-modal-label","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-dialog-scrollable"},[_c('alert-add-form')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }