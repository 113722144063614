import { render, staticRenderFns } from "./PrintOrderCopyRecipientAddForm.vue?vue&type=template&id=37d5abde&scoped=true"
import script from "./PrintOrderCopyRecipientAddForm.vue?vue&type=script&lang=js"
export * from "./PrintOrderCopyRecipientAddForm.vue?vue&type=script&lang=js"
import style0 from "./PrintOrderCopyRecipientAddForm.vue?vue&type=style&index=0&id=37d5abde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37d5abde",
  null
  
)

export default component.exports