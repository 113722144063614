<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="collection-add-form-modal-label">
                {{ duplicate
                    ? $root.text('Duplicate collection: {collection}', { collection: duplicate.name }, true)
                    : $root.text('Add collection', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form
                    v-if="($root.hasPermission('assets_access') || $root.hasPermission('templates_view'))"
                    v-on:submit.prevent="onSubmit()"
                >
                    <div class="form-group">
                        <label for="collection-name" id="collection-name-label" class="mandatory-label">
                            {{ $root.text(duplicate ? 'Enter a name for the duplicate collection' : 'Name', null,
                                true) }} *
                        </label>
                        <input
                            v-model="collection.name"
                            type="text"
                            id="collection-name"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('collection-name')"
                            v-on:change="$root.unHighlightErrors('collection-name')"
                        >
                    </div>
                </form>
            </div>
        </div>
        <div
            v-if="(($root.hasPermission('assets_access') || $root.hasPermission('templates_view')) && !updated)"
            class="modal-footer"
        >
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <span v-if="duplicate"><i class="far fa-copy mr-1"></i>
                            {{ $root.text('Duplicate', null, true) }}
                        </span>
                        <span v-else>
                            <span><i class="far fa-check mr-1"></i></span>
                            {{ $root.text('Add', null, true) }}
                        </span>
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">
                        {{ $root.text(duplicate ? 'Duplicate' : 'Add', null, true) }}
                    </span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                collection: {},
                duplicate: null
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (duplicate = null) {
                if (!this.$root.hasPermission('assets_access') && !this.$root.hasPermission('templates_view')) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                this.duplicate = duplicate;
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.collection = {};
                if (duplicate) {
                    this.collection.name = duplicate.name;
                }

                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                setTimeout(function () {
                    $('#collection-name').focus();
                }, 500);
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                let data = this.collection;
                if (this.duplicate) {
                    data.duplicate = this.duplicate.id;
                }
                /*if (!this.$root.current_collection && (this.$root.selected_assets.length > 0)) {
                    data.initial_resources = this.$root.selected_assets;
                }*/
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/collection';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                        /*const pages = ['home', 'assets', 'assets-filtered', 'collections'];
                        if (pages.indexOf(vm.$route.name) > -1) {
                            const component = vm.$root.findComponent(vm.$parent, vm.$route.name);
                            if (component) {
                                component.getCollections();
                            }*/
                            /*if (!vm.$root.current_collection) {
                                vm.$root.current_collection = response.data.collection_id;
                            }*/
                        //}
                    }

                    vm.$root.collection_filters = [];
                    if (vm.$route.name == 'collections') {
                        const component = vm.$root.findComponent(vm.$parent, 'collections');
                        if (component) {
                            component.getCollections();
                        }
                    } else {
                        vm.$router.push({ name: 'collections' });
                    }

                    setTimeout(function () {
                        $('#collection-add-form-modal').modal('hide');
                        /*if (vm.$route.name == 'view-collection') {
                            vm.$router.push({ name: 'collections' });
                        }*/
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#collection-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#collection-' + key + '-label').addClass('text-danger');
                                    $('#collection-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
