<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-change-folder-modal-label">
                {{ $root.text('Change folder', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <loading v-if="loading"></loading>

                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
                
                <div v-if="proof && !loading" style="min-height: 350px">
                    <form v-on:submit.prevent="onSubmit()">
                        <div class="form-group">
                            <label
                                :for="'standalone' + proof.id + '-1'"
                                :id="'standalone-label' + proof.id"
                                class="mandatory-label"
                            >
                                {{ $root.text('Standalone proof?', null, true) }}
                            </label>
                            <div
                                :id="'standalone' + proof.id"
                                class="form-control mandatory-field"
                                style="height: auto"
                            >
                                <div class="mr-4" style="display: inline-block">
                                    <div class="custom-control custom-radio">
                                        <input
                                            :key="'standalone1'"
                                            v-model="standalone"
                                            type="radio"
                                            :id="'standalone' + proof.id + '-1'"
                                            class="custom-control-input"
                                            :value="1"
                                            :disabled="updating"
                                        >
                                        <label
                                            class="custom-control-label"
                                            :for="'standalone' + proof.id + '-1'"
                                        >{{ $root.text('Standalone proof', null, true) }}</label>
                                    </div>
                                </div>
                                <div style="display: inline-block">
                                    <div class="custom-control custom-radio">
                                        <input
                                            :key="'standalone0'"
                                            v-model="standalone"
                                            type="radio"
                                            :id="'standalone' + proof.id + '-0'"
                                            class="custom-control-input"
                                            :value="0"
                                            :disabled="updating"
                                        >
                                        <label
                                            class="custom-control-label"
                                            :for="'standalone' + proof.id + '-0'"
                                        >{{ $root.text('Add to folder', null, true) }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="!standalone" class="form-group">
                            <label
                                :for="'proof_folder_id' + proof.id"
                                :id="'proof_folder_id-label' + proof.id"
                                class="mandatory-label"
                            >
                                {{ $root.text('Folder', null, true) }} *
                            </label>
                            <searchable-option-list
                                :key="'proof_folder_id-' + ($root.current_language || 0)"
                                v-model="proof_folder_id"
                                :value="proof_folder_id"
                                :options="getProofFolderOptions()"
                                :field_id="'proof_folder_id' + proof.id"
                                field_class="mandatory-field"
                                :readonly="updating"
                                v-on:change="$root.unHighlightErrors('proof_folder_id', proof.id)"
                            ></searchable-option-list>
                        </div>

                        <div v-if="(proof_folder_id === 0)" class="form-group">
                            <label
                                :for="'proof_folder_name' + proof.id"
                                :id="'proof_folder_name-label' + proof.id"
                                class="mandatory-label"
                            >
                                {{ $root.text('New folder name', null, true) }} *
                            </label>
                            <input
                                v-model="proof_folder_name"
                                type="text"
                                :id="'proof_folder_name' + proof.id"
                                class="form-control mandatory-field"
                                :readonly="updating"
                                v-on:keyup="$root.unHighlightErrors('proof_folder_name', proof.id)"
                                v-on:change="$root.unHighlightErrors('proof_folder_name', proof.id)"
                            >
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="!loading && !updated" class="modal-footer">
            <span v-if="!updating">
                <button
                    :key="'save-button'"
                    type="button"
                    class="btn btn-primary"
                    @click="onSubmit()"
                    :disabled="!folderChanged()"
                >
                    <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                </button>
            </span>
            <span v-else>
                <button :key="'saving-button'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof'],
        data () {
            return {
                loading: true,
                errors: false,
                updating: false,
                updated: false,
                standalone: 1,
                proof_folder_id: null,
                proof_folders: [],
                proof_folder_name: null
            }
        },
        mounted () {
            //
        },
        watch: {
            standalone: function (val) {
                if (val) {
                    this.proof_folder_id = null;
                }
            }
        },
        methods: {
            reset: function () {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.standalone = 1;
                if (this.proof.proof_folder_id) {
                    this.standalone = 0;
                }
                this.proof_folder_id = this.proof.proof_folder_id;
                this.proof_folder_name = null;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.getProofFolders();
            },
            getProofFolders: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/proof-folders/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let folders = [];
                    response.data.proof_folders.forEach((item) => {
                        if (!item.archived || (item.id == vm.proof_folder_id)) {
                            folders.push(item);
                        }
                    });
                    vm.proof_folders = folders;
                })
                .catch(function (error) {
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getProofFolderOptions: function () {
                let options = [{
                    id: 0,
                    name: this.$root.text('Create new folder', null, true)
                }];
                this.proof_folders.forEach((item) => {
                    options.push(item);
                });

                return options;
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                const data = {
                    standalone: this.standalone,
                    proof_folder_id: this.proof_folder_id,
                    proof_folder_name: this.proof_folder_name
                };

                /**
                 * Send change proof folder request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/change-folder';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = response.data.message;

                    // Close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#proof-change-folder-modal').modal('hide');
                    }, 2000);

                    vm.$parent.getProof(vm.proof.id);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            $('#proof-change-folder-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = vm.$root.text('The fields highlighted below are required.', null, true);
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    $('#' + key + '-label' + vm.proof.id).addClass('text-danger');
                                    $('#' + key + vm.proof.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            folderChanged: function () {
                return (this.proof_folder_id !== this.proof.proof_folder_id);
            }
        }
    }
</script>
