<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <p v-if="(!errors && !loading)">
            {{ $root.text('These users will receive a copy of all orders.', null, true) }}
        </p>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            {{ $root.text('There are no recipients added', null, true) }}
        </div>

        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-5 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Recipient name', null, true) }}
                                        </div>
                                        <div class="col-sm-6 col-12">{{ $root.text('Email', null, true) }}</div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row">
                                    <div class="row">
                                        <div class="col-5 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.first_name }} {{ item.last_name }}
                                        </div>
                                        <div class="col-6 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.email }}
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <p class="mb-1">{{ item.first_name }} {{ item.last_name }}</p>
                                            <p class="mb-0">{{ item.email }}</p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                v-b-tooltip.hover
                                                :title="$root.text('Delete', null, true)"
                                                @click.prevent="deleteRecipient(item)"
                                            >
                                                <i class="fa-regular fa-trash-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="print-order-copy-recipient-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="print-order-copy-recipient-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <print-order-copy-recipient-add-form></print-order-copy-recipient-add-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'print-order-copy-recipients',
                loading: false,
                errors: false,
                items: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getRecipients();
        },
        methods: {
            getRecipients: function () {
                if (!this.$root.hasPermission('printing_email_recipients')) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                this.loading = true;
                this.errors = false;
                this.items = [];

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-copy-recipients';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items = response.data.recipients;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            deleteRecipient: function (item, confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.items = [];
                    this.loading = true;

                    /**
                     * Send delete request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-copy-recipient/'
                        + item;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        //
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error
                            || vm.$root.text('Something went wrong, please try again', null, true);
                    })
                    .finally(() => {
                        vm.getRecipients();
                    });

                    return null;
                }

                this.$parent.confirm_delete = {
                    title: this.$root.text('Delete recipient', null, true),
                    text: this.$root.text('Are you sure you want to remove: {user_name}?',
                        { user_name: item.first_name + ' ' + item.last_name + ' - ' + item.email }, true) + '<br><br>'
                        + this.$root.text('The user will no longer receive a copy of orders.', null, true),
                    component: this,
                    action: 'delete-recipient-' + item.id
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                let itemId = action.substr(17);
                this.deleteRecipient(itemId, true);
            }
        }
    }
</script>
