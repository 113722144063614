<template>
    <div style="position: relative; left: -5px; margin-top: 1px">
        <div class="filter keyword">
            <form v-on:submit.prevent="keywordSearch()">
                <div class="keyword-field">
                    <div class="input-group">
                        <input
                            v-model="keyword"
                            type="text"
                            class="form-control"
                            id="filter-by-keyword"
                            :placeholder="$root.text('Search', null, true)"
                            aria-label="Search"
                            :disabled="isUsersLoading()"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-primary big-icon" type="submit" :disabled="isUsersLoading()">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <button
                        v-if="keyword && !isUsersLoading()"
                        class="btn btn-link clear-keyword"
                        @click.prevent="clearKeyword()"
                    >
                        <i class="far fa-times"></i>
                    </button>
                </div>
            </form>
        </div>
        <div v-if="(roles.length > 0)" class="filter">
            <searchable-option-list
                :key="'role-filter-' + ($root.current_language || 0)"
                v-model="role"
                :value="role"
                :options="getRoleOptions()"
                field_id="filter-by-role"
                :search_placeholder="$root.text('Select a role', null, true)"
                :readonly="isUsersLoading()"
            ></searchable-option-list>
        </div>
        <div v-if="($route.name != 'deletion-requests')" class="filter">
            <searchable-option-list
                :key="'status-filter-' + ($root.current_language || 0)"
                v-model="status"
                :value="status"
                :options="getStatusOptions()"
                field_id="filter-by-status"
                :search_placeholder="$root.text('Select a status', null, true)"
                :readonly="isUsersLoading()"
            ></searchable-option-list>
        </div>
        <div v-if="(status == 'invited')" class="filter">
            <searchable-option-list
                :key="'expired-filter-' + ($root.current_language || 0)"
                v-model="expired"
                :value="expired"
                :options="getExpiredOptions()"
                field_id="filter-by-expired"
                :search_placeholder="$root.text('All invited users', null, true)"
                :readonly="isUsersLoading()"
            ></searchable-option-list>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['user_filters'],
        data() {
            return {
                status: null,
                expired: null,
                keyword: '',
                role: -1,
                roles: []
            };
        },
        mounted () {
            this.user_filters.forEach((item) => {
                if (item.key == 'keyword') {
                    this.keyword = item.value;
                }
            });
            this.getRoles();
            this.updateFilters();
        },
        watch: {
            user_filters: function (val) {
                this.updateFilters();
            },
            role: function (val) {
                if (!val) {
                    this.$root.addFilter('user_filters', {
                        key: 'role',
                        value: null
                    });
                    var vm = this;
                    setTimeout(function () {
                        vm.role = -1;
                    }, 200);

                    return null;
                }
                if ((val != -1) && (this.roles.length > 0)) {
                    this.$root.addFilter('user_filters', {
                        key: 'role',
                        value: val,
                        text: this.getRoleName(val),
                        filters_key: 'user_filters',
                    });
                }
                this.getUsers();
            },
            status: function (val) {
                var vm = this;
                if (this.$route == 'deletion-requests') {
                    return null;
                }
                if (!val) {
                    setTimeout(function () {
                        vm.status = -1;
                    }, 200);

                    return null;
                }
                this.$root.addFilter('user_filters', {
                    key: 'status',
                    value: val,
                    text: this.getStatusName(val),
                    filters_key: 'user_filters',
                    hidden: (val == -1)
                });
                if ((val !== 'invited') && this.expired) {
                    this.expired = null;

                    return null;
                }
                setTimeout(function () {
                    vm.getUsers();
                }, 200);
            },
            expired: function (val) {
                var vm = this;
                if (val !== null) {
                    this.$root.addFilter('user_filters', {
                        key: val ? 'expired' : '!expired',
                        value: 1,
                        text: val ? this.getExpiredOptions()[0].name : this.getExpiredOptions()[1].name,
                        filters_key: 'user_filters'
                    });
                    this.$root.addFilter('user_filters', {
                        key: val ? '!expired' : 'expired',
                        value: null
                    });

                    if (this.status == 'invited') {
                        setTimeout(function () {
                            vm.getUsers();
                        }, 200);
                    }

                    return null;
                }
                // Otherwise remove expired filter.
                this.$root.addFilter('user_filters', {
                    key: 'expired',
                    value: null
                });
                this.$root.addFilter('user_filters', {
                    key: '!expired',
                    value: null
                });
                
                setTimeout(function () {
                    vm.getUsers();
                }, 200);
            }
        },
        methods: {
            updateFilters: function () {
                let roleFound = -1;
                let statusFound = null;
                let expiredFound = null;
                this.user_filters.forEach((item) => {
                    if (item.key == 'role') {
                        roleFound = item.value;
                    }
                    if (item.key == 'status') {
                        statusFound = item.value;
                    }
                    if (item.key == 'expired') {
                        expiredFound = 1;
                    }
                    if (item.key == '!expired') {
                        expiredFound = 0;
                    }
                });

                if (this.role !== roleFound) {
                    this.role = roleFound;
                }

                if (statusFound != 'invited') {
                    expiredFound = null;
                }

                if (this.status !== statusFound) {
                    this.status = statusFound;
                }

                if (this.expired !== expiredFound) {
                    this.expired = expiredFound;
                }
            },
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                
                let keywordsRemoved = false;
                let filters = [];
                this.$root.user_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsRemoved = true;
                    } else {
                        filters.push(item);
                    }
                });
                this.$root.user_filters = filters;

                if (!keywordTrimmed) {
                    if (keywordsRemoved) {
                        this.getUsers();
                    }

                    return null;
                }
                this.$root.addFilter('user_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'user_filters'
                });
                this.getUsers();
            },
            clearKeyword: function () {
                this.keyword = '';
                this.keywordSearch();
                $('#filter-by-keyword').focus();
            },
            getRoleOptions: function () {
                if (this.roles.length == 0) {
                    return null;
                }
                let rolesOrdered = [];
                this.roles.forEach((item) => {
                    rolesOrdered.push(item);
                });
                for (let i = 0; i < (this.roles.length - 1); i++) {
                    for (let j = i + 1; j < this.roles.length; j++) {
                        if (rolesOrdered[j].name < rolesOrdered[i].name) {
                            let temp = rolesOrdered[i];
                            rolesOrdered[i] = rolesOrdered[j];
                            rolesOrdered[j] = temp;
                        }
                    }
                }
                rolesOrdered.unshift({
                    id: -1,
                    name: this.$root.text('All roles', null, true)
                });

                return rolesOrdered;
            },
            getRoleName: function (roleId) {
                if (this.roles.length == 0) {
                    return '';
                }
                let itemIndex = -1;
                this.roles.forEach((item, index) => {
                    if (item.id == roleId) {
                        itemIndex = index;
                    }
                });

                return (itemIndex > -1) ? this.roles[itemIndex].name : '';
            },
            getRoles: function () {
                /**
                 * Send get roles request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.roles = response.data.roles;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUsers: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, this.$route.name);
                if (component) {
                    component.getUsers();
                }
            },
            isUsersLoading: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, this.$route.name);
                if (!component) {
                    return true;
                }

                return component.loading;
            },
            getStatusOptions: function () {
                let options = [];
                if (this.$root.hasPermission('users_view_details')) {
                    options.push({
                        id: -1,
                        name: this.$root.text('All users', null, true)
                    });
                    options.push({
                        id: 'active',
                        name: this.$root.text('Active', null, true)
                    });
                    options.push({
                        id: 'invited',
                        name: this.$root.text('Invited', null, true)
                    });
                    options.push({
                        id: 'applied',
                        name: this.$root.text('Applied', null, true)
                    });
                }
                if (this.$root.hasPermission('users_view_deleted_users')) {
                    options.push({
                        id: 'deleted',
                        name: this.$root.text('Deactivated', null, true)
                    });
                }

                return options;
            },
            getStatusName: function (statusId) {
                const statusOptions = this.getStatusOptions();
                if (statusOptions.length == 0) {
                    return '';
                }
                let itemIndex = -1;
                statusOptions.forEach((item, index) => {
                    if (item.id == statusId) {
                        itemIndex = index;
                    }
                });

                return (itemIndex > -1) ? statusOptions[itemIndex].name : '';
            },
            getExpiredOptions: function () {
                const options = [
                    {
                        id: 1,
                        name: this.$root.text('Expired invites', null, true)
                    },
                    {
                        id: 0,
                        name: this.$root.text('Active invites', null, true)
                    },
                    {
                        id: null,
                        name: this.$root.text('All invited users', null, true)
                    }
                ];

                return options;
            }
        }
    }
</script>
<style scoped>
    .filter {
        display: inline-block;
        width: 200px;
        position: relative;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .filter.keyword {
        width: 300px;
    }

    .keyword-field {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    @media (max-width: 575px) {
        .filter, .filter.keyword {
            width: 100%;
            margin-right: 0;
        }
    }
</style>
