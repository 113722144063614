<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-change-target-dates-modal-label">
                {{ $root.text('Update target dates', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
                
                <div v-if="proof" style="min-height: 350px">
                    <form v-on:submit.prevent="onSubmit()">
                        <div class="form-group">
                            <label
                                :for="'change-target_date' + proof.id"
                                :id="'change-target_date-label' + proof.id"
                                class="mandatory-label"
                            >
                                {{ $root.text('Project target date', null, true) }}
                            </label>
                            <div style="position: relative">
                                <b-form-datepicker
                                    v-model="target_date"
                                    :id="'change-target_date' + proof.id"
                                    class="form-control"
                                    start-weekday="1"
                                    locale="en-GB"
                                    :label-no-date-selected="$root.text('No date selected', null, true)"
                                    :min="minDate('target_date')"
                                    :hideHeader="true"
                                    :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                    :disabled="updating_date || updating"
                                ></b-form-datepicker>
                                <button
                                    v-if="(target_date && !updating)"
                                    class="btn btn-link clear-date"
                                    @click="clearDate('target_date')"
                                >
                                    <i class="far fa-times"></i>
                                </button>
                            </div>
                        </div>
                        <div class="form-group">
                            <label
                                :for="'change-version_target_date' + proof.id"
                                :id="'change-version_target_date-label' + proof.id"
                                class="mandatory-label"
                            >
                                {{ $root.text('Cycle target date', null, true) }}
                            </label>
                            <div style="position: relative">
                                <b-form-datepicker
                                    v-model="version_target_date"
                                    :id="'change-version_target_date' + proof.id"
                                    class="form-control"
                                    start-weekday="1"
                                    locale="en-GB"
                                    :label-no-date-selected="$root.text('No date selected', null, true)"
                                    :min="minDate('version_target_date')"
                                    :hideHeader="true"
                                    :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                                    :disabled="updating_date || updating"
                                ></b-form-datepicker>
                                <button
                                    v-if="(version_target_date && !updating)"
                                    class="btn btn-link clear-date"
                                    @click="clearDate('version_target_date')"
                                >
                                    <i class="far fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="!updating">
                <button
                    :key="'save-button'"
                    type="button"
                    class="btn btn-primary"
                    @click="onSubmit()"
                >
                    <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                </button>
            </span>
            <span v-else>
                <button :key="'saving-button'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof'],
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                target_date: null,
                version_target_date: null,
                updating_date: false
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            reset: function () {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.target_date = this.proof.target_date;
                this.version_target_date = this.proof.version_target_date;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                const data = {
                    title: this.proof.title,
                    leader_alerts: this.proof.leader_alerts,
                    review_alerts_interval: this.proof.review_alerts_interval,
                    target_date: this.target_date,
                    version_target_date: this.version_target_date
                };

                /**
                 * Send update proof request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = response.data.message;

                    // Close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#proof-change-target-dates-modal').modal('hide');
                    }, 2000);

                    vm.$parent.getProof(vm.proof.id);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            $('#proof-change-target-dates-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = vm.$root.text('The leader alert field is required.', null, true);
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    $('#' + key + '-label' + vm.proof.id).addClass('text-danger');
                                    $('#' + key + vm.proof.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            minDate: function (dateField) {
                const today = new Date();
                if (!this[dateField]) {
                    return today;
                }
                const date = new Date(this[dateField]);

                return (date.getTime() < today.getTime()) ? date : today;
            },
            clearDate: function (dateField) {
                this.updating_date = true;
                this[dateField] = null;

                var vm = this;
                setTimeout(function () {
                    vm.updating_date = false;
                }, 200);
            }
        }
    }
</script>
<style scoped>
    .clear-date {
        position: absolute;
        top: 2px;
        right: 2px;
    }
</style>