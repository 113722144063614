<template>
    <div>
        <div v-if="type == 'visits'" class="row">
            <div class="col-6">
                <span :id="'date' + data.id">{{ formatDate(data.created_at) }}</span>
            </div>
            <div class="col-6">
                {{ data.device }}
            </div>
        </div>
        <div v-if="type == 'uploads'" class="row">
            <div class="col-lg-2 col-md-3 col-sm-4 col-6 text-center">
                <img class="img-fluid" :src="image_src" alt="preview">
            </div>
            <div class="col-lg-7 col-md-6 col-sm-8 col-6">
                <p class="mb-2">{{ data.title }}</p>
                <p v-if="asset_details" class="mb-0 text-muted">
                    {{ $root.text(
                        '{resource} type:',
                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                        true
                    ) }}
                    {{ asset_details.resource_type_name }}
                </p>
                <p v-if="asset_details" class="text-muted">
                    {{ $root.text(
                        '{folder}:',
                        { folder: $root.text($root.contents.folder_title, null, false, 'folder_title') },
                        true
                    ) }}
                    {{ asset_details.folder_name }}
                </p>
                <p v-if="asset_details" class="mb-0 d-md-none">
                    {{ $root.text('Uploaded on', null, true) }}
                    <span :id="'datexs' + data.id">{{ formatDate(data.created_at) }}</span>
                </p>
            </div>
            <div class="col-md-3 col-12 d-none d-md-block">
                <span :id="'datemd' + data.id">{{ formatDate(data.created_at) }}</span>
            </div>
        </div>
        <div v-if="type == 'downloads'" class="row">
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
                <img class="img-fluid" :src="image_src" alt="preview">
            </div>
            <div class="col-lg-7 col-md-6 col-sm-8 col-6">
                <p class="mb-2">{{ data.title }}</p>
                <p v-if="asset_details" class="mb-0 text-muted">
                    {{ $root.text(
                        '{resource} type:',
                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                        true
                    ) }}
                    {{ asset_details.resource_type_name }}
                </p>
                <p v-if="asset_details" class="text-muted">
                    {{ $root.text(
                        '{folder}:',
                        { folder: $root.text($root.contents.folder_title, null, false, 'folder_title') },
                        true
                    ) }}
                    {{ asset_details.folder_name }}
                </p>
                <p v-if="asset_details" class="mb-0 d-md-none">
                    {{ $root.text('Downloaded on', null, true) }}
                    <span :id="'datexs' + data.id + '-' + data.download_id">{{ formatDate(data.downloaded_at) }}</span>
                </p>
            </div>
            <div class="col-md-3 col-12 d-none d-md-block">
                <span :id="'datemd' + data.id + '-' + data.download_id">{{ formatDate(data.downloaded_at) }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['type', 'data'],
        data () {
            return {
                image_src: '/images/file-icon.png',
                asset_details: null
            }
        },
        mounted () {
            if ((this.type == 'uploads') || (this.type == 'downloads')) {
                this.getAssetDetails();
                this.getPreviewImage();
            }
        },
        watch: {
            //
        },
        methods: {
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                const size = (this.data.file_extension == 'svg') ? 'svg' : 'lowres';
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.data.id
                    + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            getAssetDetails: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.data.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_details = response.data.resource;
                })
                .catch(function (error) {
                    // Show any errors?
                })
                .finally(() => {
                    //
                });
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            }
        }
    }
</script>
<style scoped>
    .img-fluid {
        max-height: 200px;
    }
</style>
