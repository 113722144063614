<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="template-image-editor-modal-label">
                {{ $root.text('Edit image', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" id="template-image-editor-modal-body">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div v-if="edit_image">
                <div
                    class="btn-group btn-block"
                    role="group"
                    aria-label="Image edit tools"
                >
                    <button
                        type="button"
                        :key="'tie-crop-init'"
                        id="tie-crop-init"
                        :class="'btn btn-'
                            + ((image_edit_mode == 'crop') ? 'secondary' : 'light')"
                        :disabled="isDisabled('crop')"
                        @click.prevent="initCrop()"
                    >
                        <i class="far fa-crop-alt"></i>
                    </button>
                    <b-tooltip
                        v-if="!isDisabled('crop')"
                        :key="'tie-crop-tooltip'"
                        target="tie-crop-init"
                        placement="top"
                        triggers="hover"
                        noninteractive
                    >{{ $root.text('Crop', null, true) }}</b-tooltip>

                    <button
                        type="button"
                        :key="'tie-flip-init'"
                        id="tie-flip-init"
                        :class="'btn btn-'
                            + ((image_edit_mode == 'flip') ? 'secondary' : 'light')"
                        :disabled="isDisabled('flip')"
                        @click.prevent="initFlip()"
                    >
                        <i class="far fa-image-polaroid fa-flip-vertical"></i>
                    </button>
                    <b-tooltip
                        v-if="!isDisabled('flip')"
                        :key="'tie-flip-tooltip'"
                        target="tie-flip-init"
                        placement="top"
                        triggers="hover"
                        noninteractive
                    >{{ $root.text('Flip', null, true) }}</b-tooltip>

                    <button
                        type="button"
                        :key="'tie-rotate-init'"
                        id="tie-rotate-init"
                        :class="'btn btn-'
                            + ((image_edit_mode == 'rotate') ? 'secondary' : 'light')"
                        :disabled="isDisabled('rotate')"
                        @click.prevent="initRotate()"
                    >
                        <i class="far fa-sync-alt"></i>
                    </button>
                    <b-tooltip
                        v-if="!isDisabled('rotate')"
                        :key="'tie-rotate-tooltip'"
                        target="tie-rotate-init"
                        placement="top"
                        triggers="hover"
                        noninteractive
                    >{{ $root.text('Rotate', null, true) }}</b-tooltip>

                    <button
                        type="button"
                        :key="'tie-zoom-in'"
                        id="tie-zoom-in"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click="$refs.templateImageEditor.zoomImage(1.08)"
                    >
                        <i class="far fa-magnifying-glass-plus"></i>
                    </button>
                    <b-tooltip
                        v-if="!updating"
                        :key="'tie-zoom-in-tooltip'"
                        target="tie-zoom-in"
                        placement="top"
                        triggers="hover"
                        noninteractive
                    >{{ $root.text('Zoom in', null, true) }}</b-tooltip>

                    <button
                        type="button"
                        :key="'tie-zoom-out'"
                        id="tie-zoom-out"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click="$refs.templateImageEditor.zoomImage(0.92593)"
                    >
                        <i class="far fa-magnifying-glass-minus"></i>
                    </button>
                    <b-tooltip
                        v-if="!updating"
                        :key="'tie-zoom-out-tooltip'"
                        target="tie-zoom-out"
                        placement="top"
                        triggers="hover"
                        noninteractive
                    >{{ $root.text('Zoom out', null, true) }}</b-tooltip>

                    <button
                        type="button"
                        :key="'tie-undo'"
                        id="tie-undo"
                        class="btn btn-light"
                        :disabled="((image_undo_list.length == 0) || isDisabled('undo'))"
                        @click="imageUndo()"
                    >
                        <i class="far fa-reply"></i>
                    </button>
                    <b-tooltip
                        v-if="((image_undo_list.length > 0) && !isDisabled())"
                        :key="'tie-undo-tooltip'"
                        target="tie-undo"
                        placement="top"
                        triggers="hover"
                        noninteractive
                    >{{ $root.text('Undo', null, true) }}</b-tooltip>

                    <button
                        type="button"
                        :key="'tie-reset'"
                        id="tie-reset"
                        class="btn btn-light"
                        :disabled="((image_undo_list.length == 0) || isDisabled())"
                        @click="imageReset()"
                    >
                        <i class="far fa-repeat"></i>
                    </button>
                    <b-tooltip
                        v-if="((image_undo_list.length > 0) && !isDisabled())"
                        :key="'tie-reset-tooltip'"
                        target="tie-reset"
                        placement="top"
                        triggers="hover"
                        noninteractive
                    >{{ $root.text('Reset', null, true) }}</b-tooltip>

                    <button
                        type="button"
                        :key="'tie-redo'"
                        id="tie-redo"
                        class="btn btn-light"
                        :disabled="((image_redo_list.length == 0) || isDisabled())"
                        @click="imageRedo()"
                    >
                        <i class="far fa-share"></i>
                    </button>
                    <b-tooltip
                        v-if="((image_redo_list.length > 0) && !isDisabled())"
                        :key="'tie-redo-tooltip'"
                        target="tie-redo"
                        placement="top"
                        triggers="hover"
                        noninteractive
                    >{{ $root.text('Redo', null, true) }}</b-tooltip>
                </div>

                <div
                    v-if="image_edit_mode == 'crop'"
                    class="btn-group btn-block mt-1"
                    role="group"
                    aria-label="Apply/cancel crop"
                >
                    <button
                        type="button"
                        :key="'tie-crop-apply'"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="applyCrop()"
                    >
                        <i class="far fa-check mr-1"></i>
                        {{ $root.text('Apply crop', null, true) }}
                    </button>
                    <button
                        type="button"
                        :key="'tie-crop-cancel'"
                        class="btn btn-light"
                        :disabled="(updating || !isCropped())"
                        @click.prevent="cancelCrop()"
                    >
                        <i class="far fa-times mr-1"></i>
                        {{ $root.text('Cancel', null, true) }}
                    </button>
                </div>

                <div
                    v-if="image_edit_mode == 'flip'"
                    class="btn-group btn-block mt-1"
                    role="group"
                    aria-label="Flip horizontally/vertically"
                >
                    <button
                        type="button"
                        :key="'tie-flip-x'"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="applyFlip('X')"
                    >
                        <i class="fal fa-image-polaroid"></i>
                        <i class="far fa-long-arrow-alt-right"></i>
                        <i class="fal fa-image-polaroid fa-flip-horizontal mr-1"></i>
                        {{ $root.text('Horizontal', null, true) }}
                    </button>
                    <button
                        type="button"
                        :key="'tie-flip-y'"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="applyFlip('Y')"
                    >
                        <i class="fal fa-image-polaroid"></i>
                        <i class="far fa-long-arrow-alt-right"></i>
                        <i class="fal fa-image-polaroid fa-flip-vertical mr-1"></i>
                        {{ $root.text('Vertical', null, true) }}
                    </button>
                </div>

                <div
                    v-if="image_edit_mode == 'rotate'"
                    class="btn-group btn-block mt-1"
                    role="group"
                    aria-label="Rotate CW/CCW"
                >
                    <button
                        type="button"
                        :key="'tie-rotate-ccw'"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="image_degrees -= 15"
                    >
                        <i class="far fa-undo-alt mr-1"></i>
                        {{ $root.text('Rotate 15° CCW', null, true) }}
                    </button>
                    <button
                        type="button"
                        :key="'tie-rotate-cw'"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="image_degrees += 15"
                    >
                        <i class="far fa-redo-alt mr-1"></i>
                        {{ $root.text('Rotate 15° CW', null, true) }}
                    </button>
                </div>

                <div class="mt-1" style="height: 400px">
                    <image-editor
                        ref="templateImageEditor"
                        id="template-image-editor"
                        :width="edit_image.width"
                        :height="edit_image.height"
                        :image="edit_image.src"
                        :corner_size="(edit_image.resized ? 15 : 30)"
                        :no_crop_pixels="true"
                        v-on:imageLoaded="onImageLoaded"
                        v-on:error="onImageEditorError"
                        v-on:imageCropped="onImageCropped"
                    ></image-editor>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <span v-if="!updating">
                <button
                    :key="'update-artwork-button'"
                    type="button"
                    class="btn btn-primary"
                    @click="applyImage()"
                    :disabled="isDisabled()"
                >
                    <i class="far fa-eye mr-1"></i> {{ $root.text('Update preview', null, true) }}
                </button>
            </span>
            <span v-else>
                <button :key="'update-artwork-button-spinner'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Update preview', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                image_data: null,
                template_id: null,
                field_id: null,
                caller_component: null,
                auto_image_crop: true,
                // Image editor.
                edit_image: null,
                editor_image: false,
                image_edit_mode: null,
                image_undo_list: [],
                image_redo_list: [],
                image_crop_preset: null,
                image_width: null,
                image_height: null,
                image_degrees: 0,
                freeze_degrees: false,
                image_brightness: 50,
                freeze_brightness: false,
                image_greyscale: false,
                freeze_greyscale: false
            }
        },
        mounted () {
            //
        },
        watch: {
            image_degrees: function (val, oldVal) {
                if (this.freeze_degrees) {
                    this.freeze_degrees = false;

                    return null;
                }
                this.applyRotate(val, oldVal);
            }
        },
        methods: {
            reset: function (data, templateId, fieldId, component = null) {
                this.errors = false;
                this.updating = false;
                this.edit_image = null;
                this.image_data = data;
                this.template_id = templateId;
                this.field_id = fieldId;
                if (component) {
                    this.caller_component = component;
                }
                this.auto_image_crop = true;
                this.editor_image = false;
                this.image_edit_mode = null;
                this.image_undo_list = [];
                this.image_redo_list = [];
                this.image_crop_preset = null;
                this.image_width = null;
                this.image_height = null;
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
                this.freeze_degrees = false;
                this.image_brightness = 50;
                this.freeze_brightness = false;
                this.image_greyscale = false;
                this.freeze_greyscale = false;
                if (!data) {
                    this.errors = this.$root.text('Something went wrong, please try again', null, true);

                    return null;
                }

                var vm = this;
                setTimeout(function () {
                    vm.image_data.width = $('#template-image-editor-modal-body').innerWidth() - 32;
                    vm.image_data.height = 400;
                    vm.edit_image = vm.image_data;
                }, 500);
            },
            isCropped: function () {
                if (
                    (this.image_width == this.editor_image.original_width)
                    && (this.image_height == this.editor_image.original_height)
                ) {
                    return false;
                }

                return true;
            },
            applyImage: function () {
                this.errors = false;
                this.updating = true;
                const data = {
                    resource_id: this.edit_image.resource_id || null,
                    linked_file_id: this.edit_image.linked_file_id || null,
                    image_data: this.$refs.templateImageEditor.getImage((this.edit_image.file_type == 'image/png')
                        ? 'png' : 'jpeg')
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.template_id
                    + '/field/' + this.field_id + '/save-edited-image';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.caller_component.loading = true;
                    const preview = vm.$refs.templateImageEditor.getImage(
                        (vm.edit_image.file_type == 'image/png') ? 'png' : 'jpeg'
                    );
                    vm.caller_component.image_src = preview;
                    const data = {
                        preview: preview,
                        edit_image: null,
                        data: (response.data.file_path || null)
                    };
                    vm.caller_component.$parent.updateTemplateField(
                        vm.caller_component.data,
                        data,
                        true
                    );
                    $('#template-image-editor-modal').modal('hide');

                    setTimeout(function () {
                        vm.caller_component.updateItem();
                    }, 500);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            // Image editor.
            onImageLoaded: function (event) {
                this.editor_image = {
                    original_width: event.newWidth,
                    original_height: event.newHeight,
                };
                this.imageReset(false);
                if (this.auto_image_crop) {
                    this.initCrop();
                }
            },
            onImageEditorError: function (event) {
                console.log(event);
                this.editor_image = null;
            },
            isDisabled: function (mode = null) {
                if (!this.edit_image) {
                    return true;
                }
                if (!this.editor_image) {
                    return false;
                }
                if (this.updating) {
                    return true;
                }
                if (this.auto_image_crop && (mode != 'undo')) {
                    return true;
                }
                if (this.image_edit_mode == mode) {
                    return false;
                }
                if (this.image_edit_mode == 'crop') {
                    return true;
                }

                return false;
            },
            imageUndo: function () {
                if (this.image_undo_list.length == 0) {
                    return null;
                }
                const lastItemIndex = this.image_undo_list.length - 1;
                this.$refs.templateImageEditor.undo(this.image_undo_list[lastItemIndex].steps);

                this.image_redo_list.push({
                    edit_mode: this.image_edit_mode,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: this.image_undo_list[lastItemIndex].steps
                });
                
                this.image_edit_mode = this.image_undo_list[lastItemIndex].edit_mode;
                this.editor_image.max_width = this.image_undo_list[lastItemIndex].max_width;
                this.editor_image.max_height = this.image_undo_list[lastItemIndex].max_height;
                this.image_width = this.image_undo_list[lastItemIndex].width;
                this.image_height = this.image_undo_list[lastItemIndex].height;
                if (this.image_degrees != this.image_undo_list[lastItemIndex].degrees) {
                    this.freeze_degrees = true;
                    this.image_degrees = this.image_undo_list[lastItemIndex].degrees;
                }
                if (this.image_brightness != this.image_undo_list[lastItemIndex].brightness) {
                    this.freeze_brightness = true;
                    this.image_brightness = this.image_undo_list[lastItemIndex].brightness;
                }
                if (this.image_greyscale != this.image_undo_list[lastItemIndex].greyscale) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = this.image_undo_list[lastItemIndex].greyscale;
                }
                this.image_undo_list.splice(lastItemIndex, 1);
                $('.btn-secondary').blur();

                if (!this.isCropped()) {
                    this.auto_image_crop = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.initCrop();
                    }, 500);

                    return null;
                }
            },
            imageReset: function (updateEditor = true) {
                if (updateEditor) {
                    this.$refs.templateImageEditor.reset();
                }
                this.image_edit_mode = null;
                this.image_width = this.editor_image.original_width;
                this.image_height = this.editor_image.original_height;
                this.editor_image.max_width = this.editor_image.original_width;
                this.editor_image.max_height = this.editor_image.original_height;
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
                if (this.image_brightness != 50) {
                    this.freeze_brightness = true;
                    this.image_brightness = 50;
                }
                if (this.image_greyscale != false) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = false;
                }
                $('.btn-secondary').blur();
                this.image_undo_list = [];
                this.image_redo_list = [];

                if (updateEditor) {
                    if (!this.isCropped()) {
                        this.auto_image_crop = true;
                        var vm = this;
                        setTimeout(function () {
                            vm.initCrop();
                        }, 500);

                        return null;
                    }
                }
            },
            imageRedo: function () {
                if (this.image_redo_list.length == 0) {
                    return null;
                }
                const lastItemIndex = this.image_redo_list.length - 1;
                this.$refs.templateImageEditor.redo(this.image_redo_list[lastItemIndex].steps);

                this.image_undo_list.push({
                    edit_mode: this.image_edit_mode,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: this.image_redo_list[lastItemIndex].steps
                });
                
                this.image_edit_mode = this.image_redo_list[lastItemIndex].edit_mode;
                this.editor_image.max_width = this.image_redo_list[lastItemIndex].max_width;
                this.editor_image.max_height = this.image_redo_list[lastItemIndex].max_height;
                this.image_width = this.image_redo_list[lastItemIndex].width;
                this.image_height = this.image_redo_list[lastItemIndex].height;
                if (this.image_degrees != this.image_redo_list[lastItemIndex].degrees) {
                    this.freeze_degrees = true;
                    this.image_degrees = this.image_redo_list[lastItemIndex].degrees;
                }
                if (this.image_brightness != this.image_redo_list[lastItemIndex].brightness) {
                    this.freeze_brightness = true;
                    this.image_brightness = this.image_redo_list[lastItemIndex].brightness;
                }
                if (this.image_greyscale != this.image_redo_list[lastItemIndex].greyscale) {
                    this.freeze_greyscale = true;
                    this.image_greyscale = this.image_redo_list[lastItemIndex].greyscale;
                }
                this.image_redo_list.splice(lastItemIndex, 1);
                $('.btn-secondary').blur();
            },
            initCrop: function () {
                if (this.image_edit_mode == 'crop') {
                    this.cancelCrop();
                    this.image_edit_mode = null;

                    return null;
                }
                this.image_edit_mode = 'crop';
                this.$refs.templateImageEditor.initCrop(this.edit_image.crop_ratio);
            },
            applyCrop: function () {
                this.image_redo_list = [];
                this.$refs.templateImageEditor.applyCrop();
                this.image_edit_mode = null;
            },
            cancelCrop: function () {
                if (!this.$refs.templateImageEditor || this.auto_image_crop) {
                    return null;
                }
                this.$refs.templateImageEditor.cancelCrop();
                this.image_edit_mode = null;
            },
            onImageCropped: function (event) {
                this.image_undo_list.push({
                    edit_mode: null,
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 2
                });
                this.auto_image_crop = false;
                this.image_crop_preset = null;
                this.image_edit_mode = null;
                this.editor_image.max_width = event.width;
                this.editor_image.max_height = event.height;
                if (this.image_width > this.editor_image.max_width) {
                    this.image_width = this.editor_image.max_width;
                }
                if (this.image_height > this.editor_image.max_height) {
                    this.image_height = this.editor_image.max_height;
                }
                if (this.image_degrees != 0) {
                    this.freeze_degrees = true;
                    this.image_degrees = 0;
                }
            },
            initFlip: function () {
                this.image_edit_mode = (this.image_edit_mode == 'flip') ? null : 'flip';
            },
            applyFlip: function (direction) {
                this.image_undo_list.push({
                    edit_mode: 'flip',
                    degrees: this.image_degrees,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.templateImageEditor.applyFlip(direction);
            },
            initRotate: function () {
                this.image_edit_mode = (this.image_edit_mode == 'rotate') ? null : 'rotate';
            },
            applyRotate: function (range, oldRange) {
                this.image_undo_list.push({
                    edit_mode: 'rotate',
                    degrees: oldRange,
                    brightness: this.image_brightness,
                    greyscale: this.image_greyscale,
                    width: this.image_width,
                    height: this.image_height,
                    max_width: this.editor_image.max_width,
                    max_height: this.editor_image.max_height,
                    steps: 1
                });
                this.image_redo_list = [];
                this.$refs.templateImageEditor.rotate(range);
            }
        }
    }
</script>
