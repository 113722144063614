<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="resourcepage-modal-label">{{ $root.text('Font resource page', null, true) }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div :style="'display: ' + (updated ? 'none' : 'block')">
                <div v-if="loading"><loading></loading></div>

                <div v-if="loading_errors" v-html="loading_errors" class="alert alert-danger" role="alert"></div>

                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div class="row" :style="'display: ' + ((!loading && !loading_errors) ? 'flex' : 'none')">
                    <div class="col-lg-5 col-12">
                        <div v-if="!resourcepage" class="alert alert-warning" role="alert">
                            {{ $root.text('There is no font resource page currently uploaded.', null, true) }}
                        </div>
                        <div v-else>
                            <p v-html="updatedBy()"></p>
                            <button
                                v-if="!downloading"
                                :key="'download-resourcepage-button'"
                                class="btn btn-primary"
                                type="button"
                                @click="downloadFile(download_data)"
                            >
                                <i class="far fa-file-pdf mr-1"></i>
                                {{ $root.text('Download font resource page', null, true) }}
                            </button>
                            <button
                                v-else
                                :key="'downloading-resourcepage-button'"
                                class="btn btn-primary"
                                type="button"
                            >
                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                {{ $root.text('Downloading font resource page', null, true) }}
                            </button>
                        </div>
                    </div>
                    <div class="col-lg-7 col-12 mt-lg-0 mt-3">
                        <p class="mb-2">
                            {{ $root.text('Upload new font resource page. Must be a PDF document.', null, true) }}
                        </p>
                        <vue-dropzone
                            ref="resourcepageDropzone"
                            id="resourcepage-dropzone"
                            :options="dropzone_options"
                            v-on:vdropzone-sending="onSendingFile"
                            v-on:vdropzone-upload-progress="onProgressUpdate"
                            v-on:vdropzone-success="onFileUploaded"
                            v-on:vdropzone-error="onUploadError"
                            v-on:vdropzone-drag-over="onDragOver"
                            v-on:vdropzone-drag-leave="onDragLeave"
                            v-on:vdropzone-drop="onDragLeave"
                            v-on:vdropzone-file-added="onFileAdded"
                            :useCustomSlot=true
                        >
                            <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                                <div class="dz-thumbnail">
                                    <span v-if="!file.preview_image">
                                        <img
                                            :src="$root.imagePlaceholder(file.filename)"
                                            class="img-fluid"
                                            alt=""
                                        >
                                    </span>
                                    <img
                                        v-else
                                        :src="file.preview_image"
                                        class="img-fluid"
                                        alt=""
                                    >
                                </div>
                                <div class="dz-filename">{{ file.filename }}</div>
                                <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                                <div class="dz-remove">
                                    <a
                                        v-if="!updating"
                                        href="#"
                                        v-b-tooltip.hover
                                        :title="$root.text('Delete', null, true)"
                                        @click.prevent="removeUploadedFile()"
                                    >
                                        <i class="fal fa-trash-alt"></i>
                                    </a>
                                    <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                                </div>
                                <div
                                    class="dz-upload-info"
                                    :id="'upload-info' + file.id"
                                    style="left: 160px"
                                >
                                    <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                                </div>
                                <div
                                    class="dz-progress"
                                    :id="'upload-progress' + file.id"
                                    style="width: calc(100% - 172px)"
                                >
                                    <div class="dz-upload"></div>
                                </div>
                            </div>
                            <a
                                href="#"
                                class="dz-add-files"
                                id="resourcepage-add-files"
                                @click.prevent="doNothing()"
                                :style="dzAddFilesStyle()"
                            >
                                <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                                {{ $root.text('Click or drop PDF file here to upload', null, true) }}
                            </a>
                        </vue-dropzone>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                loading: true,
                loading_errors: false,
                errors: false,
                updating: false,
                updated: false,
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'application/pdf',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (20 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#resourcepage-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                uploading: false,
                resourcepage: null,
                download_data: null,
                downloading: false
            }
        },
        mounted () {
            this.getResourcepage();
        },
        watch: {
            //
        },
        methods: {
            getResourcepage: function () {
                this.loading = true;
                this.loading_errors = false;
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.file = null;
                this.uploading = false;
                this.resourcepage = null;
                this.download_data = null;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resourcepage';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.resourcepage = response.data.resourcepage;
                    vm.download_data = response.data.download_data;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user
                        $('#resourcepage-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 404) {
                        // Ignore not found error.

                        return null;
                    }
                    vm.loading_errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            saveResourcepage: function () {
                this.updating = true;
                /**
                 * Send add/update resourcepage request to API
                 */
                const data = {
                    filename: this.file.filename,
                    file_type: this.file.file_type,
                    file_size: this.file.file_size,
                    temp_file: this.file.temp_file
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resourcepage';
                var vm = this;

                axios({
                    method: (this.resourcepage) ? 'put' : 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.$root.text('Font resource page uploaded.', null, true);
                    setTimeout(function () {
                        vm.getResourcepage();
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#resourcepage-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                if (!this.uploading) {
                    this.errors = false;
                }
                if (this.file) {
                    this.$refs.resourcepageDropzone.removeFile(file);

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.resourcepageDropzone.removeFile(file);
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: this.$root.text('Maximum upload size exceeded', null, true),
                        text: this.$root.text('The maximum size of the image you can upload is {size}.',
                            { size: this.$root.formatFileSize(this.dropzone_options.maxFilesize) }, true) + ' '
                            + this.$root.text('The size of {filename} is {size}.',
                            { filename: file.name, size: this.$root.formatFileSize(file.size) }, true)
                    };
                    $('#message-modal').modal('show');
                    $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });

                    return null;
                }

                this.errors = false;
                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.resourcepageDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html(vm.$root.text('Preparing upload...', null, true));
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#resourcepage-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#resourcepage-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
                //formData.append('create_preview', '2000x2000');
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id)
                        .html(this.$root.formatFileSize(bytesSent) + ' ' + progress.toFixed(1) + '%');
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-info' + this.file.id).html(this.$root.text('Finalising upload...', null, true));
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }

                    this.getUploadInfo(this.file.id);
                    this.$refs.resourcepageDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');
                    vm.saveResourcepage();
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        let error = this.$root.text('Invalid file type: {filename}', { filename: file.name }, true);
                        if (!this.errors) {
                            error += '<br><br>' + this.$root.text('Please choose a PDF document.', null, true);
                            this.errors = error;
                        } else {
                            this.errors = error + '<br>' + this.errors;
                        }
                        this.$refs.resourcepageDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!this.errors) {
                        this.errors = this.$root.text('Something went wrong, please try again', null, true);
                    }
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;
                    this.errors = false;

                    if (removeUploadFileId) {
                        this.$refs.resourcepageDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.resourcepageDropzone.removeFile(file);
                            }
                        });
                        this.$refs.resourcepageDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.resourcepageDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: this.$root.text('Delete file', null, true),
                    text: this.$root.text(
                        'Are you sure you want to delete: {filename}?',
                        { filename: this.file.filename },
                        true
                    ),
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            },
            downloadFile: function (download) {
                if (this.downloading) {
                    return null;
                }
                this.errors = false;
                this.downloading = true;
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'resourcepage',
                    item_id: this.resourcepage.id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#resourcepage-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            updatedBy: function () {
                return this.$root.text(
                    'Last updated on {date} by {user_name}',
                    {
                        date: this.resourcepage.updated_at_formatted,
                        user_name: '<span style="white-space: nowrap">' + this.resourcepage.last_updated_by + '</span>'
                    },
                    true
                );
            }
        }
    }
</script>
<style scoped>
    #resourcepage-dropzone {
        min-height: 126px;
    }

    #resourcepage-add-files {
        padding-top: 45px;
    }

    .dz-uploaded-file {
        cursor: default;
    }
</style>
