<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-else>
            <div v-if="data.print_order_id">
                <print-order-details :data="print_order" :is_visible="print_order_loaded" :view_only="true">
                </print-order-details>
            </div>
            <div v-else class="row">
                <div class="col-xl-2 col-lg-3 col-sm-4 mb-3">
                    <div class="bg-light text-center" style="padding: 1px">
                        <img class="img-fluid" :src="image_src" alt="preview">
                    </div>
                </div>
                <div class="col">
                    <div v-if="data" class="row">
                        <div v-if="$root.isFeatured('profiles')" class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.text($root.contents.profile_title, null, true) }}</label>
                                <div v-html="data.profile_name"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.text('User', null, true) }}</label>
                                <div v-html="data.user_name"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.text('Date', null, true) }}</label>
                                <div v-html="data.created_at_formatted"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">
                                    {{ $root.text($root.contents.asset_title, null, true, 'asset_title') }}
                                </label>
                                <div v-html="data.resource_title"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">
                                    {{ $root.text(
                                        '{resource} No.',
                                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                        true
                                    ) }}
                                </label>
                                <div v-html="data.resource_id"></div>
                            </div>
                        </div>
                        <div class="col-md-4 col-sm-6 col-12">
                            <div class="form-group">
                                <label class="mb-0">{{ $root.text('Action', null, true) }}</label>
                                <div>
                                    <span v-if="data.recipients">
                                        {{ $root.text('shared with {recipient_list}',
                                            { recipient_list: data.recipients.join(', ') }, true) }}
                                    </span>
                                    <span v-else>
                                        {{ $root.text(data.action, null, true) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'is_visible'],
        data () {
            return {
                errors: false,
                print_order: null,
                print_order_loaded: false,
                image_src: '/images/file-icon.png',
            }
        },
        mounted () {
            //
        },
        watch: {
            data: function (val) {
                if (val && this.is_visible) {
                    this.updateData(val);
                }
            },
            is_visible: function (val)  {
                if (val && this.data) {
                    this.updateData(this.data);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data.print_order_id) {
                    if (this.print_order) {
                        return null;
                    }
                    this.getPrintOrder();

                    return null;
                }
                this.getPreviewImage();
            },
            getPrintOrder: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-orders/!pagination|kw::'
                    + btoa(this.data.print_order_id);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let printOrder = response.data.orders[0];
                    printOrder.total_price = parseFloat(printOrder.total_price);
                    vm.print_order = printOrder;
                    setTimeout(function () {
                        vm.print_order_loaded = true;
                    }, 200);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                });
            },
            getStatusDate: function (item, status = null) {
                if (!item) {
                    return '';
                }
                if (!status) {
                    status = item.order_status;
                }
                switch (status) {
                    case 'On hold':
                        return item.on_hold_at ? this.$parent.formatDate(item.on_hold_at) : '';
                    case 'In production':
                        return item.in_production_at ? this.$parent.formatDate(item.in_production_at) : '';
                    case 'Dispatched':
                        return item.dispatched_at ? this.$parent.formatDate(item.dispatched_at) : '';
                    case 'Completed':
                        return item.completed_at ? this.$parent.formatDate(item.completed_at) : '';
                    default:
                        return '';
                }
            },
            getPreviewImage: function () {
                if (this.image_src != '/images/file-icon.png') {
                    return null;
                }
                /**
                 * Send request to API
                 */
                const path = '/template/' + this.data.template_id + '/artwork/' + this.data.artwork_id
                    + '/preview/1/small';
                var vm = this;

                axios({
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + path,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    //
                });
            }
        }
    }
</script>
