var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.type == 'visits')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('span',{attrs:{"id":'date' + _vm.data.id}},[_vm._v(_vm._s(_vm.formatDate(_vm.data.created_at)))])]),_vm._v(" "),_c('div',{staticClass:"col-6"},[_vm._v("\n            "+_vm._s(_vm.data.device)+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.type == 'uploads')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-2 col-md-3 col-sm-4 col-6 text-center"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.image_src,"alt":"preview"}})]),_vm._v(" "),_c('div',{staticClass:"col-lg-7 col-md-6 col-sm-8 col-6"},[_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.data.title))]),_vm._v(" "),(_vm.asset_details)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("\n                "+_vm._s(_vm.$root.text(
                    '{resource} type:',
                    { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
                    true
                ))+"\n                "+_vm._s(_vm.asset_details.resource_type_name)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.asset_details)?_c('p',{staticClass:"text-muted"},[_vm._v("\n                "+_vm._s(_vm.$root.text(
                    '{folder}:',
                    { folder: _vm.$root.text(_vm.$root.contents.folder_title, null, false, 'folder_title') },
                    true
                ))+"\n                "+_vm._s(_vm.asset_details.folder_name)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.asset_details)?_c('p',{staticClass:"mb-0 d-md-none"},[_vm._v("\n                "+_vm._s(_vm.$root.text('Uploaded on', null, true))+"\n                "),_c('span',{attrs:{"id":'datexs' + _vm.data.id}},[_vm._v(_vm._s(_vm.formatDate(_vm.data.created_at)))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-md-3 col-12 d-none d-md-block"},[_c('span',{attrs:{"id":'datemd' + _vm.data.id}},[_vm._v(_vm._s(_vm.formatDate(_vm.data.created_at)))])])]):_vm._e(),_vm._v(" "),(_vm.type == 'downloads')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-2 col-md-3 col-sm-4 col-6"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.image_src,"alt":"preview"}})]),_vm._v(" "),_c('div',{staticClass:"col-lg-7 col-md-6 col-sm-8 col-6"},[_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.data.title))]),_vm._v(" "),(_vm.asset_details)?_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("\n                "+_vm._s(_vm.$root.text(
                    '{resource} type:',
                    { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
                    true
                ))+"\n                "+_vm._s(_vm.asset_details.resource_type_name)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.asset_details)?_c('p',{staticClass:"text-muted"},[_vm._v("\n                "+_vm._s(_vm.$root.text(
                    '{folder}:',
                    { folder: _vm.$root.text(_vm.$root.contents.folder_title, null, false, 'folder_title') },
                    true
                ))+"\n                "+_vm._s(_vm.asset_details.folder_name)+"\n            ")]):_vm._e(),_vm._v(" "),(_vm.asset_details)?_c('p',{staticClass:"mb-0 d-md-none"},[_vm._v("\n                "+_vm._s(_vm.$root.text('Downloaded on', null, true))+"\n                "),_c('span',{attrs:{"id":'datexs' + _vm.data.id + '-' + _vm.data.download_id}},[_vm._v(_vm._s(_vm.formatDate(_vm.data.downloaded_at)))])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-md-3 col-12 d-none d-md-block"},[_c('span',{attrs:{"id":'datemd' + _vm.data.id + '-' + _vm.data.download_id}},[_vm._v(_vm._s(_vm.formatDate(_vm.data.downloaded_at)))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }