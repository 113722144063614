<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="isFormReady()">
            <form v-on:submit.prevent="onSubmit()">
                <div class="row">
                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <label :for="'first_name' + user.id" :id="'name-label' + user.id" class="mandatory-label">
                                {{ $root.text('Name', null, true) }} *
                            </label>
                            <div class="input-group">
                                <input
                                    v-model="user.first_name"
                                    type="text"
                                    :id="'first_name' + user.id"
                                    class="form-control mandatory-field"
                                    :readonly="(read_only || updating)"
                                    :placeholder="placeholder('first_name')"
                                    v-on:focus="placeholder('first_name', true)"
                                    v-on:blur="placeholder('first_name', true)"
                                    v-on:keyup="unHighlightNameErrors()"
                                    v-on:change="unHighlightNameErrors()"
                                >
                                <input
                                    v-model="user.last_name"
                                    type="text"
                                    :id="'last_name' + user.id"
                                    class="form-control mandatory-field"
                                    :readonly="(read_only || updating)"
                                    :placeholder="placeholder('last_name')"
                                    v-on:focus="placeholder('last_name', true)"
                                    v-on:blur="placeholder('last_name', true)"
                                    v-on:keyup="unHighlightNameErrors()"
                                    v-on:change="unHighlightNameErrors()"
                                >
                            </div>
                        </div>
                        <div class="form-group">
                            <label
                                :for="'job_title' + user.id"
                                :id="'job_title-label' + user.id"
                                class="mandatory-label"
                            >
                                {{ $root.text('Job title', null, true) }} *
                            </label>
                            <input
                                v-model="user.job_title"
                                type="text"
                                :id="'job_title' + user.id"
                                class="form-control mandatory-field"
                                :readonly="(read_only || updating)"
                                v-on:keyup="$root.unHighlightErrors('job_title', user.id)"
                                v-on:change="$root.unHighlightErrors('job_title', user.id)"
                            >
                        </div>
                        <div class="form-group">
                            <label :for="'company' + user.id" :id="'company-label' + user.id" class="mandatory-label">
                                {{ $root.text($root.contents.company_title, null, true, 'company_title') }} *
                            </label>
                            <input
                                v-model="user.company"
                                type="text"
                                :id="'company' + user.id"
                                class="form-control mandatory-field"
                                :readonly="(read_only || updating)"
                                v-on:keyup="$root.unHighlightErrors('company', user.id)"
                                v-on:change="$root.unHighlightErrors('company', user.id)"
                            >
                        </div>
                        <div class="form-group">
                            <label :for="'email' + user.id" :id="'email-label' + user.id" class="mandatory-label">
                                {{ $root.text('Email', null, true) }} *
                            </label>
                            <input
                                v-model="user.email"
                                type="email"
                                :id="'email' + user.id"
                                class="form-control mandatory-field"
                                :readonly="(read_only || updating)"
                                v-on:keyup="$root.unHighlightErrors('email', user.id)"
                                v-on:change="$root.unHighlightErrors('email', user.id)"
                            >
                        </div>
                        <div class="form-group">
                            <label :for="'telephone' + user.id" :id="'telephone-label' + user.id" class="mandatory-label">
                                {{ $root.text('Telephone', null, true) }} *
                            </label>
                            <input
                                v-model="user.telephone"
                                type="text"
                                :id="'telephone' + user.id"
                                class="form-control mandatory-field"
                                :readonly="(read_only || updating)"
                                v-on:keyup="$root.unHighlightErrors('telephone', user.id)"
                                v-on:change="$root.unHighlightErrors('telephone', user.id)"
                            >
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div class="form-group">
                            <label :for="'role' + user.id" :id="'role-label' + user.id" class="mandatory-label">
                                {{ $root.text('User role', null, true) }} *
                            </label>
                            <searchable-option-list
                                :key="'role-' + ($root.current_language || 0)"
                                v-model="user.role"
                                :value="user.role"
                                :options="user_roles"
                                :field_id="'role' + user.id"
                                field_class="mandatory-field"
                                :readonly="(read_only || updating)"
                                :search_placeholder="$root.text('Select a role', null, true)"
                                v-on:change="$root.unHighlightErrors('role', user.id)"
                            ></searchable-option-list>
                            <small :id="'role-description' + user.id" class="form-text text-muted">
                                {{ roleDescription() }}
                            </small>
                        </div>
                        <div class="form-group">
                            <label :for="'user_groups' + user.id">
                                {{ $root.text('Assigned to user group(s)', null, true) }}
                            </label>
                            <searchable-option-list
                                :key="'user-groups-' + ($root.current_language || 0)"
                                v-model="user.user_groups"
                                :value="user.user_groups"
                                :multiple="true"
                                :options="user_groups"
                                :field_id="'user_groups' + user.id"
                                field_class="form-control pb-2"
                                :readonly="(read_only || updating)"
                                :search_placeholder="$root.text('Select user group(s)', null, true)"
                            ></searchable-option-list>
                        </div>
                        <div v-if="$root.isFeatured('profiles')" class="form-group">
                            <label
                                :for="'profile_group_id' + user.id"
                                :id="'profile_group_id-label' + user.id"
                                class="mandatory-label"
                            >
                                {{ $root.text($root.contents.profile_group_title, null, true, 'profile_group_title') }} *
                            </label>
                            <searchable-option-list
                                :key="'profile-group-' + ($root.current_language || 0)"
                                v-model="user.profile_group_id"
                                :value="user.profile_group_id"
                                :options="profile_groups"
                                :field_id="'profile_group_id' + user.id"
                                field_class="mandatory-field"
                                :readonly="(read_only || updating)"
                                :search_placeholder="$root.text(
                                    'Select a {profile group}',
                                    {
                                        'profile group': $root.text(
                                            $root.contents.profile_group_title,
                                            null,
                                            false,
                                            'profile_group_title'
                                        )
                                    },
                                    true
                                )"
                                v-on:change="onProfileGroupChange()"
                            ></searchable-option-list>
                        </div>
                        <div v-if="$root.isFeatured('profiles')" class="form-group">
                            <label
                                :for="'profile_id' + user.id"
                                :id="'profile_id-label' + user.id"
                                class="mandatory-label"
                            >
                                {{ $root.text($root.contents.profile_title, null, true) }} *
                            </label>
                            <searchable-option-list
                                :key="'profile-' + ($root.current_language || 0)"
                                v-model="user.profile_id"
                                :value="user.profile_id"
                                :options="getProfileOptions(user.profile_group_id)"
                                :field_id="'profile_id' + user.id"
                                field_class="mandatory-field"
                                :readonly="!canSelectProfile()"
                                :search_placeholder="$root.text('Select a ' + $root.contents.profile_title, null, true)"
                                v-on:change="$root.unHighlightErrors('profile_id', user.id)"
                            ></searchable-option-list>
                        </div>
                        <!--<div class="form-group">
                            <div class="custom-control custom-checkbox">
                                <input
                                    v-model="user.exclude_from_reports"
                                    :id="'exclude_from_reports' + user.id"
                                    type="checkbox"
                                    class="custom-control-input"
                                    :disabled="(read_only || updating)"
                                >
                                <label class="custom-control-label" :for="'exclude_from_reports' + user.id">
                                    Exclude this user from reports
                                </label>
                            </div>
                            <small :id="'exclude_from_reports-description' + user.id" class="form-text text-muted">
                                {{ excludeFromReportsDescription() }}
                            </small>
                        </div>-->
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <div v-if="$root.isFeatured('printing')" class="form-group">
                            <label :for="'currency' + user.id" :id="'currency-label' + user.id" class="mandatory-label">
                                {{ $root.text('Currency', null, true) }} *
                            </label>
                            <searchable-option-list
                                :key="'currency-' + ($root.current_language || 0)"
                                v-model="user.currency"
                                :value="user.currency"
                                :options="currencies"
                                :field_id="'currency' + user.id"
                                field_class="mandatory-field"
                                :readonly="(read_only || updating)"
                                :search_placeholder="$root.text('Select a currency', null, true)"
                                v-on:change="$root.unHighlightErrors('currency', user.id)"
                            ></searchable-option-list>
                        </div>
                        <div v-if="$root.client.company_contact_details">
                            <div class="form-group">
                                <label
                                    :for="'company_contact_name' + user.id"
                                    :id="'company_contact_name-label' + user.id"
                                >
                                    {{ $root.text('Contact at {company_name}', { company_name: company_name }, true) }}
                                </label>
                                <input
                                    v-model="user.company_contact_details.name"
                                    type="text"
                                    :id="'company_contact_name' + user.id"
                                    class="form-control"
                                    :readonly="(read_only || updating)"
                                >
                            </div>
                            <div class="form-group">
                                <label
                                    :for="'company_contact_email' + user.id"
                                    :id="'company_contact_email-label' + user.id"
                                >
                                    {{ $root.text("Contact's email address", null, true) }}
                                </label>
                                <input
                                    v-model="user.company_contact_details.email"
                                    type="email"
                                    :id="'company_contact_email' + user.id"
                                    class="form-control"
                                    :readonly="(read_only || updating)"
                                >
                            </div>
                            <div class="form-group">
                                <label
                                    :for="'company_contact_phone' + user.id"
                                    :id="'company_contact_phone-label' + user.id"
                                >
                                    {{ $root.text("Contact's telephone", null, true) }}
                                </label>
                                <input
                                    v-model="user.company_contact_details.phone"
                                    type="text"
                                    :id="'company_contact_phone' + user.id"
                                    class="form-control"
                                    :readonly="(read_only || updating)"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-sm-6">
                        <label>{{ $root.text('Activity', null, true) }}</label>
                        <div class="table-responsive user-activity px-2 py-1 mb-2">
                            <table class="table table-sm table-centered table-hover">
                                <tbody>
                                    <tr v-if="!isApplied() && !isInvited()">
                                        <td>{{ $root.text('Last visit', null, true) }}</td>
                                        <td class="text-right">{{ user.last_visit || $root.text('n/a') }}</td>
                                    </tr>
                                    <tr v-if="!isApplied() && !isInvited()">
                                        <td>
                                            <span v-if="user.num_visits == 1">
                                                {{ $root.text('1 visit') }}
                                            </span>
                                            <span v-else>
                                                {{ $root.text('{x} visits', { x: user.num_visits }) }}
                                            </span>
                                        </td>
                                        <td class="text-right contact-links">
                                            <a
                                                v-if="(user.num_visits && $root.hasPermission('reports_users'))"
                                                href="#"
                                                class="float-right"
                                                @click.prevent="showUserActivity('visits', user.num_visits)"
                                            >
                                                <i class="fa-regular fa-calendar font-size-16 mr-1"></i>
                                                {{ $root.text('View', null, true) }}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-if="!isApplied() && !isInvited()">
                                        <td>
                                            <span v-if="user.num_downloads == 1">
                                                {{ $root.text('1 download') }}
                                            </span>
                                            <span v-else>
                                                {{ $root.text('{x} downloads', { x: user.num_downloads }) }}
                                            </span>
                                        </td>
                                        <td class="text-right contact-links">
                                            <a
                                                v-if="(user.num_downloads && $root.hasPermission('reports_assets'))"
                                                href="#"
                                                class="float-right"
                                                @click.prevent="showUserActivity('downloads', user.num_downloads)"
                                            >
                                                <i class="fa-regular fa-download font-size-16 mr-1"></i>
                                                {{ $root.text('View', null, true) }}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-if="!isApplied() && !isInvited()">
                                        <td>
                                            <span v-if="user.num_uploads == 1">
                                                {{ $root.text('1 upload') }}
                                            </span>
                                            <span v-else>
                                                {{ $root.text('{x} uploads', { x: user.num_uploads }) }}
                                            </span>
                                        </td>
                                        <td class="text-right contact-links">
                                            <a
                                                v-if="(user.num_uploads && $root.hasPermission('reports_assets'))"
                                                href="#"
                                                class="float-right"
                                                @click.prevent="showUserActivity('uploads', user.num_uploads)"
                                            >
                                                <i class="fa-regular fa-upload font-size-16 mr-1"></i>
                                                {{ $root.text('View', null, true) }}
                                            </a>
                                        </td>
                                    </tr>
                                    <tr v-if="isApplied()">
                                        <td colspan="2">
                                            {{ $root.text('Applied on {date}', { date: user.created_at_formatted },
                                            true) }}
                                        </td>
                                    </tr>
                                    <tr v-if="user.created_by_name">
                                        <td colspan="2" style="overflow-wrap: anywhere">
                                            {{ $root.text('Invited by {name} on {date}',
                                                { name: user.created_by_name + ' - ' + user.created_by_email,
                                                date: user.created_at_formatted }, true) }}
                                        </td>
                                    </tr>
                                    <tr v-if="user.approved_by_name">
                                        <td colspan="2" style="overflow-wrap: anywhere">
                                            {{ $root.text('Approved by', null, true) }} {{ user.approved_by_name }}
                                            - {{ user.approved_by_email }}
                                            {{ $root.text('on {date}', { date: user.approved_at_formatted }) }}
                                        </td>
                                    </tr>
                                    <tr v-if="!user.deleted && user.reset">
                                        <td colspan="2" style="overflow-wrap: anywhere">
                                            <span v-if="user.reset_by_name">
                                                {{ $root.text('Reset by {name}',
                                                    { name: user.reset_by_name + ' - ' + user.reset_by_email }, true) }}
                                            </span>
                                            <span v-else>
                                                {{ $root.text('reset', null, true) }}
                                            </span>
                                            <span v-if="user.reset_at_formatted">
                                                {{ $root.text('on {date}', { date: user.reset_at_formatted }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="user.deleted">
                                        <td colspan="2" style="overflow-wrap: anywhere">
                                            <span v-if="user.deleted_by_name">
                                                {{ $root.text('Deleted by {name}',
                                                    { name: user.deleted_by_name + ' - ' + user.deleted_by_email },
                                                    true) }}
                                            </span>
                                            <span v-else>
                                                {{ $root.text('deleted', null, true) }}
                                            </span>
                                            <span v-if="user.deleted_at_formatted">
                                                {{ $root.text('on {date}', { date: user.deleted_at_formatted }) }}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <div class="custom-control custom-checkbox">
                                                <input
                                                    v-model="user.exclude_from_reports"
                                                    :id="'exclude_from_reports' + user.id"
                                                    type="checkbox"
                                                    class="custom-control-input"
                                                    :disabled="(read_only || updating)"
                                                >
                                                <label class="custom-control-label" :for="'exclude_from_reports' + user.id">
                                                    {{ $root.text('Exclude this user from reports', null, true) }}
                                                </label>
                                            </div>
                                            <small
                                                :id="'exclude_from_reports-description' + user.id"
                                                class="form-text text-muted"
                                            >
                                                {{ excludeFromReportsDescription() }}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group mb-md-0">
                            <span v-if="!isApplied() && !isInvited() && !user.declined && !user.deleted">
                                <span v-if="$root.hasPermission('users_edit')">
                                    <span v-if="(!updating || (update_mode != 'update'))">
                                        <button
                                            :key="'save-button'"
                                            type="submit"
                                            :class="'btn btn-primary mr-sm-2 mb-2'
                                                + ($root.is_sm_screen ? '' : ' btn-block')"
                                            @click.prevent="onSubmit()"
                                            :disabled="updating"
                                        >
                                            <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button
                                            :key="'saving-button'"
                                            type="button"
                                            :class="'btn btn-primary mr-sm-2 mb-2'
                                                + ($root.is_sm_screen ? '' : ' btn-block')"
                                        >
                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                            <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                                        </button>
                                    </span>
                                </span>
                                <span v-if="$root.hasPermission('users_delete')">
                                    <span v-if="(!updating || (update_mode != 'delete'))">
                                        <button
                                            :key="'deactivate-button'"
                                            type="button"
                                            :class="'btn btn-secondary mr-sm-2 mb-2'
                                                + ($root.is_sm_screen ? '' : ' btn-block')"
                                            @click.prevent="deleteUser()"
                                            :disabled="updating"
                                        >
                                            <i class="far fa-user-slash mr-1"></i>
                                            {{ $root.text('Deactivate', null, true) }}
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button
                                            :key="'deactivating-button'"
                                            type="button"
                                            :class="'btn btn-secondary mr-sm-2 mb-2'
                                                + ($root.is_sm_screen ? '' : ' btn-block')"
                                        >
                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                            <span style="opacity: 0.5">{{ $root.text('Deactivate', null, true) }}</span>
                                        </button>
                                    </span>
                                    <span v-if="(!updating || (update_mode != 'anonymise'))">
                                        <button
                                            :key="'anonymise-button'"
                                            type="button"
                                            :class="'btn btn-secondary mr-sm-2 mb-2'
                                                + ($root.is_sm_screen ? '' : ' btn-block')"
                                            @click.prevent="deleteUser(false, true)"
                                            :disabled="updating"
                                        >
                                            <i class="far fa-trash-alt mr-1"></i>
                                            {{ $root.text('Delete for GDPR', null, true) }}
                                        </button>
                                    </span>
                                    <span v-else>
                                        <button
                                            :key="'anonymising-button'"
                                            type="button"
                                            :class="'btn btn-secondary mr-sm-2 mb-2'
                                                + ($root.is_sm_screen ? '' : ' btn-block')"
                                        >
                                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                            <span style="opacity: 0.5">
                                                {{ $root.text('Delete for GDPR', null, true) }}
                                            </span>
                                        </button>
                                    </span>
                                </span>
                                <span v-if="$root.hasPermission('users_reset')">
                                    <span v-if="(!updating || (update_mode != 'reset'))">
                                        <button
                                            :key="'reset-button'"
                                            type="button"
                                            :class="'btn btn-secondary mr-sm-2 mb-2'
                                                + ($root.is_sm_screen ? '' : ' btn-block')"
                                            :disabled="updating"
                                            @click.prevent="updateUser('reset')"
                                        >
                                            <i class="far fa-circle-notch mr-1"></i>
                                            {{ $root.text('Reset', null, true) }}
                                        </button>
                                    </span>
                                    <button
                                        v-else
                                        :key="'resetting-button'"
                                        type="button"
                                        :class="'btn btn-secondary mr-sm-2 mb-2'
                                            + ($root.is_sm_screen ? '' : ' btn-block')"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">{{ $root.text('Reset', null, true) }}</span>
                                    </button>
                                </span>
                                <span v-if="($root.hasPermission('users_imitate') && ($root.user.id != user.id))">
                                    <span v-if="(!updating || (update_mode != 'imitate'))">
                                        <button
                                            :key="'imitate-button'"
                                            type="button"
                                            :class="'btn btn-secondary mr-sm-2 mb-2'
                                                + ($root.is_sm_screen ? '' : ' btn-block')"
                                            :disabled="(updating || $root.imitate)"
                                            @click.prevent="updateUser('imitate')"
                                        >
                                            <i class="far fa-user-check mr-1"></i>
                                            {{ $root.text('Imitate', null, true) }}
                                        </button>
                                    </span>
                                    <button
                                        v-else
                                        :key="'imitating-button'"
                                        type="button"
                                        :class="'btn btn-secondary mr-sm-2 mb-2'
                                            + ($root.is_sm_screen ? '' : ' btn-block')"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">{{ $root.text('Imitate', null, true) }}</span>
                                    </button>
                                </span>
                            </span>
                            <span v-if="($root.hasPermission('users_approve') && isApplied())">
                                <span v-if="(!updating || (update_mode != 'approve'))">
                                    <button
                                        :key="'approve-button'"
                                        type="button"
                                        :class="'btn btn-primary mr-sm-2 mb-2'
                                            + ($root.is_sm_screen ? '' : ' btn-block')"
                                        @click.prevent="updateUser('approve')"
                                        :disabled="updating"
                                    >
                                        <i class="far fa-check mr-1"></i> {{ $root.text('Approve', null, true) }}
                                    </button>
                                </span>
                                <span v-else>
                                    <button
                                        :key="'approving-button'"
                                        type="button"
                                        :class="'btn btn-primary mr-sm-2 mb-2'
                                            + ($root.is_sm_screen ? '' : ' btn-block')"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">{{ $root.text('Approve', null, true) }}</span>
                                    </button>
                                </span>
                                <button
                                    :key="'decline-button'"
                                    type="button"
                                    :class="'btn btn-secondary mr-sm-2 mb-2'
                                        + ($root.is_sm_screen ? '' : ' btn-block')"
                                    :disabled="updating"
                                    @click.prevent="declineUser()"
                                >
                                    <i class="far fa-ban mr-1"></i> {{ $root.text('Decline', null, true) }}
                                </button>
                                <button
                                    :key="'remove-spam-button'"
                                    type="button"
                                    :class="'btn btn-secondary mr-sm-2 mb-2'
                                        + ($root.is_sm_screen ? '' : ' btn-block')"
                                    :disabled="updating"
                                    @click.prevent="declineUser(true)"
                                >
                                    <i class="far fa-trash-alt mr-1"></i>
                                    {{ $root.text('Remove spam application', null, true) }}
                                </button>
                            </span>
                            <span v-if="($root.hasPermission('users_invite') && isInvited())">
                                <span v-if="(!updating || (update_mode != 'resend-invite'))">
                                    <button
                                        :key="'resend-invite-button'"
                                        type="button"
                                        :class="'btn btn-primary mr-sm-2 mb-2'
                                            + ($root.is_sm_screen ? '' : ' btn-block')"
                                        @click.prevent="updateUser('resend-invite')"
                                        :disabled="updating"
                                    >
                                        <i class="far fa-envelope mr-1"></i>
                                        {{ $root.text('Resend invite', null, true) }}
                                    </button>
                                </span>
                                <span v-else>
                                    <button
                                        :key="'resending-invite-button'"
                                        type="button"
                                        :class="'btn btn-primary mr-sm-2 mb-2'
                                            + ($root.is_sm_screen ? '' : ' btn-block')"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">{{ $root.text('Resend invite', null, true) }}</span>
                                    </button>
                                </span>
                                <button
                                    :key="'cancel-invite-button'"
                                    type="button"
                                    :class="'btn btn-secondary mr-sm-2 mb-2'
                                        + ($root.is_sm_screen ? '' : ' btn-block')"
                                    :disabled="updating"
                                    @click.prevent="cancelInvite()"
                                >
                                    <i class="far fa-times-circle mr-1"></i>
                                    {{ $root.text('Cancel invite', null, true) }}
                                </button>
                            </span>
                            <span v-if="($root.hasPermission('users_approve') && user.deleted)">
                                <span v-if="!updating">
                                    <button
                                        :key="'reactivate-button'"
                                        type="button"
                                        :class="'btn btn-primary mr-sm-2 mb-2'
                                            + ($root.is_sm_screen ? '' : ' btn-block')"
                                        @click.prevent="reinstateUser()"
                                        :disabled="updating"
                                    >
                                        <i class="far fa-retweet-alt mr-1"></i>
                                        {{ $root.text('Reactivate user', null, true) }}
                                    </button>
                                </span>
                                <span v-else>
                                    <button
                                        :key="'reactivating-button'"
                                        type="button"
                                        :class="'btn btn-primary mr-sm-2 mb-2'
                                            + ($root.is_sm_screen ? '' : ' btn-block')"
                                    >
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">{{ $root.text('Reactivate user', null, true) }}</span>
                                    </button>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div
                        v-if="(($root.hasPermission('users_invite') && isInvited())
                            || ($root.hasPermission('users_reset') && user.reset))"
                        class="col-lg-7 col-md-6"
                    >
                        <div
                            class="alert alert-warning invite-expired-alert mb-0"
                            role="alert"
                            :style="'display: ' + (inviteLinkExpired() ? 'block' : 'none')"
                        >
                            {{ $root.text((isInvited() ? 'Invite link' : 'Password reset link') + ' expired', null, true) }}
                        </div>
                        <form
                            class="invite-link"
                            :style="'display: ' + ((invite_link && !inviteLinkExpired()) ? 'block' : 'none')"
                        >
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        {{ $root.text(isInvited() ? 'Invite link' : 'Password reset link', null, true) }}
                                    </span>
                                </div>
                                <input
                                    v-model="invite_link"
                                    type="text"
                                    :id="'invite-link-' + user.id"
                                    class="form-control"
                                    :readonly="true"
                                >
                                <div class="input-group-append">
                                    <button
                                        class="btn btn-secondary"
                                        type="button"
                                        @click="copyLinkToClipBoard()"
                                    >
                                        {{ copy_link_button_text }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </form>
        </div>
        <loading v-if="(!isFormReady() && !errors)"></loading>
    </div>
</template>

<script>
    export default {
        props: ['data', 'company_name', 'roles', 'groups', 'profiles', 'is_visible'],
        data () {
            return {
                errors: false,
                updating: false,
                user: {
                    profile_group_id: null,
                    profile_id: null
                },
                currencies: [
                    {
                        id: 'pound',
                        name: '£'
                    },
                    {
                        id: 'euro',
                        name: '€'
                    },
                    {
                        id: 'dollar',
                        name: '$'
                    }
                ],
                user_roles: null,
                user_groups: null,
                update_mode: 'update',
                user_details_loaded: false,
                read_only: true,
                copy_link_button_text: 'Copy link',
                invite_link: null,
                list_component: null,
                profile_groups: null
            }
        },
        mounted () {
            this.list_component = (this.$route.name == 'reports-registrations') ? this.$parent.$parent : this.$parent;
            if (this.data/* && this.is_visible*/) {
                this.updateData(this.data);
            }
            if (this.roles) {
                this.user_roles = this.roles;
            }
            if (this.groups) {
                this.user_groups = this.groups;
            }
            if (this.profiles && this.$root.isFeatured('profiles')) {
                this.profile_groups = this.profiles;
            }
            this.copy_link_button_text = this.$root.text('Copy link', null, true);
        },
        watch: {
            data: function (val) {
                if (val/* && this.is_visible*/) {
                    this.updateData(val);
                }
            },
            roles: function (val) {
                this.user_roles = val;
            },
            groups: function (val) {
                this.user_groups = val;
            },
            profiles: function (val) {
                if (this.$root.isFeatured('profiles')) {
                    this.profile_groups = val;
                    if (this.is_visible) {
                        this.setProfileGroup();
                    }
                }
            },
            is_visible: function (val) {
                if (this.$root.isFeatured('profiles')) {
                    this.setProfileGroup();
                }
                this.copy_link_button_text = this.$root.text('Copy link', null, true);
                /*if (val && this.data) {
                    this.updateData(this.data);
                }*/
            }
        },
        methods: {
            updateData: function (data) {
                if (this.user_details_loaded) {
                    return null;
                }
                this.user = data;
                if (this.user.company_contact_details === null) {
                    this.user.company_contact_details = {};
                }
                this.user.user_groups = [];
                this.setReadOnly();
                this.getUserDetails();
            },
            onSubmit: function () {
                this.updateUser();
            },
            roleDescription: function () {
                let description = '';
                this.user_roles.forEach((item) => {
                    if (item.id == this.user.role) {
                        description = item.description;
                    }
                });
                if (!description) {
                    return '';
                }

                return this.$root.text(description, null, true);
            },
            excludeFromReportsDescription: function () {
                if (this.user.exclude_from_reports) {
                    return this.$root.text('Activity will not be included in system reports (upload activity will still '
                        + 'be reported on)', null, true);
                }

                return this.$root.text('Activity will be included in system reports', null, true);
            },
            getProfileOptions: function (profileGroupId) {
                if ((this.profile_groups.length == 0) || !profileGroupId) {
                    return [];
                }
                let itemIndex = -1;
                this.profile_groups.forEach((item, index) => {
                    if (item.id == profileGroupId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return this.profile_groups[itemIndex].profiles;
                }

                return [];
            },
            onProfileGroupChange: function () {
                this.updating = true;
                this.$root.unHighlightErrors('profile_group_id', this.user.id);
                this.user.profile_id = null;
                this.updating = false;
            },
            canSelectProfile: function () {
                if (this.read_only || this.updating) {
                    return false;
                }
                if (!this.user.profile_group_id) {
                    return false;
                }

                return (this.user.profile_group_id > 0);
            },
            isFormReady: function () {
                if (this.user_roles === null) {
                    return false;
                }
                if (this.user_groups === null) {
                    return false;
                }
                if (this.$root.isFeatured('profiles') && (this.profile_groups === null)) {
                    return false;
                }

                return this.user_details_loaded;
            },
            isInvited: function () {
                if (this.user.declined || this.user.deleted) {
                    return false;
                }
                return (!this.user.email_verified_at && (this.user.created_by > 0));
            },
            isApplied: function () {
                if (this.user.declined || this.user.deleted) {
                    return false;
                }
                return (!this.user.approved && !(this.user.created_by > 0));
            },
            updateUser: function (mode = 'update') {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                this.update_mode = mode;

                /**
                 * Send update user request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + this.user.id;
                let data = this.user;
                if (!data.role) {
                    data.role = null;
                }
                if (this.$root.client.company_contact_details) {
                    data.company_contact_name = this.user.company_contact_details.name || '';
                    data.company_contact_email = this.user.company_contact_details.email || '';
                    data.company_contact_phone = this.user.company_contact_details.phone || '';
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.update_mode == 'reset') {
                        vm.updating = false;
                        vm.resetUser();

                        return null;
                    }
                    if (vm.update_mode == 'approve') {
                        vm.approveUser();

                        return null;
                    }
                    if (vm.update_mode == 'resend-invite') {
                        vm.resendInvite();

                        return null;
                    }
                    if (vm.update_mode == 'delete') {
                        vm.deleteUser(true);

                        return null;
                    }
                    if (vm.update_mode == 'imitate') {
                        vm.imitateUser();

                        return null;
                    }
                    // Show confirmation message.
                    vm.list_component.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('User updated', null, true),
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.getUsers(true);
                    vm.updating = false;

                    // If current user reload user details.
                    if (vm.user.id == vm.$root.user.id) {
                        vm.$root.getUser();
                    }

                    // Close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            let errorHtml = null;
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                errorHtml = value.join('<br>') + '<br>';
                                if ((key == 'profile_id') && !vm.user.profile_group_id) {
                                    vm.errors += 'The ' + vm.$root.contents.profile_group_title
                                        + ' field is required.<br>';
                                    $('#profile_group_id-label' + vm.user.id).addClass('text-danger');
                                    $('#profile_group_id' + vm.user.id).addClass('field-error');
                                }
                                vm.errors += errorHtml.replace(/profile id/g, vm.$root.contents.profile_title)
                                    .replace(/company/g, vm.$root.contents.company_title);
                                if ((key == 'first_name') || (key == 'last_name')) {
                                    $('#name-label' + vm.user.id).addClass('text-danger');
                                } else {
                                    $('#' + key + '-label' + vm.user.id).addClass('text-danger');
                                }
                                $('#' + key + vm.user.id).addClass('field-error');
                            }
                        }
                    }
                    vm.updating = false;
                });
            },
            approveUser: function () {
                /**
                 * Send approve user request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + this.user.id
                    + '/approve';
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Show confirmation message.
                    vm.list_component.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('User approved', null, true),
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.getUsers();

                    // Close after 4 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 4000);

                    // Clear notification.
                    vm.$root.clearNotification('admin', 0, 'registration', vm.user.id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            declineUser: function (spam = false) {
                const component = this.$root.findComponent(this.list_component, 'decline-user');
                if (component) {
                    component.resetForm(this.user, spam);
                    setTimeout(function() {
                        $('#decline-user-modal').modal('show');
                    }, 200);
                }
            },
            deleteUser: function (confirmed = false, anonymise = false) {
                if (confirmed) {
                    this.updating = true;

                    /**
                     * Send delete user request to API.
                     */
                    let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/'
                        + this.user.id;
                    if (anonymise) {
                        url += '/anonymise';
                    }
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.updating = false;

                        // Clear notification.
                        vm.$root.clearNotification('admin', 0, 'deletion_request', vm.user.id);

                        // If current user logout.
                        if (vm.user.id == vm.$root.user.id) {
                            setTimeout(function () {
                                vm.$root.doLogout();
                            }, 5000);

                            return null;
                        }
                        vm.$parent.getUsers();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                        vm.updating = false;
                    });

                    return null;
                }

                let text = this.$root.text(
                    'Are you sure you want to ' + (anonymise ? 'delete' : 'deactivate') + ': {name}?',
                    { name: this.user.first_name + ' ' + this.user.last_name },
                    true
                );
                if (anonymise) {
                    text += '<br><br>' + this.$root.text('This action cannot be undone.', null, true);
                }
                this.list_component.$parent.confirm_delete = {
                    title: this.$root.text(anonymise ? 'Delete user for GDPR' : 'Deactivate user', null, true),
                    text: text,
                    component: this,
                    action: anonymise ? 'anonymise-user' : 'delete-user'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'delete-user') {
                    this.updateUser('delete');
                }
                if (action == 'anonymise-user') {
                    this.deleteUser(true, true);
                }
            },
            reinstateUser: function () {
                this.updating = true;
                /**
                 * Send reinstate user request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + this.user.id
                    + '/reinstate';
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Show confirmation message.
                    vm.list_component.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('User reactivated', null, true),
                        text: vm.$root.text('The user has been reactivated and moved to Active users', null, true)
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.getUsers();

                    // Close after 4 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 4000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            resetUser: function () {
                const component = this.$root.findComponent(this.list_component, 'reset-user');
                if (component) {
                    component.resetForm(this.user);
                    setTimeout(function() {
                        $('#reset-user-modal').modal('show');
                    }, 200);
                }
            },
            resendInvite: function () {
                /**
                 * Send resend invite request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + this.user.id
                    + '/resend-invite';
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.num_expired_invites = 0;
                    // Show confirmation message.
                    vm.list_component.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('Invite email has been resent to user', null, true),
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.getUsers();

                    // Close after 4 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 4000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            cancelInvite: function () {
                const component = this.$root.findComponent(this.list_component, 'cancel-invite');
                if (component) {
                    component.resetForm(this.user);
                    setTimeout(function() {
                        $('#cancel-invite-modal').modal('show');
                    }, 200);
                }
            },
            imitateUser: function () {
                if (this.$root.imitate) {
                    return null;
                }
                /**
                 * Send imitate user request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/imitate-user/' + this.user.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.resetSystemVariables();
                    let adminUser = {};
                    for (const [key, value] of Object.entries(vm.$root.user)) {
                        adminUser[key] = value;
                    };
                    vm.$root.imitate = {
                        admin_user: adminUser,
                        admin_token: vm.$root.token.toString()
                    };
                    vm.$root.user = response.data.user;
                    vm.$root.token = response.data.token;
                    vm.$root.getAssetsInfo();
                    vm.$router.push({ name: 'home' });
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field + this.user.id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field + this.user.id).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'first_name':
                        text = this.$root.text('First name', null, true);
                        break;
                    case 'last_name':
                        text = this.$root.text('Last name', null, true);
                        break;
                }
                if (update) {
                    $('#' + field + this.user.id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.user.first_name) {
                    $('#first_name' + this.user.id).removeClass('field-error');
                }
                if (this.user.last_name) {
                    $('#last_name' + this.user.id).removeClass('field-error');
                }
                if (this.user.first_name && this.user.last_name) {
                    $('#name-label' + this.user.id).removeClass('text-danger');
                }
            },
            getUserDetails: function () {
                /**
                 * Send get user's groups request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/'
                    + this.user.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user.created_by_email = response.data.user.created_by_email || null;
                    vm.user.created_by_name = response.data.user.created_by_name || null;
                    vm.user.created_at_formatted = response.data.user.created_at_formatted || null;
                    vm.user.approved_by_email = response.data.user.approved_by_email || null;
                    vm.user.approved_by_name = response.data.user.approved_by_name || null;
                    vm.user.approved_at_formatted = response.data.user.approved_at_formatted || null;
                    vm.user.user_groups = response.data.user.user_groups;
                    vm.user.profile_image = response.data.user.profile_image;
                    vm.user.password_reset_expires_seconds = response.data.user.password_reset_expires_seconds;
                    vm.user.num_visits = response.data.user.num_visits;
                    vm.user.last_visit = response.data.user.last_visit;
                    vm.user.num_downloads = response.data.user.num_downloads;
                    vm.user.num_uploads = response.data.user.num_uploads;
                    vm.user.deleted_by_email = response.data.user.deleted_by_email || null;
                    vm.user.deleted_by_name = response.data.user.deleted_by_name || null;
                    vm.user.deleted_at_formatted = response.data.user.deleted_at_formatted || null;
                    vm.user.reset_by_email = response.data.user.reset_by_email || null;
                    vm.user.reset_by_name = response.data.user.reset_by_name || null;
                    vm.user.reset_at_formatted = response.data.user.reset_at_formatted || null;

                    // Set user profile group.
                    if (vm.$root.isFeatured('profiles') && vm.is_visible) {
                        vm.setProfileGroup();
                    }
                    vm.user_details_loaded = true;
                    vm.$parent.user_details_loaded++;
                    vm.setInviteLink();
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                });
            },
            setInviteLink: function () {
                if (
                    (!this.isInvited() || !this.$root.hasPermission('users_invite'))
                    && (!this.user.reset || !this.$root.hasPermission('users_reset'))
                ) {
                    this.invite_link = null;

                    return null;
                }
                if (!this.user.password_reset_code) {
                    this.invite_link = null;

                    return null;
                }
                if (this.user.password_reset_expires_seconds < 1) {
                    return null;
                }

                this.invite_link = this.$root.app.app_url + '/set-password/' + this.user.password_reset_code;
                var vm = this;
                setTimeout(function () {
                    if (vm.user.password_reset_expires_seconds == 1) {
                        vm.user.password_reset_expires_seconds = 0;
                        $('.invite-link').hide();
                        $('.invite-expired-alert').show();
                    }
                }, (this.user.password_reset_expires_seconds * 1000));
                this.user.password_reset_expires_seconds = 1;
            },
            inviteLinkExpired: function () {
                if (!this.isInvited() && !this.user.reset) {
                    return false;
                }
                if (!('password_reset_expires_seconds' in this.user)) {
                    return false;
                }

                return (this.user.password_reset_expires_seconds < 1);
            },
            copyLinkToClipBoard: function () {
                let copyText = document.querySelector('#invite-link-' + this.user.id);
                copyText.select();

                try {
                    if (document.execCommand('copy')) {
                        this.copy_link_button_text = this.$root.text('Copied!', null, true);
                    }
                } catch (err) {
                    //
                }

                window.getSelection().removeAllRanges();

                var vm = this;
                setTimeout(function () {
                    vm.copy_link_button_text = vm.$root.text('Copy link', null, true);
                }, 2000);
            },
            setReadOnly: function () {
                if (this.isApplied() && this.$root.hasPermission('users_approve')) {
                    this.read_only = false;

                    return null;
                }
                if (this.isInvited() && this.$root.hasPermission('users_invite')) {
                    this.read_only = false;

                    return null;
                }
                if (
                    !this.isApplied() &&
                    !this.isInvited() &&
                    !this.user.deleted &&
                    this.$root.hasPermission('users_edit')
                ) {
                    this.read_only = false;

                    return null;
                }
                this.read_only = true;
            },
            showUserActivity: function (type, total) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'user-activity');
                if (component) {
                    component.reset(this.user, type, total);
                    $('#user-activity-modal').modal('show');
                }
            },
            setProfileGroup: function () {
                if (this.user.profile_group_id) {
                    return null;
                }
                if (!this.profile_groups) {
                    return null;
                }
                if (this.profile_groups.length == 0) {
                    return null;
                }
                let profileFound = false;
                this.updating = true;
                if (this.$root.isFeatured('profiles')) {
                    this.profile_groups.forEach((group) => {
                        group.profiles.forEach((item) => {
                            if (item.id == this.user.profile_id) {
                                this.user.profile_group_id = group.id;
                                profileFound = true;
                            }
                        });
                    });
                }
                if (!profileFound) {
                    this.user.profile_group_id = null;
                    this.user.profile_id = null;
                }
                this.updating = false;
            }
        }
    }
</script>
<style scoped>
    .user-activity {
        background-color: #f8f9fa;
    }

    .user-activity .table td {
        border-top-color: #fff;
    }

    .user-activity .table tr:first-child td {
        border-top: none;
    }
</style>