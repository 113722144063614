<template>
    <div class="btn-group">
        <button
            type="button"
            class="btn btn-light btn-sm dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style="text-align: left; width: 50px"
        >
            {{ $root.getCurrentLanguageCode() }}
            <i
                class="fas fa-chevron-down"
                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
            ></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right">
            <a
                v-for="language in $root.getAvailableLanguages()"
                :key="'lang-' + language.id"
                :class="'dropdown-item'
                    + ($root.isCurrentLanguage(language.id) ? ' active' : '')"
                href="#"
                @click.prevent="$root.setLanguage(language.id)"
            >
                {{ language.code.substring(0, 2).toUpperCase() }} - {{ language.name }}
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            //
        }
    }
</script>
