<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <h5>{{ $root.text('My groups', null, true) }}</h5>
        <div class="card">
            <div class="card-body">
                <loading v-if="own_loading"></loading>
                <div v-if="!own_loading && (own_items.length == 0)">
                    {{ $root.text('There are no groups', null, true) }}
                </div>
                <div v-if="!own_loading && (own_items.length > 0)" class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-8">{{ $root.text('User group', null, true) }}</div>
                                        <div class="col-2">{{ $root.text('Users', null, true) }}</div>
                                        <div class="col-2">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in own_items" :key="item.id">
                                <td scope="row" :id="'user-group-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-8" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditUserGroup(item)">
                                                    {{ item.name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2" style="padding-top: 3px">
                                            <span v-if="item.user_info">
                                                {{ item.user_info }}
                                            </span>
                                            <span v-else><loading :align="'left'" :size="1"></loading></span>
                                        </div>
                                        <div class="col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditUserGroup(item)"
                                            >
                                                <span
                                                    v-if="(user_group_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                            <a
                                                href="#"
                                                class="float-right mr-2"
                                                v-b-tooltip.hover.left
                                                :title="$root.text('Share', null, true)"
                                                @click.prevent="makePrivate(item, true)"
                                            >
                                                <span
                                                    v-if="making_private != item.id"
                                                    :key="'make-private-' + item.id"
                                                >
                                                    <i class="fa-regular fa-user-friends"></i>
                                                </span>
                                                <span v-else :key="'making-private-' + item.id">
                                                    <i class="fal fa-spinner-third fa-spin"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'user-group-details' + item.id">
                                            <user-group-edit-form
                                                :data="item"
                                                :show_add_user_modal="item.id == auto_open_group"
                                            ></user-group-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <h5>{{ $root.text('My shared groups', null, true) }}</h5>
        <div class="card">
            <div class="card-body">
                <loading v-if="shared_loading"></loading>
                <div v-if="!shared_loading && (shared_items.length == 0)">
                    {{ $root.text('There are no shared groups', null, true) }}
                </div>
                <div v-if="!shared_loading && (shared_items.length > 0)" class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-8">{{ $root.text('User group', null, true) }}</div>
                                        <div class="col-2">{{ $root.text('Users', null, true) }}</div>
                                        <div class="col-2">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in shared_items" :key="item.id">
                                <td scope="row" :id="'user-group-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-8" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditUserGroup(item)">
                                                    {{ item.name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2" style="padding-top: 3px">
                                            <span v-if="item.user_info">
                                                {{ item.user_info }}
                                            </span>
                                            <span v-else><loading :align="'left'" :size="1"></loading></span>
                                        </div>
                                        <div class="col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditUserGroup(item)"
                                            >
                                                <span
                                                    v-if="(user_group_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                            <a
                                                href="#"
                                                class="float-right mr-2"
                                                v-b-tooltip.hover.left
                                                :title="$root.text('Make private', null, true)"
                                                @click.prevent="makePrivate(item, false)"
                                            >
                                                <span
                                                    v-if="making_private != item.id"
                                                    :key="'make-private-' + item.id"
                                                >
                                                    <i class="fa-regular fa-user-shield"></i>
                                                </span>
                                                <span v-else :key="'making-private-' + item.id">
                                                    <i class="fal fa-spinner-third fa-spin"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'user-group-details' + item.id">
                                            <user-group-edit-form
                                                :data="item"
                                                :show_add_user_modal="item.id == auto_open_group"
                                            ></user-group-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <h5>{{ $root.text('Other shared groups', null, true) }}</h5>
        <div class="card">
            <div class="card-body">
                <loading v-if="other_loading"></loading>
                <div v-if="!other_loading && (other_items.length == 0)">
                    {{ $root.text('There are no other shared groups', null, true) }}
                </div>
                <div v-if="!other_loading && (other_items.length > 0)" class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-8">{{ $root.text('User group', null, true) }}</div>
                                        <div class="col-2">{{ $root.text('Users', null, true) }}</div>
                                        <div class="col-2">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in other_items" :key="item.id">
                                <td scope="row" :id="'user-group-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-8" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditUserGroup(item)">
                                                    {{ item.name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2" style="padding-top: 3px">
                                            <span v-if="item.user_info">
                                                {{ item.user_info }}
                                            </span>
                                            <span v-else><loading :align="'left'" :size="1"></loading></span>
                                        </div>
                                        <div class="col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditUserGroup(item)"
                                            >
                                                <span
                                                    v-if="(user_group_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'user-group-details' + item.id">
                                            <user-group-edit-form
                                                :data="item"
                                                :show_add_user_modal="item.id == auto_open_group"
                                            ></user-group-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="user-group-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="user-group-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <user-group-add-form :is_private="true"></user-group-add-form>
            </div>
        </div>

        <div
            class="modal fade"
            id="user-group-user-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="user-group-user-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <user-group-user-add-form></user-group-user-add-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'my-account-user-groups',
                own_loading: false,
                shared_loading: false,
                other_loading: false,
                errors: false,
                own_items: [],
                shared_items: [],
                other_items: [],
                user_group_details_open: [],
                auto_open_group: null,
                making_private: null
            }
        },
        mounted () {
            $('body').removeClass('login-body');

            if (!this.$root.hasPermission('users_own_user_groups')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            this.getUserGroups();
        },
        methods: {
            getUserGroups: function (autoOpenGroup = null, list = null) {
                if (!list) {
                    this.auto_open_group = autoOpenGroup;
                    this.errors = false;
                    this.user_group_details_open = [];
                    this.getUserGroups(null, 'own');
                    this.getUserGroups(null, 'shared');
                    this.getUserGroups(null, 'other');

                    return null;
                }
                let filters = null;
                if (list == 'own') {
                    this.own_loading = true;
                    this.own_items = [];
                    filters = 'private|own|!shared';
                }
                if (list == 'shared') {
                    this.shared_loading = true;
                    this.shared_items = [];
                    filters = 'private|own|shared';
                }
                if (list == 'other') {
                    this.other_loading = true;
                    this.other_items = [];
                    filters = 'private|!own';
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/' + filters;
                var vm = this;
                var userGroupList = list;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    response.data.user_groups.forEach((item) => {
                        item.user_info = null;
                        if (item.created_by == vm.$root.user.id) {
                            if (item.shared) {
                                vm.shared_items.push(item);
                            } else {
                                vm.own_items.push(item);
                            }
                        } else {
                            vm.other_items.push(item);
                        }
                        if (item.id == vm.auto_open_group) {
                            setTimeout(function () {
                                vm.toggleEditUserGroup(item);
                            }, 500);
                        }
                    });
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    if (userGroupList == 'own') {
                        vm.own_loading = false;
                    }
                    if (userGroupList == 'shared') {
                        vm.shared_loading = false;
                    }
                    if (userGroupList == 'other') {
                        vm.other_loading = false;
                    }
                });
            },
            toggleEditUserGroup: function (item) {
                if (this.user_group_details_open.indexOf(item.id) < 0) {
                    this.user_group_details_open.push(item.id);
                    $('#user-group-row' + item.id).addClass('highlighted');
                    $('#user-group-details' + item.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#user-group-details' + item.id).slideUp('slow', function () {
                        $('#user-group-row' + item.id).removeClass('highlighted');
                        for (var n = 0; n < vm.user_group_details_open.length; n++) { 
                            if (vm.user_group_details_open[n] == item.id) { 
                                vm.user_group_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            makePrivate: function (item, shared = false) {
                if (this.making_private) {
                    return null;
                }
                this.making_private = item.id;
                const data = {
                    name: item.name,
                    shared: shared
                };

                /**
                 * Send update user group request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-group/'
                    + item.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.getUserGroups();
                })
                .catch(function (error) {
                    // Show any error?
                })
                .finally(() => {
                    vm.making_private = null;
                });
            }
        }
    }
</script>
