<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            {{ $root.text(
                'There are no {profile groups} that match your selection',
                {
                    'profile groups': $root.text(
                        $root.contents.profile_groups_title,
                        null,
                        false,
                        'profile_groups_title'
                    )
                },
                true
            ) }}
        </div>

        <div v-if="((total_items > 0) || sorting)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-1 col-12 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('No.') }, true)"
                                                @click.prevent="sortBy('id')"
                                            >
                                                {{ $root.text('No.', null, true) }}
                                                <span v-if="(sorted_by == 'id_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'id_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div :class="nameColClass() + ' col-12 pb-sm-0 pb-1'">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text(
                                                    'Sort by {sort_field}',
                                                    {
                                                        sort_field: $root.text(
                                                            '{profile group} name',
                                                            {
                                                                'profile group': $root.text(
                                                                    $root.contents.profile_group_title,
                                                                    null,
                                                                    false,
                                                                    'profile_group_title'
                                                                )
                                                            }
                                                        )
                                                    },
                                                    true
                                                )"
                                                @click.prevent="sortBy('name')"
                                            >
                                                {{ $root.text(
                                                    '{profile group} name',
                                                    {
                                                        'profile group': $root.text(
                                                            $root.contents.profile_group_title,
                                                            null,
                                                            false,
                                                            'profile_group_title'
                                                        )
                                                    },
                                                    true
                                                ) }}
                                                <span v-if="(sorted_by == 'name_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'name_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div v-if="hasDescription()" class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text(
                                                    'Sort by {sort_field}',
                                                    {
                                                        sort_field: $root.text(
                                                            $root.contents.profile_group_description_title,
                                                            null,
                                                            false,
                                                            'profile_group_description_title'
                                                        )
                                                    },
                                                    true
                                                )"
                                                @click.prevent="sortBy('description')"
                                            >
                                                {{ $root.text(
                                                    $root.contents.profile_group_description_title,
                                                    null,
                                                    true,
                                                    'profile_group_description_title'
                                                ) }}
                                                <span v-if="(sorted_by == 'description_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'description_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div v-if="hasAccountNo()" class="col-sm-2 col-12 pb-sm-0 pb-1">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('account number') }, true)"
                                                @click.prevent="sortBy('account-number')"
                                            >
                                                {{ $root.text('Account number', null, true) }}
                                                <span v-if="(sorted_by == 'account-number_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'account-number_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-2 col-12">
                                            <a
                                                href="#"
                                                v-b-tooltip.hover
                                                :title="$root.text('Sort by {sort_field}',
                                                    { sort_field: $root.text('registered') }, true)"
                                                @click.prevent="sortBy('created')"
                                            >
                                                {{ $root.text('Registered', null, true) }}
                                                <span v-if="(sorted_by == 'created_asc')" class="ml-1">
                                                    <i class="fas fa-caret-up"></i>
                                                </span>
                                                <span>
                                                    <span v-if="(sorted_by == 'created_desc')" class="ml-1">
                                                        <i class="fas fa-caret-down"></i>
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row">
                                    <div class="row">
                                        <div class="col-1 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.id }}
                                        </div>
                                        <div
                                            :class="nameColClass() + ' d-none d-sm-block'"
                                            style="padding-top: 3px"
                                        >
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="viewProfileGroup(item)"
                                                >
                                                    {{ item.name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div
                                            v-if="hasDescription()"
                                            class="col-3 d-none d-sm-block"
                                            style="padding-top: 3px">
                                            {{ item.description }}
                                        </div>
                                        <div
                                            v-if="hasAccountNo()"
                                            class="col-2 d-none d-sm-block"
                                            style="padding-top: 3px"
                                        >
                                            {{ item.account_number }}
                                        </div>
                                        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
                                            {{ formatDate(item.created_at) }}
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <p class="mb-1">{{ item.id }}</p>
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="viewProfileGroup(item)"
                                                >
                                                    {{ item.name }}
                                                </a>
                                            </h5>
                                            <p v-if="hasDescription()" class="mb-1">{{ item.description }}</p>
                                            <p v-if="hasAccountNo()" class="mb-1">{{ item.account_number }}</p>
                                            <p class="mb-0">{{ formatDate(item.created_at) }}</p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                v-b-tooltip.hover
                                                :title="$root.text('View', null, true)"
                                                @click.prevent="viewProfileGroup(item)"
                                            >
                                                <i class="fa-regular fa-eye"></i>
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'profile-groups',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                sorted_by: 'name_asc',
                sorting: false
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            
            /*if (!this.$root.hasPermission('printing_manage_suppliers')) {
                this.errors = 'Sorry, you do not have access to this function.';

                return false;
            }*/
            this.getProfileGroups();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            total_items: function (val) {
                this.updateTotalItems();
            }
        },
        methods: {
            updateTotalItems: function () {
                this.$root.status_info.right = this.total_items + ' '
                    + ((this.total_items == 1)
                        ? this.$root.text(this.$root.contents.profile_group_title, null, false, 'profile_group_title')
                        : this.$root.text(this.$root.contents.profile_groups_title, null, false, 'profile_groups_title')
                    );
            },
            getProfileGroups: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;

                let filters = ['sb::' + this.sorted_by];
                this.$root.profile_group_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        filters.push('kw::' + btoa(item.value));
                    }
                });

                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                }

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-groups'
                    + '/' + filters.join('|') + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.profile_groups.total;
                    vm.current_page = response.data.profile_groups.current_page;
                    vm.last_page = response.data.profile_groups.last_page;
                    response.data.profile_groups.data.forEach((item) => {
                        // Duplicate display protection - check if already added
                        let itemIndex = -1;
                        vm.items.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.items.push(item);
                        }
                    });
                    vm.updateTotalItems();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.loading = false;
                    vm.sorting = false;
                });
            },
            viewProfileGroup: function (item) {
                this.$root.view_profile_group_name = item.name;
                this.$router.push({
                    name: 'view-profile-group',
                    params: {
                        id: item.id
                    }
                });
            },
            formatDate: function (rawDate) {
                if (!rawDate) {
                    return '';
                }
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            sortBy: function (field) {
                const currentSort = this.sorted_by.split('_');
                let newSort = currentSort;
                if (currentSort[0] == field) {
                    newSort[1] = (currentSort[1] == 'asc') ? 'desc' : 'asc';
                } else {
                    newSort[0] = field;
                }
                this.sorted_by = newSort.join('_');
                this.sorting = true;
                this.getProfileGroups();
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getProfileGroups(this.current_page + 1);
                    }
                }
            },
            hasDescription: function () {
                return this.$root.client.show_profile_group_description;
            },
            hasAccountNo: function () {
                return this.$root.client.show_profile_group_account_number;
            },
            nameColClass: function () {
                if (this.hasDescription() && this.hasAccountNo()) {
                    return 'col-sm-3';
                }
                if (!this.hasDescription() && !this.hasAccountNo()) {
                    return 'col-sm-8';
                }

                return (this.hasAccountNo() ? 'col-sm-6' : 'col-sm-5');
            }
        }
    }
</script>
