<template>
    <div>
        <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6">
                <div class="card">
                    <h5 class="card-header">{{ $root.text('My details', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Personal info', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-my-details-modal"
                                            @click="resetMyDetailsForm()"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('Edit personal info', null, true)"
                                            ><i class="far fa-edit"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Change password', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#change-password-modal"
                                            @click="resetChangePasswordForm()"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('Change password', null, true)"
                                            ><i class="far fa-key"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_own_user_groups')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('User groups', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            :to="{ name: 'my-account-user-groups' }"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View user groups', null, true)"
                                            ><i class="far fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Request account deletion', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#request-deletion-modal"
                                            @click="resetRequestDeletionForm()"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('Request account deletion', null, true)"
                                            ><i class="far fa-user-slash"></i></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                v-if="($root.hasPermission('assets_access') || $root.hasPermission('templates_view'))"
                class="col-xl-3 col-lg-4 col-md-6"
            >
                <div class="card">
                    <h5 class="card-header">
                        {{ $root.text($root.contents.asset_title + 's', null, true, 'assets_title') }}
                    </h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('assets_upload')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    <div v-if="uploads_count == null">
                                        <div style="display: inline-block">
                                            <loading align="left" size="1"></loading>
                                        </div>
                                        {{ $root.text('{x} uploads in the last month', { x: '' }) }}
                                    </div>
                                    <div v-else>
                                        <span v-if="uploads_count == 1">
                                            {{ $root.text('1 upload in the last month') }}
                                        </span>
                                        <span v-else>
                                            {{ $root.text('{x} uploads in the last month', { x: uploads_count }) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link :to="{ name: 'my-account-uploads' }">
                                            <span v-b-tooltip.hover.left :title="$root.text('View uploads', null, true)">
                                                <i class="far fa-eye"></i>
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_download')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    <div v-if="downloads_count == null">
                                        <div style="display: inline-block">
                                            <loading align="left" size="1"></loading>
                                        </div>
                                        {{ $root.text('{x} downloads in the last month', { x: '' }) }}
                                    </div>
                                    <div v-else>
                                        <span v-if="downloads_count == 1">
                                            {{ $root.text('1 download in the last month') }}
                                        </span>
                                        <span v-else>
                                            {{ $root.text('{x} downloads in the last month', { x: downloads_count }) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link :to="{ name: 'my-account-downloads' }">
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View downloads', null, true)"
                                            ><i class="far fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_download')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    <div v-if="asset_approval_requests_count == null">
                                        <div style="display: inline-block">
                                            <loading align="left" size="1"></loading>
                                        </div>
                                        {{ $root.text('{x} {resources} awaiting approval',
                                            { x: '', resources: $root.text($root.contents.asset_title + 's', null, false,
                                            'assets_title') }) }}
                                    </div>
                                    <div v-else>
                                        <span v-if="asset_approval_requests_count == 1">
                                            {{ $root.text(
                                                '1 {resource} awaiting approval',
                                                {
                                                    resource: $root.text(
                                                        $root.contents.asset_title,
                                                        null,
                                                        false,
                                                        'asset_title'
                                                    )
                                                }
                                            ) }}
                                        </span>
                                        <span v-else>
                                            {{ $root.text('{x} {resources} awaiting approval',
                                                { x: asset_approval_requests_count,
                                                resources: $root.text($root.contents.asset_title + 's', null, false,
                                                'assets_title') }) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link :to="{ name: 'my-account-assets-awaiting-approval' }">
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View {resources} awaiting approval',
                                                    { resources: $root.text($root.contents.asset_title + 's', null,
                                                    false, 'assets_title') }, true)"
                                            >
                                                <i class="far fa-eye"></i>
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_download')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    <div v-if="asset_approvals_count == null">
                                        <div style="display: inline-block">
                                            <loading align="left" size="1"></loading>
                                        </div>
                                        {{ $root.text('{x} {resources} approved',
                                            { x: '', resources: $root.text($root.contents.asset_title + 's', null, false,
                                            'assets_title') }) }}
                                    </div>
                                    <div v-else>
                                        <span v-if="asset_approvals_count == 1">
                                            {{ $root.text(
                                                '1 {resource} approved',
                                                {
                                                    resource: $root.text(
                                                        $root.contents.asset_title,
                                                        null,
                                                        false,
                                                        'asset_title'
                                                    )
                                                }
                                            ) }}
                                        </span>
                                        <span v-else>
                                            {{ $root.text('{x} {resources} approved', { x: asset_approvals_count,
                                                resources: $root.text($root.contents.asset_title + 's', null, false,
                                                'assets_title') }) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link :to="{ name: 'my-account-assets-approved' }">
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View {resources} approved',
                                                    { resources: $root.text($root.contents.asset_title + 's', null,
                                                    false, 'assets_title') }, true)"
                                            >
                                                <i class="far fa-eye"></i>
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('assets_access') || $root.hasPermission('templates_view'))"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('My collections', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            :to="{ name: 'collections' }"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View collections', null, true)"
                                            ><i class="far fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_send')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Collections shared', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            :to="{ name: 'my-account-collections-shared' }"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View collections shared', null, true)"
                                            ><i class="far fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                v-if="($root.hasPermission('templates_access') && $root.hasPermission('templates_view'))
                    || canViewOrders()"
                class="col-xl-3 col-lg-4 col-md-6"
            >
                <div
                    v-if="$root.hasPermission('templates_access') && $root.hasPermission('templates_view')"
                    class="card"
                >
                    <h5 class="card-header">
                        {{ $root.text(
                            '{template} approvals',
                            { template: $root.text($root.contents.template_title, null, false, 'template_title') },
                            true
                        ) }}
                    </h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    <div v-if="artwork_approval_requests_count == null">
                                        <div style="display: inline-block">
                                            <loading align="left" size="1"></loading>
                                        </div>
                                        {{ $root.text('{x} items awaiting approval', { x: '' }) }}
                                    </div>
                                    <div v-else>
                                        <span v-if="artwork_approval_requests_count == 1">
                                            {{ $root.text('1 item awaiting approval') }}
                                        </span>
                                        <span v-else>
                                            {{ $root.text('{x} items awaiting approval',
                                                { x: artwork_approval_requests_count }) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link :to="{ name: 'my-account-artworks-awaiting-approval' }">
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View items awaiting approval', null, true)"
                                            >
                                                <i class="far fa-eye"></i>
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Approved items', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            :to="{ name: 'my-account-artworks-approved' }"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View approved items', null, true)"
                                            ><i class="far fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Declined items', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            :to="{ name: 'my-account-artworks-declined' }"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View declined items', null, true)"
                                            ><i class="far fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text(
                                        '{template} approval history',
                                        {
                                            template: $root.text(
                                                $root.contents.template_title,
                                                null,
                                                false,
                                                'template_title'
                                            )
                                        },
                                        true
                                    ) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            :to="{ name: 'my-account-artwork-actions' }"
                                        >
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text(
                                                    'View {template} approval history',
                                                    {
                                                        template: $root.text(
                                                            $root.contents.template_title,
                                                            null,
                                                            false,
                                                            'template_title'
                                                        )
                                                    },
                                                    true
                                                )"
                                            >
                                                <i class="far fa-eye"></i>
                                            </span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="canViewOrders()" class="card">
                    <h5 class="card-header">{{ $root.text('Orders', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    <div v-if="print_orders_count == null">
                                        <div style="display: inline-block">
                                            <loading align="left" size="1"></loading>
                                        </div>
                                        {{ $root.text('{x} orders', { x: '' }) }}
                                    </div>
                                    <div v-else>
                                        <span v-if="print_orders_count == 1">
                                            {{ $root.text('1 order') }}
                                        </span>
                                        <span v-else>
                                            {{ $root.text('{x} orders', { x: print_orders_count }) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a href="#" @click.prevent="viewPrintOrders()">
                                            <span v-b-tooltip.hover.left :title="$root.text('View orders', null, true)">
                                                <i class="far fa-eye"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                v-if="(hasProfile() || $root.hasPermission('flyte_access'))"
                class="col-xl-3 col-lg-4 col-md-6"
            >
                <div v-if="hasProfile()" class="card">
                    <h5 class="card-header">
                        {{ $root.text(
                            'My {profile group}',
                            {
                                'profile group': $root.text(
                                    $root.contents.profile_group_title,
                                    null,
                                    false,
                                    'profile_group_title'
                                )
                            },
                            true
                        ) }}
                    </h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <loading v-if="profile_group_loading" align="left" size="1"></loading>
                            <div v-else>
                                <div v-if="hasProfileGroup()" class="row no-gutters">
                                    <div class="col-8 pt-1 pb-1">
                                        {{ $root.text(
                                            'Manage {profile group}',
                                            {
                                                'profile group': $root.text(
                                                    $root.contents.profile_group_title,
                                                    null,
                                                    false,
                                                    'profile_group_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </div>
                                    <div class="col-4">
                                        <div class="contact-links font-size-18 text-right">
                                            <router-link
                                                :to="{ name: 'view-profile-group', params: { id: profile_group_id} }"
                                            >
                                                <span
                                                    v-b-tooltip.hover.left
                                                    :title="$root.text(
                                                        'View {profile group}',
                                                        {
                                                            'profile group': $root.text(
                                                                $root.contents.profile_group_title,
                                                                null,
                                                                false,
                                                                'profile_group_title'
                                                            )
                                                        },
                                                        true
                                                    )"
                                                >
                                                    <i class="far fa-eye"></i>
                                                </span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="alert alert-warning mb-0" role="alert">
                                    {{ $root.text(
                                        'You are not assigned to any {profile group}',
                                        {
                                            'profile group': $root.text(
                                                $root.contents.profile_group_title,
                                                null,
                                                false,
                                                'profile_group_title'
                                            )
                                        },
                                        true
                                    ) }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="$root.hasPermission('flyte_access')" class="card">
                    <h5 class="card-header">Flyte activity</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    <div v-if="flytes_count == null">
                                        <div style="display: inline-block">
                                            <loading align="left" size="1"></loading>
                                        </div>
                                        {{ $root.text('{x} flytes sent in the last month', { x: '' }) }}
                                    </div>
                                    <div v-else>
                                        <span v-if="flytes_count == 1">
                                            {{ $root.text('1 flyte sent in the last month') }}
                                        </span>
                                        <span v-else>
                                            {{ $root.text('{x} flytes sent in the last month', { x: flytes_count }) }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link :to="{ name: 'my-account-flytes-sent' }">
                                            <span
                                                v-b-tooltip.hover.left
                                                :title="$root.text('View flytes sent', null, true)"
                                            ><i class="far fa-eye"></i></span>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            class="modal fade"
            id="request-deletion-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="request-deletion-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <request-deletion></request-deletion>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'my-account',
                uploads_count: null,
                downloads_count: null,
                asset_approval_requests_count: null,
                asset_approvals_count: null,
                flytes_count: null,
                artwork_approval_requests_count: null,
                profile_group_id: null,
                print_orders_count: null,
                profile_group_loading: false
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getCounts();
            if (this.hasProfile()) {
                this.getProfileGroup();
            }
        },
        methods: {
            getCounts: function () {
                const today = new Date();
                let month = ('0' + (today.getMonth() + 1)).slice(-2);
                let day = ('0' + today.getDate()).slice(-2);
                const endDate = today.getFullYear() + '-' + month + '-' + day;
                const newDate = new Date();
                newDate.setMonth(newDate.getMonth() - 1);
                month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                day = ('0' + newDate.getDate()).slice(-2);
                const startDate = newDate.getFullYear() + '-' + month + '-' + day;

                if (this.$root.hasPermission('assets_upload')) {
                    this.getUploadsCount(startDate, endDate);
                }
                if (this.$root.hasPermission('assets_download')) {
                    this.getDownloadsCount(startDate, endDate);
                }
                if (this.$root.hasPermission('assets_download')) {
                    this.getAssetApprovalsCount('!declined|!approved');
                    this.getAssetApprovalsCount('!declined|approved');
                }
                if (this.$root.hasPermission('flyte_access')) {
                    this.getFlytesSentCount(startDate, endDate);
                }
                if (this.$root.hasPermission('templates_access') && this.$root.hasPermission('templates_view')) {
                    this.getArtworkApprovalsCount('finished|!deleted|!declined|!approved');
                }
                if (this.canViewOrders()) {
                    this.getPrintOrdersCount('!archived');
                }
            },
            getUploadsCount: function (startDate, endDate) {
                /**
                 * Send get uploads request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/report/resource-uploads/count|cb::' + this.$root.user.id + '|dr::' + startDate + '-' + endDate;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.uploads_count = response.data.uploads;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getDownloadsCount: function (startDate, endDate) {
                /**
                 * Send get downloads request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/report/resource-downloads/count|ur::' + this.$root.user.id + '|dr::' + startDate + '-' + endDate;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.downloads_count = response.data.downloads;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getAssetApprovalsCount: function (filters) {
                /**
                 * Send get asset approvals request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/report/resource-access-requests/count|ur::' + this.$root.user.id + '|' + filters;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.filters.indexOf('!approved') > -1) {
                        vm.asset_approval_requests_count = response.data.requests;

                        return null;
                    }
                    vm.asset_approvals_count = response.data.requests;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getFlytesSentCount: function (startDate, endDate) {
                /**
                 * Send get flytes sent request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/report/flytes-sent/count|cb::' + this.$root.user.id + '|dr::' + startDate + '-' + endDate;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.flytes_count = response.data.flytes;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            resetMyDetailsForm: function () {
                const component = this.$root.findComponent(this.$parent, 'my-account-edit');
                if (component) {
                    component.resetForm();
                }
            },
            resetChangePasswordForm: function () {
                const component = this.$root.findComponent(this.$parent, 'change-password');
                if (component) {
                    component.resetForm();
                }
            },
            resetRequestDeletionForm: function () {
                const component = this.$root.findComponent(this, 'request-deletion');
                if (component) {
                    component.resetForm();
                }
            },
            canViewOrders: function () {
                if (!this.$root.isFeatured('printing')) {
                    return false;
                }
                if (this.$root.isProofsGuest()) {
                    return false;
                }
                /*if (!this.$root.hasPermission('assets_view') && !this.$root.hasPermission('templates_view')) {
                    return false;
                }*/

                return true;
            },
            viewPrintOrders: function () {
                this.$root.print_order_filters = [];
                this.$router.push({ name: 'my-account-print-orders' });
            },
            getArtworkApprovalsCount: function (filters) {
                /**
                 * Send get artwork approvals request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/template/0/artworks/cb::' + this.$root.user.id + '|' + filters;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.artwork_approval_requests_count = response.data.artworks.total;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getPrintOrdersCount: function (filters) {
                /**
                 * Send get print orders request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-orders'
                    + '/!deleted|es::' + btoa('Artwork approval required') + '|own|' + filters;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.print_orders_count = response.data.orders.total;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            hasProfile: function () {
                if (!this.$root.isFeatured('profiles')) {
                    return false;
                }
                if (!this.$root.user.profile_id) {
                    return false;
                }
                if (this.$root.user.permissions.length == 0) {
                    return false;
                }

                return true;
            },
            hasProfileGroup: function () {
                return (this.hasProfile() && this.profile_group_id);
            },
            getProfileGroup: function () {
                this.profile_group_loading = true;
                /**
                 * Send get profile request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile/'
                    + this.$root.user.profile_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_group_id = response.data.profile.profile_group_id;
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() =>
                    vm.profile_group_loading = false
                );
            }
        }
    }
</script>
