<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-return-modal-label">
                {{ modalTitle() }}<span v-if="proof && ($route.name == 'proofs')">: {{ proof.title }}</span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div
                    v-if="(action == 'MC') && !proof_approve_message_dismissed"
                    class="alert alert-warning"
                    role="alert"
                >
                    <div>
                        {{ $root.text('Dependent on other reviewers changes, you may be required to review further '
                            + 'versions.', null, true) }}
                    </div>
                    <div class="mt-2 text-right">
                        <button type="button" class="btn btn-secondary btn-sm" @click="doNotShowAgain()">
                            {{ $root.text('Do not show again', null, true) }}
                        </button>
                    </div>
                </div>

                <div v-if="errors" class="alert alert-danger" role="alert">
                    <div v-html="errors"></div>
                    <div
                        v-if="(errors.indexOf('IMPORTANT') > -1) && ((action == 'WC') || (action == 'MC'))"
                        class="mt-2 text-right"
                    >
                        <button type="button" class="btn btn-secondary btn-sm" @click="toggleReturnProof('LP')">
                            <!--<i class="far fa-binoculars mr-1"></i> -->
                            {{ $root.text('Lost proof?', null, true) }}'
                        </button>
                    </div>
                </div>

                <div v-if="!action" class="row">
                    <div class="col-sm-6 col-12">
                        <div class="form-group">
                            <button class="btn btn-primary btn-block" type="button" @click="toggleReturnProof('NC')">
                                <i class="far fa-file-check mr-1"></i>
                                {{ $root.text('Approved with no changes', null, true) }}
                            </button>
                        </div>
                        <div class="form-group mb-sm-0 mb-3">
                            <button class="btn btn-secondary btn-block" type="button" @click="toggleReturnProof('WC')">
                                <i class="far fa-file-lines mr-1"></i>
                                {{ $root.text('Return with comments', null, true) }}
                            </button>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <button
                                class="btn btn-secondary px-2 btn-block"
                                type="button"
                                @click="toggleReturnProof('MC')"
                            >
                                <i class="far fa-memo-circle-check mr-1"></i>
                                {{ $root.text('Approved - minor changes', null, true) }}
                            </button>
                        </div>
                        <div class="form-group mb-0">
                            <button class="btn btn-secondary btn-block" type="button" @click="toggleReturnProof('UR')">
                                <i class="far fa-clock mr-1"></i> {{ $root.text('Review later', null, true) }}
                            </button>
                        </div>
                    </div>
                </div>

                <div v-if="action == 'NC'">
                    {{ $root.text('Are you sure you want to approve this proof?', null, true) }}
                </div>

                <div v-if="action == 'UR'">
                    <span v-if="proof.ordered">
                        {{ $root.text('You have not checked the proof and are replacing it to download later. '
                            + 'Are you sure?', null, true) }}
                    </span>
                    <span v-else>
                        {{ $root.text('You have not checked the proof and are replacing it to download later or for '
                            + 'others to download. Are you sure?', null, true) }}
                    </span>
                </div>

                <div v-if="action == 'LP'">
                    {{ $root.text('This proof will be now be available to download again.', null, true) }}
                </div>
            </div>

            <form
                v-on:submit.prevent="onSubmit()"
                :style="'display: ' + ((updated || ((action != 'WC') && (action != 'MC'))) ? 'none' : 'block')"
            >
                <div class="form-group">
                    <vue-dropzone
                        ref="proofReturnDropzone"
                        id="proof-return-dropzone"
                        :options="dropzone_options"
                        v-on:vdropzone-sending="onSendingFile"
                        v-on:vdropzone-upload-progress="onProgressUpdate"
                        v-on:vdropzone-success="onFileUploaded"
                        v-on:vdropzone-error="onUploadError"
                        v-on:vdropzone-drag-over="onDragOver"
                        v-on:vdropzone-drag-leave="onDragLeave"
                        v-on:vdropzone-drop="onDragLeave"
                        v-on:vdropzone-file-added="onFileAdded"
                        :useCustomSlot=true
                    >
                        <div v-if="file" class="dz-uploaded-file p-2 mb-1">
                            <div class="dz-thumbnail">
                                <span v-if="!file.preview_image">
                                    <img
                                        :src="$root.imagePlaceholder(file.filename)"
                                        class="img-fluid"
                                        alt=""
                                    >
                                </span>
                                <img
                                    v-else
                                    :src="file.preview_image"
                                    class="img-fluid"
                                    alt=""
                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                >
                                <div
                                    v-if="file.preview_image"
                                    class="view-image-icon"
                                    :id="'view-image-icon' + file.id"
                                    v-b-tooltip.hover
                                    :title="$root.text('Enlarge', null, true)"
                                    @click="viewImage(file)"
                                    v-on:mouseover="viewImageIcon(file.id, true)"
                                    v-on:mouseout="viewImageIcon(file.id, false)"
                                >
                                    <i class="fal fa-expand-arrows"></i>
                                </div>
                            </div>
                            <div class="dz-filename">{{ file.filename }}</div>
                            <div class="dz-size" v-html="$root.formatFileSize(file.file_size)"></div>
                            <div class="dz-remove">
                                <a
                                    v-if="!updating"
                                    href="#"
                                    v-b-tooltip.hover
                                    :title="$root.text('Delete', null, true)"
                                    @click.prevent="removeUploadedFile()"
                                >
                                    <i class="fal fa-trash-alt"></i>
                                </a>
                                <span v-if="updating"><i class="fal fa-trash-alt"></i></span>
                            </div>
                            <div class="dz-upload-info" :id="'upload-info' + file.id">
                                <!--<i class="fal fa-clock mr-1"></i> Pending upload-->
                            </div>
                            <div class="dz-progress" :id="'upload-progress' + file.id">
                                <div class="dz-upload"></div>
                            </div>
                        </div>
                        <a
                            href="#"
                            class="dz-add-files"
                            id="proof-return-add-files"
                            @click.prevent="doNothing()"
                            :style="dzAddFilesStyle()"
                        >
                            <i class="far fa-cloud-upload-alt font-size-20 mr-1"></i>
                            {{ $root.text('Click or drop file here to upload', null, true) }}
                        </a>
                    </vue-dropzone>
                </div>

                <div v-if="proof" class="form-group">
                    <label :for="'comments' + proof.id">
                        {{ $root.text('Any message for the proof leader?', null, true) }}
                    </label>
                    <textarea
                        v-model="comments"
                        :id="'comments' + proof.id"
                        class="form-control mandatory-field"
                        rows="4"
                        :readonly="updating"
                    ></textarea>
                </div>
            </form>
        </div>
        <div v-if="action && !updated" class="modal-footer">
            <span v-if="(action == 'WC') || (action == 'MC')">
                <span v-if="!updating">
                    <button
                        :key="'return-button'"
                        type="button"
                        class="btn btn-primary"
                        :disabled="!file || uploading"
                        @click="onSubmit()"
                    >
                        <!--<i class="far fa-file-lines mr-1"></i> -->
                        {{ $root.text('Return proof', null, true) }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'returning-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ $root.text('Returning', null, true) }}</span>
                    </button>
                </span>
            </span>
            <span v-if="(action == 'NC') || (action == 'UR')">
                <span v-if="!updating">
                    <button :key="'approve-button'" type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> {{ $root.text('Yes', null, true) }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'approving-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">
                            {{ $root.text((action == 'NC') ? 'Approving' : 'Replacing', null, true) }}
                        </span>
                    </button>
                </span>
            </span>
            <span v-if="action == 'LP'">
                <span v-if="!updating">
                    <button :key="'ok-button'" type="button" class="btn btn-primary" @click="makeAvailable()">
                        <i class="far fa-check mr-1"></i> {{ $root.text('OK') }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'making-available-button'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ $root.text('Making available', null, true) }}</span>
                    </button>
                </span>
            </span>
            <button
                :key="'cancel-button'"
                type="button"
                class="btn btn-light"
                @click="toggleReturnProof()"
                :disabled="updating"
            >
                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
            </button>
        </div>
    </div>
</template>

<script>
    import vue2Dropzone from 'vue2-dropzone';
    export default {
        components: {
            'vue-dropzone': vue2Dropzone
        },
        data () {
            return {
                errors: false,
                proof: null,
                action: null,
                updating: false,
                updated: false,
                uploading: false,
                dropzone_options: {
                    url: this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-v2',
                    acceptedFiles: 'application/pdf,application/msword,application/x-excel,application/mspowerpoint,'
                        + 'application/vnd.openxmlformats-officedocument.wordprocessingml.document,'
                        + 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,'
                        + 'application/vnd.openxmlformats-officedocument.presentationml.presentation,'
                        + 'application/vnd.openxmlformats-officedocument.presentationml.slideshow,'
                        + 'application/vnd.oasis.opendocument.text,'
                        + 'application/vnd.oasis.opendocument.presentation,'
                        + 'application/vnd.oasis.opendocument.spreadsheet,'
                        + 'image/jpeg,image/pjpeg',
                    createImageThumbnails: true,
                    maxThumbnailFilesize: 20,
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    uploadMultiple: false,
                    parallelUploads: 1,
                    chunking: true,
                    forceChunking: true,
                    parallelChunkUploads: false,
                    chunkSize: 10000000,
                    retryChunks: true,
                    retryChunksLimit: 5,
                    maxFilesize: (100 * 1000 * 1000),
                    autoProcessQueue: false,
                    timeout: 0,
                    clickable: '#proof-return-add-files',
                    headers: {
                        'Authorization': 'Bearer ' + this.$root.token
                    }
                },
                file: null,
                comments: null,
                proof_approve_message_dismissed: false
            }
        },
        mounted () {
            //
        },
        watch: {
            action: function (val) {
                if ((val == 'WC') || (val == 'MC')) {
                    this.errors = '<strong style="text-transform: uppercase">' + this.$root.text('important')
                        + '</strong>' + this.$root.text('The file you return must be called: {filename}',
                        { filename: '<br><strong style="color: black">' + this.proof.return_filename + '</strong>' },
                        true);
                    this.uploading = false;
                    this.file = null;
                    this.comments = null;
                    if (this.$refs.proofReturnDropzone) {
                        this.$refs.proofReturnDropzone.removeAllFiles();
                    }

                    return null;
                }
                this.errors = false;
            }
        },
        methods: {
            reset: function (proof, action = null) {
                this.proof = proof;
                this.errors = false;
                this.action = action;
                this.updated = false;
                const cookieName = this.$root.app.client_id + '_pamd' + this.$root.user.id;
                this.proof_approve_message_dismissed = this.$cookie.get(cookieName);
            },
            toggleReturnProof: function (action = null) {
                if (((this.action == 'LP') || (this.action == 'UR')) && !action) {
                    $('#proof-return-modal').modal('hide');
                }
                this.action = action;
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                let data = {
                    action: this.action,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                if ((this.action == 'WC') || (this.action == 'MC')) {
                    data.temp_file = this.file.temp_file;
                    data.filename = this.file.filename;
                    data.file_type = this.file.file_type;
                    data.file_size = this.file.file_size;
                    data.comments = this.comments;
                }

                /**
                 * Send return proof request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/return';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = response.data.message;

                    // Close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#proof-return-modal').modal('hide');
                    }, 2000);

                    if (vm.$route.name == 'proofs') {
                        const component = vm.$root.findComponent(vm.$parent, 'proofs');
                        if (component) {
                            component.getProofs();
                        }

                        return null;
                    }

                    const component = vm.$root.findComponent(vm.$parent, 'view-proof');
                    if (component) {
                        component.loading = true;
                        component.getProof(vm.proof.id);
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#proof-return-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            makeAvailable: function () {
                if ((this.$route.name == 'view-proof') || (this.$route.name == 'view-proof-tab')) {
                    $('#proof-return-modal').modal('hide');
                    let component = this.$root.findComponent(this.$parent, 'view-proof');
                    if (component) {
                        component.loading = true;
                        component.makeAvailable(true);
                    }

                    return null;
                }
                this.updating = true;

                /**
                 * Send make available request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/make-available';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    $('#proof-return-modal').modal('hide');
                    let component = vm.$root.findComponent(vm.$parent, 'proofs');
                    if (component) {
                        component.getProofs();
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            viewImage: function (file) {
                this.viewImageIcon(file.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: file.filename,
                    text: '<div class="text-center"><img src="' + file.preview_image
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (fileId, show) {
                if (show) {
                    $('#view-image-icon' + fileId).show();
                } else {
                    $('#view-image-icon' + fileId).hide();
                }
            },
            doNothing: function () {
                return false;
            },
            dzAddFilesStyle: function () {
                if (!this.file) {
                    return 'display: block';
                }

                return 'display: none';
            },
            onFileAdded: function (file) {
                this.errors = false;
                if (this.file) {
                    this.$refs.proofReturnDropzone.removeFile(file);

                    return null;
                }

                // Check filename.
                if (file.name != this.proof.return_filename) {
                    this.$refs.proofReturnDropzone.removeFile(file);
                    this.errors = this.$root.text(
                        'The filename must be {filename}',
                        { filename: '<strong style="color: black">' + this.proof.return_filename + '</strong>' },
                        true
                    );

                    return null;
                }

                // Check file size.
                if (file.size > this.dropzone_options.maxFilesize) {
                    this.$refs.proofReturnDropzone.removeFile(file);
                    this.errors = this.$root.text('The maximum size of the file you can upload is {size}.',
                        { size: this.$root.formatFileSize(this.dropzone_options.maxFilesize) }, true) + ' '
                        + this.$root.text('The size of {filename} is {size}.',
                        { filename: file.name, size: this.$root.formatFileSize(file.size) }, true);

                    return null;
                }

                let newFile = {
                    id: file.upload.uuid,
                    filename: file.name,
                    file_type: file.type,
                    file_size: file.size,
                    upload: {
                        progress: 0,
                        bytes_sent: 0
                    },
                    preview_image: (file.dataURL || null)
                };
                this.file = newFile;
                var vm = this;
                setTimeout(function () {
                    if (vm.file) {
                        vm.$refs.proofReturnDropzone.processQueue();
                        vm.uploading = true;
                        $('#upload-info' + vm.file.id).html(vm.$root.text('Preparing upload...', null, true));
                    }
                }, 500);
            },
            onDragOver: function (event) {
                $('#proof-return-dropzone').addClass('dz-drag-highlight');
            },
            onDragLeave: function (event) {
                $('#proof-return-dropzone').removeClass('dz-drag-highlight');
            },
            onSendingFile: function (file, xhr, formData) {
                formData.append('id', file.upload.uuid);
            },
            onProgressUpdate: function (file, progress, bytesSent) {
                if (!this.file) {
                    return null;
                }
                if ((progress < 100) && (this.file.id == file.upload.uuid)) {
                    if (isNaN(bytesSent)) {
                        bytesSent = file.size * (progress / 100);
                    }
                    this.file.upload.progress = progress;
                    this.file.upload.bytes_sent = bytesSent;
                    $('#upload-info' + this.file.id).html(
                        this.$root.text('Uploading', null, true) + ' ' + this.$root.formatFileSize(bytesSent)
                            + ' ' + progress.toFixed(1) + '%'
                    );
                    $('#upload-progress' + this.file.id + ' > div').css('width', progress + '%');
                }
            },
            onFileUploaded: function (file, response) {
                if (!this.file) {
                    return null;
                }
                if (this.file.id == file.upload.uuid) {
                    this.file.upload.progress = 100;
                    this.file.upload.bytes_sent = file.size;
                    $('#upload-progress' + this.file.id + ' > div').css('width', '100%');
                    if (!this.file.preview_image) {
                        this.file.preview_image = (file.dataURL || null);
                    }
                    $('#upload-info' + this.file.id).html(
                        this.$root.text(this.file.preview_image ? 'Finalising upload...' : 'Previewing...', null, true)
                    );

                    this.getUploadInfo(this.file.id);
                    this.$refs.proofReturnDropzone.removeFile(file);
                }
            },
            getUploadInfo: function (fileId) {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/upload-info/' + fileId
                    + '/preview/500x500';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.file.id != response.data.file_id) {
                        return null;
                    }
                    vm.file.temp_file = response.data.temp_file;
                    vm.onUploadComplete();
                    $('#upload-info' + vm.file.id).html('');

                    if (response.data.preview) {
                        vm.file.preview_image = response.data.preview.src;
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            onUploadComplete: function () {
                this.uploading = false;
            },
            onUploadError: function (file, message, xhr) {
                if (file.status != 'canceled') {
                    if (!file.accepted) {
                        this.errors = this.$root.text('Invalid file type: {filename}', { filename: file.name }, true)
                            + '<br><br>' + this.$root.text('Please choose a PDF document, JPEG'
                            + ' image, Word document (DOC, DOCX), Excel spreadsheet (XLS, XLSX), PowerPoint presentation'
                            + ' (PPT, PPTX, PPSX), OpenOffice document (ODT), OpenOffice spreadsheet (ODS) or '
                            + 'OpenOffice presentation (ODP) file.', null, true);
                        this.$refs.proofReturnDropzone.removeFile(file);
                        this.file = null;
                    }
                    if (!this.errors) {
                        this.errors = this.$root.text('Something went wrong, please try again', null, true);
                    }
                }
                this.uploading = false;
                console.log(file, message);
            },
            removeUploadedFile: function(confirmed = false) {
                if (confirmed) {
                    const removeUploadFileId = (this.file.upload.progress < 100) ? this.file.id : null;

                    this.file = null;

                    if (removeUploadFileId) {
                        this.$refs.proofReturnDropzone.getUploadingFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.proofReturnDropzone.removeFile(file);
                            }
                        });
                        this.$refs.proofReturnDropzone.getQueuedFiles().forEach((file) => {
                            if (file.upload.uuid == removeUploadFileId) {
                                this.$refs.proofReturnDropzone.removeFile(file);
                            }
                        });
                    }
                
                    return null;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: this.$root.text('Delete file', null, true),
                    text: this.$root.text(
                        'Are you sure you want to delete: {filename}?',
                        { filename: this.file.filename },
                        true
                    ),
                    component: this,
                    action: 'delete-file'
                };
                $('#confirm-delete-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            confirmDelete: function (action) {
                if (action == 'delete-file') {
                    this.removeUploadedFile(true);
                }
            },
            showPleaseWaitMessage: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: this.$root.text(
                        'Replace {resource}',
                        { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                        true
                    ),
                    loading: true,
                    text: '<div style="text-align: center">' 
                        + this.$root.text('Please wait until the file is uploaded', null, true) + '</div>'
                };
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            modalTitle: function () {
                if (this.$route.name != 'proofs') {
                    if (this.action == 'LP') {
                        return this.$root.text('Lost proof?', null, true);
                    }
                    if (this.action == 'UR') {
                        return this.$root.text('Review later', null, true);
                    }
                }

                return this.$root.text('Return proof', null, true);
            },
            doNotShowAgain: function () {
                this.$root.setCookie(this.$root.app.client_id + '_pamd' + this.$root.user.id, 1, '1Y');
                var vm = this;
                setTimeout(function () {
                    vm.proof_approve_message_dismissed = true;
                }, 200);
            }
        }
    }
</script>
<style scoped>
    #proof-return-dropzone {
        min-height: 126px;
    }

    #proof-return-add-files {
        padding-top: 45px;
    }

    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-upload-info {
        left: 160px;
    }

    .dz-uploaded-file .dz-progress {
        width: calc(100% - 172px);
    }
</style>
