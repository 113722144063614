<template>
    <span style="position: absolute; top: 0px; left: 20px; white-space: nowrap">
        <span v-if="canDownloadCollection()">
            <button
                v-if="!downloading"
                class="btn btn-light px-2 px-md-1 px-lg-2 mr-2"
                id="collection-sub-nav-download"
                style="height: 38px; white-space: nowrap"
                @click="downloadCollection()"
                :disabled="!collectionHasItems()"
            >
                <i class="far fa-download font-size-16"></i>
                <span
                    v-if="$root.is_xl_screen || (!isCollaborator() && $root.is_md_screen)"
                    class="ml-1"
                    style="position: relative; top: -1px"
                >
                    {{ $root.text('Download', null, true) }} {{ collectionSelectedCount('download') }}
                </span>
            </button>
            <span v-else>
                <button
                    class="btn btn-light px-2 px-md-1 px-lg-2 mr-2"
                    style="height: 38px; white-space: nowrap"
                >
                    <span>
                        <i class="fal fa-spinner-third fa-spin font-size-16"></i>
                        <span
                            v-if="$root.is_xl_screen || (!isCollaborator() && $root.is_md_screen)"
                            class="ml-1"
                            style="position: relative; top: -1px"
                        >
                            {{ $root.text('Download', null, true) }} {{ collectionSelectedCount('download') }}
                        </span>
                    </span>
                </button>
            </span>
            <b-tooltip
                v-if="(!downloading && collectionHasItems()
                    && ((!$root.is_xl_screen && isCollaborator()) || !$root.is_md_screen))"
                target="collection-sub-nav-download"
                triggers="hover"
            >
                {{ $root.text('Download', null, true) }} {{ collectionSelectedCount('download') }}
            </b-tooltip>
        </span>

        <button
            v-if="$root.hasPermission('assets_send')"
            class="btn btn-light px-2 px-md-1 px-lg-2 mr-2"
            id="collection-sub-nav-share"
            style="height: 38px; white-space: nowrap"
            data-toggle="modal"
            data-target="#collection-share-modal"
            @click="shareCollection()"
            :disabled="!collectionHasItems()"
        >
            <i class="far fa-share-alt font-size-16"></i>
            <span
                v-if="$root.is_xl_screen || (!isCollaborator() && $root.is_md_screen)"
                class="ml-1"
                style="position: relative; top: -1px"
            >
                {{ $root.text('Share', null, true) }} {{ collectionSelectedCount() }}
            </span>
        </button>
        <b-tooltip
            v-if="(collectionHasItems() && ((!$root.is_xl_screen && isCollaborator()) || !$root.is_md_screen))"
            target="collection-sub-nav-share"
            triggers="hover"
        >
            {{ $root.text('Share', null, true) }} {{ collectionSelectedCount() }}
        </b-tooltip>

        <button
            v-if="$root.hasPermission('assets_send')"
            class="btn btn-light px-2 px-md-1 px-lg-2 mr-2"
            id="collection-sub-nav-share-link"
            style="height: 38px; white-space: nowrap"
            data-toggle="modal"
            data-target="#collection-share-modal"
            @click="shareCollection('link')"
            :disabled="!collectionHasItems()"
        >
            <i class="far fa-link font-size-16"></i>
            <span
                v-if="$root.is_xl_screen || (!isCollaborator() && $root.is_md_screen)"
                class="ml-1"
                style="position: relative; top: -1px"
            >
                {{ $root.text('Get link', null, true) }}
            </span>
        </button>
        <b-tooltip
            v-if="(collectionHasItems() && ((!$root.is_xl_screen && isCollaborator()) || !$root.is_md_screen))"
            target="collection-sub-nav-share-link"
            triggers="hover"
        >
            {{ $root.text('Get link', null, true) }}
        </b-tooltip>

        <span v-if="isCollaborator()">
            <button
                class="btn btn-light px-2 px-md-1 px-lg-2 mr-2"
                id="collection-sub-nav-add-asset"
                style="height: 38px; white-space: nowrap"
                @click="addAsset()"
                :disabled="!$root.view_collection_name"
            >
                <i class="far fa-file-plus font-size-16"></i>
                <span v-if="$root.is_xl_screen" class="ml-1" style="position: relative; top: -1px">
                    {{ $root.text('Add {resources}', { resources: $root.text($root.contents.asset_title + 's', null,
                        false, 'assets_title') }, true) }}
                </span>
            </button>
            <b-tooltip
                v-if="($root.view_collection_name && !$root.is_xl_screen)"
                target="collection-sub-nav-add-asset"
                triggers="hover"
            >
                {{ $root.text('Add {resources}', { resources: $root.text($root.contents.asset_title + 's', null, false,
                    'assets_title') }, true) }}
            </b-tooltip>

            <button
                v-if="$root.hasPermission('assets_manage_attachments')"
                class="btn btn-light px-2 px-md-1 px-lg-2 mr-2"
                id="collection-sub-nav-attach-file"
                style="height: 38px; white-space: nowrap"
                data-toggle="modal"
                data-target="#collection-linked-file-add-form-modal"
                @click="resetLinkedFileAddForm()"
                :disabled="!$root.view_collection_name"
            >
                <i class="far fa-paperclip font-size-16"></i>
                <span v-if="$root.is_xl_screen" class="ml-1" style="position: relative; top: -1px">
                    {{ $root.text('Attach file', null, true) }}
                </span>
            </button>
            <b-tooltip
                v-if="($root.view_collection_name && !$root.is_xl_screen)"
                target="collection-sub-nav-attach-file"
                triggers="hover"
            >
                {{ $root.text('Attach file', null, true) }}
            </b-tooltip>

            <button
                class="btn btn-light px-2 px-md-1 px-lg-2 mr-2"
                id="collection-sub-nav-collaborator"
                style="height: 38px; white-space: nowrap"
                data-toggle="modal"
                data-target="#collaborator-add-form-modal"
                @click="resetCollaboratorAddForm()"
                :disabled="!$root.view_collection_name"
            >
                <i class="far fa-user-plus font-size-16"></i>
                <span v-if="$root.is_xl_screen" class="ml-1" style="position: relative; top: -1px">
                    {{ $root.text('Collaborator', null, true) }}
                </span>
            </button>
            <b-tooltip
                v-if="($root.view_collection_name && !$root.is_xl_screen)"
                target="collection-sub-nav-collaborator"
                triggers="hover"
            >
                {{ $root.text('Add collaborator', null, true) }}
            </b-tooltip>

            <button
                v-if="($root.current_collection != $route.params.id)"
                class="btn btn-light px-2 px-md-1 px-lg-2"
                id="collection-sub-nav-make-active"
                style="height: 38px; white-space: nowrap; padding-top: 7px"
                @click="selectCollection()"
                :disabled="!$root.view_collection_name"
            >
                <i
                    class="far fa-toggle-on fa-flip-horizontal font-size-22"
                ></i>
                <span v-if="$root.is_xl_screen" class="ml-1" style="position: relative; top: -3px">
                    {{ $root.text('Make active', null, true) }}
                </span>
            </button>
            <b-tooltip
                v-if="($root.view_collection_name && !$root.is_xl_screen
                    && ($root.current_collection != $route.params.id))"
                target="collection-sub-nav-make-active"
                triggers="hover"
            >
                {{ $root.text('Make active to collect new {resources}',
                    { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title') }, true) }}
            </b-tooltip>
            <span v-if="($root.current_collection == $route.params.id)">
                <button
                    class="btn btn-light px-2 px-md-1 px-lg-2"
                    id="collection-sub-nav-active"
                    style="height: 38px; white-space: nowrap; padding-top: 7px"
                    @click="selectCollection(false)"
                >
                    <span>
                        <i
                            class="far fa-toggle-on font-size-22"
                        ></i>
                        <span v-if="$root.is_xl_screen" class="ml-1" style="position: relative; top: -3px">
                            {{ $root.text('Active', null, true) }}
                        </span>
                    </span>
                </button>
            </span>
            <b-tooltip
                v-if="(($root.current_collection == $route.params.id) && !$root.is_xl_screen)"
                target="collection-sub-nav-active"
                triggers="hover"
            >
                {{ $root.text('Active', null, true) }}
            </b-tooltip>
        </span>
    </span>
</template>

<script>
    export default {
        data () {
            return {
                downloading: false
            }
        },
        mounted () {
            //
        },
        methods: {
            canDownloadCollection: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'view-collection');
                if (!component) {
                    return false;
                }
                if ((component.asset_ids.length > 0) && !this.$root.hasPermission('assets_download')) {
                    return false;
                }

                return true;
            },
            collectionSelectedCount: function (button = 'share') {
                if (this.$root.collection_selected_assets.length == 0) {
                    if (button == 'share') {
                        return this.$root.text('collection');
                    }
                    return this.$root.text('ALL').toUpperCase();
                }

                return this.$root.collection_selected_assets.length;
            },
            downloadCollection: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const viewCollectionComponent = this.$root.findComponent(appComponent, 'view-collection');
                const component = this.$root.findComponent(appComponent, 'collection-download');
                if (component && viewCollectionComponent) {
                    this.downloading = true;
                    component.initData(this, viewCollectionComponent.collection);
                }
            },
            collectionHasItems: function () {
                if (this.$root.collection_selected_assets.length > 0) {
                    return true;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'view-collection');
                if (!component) {
                    return false;
                }

                return ((component.asset_ids.length > 0) || (component.linked_file_ids.length > 0));
            },
            shareCollection: function (type = null) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const viewCollectionComponent = this.$root.findComponent(appComponent, 'view-collection');
                const component = this.$root.findComponent(appComponent, 'collection-share');
                if (component && viewCollectionComponent) {
                    component.reset(
                        viewCollectionComponent.collection,
                        viewCollectionComponent.asset_ids,
                        viewCollectionComponent.linked_file_ids,
                        type
                    );
                }
            },
            selectCollection: function (select = true) {
                this.$root.selected_assets = [];
                if (select) {
                    this.$root.current_collection = this.$route.params.id;

                    return null;
                }
                this.$root.current_collection = null;
                this.$root.selected_assets = [];
            },
            resetLinkedFileAddForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'collection-linked-file-add-form');
                if (component) {
                    component.resetForm(this.$route.params.id);
                }
            },
            resetCollaboratorAddForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'collaborator-add-form');
                if (component) {
                    component.resetForm(this.$route.params.id, this.$root.view_collection_name);
                }
            },
            addAsset: function () {
                this.selectCollection();
                this.$parent.showSearchDropdown();
                $('#main-nav-small-content').addClass('show');
                setTimeout(function () {
                    $('#main-nav-small-search').dropdown('show');
                }, 200);
            },
            isCollaborator: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'view-collection');
                if (!component) {
                    return false;
                }
                if (component.collaborators.length == 0) {
                    return false;
                }
                let found = false;
                component.collaborators.forEach((item) => {
                    if (item.user_id == this.$root.user.id) {
                        found = true;
                    }
                });

                return found;
            }
        }
    }
</script>
<style scoped>
    .btn-light:disabled {
        background-color: transparent !important;
    }
</style>
