import { render, staticRenderFns } from "./AssetPreviousVersions.vue?vue&type=template&id=3f139f8b&scoped=true"
import script from "./AssetPreviousVersions.vue?vue&type=script&lang=js"
export * from "./AssetPreviousVersions.vue?vue&type=script&lang=js"
import style0 from "./AssetPreviousVersions.vue?vue&type=style&index=0&id=3f139f8b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f139f8b",
  null
  
)

export default component.exports