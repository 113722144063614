<template>
    <div class="row collaborator" :id="'collaborate-comment-' + item.id">
        <div class="col-1" style="min-width: 50px; padding-top: 11px">
            <div v-if="item.profile_image" class="avatar-xs">
                <img class="rounded-circle avatar-xs" :src="item.profile_image" alt="avatar">
            </div>
            <div v-else class="avatar-xs">
                <span class="avatar-title rounded-circle">
                    {{ $root.initial(item.first_name, item.last_name) }}
                </span>
            </div>
        </div>
        <div class="col pt-2 pb-2">
            <div :id="'collaborate-original-comment-' + item.id">
                <p class="mb-1" style="font-weight: 500">{{ item.first_name + ' ' + item.last_name }}</p>
                <p v-if="!editing" class="mb-1" style="overflow-wrap: anywhere">
                    <span
                        v-for="word in getCommentWords(item.comment_html)"
                        :key="word.index"
                        :class="word.span_class"
                        :id="'comment-word-' + item.id + '_' + word.index"
                        v-html="word.text"
                        @click="openLink(word.link)"
                        :style="'white-space: normal' + (word.link ? '; cursor: pointer' : '')"
                    ></span>
                    <span v-for="word in getCommentWords(item.comment_html)" :key="'tt-' + word.index">
                        <b-tooltip
                            v-if="word.link"
                            :target="'comment-word-' + item.id + '_' + word.index"
                            triggers="hover"
                        >
                            {{ (word.link.indexOf('view_asset') > -1)
                                ? $root.text(
                                    'View {resource}',
                                    { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                    true
                                )
                                : $root.text('Open link', null, true)
                            }}
                        </b-tooltip>
                    </span>
                </p>
                <div v-if="(item.user_id == $root.user.id)">
                    <form v-on:submit.prevent="updateComment()" :style="'display: ' + (editing ? 'block' : 'none')">
                        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                        <div class="form-group mb-2">
                            <textarea
                                v-model="comment"
                                class="form-control mandatory-field"
                                :id="'collection-comment-' + item.id"
                                rows="5"
                                v-on:focus="commentPlaceholder(true)"
                                :placeholder="commentPlaceholder()"
                                v-on:blur="commentPlaceholder(true)"
                                @keydown="onKeydown(false, $event)"
                                @keyup="onKeyup(false, $event)"
                                :disabled="updating"
                            ></textarea>
                            <div class="dropdown">
                                <div
                                    class="dropdown-menu comment-link"
                                    :id="'comment-link-' + item.id"
                                    style="max-width: 280px; max-height: 150px; overflow-x: hidden; overflow-y: auto;
                                        top: -40px"
                                >
                                    <a
                                        v-for="option in comment_link_options"
                                        :key="option.id"
                                        :id="'comment-link-' + item.id + '-option' + option.id"
                                        href="#"
                                        :class="'dropdown-item' + ((option.id == selected_option) ? ' active' : '')"
                                        style="white-space: normal"
                                        @click.prevent="addLink(option)"
                                    >{{ option.name }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 text-right">
                            <p v-if="!updating" class="float-left contact-links mb-0">
                                <span
                                    v-if="(collaborators.length > 1)"
                                    class="ml-1 mr-2"
                                    v-b-tooltip.hover.bottom
                                    :title="$root.text('Mention a collaborator', null, true)"
                                >
                                    <a href="#" @click.prevent="focusCommentField(false, 'user')">
                                        <i class="far fa-at"></i>
                                    </a>
                                </span>
                                <span
                                    v-if="(assets.length > 0)"
                                    class="ml-1"
                                    v-b-tooltip.hover.bottom
                                    :title="$root.text(
                                        'Link a {resource} from this collection',
                                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                        true
                                    )"
                                >
                                    <a href="#" @click.prevent="focusCommentField(false, 'asset')">
                                        <i class="far fa-hashtag"></i>
                                    </a>
                                </span>
                            </p>
                            <span v-if="!updating">
                                <span>
                                    <button
                                        type="submit"
                                        class="btn btn-primary mb-1"
                                        @click="updateComment()"
                                        :disabled="comment == item.comment"
                                        style="width: 86px"
                                    >
                                        <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                                    </button>
                                </span>
                            </span>
                            <span v-else>
                                <button type="button" class="btn btn-primary mb-1" style="width: 86px">
                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                    <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                                </button>
                            </span>
                            <button type="button" class="btn btn-light ml-2 mb-1" @click="editing = false">
                                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
                            </button>
                        </div>
                    </form>
                </div>
                <p v-if="!editing" class="mb-0 text-muted">
                    <i class="far fa-clock"></i> {{ time_ago }}
                    <span
                        v-if="(item.user_id == $root.user.id)"
                        class="contact-links ml-3"
                        v-b-tooltip.hover
                        :title="$root.text('Edit comment', null, true)"
                    >
                        <a href="#" @click.prevent="editComment()"><i class="far fa-edit"></i></a>
                    </span>
                    <span
                        v-if="!item.reply_to"
                        class="contact-links ml-3"
                        v-b-tooltip.hover
                        :title="$root.text('Reply', null, true)"
                    >
                        <a href="#" @click.prevent="replyToComment()"><i class="far fa-reply"></i></a>
                    </span>
                </p>
            </div>

            <hr v-if="(hasReplies() || replying)">

            <form
                v-on:submit.prevent="addReply()"
                class="mt-2"
                :id="'collaborate-reply-comment-add-' + item.id"
                :style="'display: ' + (replying ? 'block' : 'none')"
            >
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div class="form-group mb-2">
                    <textarea
                        v-model="reply_comment"
                        class="form-control mandatory-field"
                        :id="'collection-reply-comment-' + item.id"
                        rows="5"
                        @keydown="onKeydown(true, $event)"
                        @keyup="onKeyup(true, $event)"
                        :disabled="reply_updating"
                    ></textarea>
                    <div v-if="replying" class="dropdown">
                        <div
                            class="dropdown-menu comment-link"
                            :id="'reply-comment-link-' + item.id"
                            style="max-width: 280px; max-height: 150px; overflow-x: hidden; overflow-y: auto;
                                top: -20px"
                        >
                            <a
                                v-for="option in comment_link_options"
                                :key="option.id"
                                :id="'reply-comment-link-' + item.id + '-option' + option.id"
                                href="#"
                                :class="'dropdown-item' + ((option.id == selected_option) ? ' active' : '')"
                                style="white-space: normal"
                                @click.prevent="addLink(option)"
                            >{{ option.name }}</a>
                        </div>
                    </div>
                </div>
                <div v-if="replying" class="form-group mb-2 text-right">
                    <p v-if="!updating" class="float-left contact-links mb-0">
                        <span
                            v-if="(collaborators.length > 1)"
                            class="ml-1 mr-2"
                            v-b-tooltip.hover.bottom
                            :title="$root.text('Mention a collaborator', null, true)"
                        >
                            <a href="#" @click.prevent="focusCommentField(true, 'user')">
                                <i class="far fa-at"></i>
                            </a>
                        </span>
                        <span
                            v-if="(assets.length > 0)"
                            class="ml-1"
                            v-b-tooltip.hover.bottom
                            :title="$root.text(
                                'Link a {resource} from this collection',
                                { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                true
                            )"
                        >
                            <a href="#" @click.prevent="focusCommentField(true, 'asset')">
                                <i class="far fa-hashtag"></i>
                            </a>
                        </span>
                    </p>
                    <span v-if="!reply_updating">
                        <span>
                            <button
                                type="submit"
                                class="btn btn-primary mb-1"
                                @click="addReply()"
                                :disabled="!reply_comment"
                                style="width: 86px"
                            >
                                <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Post', null, true) }}
                            </button>
                        </span>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary mb-1" style="width: 86px">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Post', null, true) }}</span>
                        </button>
                    </span>
                    <button type="button" class="btn btn-light ml-2 mb-1" @click="replying = false">
                        <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
                    </button>
                </div>
            </form>

            <div v-for="reply in item.replies" :key="reply.id">
                <collaborate-comment
                    :item="reply"
                    :comment_link_options="comment_link_options"
                    :selected_option="selected_option"
                    :collaborators="collaborators"
                    :assets="assets"
                ></collaborate-comment>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['item', 'comment_link_options', 'selected_option', 'collaborators', 'assets'],
        data () {
            return {
                time_ago: '',
                get_date_time_formatted_response: null,
                time_ago_interval: null,
                comment: '',
                editing: false,
                updating: false,
                errors: false,
                replying: false,
                reply_comment: '',
                reply_updating: false,
                last_key: null
            }
        },
        mounted () {
            this.updateTimeAgo();
            var vm = this;
            vm.time_ago_interval = setInterval(function () {
                vm.updateTimeAgo();
            }, 59999);

            setTimeout(function () {
                if (vm.item.user_id == vm.$root.user.id) {
                    $('#collection-comment-' + vm.item.id).trackRows();
                }
                $('#collection-reply-comment-' + vm.item.id).trackRows();
            }, 500);
        },
        beforeDestroy() {
            clearInterval(this.time_ago_interval);
        },
        watch: {
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'time_ago_' + this.item.id) {
                            this.time_ago = val.date_time_formatted;
                        }
                    }
                }
            },
            editing: function (val) {
                if (val) {
                    $('#collaborate-comment-add').addClass('static');
                    this.scrollToComment(this.item.id);

                    return null;
                }
                $('#collaborate-comment-add').removeClass('static');
                setTimeout(function () {
                    $('.collaborate-bar .tab-content').animate({
                        scrollTop: '-=' + ($('#collaborate-comment-add').height() + 20)
                    }, 700);
                }, 500);
            },
            replying: function (val) {
                if (val) {
                    $('#collaborate-comment-add').addClass('static');
                    this.scrollToComment(this.item.id);

                     return null;
                }
                $('#collaborate-comment-add').removeClass('static');
                setTimeout(function () {
                    $('.collaborate-bar .tab-content').animate({
                        scrollTop: '-=' + ($('#collaborate-comment-add').height() + 20)
                    }, 700);
                }, 500);
            },
            last_key: function (val) {
                if (val) {
                    this.$parent.last_key = val;
                    this.last_key = null;
                }
            }
        },
        methods: {
            updateTimeAgo: function () {
                this.$root.getDateTimeFormatted(this, 'time_ago_' + this.item.id, this.item.created_at, 'time_ago');
            },
            editComment: function () {
                if (this.reply_updating) {
                    return null;
                }
                this.replying = false;
                this.comment = this.item.comment;
                this.editing = true;
                this.errors = false;

                var vm = this;
                setTimeout(function () {
                    $('#collection-comment-' + vm.item.id).focus();
                }, 200);
            },
            replyToComment: function () {
                if (this.item.reply_to) {
                    this.$parent.replyToComment();

                    return null;
                }
                if (this.updating) {
                    return null;
                }
                this.editing = false;
                this.replying = true;
                this.errors = false;

                var vm = this;
                setTimeout(function () {
                    $('#collection-reply-comment-' + vm.item.id).focus();
                }, 200);
            },
            commentPlaceholder: function (update = false) {
                const focused = $('#collection-comment-' + this.item.id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#collection-comment-' + this.item.id).attr('placeholder', null);
                    }

                    return null;
                }
                const text = this.$root.text('Make a comment', null, true);
                if (update) {
                    $('#collection-comment-' + this.item.id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            updateComment: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = {
                    comment: this.comment,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-comment/'
                    + this.item.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.message) {
                        const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        const component = vm.$root.findComponent(appComponent, 'view-collection');
                        if (component) {
                            component.getComments();
                        }
                        vm.editing = false;
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    //$('#asset-type-' + key + '-label').addClass('text-danger');
                                    $('#collection-' + key + '-' + vm.item.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            onKeydown: function (isReply, event) {
                this.last_key = event.key;
                if ((event.key != 'ArrowUp') && (event.key != 'ArrowDown') && (event.key != 'Enter')) {
                    return null;
                }
                if (this.lookup(isReply, event, true)) {
                    event.preventDefault();
                }
            },
            onKeyup: function (isReply, event) {
                this.lookup(isReply, event);
            },
            lookup: function (isReply, event, test = false) {
                if (event.key == '@') {
                    return this.lookupUser(null, null, isReply, test);
                }
                if (event.key == '#') {
                    return this.lookupAsset(null, null, isReply, test);
                }
                let position = isReply ? $('#collection-reply-comment-' + this.item.id)[0].selectionStart
                    : $('#collection-comment-' + this.item.id)[0].selectionStart;
                if (position > 0) {
                    let word = '';
                    let char = '';
                    do {
                        position--;
                        char = isReply ? this.reply_comment.substr(position, 1).toString()
                            : this.comment.substr(position, 1).toString();
                        if ((char != '@') && (char != '#') && (char != ' ')) {
                            word = char + word;
                        }
                    } while ((position > 0) && (char != '@') && (char != '#') && (char != ' '));

                    if (char == '@') {
                        return this.lookupUser(null, word.toLowerCase(), isReply, test);
                    }
                    if (char == '#') {
                        return this.lookupAsset(null, word.toLowerCase(), isReply, test);
                    }
                }
                if (isReply) {
                    $('#reply-comment-link-' + this.item.id).hide();
                } else {
                    $('#comment-link-' + this.item.id).hide();
                }

                return false;
            },
            getCommentWords: function (comment) {
                const words = comment.split(' ');
                let tempWords = [];
                let spanClass = '';
                let link = null;
                let index = 0;
                let parts = [];
                words.forEach((word) => {
                    spanClass = '';
                    link = null;
                    if ((word.substr(0, 1) == '#') && (word.length > 1)) {
                        spanClass = 'badge badge-soft-secondary font-size-11 mr-1';
                        word = word.replace(/_/g, ' ');
                        parts = word.split(' ');
                        link = 'view_asset|' + parts[0];
                    } else if ((word.substr(0, 1) == '@') && (word.length > 1)) {
                        spanClass = 'badge badge-primary font-size-11 mr-1';
                        word = word.replace(/_/g, ' ');
                    } else {
                        word += ' ';
                    }
                    tempWords.push({
                        index: index,
                        text: word,
                        span_class: spanClass,
                        link: link
                    });
                    index++;
                });

                return tempWords;
            },
            openLink: function (link) {
                if (!link) {
                    return null;
                }
                const parts = link.split('|');
                if (parts[0] == 'view_asset') {
                    let itemIndex = -1;
                    this.assets.forEach((item, index) => {
                        if (item.id == parseInt(parts[1].substr(1))) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex > -1) {
                        const appComponent = this.$root.findComponent(this.$root, 'main-app');
                        const component = this.$root.findComponent(appComponent, 'view-collection');
                        if (component) {
                            component.view_asset_from_selection = false;
                            component.view_asset_tab = this.$root.client.asset_details_default_tab;
                            component.view_asset = this.assets[itemIndex];
                        }

                        return null;
                    }
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    appComponent.message = {
                        type: 'error',
                        title: this.$root.text(
                            'View {resource}',
                            { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        ),
                        text: this.$root.text(
                            'Sorry, the linked {resource} cannot be found in this collection.',
                            { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        )
                    };
                    $('#message-modal').modal('show');
                }
            },
            addReply: function () {
                this.errors = false;
                this.reply_updating = true;
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = {
                    comment: this.reply_comment,
                    reply_to: this.item.id,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-comment/'
                    + this.item.collection_id;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.message) {
                        const appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        const component = vm.$root.findComponent(appComponent, 'view-collection');
                        if (component) {
                            component.getComments();
                        }
                        vm.reply_comment = '';
                        vm.replying = false;
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#collection-reply-' + key + '-' + item.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.reply_updating = false
                );
            },
            hasReplies: function () {
                if (!this.item.replies) {
                    return false;
                }

                return (this.item.replies.length > 0);
            },
            addLink: function (option) {
                this.$parent.addLink(option);
            },
            focusCommentField: function (isReply, lookup = null) {
                const fieldId = isReply ? 'collection-reply-comment-' + this.item.id
                    : 'collection-comment-' + this.item.id;
                $('#' + fieldId).focus();
                if (!lookup) {
                    return null;
                }
                let position = 0;
                let text1 = '';
                let text2 = '';
                const symbol = (lookup == 'user') ? '@' : '#';
                if (isReply) {
                    position = $('#' + fieldId)[0].selectionStart || this.reply_comment.length;
                    text1 = this.reply_comment.substr(0, position);
                    text2 = this.reply_comment.substr(position);
                    this.reply_comment = text1 + symbol + text2;
                } else {
                    position = $('#' + fieldId)[0].selectionStart || this.comment.length;
                    text1 = this.comment.substr(0, position);
                    text2 = this.comment.substr(position);
                    this.comment = text1 + symbol + text2;
                }
                var vm = this;
                var focusFieldId = fieldId;
                var focusPosition = position + 1;
                var focusIsReply = isReply;
                if (lookup == 'user') {
                    setTimeout(function () {
                        $('#' + focusFieldId)[0].selectionEnd = focusPosition;
                        vm.lookupUser(null, null, focusIsReply);
                    }, 200);
                }
                if (lookup == 'asset') {
                    setTimeout(function () {
                        $('#' + focusFieldId)[0].selectionEnd = focusPosition;
                        vm.lookupAsset(null, null, focusIsReply);
                    }, 200);
                }
            },
            lookupUser: function (component = null, keyword = null, isReply = false, test = false) {
                return this.$parent.lookupUser(component || this, keyword, isReply, test);
            },
            lookupAsset: function (component = null, keyword = null, isReply = false, test = false) {
                return this.$parent.lookupAsset(component || this, keyword, isReply, test);
            },
            scrollToComment: function (itemId) {
                this.$parent.scrollToComment(itemId);
            }
        }
    }
</script>
