<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            {{ $root.text('There are no keyword categories', null, true) }}
        </div>
        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-9">{{ $root.text('Keyword category', null, true) }}</div>
                                        <div class="col-2">{{ $root.text('Keyword suggestions', null, true) }}</div>
                                        <div class="col-1">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'category-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-9" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditCategory(item)">
                                                    {{ item.name }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-2" style="padding-top: 3px">
                                            {{ item.keyword_suggestions.length }}
                                        </div>
                                        <div class="col-1 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditCategory(item)"
                                            >
                                                <span
                                                    v-if="(category_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'category-details' + item.id">
                                            <keyword-category-edit-form
                                                :data="item"
                                            ></keyword-category-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="keyword-category-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="keyword-category-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <keyword-category-add-form></keyword-category-add-form>
            </div>
        </div>

        <div
            class="modal fade"
            id="keyword-suggestion-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="keyword-suggestion-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <keyword-suggestion-add-form></keyword-suggestion-add-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'keyword-categories',
                loading: false,
                errors: false,
                items: [],
                category_details_open: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getKeywordSuggestions();
        },
        methods: {
            getKeywordSuggestions: function () {
                if (!this.$root.hasPermission('assets_keyword_assistant')) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                this.loading = true;
                this.errors = false;
                this.items = [];
                this.category_details_open = [];

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/keyword-suggestions';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items = response.data.keyword_categories;
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            toggleEditCategory: function (folder) {
                if (this.category_details_open.indexOf(folder.id) < 0) {
                    this.category_details_open.push(folder.id);
                    $('#category-row' + folder.id).addClass('highlighted');
                    $('#category-details' + folder.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#category-details' + folder.id).slideUp('slow', function () {
                        $('#category-row' + folder.id).removeClass('highlighted');
                        for (var n = 0; n < vm.category_details_open.length; n++) { 
                            if (vm.category_details_open[n] == folder.id) { 
                                vm.category_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            }
        }
    }
</script>
