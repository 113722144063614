<template>
    <div>
        <div v-if="$root.app.maintenance" class="row justify-content-center no-gutters">
            <div class="col-lg-6 col-md-8 col-10 text-center" style="padding-top: 100px">
                <div v-html="$root.app.maintenance_message"></div>
            </div>
        </div>

        <div
            v-if="!$root.app.maintenance && $root.app.maintenance_notice
                && (!isExternalPage() || $root.app.maintenance_notice_public)"
            class="alert alert-info maintenance-notice mb-0"
            role="alert"
        >
            <button type="button" class="close" aria-label="Close" @click="hideMaintenanceNotice()">
                <span aria-hidden="true">×</span>
            </button>
            <div v-html="$root.app.maintenance_notice" style="width: calc(100% - 30px)"></div>
        </div>

        <div v-if="!$root.app.maintenance && isLoading()">
            <loading></loading>
        </div>

        <div v-if="!$root.app.maintenance && errors" class="row justify-content-center no-gutters">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                <div v-html="errors" class="alert alert-danger" role="alert"></div>
            </div>
        </div>

        <div v-if="!$root.app.maintenance && !isLoading() && $root.client">
            <div v-if="!isExternalPage()" class="header-bar px-2">
                <div class="container-fluid">
                    <div class="row">
                        <div
                            v-if="$root.app.header_left || $root.app.header_right"
                            :class="'col-' + ($root.app.header_center ? '4' : '6')"
                        >
                            <router-link :to="{ name: $root.isProofsGuest() ? 'proofs' : 'home' }">
                                <img
                                    v-if="$root.app.header_left"
                                    class="img-fluid"
                                    :src="$root.app.header_left"
                                    alt="header-left"
                                >
                            </router-link>
                        </div>
                        <div v-if="$root.app.header_center" class="col text-center">
                            <router-link :to="{ name: $root.isProofsGuest() ? 'proofs' : 'home' }">
                                <img class="img-fluid" :src="$root.app.header_center" alt="header-center">
                            </router-link>
                        </div>
                        <div
                            v-if="$root.app.header_left || $root.app.header_right"
                            :class="'col-' + ($root.app.header_center ? '4' : '6') + ' text-right'"
                        >
                            <router-link :to="{ name: $root.isProofsGuest() ? 'proofs' : 'home' }">
                                <img
                                    v-if="$root.app.header_right"
                                    class="img-fluid"
                                    :src="$root.app.header_right"
                                    alt="header-right"
                                >
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <div id="layout-wrapper" style="display: block !important">
                <main-nav v-if="!isExternalPage()" :imitate="$root.imitate"></main-nav>

                <div :class="mainContentClass()">
                    <div :class="pageContentClass()">
                        <div :class="containerFluidClass()" id="page-container">
                            <imitate-user v-if="($root.isLoggedIn() && $root.imitate)"></imitate-user>
                            <router-view :key="$route.fullPath"></router-view>
                        </div>

                        <asset-add-form
                            v-if="(!isExternalPage() && $root.hasPermission('assets_upload'))"
                        ></asset-add-form>

                        <template-add-form
                            v-if="(!isExternalPage() && $root.hasPermission('templates_upload'))"
                        ></template-add-form>

                        <artwork-editor
                            v-if="(!isExternalPage() && $root.hasPermission('templates_access')
                                && $root.hasPermission('templates_use'))"
                        ></artwork-editor>
                    </div>

                    <site-footer v-if="!isExternalPage() && !$root.editing_artwork"></site-footer>
                </div>
            </div>

            <div
                v-if="!$root.isPublicRoute()"
                class="modal fade"
                id="terms-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="terms-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable modal-lg">
                    <terms-and-conditions :must_accept="false" :content="getTermsContent()"></terms-and-conditions>
                </div>
            </div>

            <div v-if="($root.isLoggedIn() && !$root.isPublicRoute())">
                <div
                    v-if="$root.hasPermission('proofs_access') && isProofsPage()"
                    class="modal fade"
                    id="proof-return-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="proof-return-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <proof-return></proof-return>
                    </div>
                </div>

                <div
                    v-if="$root.client.similar_assets_search && $root.hasPermission('assets_access')"
                    class="modal fade"
                    id="reverse-image-search-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="reverse-image-search-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <reverse-image-search></reverse-image-search>
                    </div>
                </div>

                <div
                    v-if="$root.client.similar_assets_search && $root.hasPermission('assets_access')"
                    class="modal fade"
                    id="reverse-image-search-tooltip-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="reverse-image-search-tooltip-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <reverse-image-search-tooltip></reverse-image-search-tooltip>
                    </div>
                </div>

                <div
                    v-if="$root.user.show_mega_menu_info"
                    class="modal fade"
                    id="mega-menu-info-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="mega-menu-info-modal-label"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal-dialog-scrollable modal-lg"
                        style="margin-top: 160px; min-width: 720px"
                    >
                        <mega-menu-info></mega-menu-info>
                    </div>
                </div>

                <div
                    class="modal fade"
                    id="user-activity-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="user-activity-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-lg">
                        <user-activity></user-activity>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('templates_access') && $root.hasPermission('templates_use')"
                    class="modal fade"
                    id="artwork-history-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="artwork-history-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-lg">
                        <artwork-history></artwork-history>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('templates_access') && $root.hasPermission('templates_use')"
                    class="modal fade"
                    id="template-options-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="template-options-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <template-options></template-options>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('templates_access') && $root.hasPermission('templates_use')"
                    class="modal fade"
                    id="artwork-share-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="artwork-share-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <artwork-share></artwork-share>
                    </div>
                </div>

                <div
                    v-if="$root.editing_artwork"
                    class="modal fade"
                    id="artwork-approval-request-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="artwork-approval-request-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <artwork-approval-request-form></artwork-approval-request-form>
                    </div>
                </div>

                <div
                    v-if="$root.isFeatured('printing')"
                    class="modal fade"
                    id="price-panel-view-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="price-panel-view-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <price-panel-view></price-panel-view>
                    </div>
                </div>

                <div
                    v-if="$root.isFeatured('printing')"
                    class="modal fade"
                    id="print-order-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="print-order-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-lg">
                        <print-order-form :is_modal="true"></print-order-form>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('templates_upload')"
                    class="modal fade"
                    id="template-image-upload-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="template-image-upload-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <template-image-upload></template-image-upload>
                    </div>
                </div>

                <div
                    v-if="($root.hasPermission('templates_use') && $root.editing_artwork)"
                    class="modal fade"
                    id="template-images-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="template-images-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-xl">
                        <template-images></template-images>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('templates_access') && $root.hasPermission('templates_use')"
                    class="modal fade"
                    id="artwork-view-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="artwork-view-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-lg">
                        <artwork-view></artwork-view>
                    </div>
                </div>

                <div
                    class="modal fade"
                    id="change-password-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="change-password-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <change-password></change-password>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('assets_manage_attachments')"
                    class="modal fade"
                    id="collection-linked-file-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="collection-linked-file-add-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-xl">
                        <collection-linked-file-add-form></collection-linked-file-add-form>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('assets_asset_types')"
                    class="modal fade"
                    id="asset-type-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="asset-type-add-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <asset-type-add-form></asset-type-add-form>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('assets_manage_folders')"
                    class="modal fade"
                    id="folder-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="folder-add-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <folder-add-form></folder-add-form>
                    </div>
                </div>

                <div
                    class="modal fade"
                    id="message-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="message-modal-label"
                    aria-hidden="true"
                    style="z-index: 1070"
                >
                    <div class="modal-dialog modal-dialog-scrollable" style="margin-top: 100px">
                        <message :data="message"></message>
                    </div>
                </div>

                <div
                    class="modal fade"
                    id="multiple-uploads-tooltip-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="multiple-uploads-tooltip-modal-label"
                    aria-hidden="true"
                    style="z-index: 1070"
                >
                    <div class="modal-dialog modal-dialog-scrollable" style="margin-top: 100px">
                        <multiple-uploads-tooltip></multiple-uploads-tooltip>
                    </div>
                </div>

                <div
                    class="modal fade"
                    id="confirm-delete-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="confirm-delete-modal-label"
                    aria-hidden="true"
                    style="z-index: 1070"
                >
                    <div class="modal-dialog modal-dialog-scrollable" style="margin-top: 200px">
                        <confirm-delete :data="confirm_delete"></confirm-delete>
                    </div>
                </div>

                <div
                    v-if="canAddUsers()"
                    class="modal fade"
                    id="user-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="user-add-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-xl">
                        <user-add-form></user-add-form>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('users_invite')"
                    class="modal fade"
                    id="resend-expired-invites-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="resend-expired-invites-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <resend-expired-invites></resend-expired-invites>
                    </div>
                </div>

                <div
                    v-if="($root.hasPermission('assets_access') || $root.hasPermission('templates_view'))"
                    class="modal fade"
                    id="collection-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="collection-add-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <collection-add-form></collection-add-form>
                    </div>
                </div>

                <div v-if="$root.hasPermission('assets_archive')">
                    <div
                        class="modal fade"
                        id="asset-archive-modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="asset-archive-modal-label"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-scrollable">
                            <asset-archive></asset-archive>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="asset-de-archive-modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="asset-de-archive-modal-label"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-scrollable">
                            <asset-de-archive></asset-de-archive>
                        </div>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('templates_archive')"
                    class="modal fade"
                    id="template-archive-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="template-archive-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <template-archive></template-archive>
                    </div>
                </div>
                <div
                    v-if="$root.hasPermission('templates_manage_archive')"
                    class="modal fade"
                    id="template-de-archive-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="template-de-archive-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <template-de-archive></template-de-archive>
                    </div>
                </div>

                <div v-if="($root.hasPermission('assets_download') || $root.hasPermission('assets_edit_image'))">
                    <div
                        class="modal fade"
                        id="asset-approval-request-form-modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="asset-approval-request-form-modal-label"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-scrollable">
                            <asset-approval-request-form></asset-approval-request-form>
                        </div>
                    </div>
                </div>

                <div v-if="($root.hasPermission('assets_send'))">
                    <div
                        class="modal fade"
                        id="asset-share-modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="asset-share-modal-label"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-scrollable modal-lg">
                            <asset-share :is_modal="true"></asset-share>
                        </div>
                    </div>
                    <div
                        class="modal fade"
                        id="collection-share-modal"
                        data-backdrop="static"
                        data-keyboard="false"
                        tabindex="-1"
                        aria-labelledby="collection-share-modal-label"
                        aria-hidden="true"
                    >
                        <div class="modal-dialog modal-dialog-scrollable modal-lg">
                            <collection-share></collection-share>
                        </div>
                    </div>
                </div>

                <div
                    v-if="($root.hasPermission('assets_access') || $root.hasPermission('templates_view'))"
                    class="modal fade"
                    id="collaborator-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="collaborator-add-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-lg">
                        <collaborator-add-form></collaborator-add-form>
                    </div>
                </div>

                <div
                    class="modal fade"
                    id="select-item-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="select-item-modal-label"
                    aria-hidden="true"
                    style="z-index: 1070"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-xl">
                        <select-item></select-item>
                    </div>
                </div>

                <div
                    class="modal fade"
                    id="edit-my-details-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="edit-my-details-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-lg">
                        <my-account-edit></my-account-edit>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('flyte_access')"
                    class="modal fade"
                    id="flyte-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="flyte-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-xl" style="transition: max-width .35s ease">
                        <flyte-form></flyte-form>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('proofs_create')"
                    class="modal fade"
                    id="proof-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="proof-add-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable" style="transition: max-width .35s ease">
                        <proof-add-form></proof-add-form>
                    </div>
                </div>

                <div
                    v-if="($root.hasPermission('proofs_create')
                        || ($root.hasPermission('proofs_access')
                        && (($route.name == 'view-proof') || ($route.name == 'view-proof-tab'))))"
                    class="modal fade"
                    id="proof-user-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="proof-user-add-form-modal-label"
                    aria-hidden="true"
                    style="z-index: 1070"
                >
                    <div class="modal-dialog modal-dialog-scrollable modal-xl">
                        <proof-user-add-form></proof-user-add-form>
                    </div>
                </div>

                <div
                    v-if="$root.hasPermission('profiles_groups_add')"
                    class="modal fade"
                    id="profile-group-add-form-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="profile-group-add-form-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <profile-group-add-form></profile-group-add-form>
                    </div>
                </div>
            </div>

            <div
                class="modal fade"
                id="email-support-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="email-support-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable modal-lg">
                    <email-support></email-support>
                </div>
            </div>

            <div
                v-if="(isSharedPage() || $root.hasPermission('assets_download'))"
            >
                <div
                    class="modal fade"
                    id="asset-download-modal"
                    data-backdrop="static"
                    data-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="asset-download-modal-label"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-scrollable">
                        <asset-download></asset-download>
                    </div>
                </div>
            </div>

            <div
                v-if="isSharedPage() || $root.hasPermission('assets_access') || $root.hasPermission('templates_view')"
                class="modal fade"
                id="collection-download-modal"
                data-backdrop="static"
                data-keyboard="false"
                tabindex="-1"
                aria-labelledby="collection-download-modal-label"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable">
                    <collection-download></collection-download>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'main-app',
                errors: false,
                message: {
                    type: null,
                    title: '',
                    text: ''
                },
                confirm_delete: {
                    title: '',
                    text: '',
                    component: null,
                    action: null
                },
                user_loaded: false,
                get_content_response: null,
                login_background_loaded: false,
                content_loading_errors: false,
                is_maintenance_notice_visible: false
            }
        },
        created () {
            this.errors = false;
            this.$root.app = this.$attrs.app_info;
        },
        mounted () {
            this.$root.hideReCaptchaBadge();
            if (this.$root.app.maintenance) {
                setTimeout(function () {
                    window.location.href='/';
                }, 1000 * 60 * 5);

                return null;
            }
            this.getApiVersion();
            this.getClient();
            if (!this.$root.isLoggedIn() && this.$cookie.get(this.$root.app.client_id + '_api_token')) {
                this.$root.token = this.$cookie.get(this.$root.app.client_id + '_api_token');
                this.$root.getUser(this);
            } else {
                this.user_loaded = true;
            }
            this.getContents();
            this.getLanguages();
            this.$root.getContent(this, 'login_background');

            var vmApp = this;
            setInterval(function () {
                const windowWidth = $(window).outerWidth();
                vmApp.$root.is_sm_screen = (windowWidth >= 576);
                vmApp.$root.is_md_screen = (windowWidth >= 768);
                vmApp.$root.is_lg_screen = (windowWidth >= 992);
                vmApp.$root.is_xl_screen = (windowWidth >= 1350);
                vmApp.$root.is_xl2_screen = (windowWidth >= 1370);
                vmApp.$root.is_xxl_screen = (windowWidth >= 1700);
                vmApp.$root.is_3xl_screen = (windowWidth >= 1900);
                vmApp.$root.is_4xl_screen = (windowWidth >= 2500);

                if (!vmApp.$root.is_md_screen && $('#mega-menu-info-modal').hasClass('show')) {
                    $('#mega-menu-info-modal').modal('hide');
                }
            }, 500);
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.key) {
                        this.$data[val.key + '_loaded'] = true;
                    }
                    if (val.status == 200) {
                        this.$root.contents[val.key] = val.content;
                        this.applyClientStyles();

                        return null;
                    }
                    this.content_loading_errors = true;
                }
            }
        },
        methods: {
            getApiVersion: function () {
                /**
                 * Get version from API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/version';
                var vm = this;

                axios({
                    url: url
                })
                .then(response => {
                    vm.$root.app.api_version = response.data.version;
                })
                .catch(function (error) {
                    //
                });
            },
            getClient: function () {
                /**
                 * Get client from API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/client';
                var vm = this;

                axios({
                    url: url
                })
                .then(response => {
                    vm.$root.client = response.data.client;
                    $('title').html(response.data.client.client_name);
                    if (vm.$root.app.maintenance) {
                        return null;
                    }
                    
                    // Get current language.
                    let cookieName = vm.$root.app.client_id + '_language';
                    const currentLanguage = parseInt(vm.$cookie.get(cookieName));
                    if (currentLanguage) {
                        let found = true;
                        if (vm.$root.languages.length > 0) {
                            found = false;
                            vm.$root.languages.forEach((item) => {
                                if (item.active && (item.id == currentLanguage)) {
                                    found = true;
                                }
                            });
                        }

                        if (!found) {
                            vm.$root.current_language = null;
                            vm.$cookie.delete(cookieName);
                        } else {
                            vm.$root.current_language = currentLanguage;
                        }
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 503) {
                            vm.$root.showMaintenanceMessage(error.response.data.message);
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                });
            },
            applyClientStyles: function () {
                if (this.$root.app.maintenance) {
                    return null;
                }
                const pCol = (this.$root.client.primary_colour || '#999');
                const pTxt = (this.$root.client.primary_text_colour || '#fff');
                const sCol = (this.$root.client.secondary_colour || '#999');
                const sTxt = (this.$root.client.secondary_text_colour || '#fff');
                const tCol = (this.$root.client.third_colour || '#999');
                const tTxt = (this.$root.client.third_text_colour || '#fff');

                let headerBgColour = this.$root.app.header_bg_colour;
                if (headerBgColour == 'primary') {
                    headerBgColour = pCol;
                }
                if (headerBgColour == 'secondary') {
                    headerBgColour = sCol;
                }

                let loginBackground = null;
                if (this.$root.contents.login_background) {
                    loginBackground = this.$root.contents.login_background;
                } else if (this.login_background_loaded) {
                    loginBackground = this.$root.app.login_background;
                }

                let styleTag = '';
                if (loginBackground) {
                    styleTag += '.login-body { background-image: url(' + loginBackground + '); }\n';
                }

                if (this.$root.client.additional_colours) {
                    if (this.$root.client.additional_colours.length > 0) {
                        this.$root.client.additional_colours.forEach((item) => {
                            styleTag += '.bg-colour' + item.id + ' { background-color: ' + item.colour
                                + ' !important; }\n';
                            styleTag += '.text-colour' + item.id + ' { color: ' + item.text_colour + ' !important; }\n';
                            styleTag += '.text-inv-colour' + item.id + ' { color: ' + item.colour + ' !important; }\n';
                        });
                    }
                }

                styleTag += '.card-header { background-color: ' + tCol + '; color: ' + tTxt + '; }\n';
                styleTag += '.avatar-title { background-color: ' + tCol + '; color: ' + tTxt + '; }\n';
                styleTag += '.loading { color: ' + tCol + '; }\n';
                styleTag += '.tickbox { color: ' + tCol + '; }\n';
                styleTag += '.btn-primary { color: ' + tTxt + '; background-color: ' + tCol + '; }\n';
                styleTag += '.btn-primary:hover, .btn-primary:focus, .show > .btn-primary.dropdown-toggle { color: '
                    + tTxt + ' !important; background-color: ' + this.rgba(tCol) + ' !important; }\n';
                styleTag += '.btn-secondary { color: ' + pTxt + ' !important; background-color: ' + pCol + '; }\n';
                styleTag += '.btn-secondary:hover, .btn-secondary:focus { color: ' + pTxt
                    + ' !important; background-color: ' + this.rgba(pCol) + ' !important; }\n';

                styleTag += '.btn-third { color: ' + sTxt + ' !important; background-color: ' + sCol + '; }\n';
                styleTag += '.btn-third:hover, .btn-third:focus { color: ' + sTxt
                    + ' !important; background-color: ' + this.rgba(sCol) + ' !important; }\n';

                styleTag += '.btn-danger { background-color: #ff0000; }\n';
                styleTag += '.btn-danger:hover, .btn-danger:focus { background-color: ' + this.rgba('#ff0000')
                    + ' !important; }\n';
                styleTag += '.nav-link.bg-danger { background-color: #ff0000 !important; border-color: #ff0000'
                    + ' !important; }\n';
                if (pCol.toLowerCase() == '#ffffff') {
                    styleTag += 'a { color: #969aae; }\n';
                    styleTag += 'a:hover { color: ' + this.rgba('#969aae') + '; }\n';
                } else if (pCol == '#000000') {
                    styleTag += 'a { color: #0000ff; }\n';
                    styleTag += 'a:hover { color: ' + this.rgba('#0000ff') + '; }\n';
                } else {
                    styleTag += 'a { color: ' + pCol + '; }\n';
                    styleTag += 'a:hover { color: ' + this.rgba(pCol) + '; }\n';
                }
                styleTag += 'a.primary { color: ' + tCol + '; }\n';
                styleTag += 'a.primary:hover { color: ' + this.rgba(tCol) + '; }\n';
                styleTag += 'a.blue { color: #0999ff; }\n';
                styleTag += 'a.blue:hover { color: ' + this.rgba('#0999ff') + '; }\n';
                styleTag += '.accordion .card .card-header { background-color: ' + sCol + '; color: ' + sTxt + '; }\n';
                styleTag += '.megamenu-list li a.all-folders h5 { color: ' + tCol + '; }\n';
                styleTag += '.megamenu-list li:focus, .megamenu-list li:hover { background-color: '
                    + this.rgba(tCol, 0.75) + '; }\n';
                styleTag += '.megamenu-list li:focus a, .megamenu-list li:hover a, .megamenu-list li:focus a h5'
                    + ', .megamenu-list li:hover a h5 { color: ' + tTxt + '; }\n';
                if (this.$root.client.invert_search_dropdown_folder_colours) {
                    styleTag += '.megamenu-folder { background-color: ' + tCol + '; }\n';
                    styleTag += '.megamenu-folder a, .megamenu-folder a h5 { color: ' + tTxt + '; }\n';
                }
                styleTag += '.main-nav .navbar-nav .dropdown-menu { border-top-color: ' + pCol + '; }\n';
                styleTag += '.vue-dropzone .dz-preview .dz-progress .dz-upload { background-color: ' + sCol + ' }\n';
                if (headerBgColour) {
                    styleTag += '.header-bar { background-color: ' + headerBgColour + '; }\n';
                }
                styleTag += 'body[data-topbar=dark] #page-topbar { background-color: ' + pCol + '; }\n';
                styleTag += 'body[data-topbar=dark] .app-search span, body[data-topbar=dark] .header-item { color: '
                    + pTxt + '; }\n';
                styleTag += 'body[data-topbar=dark] .header-item:hover { color: ' + pTxt + '; }\n';
                styleTag += 'body[data-topbar=dark] .header-item.text:hover { color: ' + pTxt + '; }\n';
                styleTag += '.search-icon:hover { background-color: ' + sCol + '; }\n';
                styleTag += '.search-icon:hover span { background-color: ' + sCol + '; color: ' + sTxt
                    + '; border: 1px solid ' + sCol + '; }\n';
                styleTag += '.bg-primary { background-color: ' + pCol + ' !important; }\n';
                styleTag += 'button.bg-primary:hover, button.bg-primary:focus { background-color: ' + pCol
                    + ' !important; }\n';
                styleTag += '.bg-soft-primary { background-color: ' + this.rgba(pCol, 0.25) + ' !important; }\n';
                styleTag += '.text-primary { color: ' + pTxt + ' !important; }\n';
                styleTag += '.text-inv-primary { color: ' + pCol + ' !important; }\n';
                styleTag += 'button.text-primary:hover, button.text-primary:focus { color: ' + pTxt + ' !important; }\n';
                styleTag += '.bg-secondary { background-color: ' + sCol + ' !important; }\n';
                styleTag += 'button.bg-secondary:hover, button.bg-secondary:focus { background-color: ' + sCol
                    + ' !important; }\n';
                styleTag += '.bg-soft-secondary { background-color: ' + this.rgba(sCol, 0.25) + ' !important; }\n';
                styleTag += '.text-secondary { color: ' + sTxt + ' !important; }\n';
                styleTag += '.text-inv-secondary { color: ' + sCol + ' !important; }\n';
                styleTag += 'button.text-secondary:hover, button.text-secondary:focus { color: ' + sTxt
                    + ' !important; }\n';
                styleTag += '.bg-third { background-color: ' + tCol + ' !important; }\n';
                styleTag += '.text-third { color: ' + tTxt + ' !important; }\n';
                styleTag += '.text-inv-third { color: ' + tCol + ' !important; }\n';
                styleTag += '.text-inv-third-80 { color: ' + this.rgba(tCol) + ' !important; }\n';
                styleTag += '.header-item .avatar-title { color: ' + pTxt + '; }\n';
                styleTag += '.badge-primary { color: ' + tTxt + '; background-color: ' + this.rgba(tCol) + '; }\n';
                styleTag += '.badge-secondary { color: ' + pTxt + '; background-color: ' + this.rgba(pCol) + '; }\n';

                styleTag += '.asset-preview .card-body .asset-menu .asset-tool { color: ' + sTxt + '; }\n';
                styleTag += '.asset-preview .card-body .asset-menu .asset-tool a { color: ' + sTxt + '; }\n';
                styleTag += '.asset-preview .card-body .asset-menu.full { background-color: ' + sCol + '; }\n';
                styleTag += '.asset-preview .card-body .asset-menu.full .asset-tools .asset-tools-mobile-toggle a { '
                    + 'color: ' + sTxt + '; }\n';

                styleTag += '.asset-preview.selectable:hover, .asset-preview.selected:hover, .asset-preview.highlighted '
                    + '{ border-color: ' + sCol + '; }\n';
                styleTag += '.asset-preview .asset-select-overlay .triangle  { background-color: ' + sCol + '; }\n';
                styleTag += '.asset-preview .asset-select-overlay .tick  { color: ' + sTxt + '; }\n';
                styleTag += '.asset-preview .related-assets-overlay .triangle  { background-color: ' + sCol + '; }\n';
                styleTag += '.asset-preview .related-assets-overlay .tick  { color: ' + sTxt + '; }\n';

                styleTag += '.asset-preview.in-collection .card-body .asset-menu .asset-tool { color: ' + tTxt + '; }\n';
                styleTag += '.asset-preview.in-collection .card-body .asset-menu .asset-tool a { color: '
                    + tTxt + '; }\n';
                styleTag += '.asset-preview.in-collection .card-body .asset-menu.full { background-color: '
                    + tCol + '; }\n';
                styleTag += '.asset-preview.in-collection .card-body .asset-menu.full .asset-tools '
                    + '.asset-tools-mobile-toggle a { color: ' + tTxt + '; }\n';

                styleTag += '.asset-preview.in-collection.selectable:hover, .asset-preview.in-collection.selected:hover,'
                    + ' .asset-preview.in-collection.highlighted { border-color: ' + tCol + '; }\n';
                styleTag += '.asset-preview.in-collection .asset-select-overlay .triangle { background-color: '
                    + tCol + '; }\n';
                styleTag += '.asset-preview.in-collection .asset-select-overlay .tick { color: '
                    + tTxt + '; }\n';
                styleTag += '.asset-preview.in-collection .related-assets-overlay .triangle { background-color: '
                    + tCol + '; }\n';
                styleTag += '.asset-preview.in-collection .related-assets-overlay .tick { color: '
                    + tTxt + '; }\n';

                if (tCol.toLowerCase() == '#ffffff') {
                    styleTag += '.prev-next  { color: #0000ff; }\n';
                } else {
                    styleTag += '.prev-next  { color: ' + tCol + '; }\n';
                }
                styleTag += '.nav-pills .nav-item .nav-link.active { background-color: ' + tCol + '; color: ' + tTxt
                    + '; }\n';
                styleTag += '.nav-pills.cl .nav-item .nav-link { background-color: white; }\n';
                styleTag += '.nav-pills.cl .nav-item .nav-link:hover { color: ' + sCol + '; }\n';
                styleTag += '.nav-pills.cl .nav-item .nav-link.active { background-color: ' + sCol + '; color: ' + sTxt
                    + '; }\n';
                styleTag += '.nav-pills.cl .nav-item .nav-link.active:hover { color: ' + sTxt + '; }\n';
                styleTag += '.nav-pills.ad .nav-item .nav-link.active, .nav-pills.ad .nav-item .nav-link.active:hover { '
                    + 'background-color: ' + sCol + ' ; color: ' + sTxt + '; border-color: ' + sCol + '; }\n';
                styleTag += '.custom-control-input:checked ~ .custom-control-label::before { color: ' + tTxt
                    + '; border-color: ' + tCol + '; background-color: ' + tCol + '; }\n';
                styleTag += '.dz-uploaded-file:hover, .dz-uploaded-file.checked { ' + 'border-color: ' + tCol + '; }\n';
                styleTag += '.dz-uploaded-file.active { ' + 'border-color: ' + tCol + ' !important; }\n';
                /*styleTag += '.dz-uploaded-file.active:after { border-top-color: ' + tCol 
                    + ' !important; border-right-color: ' + tCol + ' !important; }\n';*/
                styleTag += '.dz-uploaded-file .dz-progress .dz-upload { background-color: ' + tCol + '; }\n';
                styleTag += '.asset-overall-upload { background-color: ' + tCol + '; }\n';
                styleTag += '.tui-image-editor { border: 1px solid ' + sCol + '; }\n';
                styleTag += '.keywords-separator { background-color: ' + tCol + '; }\n';
                styleTag += '.btn-link { color: ' + this.rgba(tCol) + '; }\n';

                styleTag += '.asset-selection { background-color: ' + sCol + '; }\n';
                styleTag += '.asset-selection .tools { color: ' + sTxt + '; }\n';
                styleTag += '.asset-selection .btn-link { color: ' + sTxt + '; }\n';
                styleTag += '.asset-selection .btn-link:hover { color: ' + sTxt + '; }\n';
                styleTag += '.asset-selection .files-count, .asset-selection .collection-items-count { '
                    + 'background-color: ' + sTxt + '; color: ' + sCol + '; }\n';

                styleTag += '.asset-selection.collection { background-color: ' + tCol + '; }\n';
                styleTag += '.asset-selection.collection .tools { color: ' + tTxt + '; }\n';
                styleTag += '.asset-selection.collection .btn-link { color: ' + tTxt + '; }\n';
                styleTag += '.asset-selection.collection .btn-link:hover { color: ' + tTxt + '; }\n';
                styleTag += '.asset-selection.collection .files-count { background-color: ' + tTxt + '; color: '
                    + tCol + '; }\n';
                styleTag += '.asset-selection.collection .collection-items-count { background-color: ' + tTxt
                    + '; color: ' + tCol + '; }\n';

                styleTag += '.topnav .navbar-nav .nav-link:focus, .topnav .navbar-nav .dropdown-item:hover, '
                    + '.topnav .navbar-nav .nav-link:hover, .btn-link:hover { color: ' + tCol + '; }\n';
                styleTag += '.status-bar.active { background-color: ' + sCol + '; color: ' + sTxt + '; }\n';
                styleTag += '.status-bar.active .btn-light { background-color: ' + sCol + '; color: ' + sTxt + '; }\n';
                styleTag += '.status-bar.active .btn-light:hover, .status-bar.active .btn-light:focus { color: '
                    + sTxt + '; }\n';
                styleTag += '#collection-sub-nav-active .fa-toggle-on { color: ' + tCol + ' }\n';
                styleTag += '.contact-links a.collection-selected { color: ' + tCol + '; }\n';
                styleTag += '.contact-links a.collection-selected:hover { color: ' + this.rgba(tCol)
                    + ' !important; }\n';
                styleTag += '.dz-thumbnail.bulk-files { background-color: ' + tCol + '; color: ' + this.rgba(tTxt)
                    + ' }\n';
                styleTag += '.si-item.selected { background-color: ' + sCol + ' !important; color: ' + sTxt + '; }\n';
                styleTag += '.si-item.selected .contact-links a { color: ' + sTxt + '; }\n';
                styleTag += '.si-item.selected .contact-links a:hover { color: ' + this.rgba(sTxt) + ' !important; }\n';

                /*styleTag += '.asset-preview.select-item:hover, .asset-preview.select-item.selected { border-color: '
                    + sCol + ' !important; }\n';
                styleTag += '.asset-preview.select-item.selected .contact-links a { color: ' + sCol + '; }\n';
                styleTag += '.asset-preview.select-item.selected .contact-links a:hover { color: ' + this.rgba(sCol)
                    + ' !important; }\n';*/
                styleTag += '.daterangepicker td.in-range:not(.off) { background-color: ' + tCol + '; color: ' + tTxt
                    + '; border-radius: 0; }\n';
                styleTag += '.daterangepicker td.off.start-date, .daterangepicker td.off.end-date {'
                    + ' background-color: #fff; border-color: transparent; color: #fff; }\n';
                styleTag += '.daterangepicker td.off { color: #fff !important; }\n';

                styleTag += '@media (min-width: 992px) {\n';
                styleTag += '.asset-preview:hover .card-body .asset-menu { background-color: ' + sCol + '; }\n';
                styleTag += '.asset-preview:hover .card-body .asset-menu .asset-selected { color: ' + sTxt + '; }\n';
                styleTag += '.asset-preview.in-collection:hover .card-body .asset-menu { background-color: ' + tCol
                    + '; }\n';
                styleTag += '.asset-preview.in-collection:hover .card-body .asset-menu .asset-selected { color: '
                    + tTxt + '; }\n';
                styleTag += '}\n';

                if ($('style[rel=client-styles]').html()) {
                    $('style[rel=client-styles]').html(styleTag);

                    return null;
                }
                $('html > head').append('<style type="text/css" rel="client-styles">\n' + styleTag + '</style>');
            },
            rgba: function (hex, opacity = 0.8) {
                if (hex.length == 4) {
                    hex = '#' + (hex.substr(1, 1) + hex.substr(1, 1) + hex.substr(2, 1) + hex.substr(2, 1)
                        + hex.substr(3, 1) + hex.substr(3, 1));
                }

                return 'rgba(' + parseInt(hex.substr(1, 2), 16) + ', ' + parseInt(hex.substr(3, 2), 16) + ', '
                    + parseInt(hex.substr(5, 2), 16) + ', ' + opacity + ')';
            },
            mainContentClass: function () {
                if (this.isExternalPage()) {
                    return '';
                }

                return 'main-content';
            },
            pageContentClass: function () {
                if (this.isExternalPage()) {
                    return '';
                }

                return 'page-content' + (this.$root.editing_artwork ? ' no-padding-bottom' : '');
            },
            containerFluidClass: function () {
                if (this.isExternalPage()) {
                    return '';
                }

                return 'container-fluid';
            },
            resetModalBackdrop: function () {
                $('.modal-backdrop').css({ 'z-index': '1040', 'opacity': '0.5' });
            },
            isLoading: function () {
                if (this.errors) {
                    this.applyClientStyles();

                    return false;
                }
                if (
                    this.$root.client.client_name
                    && (this.$root.isLoggedIn() || this.user_loaded)
                    && (this.login_background_loaded || this.content_loading_errors)
                ) {
                    this.applyClientStyles();

                    return false;
                }

                return true;
            },
            showAssetAddForm: function () {
                if (this.$root.isPublicRoute() || !this.$root.hasPermission('assets_upload')) {
                    return '';
                }
                const component = this.$root.findComponent(this, 'asset-add-form');
                component.resetForm();

                this.is_maintenance_notice_visible = $('.maintenance-notice').is(":visible");
                //this.hideMaintenanceNotice();
                //$('.header-bar').slideUp(350);
                $('.page-topnav').hide();
                $('.status-bar').hide();
                var vm = this;
                $('#page-container').fadeOut(350, function () {
                    $('#asset-add-form').fadeIn(350);
                    $('body').addClass('asset-add');
                    $('html, body').animate({ scrollTop: 1 }, 350);
                    vm.hideMaintenanceNotice();
                    $('.header-bar').slideUp(350);
                });
                this.$root.adding_asset = true;
            },
            hideAssetAddForm: function () {
                var vm = this;
                //$('html, body').animate({ scrollTop: 1 }, 350);
                $('#asset-add-form').fadeOut(350, function () {
                    $('#page-container').fadeIn(350);
                    $('body').removeClass('asset-add');
                    $('.header-bar').slideDown(350);
                    $('html, body').animate({ scrollTop: 1 }, 350);
                    if (vm.is_maintenance_notice_visible) {
                        vm.showMaintenanceNotice();
                    }
                });
                $('.status-bar').show();
                $('.page-topnav').show();
                this.$root.adding_asset = false;
            },
            showTemplateAddForm: function () {
                if (this.$root.isPublicRoute() || !this.$root.hasPermission('templates_upload')) {
                    return '';
                }
                const component = this.$root.findComponent(this, 'template-add-form');
                component.resetForm();

                this.is_maintenance_notice_visible = $('.maintenance-notice').is(":visible");
                //this.hideMaintenanceNotice();
                //$('.header-bar').slideUp(350);
                $('.page-topnav').hide();
                $('.status-bar').hide();
                var vm = this;
                $('#page-container').fadeOut(350, function () {
                    $('#template-add-form').fadeIn(350);
                    $('body').addClass('template-add');
                    $('html, body').animate({ scrollTop: 1 }, 350);
                    vm.hideMaintenanceNotice();
                    $('.header-bar').slideUp(350);
                });
                this.$root.adding_template = true;
            },
            hideTemplateAddForm: function () {
                var vm = this;
                //$('html, body').animate({ scrollTop: 0 }, 350);
                $('#template-add-form').fadeOut(350, function () {
                    $('#page-container').fadeIn(350);
                    $('body').removeClass('template-add');
                    $('.header-bar').slideDown(350);
                    $('html, body').animate({ scrollTop: 1 }, 350);
                    if (vm.is_maintenance_notice_visible) {
                        vm.showMaintenanceNotice();
                    }
                });
                $('.status-bar').show();
                $('.page-topnav').show();
                this.$root.adding_template = false;
            },
            showArtworkEditor: function (asset, template, artwork = null, duplicate = false) {
                if (this.$root.isPublicRoute() || !this.$root.hasPermission('templates_use')) {
                    return '';
                }
                this.$root.editing_artwork = {
                    asset: asset,
                    template: template,
                    artwork: artwork,
                    duplicate: duplicate
                };
                const component = this.$root.findComponent(this, 'artwork-editor');
                component.reset();

                this.is_maintenance_notice_visible = $('.maintenance-notice').is(":visible");
                //this.hideMaintenanceNotice();
                //$('.header-bar').slideUp(350);
                $('.page-topnav').hide();
                $('.status-bar').hide();
                var vm = this;
                $('#page-container').fadeOut(350, function () {
                    $('#artwork-editor').fadeIn(350);
                    $('body').addClass('artwork-edit');
                    $('html, body').animate({ scrollTop: 1 }, 350);
                    vm.hideMaintenanceNotice();
                    $('.header-bar').slideUp(350);
                });
            },
            hideArtworkEditor: function () {
                var vm = this;
                //$('html, body').animate({ scrollTop: 0 }, 350);
                $('#artwork-editor').fadeOut(350, function () {
                    $('#page-container').fadeIn(350);
                    $('body').removeClass('artwork-edit');
                    $('.header-bar').slideDown(350);
                    $('html, body').animate({ scrollTop: 1 }, 350);
                    if (vm.is_maintenance_notice_visible) {
                        vm.showMaintenanceNotice();
                    }
                });
                $('.status-bar').show();
                $('.page-topnav').show();
                this.$root.editing_artwork = false;

                if (
                    (this.$route.name == 'my-account-artworks-declined')
                    || (this.$route.name == 'my-account-artwork-declined')
                ) {
                    const component = this.$root.findComponent(this, 'my-account-artwork-approvals');
                    component.getArtworks();
                }
            },
            canAddUsers: function () {
                if (this.$root.hasPermission('users_invite')) {
                    return true;
                }

                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_add_users'));
            },
            isSharedPage: function () {
                return (this.$route.name == 'shared') || (this.$route.name == 'shared-email');
            },
            isProofsPage: function () {
                const pages = ['proofs', 'view-proof', 'view-proof-tab'];

                return (pages.indexOf(this.$route.name) > -1);
            },
            isExternalPage: function () {
                if (this.$root.isPublicRoute()) {
                    return true;
                }

                if (this.$root.isLoggedIn()) {
                    return false;
                }

                if (this.isSharedPage()) {
                    if (this.$root.share_info.page_loaded) {
                        return false;
                    }
                }

                return true;
            },
            hideMaintenanceNotice: function () {
                $('.maintenance-notice').slideUp(350);
            },
            showMaintenanceNotice: function () {
                $('.maintenance-notice').slideDown(350);
            },
            getContents: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/contents';
                var vm = this;

                axios({
                    url: url
                })
                .then(response => {
                    response.data.contents.forEach((item) => {
                        if (item.key != 'login_background') {
                            vm.$root.contents[item.key] = item.content;
                        }
                    });
                    vm.applyClientStyles();
                })
                .catch(function (error) {
                    vm.content_loading_errors = true;
                });
            },
            getLanguages: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/languages';
                var vm = this;

                axios({
                    url: url
                })
                .then(response => {
                    vm.$root.languages = response.data.languages;
                    if (vm.$root.current_language) {
                        let found = false;
                        response.data.languages.forEach((item) => {
                            if (item.active && (item.id == vm.$root.current_language)) {
                                found = true;
                            }
                        });

                        if (!found) {
                            vm.$root.current_language = null;
                            vm.$cookie.delete(vm.$root.app.client_id + '_language');
                        }
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            getTermsContent: function () {
                let content = this.$root.contents.terms_and_conditions;
                if (this.$root.current_language) {
                    if (
                        (this.$root.current_language > 1)
                        && this.$root.contents['terms_and_conditions_' + this.$root.current_language]
                    ) {
                        content = this.$root.contents['terms_and_conditions_' + this.$root.current_language];
                    }
                }

                return content;
            }
        }
    }
</script>
