<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6 v-if="($route.name == 'collections')">
                                <i class="fa-light fa-rectangle-history font-size-16 mr-1"></i>
                                {{ $root.text('My collections', null, true) }}
                            </h6>
                            <h6 v-else class="contact-links">
                                <span>
                                    <i class="fa-light fa-rectangle-history font-size-16 mr-1"></i>
                                    <span v-if="!$root.view_collection_name && !editing_collection">
                                        {{ $root.text('Collection', null, true) }}
                                    </span>
                                    <span
                                        v-if="($root.view_collection_name && !editing_collection)"
                                        v-b-tooltip.hover
                                        :title="$root.text('Rename', null, true)"
                                        @click="toggleEditCollection()"
                                    >
                                        {{ $root.text('Collection: {collection}',
                                            { collection: $root.view_collection_name }, true) }}
                                    </span>
                                </span>
                            </h6>
                            <div v-if="editing_collection" class="rename-collection">
                                <form v-on:submit.prevent="updateCollection()">
                                    <div class="input-group">
                                        <input
                                            v-model="new_collection_name"
                                            type="text"
                                            class="form-control mandatory-field"
                                            id="new-collection-name"
                                            :placeholder="placeholder('new-collection-name')"
                                            v-on:focus="placeholder('new-collection-name', true)"
                                            v-on:blur="placeholder('new-collection-name', true)"
                                            aria-label="Collection name"
                                            :readonly="updating_collection"
                                            v-on:keyup="$root.unHighlightErrors('new-collection-name')"
                                            v-on:change="$root.unHighlightErrors('new-collection-name')"
                                            style="height: 37px"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                v-if="!updating_collection"
                                                class="btn btn-primary big-icon"
                                                type="submit"
                                                v-b-tooltip.hover
                                                :title="$root.text('Save', null, true)"
                                            >
                                                <strong><i class="fal fa-save"></i></strong>
                                            </button>
                                            <button
                                                v-else
                                                type="button"
                                                class="btn btn-primary"
                                            >
                                                <span>
                                                    <i class="fal fa-spinner-third fa-spin big-icon"></i>
                                                </span>
                                            </button>
                                            <button
                                                class="btn btn-light big-icon"
                                                type="button"
                                                v-b-tooltip.hover
                                                :title="$root.text('Cancel', null, true)"
                                                :disabled="updating_collection"
                                                @click="toggleEditCollection()"
                                            >
                                                <i class="fal fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </li>
                    </ul>
                    <ul v-if="($route.name == 'collections')"  class="navbar-nav">
                        <li class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#collection-add-form-modal"
                                    @click.prevent="resetCollectionAddForm()"
                                >
                                    <span class="mr-1" style="position: relative">
                                        <i class="fa-light fa-rectangle-history"></i>
                                        <i
                                            class="far fa-plus"
                                            style="position: absolute; left: 4px; bottom: 5px; font-size: 7px"
                                        ></i>
                                    </span>
                                    {{ $root.text('New collection', null, true) }}
                                </button>
                            </div>
                        </li>
                    </ul>
                    <ul v-if="(($route.name == 'view-collection') && !viewingAsset())" class="navbar-nav">
                        <!--<li v-if="breadcrumbs()" class="nav-item d-none d-md-inline">
                            <breadcrumbs :items="breadcrumbs()"></breadcrumbs>
                        </li>-->
                        <li class="nav-item" style="width: 100px; height: 36.5px">
                            <div class="btn-group" style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {{ $root.text('Actions', null, true) }}
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <div v-if="$root.view_collection_name">
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#collection-add-form-modal"
                                            @click.prevent="resetCollectionAddForm(true)"
                                        >
                                            <i class="far fa-copy mr-1"></i> {{ $root.text('Duplicate', null, true) }}
                                        </a>
                                        <a
                                            v-if="canBulkEdit()"
                                            class="dropdown-item"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#collection-bulk-edit-modal"
                                            @click="resetBulkEditModal()"
                                        >
                                            <i class="far fa-edit mr-1"></i> {{ $root.text('Bulk actions', null, true) }}
                                        </a>
                                        <!--<a
                                            v-if="$root.hasPermission('assets_manage_attachments')"
                                            class="dropdown-item"
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#collection-linked-file-add-form-modal"
                                            @click.prevent="resetLinkedFileAddForm()"
                                        >
                                            <i class="far fa-paperclip mr-1"></i> Attach file
                                        </a>-->
                                        <span v-if="canDeleteCollection()">
                                            <span v-if="!deleting_collection">
                                                <a
                                                    class="dropdown-item"
                                                    href="#"
                                                    @click.prevent="deleteCollection()"
                                                >
                                                    <i class="far fa-trash-alt mr-1"></i>
                                                    {{ $root.text('Delete', null, true) }}
                                                </a>
                                            </span>
                                            <span v-else>
                                                <span style="padding-left: 24px; line-height: 30px">
                                                    <i class="far fa-spinner-third fa-spin mr-1"></i> 
                                                    <span style="position: relative; top: 1px">
                                                        {{ $root.text('Delete', null, true) }}
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <div class="dropdown-divider"></div>
                                    </div>
                                    <router-link
                                        class="dropdown-item"
                                        :to="{ name: 'collections' }"
                                    >
                                        <i class="fa-regular fa-rectangle-history mr-1"></i>
                                        {{ $root.text('View all collections', null, true) }}
                                    </router-link>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['current_route'],
        data () {
            return {
                editing_collection: false,
                new_collection_name: '',
                updating_collection: false,
                deleting_collection: false
            }
        },
        mounted () {
            //
        },
        watch: {
            current_route: function () {
                this.editing_collection = false;
            }
        },
        methods: {
            /*breadcrumbs: function() {
                if (this.$route.name == 'collections') {
                    return null;
                }
                return [
                    {
                        id: 0,
                        text: "Collections",
                        link: 'collections'
                    },
                    {
                        id: 1,
                        text: this.$root.collection_name,
                        active: true
                    }
                ];
            },*/
            resetCollectionAddForm: function (duplicate = false) {
                const component = this.$root.findComponent(this.$parent.$parent, 'collection-add-form');
                if (component) {
                    if (duplicate) {
                        component.resetForm({ id: this.$route.params.id, name: this.$root.view_collection_name });

                        return null;
                    }
                    component.resetForm();
                }
            },
            deleteCollection: function (confirmed = false) {
                if (confirmed) {
                    this.deleting_collection = true;

                    /**
                     * Send delete collection request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/collection/' + this.$route.params.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        if (vm.$root.current_collection == vm.$route.params.id) {
                            vm.$root.current_collection = null;
                            vm.$root.selected_assets = [];
                        }
                        vm.$router.push({ name: 'collections' });
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.$parent.$parent.message = {
                            title: vm.$root.view_collection_name,
                            type: 'error',
                            text: vm.$root.text(
                                'Sorry, there was a problem with deleting this collection. Please try again.',
                                null,
                                true
                            )
                        };
                        $('#message-modal').modal('show');
                    })
                    .finally(() =>
                        vm.deleting_collection = false
                    );

                    return null;
                }

                let text = this.$root.text('Are you sure you want to delete this collection?', null, true);
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'view-collection');
                if (component) {
                    if ((component.asset_ids.length > 0) || (component.linked_file_ids.length > 0)) {
                        text += '<br><br>';
                    }
                    if (component.asset_ids.length > 0) {
                        if (component.asset_ids.length == 1) {
                            let resource = this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title');
                            text += this.$root.text(
                                'This will not delete the {resource} from the system.',
                                { resource: resource },
                                true
                            ) + ' ';
                        } else {
                            let resources = this.$root.text(
                                this.$root.contents.asset_title + 's',
                                null,
                                false,
                                'assets_title'
                            );
                            text += this.$root.text(
                                'This will not delete the {resources} from the system.',
                                { resources: resources },
                                true
                            ) + ' ';
                        }
                    }
                    if (component.linked_file_ids.length > 0) {
                        const attachments = (component.linked_file_ids.length > 1) ? 'attachments' : 'attachment';
                        text += this.$root.text('The ' + attachments + ' will be permanently deleted.', null, true);
                    }
                }

                this.$parent.$parent.confirm_delete = {
                    title: this.$root.view_collection_name,
                    text: text,
                    component: this,
                    action: 'delete-collection'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteCollection(true);
            },
            toggleEditCollection: function () {
                if (this.editing_collection) {
                    this.editing_collection = false;

                    return null;
                }
                if (this.$root.view_collection_name != '...') {
                    this.new_collection_name = this.$root.view_collection_name;
                    this.editing_collection = true;
                    setTimeout(function () {
                        $('#new-collection-name').focus();
                    }, 200);
                }
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'new-collection-name':
                        text = this.$root.text('Collection name', null, true);
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            updateCollection: function () {
                $('.mandatory-field').removeClass('field-error');
                this.updating_collection = true;
                /**
                 * Send update collection request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.$route.params.id;
                const data = {
                    name: this.new_collection_name
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.$root.view_collection_name = vm.new_collection_name;
                    if (vm.$root.current_collection == vm.$route.params.id) {
                        vm.$root.current_collection_name = vm.new_collection_name;
                    }
                    vm.toggleEditCollection();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        let errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                errors += value.join('<br>') + '<br>';
                                $('#new-collection-' + key).addClass('field-error');
                            }
                        }
                        vm.$parent.$parent.message = {
                            title: vm.$root.text('Rename collection', null, true),
                            type: 'error',
                            text: errors,
                        };
                        $('#message-modal').modal('show');
                    }
                })
                .finally(() => {
                    vm.updating_collection = false;
                });
            },
            collectionSelectedCount: function () {
                if (this.$root.collection_selected_assets.length == 0) {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    const component = this.$root.findComponent(appComponent, 'view-collection');
                    if (!component) {
                        return null;
                    }
                    return component.asset_ids.length;
                }
                let count = 0;
                this.$root.collection_selected_assets.forEach((item) => {
                    if (item > 0) {
                        count++;
                    }
                });

                return count;
            },
            canBulkEdit: function () {
                if (!this.collectionSelectedCount()) {
                    return false;
                }

                return true;
            },
            resetBulkEditModal: function () {
                const viewCollectionComponent = this.$root.findComponent(this.$parent.$parent, 'view-collection');
                if (viewCollectionComponent) {
                    const component = this.$root.findComponent(viewCollectionComponent, 'collection-bulk-edit');
                    if (component) {
                        component.resetModal();
                    }
                }
            },
            canDeleteCollection: function () {
                if (this.$route.name != 'view-collection') {
                    return false;
                }

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'view-collection');
                if (component) {
                    if (!component.collection) {
                        return false;
                    }

                    return (component.collection.user_id == this.$root.user.id);
                }

                return false;
            },
            viewingAsset: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'view-collection');
                if (component) {
                    return component.view_asset;
                }

                return false;
            }
        }
    }
</script>
<style scoped>
    .rename-collection {
        position: absolute;
        top: 8px;
        left: 25px;
        width: 280px;
        background-color: #fff;
        z-index: 1;
    }
</style>
