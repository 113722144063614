<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6 v-if="($route.name == 'profile-groups')" :key="'profile-groups'">
                                <i class="fa-light fa-building font-size-16 mr-1"></i>
                                {{ $root.text($root.contents.profile_groups_title, null, true, 'profile_groups_title') }}
                            </h6>
                            <h6 v-else :key="'profile-group'">
                                <!--<i class="fa-light fa-building font-size-16 mr-1"></i>-->
                                {{ $root.text($root.contents.profile_group_title, null, true, 'profile_group_title') }}
                                <!--:
                                {{ $root.view_profile_group_name }}-->
                            </h6>
                        </li>
                    </ul>
                    <ul v-if="hasActions()" class="navbar-nav">
                        <li v-if="(hasActions() == 1)" class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    :key="'add-profile-group-button'"
                                    v-if="($route.name == 'profile-groups')"
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#profile-group-add-form-modal"
                                    @click.prevent="resetProfileGroupAddForm()"
                                >
                                    <i class="far fa-building mr-1"></i>
                                    {{ $root.text(
                                        'Add {profile group}',
                                        {
                                            'profile group': $root.text(
                                                $root.contents.profile_group_title,
                                                null,
                                                false,
                                                'profile_group_title'
                                            )
                                        },
                                        true
                                    ) }}
                                </button>
                                <span v-else>
                                    <button
                                        v-if="isProfileGroupsAdmin()"
                                        class="btn btn-primary"
                                        @click="viewProfileGroups()"
                                    >
                                        <i class="far fa-bars mr-1"></i>
                                        {{ $root.text(
                                            'View all {profile groups}',
                                            {
                                                'profile groups': $root.text(
                                                    $root.contents.profile_groups_title,
                                                    null,
                                                    false,
                                                    'profile_groups_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </button>
                                    <button
                                        v-if="$root.hasPermission('profiles_groups_edit')
                                            || ($root.is_own_profile_group
                                            && $root.hasPermission('profiles_own_group_edit'))"
                                        class="btn btn-primary"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#profile-group-edit-form-modal"
                                        @click="resetProfileGroupEditForm()"
                                    >
                                        <i class="far fa-edit mr-1"></i>
                                        {{ $root.text(
                                            'Edit {profile group}',
                                            {
                                                'profile group': $root.text(
                                                    $root.contents.profile_group_title,
                                                    null,
                                                    false,
                                                    'profile_group_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </button>
                                    <button
                                        v-if="$root.hasPermission('profiles_groups_delete')"
                                        class="btn btn-primary"
                                        type="button"
                                        data-toggle="modal"
                                        data-target="#profile-group-edit-form-modal"
                                        @click="deleteProfileGroup()"
                                    >
                                        <i class="far fa-trash-alt mr-1"></i>
                                        {{ $root.text(
                                            'Delete {profile group}',
                                            {
                                                'profile group': $root.text(
                                                    $root.contents.profile_group_title,
                                                    null,
                                                    false,
                                                    'profile_group_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </button>
                                    <router-link
                                        v-if="showMyAccountButton()"
                                        :key="'my-account'"
                                        :to="{ name: 'my-account' }"
                                        custom
                                        v-slot="{ navigate }"
                                    >
                                        <button class="btn btn-primary" @click="navigate">
                                            <i class="far fa-user mr-1"></i> {{ $root.text('My account', null, true) }}
                                        </button>
                                    </router-link>
                                </span>
                            </div>
                        </li>
                        <li v-if="(hasActions() > 1)" class="nav-item" style="width: 100px; height: 36.5px">
                            <div class="btn-group" style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    :key="'actions-button'"
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    :disabled="!$root.view_profile_group_name"
                                >
                                    {{ $root.text('Actions', null, true) }}
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a
                                        v-if="$root.hasPermission('profiles_groups_edit')
                                            || ($root.is_own_profile_group
                                            && $root.hasPermission('profiles_own_group_edit'))"
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#profile-group-edit-form-modal"
                                        @click.prevent="resetProfileGroupEditForm()"
                                    >
                                        <i class="far fa-edit mr-1"></i>
                                        {{ $root.text(
                                            'Edit {profile group}',
                                            {
                                                'profile group': $root.text(
                                                    $root.contents.profile_group_title,
                                                    null,
                                                    false,
                                                    'profile_group_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </a>
                                    <a
                                        v-if="$root.hasPermission('profiles_groups_delete')"
                                        class="dropdown-item"
                                        href="#"
                                        @click.prevent="deleteProfileGroup()"
                                    >
                                        <i class="far fa-trash-alt mr-1"></i>
                                        {{ $root.text(
                                            'Delete {profile group}',
                                            {
                                                'profile group': $root.text(
                                                    $root.contents.profile_group_title,
                                                    null,
                                                    false,
                                                    'profile_group_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a
                                        v-if="isProfileGroupsAdmin()"
                                        class="dropdown-item"
                                        href="#"
                                        @click.prevent="viewProfileGroups()"
                                    >
                                        <i class="far fa-bars mr-1" style="position: relative; top: 1px"></i>
                                        {{ $root.text(
                                            'View all {profile groups}',
                                            {
                                                'profile groups': $root.text(
                                                    $root.contents.profile_groups_title,
                                                    null,
                                                    false,
                                                    'profile_groups_title'
                                                )
                                            },
                                            true
                                        ) }}
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            hasActions: function () {
                let actions = 0;
                if ((this.$route.name == 'profile-groups') && this.$root.hasPermission('profiles_groups_add')) {
                    actions++;
                }
                if (this.$route.name == 'view-profile-group') {
                    if (this.isProfileGroupsAdmin()) {
                        actions++;
                    }
                    if (
                        this.$root.hasPermission('profiles_groups_edit')
                        || (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_edit'))
                    ) {
                        actions++;
                    }
                    if (this.$root.hasPermission('profiles_groups_delete')) {
                        actions++;
                    }
                    if (!actions && this.$root.is_own_profile_group) {
                        actions++;
                    }
                }

                return actions;
            },
            isProfileGroupsAdmin: function () {
                if (this.$root.hasPermission('profiles_groups_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_delete')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_delete')) {
                    return true;
                }

                return false;
            },
            showMyAccountButton: function () {
                if (this.$route.name != 'view-profile-group') {
                    return false;
                }
                if (!this.$root.is_own_profile_group) {
                    return false;
                }
                if (this.isProfileGroupsAdmin()) {
                    return false;
                }
                if (this.$root.hasPermission('profiles_own_group_edit')) {
                    return false;
                }

                return true;
            },
            resetProfileGroupAddForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'profile-group-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            resetProfileGroupEditForm: function () {
                const viewProfileGroupComponent = this.$root.findComponent(this.$parent.$parent, 'view-profile-group');
                if (viewProfileGroupComponent) {
                    const component = this.$root.findComponent(viewProfileGroupComponent, 'profile-group-edit-form');
                    if (component) {
                        component.resetForm();
                    }
                }
            },
            deleteProfileGroup: function (confirmed = false) {
                if (confirmed) {
                    var viewProfileGroupComponent =
                        this.$root.findComponent(this.$parent.$parent, 'view-profile-group');
                    if (viewProfileGroupComponent) {
                        viewProfileGroupComponent.errors = false;
                        viewProfileGroupComponent.loading = true;
                    }

                    /**
                     * Send delete profile group request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-group/'
                        + this.$route.params.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.$router.push({ name: 'profile-groups' });
                        vm.$root.profiles_changed = true;
                    })
                    .catch(function (error) {
                        if (viewProfileGroupComponent) {
                            viewProfileGroupComponent.loading = false;
                        }
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.$parent.$parent.message = {
                                type: 'error',
                                title: vm.$root.text(
                                    'Delete {profile group}',
                                    {
                                        'profile group': vm.$root.text(
                                            vm.$root.contents.profile_group_title,
                                            null,
                                            false,
                                            'profile_group_title'
                                        )
                                    },
                                    true
                                ),
                                text: error.response.data.error
                            };
                            $('#message-modal').modal('show');
                        }
                    });

                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: this.$root.text(
                        'Delete {profile group}',
                        {
                            'profile group': this.$root.text(
                                this.$root.contents.profile_group_title,
                                null,
                                false,
                                'profile_group_title'
                            )
                        },
                        true
                    ),
                    text: this.$root.text(
                        'Are you sure you want to delete: {title}?',
                        { title: this.$root.view_profile_group_name },
                        true
                    ),
                    component: this,
                    action: 'delete-profile-group'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteProfileGroup(true);
            },
            viewProfileGroups: function () {
                this.$root.profile_group_filters = [];
                this.$router.push({ name: 'profile-groups' });
            }
        }
    }
</script>
