<template>
    <div class="row">
        <div class="col-sm-1 col-3 text-center" style="min-width: 80px">
            <div v-if="image_src" style="position: relative; height: 3.5rem">
                <img
                    :src="image_src"
                    alt="image"
                    style="max-width: 100%; max-height: 3.5rem; position: absolute; left: 0; right: 0; margin: auto"
                >
            </div>
            <div v-if="avatar_src" class="avatar-xs" style="display: inline-block">
                <div class="rounded-circle avatar-xs" style="position: relative; overflow: hidden">
                    <img
                        :src="avatar_src"
                        alt="image"
                        :style="'max-width: 2rem; max-height: 2rem; position: absolute; top: 0; bottom: 0;'
                            + ' left: 0; right: 0; margin: auto'"
                    >
                </div>
            </div>
            <div v-if="(!image_src && !avatar_src)" class="avatar-xs" style="display: inline-block">
                <span :class="'avatar-title rounded-circle' + (isDeclined() ? ' red' : '')" :key="iconClass()">
                    <i :class="iconClass()"></i>
                </span>
            </div>
        </div>
        <div class="col d-none d-sm-block">
            <h5 class="font-size-14 mb-1" :style="(item.read ? 'font-weight: 400' : '')">
                <a
                    href="#"
                    class="text-dark"
                    @click.prevent="openLink(item)"
                    v-b-tooltip.hover
                    :title="$root.text('Show', null, true)"
                >
                    {{ item.title }}
                </a>
            </h5>
            <p class="text-muted mb-0">{{ item.description }}</p>
        </div>
        <div class="col-2 d-none d-sm-block" style="padding-top: 3px">
            <i class="far fa-clock"></i> {{ time_ago }}
        </div>
        <div class="col d-sm-none">
            <h5 class="font-size-14 mb-1" :style="(item.read ? 'font-weight: 400' : '')">
                <a
                    href="#"
                    class="text-dark"
                    @click.prevent="openLink(item)"
                    v-b-tooltip.hover
                    :title="$root.text('Show', null, true)"
                >
                    {{ item.title }}
                </a>
            </h5>
            <p class="text-muted mb-1">{{ item.description }}</p>
            <p class="mb-1"><i class="far fa-clock"></i> {{ time_ago }}</p>
        </div>
        <div class="col-sm-2 col-3 font-size-18 contact-links">
            <a
                v-if="(item.type != 'admin')"
                href="#"
                class="float-right ml-2"
                v-b-tooltip.hover
                :title="$root.text('Remove', null, true)"
                @click.prevent="$parent.clearNotification(item)"
            >
                <i class="far fa-times fa-fw"></i>
            </a>
            <a
                href="#"
                class="float-right"
                v-b-tooltip.hover
                :title="$root.text('Show', null, true)"
                @click.prevent="openLink(item)"
            >
                <i class="far fa-arrow-circle-right fa-fw"></i>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['item'],
        data () {
            return {
                time_ago: '',
                get_date_time_formatted_response: null,
                time_ago_interval: null,
                image_src: null,
                avatar_src: null,
                is_flyte: false,
                is_filters: false
            }
        },
        mounted () {
            this.updateTimeAgo();
            var vm = this;
            vm.time_ago_interval = setInterval(function () {
                vm.updateTimeAgo();
            }, 59999);

            // Get preview image if asset shared
            if (this.item.action == 'share') {
                this.getShare();
            }
            if (this.item.action == 'resource_access_request') {
                this.getAssetApproval();
            }
            if ((this.item.action == 'resource_approved') || (this.item.action == 'resource_declined')) {
                const linkParts = this.item.link.split('::');
                this.getAssetPreview(linkParts[1]);
            }
            if (this.item.action == 'deletion_request') {
                this.getUserDetails();
            }
            if (
                (this.item.action == 'proof_review')
                || (this.item.action == 'proof_return')
                || (this.item.action == 'proof_target_date_alert')
            ) {
                this.getProofPreview();
            }
        },
        beforeDestroy() {
            clearInterval(this.time_ago_interval);
        },
        watch: {
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'time_ago_' + this.item.id) {
                            this.time_ago = val.date_time_formatted;
                        }
                    }
                }
            }
        },
        methods: {
            isDeclined: function () {
                return (this.item.action.indexOf('_declined') > 0);
            },
            iconClass: function () {
                if (this.item.action == 'collaborate') {
                    return 'fa-regular fa-rectangle-history';
                }
                if (
                    (this.item.action == 'proof_review')
                    || (this.item.action == 'proof_return')
                    || (this.item.action == 'proof_target_date_alert')
                ) {
                    return 'fa-regular fa-memo-circle-check';
                }
                if ((this.item.action == 'collaborate_comment') || (this.item.action == 'proof_comment')) {
                    return 'far fa-comment-lines';
                }
                if (this.item.action == 'share') {
                    if (this.is_filters) {
                        return 'far fa-link'; 
                    }

                    return (this.is_flyte ? 'far fa-paper-plane' : 'far fa-share-alt');
                }
                if (this.item.action == 'registration') {
                    return 'far fa-user-plus';
                }
                if (this.item.action == 'deletion_request') {
                    return 'far fa-user-slash';
                }
                if (
                    (this.item.action == 'resource_access_request')
                    || (this.item.action == 'resource_approved')
                    || (this.item.action == 'resource_declined')
                ) {
                    return 'far fa-file';
                }
                if (this.item.action == 'review_date_alert') {
                    return 'far fa-file-exclamation';
                }
                if (
                    (this.item.action == 'print_order')
                    || (this.item.action == 'print_order_approved')
                    || (this.item.action == 'print_order_declined')
                ) {
                    return 'far fa-shopping-cart';
                }
                if (
                    (this.item.action == 'artwork_approval_request')
                    || (this.item.action == 'artwork_approved')
                    || (this.item.action == 'artwork_declined')
                ) {
                    return 'far fa-pen-paintbrush';
                }

                return 'far fa-bell';
            },
            updateTimeAgo: function () {
                this.$root.getDateTimeFormatted(this, 'time_ago_' + this.item.id, this.item.created_at, 'time_ago');
            },
            openLink: function (item) {
                let route = null;
                let linkParts = null;
                switch (item.action) {
                    case 'proof_review':
                    case 'proof_return':
                    case 'proof_target_date_alert':
                        route = {
                            name: 'view-proof',
                            params: {
                                id: item.item_id
                            }
                        };
                        break;
                    case 'proof_comment':
                        route = {
                            name: 'view-proof-tab',
                            params: {
                                id: item.item_id,
                                tab: 'comments'
                            }
                        };
                        break;
                    case 'collaborate':
                    case 'collaborate_comment':
                        linkParts = item.link.split('/');
                        route = {
                            name: 'view-collection',
                            params: {
                                id: linkParts[linkParts.length - 1]
                            }
                        };
                        break;
                    case 'share':
                        linkParts = item.link.split('/');
                        route = {
                            name: 'shared-email',
                            params: {
                                code: linkParts[linkParts.length - 2],
                                email: linkParts[linkParts.length - 1]
                            }
                        };
                        break;
                    case 'registration':
                        route = {
                            name: 'new-users',
                        };
                        break;
                    case 'deletion_request':
                        route = {
                            name: 'deletion-requests',
                        };
                        break;
                    case 'resource_access_request':
                        route = {
                            name: 'asset-approvals',
                        };
                        break;
                    case 'resource_approved':
                    case 'resource_declined':
                        linkParts = item.link.split('::');
                        const assetId = parseInt(linkParts[1]);
                        this.$root.asset_filters = [];
                        this.$root.addFilter('asset_filters', {
                            key: 'resource_id',
                            value: parseInt(assetId),
                            text: '&quot;' + assetId + '&quot;',
                            filters_key: 'asset_filters'
                        });
                        if (
                            (item.action == 'resource_approved')
                            && (this.$root.assets_from_approval_email.indexOf(assetId) < 0)
                        ) {
                            this.$root.assets_from_approval_email.push(assetId);
                        }
                        route = {
                            name: 'assets',
                        };
                        break;
                    case 'review_date_alert':
                        route = {
                            name: 'asset-reviews',
                        };
                        break;
                    case 'print_order':
                        if (item.type == 'admin') {
                            route = {
                                name: 'print-orders-awaiting-approval',
                            };
                        } else {
                            linkParts = item.link.split('::');
                            route = {
                                name: 'my-account-print-orders-filtered',
                                params: {
                                    filters: 'id::' + linkParts[1]
                                }
                            };
                        }
                        break;
                    case 'print_order_approved':
                    case 'print_order_declined':
                        this.$root.print_order_filters = [];
                        route = {
                            name: 'my-account-print-orders',
                        };
                        break;
                    case 'artwork_approval_request':
                        route = {
                            name: 'artwork-approvals',
                        };
                        break;
                    case 'artwork_approved':
                        linkParts = item.link.split('artwork-approved/');
                        if (linkParts.length == 2) {
                            route = {
                                name: 'my-account-artwork-approved',
                                params: {
                                    id: linkParts[1]
                                }
                            };
                        } else {
                            route = {
                                name: 'my-account-artworks-approved',
                            };
                        }
                        break;
                    case 'artwork_declined':
                        linkParts = item.link.split('artwork-declined/');
                        if (linkParts.length == 2) {
                            route = {
                                name: 'my-account-artwork-declined',
                                params: {
                                    id: linkParts[1]
                                }
                            };
                        } else {
                            route = {
                                name: 'my-account-artworks-declined',
                            };
                        }
                        break;
                }
                if (!item.read && (item.type != 'admin')) {
                    this.$parent.markNotificationAsRead(item);
                }
                if (route) {
                    this.$router.push(route);
                }
            },
            getShare: function () {
                const linkParts = this.item.link.split('/');
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/share-auth/'
                    + linkParts[linkParts.length - 2] + '/' + linkParts[linkParts.length - 1];
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.share.asset_id) {
                        vm.getAssetPreview(response.data.share.asset_id);
                    }
                    if (response.data.share.flyte_files.length > 0) {
                        vm.is_flyte = true;
                    }
                    if (response.data.share.filters.length > 0) {
                        vm.is_filters = true;
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            getAssetApproval: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource-access-request/'
                    + this.item.item_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.getAssetPreview(response.data.request.asset_id);
                })
                .catch(function (error) {
                    //
                });
            },
            getAssetPreview: function (assetId) {
                /**
                 * Send request to API.
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + assetId
                    + '/preview/lowres';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            getUserDetails: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + this.item.item_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.user.profile_image) {
                        vm.avatar_src = response.data.user.profile_image;
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            getProofPreview: function () {
                /**
                 * Send request to API.
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.item.item_id
                    + '/preview';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            }
        }
    }
</script>
<style scoped>
    .red {
        background-color: #ff0000;
    }
</style>
