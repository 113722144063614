<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="price-panel-view-assets-modal-label">
                {{ $root.text('{resources} linked to price panel: {panel_title}',
                    { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title'),
                    panel_title: panel_title }, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <loading v-if="loading"></loading>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
                <div v-else>
                    <div v-if="assets.length == 0" class="alert alert-warning" role="alert">
                        {{ $root.text('No {resources} found', { resources: $root.text($root.contents.asset_title + 's',
                            null, false, 'assets_title') }, true) }}
                    </div>
                    <div v-else class="price-panel-assets">
                        <div class="table-responsive">
                            <table class="table table-centered table-hover">
                                <thead class="thead-light">
                                    <tr>
                                        <th scope="col">
                                            <div class="row">
                                                <div class="col-2">{{ $root.text('No.', null, true) }}</div>
                                                <div class="col-8">{{ $root.text('Title', null, true) }}</div>
                                                <div class="col-2"></div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in assets" :key="item.id">
                                        <td scope="row">
                                            <div class="row">
                                                <div class="col-2" style="padding-top: 3px">
                                                    {{ item.id }}
                                                </div>
                                                <div class="col-8" style="padding-top: 3px">
                                                    {{ item.title }}
                                                </div>
                                                <div class="col-2 font-size-18 contact-links">
                                                    <a
                                                        href="#"
                                                        class="float-right"
                                                        @click.prevent="viewAsset(item.id)"
                                                        v-b-tooltip
                                                        :title="$root.text(
                                                            'View {resource}',
                                                            {
                                                                resource: $root.text(
                                                                    $root.contents.asset_title,
                                                                    null,
                                                                    false,
                                                                    'asset_title'
                                                                )
                                                            },
                                                            true
                                                        )"
                                                    >
                                                        <i class="fa-regular fa-eye"></i>
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div
                    v-if="assets_loading"
                    style="position: absolute; left: 0; bottom: 7px; width: 100%"
                >
                    <loading></loading>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                loading: true,
                panel_id: null,
                panel_title: '',
                assets: [],
                assets_loading: false,
                assets_current_page: 1,
                assets_last_page: 1
            }
        },
        mounted () {
            //
        },
        methods: {
            reset: function (pricePanel) {
                this.errors = false;
                this.loading = true;
                this.panel_id = pricePanel.id;
                this.panel_title = pricePanel.title;
                this.assets = [];
                this.getAssets();
            },
            getAssets: function (page = 1) {
                if (
                    this.assets_loading
                    || (page < 1)
                    || ((page > 1) && (page <= this.assets_current_page))
                ) {
                    return null;
                }
                this.assets_current_page = page;
                this.assets_loading = true;
                if (page == 1) {
                    this.assets = [];
                    this.assets_last_page = 1;
                }
                let filters = ['!archived|pp::' + this.panel_id];

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/'
                    + filters.join('|') + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_current_page = response.data.resources.current_page;
                    vm.assets_last_page = response.data.resources.last_page;
                    response.data.resources.data.forEach((item) => {
                        // Duplicate display protection - check if already added.
                        let itemIndex = -1;
                        vm.assets.forEach((existingItem, existingItemIndex) => {
                            if (item.id == existingItem.id) {
                                itemIndex = existingItemIndex;
                            }
                        });
                        if (itemIndex < 0) {
                            vm.assets.push(item);
                        }
                    });
                    if ((response.data.resources.current_page == 1) && (response.data.resources.last_page > 1)) {
                        setTimeout(function () {
                            $('.price-panel-assets').on('scroll', function () {
                                vm.handleScroll();
                            });
                        }, 500);
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#price-panel-view-assets-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.assets_loading = false;
                    vm.loading = false;
                });
            },
            viewAsset: function (assetId) {
                $('#price-panel-view-assets-modal').modal('hide');
                this.$router.push({ name: 'assets-filtered', params: { filters: 'id::' + assetId } });
            },
            handleScroll() {
                if ((this.assets_current_page < this.assets_last_page) && !this.assets_loading) {
                    const scrollTop = $('.price-panel-assets').scrollTop();
                    const contentHeight = $('.price-panel-assets > div').outerHeight();
                    const scrollPosition = contentHeight - (scrollTop + 450);
                    if (scrollPosition < 200) {
                        this.getAssets(this.assets_current_page + 1);
                    }
                }
            }
        }
    }
</script>
<style scoped>
    .price-panel-assets {
        max-height: 450px;
        overflow-y: auto;
    }
</style>
