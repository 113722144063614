<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="add-to-collection-modal-label">
                {{ $root.text('Save to collection', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="alert alert-success" role="alert" :style="(added_to_collection ? '' : 'display: none')">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col">{{ added_to_collection }}</div>
                </div>
            </div>

            <div :style="'min-height: 100px' + (added_to_collection ? '; display: none' : '')">
                <form v-on:submit.prevent="saveToCollection()">
                    <div
                        v-if="save_to_collection_errors"
                        v-html="save_to_collection_errors"
                        class="alert alert-danger"
                        role="alert"
                    ></div>
                    <label>{{ $root.text('Save to new collection', null, true) }}</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span
                                class="input-group-text font-size-18"
                                style="width: 40px; padding-left: 11px; position: relative"
                            >
                                <i class="fa-light fa-rectangle-history"></i>
                                <i
                                    class="far fa-plus"
                                    style="position: absolute; left: 16px; bottom: 10px; font-size: 10px"
                                ></i>
                            </span>
                        </div>
                        <input
                            v-model="new_collection_name"
                            type="text"
                            class="form-control mandatory-field"
                            id="atc-new-collection-name"
                            :placeholder="placeholder('atc-new-collection-name')"
                            v-on:focus="placeholder('atc-new-collection-name', true)"
                            v-on:blur="placeholder('atc-new-collection-name', true)"
                            aria-label="Collection name"
                            :readonly="saving_to_collection"
                            v-on:keyup="$root.unHighlightErrors('atc-new-collection-name')"
                            v-on:change="$root.unHighlightErrors('atc-new-collection-name')"
                            style="height: 37px"
                        >
                        <div class="input-group-append">
                            <button
                                v-if="!saving_to_collection"
                                type="submit"
                                class="btn btn-primary big-icon"
                                v-b-tooltip.hover
                                :title="$root.text('Save', null, true)"
                            >
                                <strong><i class="fal fa-save"></i></strong>
                            </button>
                            <button
                                v-else
                                type="button"
                                class="btn btn-primary big-icon"
                            >
                                <span><i class="fal fa-spinner-third fa-spin"></i></span>
                            </button>
                        </div>
                    </div>
                </form>
                <div v-if="hasCollections()">
                    <hr>
                    <form v-on:submit.prevent="addToCollection()">
                        <div
                            v-if="add_to_collection_errors"
                            v-html="add_to_collection_errors"
                            class="alert alert-danger"
                            role="alert"
                        ></div>
                        <div>
                            <label>{{ $root.text('Add to collection', null, true) }}</label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" style="width: 40px; padding-left: 11px">
                                        <i class="fa-light fa-rectangle-history font-size-18"></i>
                                    </span>
                                </div>
                                <input
                                    v-model="chosen_collection_name"
                                    type="text"
                                    class="form-control"
                                    id="atc-chosen-collection-name"
                                    :readonly="adding_to_collection"
                                    v-on:focus="showCollections()"
                                >
                                <span class="atc-choose-collection-chevron" @click="showCollections()">
                                    <i class="fas fa-chevron-down"></i>
                                </span>
                            </div>
                        </div>
                        <div id="atc-choosing-collection-on">
                            <div v-for="item in getCollections()" :key="item.id">
                                <a
                                    v-if="(item.id > 0)"
                                    :class="((item.id == chosen_collection) ? 'dropdown-item active' : 'dropdown-item')"
                                    href="#"
                                    @click.prevent="chooseCollection(item)"
                                    style="white-space: normal"
                                >
                                    {{ item.name }}
                                </a>
                                <div v-else class="dropdown-divider"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['list_component', 'selected_assets', 'collections', 'collection'],
        data () {
            return {
                new_collection_name: '',
                saving_to_collection: false,
                save_to_collection_errors: false,
                chosen_collection: null,
                chosen_collection_name: 'Choose a collection',
                adding_to_collection: false,
                add_to_collection_errors: false,
                assets: [],
                added_to_collection: false
            }
        },
        mounted () {
            if (this.collection) {
                this.chosen_collection = this.collection;
            }
            $(document).on('click', function () {
                $('#atc-choosing-collection-on').hide();
            });
        },
        watch: {
            collection: function (val) {
                this.chosen_collection = val ? val : null;
            }
        },
        methods: {
            reset: function (assetIds = []) {
                this.added_to_collection = false;
                this.assets = (assetIds.length > 0) ? assetIds : this.selected_assets;
                this.chosen_collection_name = this.$root.text('Choose a collection', null, true);
            },
            saveToCollection: function () {
                this.save_to_collection_errors = false;
                this.saving_to_collection = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = {
                    name: this.new_collection_name,
                    initial_resources: this.assets
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.current_collection = response.data.collection_id;
                    vm.added_to_collection = vm.$root.text(
                        'Your selection has been saved to {collection}',
                        { collection: vm.new_collection_name },
                        true
                    );
                    vm.new_collection_name = '';
                    vm.list_component.getCollections();

                    // close after 2 seconds automatically
                    setTimeout(function () {
                        $('#add-to-collection-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.save_to_collection_errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.save_to_collection_errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.save_to_collection_errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.save_to_collection_errors += value.join('<br>') + '<br>';
                                    $('#atc-new-collection-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.saving_to_collection = false
                );
            },
            addToCollection: function () {
                if (!this.chosen_collection) {
                    this.add_to_collection_errors = this.$root.text('Please choose a collection', null, true);

                    return null;
                }
                this.add_to_collection_errors = false;
                this.adding_to_collection = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = {
                    resources: this.assets
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.chosen_collection + '/resource';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.current_collection = vm.chosen_collection;
                    let collectionName = null;
                    vm.collections.forEach((item) => {
                        if (item.id == vm.chosen_collection) {
                            collectionName = item.name;
                        }
                    });
                    if (collectionName) {
                        vm.added_to_collection = vm.$root.text(
                            'Your selection has been added to {collection}',
                            { collection: collectionName },
                            true
                        );
                    } else {
                        vm.added_to_collection = vm.$root.text(
                            'Your selection has been added to the chosen collection',
                            null,
                            true
                        );
                    }
                    vm.list_component.getCollections();

                    // close after 2 seconds automatically
                    setTimeout(function () {
                        $('#add-to-collection-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.add_to_collection_errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.add_to_collection_errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    vm.adding_to_collection = false
                );
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'atc-new-collection-name':
                        text = this.$root.text('Collection name', null, true);
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            hasCollections: function () {
                if (!this.collections) {
                    return false;
                }
                return (this.collections.length > 0);
            },
            getCollections: function () {
                if (!this.collections) {
                    return [];
                }
                if (this.collections.length < 4) {
                    return this.collections;
                }
                let collectionsReordered = [];
                let collectionsAdded = [];
                let lastAssetAdded = null;
                let lastResourceAddedAt = null;
                let itemIndex = -1;
                while (collectionsAdded.length < this.collections.length) {
                    lastAssetAdded = '2000-00-00T00:00:00.000000Z';
                    itemIndex = -1;
                    this.collections.forEach((item, index) => {
                        lastResourceAddedAt = (item.last_resource_added_at === null) ? '2000-00-00T00:00:00.000000Z'
                            : item.last_resource_added_at;
                        if (collectionsAdded.indexOf(item.id) < 0) {
                            if (collectionsAdded.length > 2) {
                                collectionsReordered.push(item);
                                collectionsAdded.push(item.id);
                            } else if (lastResourceAddedAt >= lastAssetAdded) {
                                lastAssetAdded = lastResourceAddedAt;
                                itemIndex = index;
                            }
                        }
                    });
                    if (itemIndex > -1) {
                        collectionsReordered.push(this.collections[itemIndex]);
                        collectionsAdded.push(this.collections[itemIndex].id);
                        if (collectionsAdded.length == 3) {
                            collectionsReordered.push({id: -1, name: '--------------------'});
                        }
                    }
                }

                return collectionsReordered;
            },
            showCollections: function () {
                $('#atc-chosen-collection-name').blur();
                if (this.adding_to_collection) {
                    return null;
                }
                setTimeout(function () {
                    $('#atc-choosing-collection-on').show();
                }, 200);
            },
            chooseCollection: function (collection) {
                this.chosen_collection = collection.id;
                this.collections.forEach((item) => {
                    if (item.id == collection.id) {
                        this.chosen_collection_name = item.name;
                    }
                });
                $('#atc-choosing-collection-on').hide();
                this.addToCollection();
            }
        }
    }
</script>
<style scoped>
    #atc-choosing-collection-on {
        position: absolute;
        bottom: 12px;
        left: 12px;
        width: calc(100% - 24px);
        max-height: 180px;
        overflow-y: auto;
        background-color: rgb(255, 255, 255);
        z-index: 3;
        border: 0px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        box-shadow: rgb(0 0 0 / 18%) 0px 1rem 3rem;
        padding-top: 10px;
        padding-bottom: 10px;
        display: none;
    }

    .atc-choose-collection-chevron {
        position: absolute;
        top: 9px;
        right: 12px;
    }
</style>
