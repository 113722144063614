<template>
    <div class="account-pages mx-md-5 my-5 pt-sm-5">
        <div class="container-fluid">
            <div :class="$root.loginPanelClass()">
                <div class="col-sm-6 col-lg-4 col-xl-3 login-panel-col">
                    <div class="card overflow-hidden">
                        <div :class="$root.app.login_panel_header_class + ' px-4 py-2 text-center'">
                            <img
                                v-if="$root.app.login_logo"
                                :src="$root.app.login_logo"
                                :alt="$root.client.client_name"
                                class="img-fluid"
                            >
                        </div>
                        <div class="card-body pt-2 pb-1">
                            <div v-if="$root.getAvailableLanguages().length > 1" class="text-right m-2">
                                <language-select></language-select>
                            </div>

                            <div class="p-2">
                                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                                <div v-if="loading"><loading></loading></div>

                                <div v-else class="form-group">
                                    <div v-if="!errors" class="alert alert-success" role="alert">
                                        <div class="row">
                                            <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                                            <div class="col">{{ $root.text('File downloaded', null, true) }}</div>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <router-link :to="{ name: 'login' }">
                                            <i class="far fa-sign-in-alt mr-1"></i>
                                            {{ $root.text('Click here to login', null, true) }}
                                        </router-link>
                                    </div>
                                </div>

                                <div class="text-center" style="font-size: 0.7em; position: relative; height: 10px">
                                    {{ $root.text('Powered by', null, true) }}
                                    <a href="https://resourcebase.com" target="_blank">Resourcebase</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                errors: false
            }
        },
        mounted () {
            $('body').addClass('login-body');

            if (!this.$route.query.templateid || !this.$route.query.email) {
                this.errors = this.$root.text('Invalid request.', null, true);
                this.loading = false;

                return null;
            };
            this.getPrintOrderFile();
        },
        methods: {
            getPrintOrderFile: function () {
                this.loading = true;
                this.errors = false;

                /**
                 * Send request to API.
                 */
                var vm = this;
                const path = '/' + this.$root.app.client_id + '/supplier-print-order-file/'
                    + this.$route.query.templateid + '/' + this.$route.query.email;
                const url = this.$root.app.api_url + path;

                axios({
                    url: url
                })
                .then(response => {
                    vm.downloadFile(response.data.print_order_id, response.data.file);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                    vm.loading = false;
                });
            },
            downloadFile: function (printOrderId, download) {
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'print_order_file',
                    item_id: printOrderId,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension,
                    email: this.$route.query.email
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token/external';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.loading = false
                );
            }
        }
    }
</script>
