<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="artwork-approval-request-modal-label">
                <span v-if="!sent">{{ $root.text('This item requires approval', null, true) }}</span>
                <span v-else>{{ $root.text('Your request has been submitted', null, true) }}</span>
            </h5>
            <button v-if="!sent" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent">
                <p>
                    {{ $root.text('You will receive an email once the request has been reviewed.', null, true) }}
                    {{ $root.text(
                        'You can also check the approval status on your {my account} page.',
                        { 'my account': $root.text('my account', null, true) },
                        true
                    ) }}
                    <br><br>
                    <a href="#" data-dismiss="modal" @click.prevent="finish('my-account')">
                        {{ $root.text('My account', null, true) }}
                    </a>
                </p>
                <p v-if="$root.editing_artwork" class="mb-0" style="font-weight: 500">
                    {{ $root.text('What would you like to do now?', null, true) }}
                </p>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
                <form v-if="!fatal_error">
                    <div class="form-group">
                        <label for="artwork-comments" id="artwork-comments-label" class="mandatory-label">
                            {{ $root.text('Please give brief details of how you will use this item', null, true) }} *
                        </label>
                        <textarea
                            v-model="comments"
                            id="artwork-comments"
                            class="form-control mandatory-field"
                            :readonly="sending"
                            rows="4"
                            v-on:keyup="$root.unHighlightErrors('artwork-comments')"
                            v-on:change="$root.unHighlightErrors('artwork-comments')"
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="((!sent || $root.editing_artwork) && !fatal_error)" class="modal-footer">
            <span v-if="sent" class="sent-buttons">
                <span class="left">
                    <span v-if="(!opening_artwork_editor || duplicating_artwork)">
                        <button
                            :key="'create-new-version-button'"
                            type="button"
                            class="btn btn-secondary btn-xs-block mr-sm-2 mr-0 mb-sm-0 mb-2"
                            :disabled="opening_artwork_editor"
                            @click="createArtwork()"
                        >
                            <i class="far fa-pen-paintbrush mr-1"></i> {{ $root.text('Create new version', null, true) }}
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'creating-new-button'"
                            type="button"
                            class="btn btn-secondary btn-xs-block mr-sm-2 mr-0 mb-sm-0 mb-2"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Create new version', null, true) }}</span>
                        </button>
                    </span>
                    <span v-if="(!opening_artwork_editor || !duplicating_artwork)">
                        <button
                            :key="'copy-this-version-button'"
                            type="button"
                            class="btn btn-secondary  btn-xs-block mr-sm-2 mr-0 mb-sm-0 mb-2"
                            :disabled="opening_artwork_editor"
                            @click="duplicateArtwork()"
                        >
                            <i class="far fa-copy mr-1"></i> {{ $root.text('Copy this version', null, true) }}
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'copying-this-button'"
                            type="button"
                            class="btn btn-secondary btn-xs-block mr-sm-2 mr-0 mb-sm-0 mb-2"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Copy this version', null, true) }}</span>
                        </button>
                    </span>
                </span>
                <button
                    :key="'finish-button'"
                    type="button"
                    class="btn btn-light btn-xs-block"
                    data-dismiss="modal"
                    :disabled="opening_artwork_editor"
                    @click="finish()"
                >
                    <i class="far fa-times mr-1"></i> {{ $root.text('Close', null, true) }}
                </button>
            </span>
            <span v-else>
                <span v-if="!sending">
                    <button :key="'submit-button'" type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Request approval', null, true) }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'submit-button-spinning'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ $root.text('Request approval', null, true) }}</span>
                    </button>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                fatal_error: false,
                sending: false,
                sent: false,
                comments: null,
                opening_artwork_editor: false,
                duplicating_artwork: false,
                template: null,
                editor: null
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.fatal_error = false;
                this.sending = false;
                this.sent = false;
                this.comments = null;
                if (this.$root.editing_artwork) {
                    this.editor = this.$root.findComponent(this.$parent, 'artwork-editor');
                }
            },
            onSubmit: function () {
                this.errors = false;
                this.fatal_error = false;
                this.sending = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                if (!this.comments) {
                    this.errors = 'The brief details field is required.';
                    $('#artwork-comments-label').addClass('text-danger');
                    $('#artwork-comments').addClass('field-error');
                    this.sending = false;

                    return null;
                }

                /**
                 * Send request to API
                 */
                const data = {
                    temp_id: this.editor.temp_id,
                    title: this.editor.artwork.title,
                    finished: true,
                    profile_id: this.editor.profile_id,
                    comments: this.comments,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.editor.template.id
                    + '/artwork';
                if (this.editor.artwork.id) {
                    url += '/' + this.editor.artwork.id;
                }
                var vm = this;

                axios({
                    method: (this.editor.artwork.id) ? 'put' : 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = true;
                })
                .catch(function (error) {
                    if ((error.response.status == 406) && (error.response.data.error == 'Resource replaced.')) {
                        vm.errors = vm.$root.text(
                            'The {template} used to create your artwork has been updated. Please create a new version.',
                            { template: vm.$root.text(vm.$root.contents.template_title, null, false, 'template_title') },
                            true
                        );
                        vm.fatal_error = true;
                        vm.$parent.hideArtworkEditor();

                        return null;
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.sending = false;
                });
            },
            finish: function (action = null, delay = true) {
                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.finish(action, false);
                    }, 200);

                    return null;
                }
                if (this.$root.editing_artwork) {
                    this.$parent.hideArtworkEditor();
                }
                if (!action) {
                    return null;
                }

                if (this.$route.name == action) {
                    return null;
                }
                this.$router.push({ name: action });
            },
            createArtwork: function () {
                this.opening_artwork_editor = true;
                this.duplicating_artwork = false;
                this.template = this.editor.template;

                /**
                 * Send get template details request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.editor.asset.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.template.fields = response.data.template.fields;
                    vm.showArtworkEditor();
                })
                .catch(function (error) {
                    //vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.opening_artwork_editor = false;
                });
            },
            duplicateArtwork: function () {
                this.opening_artwork_editor = true;
                this.duplicating_artwork = true;
                this.template = this.editor.template;

                /**
                 * Send get artwork request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.template.id + '/artwork/' + this.editor.artwork.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.showArtworkEditor(response.data.artwork, true);
                })
                .catch(function (error) {
                    //vm.errors = error.response.data.error || 'Something went wrong, please try again';
                })
                .finally(() => {
                    vm.opening_artwork_editor = false;
                });
            },
            showArtworkEditor: function (artwork = null, duplicate = false) {
                $('#artwork-approval-request-modal').modal('hide');
                this.$parent.showArtworkEditor(
                    this.editor.asset,
                    this.template,
                    artwork,
                    duplicate
                );
            }
        }
    }
</script>
<style scoped>
    .sent-buttons .left {
        position: absolute;
        left: 15px;
    }

    @media (max-width: 575px) {
        .sent-buttons {
            width: 100%;
        }

        .sent-buttons .left {
            position: relative;
            left: 0;
            display: block;
            width: 100%;
        }

        .sent-buttons .btn-xs-block {
            width: 100%;
        }
    }
</style>
