<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="manage-images-modal-label">{{ $root.text('Manage images', null, true) }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="errors" v-html="errors" class="alert alert-danger form-errors" role="alert"></div>

            <loading v-if="loading"></loading>

            <div v-if="(!loading && (items.length == 0))" class="alert alert-warning form-errors" role="alert">
                {{ $root.text(
                    'There are no image fields in this {template}.',
                    { template: $root.text($root.contents.template_title, null, false, 'template_title') },
                    true
                ) }}
            </div>

            <div v-if="(items.length > 0)" style="min-height: 300px">
                <div class="row">
                    <div class="col-sm-6 col-12">
                        <div class="form-group">
                            <searchable-option-list
                                :key="'manage-images-page-' + ($root.current_language || 0)"
                                v-model="current_page"
                                :value="current_page"
                                :options="templatePages()"
                                field_id="manage-images-select-page"
                                :readonly="updating || manage_images_updating_selected_images"
                                :search_placeholder="$root.text('Select page', null, true)"
                                v-on:change="selecting_template_page = true"
                            ></searchable-option-list>
                            <!-- || (templatePages().length == 1)-->
                        </div>
                        <div v-if="(!selecting_template_image && current_image)">
                            <div v-for="image in templateImages()" :key="image.id">
                                <div v-if="(image.id == current_image)">
                                    <div v-if="$root.isFeatured('profiles')" class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                v-model="image.profile_images"
                                                type="checkbox"
                                                class="custom-control-input"
                                                :id="'manage-images-profile_images' + image.id"
                                                :disabled="updating"
                                                v-on:change="onTemplateImageChange(image)"
                                            >
                                            <label
                                                class="custom-control-label"
                                                :for="'manage-images-profile_images' + image.id"
                                            >
                                                {{ $root.text($root.contents.profile_title + ' images', null, true) }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                v-model="image.user_images"
                                                type="checkbox"
                                                class="custom-control-input"
                                                :id="'manage-images-user_images' + image.id"
                                                :disabled="updating"
                                                v-on:change="onTemplateImageChange(image)"
                                            >
                                            <label
                                                class="custom-control-label"
                                                :for="'manage-images-user_images' + image.id"
                                            >{{ $root.text('Allow user images', null, true) }}</label>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox">
                                            <input
                                                v-model="image.image_editing"
                                                type="checkbox"
                                                class="custom-control-input"
                                                :id="'manage-images-image_editing' + image.id"
                                                :disabled="updating"
                                                v-on:change="onTemplateImageChange(image)"
                                            >
                                            <label
                                                class="custom-control-label"
                                                :for="'manage-images-image_editing' + image.id"
                                            >{{ $root.text('Allow image editing', null, true) }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-12">
                        <div
                            v-if="(!selecting_template_page && (current_page !== null))"
                            class="form-group"
                        >
                            <searchable-option-list
                                :key="'manage-images-image-' + ($root.current_language || 0)"
                                v-model="current_image"
                                :value="current_image"
                                :options="templateImages()"
                                field_id="manage-images-select-image"
                                :readonly="updating || manage_images_updating_selected_images"
                                :search_placeholder="$root.text('Select image field', null, true)"
                                v-on:change="selecting_template_image = true"
                            ></searchable-option-list>
                            <!-- || (templateImages().length == 1)-->
                        </div>
                        <div v-if="(!selecting_template_image && current_image)">
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-block"
                                    :disabled="(updating || manage_images_updating_selected_images)"
                                    @click.prevent="chooseAsset()"
                                >
                                    <i class="far fa-images mr-1"></i>
                                    {{ $root.text(
                                        'Choose {resources}',
                                        {
                                            resources: $root.text(
                                                $root.contents.asset_title + 's',
                                                null,
                                                false,
                                                'assets_title'
                                            )
                                        },
                                        true
                                    ) }}
                                </button>
                            </div>
                            <div class="form-group">
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-block"
                                    :disabled="(updating || manage_images_updating_selected_images)"
                                    @click.prevent="resetImageUpload()"
                                >
                                    <i class="far fa-cloud-upload mr-1"></i>
                                    {{ $root.text('Upload images', null, true) }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-for="image in templateImages()" :key="'selected-images-' + image.id">
                    <div v-if="(image.id == current_image)">
                        <div v-if="(image.selected_images.length > 0)">
                            <hr class="mt-0">
                            <p style="text-transform: uppercase">
                                <span v-if="(image.selected_images.length == 1)">
                                    {{ $root.text('1 selected image') }}
                                </span>
                                <span v-else>
                                    {{ $root.text('{x} selected images', { x: image.selected_images.length }) }}
                                </span>
                            </p>
                            <div
                                v-if="!manage_images_updating_selected_images"
                                :key="'selected-image-' + image.selected_images.length"
                                class="pt-3 pl-3 pr-3 mb-3"
                                :style="selected_images_styles"
                            >
                                <div class="row">
                                    <div
                                        v-for="item in image.selected_images"
                                        :key="item.id"
                                        class="col-md-4 col-sm-6 col-12"
                                    >
                                        <manage-image-preview
                                            :data="item"
                                            :readonly="updating"
                                            v-on:preview="onTemplateImagePreviewed"
                                            v-on:remove="onTemplateImageRemoved"
                                        ></manage-image-preview>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <loading v-if="manage_images_updating_selected_images"></loading>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loading" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="updateFields()">
                        <i class="far fa-check mr-1"></i> {{ $root.text('Done', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Updating fields', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['template'/*, 'locked_profile_fields'*/],
        data () {
            return {
                component_name: 'manage-images',
                errors: false,
                loading: true,
                items: [],
                current_page: null,
                current_image: null,
                selecting_template_page: false,
                selecting_template_image: false,
                manage_images_selected_asset_ids: null,
                manage_images_updating_selected_images: false,
                updating: false,
                selected_images_styles: 'max-height: 350px; overflow-x: hidden; overflow-y: auto; '
                    + 'background-color: #e4e4e4'
            }
        },
        mounted () {
            /*if (this.template) {
                this.getFields(this.template);
            }*/
        },
        watch: {
            /*template: function (val) {
                if (val) {
                    this.getFields(val);
                }
            },*/
            selecting_template_page: function (val) {
                if (val) {
                    this.current_image = null;
                    var vm = this;
                    setTimeout(function () {
                        const templateImages = vm.templateImages();
                        if (templateImages) {
                            if (templateImages.length == 1) {
                                vm.current_image = templateImages[0].id;
                                vm.selecting_template_image = true;
                            }
                        }
                        vm.selecting_template_page = false;
                    }, 500);
                }
            },
            selecting_template_image: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.selecting_template_image = false;
                    }, 500);
                }
            }
        },
        methods: {
            reset: function () {
                this.getFields(this.template);
            },
            getFields: function (template) {
                this.errors = false;
                this.loading = true;
                this.items = [];
                this.current_page = null;
                this.current_image = null;

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + template.id
                    + '/fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const qrSuffixes = this.$root.client.artwork_generate_qr_codes ? ['_QR', '_LQR', '_LQRx'] : [];
                    let page = 0;
                    let items = [];
                    let fields = [];
                    response.data.fields.forEach((item) => {
                        if (item.page_number > page) {
                            for (let p = page; p < item.page_number; p++) {
                                items.push(fields);
                                fields = [];
                            }
                            page = item.page_number;
                        }
                        if (
                            (item.type == 'I')
                            && (
                                ((item.title_suffix != '_Lx') && (qrSuffixes.indexOf(item.title_suffix) < 0))
                                /*|| (this.locked_profile_fields.indexOf('I' + item.title) < 0)*/
                            )
                        ) {
                            fields.push(item);
                        }
                    });
                    if (fields.length > 0) {
                        items.push(fields);
                    }
                    vm.items = items;
                    const templatePages = vm.templatePages();
                    if (templatePages.length == 1) {
                        vm.current_page = templatePages[0].id;
                        vm.selecting_template_page = true;
                        /*const templateImages = vm.templateImages();
                        if (templateImages.length == 1) {
                            vm.current_image = templateImages[0].id;
                        }*/
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#manage-field-order-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            templatePages: function () {
                let pages = [];
                this.items.forEach((fields, page) => {
                    if ((page > 0) && (fields.length > 0)) {
                        pages.push({ id: page, name: this.$root.text('Page {x}', { x: page }, true) });
                    }
                });

                return pages;
            },
            templateImages: function () {
                if (!this.items || !this.current_page) {
                    return [];
                }
                if (this.items[this.current_page].length == 0) {
                    return [];
                }
                let images = [];
                this.items[this.current_page].forEach((item) => {
                    if (!('name' in item)) {
                        item.name = item.title.toString();
                        if (item.title_suffix) {
                            item.name += item.title_suffix;
                        }
                    }
                    images.push(item);
                });

                return images;
            },
            onTemplateImageChange: function (image) {
                this.items.forEach((fields, page) => {
                    if (page > 0) {
                        fields.forEach((item) => {
                            if (
                                (item.type == 'I')
                                && (item.title.toLowerCase() == image.title.toLowerCase())
                                && (item.title_suffix == image.title_suffix)
                                && (item.id != image.id)
                            ) {
                                item.profile_images = image.profile_images;
                                item.user_images = image.user_images;
                                item.image_editing = image.image_editing;
                            }
                        });
                    }
                });
            },
            onTemplateImagePreviewed: function (event) {
                this.items[this.current_page][this.manageImageIndex()].selected_images.forEach((item) => {
                    if (event.is_asset === null) {
                        if (!('is_asset' in item) && (item.id == event.id)) {
                            item.preview = event.src;
                        }
                    } else if ('is_asset' in item) {
                        if ((item.is_asset == event.is_asset) && (item.id == event.id)) {
                            item.preview = event.src;
                        }
                    }
                });
            },
            onTemplateImageRemoved: function (event) {
                this.manage_images_updating_selected_images = true;
                this.removeSelectedImage(event.id, true, event.is_asset);
                var vm = this;
                setTimeout(function () {
                    vm.manage_images_updating_selected_images = false;
                }, 500);
            },
            removeSelectedImage: function (id, sync = false, isAsset = null) {
                const field = this.items[this.current_page][this.manageImageIndex()];

                let itemIndex = -1;
                field.selected_images.forEach((item, index) => {
                    if (isAsset === null) {
                        if (!('is_asset' in item) && (item.id == id)) {
                            itemIndex = index;
                        }
                    } else if ('is_asset' in item) {
                        if ((item.is_asset == isAsset) && (item.id == id)) {
                            itemIndex = index;
                        }
                    }
                });
                if (itemIndex > -1) {
                    field.selected_images.splice(itemIndex, 1);
                }
                if (sync) {
                    this.syncTemplateFieldImages(field);
                }
            },
            syncTemplateFieldImages: function (currentField) {
                this.items.forEach((fields, page) => {
                    if (page > 0) {
                        fields.forEach((item) => {
                            if (
                                (item.title.toLowerCase() == currentField.title.toLowerCase())
                                && (item.title_suffix == currentField.title_suffix)
                                && (item.id != currentField.id)
                                && (item.type == 'I')
                            ) {
                                let selectedImages = [];
                                currentField.selected_images.forEach((image) => {
                                    selectedImages.push(image);
                                });
                                item.selected_images = selectedImages;
                            }
                        });
                    }
                });
            },
            manageImageIndex: function () {
                if (!this.items || !this.current_page) {
                    return -1;
                }
                let imageIndex = -1;
                this.items[this.current_page].forEach((item, index) => {
                    if (item.id == this.current_image) {
                        imageIndex = index;
                    }
                });

                return imageIndex;
            },
            chooseAsset: function () {
                const field = this.items[this.current_page][this.manageImageIndex()];
                let selectedImages = [];
                field.selected_images.forEach((item) => {
                    if ('is_asset' in item) {
                        if (item.is_asset) {
                            selectedImages.push(item.id);
                        }
                    } else {
                        if (item.id > 0) {
                            selectedImages.push(item.id);
                        }
                    }
                });

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'select-item');
                component.reset(
                    ['asset'],
                    this.$root.text(
                        'Choose {resources}',
                        {
                            resources: this.$root.text(
                                this.$root.contents.asset_title + 's',
                                null,
                                false,
                                'assets_title'
                            )
                        },
                        true
                    ),
                    this,
                    'template-images',
                    'asset',
                    selectedImages,
                    ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf', 'image/svg', 'image/svg+xml'],
                    this.$root.text(
                        'Only {folders} with suitable files will appear in the drop down menu',
                        { folders: this.$root.text(this.$root.contents.folders_title, null, false, 'folders_title') },
                        true
                    ),
                    true
                );
                $('#manage-images-modal').modal('hide');
                setTimeout(function () {
                    $('#select-item-modal').modal('show');
                }, 500);
            },
            selectedItem: function (field, itemType, value) {
                this.manage_images_updating_selected_images = true;
                field = this.items[this.current_page][this.manageImageIndex()];

                // Remove asset images.
                let imagesToRemove = [];
                field.selected_images.forEach((item) => {
                    if ('is_asset' in item) {
                        if (item.is_asset && (value.indexOf(item.id) < 0)) {
                            imagesToRemove.push({ id: item.id, is_asset: true });
                        }
                    } else {
                        if ((item.id > 0) && (value.indexOf(item.id) < 0)) {
                            imagesToRemove.push({ id: item.id, is_asset: null });
                        }
                    }
                });
                if (imagesToRemove.length > 0) {
                    imagesToRemove.forEach((item) => {
                        this.removeSelectedImage(item.id, false, item.is_asset);
                    });
                    this.syncTemplateFieldImages(field);
                }

                if ((value.length > 0) && (this.manageImageIndex() > -1)) {
                    this.manage_images_selected_asset_ids = value;
                    this.getSelectedAssets();

                    return null;
                }

                var vm = this;
                setTimeout(function () {
                    vm.manage_images_updating_selected_images = false;
                }, 500);
            },
            getSelectedAssets: function () {
                const field = this.items[this.current_page][this.manageImageIndex()];
                
                let getAssetIds = [];
                this.manage_images_selected_asset_ids.forEach((assetId) => {
                    let itemIndex = -1;
                    field.selected_images.forEach((item, index) => {
                        if ('is_asset' in item) {
                            if (item.is_asset && (item.id == assetId)) {
                                itemIndex = index;
                            }
                        } else {
                            if (item.id == assetId) {
                                itemIndex = index;
                            }
                        }
                    });
                    if (itemIndex < 0) {
                        getAssetIds.push(assetId);
                    }
                });
                if (getAssetIds.length == 0) {
                    this.syncTemplateFieldImages(field);
                    this.manage_images_updating_selected_images = false;

                    return null;
                }

                /**
                 * Send get assets request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/in::'
                    + getAssetIds.join(',');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let selectedImages = [];
                    vm.items[vm.current_page][vm.manageImageIndex()].selected_images.forEach((image) => {
                        selectedImages.push(image);
                    });
                    response.data.resources.data.forEach((item) => {
                        item.preview = null;
                        if (selectedImages.length == 0) {
                            selectedImages.push(item);
                        } else {
                            let images = [];
                            let added = false;
                            selectedImages.forEach((image) => {
                                if (!added && (item.id > image.id)) {
                                    images.push(item);
                                    added = true;
                                }
                                images.push(image);
                            });
                            if (!added) {
                                images.push(item);
                            }
                            selectedImages = images;
                        }
                    });
                    vm.items[vm.current_page][vm.manageImageIndex()].selected_images = selectedImages;
                    vm.getSelectedAssets();
                })
                .catch(function (error) {
                    //
                });
            },
            resetImageUpload: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'template-image-upload');
                if (component) {
                    component.reset(this);
                    $('#manage-images-modal').modal('hide');
                    setTimeout(function () {
                        $('#template-image-upload-modal').modal('show');
                    }, 500);
                }
            },
            addUploadedImages: function (images) {
                this.manage_images_updating_selected_images = true;
                const field = this.items[this.current_page][this.manageImageIndex()];

                let nextId = 0;
                field.selected_images.forEach((item) => {
                    if (item.id < nextId) {
                        nextId = item.id;
                    }
                });
                nextId--;
                images.forEach((item) => {
                    item.id = nextId;
                    field.selected_images.unshift(item);
                    nextId--;
                });
                this.syncTemplateFieldImages(field);

                var vm = this;
                setTimeout(function () {
                    vm.manage_images_updating_selected_images = false;
                }, 500);
            },
            updateFields: function () {
                this.errors = false;
                this.updating = true;

                let fields = [];
                this.items.forEach((page) => {
                    page.forEach((item) => {
                        let selectedImages = [];
                        item.selected_images.forEach((image) => {
                            if (image.id > 0) {
                                let isAsset = true;
                                if ('is_asset' in image) {
                                    isAsset = image.is_asset;
                                }
                                selectedImages.push({
                                    id: image.id,
                                    is_asset: isAsset
                                });
                            } else {
                                selectedImages.push({
                                    filename: image.filename,
                                    file_type: image.file_type,
                                    file_size: image.file_size,
                                    temp_file: image.temp_file
                                });
                            }
                        });
                        fields.push({
                            id: item.id,
                            sort_order: item.sort_order,
                            profile_images: item.profile_images,
                            user_images: item.user_images,
                            image_editing: item.image_editing,
                            selected_images: selectedImages
                        });
                    });
                });
                const data = {
                    fields: fields,
                    images_managed: true
                };

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/' + this.template.id
                    + '/fields';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    $('#manage-images-modal').modal('hide');
                    vm.$parent.template.images_managed = true;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - close modal and logout user
                        $('#manage-images-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
