<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="subfolder-delete-modal-label">
                {{ $root.text(
                    'Delete {subfolder}:',
                    { subfolder: $root.text($root.contents.subfolder_title, null, false, 'subfolder_title') },
                    true
                ) }}
                {{ subfolder_name }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="deleted" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="deleted"></div>
                </div>
            </div>
            <form v-else v-on:submit.prevent="deleteSubfolder()">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-if="!loading && canDelete()">
                    <p>
                        {{ additionalThings() }}
                        {{ $root.text(
                            'Any {resources} associated with other {subfolders} (within the same {folder}) will be '
                                + 'unaffected.',
                            {
                                resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title'),
                                subfolders: $root.text($root.contents.subfolders_title, null, false, 'subfolders_title'),
                                folder: $root.text($root.contents.folder_title, null, false, 'folder_title')
                            },
                            true
                        ) }}
                    </p>
                    <p v-if="((assets_live_count > 0) || (assets_archived_count > 0))">
                        {{ $root.text(
                            'If you need to manage {resources} you should do this first.',
                            { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title') },
                            true
                        ) }}
                    </p>
                    <p v-if="(assets_live_count > 0)">
                        {{ $root.text(
                            'You can move live {resources} to other {folders} by adding them to a collection and using '
                                + 'the Bulk actions function.',
                            {
                                resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title'),
                                folders: $root.text($root.contents.folders_title, null, false, 'folders_title')
                            }
                        ) }}
                    </p>
                </div>
            </form>
        </div>
        <div v-if="!loading && !deleted && canDelete()" class="modal-footer">
            <span v-if="!deleting">
                <span>
                    <button type="button" class="btn btn-primary mr-2" @click="deleteSubfolder()" :disabled="loading">
                        <i class="far fa-trash-alt mr-1"></i>
                        {{ $root.text(
                            'Delete {subfolder}',
                            { subfolder: $root.text($root.contents.subfolder_title, null, false, 'subfolder_title') },
                            true
                        ) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">
                        {{ $root.text(
                            'Delete {subfolder}',
                            { subfolder: $root.text($root.contents.subfolder_title, null, false, 'subfolder_title') },
                            true
                        ) }}
                    </span>
                </button>
            </span>
            <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="deleting">
                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                folder_id: 0,
                subfolder_id: 0,
                subfolder_name: '',
                assets_live_count: 0,
                assets_archived_count: 0,
                profile_groups_count: 0,
                deleting: false,
                deleted: false,
                list_component: null
            }
        },
        mounted () {
            //
        },
        methods: {
            reset: function (subfolder, listComponent) {
                this.loading = true;
                this.errors = false;
                this.folder_id = subfolder.folder_id;
                this.subfolder_id = subfolder.id;
                this.subfolder_name = subfolder.name;
                this.assets_live_count = 0;
                this.assets_archived_count = 0;
                this.profile_groups_count = 0;
                this.deleting = false;
                this.deleted = false;
                this.list_component = listComponent;
                this.getAssetInfo();
            },
            getAssetInfo: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/subfolder/' + this.subfolder_id + '/resource-info';
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_live_count = response.data.live;
                    vm.assets_archived_count = response.data.archived;
                    vm.profile_groups_count = response.data.profile_groups || 0;
                    if (vm.profile_groups_count > 1) {
                        vm.errors = vm.$root.text(
                            'This {subfolder} is linked to {x} {profile groups} and therefore cannot be deleted until '
                                + 'the {profile groups} are removed.',
                            {
                                subfolder: vm.$root.text(
                                    vm.$root.contents.subfolder_title,
                                    null,
                                    false,
                                    'subfolder_title'
                                ),
                                x: vm.profile_groups_count,
                                'profile groups': vm.$root.text(
                                    vm.$root.contents.profile_groups_title,
                                    null,
                                    false,
                                    'profile_groups_title'
                                )
                            },
                            true
                        );
                    } else if (vm.profile_groups_count == 1) {
                        vm.errors = vm.$root.text(
                            'This {subfolder} is linked to 1 {profile group} and therefore cannot be deleted until '
                                + 'the {profile group} is removed.',
                            {
                                subfolder: vm.$root.text(
                                    vm.$root.contents.subfolder_title,
                                    null,
                                    false,
                                    'subfolder_title'
                                ),
                                'profile group': vm.$root.text(
                                    vm.$root.contents.profile_group_title,
                                    null,
                                    false,
                                    'profile_group_title'
                                )
                            },
                            true
                        );
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#subfolder-delete-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            additionalThings: function () {
                let text = 'This action permanently deletes the {subfolder}';
                let params = {
                    subfolder: this.$root.text(
                        this.$root.contents.subfolder_title,
                        null,
                        false,
                        'subfolder_title'
                    )
                };
                if ((this.assets_live_count > 0) || (this.assets_archived_count > 0)) {
                    text += ' with ';
                    if (this.assets_live_count > 0) {
                        if (this.assets_live_count > 1) {
                            text += '{x} live ';
                            params.x = this.assets_live_count;
                        } else {
                            text += '1 live ';
                        }
                    }
                    if ((this.assets_live_count > 0) && (this.assets_archived_count > 0)) {
                        text += 'and '
                    }
                    if (this.assets_archived_count > 0) {
                        if (this.assets_archived_count > 1) {
                            text += '{y} archived ';
                            params.y = this.assets_archived_count;
                        } else {
                            text += '1 archived ';
                        }
                    }
                    if ((this.assets_live_count + this.assets_archived_count) > 1) {
                        text += '{resources}';
                        params.resources = this.$root.text(
                            this.$root.contents.asset_title + 's',
                            null,
                            false,
                            'assets_title'
                        );
                    } else {
                        text += '{resource}';
                        params.resource = this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title');
                    }
                }

                return this.$root.text(text + '.', params, true);
            },
            deleteSubfolder: function () {
                this.deleting = true;
                /**
                 * Send delete subfolder request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/subfolder/' + this.subfolder_id;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.deleted = response.data.message;
                    vm.$root.folders_changed = true;
                    let itemIndex = -1;
                    vm.list_component.items.forEach((item, index) => {
                        if (item.id == vm.subfolder_id) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex > -1) {
                        vm.list_component.items.splice(itemIndex, 1);
                    }

                    setTimeout(function () {
                        $('#subfolder-delete-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#subfolder-delete-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.deleting = false;
                });
            },
            canDelete: function () {
                return (this.profile_groups_count == 0);
            }
        }
    }
</script>
