<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="proof-change-review-alerts-modal-label">
                {{ $root.text('Change time limit per reviewer', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
                
                <div v-if="proof" style="min-height: 250px">
                    <form v-on:submit.prevent="onSubmit()">
                        <div class="form-group">
                            <searchable-option-list
                                :key="'review_alerts_interval-' + ($root.current_language || 0)"
                                v-model="review_alerts_interval"
                                :value="review_alerts_interval"
                                :options="getAlertIntervalOptions()"
                                :field_id="'review_alerts_interval' + proof.id"
                                field_class="mandatory-field"
                                :search_placeholder="$root.text('Select time limit', null, true)"
                                :readonly="updating"
                                v-on:change="$root.unHighlightErrors('review_alerts_interval', proof.id)"
                            ></searchable-option-list>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="!updating">
                <button
                    :key="'save-button'"
                    type="button"
                    class="btn btn-primary"
                    @click="onSubmit()"
                >
                    <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                </button>
            </span>
            <span v-else>
                <button :key="'saving-button'" type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof'],
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                review_alerts_interval: null
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            reset: function () {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.review_alerts_interval = this.proof.review_alerts_interval;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                const data = {
                    title: this.proof.title,
                    leader_alerts: this.proof.leader_alerts,
                    review_alerts_interval: this.review_alerts_interval,
                    target_date: this.proof.target_date,
                    version_target_date: this.proof.version_target_date
                };

                /**
                 * Send update proof request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = response.data.message;

                    // Close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#proof-change-review-alerts-modal').modal('hide');
                    }, 2000);

                    vm.$parent.getProof(vm.proof.id);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            $('#proof-change-review-alerts-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = vm.$root.text('The time limit per reviewer field is required.', null, true);
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    $('#' + key + '-label' + vm.proof.id).addClass('text-danger');
                                    $('#' + key + vm.proof.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            getAlertIntervalOptions: function () {
                const options = [
                    {
                        id: 0,
                        name: this.$root.text('No limit', null, true)
                    },
                    {
                        id: 1,
                        name: this.$root.text('1 hour', null, true)
                    },
                    {
                        id: 2,
                        name: this.$root.text('2 hours', null, true)
                    },
                    {
                        id: 4,
                        name: this.$root.text('4 hours', null, true)
                    },
                    {
                        id: 8,
                        name: this.$root.text('8 hours', null, true)
                    },
                    {
                        id: 24,
                        name: this.$root.text('24 hours', null, true)
                    },
                    {
                        id: 48,
                        name: this.$root.text('48 hours', null, true)
                    },
                    {
                        id: 72,
                        name: this.$root.text('72 hours', null, true)
                    }
                ];

                return options;
            }
        }
    }
</script>
