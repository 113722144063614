var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'card asset-preview select-item' + (_vm.selected ? ' selected' : ' selectable'),on:{"click":function($event){return _vm.selectAsset()}}},[_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":_vm.image_src,"alt":_vm.title}}),_vm._v(" "),(!_vm.selected)?_c('div',{key:'select-overlay',staticClass:"asset-select-overlay"},[_c('div',{staticClass:"triangle"}),_vm._v(" "),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"tick",attrs:{"title":_vm.$root.text(
                    'Select {resource}',
                    { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
                    true
                )}},[_c('i',{staticClass:"fa-regular fa-check"})]),_vm._v(" "),_c('div',{staticClass:"ghost"})]):_c('div',{key:'deselect-overlay',staticClass:"asset-select-overlay deselect"},[_c('div',{staticClass:"triangle"}),_vm._v(" "),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"tick",attrs:{"title":_vm.$root.text(
                    'Deselect {resource}',
                    { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
                    true
                )}},[_c('span',{key:'check-icon',staticClass:"check-icon"},[_c('i',{staticClass:"fa-regular fa-check"})]),_vm._v(" "),_c('span',{key:'minus-icon',staticClass:"minus-icon"},[_c('i',{staticClass:"fa-regular fa-minus"})])]),_vm._v(" "),_c('div',{staticClass:"ghost"})])]),_vm._v(" "),_c('div',{staticClass:"card-body",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"title mb-2",attrs:{"id":_vm.prefix + 'asset-title' + _vm.id}},[_vm._v("\n            "+_vm._s(_vm.short_title)+"\n        ")]),_vm._v(" "),((_vm.short_title != _vm.title))?_c('b-tooltip',{attrs:{"target":_vm.prefix + 'asset-title' + _vm.id,"triggers":"hover"}},[_vm._v("\n            "+_vm._s(_vm.title)+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"asset-info stay"},[_c('span',{staticClass:"float-right",staticStyle:{"position":"relative","top":"2px"}},[_vm._v(_vm._s(_vm.id))]),_vm._v(" "),(_vm.extension)?_c('span',{staticClass:"badge badge-primary font-size-11",staticStyle:{"text-transform":"uppercase"}},[_vm._v("\n                "+_vm._s(_vm.extension)+"\n            ")]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }