<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="skip-review-modal-label">{{ $root.text('Skip reviewer', null, true) }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
            <div v-if="(!errors || isReasonRequired()) && review">
                <div v-if="isReasonRequired()" style="min-height: 200px">
                    <form v-on:submit.prevent="onSubmit()">
                        <div class="form-group">
                            <label for="skip-review-reason" id="skip-review-reason-label" class="mandatory-label">
                                {{ $root.text('Reason for skipping {user_name}',
                                    { user_name: review.first_name + ' ' + review.last_name }, true) }} *
                            </label>
                            <searchable-option-list
                                :key="'skip-review-reason-' + ($root.current_language || 0)"
                                v-model="reason"
                                :value="reason"
                                :options="getReasonOptions()"
                                field_id="skip-review-reason"
                                field_class="mandatory-field"
                                :search_placeholder="$root.text('Select a reason', null, true)"
                                :readonly="skipping"
                                v-on:change="$root.unHighlightErrors('skip-review-reason')"
                            ></searchable-option-list>
                        </div>
                        <div v-if="reason == 'Other'" class="form-group">
                            <label
                                for="skip-review-other_reason"
                                id="skip-review-other_reason-label"
                                class="mandatory-label"
                            >
                                {{ $root.text('Please specify', null, true) }} *
                            </label>
                            <input
                                v-model="other_reason"
                                type="text"
                                id="skip-review-other_reason"
                                class="form-control mandatory-field"
                                :readonly="skipping"
                                v-on:keyup="$root.unHighlightErrors('skip-review-other_reason')"
                                v-on:change="$root.unHighlightErrors('skip-review-other_reason')"
                            >
                        </div>
                    </form>
                </div>
                <div v-else>
                    {{ $root.text('Are you sure you want to skip: {user_name}?',
                        { user_name: review.first_name + ' ' + review.last_name }, true) }}
                </div>
            </div>
        </div>
        <div v-if="!errors || isReasonRequired()" class="modal-footer">
            <div v-if="isReasonRequired()">
                <span v-if="!skipping">
                    <button :key="'skip-button'" type="button" class="btn btn-primary mr-2" @click="skipReview()">
                        <i class="far fa-forward-step mr-1"></i> {{ $root.text('Skip', null, true) }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'skipping-button-1'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ $root.text('Skipping', null, true) }}</span>
                    </button>
                </span>
            </div>
            <div v-else>
                <span v-if="!skipping">
                    <button :key="'yes-button'" type="button" class="btn btn-primary mr-2" @click="skipReview()">
                        <i class="far fa-check mr-1"></i> {{ $root.text('Yes', null, true) }}
                    </button>
                </span>
                <span v-else>
                    <button :key="'skipping-button-2'" type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ $root.text('Skipping', null, true) }}</span>
                    </button>
                </span>
                <button :key="'cancel-button'" type="button" class="btn btn-light" data-dismiss="modal">
                    <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['proof'],
        data () {
            return {
                skipping: false,
                errors: false,
                review: null,
                reason: null,
                other_reason: null
            }
        },
        mounted () {
            //
        },
        watch: {
            reason: function (val) {
                if (val == 'Other') {
                    setTimeout(function () {
                        $('#skip-review-other_reason').focus();
                    }, 500);
                }
            }
        },
        methods: {
            reset: function (review) {
                this.skipping = false;
                this.errors = false;
                this.review = review;
                this.reason = null;
                this.other_reason = null;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            isReasonRequired: function () {
                if (!this.$root.client) {
                    return false;
                }
                if (this.$root.client.proof_skip_reviewer_reason) {
                    return true;
                }

                return false;
            },
            skipReview: function () {
                this.skipping = true;
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send skip review request to API.
                 */
                let data = {
                    user_id: this.review.user_id,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                if (this.isReasonRequired) {
                    data.skip_reason = (this.reason == 'Other') ? this.other_reason : this.reason;
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/' + this.proof.id
                    + '/skip-review';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$parent.loading = true;
                    vm.$parent.getProof(vm.proof.id);
                    $('#skip-review-modal').modal('hide');
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            vm.$root.doLogout();
                            $('#skip-review-modal').modal('hide');

                            return null;
                        }
                        if (error.response.status == 400) {
                            vm.errors = vm.$root.text('Please give a reason for skipping', null, true);
                            if (!vm.reason) {
                                $('#skip-review-reason-label').addClass('text-danger');
                                $('#skip-review-reason').addClass('field-error');

                                return false;
                            }
                            $('#skip-review-other_reason-label').addClass('text-danger');
                            $('#skip-review-other_reason').addClass('field-error');

                            return null;
                        }
                        vm.errors = error.response.data.error
                            || vm.$root.text('Something went wrong, please try again', null, true);
                    }
                })
                .finally(() =>
                    vm.skipping = false
                );
            },
            getReasonOptions: function () {
                const options = [
                    {
                        id: 'On holiday',
                        name: this.$root.text('On holiday', null, true)
                    },
                    {
                        id: 'Out of office',
                        name: this.$root.text('Out of office', null, true)
                    },
                    {
                        id: 'Sick leave',
                        name: this.$root.text('Sick leave', null, true)
                    },
                    {
                        id: 'Other',
                        name: this.$root.text('Other', null, true)
                    }
                ];

                return options;
            }
        }
    }
</script>
