<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="decline-asset-approval-request-modal-label">
                {{ $root.text('Decline approval request:', null, true) }}
                {{ asset_title }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <form>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <label for="reason_for_decline" id="reason_for_decline-label" class="mandatory-label">
                        {{ $root.text("Reason for declining {name}'s request", { name: name }, true) }}
                    </label>
                    <textarea
                        v-model="reason_for_decline"
                        id="reason_for_decline"
                        class="form-control mandatory-field"
                        :readonly="sending"
                    ></textarea>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Send', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Send', null, true) }}</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                reason_for_decline: '',
                asset_approval_id: 0,
                name: '',
                asset_title: ''
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (data) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.reason_for_decline = '';
                this.asset_approval_id = data.asset_approval_id;
                this.name = data.name;
                this.asset_title = data.asset_title;
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                /**
                 * Send decline user request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                    + '/resource-access-request/' + this.asset_approval_id + '/decline';
                const data = {
                    reason_for_decline: this.reason_for_decline,
                    app_info: {
                        app_url: this.$root.$data.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.sent = response.data.message;

                    // Remove from approval list
                    vm.$parent.removeApproval(vm.asset_approval_id);

                    // close after 4 seconds automatically
                    setTimeout(function () {
                        $('#decline-asset-approval-request-modal').modal('hide');
                    }, 4000);

                    // Clear notification.
                    vm.$root.clearNotification('admin', 0, 'resource_access_request', vm.asset_approval_id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    // show error
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            }
        }
    }
</script>
