<template>
    <div style="position: relative; left: -5px; margin-top: 1px">
        <div v-if="$root.client.print_ordering_approval" class="filter">
            <searchable-option-list
                :key="'status-filter-' + ($root.current_language || 0)"
                v-model="status"
                :value="status"
                :options="getStatusOptions()"
                field_id="filter-by-status"
                :search_placeholder="$root.text('Select', null, true)"
                :readonly="isPrintOrdersLoading()"
            ></searchable-option-list>
        </div>
        <div class="filter keyword">
            <form v-on:submit.prevent="keywordSearch()">
                <div class="keyword-field">
                    <div class="input-group">
                        <input
                            v-model="keyword"
                            type="text"
                            class="form-control"
                            id="filter-by-keyword"
                            :placeholder="$root.text('Search by order number', null, true)"
                            aria-label="Search by order number"
                            :readonly="isPrintOrdersLoading()"
                        >
                        <div class="input-group-append">
                            <button class="btn btn-primary big-icon" type="submit" :disabled="isPrintOrdersLoading()">
                                <i class="fal fa-search"></i>
                            </button>
                        </div>
                    </div>
                    <button
                        v-if="keyword && !isPrintOrdersLoading()"
                        class="btn btn-link clear-keyword"
                        @click.prevent="clearKeyword()"
                    >
                        <i class="far fa-times"></i>
                    </button>
                </div>
            </form>
        </div>
        <div v-if="canSelectApprover()" class="filter">
            <searchable-option-list
                :key="'approver-filter-' + ($root.current_language || 0)"
                v-model="approver"
                :value="approver"
                :options="getApproverOptions()"
                field_id="filter-by-approver"
                :search_placeholder="$root.text('Select approver', null, true)"
                :readonly="isPrintOrdersLoading()"
            ></searchable-option-list>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['print_order_filters', 'result_approvers'],
        data() {
            return {
                status: 'all',
                keyword: '',
                approvers: [],
                approver: 0
            };
        },
        mounted () {
            this.print_order_filters.forEach((item) => {
                if (item.key == 'keyword') {
                    this.keyword = item.value;
                }
            });
            this.updateFilters();
        },
        watch: {
            print_order_filters: function (val) {
                this.updateFilters();
            },
            status: function (val) {
                var vm = this;
                if (val === null) {
                    setTimeout(function () {
                        vm.status = 'all';
                    }, 200);

                    return null;
                }
                this.$root.addFilter('print_order_filters', {
                    key: 'status',
                    value: (val == 'all') ? null : val,
                    text: (val == 'awaiting-approval')
                        ? this.$root.text('Awaiting approval', null, true)
                        : this.$root.text(val, null, true),
                    filters_key: 'print_order_filters'
                });
                this.getPrintOrders();
            },
            approver: function (val) {
                var vm = this;
                if (val === null) {
                    setTimeout(function () {
                        vm.approver = 0;
                    }, 200);

                    return null;
                }
                if (!val || (this.approvers.length > 0)) {
                    this.$root.addFilter('print_order_filters', {
                        key: 'approver',
                        value: val ? val : null,
                        text: this.getApproverName(val),
                        filters_key: 'print_order_filters'
                    });
                    this.getPrintOrders();
                }
            },
            result_approvers: function (val) {
                this.getApprovers(val);
            }
        },
        methods: {
            updateFilters: function () {
                let statusFound = 'all';
                let approverFound = 0;
                this.print_order_filters.forEach((item) => {
                    if (item.key == 'status') {
                        statusFound = item.value;
                    }
                    if (item.key == 'approver') {
                        approverFound = item.value;
                    }
                });

                this.status = statusFound;
                this.approver = approverFound;
            },
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                
                let keywordsRemoved = false;
                let filters = [];
                this.$root.print_order_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsRemoved = true;
                    } else {
                        filters.push(item);
                    }
                });
                this.$root.print_order_filters = filters;

                if (!keywordTrimmed) {
                    if (keywordsRemoved) {
                        this.getPrintOrders();
                    }

                    return null;
                }
                this.$root.addFilter('print_order_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'print_order_filters'
                });
                this.getPrintOrders();
            },
            clearKeyword: function () {
                this.keyword = '';
                this.keywordSearch();
                $('#filter-by-keyword').focus();
            },
            getApproverOptions: function () {
                let options = [{
                    id: 0,
                    name: this.$root.text('All approvers', null, true)
                }];
                this.approvers.forEach((item) => {
                    options.push({
                        id: item.id,
                        name: item.first_name + ' ' + item.last_name
                    });
                });

                return options;
            },
            getApprovers: function (result_approvers) {
                if (!result_approvers) {
                    this.approvers = [];

                    return null;
                }
                let userIds = result_approvers;

                if (userIds.length == 0) {
                    this.approvers = [];
                    if (this.approver) {
                        this.approver = 0;
                    }

                    return null;
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|in::' + userIds.join(',');
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.approvers = [];
                    let itemIndex = -1;
                    response.data.users.forEach((item, index) => {
                        vm.approvers.push(item);
                        if (item.id == vm.approver) {
                            itemIndex = index;
                        }
                    });
                    if (itemIndex < 0) {
                        vm.approver = 0;
                    }
                })
                .catch(function (error) {
                    // Do anything else?
                });
            },
            getApproverName: function (approverId) {
                if (!approverId || (this.approvers.length == 0)) {
                    return '';
                }
                let itemIndex = -1;
                this.approvers.forEach((item, index) => {
                    if (item.id == approverId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return this.approvers[itemIndex].first_name + ' ' + this.approvers[itemIndex].last_name;
                }

                return '';
            },
            getPrintOrders: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'print-orders');
                if (component) {
                    component.getOrders();
                }
            },
            isPrintOrdersLoading: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'print-orders');
                if (!component) {
                    return true;
                }

                return component.loading;
            },
            canSelectApprover: function () {
                if (!this.$root.isFeatured('profiles')) {
                    return false;
                }
                if (!this.$root.client.print_ordering_approval) {
                    return false;
                }
                if (this.$route.name != 'print-orders') {
                    return false;
                }
                if (!this.$root.hasPermission('printing_orders_view')) {
                    return false;
                }
                if (!this.$root.hasPermission('printing_view_archived_orders')) {
                    return false;
                }

                return true;
            },
            getStatusOptions: function () {
                let options = [
                    {
                        id: 'all',
                        name: this.$root.text('All orders', null, true)
                    },
                    {
                        id: 'awaiting-approval',
                        name: this.$root.text('Awaiting approval', null, true)
                    },
                    {
                        id: 'approved',
                        name: this.$root.text('Approved', null, true)
                    },
                    {
                        id: 'declined',
                        name: this.$root.text('Declined', null, true)
                    }
                ];
                if (
                    (this.$route.name == 'my-account-print-orders')
                    || this.$root.hasPermission('printing_view_archived_orders')
                ) {
                    options.push({
                        id: 'archived',
                        name: this.$root.text('Archived', null, true)
                    });
                }

                return options;
            }
        }
    }
</script>
<style scoped>
    .filter {
        display: inline-block;
        width: 200px;
        position: relative;
        margin-right: 5px;
        margin-bottom: 5px;
    }

    .filter.keyword {
        width: 300px;
    }

    .keyword-field {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }

    @media (max-width: 575px) {
        .filter, .filter.keyword {
            width: 100%;
            margin-right: 0;
        }
    }
</style>
