<template>
    <div style="position: relative">
        <ul class="nav nav-pills cl" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active" 
                    id="c-comments-tab"
                    data-toggle="tab"
                    href="#c-comments"
                    role="tab"
                    aria-controls="c-comments"
                    aria-selected="true"
                    @click.prevent="onClickTab('c-comments')"
                >{{ $root.text('Comments', null, true) }}</a>
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    id="c-collaborators-tab"
                    data-toggle="tab"
                    href="#c-collaborators"
                    role="tab"
                    aria-controls="c-collaborators"
                    aria-selected="false"
                    @click.prevent="onClickTab('c-collaborators')"
                >{{ $root.text('Collaborators', null, true) }}</a>
            </li>
        </ul>
        <div class="d-md-none contact-links font-size-22" style="position: absolute; top: 1px; right: 3px">
            <a
                v-if="!$parent.is_collaborate_expanded"
                href="#" @click.prevent="$parent.is_collaborate_expanded = true"
            >
                <i class="far fa-chevron-circle-up"></i>
            </a>
            <span v-else>
                <a href="#" @click.prevent="$parent.is_collaborate_expanded = false">
                    <i class="far fa-chevron-circle-down"></i>
                </a>
            </span>
        </div>
        <div class="tab-content pb-1">
            <div
                class="tab-pane fade show active"
                id="c-comments"
                role="tabpanel"
                aria-labelledby="c-comments-tab"
            >
                <div v-if="comments_loading" class="p-3 pb-2">
                    <loading></loading>
                </div>
                <div v-else>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <form v-on:submit.prevent="addComment()" id="collaborate-comment-add" class="pt-3 pl-3 pr-3 pb-2">
                        <div class="form-group mb-2">
                            <textarea
                                v-model="comment"
                                class="form-control mandatory-field"
                                id="collection-comment"
                                rows="3"
                                v-on:focus="commentPlaceholder(true)"
                                :placeholder="commentPlaceholder()"
                                v-on:blur="commentPlaceholder(true)"
                                @keydown="onKeydown($event)"
                                @keyup="onKeyup($event)"
                                :disabled="updating"
                            ></textarea>
                            <div class="dropdown">
                                <div
                                    class="dropdown-menu comment-link"
                                    id="comment-link"
                                    style="max-width: 280px; max-height: 150px; overflow-x: hidden; overflow-y: auto;
                                        top: -20px"
                                >
                                    <a
                                        v-for="option in comment_link_options"
                                        :key="option.id"
                                        :id="'comment-link-option' + option.id"
                                        href="#"
                                        :class="'dropdown-item' + ((option.id == selected_option) ? ' active' : '')"
                                        style="white-space: normal"
                                        @click.prevent="addLink(option)"
                                    >{{ option.name }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-2 text-right">
                            <p v-if="!updating" class="float-left contact-links mb-0">
                                <span
                                    v-if="(collaborators.length > 1)"
                                    class="ml-1 mr-2"
                                    v-b-tooltip.hover.bottom
                                    :title="$root.text('Mention a collaborator', null, true)"
                                >
                                    <a href="#" @click.prevent="focusCommentField('user')">
                                        <i class="far fa-at"></i>
                                    </a>
                                </span>
                                <span
                                    v-if="(assets.length > 0)"
                                    class="ml-1"
                                    v-b-tooltip.hover.bottom
                                    :title="$root.text(
                                        'Link a {resource} from this collection',
                                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                        true
                                    )"
                                >
                                    <a href="#" @click.prevent="focusCommentField('asset')">
                                        <i class="far fa-hashtag"></i>
                                    </a>
                                </span>
                            </p>
                            <span v-if="!updating">
                                <span>
                                    <button
                                        type="submit"
                                        class="btn btn-primary"
                                        @click="addComment()"
                                        :disabled="!comment"
                                        style="width: 86px"
                                    >
                                        <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Post', null, true) }}
                                    </button>
                                </span>
                            </span>
                            <span v-else>
                                <button type="button" class="btn btn-primary">
                                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                    <span style="opacity: 0.5">{{ $root.text('Post', null, true) }}</span>
                                </button>
                            </span>
                        </div>
                    </form>

                    <div v-for="item in comments" :key="item.id" class="pl-3 pr-3">
                        <collaborate-comment
                            :item="item"
                            :comment_link_options="comment_link_options"
                            :selected_option="selected_option"
                            :collaborators="collaborators"
                            :assets="assets"
                        ></collaborate-comment>
                    </div>
                </div>
            </div>
            <div
                class="tab-pane fade"
                id="c-collaborators"
                role="tabpanel"
                aria-labelledby="c-collaborators-tab"
            >
                <div v-if="collaborators_loading" class="p-3 pb-2">
                    <loading></loading>
                </div>
                <div v-else class="p-3 pb-2">
                    <div class="form-group mb-2">
                        <button
                            class="btn btn-secondary btn-block"
                            data-toggle="modal"
                            data-target="#collaborator-add-form-modal"
                            @click="resetCollaboratorAddForm()"
                            :disabled="!collection"
                        >
                            <i class="far fa-user-plus mr-1"></i> {{ $root.text('Add collaborator', null, true) }}
                        </button>
                    </div>

                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div
                        v-for="item in collaborators"
                        :key="'collaborator-' + item.collaborator_id"
                        class="row collaborator"
                    >
                        <div class="col-1" style="min-width: 50px; padding-top: 11px">
                            <div v-if="item.profile_image" class="avatar-xs">
                                <img class="rounded-circle avatar-xs" :src="item.profile_image" alt="avatar">
                            </div>
                            <div v-else class="avatar-xs">
                                <span class="avatar-title rounded-circle">
                                    {{ $root.initial(item.first_name, item.last_name) }}
                                </span>
                            </div>
                        </div>
                        <div class="col pt-2 pb-2">
                            <p class="mb-0">
                                {{ item.first_name + ' ' + item.last_name }}
                                <span
                                    v-if="item.owner"
                                    class="badge badge-primary font-size-11 ml-1"
                                    style="text-transform: uppercase"
                                >
                                    {{ $root.text('Owner', null, true) }}
                                </span>
                            </p>
                            <p class="small text-muted mb-0" style="overflow-wrap: anywhere;">{{ item.email }}</p>
                        </div>
                        <div v-if="!item.owner" class="col-1 font-size-18 contact-links pt-2">
                            <a
                                href="#"
                                class="float-right"
                                @click.prevent="removeCollaborator(item)"
                                v-b-tooltip.hover.left
                                :title="$root.text('Remove collaborator', null, true)"
                            >
                                <i class="far fa-trash-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['collection', 'collaborators', 'collaborators_loading', 'comments', 'comments_loading', 'asset_ids'],
        data () {
            return {
                errors: false,
                tab: 'c-comments',
                comment: '',
                comment_current_rows: {},
                updating: false,
                assets: [],
                comment_link_options: [],
                comment_component: null,
                last_key: null,
                selected_option: null,
                checked_assets_page: 0
            }
        },
        mounted () {
            $(document).on('click', function () {
                $('.comment-link').hide();
            });
            var vm = this;
            $('.collaborate-bar .tab-content').on('scroll', function () {
                vm.rePositionAddCommentForm();
            });
            if (this.comments.length == 0) {
                setTimeout(function () {
                    $('#c-collaborators-tab').click();
                }, 200);
            }
            this.getAssets();
            //
            jQuery.fn.trackRows = function () {
                return this.each(function () {
                    var createMirror = function (textarea) {
                        jQuery(textarea).after('<div class="autogrow-textarea-mirror"></div>');

                        return jQuery(textarea).next('.autogrow-textarea-mirror')[0];
                    }
                    var sendContentToMirror = function (textarea) {
                        mirror.innerHTML = String(textarea.value.substring(0, textarea.selectionStart))
                            .replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#39;').replace(/</g, '&lt;')
                            .replace(/>/g, '&gt;').replace(/\n/g, '<br>') + '.<br>.';
                        calculateRowNumber();
                    }
                    var growTextarea = function () {
                        sendContentToMirror(this);
                    }
                    var calculateRowNumber = function () {
                        vm.comment_current_rows[textAreaId.replace(/-/g, '_')] = Math.round($(mirror).height() / 20) - 1;
                    }
                    // Create a mirror
                    var mirror = createMirror(this);
                    var textAreaId = this.id;
                    // Style the mirror
                    mirror.style.display = 'none';
                    mirror.style.wordWrap = 'break-word';
                    mirror.style.whiteSpace = 'normal';
                    mirror.style.padding = jQuery(this).css('padding');
                    mirror.style.width = jQuery(this).css('width');
                    mirror.style.fontFamily = jQuery(this).css('font-family');
                    mirror.style.fontSize = jQuery(this).css('font-size');
                    mirror.style.lineHeight = jQuery(this).css('line-height');
                    // Bind the textarea's event
                    this.onkeyup = growTextarea;
                    this.onclick = growTextarea;
                });
            };
        },
        watch: {
            tab: function (val) {
                this.errors = false;
                this.rePositionAddCommentForm();
            },
            asset_ids: function (val) {
                this.getAssets();
            },
            comments_loading: function (val) {
                if (!val) {
                    setTimeout(function () {
                        $('#collection-comment').trackRows();
                    }, 500);
                }
            }
        },
        methods: {
            rePositionAddCommentForm: function () {
                let scrollTop = 0;
                if (this.tab == 'c-comments') {
                    scrollTop = $('.collaborate-bar .tab-content')[0].scrollTop;
                }
                $('#collaborate-comment-add').css('top', scrollTop);
            },
            onClickTab: function (tab) {
                this.tab = tab;
                if (!this.$root.is_md_screen) {
                    this.$parent.is_collaborate_expanded = true;
                }
            },
            getAssets: function () {
                if (this.asset_ids.length == this.assets.length) {
                    return null;
                }
                let allAssetsFound = true;
                let assetFound = false;
                this.asset_ids.forEach((assetId) => {
                    assetFound = this.getAsset(assetId);
                    if (assetFound) {
                        this.assets.push(assetFound);
                    } else {
                        allAssetsFound = false;
                    }
                });
                if (!allAssetsFound) {
                    this.checked_assets_page = 1;
                    this.checkAssets();
                }
            },
            getAsset: function (assetId) {
                const items = this.$parent.items;
                let itemIndex = -1;
                items.forEach((item, index) => {
                    if (item.id == assetId) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    return items[itemIndex];
                }

                return false;
            },
            checkAssets: function () {
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/cl::'
                    + this.collection.id + '?page=' + this.checked_assets_page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.total == 0) {
                        return null;
                    }
                    response.data.resources.data.forEach((item) => {
                        let assetIndex = -1;
                        vm.assets.forEach((asset, index) => {
                            if (item.id == asset.id) {
                                assetIndex = index;
                            }
                        });
                        if (assetIndex == -1) {
                            vm.assets.push(item);
                        }
                    });
                    if (response.data.resources.last_page > vm.checked_assets_page) {
                        vm.checked_assets_page++;
                        vm.checkAssets();
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            resetCollaboratorAddForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'collaborator-add-form');
                if (component) {
                    component.resetForm(this.collection.id, this.$root.view_collection_name);
                }
            },
            removeCollaborator: function (item, confirmed = false) {
                if (confirmed) {
                    this.errors = false;

                    /**
                     * Send delete request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-collaborator/'
                        + item;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        if (response.data.user_id == vm.$root.user.id) {
                            vm.$router.push({ name: 'collections' });

                            return null;
                        }
                        vm.$parent.getCollaborators();
                        vm.$parent.getComments();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    });

                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: this.$root.text('Remove collaborator', null, true),
                    text: this.$root.text(
                        'Are you sure you want to remove: {user_name}?',
                        { user_name: item.first_name + ' ' + item.last_name },
                        true
                    ),
                    component: this,
                    action: 'remove-collaborator-' + item.collaborator_id
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                const collaboratorId = action.substr(20);
                this.removeCollaborator(collaboratorId, true);
            },
            commentPlaceholder: function (update = false) {
                const focused = $('#collection-comment').is(':focus');
                if (focused) {
                    if (update) {
                        $('#collection-comment').attr('placeholder', null);
                    }

                    return null;
                }
                const text = this.$root.text('Make a comment', null, true);
                if (update) {
                    $('#collection-comment').attr('placeholder', text);

                    return null;
                }

                return text;
            },
            addComment: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = {
                    comment: this.comment,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection-comment/'
                    + this.collection.id;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.message) {
                        vm.$parent.getComments();
                        vm.comment = '';
                        $('.collaborate-bar .tab-content')[0].scrollTop = 0;
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    //$('#asset-type-' + key + '-label').addClass('text-danger');
                                    $('#collection-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            onKeydown: function (event) {
                this.last_key = event.key;
                if ((event.key != 'ArrowUp') && (event.key != 'ArrowDown') && (event.key != 'Enter')) {
                    return null;
                }
                if (this.lookup(event, true)) {
                    event.preventDefault();
                }
            },
            onKeyup: function (event) {
                this.lookup(event);
            },
            lookup: function (event, test = false) {
                if (event.key == '@') {
                    return this.lookupUser(null, null, false, test);
                }
                if (event.key == '#') {
                    return this.lookupAsset(null, null, false, test);
                }
                let position = $('#collection-comment')[0].selectionStart;
                if (position > 0) {
                    let word = '';
                    let char = '';
                    do {
                        position--;
                        char = this.comment.substr(position, 1).toString();
                        if ((char != '@') && (char != '#') && (char != ' ')) {
                            word = char + word;
                        }
                    } while ((position > 0) && (char != '@') && (char != '#') && (char != ' '));

                    if (char == '@') {
                        return this.lookupUser(null, word.toLowerCase(), false, test);
                    }
                    if (char == '#') {
                        return this.lookupAsset(null, word.toLowerCase(), false, test);
                    }
                }
                $('.comment-link').hide();

                return false;
            },
            focusCommentField: function (lookup = null) {
                $('#collection-comment').focus();
                if (!lookup) {
                    return null;
                }
                const symbol = (lookup == 'user') ? '@' : '#';
                const position = $('#collection-comment')[0].selectionStart || this.comment.length;
                const text1 = this.comment.substr(0, position);
                const text2 = this.comment.substr(position);
                this.comment = text1 + symbol + text2;
                var vm = this;
                var focusPosition = position + 1;
                if (lookup == 'user') {
                    setTimeout(function () {
                        $('#collection-comment')[0].selectionEnd = focusPosition;
                        vm.lookupUser();
                    }, 200);
                }
                if (lookup == 'asset') {
                    setTimeout(function () {
                        $('#collection-comment')[0].selectionEnd = focusPosition;
                        vm.lookupAsset();
                    }, 200);
                }
            },
            lookupUser: function (component = null, keyword = null, isReply = false, test = false) {
                if (this.collaborators.length < 2) {
                    return false;
                }
                this.comment_component = component;
                this.comment_link_options = [];
                this.collaborators.forEach((item) => {
                    if (item.user_id != this.$root.user.id) {
                        if (
                            !keyword
                            || (item.first_name.toLowerCase().indexOf(keyword) > -1)
                            || (item.last_name.toLowerCase().indexOf(keyword) > -1)
                            || (item.email.toLowerCase().indexOf(keyword) > -1)
                        ) {
                            this.comment_link_options.push({
                                id: item.user_id,
                                name: item.first_name + ' ' + item.last_name,
                                link: item.first_name + ' ' + item.last_name,
                                symbol: '@'
                            });
                        }
                    }
                });

                if (this.comment_link_options.length == 0) {
                    if (!test) {
                        if (this.comment_component) {
                            if (isReply) {
                                $('#reply-comment-link-' + this.comment_component.item.id).hide();
                            } else {
                                $('#comment-link-' + this.comment_component.item.id).hide();
                            }
                        } else {
                            $('.comment-link').hide();
                        }
                        this.selected_option = null;
                        this.last_key = null;
                    }

                    return false;
                }
                if (test) {
                    return true;
                }
                var vm = this;
                setTimeout(function () {
                    vm.rePositionCommentLink(isReply);
                }, 100);
            },
            lookupAsset: function (component = null, keyword = null, isReply = false, test = false) {
                if (this.assets.length < 1) {
                    return false;
                }
                this.comment_component = component;
                this.comment_link_options = [];
                this.assets.forEach((item) => {
                    if (
                        !keyword
                        || (item.id.toString().indexOf(keyword) > -1)
                        || (item.title.toLowerCase().indexOf(keyword) > -1)
                    ) {
                        this.comment_link_options.push({
                            id: item.id,
                            name: item.id + ' - ' + item.title,
                            link: item.id,
                            symbol: '#'
                        });
                    }
                });

                if (this.comment_link_options.length == 0) {
                    if (!test) {
                        if (this.comment_component) {
                            if (isReply) {
                                $('#reply-comment-link-' + this.comment_component.item.id).hide();
                            } else {
                                $('#comment-link-' + this.comment_component.item.id).hide();
                            }
                        } else {
                            $('.comment-link').hide();
                        }
                        this.selected_option = null;
                        this.last_key = null;
                    }

                    return false;
                }
                if (test) {
                    return true;
                }
                var vm = this;
                setTimeout(function () {
                    vm.rePositionCommentLink(isReply);
                }, 100);
            },
            rePositionCommentLink: function (isReply) {
                let position = 0;
                let scrollTop = 0;
                if (this.comment_component) {
                    let commentId = null;
                    let currentRows = 0;
                    if (isReply) {
                        commentId = '#collection-reply-comment-' + this.comment_component.item.id;
                        currentRows =
                            this.comment_current_rows['collection_reply_comment_' + this.comment_component.item.id] || 1;
                        position = Math.round($(commentId).innerHeight() / 20) - currentRows;
                        scrollTop = $('#collection-reply-comment-' + this.comment_component.item.id)[0].scrollTop;
                        $('#reply-comment-link-' + this.comment_component.item.id).css(
                            'top',
                            '-' + (position * 20 - 13 + scrollTop) + 'px'
                        );
                        $('#reply-comment-link-' + this.comment_component.item.id).show();
                    } else {
                        commentId = '#collection-comment-' + this.comment_component.item.id;
                        currentRows =
                            this.comment_current_rows['collection_comment_' + this.comment_component.item.id] || 1;
                        position = Math.round($(commentId).innerHeight() / 20) - currentRows;
                        scrollTop = $('#collection-comment-' + this.comment_component.item.id)[0].scrollTop;
                        $('#comment-link-' + this.comment_component.item.id).css(
                            'top',
                            '-' + (position * 20 - 13 + scrollTop) + 'px'
                        );
                        $('#comment-link-' + this.comment_component.item.id).show();
                    }
                    this.scrollToComment(this.comment_component.item.id);
                } else {
                    position = Math.round($('#collection-comment').innerHeight() / 20)
                        - (this.comment_current_rows.collection_comment || 1);
                    scrollTop = $('#collection-comment')[0].scrollTop;
                    $('#comment-link').css('top', '-' + (position * 20 - 13 + scrollTop) + 'px');
                    $('#comment-link').show();
                }
                ////
                let currentIndex = -1;
                let prevIndex = -1;
                let nextIndex = -1;
                if (this.selected_option) {
                    this.comment_link_options.forEach((option, index) => {
                        if (option.id == this.selected_option) {
                            currentIndex = index;
                        } else {
                            if (currentIndex == -1) {
                                prevIndex = index;
                            }
                            if ((currentIndex > -1) && (nextIndex == -1)) {
                                nextIndex = index;
                            }
                        }
                    });
                    if (currentIndex == -1) {
                        this.selected_option = null;
                    }
                }
                if (!this.selected_option) {
                    this.selected_option = this.comment_link_options[0].id;
                } else {
                    if ((this.last_key == 'ArrowDown') && (nextIndex > -1)) {
                        this.selected_option = this.comment_link_options[nextIndex].id;
                        this.scrollToCommentLinkOption(isReply);
                    }
                    if ((this.last_key == 'ArrowUp') && (prevIndex > -1)) {
                        this.selected_option = this.comment_link_options[prevIndex].id;
                        this.scrollToCommentLinkOption(isReply);
                    }
                    if ((this.last_key == 'Enter') && (currentIndex > -1)) {
                        this.selected_option = null;
                        this.last_key = null;
                        this.addLink(this.comment_link_options[currentIndex]);
                        if (this.comment_component) {
                            if (isReply) {
                                $('#reply-comment-link-' + this.comment_component.item.id).hide();
                            } else {
                                $('#comment-link-' + this.comment_component.item.id).hide();
                            }
                        } else {
                            $('.comment-link').hide();
                        }
                        this.selected_option = null;
                        this.last_key = null;
                    }
                }
            },
            scrollToCommentLinkOption: function (isReply) {
                let scrollTo = 0;
                let found = false;
                this.comment_link_options.forEach((option) => {
                    if (!found) {
                        if (option.id != this.selected_option) {
                            if (this.comment_component) {
                                if (isReply) {
                                    scrollTo += $(
                                        '#reply-comment-link-' + this.comment_component.item.id + '-option' + option.id
                                    ).outerHeight();
                                } else {
                                    scrollTo += $(
                                        '#comment-link-' + this.comment_component.item.id + '-option' + option.id
                                    ).outerHeight();
                                }
                            } else {
                                scrollTo += $('#comment-link-option' + option.id).outerHeight();
                            }
                        } else {
                            found = true;
                        }
                    }
                });
                if (this.comment_component) {
                    if (isReply) {
                        $('#reply-comment-link-' + this.comment_component.item.id).animate({ scrollTop: scrollTo }, 350);
                    } else {
                        $('#comment-link-' + this.comment_component.item.id).animate({ scrollTop: scrollTo }, 350);
                    }
                } else {
                    $('#comment-link').animate({ scrollTop: scrollTo }, 200);
                }
            },
            addLink: function (option) {
                let position = 0;
                let text1 = '';
                let text2 = '';
                if (this.comment_component) {
                    if (this.comment_component.replying) {
                        position = $('#collection-reply-comment-' + this.comment_component.item.id)[0].selectionStart
                            || this.comment_component.reply_comment.length;
                        text1 = this.comment_component.reply_comment.substr(0, position);
                        text2 = this.comment_component.reply_comment.substr(position);
                    } else {
                        position = $('#collection-comment-' + this.comment_component.item.id)[0].selectionStart
                            || this.comment_component.comment.length;
                        text1 = this.comment_component.comment.substr(0, position);
                        text2 = this.comment_component.comment.substr(position);
                    }
                } else {
                    position = ($('#collection-comment')[0].selectionStart || this.comment.length);
                    text1 = this.comment.substr(0, position);
                    text2 = this.comment.substr(position);
                }

                if (text1.length == 0) {
                    text1 = '[' + option.symbol;
                } else {
                    let word = '';
                    let char = '';
                    do {
                        position--;
                        char = text1.substr(position, 1).toString();
                        if (char != ' ') {
                            word = char + word;
                        }
                    } while ((position > 0) && (char != option.symbol) && (char != ' '));

                    if (char == option.symbol) {
                        text1 = text1.substr(0, (text1.length - word.length));
                    }
                    if (
                        (text1.length > 0)
                        && (text1.substr(text1.length - 1, 1) != ' ')
                        && (text1.substr(text1.length - 1, 1) != '[')
                        && (text1.substr(text1.length - 1, 1) != '\n')
                    ) {
                        text1 += ' ';
                    }
                    if (text1.substr(text1.length - 1, 1) != '[') {
                        text1 += '[';
                    }
                    text1 += option.symbol;
                }
                
                if ((text2.length == 0) || (text2.substr(0, 1) != ' ')) {
                    text2 = ' ' + text2;
                }
                text2 = ']' + text2;

                if (this.comment_component) {
                    if (this.comment_component.replying) {
                        this.comment_component.reply_comment = text1 + option.link + text2;
                        $('#collection-reply-comment-' + this.comment_component.item.id).focus();

                        return null;
                    }
                    this.comment_component.comment = text1 + option.link + text2;
                    $('#collection-comment-' + this.comment_component.item.id).focus();

                    return null;
                }
                this.comment = text1 + option.link + text2;
                $('#collection-comment').focus();
            },
            scrollToComment: function (id, delay = true) {
                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.scrollToComment(id, false);
                    }, 100);

                    return null;
                }
                let scrollTop = $('#collaborate-comment-add').outerHeight();
                let found = false;
                let repliesScrollTop = 0;
                let replyFound = false;
                this.comments.forEach((item) => {
                    if (item.id == id) {
                        found = true;
                    }
                    if (item.replies.length > 0) {
                        repliesScrollTop = 0;
                        replyFound = false;
                        item.replies.forEach((reply) => {
                            if (reply.id == id) {
                                replyFound = true;
                            }
                            if (!replyFound) {
                                repliesScrollTop += $('#collaborate-comment-' + reply.id).outerHeight();
                            }
                        });
                        if (replyFound) {
                            found = true;
                            scrollTop += $('#collaborate-original-comment-' + item.id).outerHeight();
                            scrollTop += $('#collaborate-reply-comment-add-' + item.id).outerHeight() || 0;
                            scrollTop += repliesScrollTop;
                        }
                    }
                    if (!found) {
                        scrollTop += $('#collaborate-comment-' + item.id).outerHeight();
                    }
                });
                $('.collaborate-bar .tab-content').animate({ scrollTop: scrollTop }, 700);
            }
        }
    }
</script>
<style scoped>
    #collaborate-comment-add {
        position: relative;
        background-color: #fff;
        z-index: 2;
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    }

    #collaborate-comment-add.static {
        position: static;
    }
</style>
