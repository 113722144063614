var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"folder-delete-modal-label"}},[_vm._v("\n            "+_vm._s(_vm.$root.text(
                'Delete {folder}:',
                { folder: _vm.$root.text(_vm.$root.contents.folder_title, null, false, 'folder_title') },
                true
            ))+"\n            "+_vm._s(_vm.folder_name)+"\n        ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.deleted)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col",domProps:{"innerHTML":_vm._s(_vm.deleted)}})])]):_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.deleteFolder()}}},[(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),(_vm.loading)?_c('div',[_c('loading')],1):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.canDelete())?_c('div',[_c('p',[_vm._v(_vm._s(_vm.additionalThings()))]),_vm._v(" "),((_vm.assets_live_count + _vm.assets_archived_count + _vm.custom_fields_count) > 0)?_c('p',[(((_vm.assets_live_count + _vm.assets_archived_count) > 0) && (_vm.custom_fields_count > 0))?_c('span',[_vm._v("\n                        "+_vm._s(_vm.$root.text(
                            'If you need to manage {resources} or custom fields you should do this first.',
                            {
                                resources: _vm.$root.text(
                                    this.$root.contents.asset_title + 's',
                                    null,
                                    false,
                                    'assets_title'
                                ),
                            },
                            true
                        ))+"\n                    ")]):_vm._e(),_vm._v(" "),(((_vm.assets_live_count + _vm.assets_archived_count) > 0) && (_vm.custom_fields_count == 0))?_c('span',[_vm._v("\n                        "+_vm._s(_vm.$root.text(
                            'If you need to manage {resources} you should do this first.',
                            {
                                resources: _vm.$root.text(
                                    this.$root.contents.asset_title + 's',
                                    null,
                                    false,
                                    'assets_title'
                                ),
                            },
                            true
                        ))+"\n                    ")]):_vm._e(),_vm._v(" "),(((_vm.assets_live_count + _vm.assets_archived_count) == 0) && (_vm.custom_fields_count > 0))?_c('span',[_vm._v("\n                        "+_vm._s(_vm.$root.text('If you need to manage custom fields you should do this first.', null, true))+"\n                    ")]):_vm._e()]):_vm._e(),_vm._v(" "),((_vm.assets_live_count > 0))?_c('p',[_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'You can move live {resources} to other {folders} by adding them to a collection and using '
                            + 'the Bulk actions function.',
                        {
                            resources: _vm.$root.text(
                                this.$root.contents.asset_title + 's',
                                null,
                                false,
                                'assets_title'
                            ),
                            folders: _vm.$root.text(_vm.$root.contents.folders_title, null, false, 'folders_title')
                        }
                    ))+"\n                ")]):_vm._e()]):_vm._e()])]),_vm._v(" "),(!_vm.loading && !_vm.deleted && _vm.canDelete())?_c('div',{staticClass:"modal-footer"},[(!_vm.deleting)?_c('span',[_c('span',[_c('button',{staticClass:"btn btn-primary mr-2",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.deleteFolder()}}},[_c('i',{staticClass:"far fa-trash-alt mr-1"}),_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'Delete {folder}',
                        { folder: _vm.$root.text(_vm.$root.contents.folder_title, null, false, 'folder_title') },
                        true
                    ))+"\n                ")])])]):_c('span',[_c('button',{staticClass:"btn btn-primary mr-2",attrs:{"type":"button"}},[_c('i',{staticClass:"fal fa-spinner-third fa-spin mr-1"}),_vm._v(" "),_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v("\n                    "+_vm._s(_vm.$root.text(
                        'Delete {folder}',
                        { folder: _vm.$root.text(_vm.$root.contents.folder_title, null, false, 'folder_title') },
                        true
                    ))+"\n                ")])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-light",attrs:{"type":"button","data-dismiss":"modal","disabled":_vm.deleting}},[_c('i',{staticClass:"far fa-times mr-1"}),_vm._v(" "+_vm._s(_vm.$root.text('Cancel', null, true))+"\n        ")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1",staticStyle:{"max-width":"30px"}},[_c('i',{staticClass:"far fa-check"})])
}]

export { render, staticRenderFns }