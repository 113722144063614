<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="artwork-preview-zoom-tooltip-modal-label">
                {{ $root.text(
                    'New {template} viewing tools',
                    { template: $root.text($root.contents.template_title, null, false, 'template_title') },
                    true
                ) }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-3" style="max-width: 69px">
                    <span :key="'zoom-in-button-wrapper'">
                        <button
                            :key="'zoom-in-button'"
                            class="btn btn-secondary font-size-20 btn-block mb-2"
                        ><i class="fa-light fa-magnifying-glass-plus"></i></button>
                    </span>
                </div>
                <div class="col">
                    <h6 class="font-size-14 mt-1 mb-1">
                        {{ $root.text('Multi-click zoom - check the small print!', null, true) }}
                    </h6>
                    <p class="mb-0">{{ $root.text('Click on the preview to move around the screen', null, true) }}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-3" style="max-width: 69px">
                    <span :key="'zoom-out-button-wrapper'">
                        <button
                            :key="'zoom-out-button'"
                            class="btn btn-secondary font-size-20 btn-block mb-2"
                        ><i class="fa-light fa-magnifying-glass-minus"></i></button>
                    </span>
                </div>
                <div class="col">
                    <h6 class="font-size-14" style="margin-top: 14px">{{ $root.text('Zoom out', null, true) }}</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-3" style="max-width: 69px">
                    <span :key="'fit-screen-button-wrapper'">
                        <button
                            :key="'fit-screen-button'"
                            class="btn btn-secondary font-size-20 btn-block"
                        ><i class="fa-light fa-expand"></i></button>
                    </span>
                </div>
                <div class="col">
                    <h6 class="font-size-14" style="margin-top: 14px">
                        {{ $root.text('Reset to original view', null, true) }}
                    </h6>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        methods: {
            doNotShowAgain: function () {
                this.$parent.preview_zoom_tooltip_seen = true;
                this.$root.setCookie(this.$root.app.client_id + '_apztt' + this.$root.user.id, 1, '1Y');
            }
        }
    }
</script>
