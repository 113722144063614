<template>
    <div>
        <form v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label
                            :for="'name' + asset_type.id"
                            :id="'name-label' + asset_type.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Name', null, true) }} *
                        </label>
                        <input
                            v-model="asset_type.name"
                            type="text"
                            :id="'name' + asset_type.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('name', asset_type.id)"
                            v-on:change="$root.unHighlightErrors('name', asset_type.id)"
                        >
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>
                            {{ $root.text('{resources} require approval before download by default',
                                { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title') },
                                true) }}
                        </label>
                        <div class="form-control" style="height: auto">
                            <div class="mr-4" style="display: inline-block">
                                <div class="custom-control custom-radio">
                                    <input
                                        v-model="asset_type.restricted"
                                        type="radio"
                                        :id="'restricted' + asset_type.id + '-0'"
                                        class="custom-control-input"
                                        :value="0"
                                        :disabled="updating"
                                    >
                                    <label class="custom-control-label" :for="'restricted' + asset_type.id + '-0'">
                                        {{ $root.text('No', null, true) }}
                                    </label>
                                </div>
                            </div>
                            <div style="display: inline-block">
                                <div class="custom-control custom-radio">
                                    <input
                                        v-model="asset_type.restricted"
                                        type="radio"
                                        :id="'restricted' + asset_type.id + '-1'"
                                        class="custom-control-input"
                                        :value="1"
                                        :disabled="updating"
                                    >
                                    <label class="custom-control-label" :for="'restricted' + asset_type.id + '-1'">
                                        {{ $root.text('Yes', null, true) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="asset_type.active"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'active' + asset_type.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'active' + asset_type.id">
                                {{ $root.text('Active', null, true) }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col"></div>
            </div>

            <div class="row">
                <div class="col">
                    <span v-if="!updating">
                        <button
                            :key="'save'"
                            type="submit"
                            class="btn btn-primary mr-2"
                            :disabled="updating"
                        >
                            <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                        </button>
                    </span>
                    <span v-else>
                        <button :key="'save-spinner'" type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                        </button>
                    </span>

                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-toggle="modal"
                        data-target="#asset-type-delete-modal"
                        @click="resetDeleteAssetType()"
                        :disabled="updating"
                    >
                        <i class="far fa-trash-alt mr-1"></i> {{ $root.text('Delete', null, true) }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['data', 'list_component'],
        data () {
            return {
                errors: false,
                updating: false,
                asset_type: {},
                original_data: {}
            }
        },
        mounted () {
            if (this.data) {
                this.asset_type = this.data;
                this.updateOriginalData();
            }
        },
        watch: {
            data: function (val) {
                this.asset_type = val;
                this.updateOriginalData();
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update asset type request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource-type/'
                    + this.asset_type.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.asset_type,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.list_component.$parent.message = {
                        type: 'success',
                        title: vm.$root.text(
                            'Update {resource} type',
                            { resource: vm.$root.text(vm.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        ),
                        text: vm.$root.text(
                            '{resource} type updated',
                            { resource: vm.$root.text(vm.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        )
                    };
                    $('#message-modal').modal('show');

                    // close after 2 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                        if (vm.asset_type.active != vm.original_data.active) {
                            vm.list_component.getAssetTypes();
                        }
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += vm.$root.parseApiErrors(key, value);
                                $('#' + key + '-label' + vm.asset_type.id).addClass('text-danger');
                                $('#' + key + vm.asset_type.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            updateOriginalData: function () {
                for (const [key, value] of Object.entries(this.asset_type)) {
                    this.original_data[key] = value;
                }
            },
            resetDeleteAssetType: function () {
                const component = this.$root.findComponent(this.list_component, 'asset-type-delete');
                if (component) {
                    component.reset(this.asset_type);
                }
            }
        }
    }
</script>
<style scoped>
    .custom-control {
        z-index: 0;
    }
</style>
