<template>
    <div>
        <div class="row no-gutters pl-3 pt-3 pr-3">
            <div class="col-5 pt-1">
                <div
                    class="font-size-16"
                    v-html="title"
                    :style="'height: ' + (chart_height - 25) + 'px; font-weight: 500; line-height: 1'"
                ></div>
                <h4 class="mb-0">{{ total }}</h4>                                            
            </div>
            <div class="col-7 pr-0">
                <apex-spline-area-chart :height="chart_height" :data="chart_data"></apex-spline-area-chart>
            </div>
        </div>
        <hr>
        <div class="pr-3 pb-3 pl-3">
            <span v-if="percent >= 0" :key="'increase'">
                <span class="badge badge-soft-success mr-1">
                    <i class="far fa-arrow-trend-up mr-1"></i> {{ percent.toFixed(2) }}%
                </span>
                {{ $root.text('Increase on previous 30 days', null, true) }}
            </span>
            <span v-else :key="'decrease'">
                <span class="badge badge-soft-danger mr-1">
                    <i class="far fa-arrow-trend-down mr-1"></i> {{ Math.abs(percent.toFixed(2)) }}%
                </span>
                {{ $root.text('Decrease on previous 30 days', null, true) }}
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['chart_height', 'chart_data', 'title', 'total', 'increase_percent'],
        data () {
            return {
                percent: parseFloat(this.increase_percent)
            }
        },
        mounted () {
            //
        },
        watch: {
            increase_percent: function (val) {
                this.percent = parseFloat(val);
            }
        }
    }
</script>
