<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="user-add-form-modal-label">{{ modalTitle() }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated">
                <div class="alert alert-success" role="alert">
                    <div class="row">
                        <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                        <div class="col" v-html="updated"></div>
                    </div>
                </div>
                <form v-if="invite_link">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{ $root.text('Invite link', null, true) }}</span>
                        </div>
                        <input
                            v-model="invite_link"
                            type="text"
                            id="invite-link"
                            class="form-control"
                            :readonly="true"
                        >
                        <div class="input-group-append">
                            <button
                                class="btn btn-secondary"
                                type="button"
                                @click="copyLinkToClipBoard()"
                            >
                                {{ copy_link_button_text }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else :style="(isUserAdmin() && !is_supplier) ? 'min-height: 450px' : ''">
                <div v-if="errors" class="alert alert-danger" role="alert">
                    <span v-html="errors"></span>
                    <span v-if="show_link_to_users">
                        <a href="#" @click.prevent="goToUsers()">{{ show_link_to_users }}</a>.
                    </span>
                </div>

                <div v-if="isFormReady()">
                    <form v-on:submit.prevent="onSubmit()">
                        <div class="row">
                            <div
                                :class="'col-lg-' + (!is_supplier ? '4' : '6')"
                            >
                                <div class="form-group">
                                    <label for="first_name" id="name-label" class="mandatory-label">
                                        {{ $root.text('Name', null, true) }} *
                                    </label>
                                    <div class="input-group">
                                        <input
                                            v-model="user.first_name"
                                            type="text"
                                            id="first_name"
                                            class="form-control mandatory-field"
                                            :readonly="updating"
                                            :placeholder="placeholder('first_name')"
                                            v-on:focus="placeholder('first_name', true)"
                                            v-on:blur="placeholder('first_name', true)"
                                            v-on:keyup="unHighlightNameErrors()"
                                            v-on:change="unHighlightNameErrors()"
                                        >
                                        <input
                                            v-model="user.last_name"
                                            type="text"
                                            id="last_name"
                                            class="form-control mandatory-field"
                                            :readonly="updating"
                                            :placeholder="placeholder('last_name')"
                                            v-on:focus="placeholder('last_name', true)"
                                            v-on:blur="placeholder('last_name', true)"
                                            v-on:keyup="unHighlightNameErrors()"
                                            v-on:change="unHighlightNameErrors()"
                                        >
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="job_title" id="job_title-label" class="mandatory-label">
                                        {{ $root.text('Job title', null, true) }} <span v-if="isUserAdmin()">*</span>
                                    </label>
                                    <input
                                        v-model="user.job_title"
                                        type="text"
                                        id="job_title"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        v-on:keyup="$root.unHighlightErrors('job_title')"
                                        v-on:change="$root.unHighlightErrors('job_title')"
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="company" id="company-label" class="mandatory-label">
                                        <span v-if="is_supplier">
                                            {{ $root.text('company', null, true) }}
                                        </span>
                                        <span v-else>
                                            {{ $root.text($root.contents.company_title, null, true, 'company_title') }}
                                        </span>
                                        <span v-if="isUserAdmin()">*</span>
                                    </label>
                                    <input
                                        v-model="user.company"
                                        type="text"
                                        id="company"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        v-on:keyup="$root.unHighlightErrors('company')"
                                        v-on:change="$root.unHighlightErrors('company')"
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="email" id="email-label" class="mandatory-label">
                                        {{ $root.text('Email', null, true) }} *
                                    </label>
                                    <input
                                        v-model="user.email"
                                        type="email"
                                        id="email"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        v-on:keyup="$root.unHighlightErrors('email')"
                                        v-on:change="$root.unHighlightErrors('email')"
                                        v-on:blur="checkEmail()"
                                    >
                                </div>
                                <div class="form-group">
                                    <label for="telephone" id="telephone-label" class="mandatory-label">
                                        {{ $root.text('Telephone', null, true) }} *
                                    </label>
                                    <input
                                        v-model="user.telephone"
                                        type="text"
                                        id="telephone"
                                        class="form-control mandatory-field"
                                        :readonly="updating"
                                        v-on:keyup="$root.unHighlightErrors('telephone')"
                                        v-on:change="$root.unHighlightErrors('telephone')"
                                    >
                                </div>
                            </div>
                            <div
                                :class="'col-lg-' + (!is_supplier ? '4' : '6')"
                            >
                                <div class="form-group">
                                    <label for="role" id="role-label" class="mandatory-label">
                                        {{ $root.text('User role', null, true) }} <span v-if="isUserAdmin()">*</span>
                                    </label>
                                    <searchable-option-list
                                        :key="'role-' + ($root.current_language || 0)"
                                        v-model="user.role"
                                        :value="user.role"
                                        :options="user_roles"
                                        field_id="role"
                                        field_class="mandatory-field"
                                        :readonly="!isUserAdmin() || updating"
                                        :search_placeholder="$root.text('Select a role', null, true)"
                                        v-on:change="$root.unHighlightErrors('role')"
                                    ></searchable-option-list>
                                    <small id="role-description" class="form-text text-muted">
                                        {{ roleDescription() }}
                                    </small>
                                </div>
                                <div v-if="isUserAdmin() && !is_supplier" class="form-group">
                                    <label for="user_groups">
                                        {{ $root.text('Assigned to user group(s)', null, true) }}
                                    </label>
                                    <searchable-option-list
                                        :key="'user-groups-' + ($root.current_language || 0)"
                                        v-model="user.user_groups"
                                        :value="user.user_groups"
                                        :multiple="true"
                                        :options="user_groups"
                                        field_id="user_groups"
                                        field_class="form-control pb-2"
                                        :readonly="updating"
                                        :search_placeholder="$root.text('Select user group(s)', null, true)"
                                    ></searchable-option-list>
                                </div>
                                <div v-if="$root.isFeatured('profiles') && !is_supplier" class="form-group">
                                    <label for="profile_group_id" id="profile_group_id-label" class="mandatory-label">
                                        {{ $root.text($root.contents.profile_group_title, null, true,
                                            'profile_group_title') }}
                                        <span v-if="isUserAdmin()">*</span>
                                    </label>
                                    <searchable-option-list
                                        :key="'profile-group-' + ($root.current_language || 0)"
                                        v-model="user.profile_group_id"
                                        :value="user.profile_group_id"
                                        :options="profile_groups"
                                        field_id="profile_group_id"
                                        field_class="mandatory-field"
                                        :readonly="!isUserAdmin() || updating"
                                        :search_placeholder="$root.text(
                                            'Select a {profile group}',
                                            {
                                                'profile group': $root.text(
                                                    $root.contents.profile_group_title,
                                                    null,
                                                    false,
                                                    'profile_group_title'
                                                )
                                            },
                                            true
                                        )"
                                        v-on:change="onProfileGroupChange()"
                                    ></searchable-option-list>
                                </div>
                                <div v-if="$root.isFeatured('profiles') && ! is_supplier" class="form-group">
                                    <label for="profile_id" id="profile_id-label" class="mandatory-label">
                                        {{ $root.text($root.contents.profile_title, null, true) }} *
                                    </label>
                                    <searchable-option-list
                                        :key="'profile-' + ($root.current_language || 0)"
                                        v-model="user.profile_id"
                                        :value="user.profile_id"
                                        :options="getProfileOptions(user.profile_group_id)"
                                        field_id="profile_id"
                                        field_class="mandatory-field"
                                        :readonly="!canSelectProfile()"
                                        :search_placeholder="$root.text('Select a ' + $root.contents.profile_title,
                                            null, true)"
                                        v-on:change="$root.unHighlightErrors('profile_id')"
                                    ></searchable-option-list>
                                </div>
                                <div v-if="$root.isFeatured('printing') && is_supplier" class="form-group">
                                    <label for="currency" id="currency-label" class="mandatory-label">
                                        {{ $root.text('Currency', null, true) }} <span v-if="isUserAdmin()">*</span>
                                    </label>
                                    <searchable-option-list
                                        :key="'currency-' + ($root.current_language || 0)"
                                        v-model="user.currency"
                                        :value="user.currency"
                                        :options="currencies"
                                        field_id="currency"
                                        field_class="mandatory-field"
                                        :readonly="!isUserAdmin() || updating"
                                        :search_placeholder="$root.text('Select a currency', null, true)"
                                        v-on:change="$root.unHighlightErrors('currency')"
                                    ></searchable-option-list>
                                </div>
                                <div v-if="isUserAdmin()" class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input
                                            v-model="user.exclude_from_reports"
                                            id="exclude_from_reports"
                                            type="checkbox"
                                            class="custom-control-input"
                                            :disabled="updating"
                                        >
                                        <label class="custom-control-label" for="exclude_from_reports">
                                            {{ $root.text('Exclude this user from reports', null, true) }}
                                        </label>
                                    </div>
                                    <small id="exclude_from_reports-description" class="form-text text-muted">
                                        {{ excludeFromReportsDescription() }}
                                    </small>
                                </div>
                            </div>
                            <div v-if="!is_supplier" class="col-lg-4">
                                <div v-if="$root.isFeatured('printing')" class="form-group">
                                    <label for="currency" id="currency-label" class="mandatory-label">
                                        {{ $root.text('Currency', null, true) }} <span v-if="isUserAdmin()">*</span>
                                    </label>
                                    <searchable-option-list
                                        :key="'currency2-' + ($root.current_language || 0)"
                                        v-model="user.currency"
                                        :value="user.currency"
                                        :options="currencies"
                                        field_id="currency"
                                        field_class="mandatory-field"
                                        :readonly="!isUserAdmin() || updating"
                                        :search_placeholder="$root.text('Select a currency', null, true)"
                                        v-on:change="$root.unHighlightErrors('currency')"
                                    ></searchable-option-list>
                                </div>
                                <div v-if="$root.client.company_contact_details" class="form-group">
                                    <label for="company_contact_name" id="company_contact_name-label">
                                        {{ $root.text('Contact at {company_name}', { company_name: company_name },
                                            true) }}
                                    </label>
                                    <input
                                        v-model="user.company_contact_details.name"
                                        type="text"
                                        id="company_contact_name"
                                        class="form-control"
                                        :readonly="updating"
                                    >
                                </div>
                                <div v-if="$root.client.company_contact_details" class="form-group">
                                    <label for="company_contact_email" id="company_contact_email-label">
                                        {{ $root.text("Contact's email address", null, true) }}
                                    </label>
                                    <input
                                        v-model="user.company_contact_details.email"
                                        type="email"
                                        id="company_contact_email"
                                        class="form-control"
                                        :readonly="updating"
                                    >
                                </div>
                                <div v-if="$root.client.company_contact_details" class="form-group">
                                    <label for="company_contact_phone" id="company_contact_phone-label">
                                        {{ $root.text("Contact's telephone", null, true) }}
                                    </label>
                                    <input
                                        v-model="user.company_contact_details.phone"
                                        type="text"
                                        id="company_contact_phone"
                                        class="form-control"
                                        :readonly="updating"
                                    >
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <loading v-if="(!isFormReady() && !errors)"></loading>
            </div>
        </div>
        <div v-if="(canInvite() && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Send invite', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Send invite', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'user-add-form',
                errors: false,
                updating: false,
                updated: false,
                user: {
                    profile_group_id: null,
                    profile_id: null
                },
                currencies: [
                    {
                        id: 'pound',
                        name: '£'
                    },
                    {
                        id: 'euro',
                        name: '€'
                    },
                    {
                        id: 'dollar',
                        name: '$'
                    }
                ],
                user_roles: null,
                user_groups: null,
                get_content_response: null,
                company_name: '',
                copy_link_button_text: 'Copy link',
                invite_link: null,
                is_supplier: false,
                is_profile_group_user: false,
                profile_groups: null,
                checking_email: false,
                show_link_to_users: false
            }
        },
        mounted () {
            //
        },
        watch: {
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'company_name') {
                            this.company_name = val.content;
                        }
                    }
                }
            }
        },
        methods: {
            resetForm: function (userType = null, profile = null) {
                if (!this.canInvite()) {
                    this.errors = 'Sorry, you do not have access to this function.';

                    return false;
                }
                this.errors = false;
                this.show_link_to_users = false;
                this.updating = false;
                this.updated = false;
                this.copy_link_button_text = this.$root.text('Copy link', null, true);
                this.is_supplier = (userType == 'supplier');
                if (this.is_supplier) {
                    $('#user-add-form-modal .modal-dialog').removeClass('modal-xl');
                    $('#user-add-form-modal .modal-dialog').addClass('modal-lg');
                } else {
                    $('#user-add-form-modal .modal-dialog').removeClass('modal-lg');
                    $('#user-add-form-modal .modal-dialog').addClass('modal-xl');
                }
                this.is_profile_group_user = false;
                if (userType == 'profile-group-user') {
                    this.is_profile_group_user = profile;
                }
                this.user = {
                    role: !this.is_profile_group_user
                        ? null
                        : parseInt(this.$root.client.profile_group_default_role || this.$root.client.auto_approve_role),
                    company_contact_details: {
                        name: null,
                        phone: null,
                        email: null
                    },
                    currency: !this.is_profile_group_user
                        ? null
                        : this.$root.client.profile_group_default_currency || null
                };
                if (userType == 'profile-group-user') {
                    this.user.profile_group_id = profile.profile_group_id;
                    this.user.profile_id = profile.id;
                }
                this.invite_link = null;
                this.user_groups = null;
                this.profile_groups = null;

                this.company_name = this.$root.client.client_name;
                if (this.$root.client.company_contact_details) {
                    this.$root.getContent(this, 'company_name');
                }

                this.getRoles();
                this.getUserGroups();
                if (this.$root.isFeatured('profiles')) {
                    this.getProfiles();
                }
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function (delay = true) {
                this.errors = false;
                this.show_link_to_users = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                if (delay) {
                    var vm = this;
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 500);
                }

                if (this.checking_email) {
                    return null;
                }

                /**
                 * Send request to API.
                 */
                let data = this.user;
                data.full_invite = (this.isUserAdmin() && !this.is_supplier);
                data.app_info = {
                    app_url: this.$root.app.app_url
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = response.data.message;
                    if (response.data.code) {
                        vm.invite_link = this.$root.app.app_url + '/set-password/' + response.data.code;
                    }
                    if (vm.$route.name == 'users') {
                        const component = vm.$root.findComponent(vm.$parent, 'users');
                        if (component) {
                            component.getUsers();
                        }
                    }
                    if (vm.is_supplier) {
                        vm.createSupplier(response.data.user_id);
                        /*setTimeout(function () {
                            $('#user-add-form-modal').modal('hide');
                        }, 2000);*/
                    }
                    if(vm.is_profile_group_user) {
                        const component = vm.$root.findComponent(vm.$parent, 'view-profile-group');
                        if (component) {
                            component.getProfileGroup();
                            component.getUsers();
                        }
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#user-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                                if (vm.is_supplier && (vm.errors.indexOf('already exists') > 0)) {
                                    vm.errors = vm.$root.text('This email address is already in use on the system.',
                                        null, true) + ' ' + vm.$root.text('If you need to convert the account to a '
                                        + 'supplier please email your Resourcebase contact.', null, true);
                                }
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    errorHtml = value.join('<br>') + '<br>';
                                    if ((key == 'profile_id') && !vm.user.profile_group_id) {
                                        vm.errors += 'The ' + vm.$root.contents.profile_group_title
                                            + ' field is required.<br>';
                                        $('#profile_group_id-label').addClass('text-danger');
                                        $('#profile_group_id').addClass('field-error');
                                    }
                                    vm.errors += errorHtml.replace(
                                        /folder id/g, 'assigned to ' + vm.$root.contents.folder_title
                                    ).replace(/profile id/g, vm.$root.contents.profile_title);
                                    if ((key == 'company') && !vm.is_supplier) {
                                        let errors = vm.errors;
                                        vm.errors = errors.replace(/company/g, vm.$root.contents.company_title);
                                    }
                                    if ((key == 'first_name') || (key == 'last_name')) {
                                        $('#name-label').addClass('text-danger');
                                    } else {
                                        $('#' + key + '-label').addClass('text-danger');
                                    }
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            copyLinkToClipBoard: function () {
                let copyText = document.querySelector('#invite-link');
                copyText.select();

                try {
                    if (document.execCommand('copy')) {
                        this.copy_link_button_text = this.$root.text('Copied!', null, true);
                    }
                } catch (err) {
                    //
                }

                window.getSelection().removeAllRanges();

                var vm = this;
                setTimeout(function () {
                    vm.copy_link_button_text = vm.$root.text('Copy link', null, true);
                }, 2000);
            },
            getRoles: function () {
                /**
                 * Send get roles request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_roles = response.data.roles;
                    if (vm.is_supplier) {
                        vm.user_roles.forEach((item) => {
                            if (item.name.toLowerCase().indexOf('supplier') > -1) {
                                vm.user.role = item.id;
                            }
                        });
                    }
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroups: function () {
                /**
                 * Send get user groups request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getProfiles: function () {
                /**
                 * Send get profile groups request to API.
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/profile-groups/!pagination';
                if (this.is_profile_group_user) {
                    url += '|pg::' + this.is_profile_group_user.profile_group_id;
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_groups = response.data.profile_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getProfileOptions: function (profileGroupId) {
                if ((this.profile_groups.length == 0) || !profileGroupId) {
                    return [];
                }
                let itemIndex = -1;
                this.profile_groups.forEach((item, index) => {
                    if (item.id == profileGroupId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return this.profile_groups[itemIndex].profiles;
                }

                return [];
            },
            isFormReady: function () {
                /*if (this.user_folders === null) {
                    return false;
                }*/
                if (this.user_roles === null) {
                    return false;
                }
                if (this.user_groups === null) {
                    return false;
                }
                if (!this.$root.isFeatured('profiles')) {
                    return true;
                }
                if (this.profile_groups === null) {
                    return false;
                }

                return true;
            },
            roleDescription: function () {
                let description = '';
                this.user_roles.forEach((item) => {
                    if (item.id == this.user.role) {
                        description = item.description;
                    }
                });
                if (!description) {
                    return '';
                }

                return this.$root.text(description, null, true);
            },
            excludeFromReportsDescription: function () {
                if (this.user.exclude_from_reports) {
                    return this.$root.text('Activity will not be included in system reports (upload activity will '
                        + 'still be reported on)', null, true);
                }

                return this.$root.text('Activity will be included in system reports', null, true);
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'first_name':
                        text = this.$root.text('First name', null, true);
                        break;
                    case 'last_name':
                        text = this.$root.text('Last name', null, true);
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.user.first_name) {
                    $('#first_name').removeClass('field-error');
                }
                if (this.user.last_name) {
                    $('#last_name').removeClass('field-error');
                }
                if (this.user.first_name && this.user.last_name) {
                    $('#name-label').removeClass('text-danger');
                }
            },
            modalTitle: function () {
                if (this.is_supplier) {
                    return this.$root.text('Invite an order supplier', null, true);
                }

                return this.$root.text('Invite a user', null, true);
            },
            createSupplier: function (userId) {
                /**
                 * Send request to API.
                 */
                const data = {
                    user_id: userId
                }
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-supplier';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.$route.name == 'print-order-suppliers') {
                        const component = vm.$root.findComponent(vm.$parent, 'print-order-suppliers');
                        if (component) {
                            component.getSuppliers();
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#print-order-supplier-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error
                            || vm.$root.text('Something went wrong, please try again', null, true);
                    }
                });
            },
            onProfileGroupChange: function () {
                this.updating = true;
                this.$root.unHighlightErrors('profile_group_id');
                this.user.profile_id = null;
                this.updating = false;
            },
            canSelectProfile: function () {
                if (this.updating) {
                    return false;
                }
                if (!this.user.profile_group_id) {
                    return false;
                }

                return (this.user.profile_group_id > 0);
            },
            canInvite: function () {
                if (this.$root.hasPermission('users_invite')) {
                    return true;
                }

                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_add_users'));
            },
            isUserAdmin: function () {
                return !this.is_profile_group_user;
            },
            checkEmail: function () {
                if (!this.$root.hasPermission('users_invite')) {
                    return null;
                }
                if (!this.user.email) {
                    return null;
                }
                if (this.user.email.indexOf('@') < 1) {
                    return null;
                }
                this.checking_email = true;
                this.errors = false;
                this.show_link_to_users = false;

                /**
                 * Send get users request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!pagination|!export|kw::' + btoa(this.user.email);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.users.length == 0) {
                        return null;
                    }
                    let existingUser = null;
                    response.data.users.forEach((item) => {
                        if (item.email.toLowerCase() == vm.user.email.toLowerCase()) {
                            existingUser = item;
                        }
                    });
                    if (!existingUser) {
                        return null;
                    }
                    if (existingUser.deleted) {
                        vm.errors = vm.$root.text('This is a registered email address and the user account has been '
                            + 'deactivated.', null, true);
                        vm.show_link_to_users = $root.text('Click here to view / reactivate user', null, true);
                    } else if (!existingUser.approved) {
                        vm.errors = vm.$root.text('This is a registered email address and the user is awaiting '
                            + 'approval.', null, true);
                        vm.show_link_to_users = vm.$root.text('Click here to view / approve user', null, true);
                    } else if (!existingUser.email_verified_at) {
                        vm.errors = vm.$root.text('This is a registered email address and the user has been sent an '
                            + 'invite.', null, true);
                        vm.show_link_to_users = vm.$root.text('Click here to view / resend invite', null, true);
                    } else {
                        vm.errors = vm.$root.text('This is a registered email address.', null, true);
                        vm.show_link_to_users = vm.$root.text('Click here to view / edit user', null, true);
                    }
                    vm.updating = false;
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() => {
                    vm.checking_email = false;
                    if (vm.updating) {
                        vm.onSubmit(false);
                    }
                });
            },
            goToUsers: function () {
                this.$root.user_filters = [];
                this.$root.addFilter('user_filters', {
                    key: 'keyword',
                    value: this.user.email,
                    text: '&quot;' + this.user.email + '&quot;',
                    filters_key: 'user_filters'
                });
                this.$root.addFilter('user_filters', {
                    key: 'status',
                    value: -1,
                    text: null,
                    filters_key: 'user_filters',
                    hidden: true
                });
                if (this.$route.name == 'users') {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    const mainNavcomponent = this.$root.findComponent(appComponent, 'main-nav');
                    const component = this.$root.findComponent(mainNavcomponent, 'user-filters');
                    component.keyword = this.user.email;
                } else {
                    this.$router.push({ name: 'users' });
                }
                $('#user-add-form-modal').modal('hide');
            }
        }
    }
</script>
