<template>
    <div>
        <div v-click-outside="config" class="right-bar">
            <div>
                <div class="rightbar-title px-3 py-4">
                    <a href="#" class="right-bar-toggle float-right" @click.prevent="hide()">
                        <i class="far fa-times noti-icon" style="font-size: 12px"></i>
                    </a>
                    <h5 class="m-0">{{ $root.text('Filters', null, true) }}</h5>
                </div>

                <hr class="mt-0">

                <div class="pl-3 pr-3 pt-1 pb-3">
                    <form v-on:submit.prevent="keywordSearch()">
                        <div class="form-group keyword">
                            <div class="input-group">
                                <input
                                    v-model="keyword"
                                    type="text"
                                    class="form-control"
                                    id="filter-by-keyword"
                                    :placeholder="$root.text('Filter by name', null, true)"
                                    aria-label="Filter by name"
                                >
                                <div class="input-group-append">
                                    <button class="btn btn-primary big-icon" type="submit">
                                        <i class="fal fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button v-if="keyword" class="btn btn-link clear-keyword" @click.prevent="clearKeyword()">
                                <i class="far fa-times"></i>
                            </button>
                        </div>
                        <div class="form-group">
                            <label>{{ $root.text('Order results by', null, true) }}</label>
                            <div class="form-control" style="height: auto">
                                <div class="mb-2">
                                    <div class="custom-control custom-radio" style="display: inline-block">
                                        <input
                                            v-model="sort_by"
                                            type="radio"
                                            id="sort-by-updated-desc"
                                            class="custom-control-input"
                                            value="updated_desc"
                                        >
                                        <label class="custom-control-label" for="sort-by-updated-desc">
                                            {{ $root.text('Last updated', null, true) }}
                                        </label>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <div class="custom-control custom-radio" style="display: inline-block">
                                        <input
                                            v-model="sort_by"
                                            type="radio"
                                            id="sort-by-created-desc"
                                            class="custom-control-input"
                                            value="created_desc"
                                        >
                                        <label class="custom-control-label" for="sort-by-created-desc">
                                            {{ $root.text('Newest first', null, true) }}
                                        </label>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <div class="custom-control custom-radio" style="display: inline-block">
                                        <input
                                            v-model="sort_by"
                                            type="radio"
                                            id="sort-by-created-asc"
                                            class="custom-control-input"
                                            value="created_asc"
                                        >
                                        <label class="custom-control-label" for="sort-by-created-asc">
                                            {{ $root.text('Oldest first', null, true) }}
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div class="custom-control custom-radio" style="display: inline-block">
                                        <input
                                            v-model="sort_by"
                                            type="radio"
                                            id="sort-by-name-asc"
                                            class="custom-control-input"
                                            value="name_asc"
                                        >
                                        <label class="custom-control-label" for="sort-by-name-asc">
                                            {{ $root.text('Name', null, true) }}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="rightbar-overlay"></div>
    </div>
</template>

<script>
    export default {
        props: ['collection_filters'],
        data() {
            return {
                config: {
                    handler: this.handleRightBarClick,
                    middleware: this.middleware,
                    events: ['click']
                },
                keyword: '',
                sort_by: 'updated_desc'
            };
        },
        mounted () {
            //
        },
        watch: {
            collection_filters: function (val) {
                this.updateFilters();
            },
            sort_by: function (val) {
                let text = this.$root.text('Last updated', null, true);
                if (val == 'created_desc') {
                    text = this.$root.text('Newest first', null, true);
                }
                if (val == 'created_asc') {
                    text = this.$root.text('Oldest first', null, true);
                }
                if (val == 'name_asc') {
                    text = this.$root.text('Name', null, true);
                }
                this.$root.addFilter('collection_filters', {
                    key: 'sort_by',
                    value: val,
                    text: this.$root.text('Order results by:', null, true) + ' ' + text,
                    filters_key: 'collection_filters'
                });
                this.$parent.getCollections();
            }
        },
        methods: {
            hide() {
                $('body').toggleClass('right-bar-enabled');
            },
            handleRightBarClick(e, el) {
                $('body').removeClass('right-bar-enabled');
            },
            middleware(event, el) {
                return !event.target.classList.contains('toggle-right');
            },
            updateFilters: function () {
                let sortByFound = false;
                this.collection_filters.forEach((item) => {
                    if (item.key == 'sort_by') {
                        sortByFound = item.value;
                    }
                });
                this.sort_by = sortByFound ? sortByFound : 'updated_desc';
            },
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                if (keywordTrimmed == '') {
                    return null;
                }
                let keywordsAdded = [];
                this.collection_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsAdded.push(item.value);
                    }
                });
                if (keywordsAdded.indexOf(keywordTrimmed) > -1) {
                    return null;
                }
                this.$root.addFilter('collection_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'collection_filters'
                });
                this.$parent.getCollections();
            },
            clearKeyword: function () {
                this.keyword = '';
                let filters = this.$root.collection_filters;
                for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                    if (filters[itemIndex].key == 'keyword') {
                        filters.splice(itemIndex, 1);
                    }
                }
                this.$parent.getCollections();
                $('#filter-by-keyword').focus();
            }
        }
    }
</script>
<style scoped>
    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }
</style>
