<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="decline-artwork-approval-request-modal-label">
                <span v-if="print_order">{{ $root.text('Decline order {x}', { x: print_order.id }, true) }}</span>
                <span v-else>{{ $root.text('Decline order', null, true) }}</span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <form>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label for="reason_for_decline" id="reason_for_decline-label" class="mandatory-label">
                            {{ $root.text('Please give your reason for declining this order', null, true) }} *
                        </label>
                        <textarea
                            v-model="reason_for_decline"
                            id="reason_for_decline"
                            class="form-control mandatory-field"
                            :readonly="sending"
                            rows="4"
                            v-on:keyup="$root.unHighlightErrors('reason_for_decline')"
                            v-on:change="$root.unHighlightErrors('reason_for_decline')"
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Send', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Send', null, true) }}</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                reason_for_decline: null,
                print_order: null
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (data, isAssetReplaced) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.reason_for_decline = isAssetReplaced 
                    ? this.$root.text(
                        'The {resource} you have ordered has been updated so cannot be approved. '
                            + 'Please place a new order.',
                        { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                        true
                    )
                    : null;
                this.print_order = data;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send decline print order request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order/'
                    + this.print_order.id + '/decline';
                const data = {
                    reason_for_decline: this.reason_for_decline,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = response.data.message;

                    // Remove from orders list.
                    vm.$parent.getOrders();

                    // Close after 4 seconds automatically.
                    setTimeout(function () {
                        $('#decline-print-order-modal').modal('hide');
                    }, 4000);

                    // Clear notification.
                    vm.$root.clearNotification('admin', 0, 'print_order', vm.print_order.id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-label').addClass('text-danger');
                                $('#' + key).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            }
        }
    }
</script>
