var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topnav page-topnav",staticStyle:{"padding":"0 12px","max-height":"none","overflow-y":"visible"}},[_c('div',{staticClass:"container-fluid"},[_c('nav',{staticClass:"navbar navbar-light navbar-expand topnav-menu"},[_c('div',{staticClass:"navbar-collapse align-items-center justify-content-between"},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item"},[_c('h6',[((_vm.$route.name == 'asset-types-inactive'))?_c('span',[_vm._v("\n                                "+_vm._s(_vm.$root.text(
                                    'Inactive {resource} types',
                                    { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
                                    true
                                ))+"\n                            ")]):_c('span',[_vm._v("\n                                "+_vm._s(_vm.$root.text(
                                    'Live {resource} types',
                                    { resource: _vm.$root.text(_vm.$root.contents.asset_title, null, false, 'asset_title') },
                                    true
                                ))+"\n                            ")])])])]),_vm._v(" "),_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item",staticStyle:{"width":"100px","height":"36.5px"}},[_c('div',{staticClass:"btn-group",staticStyle:{"position":"absolute","height":"36.5px","right":"0"}},[_c('button',{staticClass:"btn btn-primary dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("\n                                "+_vm._s(_vm.$root.text('Actions', null, true))+"\n                                "),_c('i',{staticClass:"fas fa-chevron-down",staticStyle:{"font-size":"9px","position":"relative","top":"-1px","left":"2px"}})]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-toggle":"modal","data-target":"#asset-type-add-form-modal"},on:{"click":function($event){$event.preventDefault();return _vm.resetAssetTypeAddForm()}}},[_c('i',{staticClass:"far fa-file-plus mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'Add {resource} type',
                                        {
                                            resource: _vm.$root.text(
                                                _vm.$root.contents.asset_title,
                                                null,
                                                false,
                                                'asset_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]),_vm._v(" "),((_vm.$route.name == 'asset-types'))?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'asset-types-inactive' }}},[_c('i',{staticClass:"far fa-archive mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'View inactive {resource} types',
                                        {
                                            resource: _vm.$root.text(
                                                _vm.$root.contents.asset_title,
                                                null,
                                                false,
                                                'asset_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]):_vm._e(),_vm._v(" "),((_vm.$route.name == 'asset-types-inactive'))?_c('span',[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'asset-types' }}},[_c('i',{staticClass:"far fa-copy mr-1"}),_vm._v("\n                                        "+_vm._s(_vm.$root.text(
                                            'View live {resource} types',
                                            {
                                                resource: _vm.$root.text(
                                                    _vm.$root.contents.asset_title,
                                                    null,
                                                    false,
                                                    'asset_title'
                                                )
                                            },
                                            true
                                        ))+"\n                                    ")])],1):_vm._e()],1)])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }