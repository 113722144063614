<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="loading"><loading></loading></div>

        <div v-if="(!errors && !loading)" class="row">
            <div
                v-if="showLoginAndRegistrationSettings() || $root.hasPermission('content_edit')
                    || $root.hasPermission('homepage_edit')"
                class="col-xl-3 col-lg-4 col-md-6"
            >
                <div v-if="showLoginAndRegistrationSettings()" class="card">
                    <h5 class="card-header">{{ $root.text('Login & registration', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('content_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Login page background image', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent(
                                                $root.text('Login page background image', null, true),
                                                'login_background')"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Login panel position', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(getPanelPositionOptions(), settings.login_panel_position) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in getPanelPositionOptions()"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('login_panel_position', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('content_edit') && settings.allow_register)"
                            class="list-group-item"
                        >
                            <div
                                v-if="$root.hasPermission('content_edit')"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Registration form introduction/instruction', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent(
                                                $root.text('Registration form introduction/instruction', null, true),
                                                'registration_info', true)"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="(($root.hasPermission('setting_edit') || $root.hasPermission('content_edit'))
                                && settings.allow_register)"
                            class="list-group-item"
                        >
                            <div v-if="$root.hasPermission('setting_edit')" class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Additional fields for non-staff applicants', null, true) }}
                                    <a
                                        class="font-size-15 ml-1"
                                        href="#"
                                        id="company-contact-details-tooltip"
                                        @click.prevent="doNothing()"
                                    >
                                        <i class="far fa-info-circle"></i>
                                    </a>
                                    <b-tooltip
                                        target="company-contact-details-tooltip"
                                        triggers="hover"
                                    >
                                        {{ $root.text('To facilitate Admin approval, require non-staff applicants to '
                                            + 'provide the name, email and telephone number for their staff contact.',
                                            null, true) }}
                                    </b-tooltip>
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.company_contact_details"
                                            @click="updateSetting('company_contact_details', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('company_contact_details', false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('content_edit') && settings.company_contact_details)"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Additional fields introduction/instruction', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent(
                                                $root.text('Additional fields introduction/instruction', null, true),
                                                'company_contact_name_label', true)"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('users_pre_approved_email_domains') && settings.allow_register)"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Auto-approve domains', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'pre-approved-email-domains' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('User password expires in', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(getPasswordExpiryOptions(), settings.password_expiry_days) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in getPasswordExpiryOptions()"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('password_expiry_days', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('content_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Company field title', null, true) }}
                                    {{ $root.text('(term for: {exp})', { exp: $root.text('company') }, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent(
                                                $root.text('term for: {exp}', { exp: $root.text('company') }, true),
                                                'company_title',
                                                false,
                                                true
                                            )"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="$root.hasPermission('homepage_edit')" class="card">
                    <h5 class="card-header">{{ $root.text('Homepage', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Show welcome panel', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.show_welcome_panel"
                                            @click="updateSetting('show_welcome_panel', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('show_welcome_panel', false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="settings.show_welcome_panel"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    {{ $root.text('Welcome panel colour', null, true) }}
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.welcome_panel_colour + ' text-'
                                                + settings.welcome_panel_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.welcome_panel_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('welcome_panel_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="settings.show_welcome_panel"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    {{ $root.text('Welcome panel button colour', null, true) }}
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.welcome_panel_button_colour + ' text-'
                                                + settings.welcome_panel_button_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.welcome_panel_button_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('welcome_panel_button_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Homepage panels', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            @click.prevent="editHomepage()"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.isFeatured('flyte')" class="list-group-item">
                            <div class="row no-gutters">
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    {{ $root.text('Flyte panel header colour', null, true) }}
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.flyte_panel_header_colour + ' text-'
                                                + settings.flyte_panel_header_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.flyte_panel_header_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('flyte_panel_header_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters" style="margin-top: 5px">
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    {{ $root.text('Flyte panel body colour', null, true) }}
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.flyte_panel_colour + ' text-'
                                                + settings.flyte_panel_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.flyte_panel_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('flyte_panel_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters" style="margin-top: 5px">
                                <div :class="'col-' + ($root.is_3xl_screen ? '6' : '12') + ' pt-1'">
                                    {{ $root.text('Flyte panel button colour', null, true) }}
                                </div>
                                <div class="col text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            :class="'btn btn-light btn-sm dropdown-toggle bg-'
                                                + settings.flyte_panel_button_colour + ' text-'
                                                + settings.flyte_panel_button_colour"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :style="colour_button_styles"
                                        >
                                            {{ optionText(colour_options, settings.flyte_panel_button_colour) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu limited-height">
                                            <a
                                                v-for="option in colour_options"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('flyte_panel_button_colour', option.key)"
                                            >
                                                <span
                                                    :class="'mr-2 bg-' + option.key"
                                                    :style="colour_span_styles"
                                                ></span>
                                                <span style="position: relative; top: -2px">{{ option.value }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="$root.hasPermission('content_edit')" class="card">
                    <h5 class="card-header">{{ $root.text('System & company', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.client.client_name }} {{ $root.text('UI version', null, true) }}
                                </div>
                                <div class="col-4 pt-1 pb-1 text-right">
                                    {{ $root.app.client_version }}
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Resourcebase {{ $root.text('API version', null, true) }}
                                </div>
                                <div class="col-4 pt-1 pb-1 text-right">
                                    {{ $root.app.api_version }}
                                </div>
                            </div>
                        </li>
                        <li class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Company name', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent($root.text('Company name', null, true),
                                                'company_name', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.hasPermission('manage_languages')"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Interface languages', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'languages' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="showAssetSettings() || showProofSettings()" class="col-xl-3 col-lg-4 col-md-6">
                <div v-if="showAssetSettings()" class="card">
                    <h5 class="card-header">
                        {{ $root.text($root.contents.asset_title + 's', null, true, 'assets_title') }}
                    </h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('assets_asset_types')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text(
                                        '{resource} types',
                                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                        true
                                    ) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'asset-types' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="$root.hasPermission('setting_edit')"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text(
                                        'Show {resource} type menu bar',
                                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                        true
                                    ) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.asset_type_filter_menu"
                                            @click="updateSetting('asset_type_filter_menu', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('asset_type_filter_menu', false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li v-if="$root.hasPermission('content_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('term for: {exp}', { exp: $root.text('folder') }, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent($root.text('term for: {exp}',
                                                { exp: $root.text('folder') }, true), 'folder_title', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters" style="margin-top: 5px">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('plural: {exp}', { exp: $root.text('folders') }, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent($root.text('plural: {exp}',
                                                { exp: $root.text('folders') }, true), 'folders_title', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('content_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('term for: {exp}', { exp: $root.text('subfolder') }, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent($root.text('term for: {exp}',
                                                { exp: $root.text('subfolder') }, true), 'subfolder_title', false, true)"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters" style="margin-top: 5px">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('plural: {exp}', { exp: $root.text('subfolders') }, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent($root.text('plural: {exp}',
                                                { exp: $root.text('subfolders') }, true), 'subfolders_title', false,
                                                true)"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li v-if="$root.hasPermission('assets_manage_folders')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text($root.contents.folders_title, null, true, 'folders_title') }}
                                    {{ $root.text('and') }}
                                    {{ $root.text($root.contents.subfolders_title, null, false, 'subfolders_title') }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'folders' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_custom_fields')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Custom fields', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'custom-fields' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_keyword_assistant')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Keyword assistant', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'keyword-categories' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.client.assets_use_detected_labels
                                && $root.hasPermission('assets_keyword_assistant')"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('AI keyword exclusion list', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'detected-label-exclusions' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.hasPermission('assets_manage_archive')
                                || $root.hasPermission('templates_manage_archive')"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Archived {resources}',
                                        { resources: $root.text($root.contents.asset_title + 's', null, false,
                                        'assets_title') }, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewAssets({ name: 'assets-filtered',
                                                params: { filters: 'archived' } })"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text(
                                        '{resource} approval expires in',
                                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                        true
                                    ) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(getInviteExpiryOptions(),
                                                settings.asset_approval_expiry_days) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in getInviteExpiryOptions()"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('asset_approval_expiry_days', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_approval_system')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text(
                                        '{resource} approvals',
                                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                        true
                                    ) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'asset-approvals' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('assets_review')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('{resources} due for review',
                                        { resources: $root.text($root.contents.asset_title + 's', null, false,
                                        'assets_title') }, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'asset-reviews' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="showProofSettings()" class="card">
                    <h5 class="card-header">{{ $root.text('Proof tracker', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Homepage view', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(getProofsViewOptions(), settings.proofs_view) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in getProofsViewOptions()"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('proofs_view', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('setting_edit')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Default proof type', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="btn-group btn-block">
                                        <button
                                            type="button"
                                            class="btn btn-light btn-sm dropdown-toggle"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style="text-align: left"
                                        >
                                            {{ optionText(getProofsTypeOptions(), settings.proofs_type) }}
                                            <i
                                                class="fas fa-chevron-down"
                                                style="position: absolute; top: 8px; right: 10px; font-size: 9px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="option in getProofsTypeOptions()"
                                                class="dropdown-item"
                                                :key="option.key"
                                                href="#"
                                                @click.prevent="setOption('proofs_type', option.key)"
                                            >{{ option.value }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('proofs_view_archive')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Proof archive', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewProofs(true)"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="showTemplateSettings() || showPrintOrderingSettings()" class="col-xl-3 col-lg-4 col-md-6">
                <div v-if="showTemplateSettings()" class="card">
                    <h5 class="card-header">
                        {{ $root.text($root.contents.template_title + 's', null, true, 'templates_title') }}
                    </h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('templates_manage_resourcepage')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Font resource page', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#resourcepage-modal"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="showProfileFieldSettings()" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Profile field set', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'profile-fields' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="showProfileGroupSettings()" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text(
                                        $root.contents.profile_groups_title,
                                        null,
                                        true,
                                        'profile_groups_title'
                                    ) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewProfileGroups()"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('templates_brand_approvals')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text(
                                        '{template} marketing approvals',
                                        {
                                            template: $root.text(
                                                $root.contents.template_title,
                                                null,
                                                false,
                                                'template_title'
                                            )
                                        },
                                        true
                                    ) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'artwork-approvals' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="showPrintOrderingSettings()" class="card">
                    <h5 class="card-header">{{ $root.text('Item ordering', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li
                            v-if="$root.client.print_ordering_approval && ($root.hasPermission('printing_orders_view')
                                || $root.hasPermission('printing_financial_approvals'))"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Orders awaiting approval', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'print-orders-awaiting-approval' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.client.print_ordering_approval && ($root.hasPermission('printing_orders_view')
                                || $root.hasPermission('printing_financial_approvals'))"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Declined orders', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'print-orders-declined' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="$root.hasPermission('printing_orders_view')
                                || $root.hasPermission('printing_financial_approvals')"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text($root.client.print_ordering_approval ? 'All orders' : 'Orders',
                                        null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewPrintOrders()"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('printing_view_archived_orders')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Archived orders', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'print-orders-archived' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('printing_manage_price_panels')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Price panels', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'price-panel-types' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-fields"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Custom fields', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            @click.prevent="showPrintOrderCustomFields()"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Show', null, true)">
                                                <i class="far fa-chevron-circle-down"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-field"
                            style="display: none"
                        >
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('content_edit'))"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Custom field 1 title', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent(
                                                $root.text('Item order form custom field 1 title', null, true),
                                                'print_order_custom_field_1_title')"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('setting_edit'))"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Required field', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.print_order_custom_field_1_required"
                                            @click="updateSetting('print_order_custom_field_1_required', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('print_order_custom_field_1_required',
                                                    false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-field"
                            style="display: none"
                        >
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('content_edit'))"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Custom field 2 title', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent(
                                                $root.text('Item order form custom field 2 title', null, true),
                                                'print_order_custom_field_2_title')"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('setting_edit'))"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Required field', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.print_order_custom_field_2_required"
                                            @click="updateSetting('print_order_custom_field_2_required', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('print_order_custom_field_2_required',
                                                    false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-field"
                            style="display: none"
                        >
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('content_edit'))"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Custom field 3 title', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent(
                                                $root.text('Item order form custom field 3 title', null, true),
                                                'print_order_custom_field_3_title')"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('setting_edit'))"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Required field', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.print_order_custom_field_3_required"
                                            @click="updateSetting('print_order_custom_field_3_required', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('print_order_custom_field_3_required',
                                                    false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_manage_custom_fields')
                                || $root.hasPermission('content_edit') || $root.hasPermission('setting_edit'))"
                            class="list-group-item print-order-custom-field"
                            style="display: none"
                        >
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('content_edit'))"
                                class="row no-gutters"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Custom field 4 title', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent(
                                                $root.text('Item order form custom field 4 title', null, true),
                                                'print_order_custom_field_4_title')"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="($root.hasPermission('printing_manage_custom_fields')
                                    || $root.hasPermission('setting_edit'))"
                                class="row no-gutters"
                                style="margin-top: 5px"
                            >
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Required field', null, true) }}
                                </div>
                                <div class="col-4 text-right">
                                    <div class="contact-links font-size-22 text-right">
                                        <span
                                            v-if="!settings.print_order_custom_field_4_required"
                                            @click="updateSetting('print_order_custom_field_4_required', true)"
                                        >
                                            <i class="far fa-toggle-on fa-flip-horizontal"></i>
                                        </span>
                                        <span v-else>
                                            <a
                                                href="#"
                                                @click.prevent="updateSetting('print_order_custom_field_4_required', false)"
                                            >
                                                <i class="far fa-toggle-on"></i>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li
                            v-if="($root.hasPermission('printing_onscreen_confirmation')
                                    || $root.hasPermission('content_edit'))"
                            class="list-group-item"
                        >
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('On-screen confirmation', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#edit-content-modal"
                                            @click.prevent="editContent($root.text('On-screen confirmation', null, true),
                                                'print_order_confirmation', true)"
                                        >
                                            <span v-b-tooltip.hover.left :title="$root.text('Edit', null, true)">
                                                <i class="far fa-edit"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('printing_email_recipients')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Copy item order recipients', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'print-order-copy-recipients' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('printing_manage_suppliers')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Suppliers', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'print-order-suppliers' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="showUserSettings() || showReportSettings()" class="col-xl-3 col-lg-4 col-md-6">
                <div v-if="showUserSettings()" class="card">
                    <h5 class="card-header">{{ $root.text('Users', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <!--<li v-if="$root.hasPermission('users_invite')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    Invite a user
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            data-toggle="modal"
                                            data-target="#user-add-form-modal"
                                            @click.prevent="resetAddUserForm()"
                                        >
                                            <span v-b-tooltip.hover.left title="Invite a user">
                                                <i class="far fa-user-plus"></i>
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>-->
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('All users', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewUsers()"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Active users', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewUsers('users', 'active')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Invited users', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewUsers('users', 'invited')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Applied users', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewUsers('users', 'applied')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_deleted_users')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Deactivated users', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewUsers('users', 'deleted')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_view_details')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Deletion requests', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            @click.prevent="viewUsers('deletion-requests')"
                                        >
                                            <i class="far fa-eye"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_user_groups')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('User groups', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'user-groups' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('users_manage_alerts')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Administrator alerts', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'alerts' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="showReportSettings()" class="card">
                    <h5 class="card-header">{{ $root.text('Reports', null, true) }}</h5>
                    <ul class="list-group list-group-flush">
                        <li v-if="$root.hasPermission('reports_overview')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('System overview', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-overview' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_users')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Registrations', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-registrations' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_users')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Visits', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-visits' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_assets')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Uploads', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-asset-uploads' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_assets')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Downloads', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-asset-downloads' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_templates')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text(
                                        '{templates} created',
                                        {
                                            templates: $root.text(
                                                $root.contents.template_title + 's',
                                                null,
                                                false,
                                                'templates_title'
                                            )
                                        },
                                        true
                                    ) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-artwork-created' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_templates')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text($root.contents.template_title + 's', null, true, 'template_title') }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-templates' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_print_orders')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Orders', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-orders' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li v-if="$root.hasPermission('reports_flyte')" class="list-group-item">
                            <div class="row no-gutters">
                                <div class="col-8 pt-1 pb-1">
                                    {{ $root.text('Flyte activity', null, true) }}
                                </div>
                                <div class="col-4">
                                    <div class="contact-links font-size-18 text-right">
                                        <router-link
                                            v-b-tooltip.hover.left
                                            :title="$root.text('View', null, true)"
                                            :to="{ name: 'reports-flytes-sent' }"
                                        >
                                            <i class="far fa-eye"></i>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div
            v-if="$root.hasPermission('content_edit')"
            class="modal fade"
            id="edit-content-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="edit-content-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <edit-content></edit-content>
            </div>
        </div>
        <div
            v-if="$root.hasPermission('templates_manage_resourcepage')"
            class="modal fade"
            id="resourcepage-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="resourcepage-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable modal-lg">
                <resourcepage></resourcepage>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                settings: {
                    show_welcome_panel: false,
                    welcome_panel_colour: 'third',
                    welcome_panel_button_colour: 'primary',
                    flyte_panel_colour: 'third',
                    flyte_panel_header_colour: 'third',
                    flyte_panel_button_colour: 'primary',
                    login_panel_position: 'center',
                    allow_register: false,
                    company_contact_details: false,
                    must_accept_terms: false,
                    password_expiry_days: 366,
                    assets_per_page: 25,
                    asset_download_prefix: true,
                    asset_download_title: true,
                    asset_download_filename: true,
                    users_per_page: 25,
                    invite_expiry_days: 3,
                    asset_description_required: true,
                    asset_watermarking: true,
                    asset_approval_expiry_days: 7,
                    asset_approval_usage_notes: false,
                    asset_type_filter_menu: true,
                    print_order_custom_field_1_required: false,
                    print_order_custom_field_2_required: false,
                    print_order_custom_field_3_required: false,
                    print_order_custom_field_4_required: false,
                    proofs_view: 'folders',
                    proofs_type: 'unordered'
                },
                colour_options: [
                    {
                        key: 'primary',
                        value: this.$root.client.primary_colour_name
                    },
                    {
                        key: 'secondary',
                        value: this.$root.client.secondary_colour_name
                    },
                    {
                        key: 'third',
                        value: this.$root.client.third_colour_name
                    }
                ],
                colour_button_styles: 'text-align: left; box-shadow: 0 0.05rem 0.75rem rgba(18, 38, 63, 0.03) '
                    + '!important',
                colour_span_styles: 'display: inline-block; width: 15px; height: 15px; border: 1px solid #eff2f7'
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.loading = true;
            this.getSettings();
        },
        methods: {
            getSettings: function () {
                this.errors = false;

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/settings';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    for (const [key, value] of Object.entries(response.data.settings)) {
                        vm.settings[key] = value;
                    }
                    vm.getAdditionalColours();
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getAdditionalColours: function () {
                if (this.$root.client.additional_colours) {
                    if (this.$root.client.additional_colours.length > 0) {
                        this.$root.client.additional_colours.forEach((item) => {
                            this.colour_options.push({ key: 'colour' + item.id, value: item.colour_name });
                        });
                    }

                    return null;
                }

                var vm = this;
                setTimeout(function () {
                    vm.getAdditionalColours();
                }, 500);
            },
            updateSetting: function(key, value = null) {
                if (value !== null) {
                    this.settings[key] = value;
                }
                let updateValue = this.settings[key];
                if (updateValue === true) {
                    updateValue = 'true';
                }
                if (updateValue === false) {
                    updateValue = 'false';
                }

                /**
                 * Send update setting request to API
                 */
                var url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/setting/' + key;
                var data = {
                    value: updateValue
                };
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // update client data stored in frontend
                    vm.$root.client[key] = vm.settings[key];
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.status == 404) {
                        /**
                         * Setting does not exists - create new
                         * Send create setting request to API
                         */
                        url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/setting';
                        data = {
                            key: key,
                            value: updateValue
                        };

                        axios({
                            method: 'post',
                            url: url,
                            data: data,
                            headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                        })
                        .then(response => {
                            // update client data stored in frontend
                            vm.$root.client[key] = vm.settings[key];
                        })
                        .catch(function (error) {
                            if (error.response.status == 401) {
                                // missing/lost API token - logout user
                                vm.$root.doLogout();
                            }
                            // reset setting if already exists in frontend
                            if (key in vm.$root.client) {
                                vm.settings[key] = vm.$root.client[key];
                            }
                            // show error
                            if (error.response.data.error) {
                                vm.errors = error.response.data.error;
                            } else {
                                vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                            }
                        });
                    } else {
                        // reset setting if already exists in frontend
                        if (key in vm.$root.client) {
                            vm.settings[key] = vm.$root.client[key];
                        }
                        // show error
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        } else {
                            vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                        }
                    }
                });
            },
            editContent: function (title, key, multiLine = false, mandatory = false) {
                const component = this.$root.findComponent(this, 'edit-content');
                if (component) {
                    component.resetForm(title, key, multiLine, mandatory);
                }
            },
            showLoginAndRegistrationSettings: function () {
                if (this.$root.hasPermission('content_edit') || this.$root.hasPermission('setting_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('users_pre_approved_email_domains')) {
                    return true;
                }

                return false;
            },
            showAssetSettings: function () {
                if (this.$root.hasPermission('content_edit') || this.$root.hasPermission('setting_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_asset_types')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_custom_fields')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_keyword_assistant')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_manage_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('templates_manage_archive')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_approval_system')) {
                    return true;
                }
                if (this.$root.hasPermission('assets_review')) {
                    return true;
                }

                return false;
            },
            showProfileFieldSettings: function () {
                if (this.$root.hasPermission('profiles_fieldset_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_fieldset_delete')) {
                    return true;
                }

                return false;
            },
            showProfileSettings: function () {
                if (this.$root.hasPermission('profiles_profiles_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_profiles_delete')) {
                    return true;
                }

                return false;
            },
            showProfileGroupSettings: function () {
                if (this.$root.hasPermission('profiles_groups_add')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('profiles_groups_delete')) {
                    return true;
                }

                return this.showProfileSettings();
            },
            showTemplateSettings: function () {
                if (this.$root.hasPermission('templates_manage_resourcepage')) {
                    return true;
                }
                if (this.showProfileGroupSettings()) {
                    return true;
                }
                if (this.showProfileFieldSettings()) {
                    return true;
                }
                if (this.$root.hasPermission('templates_brand_approvals')) {
                    return true;
                }

                return false;
            },
            showUserSettings: function () {
                if (this.$root.hasPermission('users_invite')) {
                    return true;
                }
                if (this.$root.hasPermission('users_view_details')) {
                    return true;
                }
                if (this.$root.hasPermission('users_view_deleted_users')) {
                    return true;
                }
                if (this.$root.hasPermission('users_user_groups')) {
                    return true;
                }
                if (this.$root.hasPermission('users_manage_alerts')) {
                    return true;
                }

                return false;
            },
            showPrintOrderingSettings: function () {
                if (!this.$root.isFeatured('printing')) {
                    return false;
                }
                if (this.$root.hasPermission('printing_manage_suppliers')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_manage_price_panels')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_manage_custom_fields')) {
                    return true;
                }
                if (this.$root.hasPermission('setting_edit') || this.$root.hasPermission('content_edit')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_orders_view')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_view_archived_orders')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_financial_approvals')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_onscreen_confirmation')) {
                    return true;
                }
                if (this.$root.hasPermission('printing_email_recipients')) {
                    return true;
                }

                return false;
            },
            showReportSettings: function () {
                if (this.$root.hasPermission('reports_users') || this.$root.hasPermission('reports_overview')) {
                    return true;
                }
                if (this.$root.hasPermission('reports_assets') || this.$root.hasPermission('reports_flyte')) {
                    return true;
                }
                if (this.$root.hasPermission('reports_templates') || this.$root.hasPermission('reports_print_orders')) {
                    return true;
                }

                return false;
            },
            showProofSettings: function () {
                if (!this.$root.isFeatured('proofs')) {
                    return false;
                }
                if (this.$root.hasPermission('setting_edit') || this.$root.hasPermission('proofs_view_archive')) {
                    return true;
                }

                return false;
            },
            optionText: function (options, key) {
                let itemIndex = -1;
                options.forEach((item, index) => {
                    if (item.key == key) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    return options[itemIndex].value;
                }

                return 'Select';
            },
            setOption: function (key, value) {
                this.settings[key] = value;
                this.updateSetting(key, value);
            },
            editHomepage: function () {
                if (!this.$root.hasPermission('homepage_edit')) {
                    return null;
                }
                this.$root.editing_homepage = true;
                this.$router.push({ name: 'home' });
            },
            viewAssets: function (route) {
                this.$root.asset_filters = [];
                this.$router.push(route);
            },
            viewUsers: function (pageName = 'users', status = null) {
                this.$root.user_filters = [];
                if (status) {
                    this.$root.addFilter('user_filters', {
                        key: 'status',
                        value: status,
                        text: this.$root.text(status, null, true),
                        filters_key: 'user_filters',
                        hidden: (status == -1)
                    });
                }
                this.$router.push({ name: pageName });
            },
            viewProofs: function (archived = false) {
                this.$root.proof_filters = [];
                if (archived) {
                    this.$root.addFilter('proof_filters', {
                        key: 'archived',
                        value: 1,
                        text: this.$root.text('archived'),
                        filters_key: 'proof_filters',
                        hidden: true
                    });
                }
                this.$router.push({ name: 'proofs' });
            },
            viewProfileGroups: function () {
                this.$root.profile_group_filters = [];
                this.$router.push({ name: 'profile-groups' });
            },
            doNothing: function () {
                return null;
            },
            resetAddUserForm: function () {
                const component = this.$root.findComponent(this.$parent, 'user-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            viewPrintOrders: function () {
                this.$root.print_order_filters = [];
                this.$router.push({ name: 'print-orders' });
            },
            showPrintOrderCustomFields: function () {
                $('.print-order-custom-fields').slideUp(350);
                $('.print-order-custom-field').slideDown(350);
            },
            getPanelPositionOptions: function () {
                const options = [
                    {
                        key: 'left',
                        value: this.$root.text('Left', null, true)
                    },
                    {
                        key: 'center',
                        value: this.$root.text('Center', null, true)
                    },
                    {
                        key: 'right',
                        value: this.$root.text('Right', null, true)
                    }
                ];

                return options;
            },
            getPasswordExpiryOptions: function () {
                const options = [
                    {
                        key: 0,
                        value: this.$root.text('Never', null, true)
                    },
                    {
                        key: 31,
                        value: this.$root.text('1 month', null, true)
                    },
                    {
                        key: 61,
                        value: this.$root.text('2 months', null, true)
                    },
                    {
                        key: 92,
                        value: this.$root.text('3 months', null, true)
                    },
                    {
                        key: 183,
                        value: this.$root.text('6 months', null, true)
                    },
                    {
                        key: 366,
                        value: this.$root.text('1 year', null, true)
                    }
                ];

                return options;
            },
            getInviteExpiryOptions: function () {
                const options = [
                    {
                        key: 2,
                        value: this.$root.text('2 days', null, true)
                    },
                    {
                        key: 3,
                        value: this.$root.text('3 days', null, true)
                    },
                    {
                        key: 7,
                        value: this.$root.text('7 days', null, true)
                    },
                    {
                        key: 14,
                        value: this.$root.text('14 days', null, true)
                    }
                ];

                return options;
            },
            getProofsViewOptions: function () {
                const options = [
                    {
                        key: 'folders',
                        value: this.$root.text('Folders', null, true)
                    },
                    {
                        key: 'proofs',
                        value: this.$root.text('All proofs', null, true)
                    }
                ];

                return options;
            },
            getProofsTypeOptions: function () {
                const options = [
                    {
                        key: 'unordered',
                        value: this.$root.text('Unordered', null, true)
                    },
                    {
                        key: 'ordered',
                        value: this.$root.text('Ordered', null, true)
                    }
                ];

                return options;
            }
        }
    }
</script>
<style scoped>
    .dropdown-menu.limited-height {
        max-height: 250px;
        overflow-y: auto;
    }
</style>
