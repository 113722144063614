<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="artwork-download-modal-label">{{ title }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <loading v-if="!download_data"></loading>

            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div v-if="download_data && !errors">
                <button
                    v-if="!downloading"
                    :key="'download-artwork-button'"
                    class="btn btn-primary btn-block"
                    type="button"
                    @click="downloadFile(download_data)"
                >
                    <i class="far fa-download mr-1"></i> {{ $root.text('Download PDF', null, true) }}
                </button>
                <button
                    v-else
                    :key="'downloading-artwork-button'"
                    class="btn btn-primary btn-block"
                    type="button"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i> {{ $root.text('Downloading PDF', null, true) }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        //props: ['data'],
        data () {
            return {
                title: '',
                download_data: null,
                errors: false,
                downloading: false
            }
        },
        mounted () {
            this.title = this.$root.text('Please wait...', null, true);
        },
        methods: {
            reset: function (title, data) {
                this.title = title;
                this.download_data = data;
            },
            downloadFile: function (download) {
                if (this.downloading) {
                    return null;
                }
                this.errors = false;
                this.downloading = true;
                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'artwork_pdf_preview',
                    item_id: (this.$parent.artwork.id || 0),
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                    $('#artwork-download-modal').modal('hide');
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#artwork-download-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() =>
                    vm.downloading = null
                );
            }
        }
    }
</script>
