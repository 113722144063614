<template>
    <div :class="assetPreviewClass()">
        <div
            class="thumbnail"
            style="cursor: pointer"
            @click="onThumbnailClick()"
        >
            <img :src="image_src" :alt="title">

            <div v-if="canSelect()" :key="'select-overlay'" class="asset-select-overlay">
                <div class="triangle"></div>
                <div
                    class="tick"
                    @click="selectLinkedFile()"
                    v-b-tooltip.hover
                    :title="$root.text('Select', null, true)"
                >
                    <i class="fa-regular fa-check"></i>
                </div>
                <div class="ghost"></div>
            </div>
            <div v-if="canDeselect()" :key="'deselect-overlay'" class="asset-select-overlay deselect">
                <div class="triangle"></div>
                <div
                    class="tick"
                    @click="selectLinkedFile(false)"
                    v-b-tooltip.hover
                    :title="$root.text('Deselect', null, true)"
                >
                    <span :key="'check-icon'" class="check-icon">
                        <i class="fa-regular fa-check"></i>
                    </span>
                    <span :key="'minus-icon'" class="minus-icon">
                        <i class="fa-regular fa-minus"></i>
                    </span>
                </div>
                <div class="ghost"></div>
            </div>
        </div>

        <div class="card-body" style="position: relative">
            <div
                class="title mb-2"
                :id="'linked-file-title' + id"
                @click="viewLinkedFile()"
                style="cursor: pointer"
            >
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="'linked-file-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <div>
                <span
                    :class="($root.isLoggedIn() ? 'asset-info' : 'asset-info stay')"
                    :id="'linked-file-info' + id"
                >
                    <span
                        v-if="extension"
                        class="badge badge-primary font-size-11 mr-1"
                        style="text-transform: uppercase"
                    >
                        {{ extension }}
                    </span>
                    {{ $root.formatFileSize(data.file_size) }}
                </span>
                &nbsp;
            </div>

            <div v-if="$root.isLoggedIn()" class="font-size-22 asset-menu" :id="'linked-file-menu' + id">
                <span class="asset-tools">
                    <span>
                        <a
                            v-if="!downloading"
                            href="#"
                            class="asset-tool mr-1"
                            @click.prevent="initDownload()"
                        >
                            <span v-b-tooltip.hover :title="$root.text('Download', null, true)">
                                <i class="fal fa-download"></i>
                            </span>
                        </a>
                        <span v-else class="asset-tool mr-1">
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </span>
                    </span>
                    <a
                        v-if="$root.hasPermission('assets_send')"
                        href="#"
                        class="asset-tool mr-1"
                        data-toggle="modal"
                        data-target="#asset-share-modal"
                        @click.prevent="shareLinkedFile()"
                    >
                        <span v-b-tooltip.hover :title="$root.text('Share', null, true)">
                            <i class="fal fa-share-alt"></i>
                        </span>
                    </a>
                    <div v-if="canDeleteLinkedFile()" style="display: inline-block">
                        <a
                            v-if="!deleting"
                            href="#"
                            class="asset-tool mr-1"
                            @click.prevent="deleteLinkedFile()"
                        >
                            <span v-b-tooltip.hover :title="$root.text('Delete', null, true)">
                                <i class="fal fa-trash-alt"></i>
                            </span>
                        </a>
                        <span v-else class="asset-tool mr-1">
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </span>
                    </div>
                    <span v-if="asset_menu_mobile_full" class="asset-tools-mobile-toggle ml-1">
                        <a
                            href="#"
                            @click.prevent="toggleAssetTools()"
                        >
                            <span class="font-size-16" v-b-tooltip.hover :title="$root.text('Hide actions', null, true)">
                                <i class="fal fa-times"></i>
                            </span>
                        </a>
                    </span>
                    <span
                        class="asset-tool"
                        :id="'attachment' + id"
                        :style="'position: absolute; right: 0px; top: 1px; z-index: 2'"
                    >
                        <i class="fal fa-paperclip"></i>
                    </span>
                    <b-tooltip :target="'attachment' + id" triggers="hover">
                        {{ $root.text('Attachment', null, true) }}
                    </b-tooltip>
                </span>

                <span class="float-right">
                    <span v-if="!asset_menu_mobile_full" class="asset-tools-mobile-toggle">
                        <a
                            href="#"
                            @click.prevent="toggleAssetTools()"
                        >
                            <span v-b-tooltip.hover :title="$root.text('Actions', null, true)">
                                <i class="far fa-ellipsis-h ml-2"></i>
                            </span>
                        </a>
                    </span>
                    <span class="asset-restricted">
                        <span class="badge badge-primary px-2 py-1 ml-2" style="position: relative; top: -2px">
                            <i class="fal fa-paperclip"></i>
                        </span>
                    </span>
                </span>
            </div>

            <div
                v-if="!$root.isLoggedIn()"
                class="font-size-22 contact-links"
                style="position: absolute; right: 15px; bottom: 6px"
            >
                <a
                    v-if="!downloading"
                    href="#"
                    @click.prevent="initDownload()"
                >
                    <span v-b-tooltip.hover :title="$root.text('Download', null, true)">
                        <i class="fal fa-download"></i>
                    </span>
                </a>
                <span v-else>
                    <i class="fal fa-spinner-third fa-spin"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'selected', 'in_selection', 'is_visible', 'in_share', 'email'],
        data () {
            return {
                id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                extension: null,
                file_type: null,
                asset_menu_mobile_full: false,
                deleting: false,
                downloading: false,
                download_options: [],
                block_thumbnail_click: false
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                if (!vm.in_selection || vm.is_visible) {
                    vm.short_title = vm.title;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            });

            $(document).on('click', '.asset-preview-action-dropdown', function (e) {
                e.stopPropagation();
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            },
            is_visible: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            },
            block_thumbnail_click: function (val) {
                if (val) {
                    var vm = this;
                    setTimeout(function () {
                        vm.block_thumbnail_click = false;
                    }, 200);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    this.id = data.id;
                    this.title = data.title;
                    this.short_title = data.title;
                    this.extension = data.file_extension;
                    this.file_type = data.file_type;
                    this.download_options = [];
                    this.getPreviewImage();
                    var vm = this;
                    if (!this.in_selection || this.is_visible) {
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 500);
                    }
                }
            },
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                const size = (this.extension == 'svg') ? 'svg' : 'lowres';
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.data.collection_id + '/linked-file/' + Math.abs(this.id) + '/preview/' + size;
                if (!this.$root.isLoggedIn() && this.in_share) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: (!this.$root.isLoggedIn() && this.in_share)
                        ? null : { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#linked-file-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#linked-file-title' + this.id)[0].scrollHeight > 20) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            onThumbnailClick: function () {
                if (this.block_thumbnail_click) {
                    return null;
                }
                this.viewLinkedFile();
            },
            viewLinkedFile: function () {
                if (!this.in_selection) {
                    this.$parent.view_asset_from_selection = false;
                }
                this.$parent.view_asset = this.data;
            },
            selectLinkedFile: function (select = true) {
                this.block_thumbnail_click = true;
                if (select) {
                    this.$root.collection_selected_assets.push(this.id);

                    return null;
                }
                const selectedAssets = this.$root.collection_selected_assets;
                let itemIndex = -1;
                selectedAssets.forEach((item, index) => {
                    if (item == this.id) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    selectedAssets.splice(itemIndex, 1);
                }
            },
            initDownload: function () {
                this.downloading = true;
                if (this.download_options.length == 0) {
                    this.getDownloadOptions();

                    return null;
                }
                /**
                 * Send request to API
                 */
                const download = this.download_options[0];
                const data = {
                    item_type: 'collection_linked_file',
                    item_id: Math.abs(this.id),
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();

                        return null;
                    }
                    vm.showError(
                        vm.$root.text('Download', null, true),
                        error.response.data.error ? error.response.data.error
                            : vm.$root.text('Something went wrong, please try again', null, true)
                    );
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            showError: function (title, text) {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: 'error',
                    title: title,
                    text: text
                };
                $('#message-modal').modal('show');
            },
            shareLinkedFile: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const component = this.$root.findComponent(appComponent, 'asset-share');
                    if (component) {
                        component.reset(this.data);
                    }
                }
            },
            toggleAssetTools: function () {
                this.asset_menu_mobile_full = !this.asset_menu_mobile_full;
                if (this.asset_menu_mobile_full) {
                    $('#linked-file-info' + this.id).addClass('hide');
                    $('#linked-file-menu' + this.id).addClass('full');

                    return null;
                }
                $('#linked-file-info' + this.id).removeClass('hide');
                $('#linked-file-menu' + this.id).removeClass('full');
            },
            deleteLinkedFile: function (confirmed = false) {
                if (confirmed) {
                    this.deleting = true;
                    const linkedFiles = [this.id];
                    this.$parent.deleteLinkedFiles(linkedFiles);

                    return null;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: this.$root.text('Delete attachment', null, true),
                    text: this.$root.text('Are you sure you want to delete: {title}?', { title: this.title }, true)
                        + '<br><br>' + this.$root.text('This action cannot be undone.', null, true),
                    component: this,
                    action: 'delete-linked-file'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action = null) {
                this.deleteLinkedFile(true);
            },
            getDownloadOptions: function () {
                /**
                 * Send request to API
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/collection/'
                    + this.data.collection_id + '/linked-file/' + Math.abs(this.id) + '/download-options';
                if (this.in_share && !this.$root.user) {
                    url += '/' + this.in_share;
                    if (this.email) {
                        url += '/' + this.email;
                    }
                }
                var vm = this;

                axios({
                    url: url,
                    headers: vm.$root.user ? { 'Authorization': 'Bearer ' + vm.$root.token } : null
                })
                .then(response => {
                    vm.download_options = response.data.download_options;
                    if (vm.download_options.length > 0) {
                        vm.initDownload();

                        return null;
                    }
                    vm.downloading = false;
                    vm.showError(
                        vm.$root.text('Download', null, true),
                        vm.$root.text('There is no file available to download', null, true)
                    );
                })
                .catch(function (error) {
                    vm.downloading = false;
                    vm.showError(
                        vm.$root.text('Download', null, true),
                        error.response.data.error ? error.response.data.error
                            : vm.$root.text('Something went wrong, please try again', null, true)
                    );
                });
            },
            canDeleteLinkedFile: function () {
                if (!this.$root.isLoggedIn()) {
                    return false;
                }
                if (this.in_share) {
                    return false;
                }

                return this.$root.hasPermission('assets_manage_attachments');
            },
            assetPreviewClass: function () {
                let className = 'card asset-preview in-collection';
                if (this.in_selection) {
                    className += ' in-selection';
                }
                if (this.canSelect()) {
                    className += ' selectable'
                }
                if (this.selected) {
                    className += ' selected';
                }
                if (this.asset_menu_mobile_full) {
                    className += ' highlighted'
                }

                return className;
            },
            canSelect: function () {
                if (this.selected || this.in_share || this.in_selection) {
                    return false;
                }

                return true;
            },
            canDeselect: function () {
                return this.selected;
            }
        }
    }
</script>
<style scoped>
    .asset-preview {
        border: 2px solid #e4e4e4;
        border-radius: 0.35rem;
    }

    .asset-preview.in-selection {
        border-color: #ced4da;
    }

    .asset-preview .thumbnail {
        padding: 0;
        height: 198px;
    }

    .asset-preview .thumbnail img {
        max-width: 100%;
    }

    .asset-preview.in-selection .thumbnail {
        height: 128px;
    }

    .asset-preview.in-selection .thumbnail img {
        max-height: 128px;
    }

    .asset-preview:hover .card-body .asset-info.stay {
        opacity: 1;
    }
</style>
