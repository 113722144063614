<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="collection-share-modal-label">{{ getTitle() }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>

        <div class="modal-body">
            <div v-if="(sent && (type != 'link'))" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>

            <div v-if="(!sent || (type == 'link'))">
                <div
                    v-if="non_shareable_resources.length > 0"
                    class="alert alert-warning pb-0 non-shareable-assets"
                    role="alert"
                >
                    <button type="button" class="close" aria-label="Close" @click="non_shareable_resources = []">
                        <span aria-hidden="true">×</span>
                    </button>
                    <p v-if="non_shareable_resources.length > 1">
                        {{ $root.text('The following {x} non-shareable {resources} will not be shared',
                            { x: non_shareable_resources.length, resources: $root.text($root.contents.asset_title + 's',
                            null, false, 'assets_title') }, true) }}
                    </p>
                    <p v-else>
                        {{ $root.text(
                            'The following non-shareable {resource} will not be shared',
                            { resources: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                            true
                        ) }}
                    </p>
                    <div
                        class="pr-3"
                        style="max-height: 227px; overflow-x: hidden; overflow-y: auto; width: calc(100% + 16px)"
                    >
                        <div class="row">
                            <div
                                v-for="resource in non_shareable_resources"
                                :key="resource.id"
                                class="col-md-4 col-sm-6 col-12"
                            >
                                <non-shareable-asset-image-preview :data="resource"></non-shareable-asset-image-preview>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'email') ? ' active' : '')"
                            id="collection-share-email-tab"
                            data-toggle="tab"
                            href="#collection-share-email"
                            role="tab"
                            aria-controls="collection-share-email"
                            :aria-selected="((type == 'email') ? 'true' : 'false')"
                            @click.prevent="type = 'email'"
                        >{{ $root.text('Email', null, true) }}</a>
                    </li>
                    <li class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'link') ? ' active' : '')"
                            id="collection-share-link-tab"
                            data-toggle="tab"
                            href="#collection-share-link"
                            role="tab"
                            aria-controls="collection-share-link"
                            :aria-selected="((type == 'link') ? 'true' : 'false')"
                            @click.prevent="type = 'link'"
                        >{{ $root.text('Get a link', null, true) }}</a>
                    </li>
                    <!--<li v-if="!isRestricted()" class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'embed') ? ' active' : '')"
                            id="collection-share-embed-tab"
                            data-toggle="tab"
                            href="#collection-share-embed"
                            role="tab"
                            aria-controls="collection-share-embed"
                            :aria-selected="((type == 'embed') ? 'true' : 'false')"
                            @click.prevent="type = 'embed'"
                        >Embed</a>
                    </li>
                    <li v-if="!isRestricted()" class="nav-item">
                        <a
                            :class="'nav-link' + ((type == 'dropbox') ? ' active' : '')"
                            id="collection-share-dropbox-tab"
                            data-toggle="tab"
                            href="#collection-share-dropbox"
                            role="tab"
                            aria-controls="collection-share-dropbox"
                            :aria-selected="((type == 'dropbox') ? 'true' : 'false')"
                            @click.prevent="type = 'dropbox'"
                        >Dropbox</a>
                    </li>-->
                </ul>

                <div v-if="errors" v-html="errors" class="alert alert-danger mt-3 mb-0" role="alert"></div>

                <div class="tab-content pt-3">
                    <div
                        :class="'tab-pane fade' + ((type == 'email') ? ' show active' : '')"
                        id="collection-share-email"
                        role="tabpanel"
                        aria-labelledby="collection-share-email-tab"
                    >
                        <form>
                            <div class="form-group">
                                <label
                                    for="collection-share-email-subject"
                                    id="collection-share-email-subject-label"
                                    class="mandatory-label"
                                >
                                    {{ $root.text('Subject line', null, true) }} *
                                </label>
                                <input
                                    v-model="email_subject"
                                    type="text"
                                    id="collection-share-email-subject"
                                    class="form-control mandatory-field"
                                    :readonly="sending"
                                    v-on:keyup="$root.unHighlightErrors('collection-share-email-subject')"
                                    v-on:change="$root.unHighlightErrors('collection-share-email-subject')"
                                >
                            </div>
                            <div class="form-group dropdown">
                                <label id="collection-share-email-recipients-label" class="mandatory-label">
                                    {{ $root.text('Recipient email(s)', null, true) }} *
                                </label>
                                <div
                                    class="form-control mandatory-field pb-0"
                                    id="collection-share-email-recipients"
                                    @click="focusEmailRecipientKeyword()"
                                    style="height: auto; min-height: 38px"
                                >
                                    <span
                                        v-for="item in email_recipients"
                                        :key="item.email"
                                        :class="emailRecipientClass(item.email)"
                                        v-b-tooltip.hover
                                        title=""
                                    >
                                        <span v-if="!item.name">{{ item.email }}</span>
                                        <span v-else v-b-tooltip.hover :title="item.email">{{ item.name }}</span>
                                        <a
                                            v-if="!sending"
                                            href="#"
                                            v-b-tooltip.hover
                                            :title="$root.text('Remove', null, true)"
                                            @click.prevent="removeEmailRecipient(item)"
                                        >
                                            <i class="far fa-times"></i>
                                        </a>
                                        <span v-if="sending">
                                            <i class="far fa-times"></i>
                                        </span>
                                    </span>

                                    <div style="display: inline-block; position: relative">
                                        <input
                                            v-model="email_recipient_keyword"
                                            type="text"
                                            id="collection-share-email-recipient-keyword"
                                            class="form-control px-1 mb-2"
                                            v-on:focus="searchEmailRecipient(true)"
                                            v-on:blur="focusEmailRecipientKeyword(false)"
                                            style="width: 280px; max-width: calc(100% - 40px)"
                                        >
                                        <button
                                            v-if="email_recipient_keyword"
                                            type="button"
                                            class="btn btn-secondary btn-sm"
                                            v-b-tooltip.hover
                                            :title="$root.text('Add recipient', null, true)"
                                            @click="addEmailRecipient()"
                                            :disabled="sending"
                                            style="padding-top: 1px; padding-bottom: 1px; position: absolute; top: 0"
                                        >
                                            <i class="far fa-plus"></i>
                                        </button>
                                    </div>
                                </div>

                                <div
                                    class="dropdown-menu share-email-recipient-dropdown"
                                    style="width: 280px; max-height: 230px; overflow-y: auto"
                                >
                                    <div v-for="item in email_recipient_results" :key="item.id">
                                        <a
                                            class="dropdown-item"
                                            href="#"
                                            @click.prevent="addEmailRecipient(item)"
                                        >
                                            {{ item.first_name }} {{ item.last_name }}
                                            <p class="small mb-1">{{ item.email }}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="collection-share-email-message">
                                    {{ $root.text('Message', null, true) }}
                                </label>
                                <textarea
                                    v-model="email_message"
                                    id="collection-share-email-message"
                                    class="form-control"
                                    :readonly="sending"
                                    style="height: 120px"
                                ></textarea>
                            </div>
                            <div class="form-group">
                                <label for="collection-share-email-link-expires">
                                    {{ $root.text('Expires', null, true) }}
                                </label>
                                <div class="input-group">
                                    <b-form-datepicker
                                        v-model="email_link_expires"
                                        id="collection-share-email-link-expires"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :label-no-date-selected="$root.text('No date selected', null, true)"
                                        :min="tomorrow()"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit',
                                            'year': 'numeric' }"
                                        :disabled="sending"
                                    ></b-form-datepicker>
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-light dropdown-toggle date-presets-button"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="(updating_link_expiry_date || sending)"
                                        >
                                            {{ $root.text('Preset periods', null, true) }}
                                            <i
                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="preset in getLinkExpiryPresets()"
                                                :key="preset.value"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="setDate('email_link_expires', preset.value)"
                                            >{{ preset.text }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        v-model="email_external_view_only"
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="collection-share-email-external-view-only"
                                        :disabled="sending"
                                    >
                                    <label
                                        class="custom-control-label"
                                        for="collection-share-email-external-view-only"
                                    >
                                        {{ $root.text('View only for non-registered users', null, true) }}
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div
                        :class="'tab-pane fade' + ((type == 'link') ? ' show active' : '')"
                        id="collection-share-link"
                        role="tabpanel"
                        aria-labelledby="collection-share-link-tab"
                    >
                        <div v-if="sent">
                            <form>
                                <div class="form-group">
                                    <label for="collection-share-link-generated">
                                        {{ $root.text('Link to collection', null, true) }}
                                    </label>
                                    <div class="input-group">
                                        <input
                                            v-model="sent"
                                            type="text"
                                            id="collection-share-link-generated"
                                            class="form-control"
                                            :readonly="true"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-secondary"
                                                type="button"
                                                @click="copyLinkToClipBoard()"
                                            >
                                                {{ copy_link_button_text }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <form v-else>
                            <!--<div class="form-group">
                                <label for="collection-share-link-subject">Title for reference</label>
                                <input
                                    v-model="link_subject"
                                    type="text"
                                    id="collection-share-link-subject"
                                    class="form-control"
                                    :readonly="sending"
                                >
                            </div>-->
                            <div class="form-group">
                                <label>{{ $root.text('Access options', null, true) }}</label>
                                <div class="form-control" style="height: auto">
                                    <div class="mb-2">
                                        <div
                                            class="custom-control custom-radio"
                                            id="collection-share-link-access-none-radio"
                                            style="display: inline-block"
                                        >
                                            <input
                                                v-model="link_access"
                                                type="radio"
                                                id="collection-share-link-access-none"
                                                class="custom-control-input"
                                                value="none"
                                                :disabled="(sending || isRestricted())"
                                            >
                                            <label
                                                :class="'custom-control-label' + (isRestricted() ? ' text-muted' : '')"
                                                for="collection-share-link-access-none"
                                            >
                                                {{ $root.text('Anyone (no tracking will be available)', null, true) }}
                                            </label>
                                        </div>
                                        <b-tooltip
                                            v-if="isRestricted()"
                                            target="collection-share-link-access-none-radio"
                                            triggers="hover"
                                        >
                                            {{ $root.text('This collection contains restricted or suspended {resources} '
                                                + 'therefore an external link to it cannot be created',
                                                { resources: $root.text($root.contents.asset_title + 's', null, false,
                                                'assets_title') }, true) }}
                                        </b-tooltip>
                                    </div>
                                    <div class="mb-2">
                                        <div
                                            class="custom-control custom-radio"
                                            id="collection-share-link-access-email-radio"
                                            style="display: inline-block"
                                        >
                                            <input
                                                v-model="link_access"
                                                type="radio"
                                                id="collection-share-link-access-email"
                                                class="custom-control-input"
                                                value="email"
                                                :disabled="(sending || isRestricted())"
                                            >
                                            <label
                                                :class="'custom-control-label' + (isRestricted() ? ' text-muted' : '')"
                                                for="collection-share-link-access-email"
                                            >
                                                {{ $root.text('Anyone (recipient required to enter their email address)',
                                                    null, true) }}
                                            </label>
                                        </div>
                                        <b-tooltip
                                            v-if="isRestricted()"
                                            target="collection-share-link-access-email-radio"
                                            triggers="hover"
                                        >
                                            {{ $root.text('This collection contains restricted or suspended {resources} '
                                                + 'therefore an external link to it cannot be created',
                                                { resources: $root.text($root.contents.asset_title + 's', null, false,
                                                'assets_title') }, true) }}
                                        </b-tooltip>
                                    </div>
                                    <div class="mb-2">
                                        <div class="custom-control custom-radio">
                                            <input
                                                v-model="link_access"
                                                type="radio"
                                                id="collection-share-link-access-user"
                                                class="custom-control-input"
                                                value="user"
                                                :disabled="sending"
                                            >
                                            <label class="custom-control-label" for="collection-share-link-access-user">
                                                {{ $root.text('Only registered users (they will be required to sign in)',
                                                    null, true) }}
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        v-if="($root.client.share_link_3rd_party && share_link_3rd_party_platform)"
                                        class="mb-2"
                                    >
                                        <div
                                            class="custom-control custom-radio"
                                            id="collection-share-link-access-3rd_party-radio"
                                            style="display: inline-block"
                                        >
                                            <input
                                                v-model="link_access"
                                                type="radio"
                                                id="collection-share-link-access-3rd_party"
                                                class="custom-control-input"
                                                value="3rd_party"
                                                :disabled="(sending || isRestricted())"
                                            >
                                            <label
                                                :class="'custom-control-label' + (isRestricted() ? ' text-muted' : '')"
                                                for="collection-share-link-access-3rd_party"
                                            >
                                                {{ $root.text('Get link for {tpp}',
                                                    { tpp: share_link_3rd_party_platform }, true) }}
                                            </label>
                                        </div>
                                        <b-tooltip
                                            v-if="isRestricted()"
                                            target="collection-share-link-access-3rd_party-radio"
                                            triggers="hover"
                                        >
                                            {{ $root.text('This collection contains restricted or suspended {resources} '
                                                + 'therefore an external link to it cannot be created',
                                                { resources: $root.text($root.contents.asset_title + 's', null, false,
                                                'assets_title') }, true) }}
                                        </b-tooltip>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="collection-share-link-link-expires">
                                    {{ $root.text('Expires', null, true) }}
                                </label>
                                <div class="input-group">
                                    <b-form-datepicker
                                        v-model="link_link_expires"
                                        id="collection-share-link-link-expires"
                                        class="form-control"
                                        start-weekday="1"
                                        locale="en-GB"
                                        :label-no-date-selected="$root.text('No date selected', null, true)"
                                        :min="tomorrow()"
                                        :hideHeader="true"
                                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit',
                                            'year': 'numeric' }"
                                        :disabled="sending"
                                    ></b-form-datepicker>
                                    <div class="input-group-append">
                                        <button
                                            class="btn btn-light dropdown-toggle date-presets-button"
                                            type="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            :disabled="(updating_link_expiry_date || sending)"
                                        >
                                            {{ $root.text('Preset periods', null, true) }}
                                            <i
                                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                            ></i>
                                        </button>
                                        <div class="dropdown-menu">
                                            <a
                                                v-for="preset in getLinkExpiryPresets()"
                                                :key="preset.value"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="setDate('link_link_expires', preset.value)"
                                            >{{ preset.text }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="link_access != 'user'" class="form-group">
                                <div class="custom-control custom-checkbox">
                                    <input
                                        v-model="link_external_view_only"
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="collection-share-link-external-view-only"
                                        :disabled="sending"
                                    >
                                    <label
                                        class="custom-control-label"
                                        for="collection-share-link-external-view-only"
                                    >
                                        {{ $root.text('View only for non-registered users', null, true) }}
                                    </label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--<div
                        :class="'tab-pane fade' + ((type == 'embed') ? ' show active' : '')"
                        id="collection-share-embed"
                        role="tabpanel"
                        aria-labelledby="collection-share-embed-tab"
                    >
                        <p>
                            Embed TBD...
                        </p>
                    </div>
                    <div
                        :class="'tab-pane fade' + ((type == 'dropbox') ? ' show active' : '')"
                        id="collection-share-dropbox"
                        role="tabpanel"
                        aria-labelledby="collection-share-dropbox-tab"
                    >
                        <p>
                            {{ $root.text('Coming soon', null, true) }}
                        </p>
                    </div>-->
                </div>
            </div>

            <div v-if="warnings" v-html="warnings" class="alert alert-warning mt-3 mb-0" role="alert"></div>
        </div>

        <div v-if="(!sent || (type == 'link'))" class="modal-footer">
            <span v-if="(type == 'email')">
                <span v-if="!sending">
                    <span>
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="onSubmit()"
                        >
                            <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Send', null, true) }}
                        </button>
                    </span>
                </span>
                <span v-else>
                    <button type="button" class="btn btn-primary">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ $root.text('Send', null, true) }}</span>
                    </button>
                </span>
            </span>
            <span v-if="(type == 'link')">
                <span>
                    <span v-if="(!sent && !sending)">
                        <span>
                            <button
                                type="button"
                                class="btn btn-primary"
                                @click="onSubmit()"
                            >
                                <i class="far fa-link mr-1"></i> {{ $root.text('Generate link', null, true) }}
                            </button>
                        </span>
                    </span>
                    <span v-if="sending">
                        <button type="button" class="btn btn-primary">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Generate link', null, true) }}</span>
                        </button>
                    </span>
                    <span v-if="sent">
                        <span>
                            <span>
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click="done()"
                                >
                                    <i class="far fa-check mr-1"></i> {{ $root.text('Done', null, true) }}
                                </button>
                            </span>
                        </span>
                    </span>
                </span>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                collection: {
                    name: '...'
                },
                type: 'email',
                email_subject: null,
                email_recipients: [],
                email_message: null,
                email_link_expires: null,
                email_external_view_only: false,
                email_recipient_keyword: '',
                block_recipient_keyword_auto_process: false,
                email_recipient_results: [],
                keypress_time: 0,
                updating_link_expiry_date: false,
                link_subject: null,
                link_link_expires: null,
                link_external_view_only: false,
                copy_link_button_text: 'Copy link',
                link_access: 'none',
                highlight_emails: [],
                collection_assets: [],
                collection_linked_files: [],
                has_restricted_asset: false,
                checked_assets_page: 0,
                get_content_response: null,
                share_link_3rd_party_platform: '',
                validating: false,
                warnings: false,
                leave_errors: false,
                non_shareable_resources: []
            }
        },
        mounted () {
            if (this.$root.client.share_link_3rd_party) {
                this.$root.getContent(this, 'share_link_3rd_party_platform');
            }
        },
        watch: {
            errors: function (val) {
                if (!val) {
                    $('.mandatory-label').removeClass('text-danger');
                    $('.mandatory-field').removeClass('field-error');
                }
            },
            type: function (val) {
                if (this.leave_errors) {
                    this.leave_errors = false;

                    return null;
                }
                this.errors = false;
                this.warnings = false;
                if (this.sent) {
                    this.reset(this.colllection, this.collection_assets, this.collection_linked_files, val, false);
                }
            },
            email_recipient_keyword: function (val) {
                this.$root.unHighlightErrors('collection-share-email-recipients');
                if (val) {
                    this.searchEmailRecipient(true);

                    return null;
                }
                $('.share-email-recipient-dropdown').dropdown('hide');
            },
            get_content_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.key == 'share_link_3rd_party_platform') {
                            this.share_link_3rd_party_platform = val.content;
                        }
                    }
                }
            },
            link_access: function (val) {
                if (val == 'user') {
                    this.link_external_view_only = false;
                }
            }
        },
        methods: {
            reset: function (collection, assetIds, linkedFileIds = [], type = null, first = true) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.warnings = false;
                this.validating = false;
                this.copy_link_button_text = this.$root.text('Copy link', null, true);
                if (!first) {
                    return null;
                }
                this.collection = collection;
                this.collection_assets = assetIds;
                this.collection_linked_files = linkedFileIds;
                this.type = type ? type : 'email';
                const itemsCount =
                    ((this.$route.name == 'view-collection') && (this.$root.collection_selected_assets.length > 0))
                    ? this.$root.collection_selected_assets.length
                    : (assetIds.length + linkedFileIds.length);
                if (this.hasAssets()) {
                    if (itemsCount > 1) {
                        this.email_subject = this.$root.text(
                            '{resources} from {name}',
                            {
                                resources: this.$root.text(this.$root.contents.asset_title + 's', null, false,
                                    'assets_title'),
                                name: this.$root.user.first_name + ' ' + this.$root.user.last_name
                            },
                            true
                        );
                    } else {
                        this.email_subject = this.$root.text(
                            '{resource} from {name}',
                            {
                                resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title'),
                                name: this.$root.user.first_name + ' ' + this.$root.user.last_name
                            },
                            true
                        );
                    }
                } else {
                    this.email_subject = this.$root.text(
                        (itemsCount > 1) ? 'files from {name}' : 'file from {name}',
                        { name: this.$root.user.first_name + ' ' + this.$root.user.last_name },
                        true
                    );
                }
                this.email_message = this.$root.text(
                    (itemsCount > 1) ? 'Your files from {name}' : 'Your file from {name}',
                    { name: this.$root.user.first_name + ' ' + this.$root.user.last_name },
                    true
                );
                this.email_recipients = [];
                this.email_link_expires = null;
                this.email_external_view_only = false;
                this.email_recipient_keyword = '';
                this.block_recipient_keyword_auto_process = false;
                this.link_subject = this.email_subject;
                this.link_link_expires = null;
                this.link_external_view_only = false;
                this.highlight_emails = [];
                this.setDate('email_link_expires', '1m');
                this.setDate('link_link_expires', '1m');
                $('#collection-share-' + this.type + '-tab').click();
                this.link_access = 'none';
                this.has_restricted_asset = false;
                this.non_shareable_resources = [];
                if (this.hasAssets()) {
                    this.checked_assets_page = 1;
                    this.checkAssets();
                }
            },
            checkAssets: function () {
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/';
                url += this.collection ? 'cl::' + this.collection.id : 'in::' + this.collection_assets.join(',');
                url += '?page=' + this.checked_assets_page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.total == 0) {
                        return null;
                    }
                    let assetIds = [];
                    if ((vm.$route.name == 'view-collection') && (vm.$root.collection_selected_assets.length > 0)) {
                        vm.$root.collection_selected_assets.forEach((item) => {
                            if (item > 0) {
                                assetIds.push(item);
                            }
                        });
                    } else {
                        assetIds = vm.collection_assets;
                    }
                    response.data.resources.data.forEach((item) => {
                        if (item.restricted || item.suspended) {
                            if (assetIds.indexOf(item.id) > -1) {
                                vm.has_restricted_asset = true;
                                vm.link_access = 'user';
                            }
                        }
                        /*if (!item.template && !item.downloadable) {
                            vm.non_shareable_resources.push(item);
                        }*/
                    });
                    if (!vm.has_restricted_asset && (response.data.resources.last_page > vm.checked_assets_page)) {
                        vm.checked_assets_page++;
                        vm.checkAssets();
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            getTitle: function () {
                if (
                    this.collection
                    && ((this.$route.name != 'view-collection') || (this.$root.collection_selected_assets.length == 0))
                ) {
                    return this.$root.text('Share live collection', null, true);
                }
                const itemsCount =
                    ((this.$route.name == 'view-collection') && (this.$root.collection_selected_assets.length > 0))
                    ? this.$root.collection_selected_assets.length
                    : (this.collection_assets.length + this.collection_linked_files.length);
                if (this.collection) {
                    if (this.hasAssets()) {
                        if (itemsCount > 1) {
                            return this.$root.text(
                                'Share {x} {resources} from this collection: {collection}',
                                {
                                    x: itemsCount,
                                    resources: this.$root.text(this.$root.contents.asset_title + 's', null, false,
                                        'assets_title'),
                                    collection: this.collection.name
                                },
                                true
                            );
                        }
                        return this.$root.text(
                            'Share 1 {resource} from this collection: {collection}',
                            {
                                resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title'),
                                collection: this.collection.name
                            },
                            true
                        );
                    }
                    if (itemsCount > 1) {
                        return this.$root.text(
                            'Share {x} attachments from this collection: {collection}',
                            { x: itemsCount, collection: this.collection.name },
                            true
                        );
                    }
                    return this.$root.text(
                        'Share 1 attachment from this collection: {collection}',
                        { collection: this.collection.name },
                        true
                    );
                }
                if (this.hasAssets()) {
                    if (itemsCount > 1) {
                        return this.$root.text(
                            'Share {x} {resources}',
                            {
                                x: itemsCount,
                                resources: this.$root.text(this.$root.contents.asset_title + 's', null, false,
                                    'assets_title')
                            },
                            true
                        );
                    }
                    return this.$root.text(
                        'Share 1 {resource}',
                        { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                        true
                    );
                }
                if (itemsCount > 1) {
                    return this.$root.text('Share {x} attachments', { x: itemsCount }, true);
                }
                return this.$root.text('Share 1 attachment', null, true);
            },
            onSubmit: function (validate = false) {
                this.errors = false;
                this.highlight_emails = [];
                this.sending = true;
                this.validating = validate;
                this.warnings = false;

                let data = {
                    type: this.type
                };
                if (this.collection) {
                    data.collection_id = this.collection.id;
                }
                if ((this.$route.name == 'view-collection') && (this.$root.collection_selected_assets.length > 0)) {
                    let assetIds = [];
                    let linkedFileIds = [];
                    this.$root.collection_selected_assets.forEach((item) => {
                        if (item < 0) {
                            linkedFileIds.push(Math.abs(item));
                        } else {
                            assetIds.push(item);
                        }
                        if (assetIds.length > 0) {
                            data.resources = assetIds;
                        }
                        if (linkedFileIds.length > 0) {
                            data.linked_files = linkedFileIds;
                        }
                    });
                } else if (!this.collection && (this.collection_assets.length > 0)) {
                    data.resources = this.collection_assets;
                }

                if (this.type == 'email') {
                    data.subject = this.email_subject;
                    data.recipients = this.email_recipients;
                    if (this.email_recipient_keyword) {
                        data.recipients.push({email: this.email_recipient_keyword});
                    }
                    if (this.email_message) {
                        data.message = this.email_message;
                    }
                    if (this.email_link_expires) {
                        data.link_expires = this.email_link_expires;
                    }
                    data.external_view_only = this.email_external_view_only;
                    data.app_info = {
                        app_url: this.$root.app.app_url
                    }
                }
                if (this.type == 'link') {
                    if (this.email_subject) {
                        data.subject = this.email_subject;
                    }
                    if (this.link_link_expires) {
                        data.link_expires = this.link_link_expires;
                    }
                    data.external_view_only = this.link_external_view_only;
                    if ((this.link_access != 'none') && (this.link_access != '3rd_party')) {
                        data.type += ('_' + this.link_access);
                    }
                }

                /**
                 * Send share asset request to API.
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/share';
                if (validate) {
                    url += '/validate';
                }
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (vm.validating) {
                        vm.validating = false;

                        return null;
                    }

                    if (response.data.warnings) {
                        vm.warnings = response.data.warnings.replace(/resource/g, vm.$root.contents.asset_title)
                            .replace(/\|/g, '<br>');
                    }

                    const type = response.data.type.split('_');
                    if (vm.type != type[0]) {
                        $('#collection-share-' + type[0] + '-tab').click();
                        vm.type = type[0];
                    }
                    if (vm.type == 'email') {
                        const itemsCount =
                            ((vm.$route.name == 'view-collection') && (vm.$root.collection_selected_assets.length > 0))
                            ? vm.$root.collection_selected_assets.length
                            : (vm.collection_assets.length + vm.collection_linked_files.length);
                        if (vm.hasAssets()) {
                            if (itemsCount > 1) {
                                vm.sent = vm.$root.text(
                                    '{resources} have been emailed.',
                                    {
                                        resources: vm.$root.text(
                                            vm.$root.contents.asset_title + 's',
                                            null,
                                            false,
                                            'assets_title'
                                        )
                                    },
                                    true
                                );
                            } else {
                                vm.sent = vm.$root.text(
                                    '{resource} has been emailed.',
                                    {
                                        resource: vm.$root.text(
                                            vm.$root.contents.asset_title,
                                            null,
                                            false,
                                            'asset_title'
                                        )
                                    },
                                    true
                                );
                            }
                        } else {
                            vm.sent = vm.$root.text(
                                (itemsCount > 1) ? 'attachments have been emailed.' : 'attachment has been emailed.',
                                null,
                                true
                            );
                        }

                        // Close after 4 seconds automatically.
                        setTimeout(function () {
                            $('#collection-share-modal').modal('hide');

                            return null;
                        }, 4000);

                        return null;
                    }
                    if (vm.type == 'link') {
                        vm.sent = vm.$root.app.app_url + '/shared/' + response.data.access_code;

                        return null;
                    }
                    vm.sent = response.data.message;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#collection-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }

                    const type = error.response.data.type ? error.response.data.type.split('_') : null;
                    if (type) {
                        if (vm.type != type[0]) {
                            vm.leave_errors = true;
                            $('#collection-share-' + type[0] + '-tab').click();
                            vm.type = type[0];
                        }
                    }

                    if ((error.response.status == 406) && (error.response.data.error == 'NotRegistered')) {
                        vm.errors = vm.$root.text(
                            'You can not share this collection externally as it contains restricted or suspended '
                                + '{resources}.',
                            {
                                resources: vm.$root.text(vm.$root.contents.asset_title + 's', null, false,
                                    'assets_title')
                            },
                            true
                        );
                        if (error.response.data.emails) {
                            error.response.data.emails.forEach((email) => {
                                vm.highlight_emails.push(email);
                            });
                        }

                        return null;
                    }

                    if (vm.validating) {
                        vm.validating = false;
                    }

                    // Show error.
                    if (error.response.data.error) {
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error.replace(/resource/g, vm.$root.contents.asset_title);
                            if (error.response.data.warnings) {
                                vm.warnings = error.response.data.warnings
                                    .replace(/resource/g, vm.$root.contents.asset_title).replace(/\|/g, '<br>');
                            }
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#collection-share-' + vm.type + '-' + key + '-label').addClass('text-danger');
                                $('#collection-share-' + vm.type + '-' + key).addClass('field-error');
                            }
                        }
                    } else {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            focusEmailRecipientKeyword: function (focus = true, delayed = false) {
                if (focus) {
                    $('#collection-share-email-recipient-keyword').css('display', 'inline');
                    $('#collection-share-email-recipient-keyword').focus();
                    var vm = this;
                    setTimeout(function () {
                        vm.block_recipient_keyword_auto_process = false;
                    }, 500);

                    return null;
                }

                if (!delayed) {
                    var vm = this;
                    setTimeout(function () {
                        vm.focusEmailRecipientKeyword(false, true);
                    }, 500);

                    return null;
                }
                if (this.block_recipient_keyword_auto_process) {
                    return null;
                }
                if (this.email_recipient_keyword) {
                    this.addEmailRecipient(null, false);
                }
                $('#collection-share-email-recipient-keyword').css('display', 'none');
                $('.share-email-recipient-dropdown').dropdown('hide');
            },
            searchEmailRecipient: function (delay = false) {
                if (!this.email_recipient_keyword) {
                    return null;
                }
                if (delay) {
                    this.keypress_time = new Date();
                    var vm = this;
                    setTimeout(function () {
                        vm.searchEmailRecipient();
                    }, 500);
                }

                const time = new Date();
                if ((time - this.keypress_time) < 500) {
                    return null;
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|approved|!declined|!deleted|!invited|kw::'
                    + btoa(this.email_recipient_keyword);
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.email_recipient_results = [];
                    response.data.users.forEach((item, index) => {
                        if ((item.email != vm.$root.user.email) && !vm.isEmailRecipientAdded(item.email)) {
                            vm.email_recipient_results.push(item);
                        }
                    });
                    if (vm.email_recipient_results.length > 0) {
                        setTimeout(function () {
                            $('.share-email-recipient-dropdown').dropdown('show');
                        }, 200);

                        return null;
                    }
                    $('.share-email-recipient-dropdown').dropdown('hide');
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        $('#collection-share-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                });
            },
            isEmailRecipientAdded: function (email) {
                if (!email || (this.email_recipients.length == 0)) {
                    return false;
                }
                let found = false;
                this.email_recipients.forEach((item) => {
                    if (item.email.toLowerCase() == email.toLowerCase()) {
                        found = true;
                    }
                });

                return found;
            },
            addEmailRecipient: function (user = null, addNew = true) {
                if (!user && !this.email_recipient_keyword) {
                    return null;
                }
                const email = user ? user.email.trim() : this.email_recipient_keyword.trim();

                this.email_recipient_keyword = '';
                this.email_recipient_results = [];
                setTimeout(function () {
                    $('.share-email-recipient-dropdown').dropdown('hide');
                }, 250);

                if ((email.length > 0) && !this.isEmailRecipientAdded(email)) {
                    const recipient = {
                        email: email,
                        name: user ? user.first_name + ' ' + user.last_name : null,
                        user_id : user ? user.id : null
                    };
                    this.email_recipients.push(recipient);
                    this.onSubmit(true);
                }

                if (addNew) {
                    this.block_recipient_keyword_auto_process = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.focusEmailRecipientKeyword();
                    }, 200);
                }
            },
            removeEmailRecipient: function (recipient) {
                this.$root.unHighlightErrors('collection-share-email-recipients');
                this.block_recipient_keyword_auto_process = true;
                let itemIndex = -1;
                this.email_recipients.forEach((item, index) => {
                    if (item == recipient) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.email_recipients.splice(itemIndex, 1);
                }
            },
            tomorrow: function () {
                const newDate = new Date();
                newDate.setDate(newDate.getDate() + 1);

                return newDate;
            },
            setDate: function (dateField, value) {
                this.updating_link_expiry_date = true;
                if (value == 'never') {
                    this[dateField] = null;
                } else {
                    const newDate = new Date();
                    if (value == '1d') {
                        newDate.setDate(newDate.getDate() + 1);
                    }
                    if (value == '7d') {
                        newDate.setDate(newDate.getDate() + 7);
                    }
                    if (value == '14d') {
                        newDate.setDate(newDate.getDate() + 14);
                    }
                    if (value == '1m') {
                        newDate.setMonth(newDate.getMonth() + 1);
                    }
                    if (value == '1y') {
                        newDate.setFullYear(newDate.getFullYear() + 1);
                    }
                    const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                    const day = ('0' + newDate.getDate()).slice(-2);
                    this[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                }
                this.updating_link_expiry_date = false;
            },
            copyLinkToClipBoard: function () {
                let copyText = document.querySelector('#collection-share-link-generated');
                copyText.select();

                try {
                    if (document.execCommand('copy')) {
                        this.copy_link_button_text = this.$root.text('Copied!', null, true);
                    }
                } catch (err) {
                    //
                }

                window.getSelection().removeAllRanges();
            },
            done: function () {
                $('#collection-share-modal').modal('hide');

                return null;
            },
            emailRecipientClass: function (email) {
                let classList = 'badge ';
                classList += (this.highlight_emails.indexOf(email) > -1) ? 'badge-soft-danger'
                    : 'badge-soft-secondary';
                classList += ' contact-links font-size-12 mr-2 mb-2';

                return classList;
            },
            isRestricted: function () {
                return this.has_restricted_asset;
            },
            hasAssets: function () {
                if ((this.$route.name == 'view-collection') && (this.$root.collection_selected_assets.length > 0)) {
                    let assetFound = false;
                    this.$root.collection_selected_assets.forEach((item) => {
                        if (item > 0) {
                            assetFound = true;
                        }
                    });

                    return assetFound;
                }

                return (this.collection_assets.length > 0);
            },
            getLinkExpiryPresets: function () {
                const presets = [
                    {
                        value: '1d',
                        text: this.$root.text('In 1 day', null, true)
                    },
                    {
                        value: '7d',
                        text: this.$root.text('In 1 week', null, true)
                    },
                    {
                        value: '14d',
                        text: this.$root.text('In 2 weeks', null, true)
                    },
                    {
                        value: '1m',
                        text: this.$root.text('In 1 month', null, true)
                    },
                    {
                        value: '1y',
                        text: this.$root.text('In 1 year', null, true)
                    },
                    {
                        value: 'never',
                        text: this.$root.text('Never expires', null, true)
                    }
                ];

                return presets;
            }
        }
    }
</script>
<style scoped>
    #collection-share-email-recipient-keyword {
        display: none;
        width: auto;
        height: 20px;
        border: none;
    }

    #collection-share-email-recipient-keyword:focus {
        background-color: #f8f9fa;
    }

    .modal-body {
        min-height: 350px;
    }
</style>
