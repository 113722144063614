import { render, staticRenderFns } from "./SavedArtworkPreview.vue?vue&type=template&id=66ed59fa&scoped=true"
import script from "./SavedArtworkPreview.vue?vue&type=script&lang=js"
export * from "./SavedArtworkPreview.vue?vue&type=script&lang=js"
import style0 from "./SavedArtworkPreview.vue?vue&type=style&index=0&id=66ed59fa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ed59fa",
  null
  
)

export default component.exports