<template>
    <div class="px-lg-2">
        <div class="row no-gutters">
            <div v-if="$root.hasPermission('homepage_edit')" :class="colClass()">
                <a
                    class="dropdown-icon-item"
                    href="#"
                    @click.prevent="resetAddHomePanelForm()"
                >
                    <i class="fal fa-home fa-2x"></i>
                    <span>{{ $root.text('Homepage panel', null, true) }}</span>
                </a>
            </div>
            <div v-if="$root.hasPermission('assets_upload')" :class="colClass()">
                <a class="dropdown-icon-item" href="#" @click.prevent="addAsset()">
                    <i class="fal fa-copy fa-2x"></i>
                    <span>{{ $root.text($root.contents.asset_title + 's', null, true, 'assets_title') }}</span>
                </a>
            </div>
            <div
                v-if="$root.hasPermission('templates_view') && $root.hasPermission('templates_upload')"
                :class="colClass()"
            >
                <a class="dropdown-icon-item" href="#" @click.prevent="addTemplate()">
                    <i class="fal fa-send-back fa-2x"></i>
                    <span>{{ $root.text($root.contents.template_title, null, true, 'template_title') }}</span>
                </a>
            </div>
            <div
                v-if="($root.hasPermission('assets_access') || $root.hasPermission('templates_view'))"
                :class="colClass()"
            >
                <a
                    class="dropdown-icon-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#collection-add-form-modal"
                    @click.prevent="resetAddCollectionForm()"
                >
                    <i class="fa-light fa-rectangle-history fa-2x"></i>
                    <span>{{ $root.text('Collection', null, true) }}</span>
                </a>
            </div>
            <div v-if="$root.hasPermission('proofs_create')" :class="colClass()">
                <a
                    class="dropdown-icon-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#proof-add-form-modal"
                    @click.prevent="resetAddProofForm()"
                >
                    <i class="fal fa-memo-circle-check fa-2x"></i>
                    <span>{{ $root.text('Proof', null, true) }}</span>
                </a>
            </div>
            <div v-if="$root.hasPermission('users_invite')" :class="colClass()">
                <a
                    class="dropdown-icon-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#user-add-form-modal"
                    @click.prevent="resetAddUserForm()"
                >
                    <i class="fal fa-user-friends fa-2x"></i>
                    <span>{{ $root.text('User', null, true) }}</span>
                </a>
            </div>
            <div v-if="$root.hasPermission('profiles_groups_add')" :class="colClass()">
                <a
                    class="dropdown-icon-item"
                    href="#"
                    data-toggle="modal"
                    data-target="#profile-group-add-form-modal"
                    @click.prevent="resetProfileGroupAddForm()"
                >
                    <i class="fal fa-building fa-2x"></i>
                    <span>{{ $root.text($root.contents.profile_group_title, null, true, 'profile_group_title') }}</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            //
        },
        methods: {
            colClass: function () {
                let numItems = 0;
                if (this.$root.hasPermission('homepage_edit')) {
                    numItems++;
                }
                if (this.$root.hasPermission('assets_upload')) {
                    numItems++;
                }
                if (this.$root.hasPermission('templates_view') && this.$root.hasPermission('templates_upload')) {
                    numItems++;
                }
                if (this.$root.hasPermission('assets_access') || this.$root.hasPermission('templates_view')) {
                    numItems++;
                }
                if (this.$root.hasPermission('proofs_create')) {
                    numItems++;
                }
                if (this.$root.hasPermission('users_invite')) {
                    numItems++;
                }
                if (this.$root.hasPermission('profiles_groups_add')) {
                    numItems++;
                }

                return (numItems > 1) ? 'col-6' : 'col';
            },
            resetAddHomePanelForm: function (redirected = false) {
                if (!redirected) {
                    const isEditMode = this.$root.editing_homepage;
                    if (!isEditMode) {
                        this.$root.editing_homepage = true;
                    }
                    if (this.$route.name == 'home') {
                        var vm = this;
                        setTimeout(function () {
                            vm.resetAddHomePanelForm(true);
                        }, 200);
                        if (!isEditMode) {
                            // Reload panels.
                            const appComponent = this.$root.findComponent(this.$root, 'main-app');
                            const homeComponent = this.$root.findComponent(appComponent, 'home');
                            homeComponent.getPanels();
                        }

                        return null;
                    }
                    this.$router.push({ name: 'home' });
                    var vm = this;
                    setTimeout(function () {
                        vm.resetAddHomePanelForm(true);
                    }, 200);

                    return null;
                }
                $('#home-panel-edit-form-modal').modal('show');
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (appComponent) {
                    const homeComponent = this.$root.findComponent(appComponent, 'home');
                    const component = this.$root.findComponent(homeComponent, 'home-panel-edit-form');
                    component.resetForm();
                }
            },
            resetAddUserForm: function () {
                const component = this.$root.findComponent(this.$parent.$parent.$parent, 'user-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            resetAddCollectionForm: function () {
                const component = this.$root.findComponent(this.$parent.$parent.$parent, 'collection-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            addAsset: function () {
                this.$parent.$parent.$parent.showAssetAddForm();
            },
            addTemplate: function () {
                this.$parent.$parent.$parent.showTemplateAddForm();
            },
            resetProfileGroupAddForm: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'profile-group-add-form');
                if (component) {
                    component.resetForm();
                }
            },
            resetAddProofForm: function () {
                const component = this.$root.findComponent(this.$parent.$parent.$parent, 'proof-add-form');
                if (component) {
                    component.resetForm();
                }
            }
        }
    }
</script>
