<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="asset_details_loading"><loading></loading></div>

        <div v-if="asset_details" class="row">
            <div class="col-xl-2 col-lg-3 col-sm-4 mb-3">
                <div class="bg-light text-center" style="padding: 1px">
                    <img class="img-fluid" :src="image_src" :alt="data.title">
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="mb-0">
                                {{ $root.text($root.contents.folder_title, null, true, 'folder_title') }}
                            </label>
                            <div>{{ (asset_details ? asset_details.folder_name : '') }}</div>
                        </div>
                        <div class="form-group">
                            <label class="mb-0">
                                <span v-if="subfoldersCount() == 1">
                                    {{ $root.text($root.contents.subfolder_title, null, true, 'subfolder_title') }}
                                </span>
                                <span v-else>
                                    {{ $root.text($root.contents.subfolders_title, null, true, 'subfolders_title') }}
                                </span>
                            </label>
                            <div>{{ (asset_details ? asset_details.subfolder_names : '') }}</div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="mb-0">
                                {{ $root.text(
                                    '{resource} type',
                                    { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                    true
                                ) }}
                            </label>
                            <div>{{ (asset_details ? asset_details.resource_type_name : '') }}</div>
                        </div>
                        <div class="form-group">
                            <label class="mb-0">
                                {{ $root.text((keywordsCount() == 1) ? 'Keyword' : 'Keywords', null, true) }}
                            </label>
                            <div>
                                <span v-for="item in getKeywords()" :key="item.index">
                                    <a
                                        href="#"
                                        @click.prevent="keywordSearch(item.keyword)"
                                    >
                                        {{ item.keyword }}</a><span v-if="(item.index < (keywordsCount() - 1))">,
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="data.archived" class="form-group">
                    {{ $root.text(
                        'This {resource} is archived',
                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                        true
                    ) }}
                </div>
            </div>
        </div>

        <button
            v-if="asset_details"
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            @click.prevent="viewAsset()"
        >
            <i class="far fa-eye mr-1"></i>
            {{ $root.text(
                'View {resource}',
                { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                true
            ) }}
        </button>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                errors: false,
                image_src: '/images/file-icon.png',
                asset_details: null,
                asset_details_loading: true
            }
        },
        mounted () {
            this.getAssetDetails();
            this.getPreviewImage();
        },
        watch: {
            //
        },
        methods: {
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                const size = (this.data.file_extension == 'svg') ? 'svg' : 'lowres';
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.data.id
                    + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            viewAsset: function () {
                this.$root.asset_filters = [];
                this.$root.addFilter('asset_filters', {
                    key: 'resource_id',
                    value: this.data.id,
                    text: '&quot;' + this.data.id + '&quot;',
                    filters_key: 'asset_filters'
                });
                if (this.data.archived) {
                    this.$router.push({ name: 'assets-filtered', params: { filters: 'archived' } });

                    return null;
                }
                this.$router.push({ name: 'assets' });
            },
            getAssetDetails: function () {
                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.data.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_details = response.data.resource;
                })
                .catch(function (error) {
                    const assetTitle = vm.$root.text(vm.$root.contents.asset_title, null, false, 'asset_title');
                    vm.errors = vm.$root.text(
                        'Sorry, you no longer have access to this {resource}',
                        { resource: assetTitle },
                        true
                    );
                })
                .finally(() => {
                    vm.asset_details_loading = false;
                });
            },
            subfoldersCount: function () {
                if (!this.asset_details) {
                    return 0;
                }

                return this.asset_details.subfolders.length;
            },
            keywordsCount: function () {
                if (!this.data.keywords) {
                    return 0;
                }
                const keywords = this.data.keywords.split(',');

                return keywords.length;
            },
            getKeywords: function () {
                if (!this.data.keywords) {
                    return [];
                }
                const words = this.data.keywords.split(',');
                let keywords = [];
                words.forEach((item, index) => {
                    keywords.push({
                        index: index,
                        keyword: item
                    });
                });

                return keywords;
            },
            keywordSearch: function (keyword) {
                this.$root.asset_filters = [];
                this.$root.addFilter('asset_filters', {
                    key: 'keyword',
                    value: keyword,
                    text: '&quot;' + keyword.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'asset_filters'
                });
                /*if (this.data.archived) {
                    this.$router.push({ name: 'assets-filtered', params: { filters: 'archived' } });

                    return null;
                }*/
                this.$router.push({ name: 'assets' });
            }
        }
    }
</script>
<style scoped>
    img {
        max-height: 200px;
    }

    @media (max-width: 575px) {
        .btn {
            display: block;
            width: 100%;
        }
    }
</style>
