<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6 v-if="($route.name == 'proofs') && !isFolderView()" :key="'all-proofs'">
                                <span v-if="isArchive()" :key="'proof-archive'">
                                    <i class="fa-light fa-archive font-size-16 mr-1"></i>
                                    {{ $root.text('Proof archive', null, true) }}
                                </span>
                                <span v-else :key="'proof-tracker'">{{ $root.text('Proof tracker', null, true) }}</span>
                            </h6>
                            <h6 v-if="isFolderView()" :key="'folder-proofs'">
                                <span>
                                    <i class="fa-light fa-folder font-size-16 mr-1"></i>
                                    <span v-if="!editing_folder">{{ $root.text('Folder', null, true) }}</span><span
                                        v-if="(canEditFolder() && !editing_folder)"
                                        v-b-tooltip.hover
                                        :title="$root.text('Rename', null, true)"
                                        @click="toggleEditFolder()"
                                    >: {{ getFolderName() }}</span><span
                                        v-if="(!canEditFolder() && !editing_folder)"
                                    >: {{ getFolderName() }}</span>
                                    <span v-if="!editing_folder" class="contact-links">
                                        <a
                                            href="#"
                                            v-b-tooltip.hover
                                            :title="$root.text('Close folder', null, true)"
                                            @click.prevent="viewAllProofs()"
                                        >
                                            <i class="fal fa-times-circle"></i>
                                        </a>
                                    </span>
                                </span>
                            </h6>
                            <div v-if="editing_folder" class="rename-folder">
                                <form v-on:submit.prevent="updateFolder()">
                                    <div class="input-group">
                                        <input
                                            v-model="new_folder_name"
                                            type="text"
                                            class="form-control mandatory-field"
                                            id="new-folder-name"
                                            :placeholder="placeholder('new-folder-name')"
                                            v-on:focus="placeholder('new-folder-name', true)"
                                            v-on:blur="placeholder('new-folder-name', true)"
                                            aria-label="Folder name"
                                            :readonly="updating_proof"
                                            v-on:keyup="$root.unHighlightErrors('new-folder-name')"
                                            v-on:change="$root.unHighlightErrors('new-folder-name')"
                                            style="height: 37px"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                v-if="!updating_folder"
                                                class="btn btn-primary big-icon"
                                                type="submit"
                                                v-b-tooltip.hover
                                                :title="$root.text('Save', null, true)"
                                            >
                                                <strong><i class="fal fa-save"></i></strong>
                                            </button>
                                            <button
                                                v-else
                                                type="button"
                                                class="btn btn-primary"
                                            >
                                                <span>
                                                    <i class="fal fa-spinner-third fa-spin big-icon"></i>
                                                </span>
                                            </button>
                                            <button
                                                class="btn btn-light big-icon"
                                                type="button"
                                                v-b-tooltip.hover
                                                :title="$root.text('Cancel', null, true)"
                                                :disabled="updating_folder"
                                                @click="toggleEditFolder()"
                                            >
                                                <i class="fal fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <h6
                                v-if="($route.name == 'view-proof') || ($route.name == 'view-proof-tab')"
                                :key="'view-proof'"
                            >
                                <span>
                                    <i class="fa-light fa-memo-circle-check font-size-16 mr-1"></i>
                                    <span v-if="!editing_proof">{{ $root.text('Proof', null, true) }}</span><span
                                        v-if="($root.view_proof_title && isLeader() && !editing_proof)"
                                        v-b-tooltip.hover
                                        :title="$root.text('Rename', null, true)"
                                        @click="toggleEditProof()"
                                    >: {{ $root.view_proof_title }}</span><span
                                        v-if="($root.view_proof_title && !isLeader() && !editing_proof)"
                                    >: {{ $root.view_proof_title }}</span>
                                </span>
                            </h6>
                            <div v-if="editing_proof" class="rename-proof">
                                <form v-on:submit.prevent="updateProof()">
                                    <div class="input-group">
                                        <input
                                            v-model="new_proof_title"
                                            type="text"
                                            class="form-control mandatory-field"
                                            id="new-proof-title"
                                            :placeholder="placeholder('new-proof-title')"
                                            v-on:focus="placeholder('new-proof-title', true)"
                                            v-on:blur="placeholder('new-proof-title', true)"
                                            aria-label="Proof title"
                                            :readonly="updating_proof"
                                            v-on:keyup="$root.unHighlightErrors('new-proof-title')"
                                            v-on:change="$root.unHighlightErrors('new-proof-title')"
                                            style="height: 37px"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                v-if="!updating_proof"
                                                class="btn btn-primary big-icon"
                                                type="submit"
                                                v-b-tooltip.hover
                                                :title="$root.text('Save', null, true)"
                                            >
                                                <strong><i class="fal fa-save"></i></strong>
                                            </button>
                                            <button
                                                v-else
                                                type="button"
                                                class="btn btn-primary"
                                            >
                                                <span>
                                                    <i class="fal fa-spinner-third fa-spin big-icon"></i>
                                                </span>
                                            </button>
                                            <button
                                                class="btn btn-light big-icon"
                                                type="button"
                                                v-b-tooltip.hover
                                                :title="$root.text('Cancel', null, true)"
                                                :disabled="updating_proof"
                                                @click="toggleEditProof()"
                                            >
                                                <i class="fal fa-times"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </li>
                    </ul>
                    <ul  class="navbar-nav">
                        <li
                            v-if="($route.name == 'proofs') && !isFolderView()"
                            :class="'nav-item d-none d-md-inline' + (this.hasActions() ? ' pr-2' : '')"
                        >
                            <form v-on:submit.prevent="keywordSearch()">
                                <div style="position: relative">
                                    <div class="input-group">
                                        <input
                                            v-model="keyword"
                                            type="text"
                                            class="form-control"
                                            id="search-by-keyword-top-nav"
                                            :placeholder="$root.text('Search ' + (isArchive() ? 'archived' : 'live')
                                                + ' proofs', null, true)"
                                            aria-label="Filter by title"
                                            style="min-width: 200px; height: 34.5px"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-primary big-icon"
                                                type="submit"
                                                style="height: 34.5px"
                                            >
                                                <i class="fal fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        v-if="keyword"
                                        type="button"
                                        class="btn btn-link clear-keyword"
                                        @click.prevent="clearKeyword()"
                                    >
                                        <i class="far fa-times"></i>
                                    </button>
                                </div>
                            </form>
                        </li>
                        <li v-if="this.hasActions() == 1" class="nav-item" style="height: 36.5px">
                            <div style="position: relative; height: 36.5px; right: 0">
                                <button
                                    v-if="$root.hasPermission('proofs_create')"
                                    :key="'add-proof-button'"
                                    type="button"
                                    class="btn btn-primary"
                                    data-toggle="modal"
                                    data-target="#proof-add-form-modal"
                                    @click.prevent="resetProofAddForm()"
                                >
                                    <i class="fa-light fa-memo-circle-check mr-1"></i>
                                    {{ $root.text('New proof', null, true) }}
                                </button>
                                <button
                                    v-if="isFolderView() || ($route.name != 'proofs')"
                                    :key="'view-all-proofs-button'"
                                    type="button"
                                    class="btn btn-primary"
                                    @click.prevent="viewAllProofs()"
                                >
                                    <i class="fa-light fa-grid-2 mr-1"></i>
                                    {{ $root.text('View all proofs', null, true) }}
                                </button>
                                <button
                                    v-if="!isArchive() && !isFolderView() && ($route.name == 'proofs')
                                        && $root.hasPermission('proofs_view_archive')"
                                    :key="'view-archived-proofs-button'"
                                    type="button"
                                    class="btn btn-primary"
                                    @click.prevent="viewArchivedProofs()"
                                >
                                    <i class="fa-light fa-archive mr-1"></i>
                                    {{ $root.text('View proof archive', null, true) }}
                                </button>
                                <button
                                    v-if="isArchive() && !isFolderView() && ($route.name == 'proofs')"
                                    :key="'view-live-proofs-button'"
                                    type="button"
                                    class="btn btn-primary"
                                    @click.prevent="viewAllProofs(false)"
                                >
                                    <i class="fa-light fa-grid-2 mr-1"></i>
                                    {{ $root.text('View live proofs', null, true) }}
                                </button>
                            </div>
                        </li>
                        <li v-if="this.hasActions() > 1" class="nav-item" style="height: 36.5px">
                            <div class="btn-group" style="position: relative; height: 34.5px">
                                <button
                                    type="button"
                                    class="btn btn-primary dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {{ $root.text('Actions', null, true) }}
                                    <i
                                        class="fas fa-chevron-down"
                                        style="font-size: 9px; position: relative; top: -1px; left: 2px"
                                    ></i>
                                </button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <a
                                        v-if="$root.hasPermission('proofs_create')"
                                        :key="'add-proof'"
                                        class="dropdown-item"
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#proof-add-form-modal"
                                        @click.prevent="resetProofAddForm()"
                                    >
                                        <i class="far fa-memo-circle-check mr-1"></i>
                                        {{ $root.text('New proof', null, true) }}
                                    </a>
                                    <span v-if="canEditFolder()">
                                        <span v-if="!folder.archived">
                                            <span v-if="!archiving_folder">
                                                <a
                                                    :key="'archive-folder'"
                                                    class="dropdown-item"
                                                    href="#"
                                                    @click.prevent="archiveFolder()"
                                                >
                                                    <i class="far fa-archive mr-1"></i>
                                                    {{ $root.text('Archive folder', null, true) }}
                                                </a>
                                            </span>
                                            <span v-else>
                                                <span
                                                    :key="'archiving-folder'"
                                                    style="padding-left: 24px; line-height: 30px"
                                                >
                                                    <i class="far fa-spinner-third fa-spin mr-1"></i> 
                                                    <span style="position: relative; top: 1px">
                                                        {{ $root.text('Archive folder', null, true) }}
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                        <span v-else>
                                            <span v-if="!archiving_folder">
                                                <a
                                                    :key="'dearchive-folder'"
                                                    class="dropdown-item"
                                                    href="#"
                                                    @click.prevent="deArchiveFolder()"
                                                >
                                                    <i class="far fa-archive mr-1"></i>
                                                    {{ $root.text('De-archive folder', null, true) }}
                                                </a>
                                            </span>
                                            <span v-else>
                                                <span
                                                    :key="'dearchiving-folder'"
                                                    style="padding-left: 24px; line-height: 30px"
                                                >
                                                    <i class="far fa-spinner-third fa-spin mr-1"></i> 
                                                    <span style="position: relative; top: 1px">
                                                        {{ $root.text('De-archive folder', null, true) }}
                                                    </span>
                                                </span>
                                            </span>
                                        </span>

                                        <span v-if="!deleting_folder">
                                            <a
                                                :key="'delete-folder'"
                                                class="dropdown-item"
                                                href="#"
                                                @click.prevent="deleteFolder()"
                                            >
                                                <i class="far fa-trash-alt mr-1"></i>
                                                {{ $root.text('Delete folder', null, true) }}
                                            </a>
                                        </span>
                                        <span v-else>
                                            <span :key="'deleting-folder'" style="padding-left: 24px; line-height: 30px">
                                                <i class="far fa-spinner-third fa-spin mr-1"></i> 
                                                <span style="position: relative; top: 1px">
                                                    {{ $root.text('Delete folder', null, true) }}
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                    <div class="dropdown-divider"></div>
                                    <a
                                        v-if="isFolderView() || ($route.name != 'proofs')"
                                        :key="'view-all-proofs'"
                                        class="dropdown-item"
                                        href="#"
                                        @click.prevent="viewAllProofs()"
                                    >
                                        <i class="fa-regular fa-grid-2 mr-1"></i>
                                        {{ $root.text('View all proofs', null, true) }}
                                    </a>
                                    <a
                                        v-if="!isArchive() && !isFolderView() && ($route.name == 'proofs')
                                            && $root.hasPermission('proofs_view_archive')"
                                        :key="'view-archived-proofs'"
                                        class="dropdown-item"
                                        href="#"
                                        @click.prevent="viewArchivedProofs()"
                                    >
                                        <i class="fa-regular fa-archive mr-1"></i>
                                        {{ $root.text('View proof archive', null, true) }}
                                    </a>
                                    <a
                                        v-if="isArchive() && !isFolderView() && ($route.name == 'proofs')"
                                        :key="'view-live-proofs'"
                                        class="dropdown-item"
                                        href="#"
                                        @click.prevent="viewAllProofs(false)"
                                    >
                                        <i class="fa-regular fa-grid-2 mr-1"></i>
                                        {{ $root.text('View live proofs', null, true) }}
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['current_route'],
        data () {
            return {
                editing_proof: false,
                new_proof_title: '',
                updating_proof: false,
                editing_folder: false,
                new_folder_name: '',
                updating_folder: false,
                folder: null,
                loading_folder: false,
                archiving_folder: false,
                deleting_folder: false,
                keyword: '',
            }
        },
        mounted () {
            //
        },
        watch: {
            current_route: function () {
                this.editing_proof = false;
                this.editing_folder = false;
            }
        },
        methods: {
            hasActions: function () {
                let actions = 0;
                if (this.$root.hasPermission('proofs_create')) {
                    // New proof.
                    actions++;
                }
                if (this.isFolderView() || (this.$route.name != 'proofs')) {
                    // View all proofs.
                    actions++;
                }
                if (
                    !this.isArchive()
                    && !this.isFolderView()
                    && (this.$route.name == 'proofs')
                    && this.$root.hasPermission('proofs_view_archive')
                ) {
                    // View archived proofs.
                    actions++;
                }
                if (this.isArchive() && !this.isFolderView() && (this.$route.name == 'proofs')) {
                    // View live proofs.
                    actions++;
                }
                if (this.canEditFolder()) {
                    // Archive & delete folder.
                    actions += 2;
                }

                return actions;
            },
            resetProofAddForm: function () {
                const component = this.$root.findComponent(this.$parent.$parent, 'proof-add-form');
                if (component) {
                    component.resetForm(this.isFolderView() ? this.getFolderId() : null);
                }
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'new-proof-title':
                        text = this.$root.text('Proof title', null, true);
                        break;
                    case 'new-folder-name':
                        text = this.$root.text('Folder name', null, true);
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            toggleEditProof: function () {
                if (this.editing_proof) {
                    this.editing_proof = false;

                    return null;
                }
                if (this.$root.view_proof_title != '...') {
                    this.new_proof_title = this.$root.view_proof_title;
                    this.editing_proof = true;
                    setTimeout(function () {
                        $('#new-proof-title').focus();
                    }, 200);
                }
            },
            updateProof: function () {
                $('.mandatory-field').removeClass('field-error');
                let proof = null;
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (!appComponent) {
                    return null;
                }
                const component = this.$root.findComponent(appComponent, 'view-proof');
                if (!component) {
                    return null;
                }
                proof = component.proof;
                this.updating_proof = true;
                /**
                 * Send update proof request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof/'
                    + this.$route.params.id;
                const data = {
                    title: this.new_proof_title,
                    leader_alerts: proof.leader_alerts,
                    review_alerts_interval: proof.review_alerts_interval,
                    target_date: proof.target_date,
                    version_target_date: proof.version_target_date
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$root.view_proof_title = vm.new_proof_title;
                    vm.toggleEditProof();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        let errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                errors += value.join('<br>') + '<br>';
                                $('#new-proof-' + key).addClass('field-error');
                            }
                        }
                        vm.$parent.$parent.message = {
                            title: vm.$root.text('Rename proof', null, true),
                            type: 'error',
                            text: errors,
                        };
                        $('#message-modal').modal('show');
                    }
                })
                .finally(() => {
                    vm.updating_proof = false;
                });
            },
            canEditFolder: function () {
                if (!this.isFolderView()) {
                    return false;
                }
                if (!this.folder) {
                    return false;
                }

                return (this.folder.created_by == this.$root.user.id);
            },
            toggleEditFolder: function () {
                if (this.editing_folder) {
                    this.editing_folder = false;

                    return null;
                }
                this.new_folder_name = this.getFolderName();
                this.editing_folder = true;
                setTimeout(function () {
                    $('#new-folder-name').focus();
                }, 200);
            },
            updateFolder: function () {
                $('.mandatory-field').removeClass('field-error');
                this.updating_folder = true;
                /**
                 * Send update proof folder request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof-folder/'
                    + this.getFolderId();
                const data = {
                    name: this.new_folder_name
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folder.name = vm.new_folder_name;
                    vm.toggleEditFolder();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        let errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                errors += value.join('<br>') + '<br>';
                                $('#new-folder-' + key).addClass('field-error');
                            }
                        }
                        vm.$parent.$parent.message = {
                            title: vm.$root.text('Rename folder', null, true),
                            type: 'error',
                            text: errors,
                        };
                        $('#message-modal').modal('show');
                    }
                })
                .finally(() => {
                    vm.updating_folder = false;
                });
            },
            isLeader: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (!appComponent) {
                    return false;
                }
                const component = this.$root.findComponent(appComponent, 'view-proof');
                if (component) {
                    return component.isLeader();
                }

                return false;
            },
            isFolderView: function () {
                if (this.$route.name != 'proofs') {
                    return false;
                }
                let found = false;
                this.$root.proof_filters.forEach((item) => {
                    if (item.key == 'folder') {
                        found = item.value;
                    }
                });

                if (found && (!this.folder || (this.folder.id != found)) && !this.loading_folder) {
                    this.getFolder();
                }

                return found;
            },
            isArchive: function () {
                let found = false;
                this.$root.proof_filters.forEach((item) => {
                    if (item.key == 'archived') {
                        found = true;
                    }
                });

                return found;
            },
            getFolderId: function () {
                if (this.folder) {
                    return this.folder.id;
                }
                let folderId = '';
                this.$root.proof_filters.forEach((item) => {
                    if (item.key == 'folder') {
                        folderId = item.value;
                    }
                });

                return folderId;
            },
            getFolderName: function () {
                if (this.folder) {
                    return this.folder.name;
                }
                let folderName = '';
                this.$root.proof_filters.forEach((item) => {
                    if (item.key == 'folder') {
                        folderName = item.text;
                    }
                });

                return folderName;
            },
            viewAllProofs: function (keepArchived = true) {
                this.editing_folder = false;
                let filters = [];
                if (keepArchived) {
                    this.$root.proof_filters.forEach((item) => {
                        if ((item.key == 'archived') || (item.key == 'user') || (item.key == 'sort_by')) {
                            filters.push(item);
                        }
                    });
                }
                this.$root.proof_filters = filters;
                this.getProofs();
            },
            viewArchivedProofs: function () {
                this.$root.proof_filters = [];
                this.$root.addFilter('proof_filters', {
                    key: 'archived',
                    value: 1,
                    text: this.$root.text('archived'),
                    filters_key: 'proof_filters',
                    hidden: true
                });
                this.getProofs();
            },
            getFolder: function () {
                this.folder = null;
                this.loading_folder = true;

                /**
                 * Send get proof folder request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof-folder/'
                    + this.getFolderId();
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folder = response.data.proof_folder;
                    vm.loading_folder = false;
                })
                .catch(function (error) {
                    //
                });/*
                .finally(() =>
                    vm.loading_folder = false
                );*/
            },
            archiveFolder: function (confirmed = false) {
                if (confirmed) {
                    this.archiving_folder = true;
                    /**
                     * Send archive proof folder request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof-folder/'
                        + this.getFolderId() + '/archive';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.folder = null;
                        vm.viewAllProofs();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();

                            return null;
                        }
                        let appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        appComponent.message = {
                            type: 'error',
                            title: vm.$root.text('Archive folder', null, true),
                            text: error.response.data.error
                                || vm.$root.text('Something went wrong, please try again', null, true),
                            component: vm,
                        };
                        $('#message-modal').modal('show');
                    })
                    .finally(() =>
                        vm.archiving_folder = false
                    );

                    return null;
                }
                let appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: this.$root.text('Archive folder', null, true),
                    text: this.$root.text(
                        'Are you sure you want to archive this folder? All proofs within will also be archived.',
                        null,
                        true
                    ),
                    component: this,
                    action: 'archive-folder'
                };
                $('#confirm-delete-modal').modal('show');
            },
            deArchiveFolder: function (confirmed = false) {
                if (confirmed) {
                    this.archiving_folder = true;
                    /**
                     * Send de-archive proof folder request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof-folder/'
                        + this.getFolderId() + '/de-archive';
                    var vm = this;

                    axios({
                        method: 'put',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.folder = null;
                        vm.viewAllProofs();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();

                            return null;
                        }
                        let appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        appComponent.message = {
                            type: 'error',
                            title: vm.$root.text('De-archive folder', null, true),
                            text: error.response.data.error
                                || vm.$root.text('Something went wrong, please try again', null, true),
                            component: this,
                        };
                        $('#message-modal').modal('show');
                    })
                    .finally(() =>
                        vm.archiving_folder = false
                    );

                    return null;
                }
                let appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: this.$root.text('De-archive folder', null, true),
                    text: this.$root.text(
                        'Are you sure you want to de-archive this folder? All proofs within will also be de-archived.',
                        null,
                        true
                    ),
                    component: this,
                    action: 'dearchive-folder'
                };
                $('#confirm-delete-modal').modal('show');
            },
            deleteFolder: function (confirmed = false) {
                if (confirmed) {
                    this.deleting_folder = true;
                    /**
                     * Send delete proof folder request to API.
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/proof-folder/'
                        + this.getFolderId();
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        vm.folder = null;
                        vm.viewAllProofs();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user.
                            vm.$root.doLogout();

                            return null;
                        }
                        let appComponent = vm.$root.findComponent(vm.$root, 'main-app');
                        appComponent.message = {
                            type: 'error',
                            title: vm.$root.text('Delete folder', null, true),
                            text: error.response.data.error
                                || vm.$root.text('Something went wrong, please try again', null, true),
                            component: this,
                        };
                        $('#message-modal').modal('show');
                    })
                    .finally(() =>
                        vm.deleting_folder = false
                    );

                    return null;
                }
                let appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.confirm_delete = {
                    title: this.$root.text('Delete folder', null, true),
                    text: this.$root.text(
                        'Deleting this folder will remove all its ' + (this.folder.archived ? '' : 'live and archived ')
                            + 'proofs. Do you wish to continue?',
                        null,
                        true
                    ),
                    component: this,
                    action: 'delete-folder'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                if (action == 'archive-folder') {
                    this.archiveFolder(true);

                    return null;
                }
                if (action == 'dearchive-folder') {
                    this.deArchiveFolder(true);

                    return null;
                }
                if (action == 'delete-folder') {
                    this.deleteFolder(true);

                    return null;
                }
            },
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                if (keywordTrimmed == '') {
                    return null;
                }
                let keywordsAdded = [];
                this.$root.proof_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsAdded.push(item.value);
                    }
                });
                if (keywordsAdded.indexOf(keywordTrimmed) > -1) {
                    return null;
                }
                this.$root.addFilter('proof_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'proof_filters'
                });
                this.keyword = '';
                this.getProofs();
            },
            clearKeyword: function () {
                this.keyword = '';
                $('#search-by-keyword-top-nav').focus();
            },
            getProofs: function () {
                if (this.$route.name == 'proofs') {
                    const appComponent = this.$root.findComponent(this.$root, 'main-app');
                    const component = this.$root.findComponent(appComponent, 'proofs');
                    if (component) {
                        component.getProofs();
                    }

                    return null;
                }
                this.$router.push({ name: 'proofs' });
            }
        }
    }
</script>
<style scoped>
    .rename-proof, .rename-folder {
        position: absolute;
        top: 8px;
        left: 25px;
        width: 280px;
        background-color: #fff;
        z-index: 1;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }
</style>
