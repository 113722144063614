<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-type-delete-modal-label">
                {{ $root.text(
                    'Delete {resource} type:',
                    { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                    true
                ) }}
                {{ asset_type_name }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="deleted" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="deleted"></div>
                </div>
            </div>
            <form v-else v-on:submit.prevent="deleteAssetType()">
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <div v-if="loading"><loading></loading></div>

                <div v-else>
                    <p v-if="(assets_live_count + assets_archived_count) > 0">
                        <span v-if="(assets_live_count + assets_archived_count) > 1">
                            <span v-if="(assets_live_count > 0) && (assets_archived_count > 0)">
                                {{ $root.text(
                                    'This action permanently deletes the {resource} type with {x} live and '
                                        + '{y} archived {resources}.',
                                    {
                                        resource: $root.text($root.contents.asset_title, null, false, 'asset_title'),
                                        x: assets_live_count,
                                        y: assets_archived_count,
                                        resources: $root.text(
                                            $root.contents.asset_title + 's',
                                            null,
                                            false,
                                            'assets_title'
                                        )
                                    },
                                    true
                                ) }}
                            </span>
                            <span v-if="(assets_live_count > 0) && (assets_archived_count == 0)">
                                {{ $root.text(
                                    'This action permanently deletes the {resource} type with {x} live {resources}.',
                                    { resource: $root.text($root.contents.asset_title, null, false,
                                    'asset_title'), x: assets_live_count, resources: $root.text(
                                    $root.contents.asset_title + 's', null, false, 'assets_title') }, true) }}
                            </span>
                            <span v-if="(assets_live_count == 0) && (assets_archived_count > 0)">
                                {{ $root.text('This action permanently deletes the {resource} type with {x} archived '
                                    + '{resources}.', { resource: $root.text($root.contents.asset_title, null, false,
                                    'asset_title'), x: assets_archived_count, resources: $root.text(
                                    $root.contents.asset_title + 's', null, false, 'assets_title') }, true) }}
                            </span>
                        </span>
                        <span v-else>
                            <span v-if="assets_live_count > 0">
                                {{ $root.text('This action permanently deletes the {resource} type with 1 live ',
                                    + '{resource}.', { resource: $root.text($root.contents.asset_title, null, false,
                                    'asset_title') }, true) }}
                            </span>
                            <span v-else>
                                {{ $root.text('This action permanently deletes the {resource} type with 1 archived ',
                                    + '{resource}.', { resource: $root.text($root.contents.asset_title, null, false,
                                    'asset_title') }, true) }}
                            </span>
                        </span>
                    </p>
                    <p v-else>
                        {{ $root.text('This action permanently deletes the {resource} type.',
                            { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') }, true) }}
                    </p>

                    <p v-if="((assets_live_count > 0) || (assets_archived_count > 0))">
                        {{ $root.text('If you need to manage {resources} you should do this first.',
                            { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title') },
                            true) }}
                    </p>
                    <p v-if="(assets_live_count > 0)">
                        {{ $root.text('You can reassign live {resources} to other {resource} types by adding them to a '
                            + 'collection and using the Bulk actions function.',
                            { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title'),
                            resource: $root.text($root.contents.asset_title, null, false, 'asset_title') }) }}
                    </p>
                </div>
            </form>
        </div>
        <div v-if="!deleted" class="modal-footer">
            <span v-if="!deleting">
                <span>
                    <button type="button" class="btn btn-primary mr-2" @click="deleteAssetType()" :disabled="loading">
                        <i class="far fa-trash-alt mr-1"></i>
                        {{ $root.text(
                            'Delete {resource} type',
                            { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                            true
                        ) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">
                        {{ $root.text(
                            'Delete {resource} type',
                            { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                            true
                        ) }}
                    </span>
                </button>
            </span>
            <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="deleting">
                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel') }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                asset_type_id: 0,
                asset_type_name: '',
                assets_live_count: 0,
                assets_archived_count: 0,
                deleting: false,
                deleted: false
            }
        },
        mounted () {
            //
        },
        methods: {
            reset: function (assetType) {
                this.loading = true;
                this.asset_type_id = assetType.id;
                this.asset_type_name = assetType.name;
                this.assets_live_count = 0;
                this.assets_archived_count = 0;
                this.deleting = false;
                this.deleted = false;
                this.getAssetInfo();
            },
            getAssetInfo: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource-type/'
                    + this.asset_type_id + '/resource-info';
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_live_count = response.data.live;
                    vm.assets_archived_count = response.data.archived;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-type-delete-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            deleteAssetType: function () {
                this.deleting = true;
                /**
                 * Send delete asset type request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource-type/'
                    + this.asset_type_id;
                var vm = this;

                axios({
                    method: 'delete',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.deleted = response.data.message;
                    vm.$parent.getAssetTypes();

                    setTimeout(function () {
                        $('#asset-type-delete-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#asset-type-delete-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.deleting = false;
                });
            }
        }
    }
</script>
