<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(items.length > 0)">
            <div v-if="approval_info" class="alert alert-info" v-html="approval_info"></div>

            <div v-for="item in items" :key="item.id" class="dz-uploaded-file p-2 mb-1">
                <div class="dz-thumbnail">
                    <span v-if="!hasPreview(item)">
                        <img
                            :src="getImageSrc(item)"
                            class="img-fluid"
                            alt=""
                        >
                    </span>
                    <img
                        v-else
                        :src="item.image_src"
                        class="img-fluid"
                        alt=""
                        v-on:mouseover="viewImageIcon(item.id, true)"
                        v-on:mouseout="viewImageIcon(item.id, false)"
                    >
                    <div
                        v-if="item.image_src"
                        class="view-image-icon"
                        :id="'view-previous-version-image-icon' + item.id"
                        v-b-tooltip.hover
                        :title="$root.text('Enlarge', null, true)"
                        @click="viewImage(item)"
                        v-on:mouseover="viewImageIcon(item.id, true)"
                        v-on:mouseout="viewImageIcon(item.id, false)"
                    >
                        <i class="fal fa-expand-arrows"></i>
                    </div>
                </div>
                <div class="dz-filename">
                    {{ item.title }}
                    <div
                        v-b-tooltip.hover
                        :title="$root.text('Original version replaced on {date} by {name}',
                            { date: formatDate(item.created_at), name: item.replaced_by_name }, true)"
                    >
                        <span style="white-space: nowrap">
                            <i class="far fa-retweet-alt fa-fw"></i> {{ formatDate(item.created_at) }}
                        </span> &nbsp;&nbsp;
                        <span style="white-space: nowrap">
                            <i class="far fa-user fa-fw"></i> {{ item.replaced_by_name }}
                        </span>
                    </div>
                </div>
                <div class="dz-size">
                    <span
                        class="badge badge-primary font-size-11 mr-2"
                        style="text-transform: uppercase; position: relative; top: -2px"
                    >
                        {{ item.file_extension }}
                    </span>
                    {{ $root.formatFileSize(item.file_size) }}
                </div>
                <div class="dz-remove">
                    <span v-if="hasLinkedFiles(item)" class="mr-2">
                        <a
                            v-if="(linked_files[item.id].length > 0)"
                            href="#"
                            data-toggle="modal"
                            data-target="#asset-previous-version-linked-files-modal"
                            @click.prevent="showLinkedFiles(item)"
                        >
                            <span v-b-tooltip.hover :title="$root.text('View attachments', null, true)">
                                <i class="fal fa-paperclip"></i>
                            </span>
                        </a>
                    </span>

                    <a
                        v-if="(approval_status != 'awaiting-approval') && ($root.hasPermission('assets_download')
                            && (downloading != item.file_path))"
                        href="#"
                        @click.prevent="downloadPreviousVersion(item)"
                    >
                        <span v-b-tooltip.hover :title="$root.text('Download', null, true)">
                            <i class="fal fa-download"></i>
                        </span>
                    </a>
                    <span v-if="(approval_status == 'awaiting-approval')">
                        <i class="fal fa-download"></i>
                    </span>
                    <span v-if="(downloading == item.file_path)">
                        <a
                            href="#"
                            @click.prevent="doNothing(item)"
                        >
                            <i class="fal fa-spinner-third fa-spin"></i>
                        </a>
                    </span>

                    <span v-if="$root.hasPermission('assets_delete_versions')" class="ml-2">
                        <a
                            href="#"
                            v-b-tooltip.hover
                            :title="$root.text('Delete', null, true)"
                            @click.prevent="deletePreviousVersion(item)"
                        >
                            <i class="fal fa-trash-alt"></i>
                        </a>
                    </span>
                </div>
            </div>
        </div>

        <loading v-if="loading"></loading>
    </div>
</template>

<script>
    export default {
        props: ['data', 'approval_info', 'approval_status'],
        data () {
            return {
                errors: false,
                item_to_delete: null,
                loading: true,
                downloading: null,
                items: [],
                linked_files: {},
                items_loaded: 0
            }
        },
        mounted () {
            this.updateData();
        },
        watch: {
            data: function (val) {
                if (val.length > 0) {
                    this.updateData();
                }
            }
        },
        methods: {
            updateData: function () {
                this.loading = true;
                let newItems = [];
                this.items_loaded = 0;
                this.data.forEach((item, index) => {
                    item.index = index;
                    item.linked_files = [];
                    newItems.push(item);
                    if (this.$root.hasPermission('assets_view_attachments')) {
                        this.getLinkedFiles(item);
                    } else {
                        this.items_loaded++;
                    }
                });
                this.items = newItems;
                if ((newItems.length > 0) && this.$root.hasPermission('assets_view_attachments')) {
                    return null;
                }
                this.loading = false;
            },
            hasPreview: function (item) {
                return (item.image_src.indexOf('data:') == 0);
            },
            getImageSrc: function (item) {
                return (item.image_src || '/images/file-icon.png');
            },
            viewImage: function (item) {
                this.viewImageIcon(item.id, false);

                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                appComponent.message = {
                    type: null,
                    title: item.title,
                    text: '<div class="text-center"><img src="' + item.image_src
                        + '" alt="" class="img-fluid" style="max-height: 500px"></div>'
                };
                $('#message-modal > div').addClass('modal-lg');
                $('#message-modal').modal('show');
                $('.modal-backdrop').css({ 'z-index': '1060', 'opacity': '0.25' });
            },
            viewImageIcon: function (itemId, show) {
                if (show) {
                    $('#view-previous-version-image-icon' + itemId).show();
                } else {
                    $('#view-previous-version-image-icon' + itemId).hide();
                }
            },
            deletePreviousVersion: function (item, confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete previous version request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                        + '/resource/' + item.asset_id + '/previous-version/' + item.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        // Reload previous versions
                        vm.items = [];
                        vm.$parent.previous_versions = [];
                        vm.$parent.getPreviousVersions();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = vm.$root.text('Error deleting previous version, please try again', null, true);
                    });

                    return null;
                }

                this.item_to_delete = item;
                this.$parent.$parent.$parent.confirm_delete = {
                    title: this.$root.text('Delete previous version', null, true),
                    text: this.$root.text('Are you sure you want to delete: {title}?', { title: item.title }, true),
                    component: this,
                    action: 'delete-previous-version'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deletePreviousVersion(this.item_to_delete, true);
            },
            downloadPreviousVersion: function (item) {
                if (this.downloading) {
                    return null;
                }

                if (this.approval_status == 'requires-approval') {
                    const component = this.$root.findComponent(
                        this.$parent.$parent.$parent,
                        'asset-approval-request-form'
                    );
                    if (component) {
                        component.resetForm(this.$parent.asset, this.$parent);
                        setTimeout(function() {
                            $('#asset-approval-request-form-modal').modal('show');
                        }, 200);
                    }
                    
                    return null;
                }

                this.downloading = item.file_path;

                /**
                 * Send request to API
                 */
                const data = {
                    item_type: 'resource_version',
                    item_id: item.id,
                    file_path: item.file_path,
                    file_type: item.file_type,
                    download_filename: item.download_filename + '.' + item.file_extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/' + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            doNothing: function () {
                return null;
            },
            getLinkedFiles: function (previousVersion) {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + previousVersion.asset_id + '/linked-files/' + previousVersion.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items_loaded++;
                    let linkedFiles = [];
                    let previousVersionId = null;
                    response.data.linked_files.forEach((item, index) => {
                        vm.items.forEach((apvItem, apvIndex) => {
                            if (item.asset_previous_version_id == apvItem.id) {
                                linkedFiles.push(item);
                                if (!previousVersionId) {
                                    previousVersionId = apvItem.id;
                                }
                            }
                        });
                    });
                    if (previousVersionId) {
                        vm.linked_files[previousVersionId] = linkedFiles;
                    }
                    if (vm.items_loaded == vm.items.length) {
                        vm.loading = false;
                    }
                })
                .catch(function (error) {
                    //
                });
            },
            hasLinkedFiles: function (item) {
                return (item.id in this.linked_files);
            },
            showLinkedFiles: function (item) {
                this.$parent.previous_version_linked_files = {
                    title: item.title,
                    items: this.linked_files[item.id]
                }
            }
        }
    }
</script>
<style scoped>
    .dz-uploaded-file {
        cursor: default;
    }

    .dz-uploaded-file .dz-filename {
        width: calc(100% - 155px);
    }

    .dz-uploaded-file .dz-size {
        bottom: 10px;
        left: 158px;
    }

    .dz-uploaded-file .dz-remove {
        bottom: 8px;
    }
</style>
