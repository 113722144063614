var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topnav page-topnav",staticStyle:{"padding":"0 12px","max-height":"none","overflow-y":"visible"}},[_c('div',{staticClass:"container-fluid"},[_c('nav',{staticClass:"navbar navbar-light navbar-expand topnav-menu"},[_c('div',{staticClass:"navbar-collapse align-items-center justify-content-between"},[_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item"},[_c('h6',[((_vm.$route.name == 'folders-archived'))?_c('span',[_vm._v("\n                                "+_vm._s(_vm.$root.text(
                                    'Archived {folders} and {subfolders}',
                                    {
                                        folders: _vm.$root.text(
                                            _vm.$root.contents.folders_title,
                                            null,
                                            false,
                                            'folders_title'
                                        ),
                                        subfolders: _vm.$root.text(
                                            _vm.$root.contents.subfolders_title,
                                            null,
                                            false,
                                            'subfolders_title'
                                        )
                                    },
                                    true
                                ))+"\n                            ")]):_c('span',[_vm._v("\n                                "+_vm._s(_vm.$root.text(
                                    'Live {folders} and {subfolders}',
                                    {
                                        folders: _vm.$root.text(
                                            _vm.$root.contents.folders_title,
                                            null,
                                            false,
                                            'folders_title'
                                        ),
                                        subfolders: _vm.$root.text(
                                            _vm.$root.contents.subfolders_title,
                                            null,
                                            false,
                                            'subfolders_title'
                                        )
                                    },
                                    true
                                ))+"\n                            ")])])])]),_vm._v(" "),_c('ul',{staticClass:"navbar-nav"},[_c('li',{staticClass:"nav-item",staticStyle:{"width":"100px","height":"36.5px"}},[_c('div',{staticClass:"btn-group",staticStyle:{"position":"absolute","height":"36.5px","right":"0"}},[_c('button',{staticClass:"btn btn-primary dropdown-toggle",attrs:{"type":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v("\n                                "+_vm._s(_vm.$root.text('Actions', null, true))+"\n                                "),_c('i',{staticClass:"fas fa-chevron-down",staticStyle:{"font-size":"9px","position":"relative","top":"-1px","left":"2px"}})]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-menu-right"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#","data-toggle":"modal","data-target":"#folder-add-form-modal"},on:{"click":function($event){$event.preventDefault();return _vm.resetFolderAddForm()}}},[_c('i',{staticClass:"far fa-folder-plus mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'Add {folder}',
                                        {
                                            folder: _vm.$root.text(
                                                _vm.$root.contents.folder_title,
                                                null,
                                                false,
                                                'folder_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]),_vm._v(" "),((_vm.$route.name == 'folders'))?_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'folders-archived' }}},[_c('i',{staticClass:"far fa-archive mr-1"}),_vm._v("\n                                    "+_vm._s(_vm.$root.text(
                                        'View archived {folders}',
                                        {
                                            folders: _vm.$root.text(
                                                _vm.$root.contents.folders_title,
                                                null,
                                                false,
                                                'folders_title'
                                            )
                                        },
                                        true
                                    ))+"\n                                ")]):_vm._e(),_vm._v(" "),((_vm.$route.name == 'folders-archived'))?_c('span',[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'folders' }}},[_c('i',{staticClass:"far fa-folders mr-1"}),_vm._v("\n                                        "+_vm._s(_vm.$root.text(
                                            'View live {folders}',
                                            {
                                                folders: _vm.$root.text(
                                                    _vm.$root.contents.folders_title,
                                                    null,
                                                    false,
                                                    'folders_title'
                                                )
                                            },
                                            true
                                        ))+"\n                                    ")])],1):_vm._e()],1)])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }