<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul
                        class="navbar-nav"
                        :style="'width: calc(100% - ' + topNavRhsWidth() + 'px)'"
                    >
                        <li class="nav-item pb-2" style="width: 100%; min-height: 52px">
                            <h6 class="contact-links pt-3 pb-1">
                                <span v-if="hasSharedByInfo()">
                                    {{ $root.text('{user_name} has sent you {x} {itm}',
                                        { user_name: $root.share_info.shared_by.name,
                                        x: $root.share_info.number_of_items,
                                        itm: $root.text($root.share_info.items_title) }) }}
                                </span>
                                &nbsp;
                            </h6>
                            <h6 v-if="hasSharedByInfo()" class="contact-links pt-3 pb-1">
                                <i class="fal fa-envelope font-size-16 mr-1"></i>
                                <a
                                    :href="'mailto:' + $root.share_info.shared_by.email"
                                    style="position: relative; top: -1px"
                                >
                                    {{ $root.share_info.shared_by.email }}
                                </a>
                            </h6>
                            <h6 v-if="hasSharedByInfo('telephone')" class="contact-links pt-3 pb-1">
                                <i class="fal fa-phone font-size-16 mr-1"></i>
                                <span style="position: relative; top: -1px">
                                    {{ $root.share_info.shared_by.telephone }}
                                </span>
                            </h6>
                            <h6 v-if="$root.share_info.expires_at" class="contact-links pt-3 pb-1">
                                <i class="fal fa-timer font-size-16 mr-1"></i>
                                <span style="position: relative; top: -1px">
                                    {{ $root.text('Expiry date:', null, true) }}
                                    {{ formatDate($root.share_info.expires_at) }}
                                </span>
                            </h6>
                        </li>
                    </ul>
                    <ul v-if="canDownloadAll()" class="navbar-nav">
                        <li class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0; bottom: 7px">
                                <span v-if="!isDownloading()">
                                    <span>
                                        <button type="button" class="btn btn-primary" @click="downloadAll()">
                                            <i class="far fa-download mr-1"></i>
                                            {{ $root.text('Download all', null, true) }}
                                        </button>
                                    </span>
                                </span>
                                <span v-else>
                                    <button type="button" class="btn btn-primary">
                                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                        <span style="opacity: 0.5">{{ $root.text('Download all', null, true) }}</span>
                                    </button>
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['flyte_downloading'],
        data () {
            return {
                downloading: false
            }
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            hasSharedByInfo: function (field = null) {
                if (!this.$root.share_info) {
                    return false;
                }
                if (!this.$root.share_info.shared_by) {
                    return false;
                }
                if (field) {
                    if (this.$root.share_info.shared_by[field]) {
                        return true;
                    }

                    return false;
                }

                return true;
            },
            canDownloadAll: function () {
                if (!this.$root.share_info.number_of_items) {
                    return false;
                }
                if (this.$root.share_info.number_of_items < 2) {
                    return false;
                }
                if (this.$root.share_info.is_flyte) {
                    return true;
                }
                if (!this.$root.share_info.external_view_only) {
                    return true;
                }
                if (!this.$root.isLoggedIn()) {
                    return false;
                }
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (!appComponent) {
                    return false;
                }
                const sharedComponent = this.$root.findComponent(appComponent, 'shared');
                if (!sharedComponent) {
                    return false;
                }
                let hasDownloadOnlyAsset = false;
                sharedComponent.share.resources.forEach((item) => {
                    if (item.download_only) {
                        hasDownloadOnlyAsset = true;
                    }
                });

                return !hasDownloadOnlyAsset;
            },
            downloadAll: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                if (!appComponent) {
                    return null;
                }
                const sharedComponent = this.$root.findComponent(appComponent, 'shared');
                if (!sharedComponent) {
                    return null;
                }
                if (this.$root.share_info.is_flyte) {
                    sharedComponent.downloadFlyte();

                    return null;
                }
                const component = this.$root.findComponent(appComponent, 'collection-download');
                if (component) {
                    this.downloading = true;
                    const collection = {
                        id: this.$root.share_info.collection_id || 0,
                        name: 'Shared ' + this.$root.share_info.items_title
                    };
                    let email = null;
                    if (!this.$root.isLoggedIn()) {
                        if (this.$route.params.email) {
                            email = this.$route.params.email;
                        }
                    }
                    component.initData(
                        this,
                        collection,
                        sharedComponent.share.id,
                        email
                    );
                }
            },
            isDownloading: function () {
                if (this.$root.share_info.is_flyte && this.flyte_downloading) {
                    return true;
                }

                return this.downloading;
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0, 10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            topNavRhsWidth: function () {
                if (!this.$root.share_info.number_of_items || !this.$root.is_sm_screen) {
                    return 0;
                }
                let width = 0;
                if (this.canDownloadAll()) {
                    width += 140;
                }

                return width;
            }
        }
    }
</script>
<style scoped>
    h6.contact-links {
        display: inline-block;
    }

    @media (max-width: 575px) {
        h6.contact-links {
            font-size: 14px;
        }
    }
</style>
