<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="reset-user-modal-label">
                <span v-if="sent">
                    {{ $root.text('Reset email sent to {email}', { email: user.email }, true) }}
                </span>
                <span v-else>
                    {{ $root.text('Reset user', null, true) }}
                </span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent">
                <form v-if="link">
                    <p>
                        {{ $root.text('To use your own email to send them a link, copy the link below.', null, true) }}
                    </p>
                    <p>{{ $root.text('This link will expire at {exp}.', { exp: link_expires_at_formatted }, true) }}.</p>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{ $root.text('Password reset link', null, true) }}</span>
                        </div>
                        <input
                            v-model="link"
                            type="text"
                            id="password-reset-link"
                            class="form-control"
                            :readonly="true"
                        >
                        <div class="input-group-append">
                            <button
                                class="btn btn-secondary"
                                type="button"
                                @click="copyLinkToClipBoard()"
                            >
                                {{ copy_link_button_text }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                {{ $root.text('Do you want to reset this user? This action cannot be undone.', null, true) }}
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-circle-notch mr-1"></i> {{ $root.text('Reset', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Reset', null, true) }}</span>
                </button>
            </span>
            <button type="button" class="btn btn-light ml-2" data-dismiss="modal" :disabled="sending">
                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                user: {},
                link: null,
                link_expires_at_formatted: null,
                copy_link_button_text: 'Copy link'
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (user) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.user = user;
                this.link = null;
                this.link_expires_at_formatted = null;
                this.copy_link_button_text = this.$root.text('Copy link', null, true);

                $('#reset-user-modal .modal-dialog').removeClass('modal-lg');
            },
            onSubmit: function () {
                this.errors = false;
                this.sending = true;

                /**
                 * Send reset user request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user/' + this.user.id + '/reset';
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = true;

                    // Reload user list.
                    vm.$parent.getUsers();

                    if (response.data.code) {
                        vm.link = vm.$root.app.app_url + '/set-password/' + response.data.code;
                        vm.link_expires_at_formatted = response.data.password_reset_expires_at_formatted;
                        $('#reset-user-modal .modal-dialog').addClass('modal-lg');

                        return null;
                    }

                    // close after 4 seconds automatically
                    setTimeout(function () {
                        $('#reset-user-modal').modal('hide');
                    }, 4000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    // show error
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            copyLinkToClipBoard: function () {
                let copyText = document.querySelector('#password-reset-link');
                copyText.select();

                try {
                    if (document.execCommand('copy')) {
                        this.copy_link_button_text = this.$root.text('Copied!', null, true);
                    }
                } catch (err) {
                    //
                }

                window.getSelection().removeAllRanges();

                var vm = this;
                setTimeout(function () {
                    vm.copy_link_button_text = vm.$root.text('Copy link', null, true);
                }, 2000);
            }
        }
    }
</script>
