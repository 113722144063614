<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            {{ $root.text('There are no {resources} awaiting approval',
                { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title') }, true) }}
        </div>
        <div v-if="(total_items > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-md-2 col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text(
                                                '{resource} No.',
                                                {
                                                    resource: $root.text(
                                                        $root.contents.asset_title,
                                                        null,
                                                        false,
                                                        'asset_title'
                                                    )
                                                },
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-md-7 col-sm-5 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Title', null, true) }}
                                        </div>
                                        <div class="col-md-2 col-sm-3 col-12">
                                            {{ $root.text('Submitted on', null, true) }}
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.approval_id">
                                <td scope="row" :id="'approval_row' + item.approval_id" class="pb-0">
                                    <div class="row">
                                        <div class="col-md-2 col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ item.asset_id }}
                                        </div>
                                        <div class="col-md-7 col-5 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleApprovalDetails(item)"
                                                >
                                                    {{ item.title }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-md-2 col-3 d-none d-sm-block" style="padding-top: 3px">
                                            {{ formatDate(item.requested_at) }}
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <p class="mb-1">{{ item.approval_id }}</p>
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleApprovalDetails(item)"
                                                >
                                                    {{ item.title }}
                                                </a>
                                            </h5>
                                            <p class="mb-0">
                                                {{ formatDate(item.requested_at) }}
                                            </p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleApprovalDetails(item)"
                                            >
                                                <span
                                                    v-if="(approval_details_open.indexOf(item.approval_id) < 0)"
                                                    :key="'close-' + item.approval_id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.approval_id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'approval-details' + item.approval_id">
                                            <my-account-asset-approval-details :data="item"></my-account-asset-approval-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'my-account-assets-awaiting-approval',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                approval_details_open: [],
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getApprovals();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            total_items: function (val) {
                this.$root.status_info.right = val + ' ' + (
                    (val == 1)
                    ? this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title')
                    : this.$root.text(this.$root.contents.asset_title + 's', null, false, 'assets_title')
                );
            }
        },
        methods: {
            getApprovals: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;
                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.approval_details_open = [];
                }

                /**
                 * Send get asset approvals request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/report/resource-access-requests/!declined|!approved|ur::' + this.$root.user.id + '?page=' + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.requests.total;
                    vm.current_page = response.data.requests.current_page;
                    vm.last_page = response.data.requests.last_page;
                    response.data.requests.data.forEach((item) => {
                        vm.items.push(item);
                    });
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            formatDate: function (rawDate) {
                const date = rawDate.substr(0 ,10).split('-');

                return date[2] + '/' + date[1] + '/' + date[0];
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getApprovals(this.current_page + 1);
                    }
                }
            },
            toggleApprovalDetails: function (item) {
                if (this.approval_details_open.indexOf(item.approval_id) < 0) {
                    this.approval_details_open.push(item.approval_id);
                    $('#approval_row' + item.approval_id).addClass('highlighted');
                    $('#approval-details' + item.approval_id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#approval-details' + item.approval_id).slideUp('slow', function () {
                        $('#approval_row' + item.approval_id).removeClass('highlighted');
                        for (var n = 0; n < vm.approval_details_open.length; n++) { 
                            if (vm.approval_details_open[n] == item.approval_id) { 
                                vm.approval_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            }
        }
    }
</script>
