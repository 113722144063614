<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="subfolder-de-archive-modal-label">
                {{ $root.text(
                    'De-archive {subfolder}:',
                    { subfolder: $root.text($root.contents.subfolder_title, null, false, 'subfolder_title') },
                    true
                ) }}
                {{ subfolder_name }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="archived" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="archived"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="isFolderArchived()">
                    {{ $root.text(
                        'You cannot de-archive this {subfolder} as its {folder} is archived.',
                        {
                            subfolder: $root.text($root.contents.subfolder_title, null, false, 'subfolder_title'),
                            folder: $root.text($root.contents.folder_title, null, false, 'folder_title')
                        },
                        true
                    ) }}
                </div>
                <form v-else v-on:submit.prevent="deArchiveSubfolder()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div v-if="loading"><loading></loading></div>

                    <div v-else>
                        <p>{{ additionalThings() }}</p>
                        <p v-if="(assets_archived_count > 0)">
                            {{ $root.text(
                                'If you need to manage {resources} you should do this first.',
                                { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title') },
                                true
                            ) }}
                        </p>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="(!archived && !isFolderArchived())" class="modal-footer">
            <span v-if="!archiving">
                <span>
                    <button type="button" class="btn btn-primary mr-2" @click="deArchiveSubfolder()" :disabled="loading">
                        <i class="far fa-archive mr-1"></i>
                        {{ $root.text(
                            'De-archive {subfolder}',
                            { subfolder: $root.text($root.contents.subfolder_title, null, false, 'subfolder_title') },
                            true
                        ) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">
                        {{ $root.text(
                            'De-archive {subfolder}',
                            { subfolder: $root.text($root.contents.subfolder_title, null, false, 'subfolder_title') },
                            true
                        ) }}
                    </span>
                </button>
            </span>
            <button type="button" class="btn btn-light" data-dismiss="modal" :disabled="archiving">
                <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: false,
                errors: false,
                folder_id: 0,
                subfolder_id: 0,
                subfolder_name: '',
                assets_archived_count: 0,
                archiving: false,
                archived: false,
                list_component: null
            }
        },
        mounted () {
            //
        },
        methods: {
            reset: function (subfolder, listComponent) {
                this.loading = true;
                this.folder_id = subfolder.folder_id;
                this.subfolder_id = subfolder.id;
                this.subfolder_name = subfolder.name;
                this.list_component = listComponent;
                this.assets_archived_count = 0;
                this.archiving = false;
                this.archived = false;
                if (!this.isFolderArchived()) {
                    this.getAssetInfo();
                }
            },
            getAssetInfo: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/subfolder/' + this.subfolder_id + '/de-archive/resource-info';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.assets_archived_count = response.data.archived;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#subfolder-de-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            additionalThings: function () {
                let text = 'This action de-archives the {subfolder}';
                let params = {
                    subfolder: this.$root.text(this.$root.contents.subfolder_title, null, false, 'subfolder_title')
                };
                if (this.assets_archived_count > 1) {
                    text += ' with {x} {resources}';
                    params.x = this.assets_archived_count;
                    params.resources = this.$root.text(
                        this.$root.contents.asset_title + 's',
                        null,
                        false,
                        'assets_title'
                    );
                }
                if (this.assets_archived_count == 1) {
                    text += ' with 1 {resource}';
                    params.resource = this.$root.text(this.$root.contents.asset_title, null, false, 'assets_title');
                }

                return this.$root.text(text + '.', params, true);
            },
            deArchiveSubfolder: function () {
                this.archiving = true;
                /**
                 * Send archive subfolder request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/' + this.folder_id
                    + '/subfolder/' + this.subfolder_id + '/de-archive';
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.archived = response.data.message;
                    vm.list_component.getSubfolders();
                    vm.$root.folders_changed = true;

                    setTimeout(function () {
                        $('#subfolder-de-archive-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        $('#subfolder-de-archive-modal').modal('hide');
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.archiving = false;
                });
            },
            isFolderArchived: function () {
                if (!this.list_component) {
                    return false;
                }

                return this.list_component.data.archived;
            }
        }
    }
</script>
