var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[((_vm.report == 'registrations'))?_c('div',[(!_vm.user)?_c('loading'):_c('user-edit-form',{attrs:{"data":_vm.user,"company_name":_vm.client_company_name,"roles":_vm.roles,"groups":_vm.user_groups,"profiles":_vm.profile_groups,"is_visible":true}})],1):_vm._e(),_vm._v(" "),((_vm.report == 'visits'))?_c('div',{staticClass:"table-responsive"},[(_vm.items.length == 0)?_c('loading'):_c('table',{staticClass:"table table-centered table-hover"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"py-2",attrs:{"scope":"col"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-6"},[_vm._v(_vm._s(_vm.$root.text('Date', null, true)))]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.$root.text('Device', null, true)))])])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"pt-2",staticStyle:{"padding-bottom":"5px"},attrs:{"scope":"row"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-6",staticStyle:{"padding-top":"1px"}},[_vm._v("\n                                "+_vm._s(item.created_at_formatted)+"\n                            ")]),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n                                "+_vm._s(item.device)+"\n                            ")])])])])}),0)])],1):_vm._e(),_vm._v(" "),((_vm.report == 'asset-downloads'))?_c('div',{staticClass:"table-responsive"},[(_vm.items.length == 0)?_c('loading'):_c('table',{staticClass:"table table-centered table-hover"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"py-2",attrs:{"scope":"col"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_vm._v(_vm._s(_vm.$root.text('User', null, true)))]),_vm._v(" "),(_vm.$root.isFeatured('profiles'))?_c('div',{staticClass:"col-4"},[_vm._v("\n                                "+_vm._s(_vm.$root.text(
                                    _vm.$root.contents.profile_group_title,
                                    null,
                                    true,
                                    'profile_group_title'
                                ))+"\n                            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.$root.text('Download date', null, true)))])])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"pt-2",staticStyle:{"padding-bottom":"5px"},attrs:{"scope":"row"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4",staticStyle:{"padding-top":"1px"}},[_vm._v("\n                                "+_vm._s(item.first_name ? item.first_name + ' ' + item.last_name
                                    : (item.download_email ? item.download_email : 'n/a'))+"\n                            ")]),_vm._v(" "),(_vm.$root.isFeatured('profiles'))?_c('div',{staticClass:"col-4"},[_vm._v("\n                                "+_vm._s(item.profile_group_name)+"\n                            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n                                "+_vm._s(item.created_at_formatted)+"\n                            ")])])])])}),0)])],1):_vm._e(),_vm._v(" "),((_vm.report == 'artwork-created'))?_c('div',{staticClass:"table-responsive"},[(_vm.items.length == 0)?_c('loading'):_c('table',{staticClass:"table table-centered table-hover"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"py-2",attrs:{"scope":"col"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4"},[_vm._v(_vm._s(_vm.$root.text('User', null, true)))]),_vm._v(" "),(_vm.$root.isFeatured('profiles'))?_c('div',{staticClass:"col-4"},[_vm._v("\n                                "+_vm._s(_vm.$root.text(
                                    _vm.$root.contents.profile_group_title,
                                    null,
                                    true,
                                    'profile_group_title'
                                ))+"\n                            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.$root.text('Created on', null, true)))])])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"pt-2",staticStyle:{"padding-bottom":"5px"},attrs:{"scope":"row"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4",staticStyle:{"padding-top":"1px"}},[_vm._v("\n                                "+_vm._s(item.first_name + ' ' + item.last_name)+"\n                            ")]),_vm._v(" "),(_vm.$root.isFeatured('profiles'))?_c('div',{staticClass:"col-4"},[_vm._v("\n                                "+_vm._s(item.profile_group_name)+"\n                            ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._v("\n                                "+_vm._s(item.created_at_formatted)+"\n                            ")])])])])}),0)])],1):_vm._e(),_vm._v(" "),((_vm.report == 'orders'))?_c('div',[_c('print-order-details',{attrs:{"data":_vm.details,"is_visible":_vm.visible,"view_only":true}})],1):_vm._e(),_vm._v(" "),((_vm.report == 'flytes-sent'))?_c('div',[(_vm.visible)?_c('flyte-sent-details',{attrs:{"share":_vm.details}}):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }