<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="language-add-form-modal-label">
                {{ $root.text('Add language', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body" style="min-height: 400px">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="$root.hasPermission('manage_languages')" v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label for="language-name" id="language-name-label" class="mandatory-label">
                            {{ $root.text('Name', null, true) }} *
                        </label>
                        <input
                            v-model="language.name"
                            type="text"
                            id="language-name"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('language-name')"
                            v-on:change="$root.unHighlightErrors('language-name')"
                        >
                    </div>
                    <div class="form-group">
                        <label for="language-code" id="language-code-label" class="mandatory-label">
                            {{ $root.text('Code', null, true) }} *
                        </label>
                        <searchable-option-list
                            :key="'language-code-' + ($root.current_language || 0)"
                            v-model="language.code"
                            :value="language.code"
                            :options="getCodeOptions()"
                            field_id="language-code"
                            field_class="mandatory-field"
                            :readonly="updating"
                            :placeholder="$root.text('Select a language code', null, true)"
                            v-on:change="$root.unHighlightErrors('language-code')"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="language.active"
                                type="checkbox"
                                class="custom-control-input"
                                id="language-active"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" for="language-active">
                                {{ $root.text('Active', null, true) }}
                            </label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="($root.hasPermission('manage_languages') && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> {{ $root.text('Add', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Add', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                language: {}
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (user) {
                if (!this.$root.hasPermission('manage_languages')) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.language = {
                    name: '',
                    code: null,
                    active: false
                };
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                setTimeout(function () {
                    $('#language-name').focus();
                }, 500);
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API.
                 */
                const data = this.language;
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/language';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.$root.text('Language added', null, true);
                    vm.$parent.getLanguages();

                    setTimeout(function () {
                        $('#language-add-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // Missing/lost API token - close modal and logout user.
                            $('#language-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#language-' + key + '-label').addClass('text-danger');
                                    $('#language-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            getCodeOptions: function () {
                const options = [
                    {
                        id: 'de-DE',
                        name: 'de-DE ' + this.$root.text('(German)')
                    },
                    {
                        id: 'en-US',
                        name: 'en-US ' + this.$root.text('(English US)')
                    },
                    {
                        id: 'es-ES',
                        name: 'es-ES ' + this.$root.text('(Spanish)')
                    },
                    {
                        id: 'fr-FR',
                        name: 'fr-FR ' + this.$root.text('(French)')
                    },
                    {
                        id: 'it-IT',
                        name: 'it-IT ' + this.$root.text('(Italian)')
                    },
                    {
                        id: 'nl-NL',
                        name: 'nl-NL ' + this.$root.text('(Dutch)')
                    },
                    {
                        id: 'sv-SE',
                        name: 'sv-SE ' + this.$root.text('(Swedish)')
                    }
                ];

                return options;
            }
        }
    }
</script>
