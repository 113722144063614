<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <p v-if="(!errors && !loading)">
            <span v-if="getLowestRole()">
                {{ $root.text('Applicants with an email domain listed below get automatic approval as {role}.',
                    { role: getLowestRole() }, true) }}
            </span>
            <span v-else>
                {{ $root.text('Applicants with an email domain listed below get automatic approval.', null, true) }}
            </span>
        </p>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            {{ $root.text('There are no domains added', null, true) }}
        </div>

        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-11">{{ $root.text('Existing domains', null, true) }}</div>
                                        <div class="col-1">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'domain-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-11" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a href="#" class="text-dark" @click.prevent="toggleEditDomain(item)">
                                                    {{ item.domain }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-1 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditDomain(item)"
                                            >
                                                <span
                                                    v-if="(domain_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'domain-details' + item.id">
                                            <pre-approved-email-domain-edit-form
                                                :data="item"
                                            ></pre-approved-email-domain-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="pre-approved-email-domain-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="pre-approved-email-domain-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <pre-approved-email-domain-add-form></pre-approved-email-domain-add-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'pre-approved-email-domains',
                loading: false,
                errors: false,
                roles: [],
                items: [],
                domain_details_open: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getRoles();
        },
        methods: {
            getRoles: function () {
                this.loading = true;
                /**
                 * Send get roles request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/settings/roles';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.roles = response.data.roles;
                })
                .catch(function (error) {
                    // Do anything?
                })
                .finally(() => {
                    vm.getDomains();
                });
            },
            getDomains: function () {
                if (!this.$root.hasPermission('users_pre_approved_email_domains')) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                this.loading = true;
                this.errors = false;
                this.items = [];
                this.domain_details_open = [];

                /**
                 * Send request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                    + '/pre-approved-email-domains';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.items = response.data.domains;
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            toggleEditDomain: function (folder) {
                if (this.domain_details_open.indexOf(folder.id) < 0) {
                    this.domain_details_open.push(folder.id);
                    $('#domain-row' + folder.id).addClass('highlighted');
                    $('#domain-details' + folder.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#domain-details' + folder.id).slideUp('slow', function () {
                        $('#domain-row' + folder.id).removeClass('highlighted');
                        for (var n = 0; n < vm.domain_details_open.length; n++) { 
                            if (vm.domain_details_open[n] == folder.id) { 
                                vm.domain_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            getLowestRole: function () {
                if (this.roles.length == 0) {
                    return null;
                }

                let roleIndex = -1;
                let numPermissions = 9999;
                this.roles.forEach((item, index) => {
                    if (item.permissions.length < numPermissions) {
                        roleIndex = index;
                        numPermissions = item.permissions.length;
                    }
                });

                if (roleIndex > -1) {
                    return this.roles[roleIndex].name;
                }

                return null;
            }
        }
    }
</script>
