<template>
    <div class="card asset-preview select-item">
        <div class="thumbnail">
            <img :src="image_src" :alt="title">
        </div>

        <div class="card-body" style="position: relative">
            <div class="title mb-2" :id="prefix + 'asset-title' + id">
                {{ short_title }}
            </div>

            <b-tooltip
                v-if="(short_title != title)"
                :target="prefix + 'asset-title' + id"
                triggers="hover"
            >
                {{ title }}
            </b-tooltip>

            <div>
                <span
                    class="asset-info stay"
                    :id="prefix + 'asset-info' + id"
                >
                    <span v-if="(id > 0)">{{ id }}</span>
                    <span
                        v-if="extension"
                        :class="'badge badge-primary font-size-11' + ((id > 0) ? ' ml-1' : '')"
                        style="text-transform: uppercase"
                    >
                        {{ extension }}
                    </span>
                </span>
                &nbsp;
            </div>

            <div
                class="font-size-18 contact-links select-asset"
                style="position: absolute; right: 15px; bottom: 6px"
            >
                <span v-if="!readonly" :key="'remove-image-on'">
                    <a
                        :key="'remove' + id"
                        href="#"
                        @click.prevent="remove()"
                        v-b-tooltip.hover
                        :title="$root.text('Remove image', null, true)"
                    >
                        <i class="fal fa-trash-alt"></i>
                    </a>
                </span>
                <span v-else :key="'remove-image-off'">
                    <i class="fal fa-trash-alt"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'readonly'],
        data () {
            return {
                id: 0,
                original_id: 0,
                image_src: '/images/file-icon.png',
                title: '',
                short_title: '',
                extension: null,
                file_type: null,
                prefix: 'manage-image-'
            }
        },
        mounted () {
            this.updateData(this.data);
            var vm = this;
            $(window).resize(function () {
                if (!vm.in_selection || vm.is_visible) {
                    vm.short_title = vm.title;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            });
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            updateData: function (data) {
                if (data) {
                    if ('is_asset' in data) {
                        this.id = data.is_asset ? data.id : 'l-' + data.id;
                    } else {
                        this.id = (data.id > 0) ? data.id : 'u-' + (-1 * data.id);
                    }
                    this.original_id = data.id;
                    this.title = data.title || data.filename;
                    this.short_title = data.title || data.filename;
                    this.extension = data.file_extension || null;
                    if (!this.extesion && data.filename) {
                        const parts = data.filename.split('.');
                        this.extension = parts[parts.length - 1];
                    }
                    this.file_type = data.file_type;
                    if (data.preview) {
                        this.image_src = data.preview;
                    }
                    this.getPreviewImage();
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 500);
                }
            },
            getPreviewImage: function () {
                if (this.image_src != '/images/file-icon.png') {
                    return null;
                }
                /**
                 * Send request to API
                 */
                let size = (this.extension == 'svg') ? 'svg' : 'lowres';
                if (this.file_type == 'image/gif') {
                    size = 'highres';
                }
                const idString = this.id.toString();
                const path = (idString.indexOf('l-') == 0)
                    ? '/collection/' + this.data.collection_id + '/linked-file/' + this.original_id
                    : '/resource/' + this.id;
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + path + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                        let data = {
                            id: this.original_id,
                            src: response.data.image_src,
                            is_asset: ('is_asset' in this.data) ? this.data.is_asset : null
                        };
                        this.$emit('preview', data);
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            fitTitle: function () {
                if (!$('#' + this.prefix + 'asset-title' + this.id)[0]) {
                    var vm = this;
                    setTimeout(function () {
                        vm.fitTitle();
                    }, 200);

                    return null;
                }
                if ($('#' + this.prefix + 'asset-title' + this.id)[0].scrollHeight > 20) {
                    let words = this.short_title.split(' ');
                    words.pop();
                    if (words.length > 0) {
                        this.short_title = words.join(' ') + '...';
                        var vm = this;
                        setTimeout(function () {
                            vm.fitTitle();
                        }, 200);
                    }
                }
            },
            remove: function () {
                let data = {
                    id: this.original_id,
                    is_asset: ('is_asset' in this.data) ? this.data.is_asset : null
                };
                this.$emit('remove', data);
            }
        }
    }
</script>
<style scoped>
    .asset-preview.select-item .thumbnail {
        height: 132px;
    }

    .asset-preview.select-item .thumbnail img {
        max-height: 130px;
    }

    .asset-preview:hover .card-body .asset-info.stay {
        opacity: 1;
    }
</style>
