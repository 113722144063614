<template>
    <div class="account-pages mx-md-5 my-5 pt-sm-5">
        <div class="container-fluid">
            <div :class="$root.loginPanelClass()">
                <div class="col-sm-6 col-lg-4 col-xl-3 login-panel-col">
                    <div class="card overflow-hidden">
                        <div :class="$root.app.login_panel_header_class + ' px-4 py-2 text-center'">
                            <img
                                v-if="$root.app.login_logo"
                                :src="$root.app.login_logo"
                                :alt="$root.client.client_name"
                                class="img-fluid"
                            >
                        </div>
                        <div class="card-body pt-2 pb-1">
                            <div v-if="$root.getAvailableLanguages().length > 1" class="text-right m-2">
                                <language-select></language-select>
                            </div>

                            <div class="p-2">
                                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                                <div v-if="loading"><loading></loading></div>

                                <div v-if="(!loading && errors)" class="form-group text-center">
                                    <router-link :to="{ name: 'login' }">
                                        <i class="far fa-sign-in-alt mr-1"></i>
                                        {{ $root.text('Click here to login', null, true) }}
                                    </router-link>
                                </div>

                                <div class="text-center" style="font-size: 0.7em; position: relative; height: 10px">
                                    {{ $root.text('Powered by', null, true) }}
                                    <a href="https://resourcebase.com" target="_blank">Resourcebase</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                loading: true,
                errors: false
            }
        },
        mounted () {
            $('body').addClass('login-body');

            if (!this.$route.query.EncryptedData) {
                this.errors = this.$root.text('No data received.', null, true);
                this.loading = false;

                return null;
            };
            this.verifyData();
        },
        methods: {
            verifyData: function () {
                this.loading = true;
                this.errors = false;

                /**
                 * Send request to API.
                 */
                var vm = this;
                const path = '/' + this.$root.app.client_id + '/user/single-sign-on-438';
                const url = this.$root.app.api_url + path;

                axios({
                    method: 'post',
                    url: url,
                    data: {
                        data: this.$route.query.EncryptedData,
                        device: this.$root.getDevice()
                    }
                })
                .then(response => {
                    if (response.data.user) {
                        // Auto-login.
                        vm.$root.user = response.data.user;
                        vm.$root.token = response.data.token;
                        vm.$root.resetSystemVariables();
                    
                        // Create token cookie.
                        vm.$root.setCookie(vm.$root.app.client_id + '_api_token', response.data.token, '1Y');
                        vm.$root.getSelectedAssetsAndCollectionFromCookie();
                        vm.$root.getAssetsInfo();

                        // Show mega menu info modal?
                        vm.$root.showMegaMenuInfo();

                        vm.$router.push({ name: 'home' });
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.loading = false
                );
            }
        }
    }
</script>
