<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label :for="'name' + folder.id" :id="'name-label' + folder.id" class="mandatory-label">
                            {{ $root.text(
                                '{folder} name',
                                { folder: $root.text($root.contents.folder_title, null, false, 'folder_title') },
                                true
                            ) }} *
                        </label>
                        <input
                            v-model="folder.name"
                            type="text"
                            :id="'name' + folder.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('name', folder.id)"
                            v-on:change="$root.unHighlightErrors('name', folder.id)"
                        >
                    </div>
                    <!--<div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="folder.related_assets"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'related_assets' + folder.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'related_assets' + folder.id">
                                Make all {{ $root.contents.subfolders_title }} in this {{ $root.contents.folder_title }}
                                related {{ $root.contents.asset_title }} fields
                            </label>
                        </div>
                    </div>-->

                    <span v-if="!updating">
                        <button
                            :key="'save'"
                            type="submit"
                            :class="'btn btn-primary mb-2' + ($root.is_xxl_screen ? ' mr-2' : ' btn-block')"
                        >
                            <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                        </button>
                    </span>
                    <span v-else>
                        <button
                            :key="'save-spinner'"
                            type="button"
                            :class="'btn btn-primary mb-2' + ($root.is_xxl_screen ? ' mr-2' : ' btn-block')"
                        >
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                        </button>
                    </span>

                    <button
                        v-if="(folder.archived)"
                        type="button"
                        :class="'btn btn-secondary mb-2' + ($root.is_xxl_screen ? ' mr-2' : ' btn-block')"
                        data-toggle="modal"
                        data-target="#folder-de-archive-modal"
                        @click="resetDeArchiveFolder()"
                        :disabled="updating"
                    >
                        <i class="far fa-archive mr-1"></i> {{ $root.text('De-archive', null, true) }}
                    </button>
                    <button
                        v-if="(!folder.archived)"
                        type="button"
                        :class="'btn btn-secondary mb-2' + ($root.is_xxl_screen ? ' mr-2' : ' btn-block')"
                        data-toggle="modal"
                        data-target="#folder-archive-modal"
                        @click="resetArchiveFolder()"
                        :disabled="updating"
                    >
                        <i class="far fa-archive mr-1"></i> {{ $root.text('Archive', null, true) }}
                    </button>

                    <button
                        type="button"
                        :class="'btn btn-secondary mb-2' + ($root.is_xxl_screen ? '' : ' btn-block')"
                        data-toggle="modal"
                        data-target="#folder-delete-modal"
                        @click="resetDeleteFolder()"
                        :disabled="updating"
                    >
                        <i class="far fa-trash-alt mr-1"></i> {{ $root.text('Delete', null, true) }}
                    </button>
                </form>
            </div>
            <div v-if="$root.hasPermission('assets_manage_subfolders')" class="col-lg-9 col-md-8 col-sm-6">
                <label v-if="(!folder.archived || (subfolders_live.length > 0))">
                    {{ $root.text(
                        'Live {subfolders}',
                        { subfolders: $root.text($root.contents.subfolders_title, null, false, 'subfolders_title') },
                        true
                    ) }}
                </label>

                <div v-if="subfolders_loading"><loading :align="'left'"></loading></div>

                <div
                    v-if="subfolders_errors"
                    v-html="subfolders_errors"
                    class="alert alert-danger"
                    role="alert"
                ></div>

                <div v-if="(!subfolders_errors && !subfolders_loading)">
                    <div v-if="(!folder.archived || (subfolders_live.length > 0))">
                        <div
                            v-if="(subfolders_live.length == 0)"
                            class="alert alert-warning"
                            role="alert"
                        >
                            {{ $root.text(
                                'There are no live {subfolders} in this {folder}',
                                {
                                    subfolders: $root.text(
                                        $root.contents.subfolders_title,
                                        null,
                                        false,
                                        'subfolders_title'
                                    ),
                                    folder: $root.text($root.contents.folder_title, null, false, 'folder_title')
                                },
                                true
                            ) }}
                        </div>
                        <subfolders
                            v-if="(subfolders_live.length > 0)"
                            :status="'!archived'"
                            :sf_items="subfolders_live"
                            :list_component="list_component"
                            :folder="folder"
                        ></subfolders>

                        <div class="text-right">
                            <button
                                v-if="!folder.archived"
                                type="button"
                                class="btn btn-secondary"
                                data-toggle="modal"
                                data-target="#subfolder-add-form-modal"
                                @click.prevent="resetSubfolderAddForm()"
                            >
                                <i class="far fa-folder-plus mr-1"></i>
                                {{ $root.text(
                                    'Add {subfolder}',
                                    {
                                        subfolder: $root.text(
                                            $root.contents.subfolder_title,
                                            null,
                                            false,
                                            'subfolder_title'
                                        )
                                    },
                                    true
                                ) }}
                            </button>
                        </div>
                    </div>

                    <label>
                        {{ $root.text(
                            'Archived {subfolders}',
                            { subfolders: $root.text($root.contents.subfolders_title, null, false, 'subfolders_title') },
                            true
                        ) }}
                    </label>
                    <div
                        v-if="(subfolders_archived.length == 0)"
                        class="alert alert-warning"
                        role="alert"
                    >
                        {{ $root.text(
                            'There are no archived {subfolders} in this {folder}',
                            {
                                subfolders: $root.text($root.contents.subfolders_title, null, false, 'subfolders_title'),
                                folder: $root.text($root.contents.folder_title, null, false, 'folder_title')
                            },
                            true
                        ) }}
                    </div>
                    <subfolders
                        v-if="(subfolders_archived.length > 0)"
                        :status="'archived'"
                        :sf_items="subfolders_archived"
                        :list_component="list_component"
                        :folder="folder"
                    ></subfolders>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'list_component'],
        data () {
            return {
                errors: false,
                updating: false,
                folder: {},
                subfolders_live: [],
                subfolders_archived: [],
                subfolders_loading: true,
                subfolders_loaded: 0,
                subfolders_errors: false
            }
        },
        mounted () {
            if (this.data) {
                this.folder = this.data;
                this.getSubfolders();
                this.getCustomFields();
            }
        },
        watch: {
            data: function (val) {
                this.folder = val;
                this.getSubfolders();
                this.getCustomFields();
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update folder request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/'
                    + this.folder.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.folder,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // Show confirmation message.
                    vm.list_component.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('Update folder', null, true),
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$root.folders_changed = true;

                    // Close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-label' + vm.folder.id).addClass('text-danger');
                                $('#' + key + vm.folder.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            getSubfolders: function (status = 'all') {
                this.subfolders_errors = false;
                if (status == 'all') {
                    //this.subfolders_loading = true;
                    //this.subfolders_loaded = 0;
                    status = 'archived';
                    this.getSubfolders('!archived');
                }

                /**
                 * Send get subfolders request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/'
                    + this.folder.id + '/subfolders/' + status;
                var vm = this;
                var destination = (status == 'archived') ? 'subfolders_archived' : 'subfolders_live';

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm[destination] = response.data.subfolders;
                    vm.folder.subfolder_info = vm.subfolders_live.length.toString();
                    if (vm.subfolders_archived.length > 0) {
                        vm.folder.subfolder_info += ' ' + vm.$root.text(
                            '(+{x} archived)',
                            { x: vm.subfolders_archived.length }
                        );
                    }
                    if (vm.subfolders_loading) {
                        vm.subfolders_loaded++;
                        if (vm.subfolders_loaded == 2) {
                            vm.subfolders_loading = false;
                        }
                    }
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.subfolders_errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.data.error) {
                        vm.subfolders_errors = error.response.data.error;
                    }
                    vm.subfolders_loading = false;
                });
            },
            getCustomFields: function () {
                /**
                 * Send get folder request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/folder/'
                    + this.folder.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folder.custom_fields_count = 0;
                    if (response.data.folder.custom_fields) {
                        let customFields = [];
                        response.data.folder.custom_fields.forEach((item) => {
                            customFields.push('<span class="badge badge-info font-size-12 mr-1 mb-1">'
                                + item.name + '</span>');
                        });
                        vm.folder.custom_field_info = customFields.join(' ');
                        vm.folder.custom_fields_count = response.data.folder.custom_fields.length;
                    }
                    if (!vm.folder.custom_field_info) {
                        vm.folder.custom_field_info = '&nbsp;';
                    }
                    vm.folder.subfolders_count = response.data.folder.subfolders.length;
                    vm.list_component.items.push({id: -1});
                    vm.list_component.items.pop();
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.subfolders_errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.data.error) {
                        vm.subfolders_errors = error.response.data.error;
                    }
                });
            },
            resetSubfolderAddForm: function () {
                const component = this.$root.findComponent(this.list_component, 'subfolder-add-form');
                if (component) {
                    component.resetForm(this.folder, this);
                }
            },
            resetDeleteFolder: function () {
                const component = this.$root.findComponent(this.list_component, 'folder-delete');
                if (component) {
                    component.reset(this.folder);
                }
            },
            resetArchiveFolder: function () {
                const component = this.$root.findComponent(this.list_component, 'folder-archive');
                if (component) {
                    component.reset(this.folder, this.subfolders_live.length);
                }
            },
            resetDeArchiveFolder: function () {
                const component = this.$root.findComponent(this.list_component, 'folder-de-archive');
                if (component) {
                    component.reset(this.folder, this.subfolders_archived.length);
                }
            }
        }
    }
</script>
