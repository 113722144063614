<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (items.length == 0))" class="alert alert-warning" role="alert">
            {{ $root.text(
                'There are no administrators added. Currently all alerts go to {support_email}.',
                { support_email: $root.client.support_email },
                true
            ) }}.
        </div>

        <p v-if="(items.length > 0)">
            {{ $root.text(
                'Configure which administrators will receive notifications. Tick the notification type(s) '
                    + 'and then select the {folders} from the drop down menu.',
                { folders: $root.text($root.contents.folders_title, null, false, 'folders_title') },
                true
            ) }}  
        </p>

        <div v-if="(items.length > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive" style="padding-bottom: 170px">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-sm-3 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Administrator', null, true) }}
                                        </div>
                                        <div class="col-sm-5 col-12 pb-sm-0 pb-1">
                                            {{ $root.text('Alerts', null, true) }}
                                        </div>
                                        <div class="col-sm-3 col-12">
                                            {{ $root.text(
                                                'Assigned to {folders}',
                                                {
                                                    folders: $root.text(
                                                        $root.contents.folders_title,
                                                        null,
                                                        false,
                                                        'folders_title'
                                                    )
                                                },
                                                true
                                            ) }}
                                        </div>
                                        <div class="col-sm-1 d-none d-sm-block">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.id">
                                <td scope="row" :id="'alert-row' + item.id" class="pb-0">
                                    <div class="row">
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleEditAlert(item)"
                                                >
                                                    {{ item.first_name }} {{ item.last_name }}
                                                </a>
                                            </h5>
                                            <p class="text-muted mb-0">{{ item.email }}</p>
                                        </div>
                                        <div class="col-5 d-none d-sm-block" style="padding-top: 2px">
                                            <span v-if="!hasAlert(item)" style="margin-top: 1px">
                                                {{ $root.text('None', null, true) }}
                                            </span>
                                            <span v-if="hasAlert(item) && (alert_details_open.indexOf(item.id) < 0)">
                                                <span v-for="option in getAlertOptions()" :key="option.key">
                                                    <span
                                                        v-if="item[option.key]"
                                                        class="badge badge-info font-size-12 mr-2 mb-2"
                                                    >{{ option.name }}</span>
                                                </span>
                                            </span>
                                        </div>
                                        <div class="col-3 d-none d-sm-block" style="padding-top: 3px">
                                            <span v-if="(item.folders.length == 0)">
                                                {{ $root.text(
                                                    'All {folders}',
                                                    {
                                                        folders: $root.text(
                                                            $root.contents.folders_title,
                                                            null,
                                                            false,
                                                            'folders_title'
                                                        )
                                                    },
                                                    true
                                                ) }}
                                            </span>
                                            <span v-else>
                                                <span v-if="(folders.length > 0)">
                                                    {{ getFolderNames(item.folders) }}
                                                </span>
                                                <span v-else><loading :align="'left'" :size="1"></loading></span>
                                            </span>
                                        </div>
                                        <div class="col-10 d-sm-none">
                                            <h5 class="font-size-14 mb-1">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleEditAlert(item)"
                                                >
                                                    {{ item.first_name }} {{ item.last_name }}
                                                </a>
                                            </h5>
                                            <p class="text-muted mb-1">{{ item.email }}</p>
                                            <p v-if="!hasAlert(item)" class="mb-1">
                                                {{ $root.text('None', null, true) }}
                                            </p>
                                            <p
                                                v-if="hasAlert(item) && (alert_details_open.indexOf(item.id) < 0)"
                                                class="mb-0"
                                            >
                                                <span v-for="option in getAlertOptions()" :key="option.key">
                                                    <span
                                                        v-if="item[option.key]"
                                                        class="badge badge-info font-size-12 mr-2 mb-1"
                                                    >{{ option.name }}</span>
                                                </span>
                                            </p>
                                            <p class="mb-0">
                                                <span v-if="(item.folders.length == 0)">
                                                    {{ $root.text(
                                                        'All {folders}',
                                                        {
                                                            folders: $root.text(
                                                                $root.contents.folders_title,
                                                                null,
                                                                false,
                                                                'folders_title'
                                                            )
                                                        },
                                                        true
                                                    ) }}
                                                </span>
                                                <span v-else>
                                                    <span v-if="(folders.length > 0)">
                                                        <span v-for="folder in folders" :key="folder.id">
                                                            <span
                                                                v-if="(item.folders.indexOf(folder.id) > -1)"
                                                                class="mr-2"
                                                            >{{ folder.name }}</span>
                                                        </span>
                                                    </span>
                                                    <span v-else><loading :align="'left'" :size="1"></loading></span>
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleEditAlert(item)"
                                            >
                                                <span
                                                    v-if="(alert_details_open.indexOf(item.id) < 0)"
                                                    :key="'close-' + item.id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'alert-details' + item.id">
                                            <alert-edit-form
                                                :data="item"
                                                :alert_options="getAlertOptions()"
                                                :folders="folders"
                                            ></alert-edit-form>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>

        <div
            class="modal fade"
            id="alert-add-form-modal"
            data-backdrop="static"
            data-keyboard="false"
            tabindex="-1"
            aria-labelledby="alert-add-form-modal-label"
            aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-scrollable">
                <alert-add-form></alert-add-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                component_name: 'alerts',
                loading: false,
                errors: false,
                items: [],
                alert_details_open: [],
                folders: []
            }
        },
        mounted () {
            $('body').removeClass('login-body');
            
            if (!this.$root.hasPermission('users_manage_alerts')) {
                this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                return false;
            }
            this.getFolders();
            this.getAlerts();
        },
        watch: {
            //
        },
        methods: {
            getAlerts: function() {
                this.alert_details_open = [];
                this.items = [];
                this.loading = true;
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/administrator-alerts';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.items = response.data.alerts;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            getFolders: function() {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!pagination|!archived';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    }
                });
            },
            toggleEditAlert: function (alert) {
                if (this.alert_details_open.indexOf(alert.id) < 0) {
                    this.alert_details_open.push(alert.id);
                    $('#alert-row' + alert.id).addClass('highlighted');
                    $('#alert-details' + alert.id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#alert-details' + alert.id).slideUp('slow', function () {
                        $('#alert-row' + alert.id).removeClass('highlighted');
                        for (var n = 0; n < vm.alert_details_open.length; n++) { 
                            if (vm.alert_details_open[n] == alert.id) { 
                                vm.alert_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            },
            hasAlert: function (item) {
                if (this.$root.getSetting('allow_register', false) && item.registration) {
                    return true;
                }
                if (item.support || item.deletion_request) {
                    return true;
                }
                if (this.$root.isFeatured('assets')) {
                    if (item.asset_upload || item.asset_approval || item.asset_review) {
                        return true;
                    }
                }
                if (this.$root.isFeatured('templates')) {
                    if (item.template_upload || item.template_approval || item.weekly_artwork_created) {
                        return true;
                    }
                }
                if (this.$root.isFeatured('printing') && item.monthly_orders) {
                    return true;
                }

                return false;
            },
            getAlertOptions: function () {
                let options = [];
                if (this.$root.getSetting('allow_register', false)) {
                    options.push({ key: 'registration', name: this.$root.text('New applicant', null, true) });
                }
                options.push({ key: 'deletion_request', name: this.$root.text('Account deletion request', null, true) });
                options.push({ key: 'support', name: this.$root.text('Support request', null, true) });
                if (this.$root.isFeatured('assets')) {
                    options.push({
                        key: 'asset_upload',
                        name: this.$root.text(
                            '{resource} upload',
                            { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        )
                    });
                    options.push({
                        key: 'asset_approval',
                        name: this.$root.text(
                            '{resource} approval',
                            { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        )
                    });
                    options.push({
                        key: 'asset_review',
                        name: this.$root.text(
                            '{resource} review date',
                            { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        )
                    });
                }
                if (this.$root.isFeatured('templates')) {
                    options.push({
                        key: 'template_upload',
                        name: this.$root.text(
                            '{template} upload',
                            {
                                template: this.$root.text(
                                    this.$root.contents.template_title,
                                    null,
                                    false,
                                    'template_title'
                                )
                            },
                            true
                        )
                    });
                    options.push({
                        key: 'template_approval',
                        name: this.$root.text(
                            '{template} approval',
                            {
                                template: this.$root.text(
                                    this.$root.contents.template_title,
                                    null,
                                    false,
                                    'template_title'
                                )
                            },
                            true
                        )
                    });
                    options.push({
                        key: 'weekly_artwork_created',
                        name: this.$root.text(
                            'Weekly {templates} created',
                            {
                                templates: this.$root.text(
                                    this.$root.contents.template_title + 's',
                                    null,
                                    false,
                                    'templates_title'
                                )
                            },
                            true
                        )
                    });
                }
                if (this.$root.isFeatured('printing')) {
                    options.push({ key: 'monthly_orders', name: this.$root.text('Monthly orders', null, true) });
                }

                return options;
            },
            getFolderNames: function (folderIds) {
                let folderNames = '';
                this.folders.forEach((item) => {
                    if (folderIds.indexOf(item.id) > -1) {
                        if (folderNames != '') {
                            folderNames += ', ';
                        }
                        folderNames += item.name;
                    }
                });

                return folderNames;
            }
        }
    }
</script>
