<template>
    <div style="min-height: 400px">
        <div v-if="loaded" class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label :for="'name' + language.id" :id="'name-label' + language.id" class="mandatory-label">
                            {{ $root.text('Name', null, true) }} *
                        </label>
                        <input
                            v-model="language.name"
                            type="text"
                            :id="'name' + language.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('name', language.id)"
                            v-on:change="$root.unHighlightErrors('name', language.id)"
                        >
                    </div>
                    <div class="form-group">
                        <label :for="'code' + language.id" :id="'code-label' + language.id" class="mandatory-label">
                            {{ $root.text('Code', null, true) }} *
                        </label>
                        <searchable-option-list
                            v-model="language.code"
                            :value="language.code"
                            :options="getCodeOptions()"
                            :field_id="'code' + language.id"
                            field_class="mandatory-field"
                            :readonly="updating"
                            :placeholder="$root.text('Select a language code', null, true)"
                            v-on:change="$root.unHighlightErrors('language-code')"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="language.active"
                                type="checkbox"
                                class="custom-control-input"
                                :id="'active' + language.id"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" :for="'active' + language.id">
                                {{ $root.text('Active', null, true) }}
                            </label>
                        </div>
                    </div>

                    <button
                        v-if="!updating"
                        :key="'update-button'"
                        type="submit"
                        class="btn btn-primary mr-2"
                        :disabled="updating"
                    >
                        <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                    </button>
                    <button v-else :key="'updating-button'" type="button" class="btn btn-primary mr-2">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                    </button>
                </form>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-6" style="position: relative">
                <div class="row">
                    <div class="col-6 pt-2"><label>{{ $root.text('Translations', null, true) }}</label></div>
                    <div class="col-6">
                        <form v-on:submit.prevent="filterTranslations()">
                            <div class="input-group" style="top: -8px">
                                <input
                                    v-model="translation_keyword"
                                    type="text"
                                    class="form-control"
                                    :placeholder="$root.text('Keyword search', null, true)"
                                    aria-label="Keyword search"
                                >
                                <div class="input-group-append">
                                    <button class="btn btn-primary big-icon" type="submit">
                                        <i class="fal fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button
                                v-if="translation_keyword"
                                class="btn btn-link clear-translation-keyword"
                                @click.prevent="clearTranslationKeyword()"
                            >
                                <i class="far fa-times"></i>
                            </button>
                        </form>
                    </div>
                </div>
                
                <div
                    class="table-responsive mb-0"
                    :style="'display: ' + ((hasTranslations() || translation_keyword) ? 'block' : 'none')"
                >
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-6">English</div>
                                        <div class="col-5">{{ data.name }}</div>
                                        <div class="col-1">&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <div
                        class="translations"
                        :id="'translations' + data.id"
                        :style="'display: ' + (hasTranslations() ? 'block' : 'none')"
                    >
                        <table v-if="hasTranslations()" class="table table-centered table-hover translations-list">
                            <tbody>
                                <tr v-for="item in items_paginated" :key="item.ekey">
                                    <td scope="row">
                                        <div class="row">
                                            <div
                                                class="col-6"
                                                style="padding-top: 3px"
                                                v-html="stripTagsShort(item.eval)"
                                            ></div>
                                            <div
                                                class="col-5"
                                                style="padding-top: 3px"
                                                v-html="stripTagsShort(item.tval)"
                                            ></div>
                                            <div class="col-1 font-size-18 contact-links">
                                                <a
                                                    href="#"
                                                    class="float-right"
                                                    v-b-tooltip.hover.left
                                                    :title="$root.text('Edit', null, true)"
                                                    data-toggle="modal"
                                                    data-target="#edit-content-modal"
                                                    @click.prevent="editTranslation(item)"
                                                >
                                                    <i class="far fa-edit"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div
                    v-if="!hasTranslations() && !paginating"
                    class="alert alert-warning mt-3"
                    role="alert"
                >
                    {{ $root.text('There are no translations', null, true) }}
                </div>
                <div v-if="paginating" style="position: absolute; left: 0; bottom: -15px; width: 100%">
                    <loading></loading>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data', 'is_visible'],
        data () {
            return {
                errors: false,
                updating: false,
                loaded: false,
                language: {},
                items_per_page: 25,
                items_paginated: [],
                page: 0,
                paginating: false,
                translation_keyword: null
            }
        },
        mounted () {
            if (this.data && this.is_visible) {
                this.updateData(this.data);
            }
        },
        watch: {
            data: function (val) {
                if (!this.is_visible) {
                    return null;
                }
                this.updateData(val);
            },
            is_visible: function (val) {
                if (!val || !this.data || (this.items_paginated.length > 0)) {
                    return null;
                }
                this.updateData(this.data);
            },
            page: function (val) {
                if (val) {
                    this.paginating = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.paginate();
                    }, 600);
                }
            }
        },
        methods: {
            updateData: function (data) {
                for (const [key, value] of Object.entries(data)) {
                    this.language[key] = value;
                }
                this.loaded = true;
                if (this.page) {
                    return null;
                }
                this.page = 1;

                var vm = this;
                setTimeout(function () {
                    $('#translations' + vm.data.id).on('scroll', function () {
                        vm.handleScroll();
                    });
                }, 500);
            },
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update language request to API.
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/language/'
                    + this.language.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.language,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    // Show confirmation message.
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('Update language', null, true),
                        text: vm.$root.text('Language updated.', null, true)
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.getLanguages();

                    // Close after 2 seconds automatically.
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-label' + vm.language.id).addClass('text-danger');
                                $('#' + key + vm.language.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            getTranslations: function () {
                const excludeKeys = ['company_name', 'login_background'];
                let translations = [];
                let keywordTrimmed = '';
                if (this.translation_keyword) {
                    keywordTrimmed = this.translation_keyword.trim().toLowerCase();
                }
                for (const [key, value] of Object.entries(this.$root.contents)) {
                    if (value && (excludeKeys.indexOf(key) < 0)) {
                        let parts = key.split('_');
                        let isOriginal = (parts.length == 1);
                        if (!isOriginal) {
                            let lastPart = parts.pop();
                            if (isNaN(lastPart)) {
                                isOriginal = true;
                            }
                        }
                        if (isOriginal) {
                            let tkey = key + '_' + this.language.id;
                            let tval = this.$root.contents[tkey] ? this.$root.contents[tkey] : null;
                            let keywordMatch = true;
                            if (keywordTrimmed) {
                                let valueToSearch = value.replace(/{/g, '').replace(/}/g, '').toLowerCase();
                                if (valueToSearch.indexOf(keywordTrimmed) < 0) {
                                    keywordMatch = false;
                                    if (tval) {
                                        valueToSearch = tval.replace(/{/g, '').replace(/}/g, '').toLowerCase();
                                        if (valueToSearch.indexOf(keywordTrimmed) > -1) {
                                            keywordMatch = true;
                                        }
                                    }
                                }
                            }
                            if (keywordMatch) {
                                let tkey = key + '_' + this.language.id;
                                let evals = this.stripTagsShort(value, '').toLowerCase().replace(/{/g, '')
                                    .replace(/}/g, '');
                                translations.push({
                                    ekey: key,
                                    eval: value,
                                    evals: evals,
                                    tkey: tkey,
                                    tval: tval
                                });
                            }
                        }
                    }
                }
                if (translations.length > 1) {
                    for (let i = 0; i < (translations.length - 1); i++) {
                        for (let j = i + 1; j < translations.length; j++) {
                            if (translations[j].evals < translations[i].evals) {
                                let temp = translations[i];
                                translations[i] = translations[j];
                                translations[j] = temp;
                            }
                        }
                    }
                }

                return translations;
            },
            hasTranslations: function () {
                return (this.items_paginated.length > 0);
            },
            editTranslation: function (item) {
                const multiLineContents = ['terms_and_conditions', 'company_contact_name_label'];
                const component = this.$root.findComponent(this.$parent, 'edit-content');
                if (component) {
                    component.resetForm(
                        this.$root.text('Edit translation', null, true),
                        item.tkey,
                        (multiLineContents.indexOf(item.ekey) > -1),
                        false,
                        {
                            original_content: item.eval,
                            language_id: this.data.id,
                            language_name: this.data.name,
                            list_component: this
                        }
                    );
                }
            },
            handleScroll: function () {
                if (this.paginating) {
                    return null;
                }
                const scrollTop = $('#translations' + this.data.id).scrollTop();
                const contentHeight = $('#translations' + this.data.id + ' > table').outerHeight();
                const scrollPosition = contentHeight - (scrollTop + 400);
                if (scrollPosition < 50) {
                    this.page++;
                }
            },
            stripTagsShort: function (text, replaceWith = ' ') {
                if (!text) {
                    return '';
                }
                text = text.replace(/<\/?[^>]+>/gi, replaceWith);
                if (text.length > 200) {
                    return text.substring(0, 200) + '...';
                }

                return text;
            },
            paginate: function () {
                if (this.page < 2) {
                    $('#translations' + this.data.id)[0].scrollTop = 0;
                }
                const items = this.getTranslations();
                const indexStart = this.items_paginated.length;
                let indexEnd = (this.page * this.items_per_page) - 1;
                if (indexEnd > (items.length - 1)) {
                    indexEnd = items.length - 1;
                }
                if (indexEnd < indexStart) {
                    this.paginating = false;

                    return null;
                }
                for (let index = indexStart; index <= indexEnd; index++) {
                    this.items_paginated.push(items[index]);
                }
                this.paginating = false;
            },
            filterTranslations: function () {
                this.page = 0;
                this.paginating = true;
                this.items_paginated = [];
                var vm = this;
                setTimeout(function () {
                    vm.page = 1;
                }, 400);
            },
            clearTranslationKeyword: function () {
                this.translation_keyword = null;
                this.filterTranslations();
            },
            getCodeOptions: function () {
                const options = [
                    {
                        id: 'de-DE',
                        name: 'de-DE ' + this.$root.text('(German)')
                    },
                    {
                        id: 'en-US',
                        name: 'en-US ' + this.$root.text('(English US)')
                    },
                    {
                        id: 'es-ES',
                        name: 'es-ES ' + this.$root.text('(Spanish)')
                    },
                    {
                        id: 'fr-FR',
                        name: 'fr-FR ' + this.$root.text('(French)')
                    },
                    {
                        id: 'it-IT',
                        name: 'it-IT ' + this.$root.text('(Italian)')
                    },
                    {
                        id: 'nl-NL',
                        name: 'nl-NL ' + this.$root.text('(Dutch)')
                    },
                    {
                        id: 'sv-SE',
                        name: 'sv-SE ' + this.$root.text('(Swedish)')
                    }
                ];

                return options;
            }
        }
    }
</script>
<style scoped>
    .translations {
        max-height: 400px;
        overflow-y: auto;
    }

    .translations-list tbody tr:first-child td {
        border-top: none;
    }

    .clear-translation-keyword {
        position: absolute;
        top: -6px;
        right: 51px;
        z-index: 9;
    }
</style>
