<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="asset-type-add-form-modal-label">
                {{ $root.text(
                    'Add {resource} type',
                    { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                    true
                ) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="$root.hasPermission('assets_asset_types')" v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label for="asset-type-name" id="asset-type-name-label" class="mandatory-label">
                            {{ $root.text('Name', null, true) }} *
                        </label>
                        <input
                            v-model="asset_type.name"
                            type="text"
                            id="asset-type-name"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('asset-type-name')"
                            v-on:change="$root.unHighlightErrors('asset-type-name')"
                        >
                    </div>
                    <div class="form-group">
                        <label>
                            {{ $root.text('{resources} require approval before download by default',
                                { resources: $root.text($root.contents.asset_title + 's', null, false, 'assets_title') },
                                true) }}
                        </label>
                        <div class="form-control" style="height: auto">
                            <div class="mr-4" style="display: inline-block">
                                <div class="custom-control custom-radio">
                                    <input
                                        v-model="asset_type.restricted"
                                        type="radio"
                                        id="asset-type-restricted-0"
                                        class="custom-control-input"
                                        :value="0"
                                        :disabled="updating"
                                    >
                                    <label class="custom-control-label" for="asset-type-restricted-0">
                                        {{ $root.text('No', null, true) }}
                                    </label>
                                </div>
                            </div>
                            <div style="display: inline-block">
                                <div class="custom-control custom-radio">
                                    <input
                                        v-model="asset_type.restricted"
                                        type="radio"
                                        id="asset-type-restricted-1"
                                        class="custom-control-input"
                                        :value="1"
                                        :disabled="updating"
                                    >
                                    <label class="custom-control-label" for="asset-type-restricted-1">
                                        {{ $root.text('Yes', null, true) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="custom-control custom-checkbox">
                            <input
                                v-model="asset_type.active"
                                type="checkbox"
                                class="custom-control-input"
                                id="asset-type-active"
                                :disabled="updating"
                            >
                            <label class="custom-control-label" for="asset-type-active">
                                {{ $root.text('Active', null, true) }}
                            </label>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="($root.hasPermission('assets_asset_types') && !updated)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> {{ $root.text('Add', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Add', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                asset_type: {}
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (user) {
                if (!this.$root.hasPermission('assets_asset_types')) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.asset_type = {
                    name: '',
                    restricted: 0,
                    active: true
                };
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                setTimeout(function () {
                    $('#asset-type-name').focus();
                }, 500);
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = this.asset_type;
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource-type';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = vm.$root.text(
                            '{resource} type added',
                            { resource: vm.$root.text(vm.$root.contents.asset_title, null, false, 'asset_type') },
                            true
                        );
                        if (vm.asset_type.active && (vm.$route.name == 'asset-types')) {
                            const component = vm.$root.findComponent(vm.$parent, 'asset-types');
                            if (component) {
                                component.getAssetTypes();
                            }
                        }
                        if (!vm.asset_type.active && (vm.$route.name == 'asset-types-inactive')) {
                            const component = vm.$root.findComponent(vm.$parent, 'asset-types-inactive');
                            if (component) {
                                component.getAssetTypes();
                            }
                        }
                    }

                    setTimeout(function () {
                        $('#asset-type-add-form-modal').modal('hide');
                        if (vm.asset_type.active && (vm.$route.name != 'asset-types')) {
                            vm.$router.push({ name: 'asset-types' });
                        }
                        if (!vm.asset_type.active && (vm.$route.name != 'asset-types-inactive')) {
                            vm.$router.push({ name: 'asset-types-inactive' });
                        }
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#asset-type-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += vm.$root.parseApiErrors(key, value);
                                    $('#asset-type-' + key + '-label').addClass('text-danger');
                                    $('#asset-type-' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
