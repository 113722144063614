<template>
    <div :class="'form-group' + (isTableField() ? ' mb-2' : '')">
        <label
            v-if="!isTableField()"
            :for="'template-field' + data.id"
            :id="'template-field-label' + data.id"
            class="mandatory-label"
        >
            <span
                v-if="item.locked"
                class="text-danger mr-1"
                v-b-tooltip.hover
                :title="$root.text('Set by your ' + $root.contents.profile_title, null, true)"
            >
                <i class="far fa-lock font-size-16 mr-1"></i>
            </span>
            {{ data.title }}
        </label>
        <div v-if="item.profile_field_changed" class="alert alert-warning alert-dismissible fade show mb-2" role="alert">
            {{ $root.text('The profile has changed therefore the content of this field has been updated automatically.',
                null, true) }}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="dismissProfileFieldChanged()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <loading v-if="loading" align="left" :size="isTableField() ? 2 : 3"></loading>
        <div v-else>
            <div v-if="(item.type == 'I')">
                <div v-if="canGenerateQrCode()" class="mb-2">
                    <form v-on:submit.prevent="generateQrCode()">
                        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
                        <div class="input-group">
                            <input
                                v-model="item.qr_code_text"
                                type="text"
                                class="form-control"
                                :placeholder="$root.text('Enter URL', null, true)"
                                aria-label="Enter URL"
                                :readonly="updating"
                            >
                            <div class="input-group-append">
                                <button
                                    v-if="!updating"
                                    :key="'generate_qr_code'"
                                    class="btn btn-primary big-icon"
                                    type="submit"
                                    v-b-tooltip.hover
                                    :title="$root.text('Generate QR code', null, true)"
                                >
                                    <i class="fal fa-qrcode"></i>
                                </button>
                                <button
                                    v-else
                                    :key="'generating-qr-code'"
                                    type="button"
                                    class="btn btn-primary big-icon"
                                    style="width: 40px"
                                >
                                    <i class="fal fa-spinner-third fa-spin"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="row">
                    <div
                        :class="($root.is_xl_screen ? 'col-lg-' + ($root.is_xxl_screen ? 7 : 6) + ' ' : '')
                            + 'col-12 asset-preview'"
                    >
                        <div class="thumbnail">
                            <img
                                :src="image_src"
                                :class="'img-fluid' + (canGenerateQrCode() ? ' qr-code' : '')"
                                :alt="data.title"
                            >
                        </div>
                    </div>
                    <div :class="'col text-center' + ($root.is_xl_screen ? '' : ' pt-2')">
                        <button
                            v-if="!item.locked && !canGenerateQrCode()"
                            :class="'btn btn-secondary px-2 mb-2' + ($root.is_xl_screen ? '' : ' mx-3')"
                            type="button"
                            data-toggle="modal"
                            data-target="#template-images-modal"
                            @click.prevent="resetTemplateImages()"
                            :disabled="readonly"
                            style="width: 140px"
                        >
                            <i class="far fa-images mr-1"></i> {{ $root.text('Change image', null, true) }}
                        </button>
                        <button
                            v-if="canEditImage()"
                            :class="'btn btn-secondary mb-2' + ($root.is_xl_screen ? '' : ' mx-3')"
                            type="button"
                            @click="editImage()"
                            :disabled="readonly"
                            style="width: 140px"
                        >
                            <i class="far fa-crop-alt mr-1"></i> {{ $root.text('Edit image', null, true) }}
                        </button>
                        <button
                            v-if="canGenerateQrCode() && download_data && !downloading"
                            :key="'download-button'"
                            :class="'btn btn-secondary mb-2' + ($root.is_xl_screen ? '' : ' mx-3')"
                            type="button"
                            @click="downloadFile(download_data)"
                        >
                            <span style="white-space: nowrap">
                                <i class="far fa-download mr-1"></i> {{ $root.text('Download', null, true) }}
                            </span>
                            <span style="white-space: nowrap">{{ $root.text('QR code', null, true) }}</span>
                        </button>
                        <button
                            v-if="downloading"
                            :key="'downloading-button'"
                            :class="'btn btn-secondary mb-2' + ($root.is_xl_screen ? '' : ' mx-3')"
                            type="button"
                        >
                            <span style="white-space: nowrap">
                                <i class="fal fa-spinner-third fa-spin mr-1"></i>
                                {{ $root.text('Download', null, true) }}
                            </span>
                            <span style="white-space: nowrap">{{ $root.text('QR code', null, true) }}</span>
                        </button>
                    </div>
                </div>
            </div>

            <div v-if="(!item.single_line && (item.type == 'T'))">
                <p v-if="item.error && !isTableField()" class="text-danger mb-1">
                    <i class="far fa-exclamation-triangle font-size-16 mr-1"></i>
                    {{ item.error }}
                </p>
                <p v-if="item.warning && !isTableField()" class="text-warning mb-1">
                    <i class="far fa-exclamation-triangle font-size-16 mr-1"></i>
                    {{ item.warning }}
                </p>
                <div
                    v-if="tiny_editor_init"
                    :class="isTableField() ? 'form-control pb-0' + tableFieldClass() : ''"
                    style="height: auto"
                >
                    <!-- :api-key="$root.app.tiny_api_key" -->
                    <editor
                        v-model="content"
                        :init="tiny_editor_init"
                        :disabled="(readonly || item.locked)"
                    ></editor>
                </div>
            </div>

            <div v-if="(item.single_line && (item.type == 'T'))">
                <p v-if="item.error && !isTableField()" class="text-danger mb-1">
                    <i class="far fa-exclamation-triangle font-size-16 mr-1"></i>
                    {{ item.error }}
                </p>
                <p v-if="item.warning && !isTableField()" class="text-warning mb-1">
                    <i class="far fa-exclamation-triangle font-size-16 mr-1"></i>
                    {{ item.warning }}
                </p>
                <input
                    v-model="content"
                    type="text"
                    :class="'form-control' + tableFieldClass()"
                    :disabled="(readonly || item.locked)"
                >
            </div>

            <div v-if="item.type == 'table'">
                <p v-if="item.errors.length > 0" class="text-danger mb-1">
                    <i class="far fa-exclamation-triangle font-size-16 mr-1"></i>
                    <span v-html="item.errors.join('<br>')"></span>
                </p>
                <p v-if="item.warnings.length > 0" class="text-warning mb-1">
                    <i class="far fa-exclamation-triangle font-size-16 mr-1"></i>
                    <span v-html="item.warnings.join('<br>')"></span>
                </p>
                <button
                    class="btn btn-secondary btn-block"
                    type="button"
                    @click="editTable()"
                    :disabled="readonly"
                >
                    <i class="far fa-table mr-1"></i> {{ $root.text('Edit table content', null, true) }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import 'tinymce'
    import Editor from '@tinymce/tinymce-vue';
    import 'tinymce/icons/default/icons.min.js';
    import 'tinymce/themes/silver/theme.min.js';
    import 'tinymce/models/dom/model.min.js';
    import 'tinymce/skins/ui/oxide/skin.js';
    //import 'tinymce/plugins/advlist';
    import 'tinymce/plugins/code';
    //import 'tinymce/plugins/emoticons';
    //import 'tinymce/plugins/emoticons/js/emojis';
    //import 'tinymce/plugins/link';
    import 'tinymce/plugins/lists';
    //import 'tinymce/plugins/table';
    import 'tinymce/skins/content/default/content.js';
    export default {
        components: {
            'editor': Editor
        },
        props: ['artwork_editor_component', 'data', 'profile_id', 'readonly', 'reload'],
        data () {
            return {
                loading: true,
                errors: false,
                tiny_editor_init: null,
                item: {},
                image_src: null,
                content: null,
                edit_image: null,
                updating: false,
                download_data: null,
                downloading: false
            }
        },
        mounted () {
            if (this.data) {
                this.updateItem();
            }
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateItem(val);
                }
            },
            reload: function (val) {
                if (this.data) {
                    this.loading = true;
                    var vm = this;
                    setTimeout(function () {
                        vm.updateItem();
                    }, 500);
                }
            },
            content: function (val, oldVal) {
                const hasData2 = (
                    (this.item.type == 'T')
                    && !this.item.single_line
                    && this.item.data2
                    && (this.getTextSize() <= 48)
                );
                if ((oldVal === null) && !hasData2) {
                    return null;
                }
                let updateData = {
                    data: val
                };
                if (hasData2) {
                    updateData.data2 = val;
                }
                if (oldVal !== null) {
                    if (this.item.profile_field_changed) {
                        this.item.profile_field_changed = false;
                        updateData.profile_field_changed = false;
                    }
                    this.artwork_editor_component.collapse_saved_artworks = true;
                }
                this.artwork_editor_component.updateTemplateField(this.data, updateData, false, (oldVal === null));
            }
        },
        methods: {
            hasSemiBoldTool: function () {
                if (Array.isArray(this.$root.app.tiny_toolbar_template_field)) {
                    let found = false;
                    this.$root.app.tiny_toolbar_template_field.forEach((item) => {
                        if (item.indexOf('semibold') > -1) {
                            found = true;
                        }
                    });

                    return found;
                }

                return (this.$root.app.tiny_toolbar_template_field.indexOf('semibold') > -1);
            },
            updateItem: function (data = null) {
                if (!data) {
                    data = this.data;
                }
                let preview = null;
                for (const [key, value] of Object.entries(data)) {
                    if (key == 'preview') {
                        preview = value;
                    } else if (key == 'edit_image') {
                        this.edit_image = value;
                    } else {
                        this.item[key] = value;
                    }
                }
                if (preview) {
                    this.image_src = preview;
                }
                if (data.type == 'T') {
                    if (!this.item.single_line && this.item.data2 && (this.getTextSize() <= 48)) {
                        this.content = this.item.data2;
                    } else {
                        this.content = this.item.data;
                    }
                }
                if (
                    (data.type == 'I')
                    && (!this.image_src || this.canGenerateQrCode() || (this.canEditImage(false) && !this.edit_image))
                ) {
                    this.getImage();

                    return null;
                }
                if (!this.item.single_line && (this.item.type == 'T')) {
                    this.initTinyMce();
                }
                this.loading = false;
            },
            initTinyMce: function () {
                if (!this.isTableField() && this.tiny_editor_init) {
                    return null;
                }
                let plugins = this.isTableField() ? [] : this.$root.app.tiny_plugins_template_field;
                let toolbar = this.isTableField() ? 'fontsize | bold italic' : this.$root.app.tiny_toolbar_template_field;
                if (!this.isTableField()) {
                    const textSize = this.getTextSize();
                    if (this.$root.client.template_field_tool_fontsize || (textSize > 48)) {
                        toolbar = toolbar.replace(/fontsize /g, '').replace(/ fontsize/g, '');
                        if (textSize <= 48) {
                            toolbar = 'fontsize ' + toolbar;
                        }
                    }
                    if (this.$root.client.template_field_tool_align) {
                        toolbar = toolbar.replace(/alignleft /g, '')
                            .replace(/ alignleft/g, '')
                            .replace(/aligncenter /g, '')
                            .replace(/ aligncenter/g, '')
                            .replace(/alignright /g, '')
                            .replace(/ alignright/g, '');
                        toolbar += ' alignleft aligncenter alignright';
                    }
                    if (this.$root.client.template_field_tool_code) {
                        if (plugins.indexOf('code') < 0) { 
                            plugins.push('code');
                        }
                        toolbar = toolbar.replace(/code /g, '').replace(/ code/g, '');
                        toolbar += ' code';
                    }
                }
                let contentStyle = this.$root.tiny_mce_content_styles;
                /*if (this.isTableField() && this.item.error) {
                    const length = contentStyle.length - 1;
                    contentStyle = contentStyle.substring(0, length) + 'background-color: #f46a6a; }';
                }*/
                let tinyEditorInit = {
                    license_key: 'gpl',
                    height: 300,
                    menubar: false,
                    elementpath: false,
                    branding: false,
                    resize: true,
                    plugins: plugins,
                    toolbar: toolbar,
                    inline: this.isTableField(),
                    font_size_input_default_unit: 'pt',
                    font_size_formats: '6pt 7pt 8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt 22pt 24pt 30pt '
                        + '36pt 42pt 48pt',
                    content_style: contentStyle
                    /*color_map: [
                        '000000', 'Black',
                        'ffffff', 'White',
                        'ff0000', 'Red',
                        '0000FF', 'Blue'
                    ],
                    custom_colors: false*/
                };
                if (!this.isTableField()) {
                    // Jira R2-886.
                    tinyEditorInit.browser_spellcheck = true;
                    tinyEditorInit.contextmenu = false;

                    if (this.$root.app.tiny_styles_template_field) {
                        tinyEditorInit.style_formats = this.$root.app.tiny_styles_template_field;
                    }
                    if (Array.isArray(this.$root.app.tiny_toolbar_template_field)) {
                        tinyEditorInit.height += ((this.$root.app.tiny_toolbar_template_field.length - 1) * 39);
                    }
                    if (this.hasSemiBoldTool()) {
                        tinyEditorInit.formats = {
                            semibold: { inline: 'span', styles: { fontWeight: 600 } },
                        };
                        tinyEditorInit.setup = function (editor) {
                            const svg = '<svg width="24" height="24" focusable="false"><path d="M 7.8,19 C 7.5,19 7.3,19 '
                                + '7.2,18.8 L 7,18.3 V 5.7 C 7,5.5 7,5.3 7.2,5.2 L 7.8,5 h 5 c 1.5,0 2.7,0.3 3.5,1 0.7,0.6 '
                                + '1.1,1.4 1.1,2.5 0.0223,0.6831937 -0.189381,1.3535178 -0.6,1.9 -0.4,0.6 -1,1 -1.6,1.2 0.4,'
                                + '0.1 0.9,0.3 1.3,0.6 0.4,0.3 0.857007,0.585986 1.057007,1.085986 C 17.957007,13.685986 18,'
                                + '14.4 18,15 c 0,1.3 -0.4,2.3 -1.3,3 -0.8,0.7 -2.1,1 -3.8,1 z m 5.285035,-7.957957 c 0,0 '
                                + '1.057483,-0.242518 1.457483,-0.642518 0.4,-0.3 1.08456,-0.9850357 1.08456,-1.5850357 0,'
                                + '-1.1 -1.08507,-2.194676 -2.585036,-2.1845605 l -4.2266026,0.028503 v 4.3836112 l '
                                + '2.9299286,0.05701 z m 0.186458,6.228028 c 0.7,0 1.670547,-0.356532 2.070547,-0.656532 '
                                + '0.4,-0.4 0.628503,-0.957007 0.628503,-1.557007 0,-0.6 -0.342517,-1.171021 -0.842517,'
                                + '-1.571021 -0.4,-0.3 -0.971585,-0.584284 -1.971497,-0.571022 l -4.2980997,0.05701 v '
                                + '4.341568 H 11.8038 Z" fill-rule="evenodd"></path></svg>';
                            editor.ui.registry.addIcon('semibold', svg);
                            editor.ui.registry.addToggleButton('semibold', {
                                icon: 'semibold',
                                tooltip: 'Semi-bold',
                                onAction: (_) => editor.execCommand('mceToggleFormat', false, 'semibold'),
                                onSetup: (api) => {
                                    api.setActive(editor.formatter.match('semibold'));
                                    //const changed = editor.formatter.formatChanged('semibold', (state) => api.setActive(state));
                                    //return () => changed.unbind();
                                }
                            });
                        };
                    }
                }
                this.tiny_editor_init = tinyEditorInit;

                // Prevent Bootstrap dialog from blocking focusin
                $(document).on('focusin', function(e) {
                    if (
                        $(e.target).closest(".tox-tinymce, .tox-tinymce-aux, .moxman-window, .tam-assetmanager-root").length
                    ) {
                        e.stopImmediatePropagation();
                    }
                });
            },
            getTextSize: function () {
                if (!this.data) {
                    return 10;
                }
                if (this.data.single_line || (this.data.type != 'T')) {
                    return 10;
                }
                if (!this.data.data2) {
                    return 10;
                }
                let highestPt = 10;
                for (let pt = 10; pt <= 500; pt++) {
                    if (
                        (this.data.data2.indexOf('<span style="font-size: ' + pt + 'pt') > -1)
                        || (this.data.data2.indexOf('<span style="font-size:' + pt + 'pt') > -1)
                        || (this.data.data2.indexOf('<span style="font-size: ' + pt + '.0pt') > -1)
                        || (this.data.data2.indexOf('<span style="font-size:' + pt + '.0pt') > -1)
                        || (this.data.data2.indexOf('<span style="font-size: ' + pt + '.5pt') > -1)
                        || (this.data.data2.indexOf('<span style="font-size:' + pt + '.5pt') > -1)
                    ) {
                        highestPt = pt;
                    }
                }
                if ((highestPt == 10) && this.data.styles) {
                    // Try styles
                    for (let pt = 10; pt <= 500; pt++) {
                        for (const [key, value] of Object.entries(this.data.styles)) {
                            if (
                                (key.indexOf(pt + 'pt') > -1)
                                || (key.indexOf(pt + 'pt') > -1)
                                || (key.indexOf(pt + '.0pt') > -1)
                                || (key.indexOf(pt + '.0pt') > -1)
                                || (key.indexOf(pt + '.5pt') > -1)
                                || (key.indexOf(pt + '.5pt') > -1)
                            ) {
                                highestPt = pt;
                            }
                        }
                    }
                }

                return highestPt;
            }
            /*getContentStyles: function () {
                if (!this.content) {
                    return this.$root.tiny_mce_content_styles;
                }
                let highestPt = 0;
                for (let pt = 20; pt <= 500; pt++) {
                    if (
                        (this.content.indexOf('<span style="font-size: ' + pt + 'pt') > -1)
                        || (this.content.indexOf('<span style="font-size:' + pt + 'pt') > -1)
                        || (this.content.indexOf('<span style="font-size: ' + pt + '.0pt') > -1)
                        || (this.content.indexOf('<span style="font-size:' + pt + '.0pt') > -1)
                        || (this.content.indexOf('<span style="font-size: ' + pt + '.5pt') > -1)
                        || (this.content.indexOf('<span style="font-size:' + pt + '.5pt') > -1)
                    ) {
                        highestPt = pt;
                    }
                }

                if (highestPt < 20) {
                    return this.$root.tiny_mce_content_styles;
                }

                const ratio = highestPt / 20;
                let styles = this.$root.tiny_mce_content_styles;
                for (let pt = 8; pt <= highestPt; pt++) {
                    styles += ' span[style="font-size: ' + pt + 'pt;"] { font-size: ' + (pt / ratio)
                        + 'pt !important; }';
                    styles += ' span[style="font-size: ' + pt + '.0pt;"] { font-size: ' + (pt / ratio)
                        + 'pt !important; }';
                    styles += ' span[style="font-size: ' + pt + '.5pt;"] { font-size: ' + ((pt + 0.5) / ratio)
                        + 'pt !important; }';
                }

                return styles;
            }*/,
            getImage: function () {
                this.loading = true;
                this.download_data = null;

                /**
                 * Send request to API.
                 */
                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.item.template_id + '/field-image/' + this.item.id;
                if (this.item.data) {
                    url += '/' + btoa(this.item.data);
                }
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                        vm.edit_image = response.data.edit_image;
                        vm.download_data = response.data.download_data;
                        vm.artwork_editor_component.updateTemplateField(vm.data, { preview: response.data.image_src });
                    }
                })
                .catch(function (error) {
                    //
                })
                .finally(() =>
                    vm.loading = false
                );
            },
            resetTemplateImages: function () {
                const appComponent = this.$root.findComponent(this.$root, 'main-app');
                const component = this.$root.findComponent(appComponent, 'template-images');
                if (component) {
                    component.reset(this);
                }
            },
            canEditImage: function (checkEditImage = true) {
                if (this.canGenerateQrCode()) {
                    return false;
                }
                if (this.item.locked || !this.item.data || !this.item.image_editing) {
                    return false;
                }
                if (!checkEditImage) {
                    return true;
                }

                return this.edit_image;
            },
            editImage: function () {
                const component = this.$root.findComponent(this.artwork_editor_component, 'template-image-editor');
                if (component) {
                    component.reset(this.edit_image, this.item.template_id, this.item.id, this);
                    $('#template-image-editor-modal').modal('show');
                }
            },
            canGenerateQrCode: function () {
                if (this.item.locked) {
                    return false;
                }
                if (!this.$root.client.artwork_generate_qr_codes) {
                    return false;
                }
                if (
                    (this.item.type != 'I')
                    || (
                        (this.item.title_suffix != '_QR')
                        && (this.item.title_suffix != '_LQR')
                        && (this.item.title_suffix != '_LQRx')
                    )
                ) {
                    return false;
                }

                return true;
            },
            generateQrCode: function () {
                if (!this.item.qr_code_text) {
                    this.errors = this.$root.text('Please enter an URL for the QR code.', null, true);

                    return null;
                }
                this.errors = false;
                this.updating = true;
                const data = {
                    qr_code_text: this.item.qr_code_text
                }

                /**
                 * Send request to API.
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.item.template_id + '/field/' + this.item.id + '/generate-qr-code';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.loading = true;
                    const data = {
                        qr_code_text: vm.item.qr_code_text,
                        data: (response.data.file_path || null)
                    };
                    vm.artwork_editor_component.updateTemplateField(
                        vm.data,
                        data,
                        true
                    );

                    setTimeout(function () {
                        vm.updateItem();
                    }, 500);
                })
                .catch(function (error) {
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            downloadFile: function (download) {
                if (this.downloading) {
                    return null;
                }
                this.errors = false;
                this.downloading = true;

                /**
                 * Send request to API.
                 */
                const data = {
                    item_type: 'collection_linked_file',
                    item_id: download.collection_linked_file_id,
                    file_path: download.file_path,
                    file_type: download.file_type,
                    download_filename: download.filename + '.' + download.extension
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/request-download-token';
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    const url = vm.$root.app.api_url + '/' + vm.$root.app.client_id + '/download/'
                        + response.data.token;
                    window.location.href = url;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // Missing/lost API token - logout user.
                        vm.$root.doLogout();
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() =>
                    vm.downloading = null
                );
            },
            dismissProfileFieldChanged: function () {
                this.artwork_editor_component.updateTemplateField(this.data, { profile_field_changed: false });
            },
            editTable: function () {
                const component = this.$root.findComponent(this.artwork_editor_component, 'template-table-fields');
                if (component) {
                    component.reset(this.item);
                    $('#template-table-fields-modal').modal('show');
                }
            },
            isTableField: function () {
                const tableSuffixes = ['_T', '_TA', '_TD', '_TF'];

                return (tableSuffixes.indexOf(this.data.title_suffix) > -1);
            },
            tableFieldClass: function () {
                if (!this.isTableField() || !this.item) {
                    return '';
                }
                if (this.item.error) {
                    return ' bg-danger';
                }
                if (this.item.warning) {
                    return ' bg-warning';
                }

                return '';
            }
        }
    }
</script>
<style scoped>
    .asset-preview .thumbnail {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
        height: auto;
        text-align: center;
    }

    .asset-preview .thumbnail img {
        max-height: 198px;
        position: static;
        margin: 0;
    }

    .asset-preview .thumbnail img.qr-code {
        max-height: 100px;
    }
</style>
