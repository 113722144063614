<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div v-if="(!errors && !loading && (total_items == 0))" class="alert alert-warning" role="alert">
            {{ $root.text('There are no collections shared', null, true) }}
        </div>
        <div v-if="(total_items > 0)" class="card">
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-centered table-hover">
                        <thead class="thead-light">
                            <tr>
                                <th scope="col">
                                    <div class="row">
                                        <div class="col-12">
                                            {{ $root.text('Collection name', null, true) }}
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in items" :key="item.collection_id">
                                <td scope="row" :id="'collection-row' + item.collection_id" class="pb-0">
                                    <div class="row">
                                        <div class="col-sm-11 col-10" style="padding-top: 3px">
                                            <h5 class="font-size-14 mb-0">
                                                <a
                                                    href="#"
                                                    class="text-dark"
                                                    @click.prevent="toggleCollectionDetails(item)"
                                                >
                                                    {{ item.name ? item.name : $root.text('(deleted collection)') }}
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-sm-1 col-2 font-size-18 contact-links">
                                            <a
                                                href="#"
                                                class="float-right"
                                                @click.prevent="toggleCollectionDetails(item)"
                                            >
                                                <span
                                                    v-if="(collection_details_open.indexOf(item.collection_id) < 0)"
                                                    :key="'close-' + item.collection_id"
                                                >
                                                    <i class="fa-regular fa-chevron-circle-down"></i>
                                                </span>
                                                <span v-else :key="'open-' + item.collection_id">
                                                    <i class="fa-regular fa-chevron-circle-up"></i>
                                                </span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row list-details-td mt-2">
                                        <div class="col-12 list-details" :id="'collection-details' + item.collection_id">
                                            <my-account-collection-shared-details :collection_id="item.collection_id">
                                            </my-account-collection-shared-details>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div style="position: relative; top: -20px; height: 40px; overflow: visible">
            <loading v-if="loading"></loading>
        </div>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    export default {
        data () {
            return {
                component_name: 'my-account-collections-shared',
                loading: false,
                errors: false,
                items: [],
                total_items: 0,
                current_page: 1,
                last_page: 1,
                collection_details_open: [],
            }
        },
        created() {
            this.handleDebouncedScroll = debounce(this.handleScroll, 100);
            window.addEventListener('scroll', this.handleDebouncedScroll);
        },
        mounted () {
            $('body').removeClass('login-body');
            this.getCollectionsShared();
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleDebouncedScroll);
        },
        watch: {
            total_items: function (val) {
                this.$root.status_info.right = this.total_items + this.$root.text(
                    (this.total_items == 1) ? 'collection' : 'collections'
                );
            }
        },
        methods: {
            getCollectionsShared: function (page = 1) {
                if (this.loading || (page < 1) || ((page > 1) && (page <= this.current_page))) {
                    return false;
                }
                this.current_page = page;
                this.loading = true;
                this.errors = false;
                if (page == 1) {
                    this.items = [];
                    this.total_items = 0;
                    this.current_page = 1;
                    this.last_page = 1;
                    this.collection_details_open = [];
                }

                /**
                 * Send get collections shared request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/report/collections-shared?page='
                    + page;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.total_items = response.data.collections.total;
                    vm.current_page = response.data.collections.current_page;
                    vm.last_page = response.data.collections.last_page;
                    response.data.collections.data.forEach((item) => {
                        vm.items.push(item);
                    });
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.loading = false;
                });
            },
            handleScroll(event) {
                if ((this.current_page < this.last_page) && !this.loading) {
                    const pageScrollTop = $('html, body').scrollTop();
                    const appHeight = $('#app').outerHeight();
                    const windowHeight = $(window).height();
                    const scrollPosition = appHeight - (pageScrollTop + windowHeight);
                    if (scrollPosition < 200) {
                        this.getCollectionsShared(this.current_page + 1);
                    }
                }
            },
            toggleCollectionDetails: function (collection) {
                if (this.collection_details_open.indexOf(collection.collection_id) < 0) {
                    this.collection_details_open.push(collection.collection_id);
                    $('#collection-row' + collection.collection_id).addClass('highlighted');
                    $('#collection-details' + collection.collection_id).slideDown('slow');
                } else {
                    var vm = this;
                    $('#collection-details' + collection.collection_id).slideUp('slow', function () {
                        $('#collection-row' + collection.collection_id).removeClass('highlighted');
                        for (var n = 0; n < vm.collection_details_open.length; n++) { 
                            if (vm.collection_details_open[n] == collection.collection_id) { 
                                vm.collection_details_open.splice(n, 1); 
                            }
                        }
                    });
                }
            }
        }
    }
</script>
