<template>
    <div>
        <div v-click-outside="config" class="right-bar">
            <div>
                <div class="rightbar-title px-3 py-4">
                    <a href="#" class="right-bar-toggle float-right" @click.prevent="hide()">
                        <i class="far fa-times noti-icon" style="font-size: 12px"></i>
                    </a>
                    <h5 class="m-0">{{ $root.text('Filters', null, true) }}</h5>
                </div>

                <hr class="mt-0">

                <div class="pl-3 pr-3 pt-1 pb-3">
                    <form v-on:submit.prevent="keywordSearch()">
                        <div class="form-group keyword">
                            <div class="input-group">
                                <input
                                    v-model="keyword"
                                    type="text"
                                    class="form-control"
                                    id="filter-by-keyword"
                                    :placeholder="$root.text('Search POD codes', null, true)"
                                    aria-label="Search POD codes"
                                >
                                <div class="input-group-append">
                                    <button class="btn btn-primary big-icon" type="submit">
                                        <i class="fal fa-search"></i>
                                    </button>
                                </div>
                            </div>
                            <button v-if="keyword" class="btn btn-link clear-keyword" @click.prevent="clearKeyword()">
                                <i class="far fa-times"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="rightbar-overlay"></div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                config: {
                    handler: this.handleRightBarClick,
                    middleware: this.middleware,
                    events: ['click']
                },
                keyword: ''
            };
        },
        mounted () {
            //
        },
        watch: {
            //
        },
        methods: {
            hide() {
                $('body').toggleClass('right-bar-enabled');
            },
            handleRightBarClick(e, el) {
                $('body').removeClass('right-bar-enabled');
            },
            middleware(event, el) {
                return !event.target.classList.contains('toggle-right');
            },
            keywordSearch: function () {
                const keywordTrimmed = this.keyword.trim();
                if (keywordTrimmed == '') {
                    return null;
                }
                let keywordsAdded = [];
                this.$root.pod_code_filters.forEach((item) => {
                    if (item.key == 'keyword') {
                        keywordsAdded.push(item.value);
                    }
                });
                if (keywordsAdded.indexOf(keywordTrimmed) > -1) {
                    return null;
                }
                this.$root.addFilter('pod_code_filters', {
                    key: 'keyword',
                    value: keywordTrimmed,
                    text: '&quot;' + keywordTrimmed.replace(/"/g, '&quot;').replace(/'/g, '&apos;') + '&quot;',
                    filters_key: 'pod_code_filters'
                });
                this.$parent.getPodCodes();
            },
            clearKeyword: function () {
                this.keyword = '';
                let filters = this.$root.pod_code_filters;
                for (let itemIndex = (filters.length -1); itemIndex >= 0; itemIndex--) {
                    if (filters[itemIndex].key == 'keyword') {
                        filters.splice(itemIndex, 1);
                    }
                }
                this.$parent.getPodCodes();
                $('#filter-by-keyword').focus();
            }
        }
    }
</script>
<style scoped>
    .form-group.keyword {
        position: relative;
    }

    .clear-keyword {
        position: absolute;
        top: 2px;
        right: 35px;
        z-index: 9;
    }
</style>
