<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="request-deletion-modal-label">
                {{ $root.text('Request account deletion', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert" :key="'alert-success'">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>

            <div
                v-if="(!sent && $root.user.deletion_requested)"
                class="alert alert-info"
                role="alert"
                :key="'alert-info'"
            >
                <span v-if="requested_at_formatted">
                    {{ $root.text('You have already requested your account be deleted on {date}.',
                        { date: requested_at_formatted }, true) }}
                </span>
                <span v-else>
                    {{ $root.text('You have already requested your account be deleted.', null, true) }}
                </span>
	            <br><br>
                <a href="#">{{ $root.text('click here', null, true) }}</a>
                {{ $root.text('{click_here} to email the administrator to chase the deletion.',
                    { click_here: '' }, true) }}
            </div>

            <div v-if="(!sent && !$root.user.deletion_requested)">
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>
                    <p>{{ $root.text('Are you sure you want to delete your account?', null, true) }}</p>
                    <p>{{ $root.text('The request will be sent to your system administrator to action.', null,
                        true) }}</p>
                </form>
            </div>
        </div>
        <div v-if="(!sent && !$root.user.deletion_requested)" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="onSubmit()"
                    >
                        <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Submit request', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Submit request', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                requested_at_formatted: null,
                get_date_time_formatted_response: null,
            }
        },
        mounted () {
            //
        },
        watch: {
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'requested_at') {
                            this.requested_at_formatted = val.date_time_formatted;
                        }
                    }
                }
            }
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                if (!this.requested_at_formatted) {
                    this.$root.getDateTimeFormatted(
                        this,
                        'requested_at',
                        this.$root.user.deletion_requested_at,
                        'd/m/Y \\a\\t g:ia'
                    );
                }
            },
            onSubmit: function () {
                this.sending = true;
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };

                const path = '/' + this.$root.app.client_id + '/user/request-deletion';
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.sent = response.data.message;
                    vm.$root.user.deletion_requested = 1;
                    vm.$root.user.deletion_requested_at = response.data.requested_at;
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#request-deletion-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            resetForgottenPasswordForm: function () {
                $('#request-deletion-modal').modal('hide');
                const component = this.$root.findComponent(this.$parent, 'forgotten-password');
                if (component) {
                    component.resetForm();
                }
            }
        }
    }
</script>
