<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="keyword-suggestion-add-form-modal-label">
                {{ $root.text('Add keyword suggestion', null, true) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <form v-on:submit.prevent="onSubmit()">
                    <button type="submit" class="d-none"></button>
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label>{{ $root.text('Category', null, true) }}</label>
                        <input
                            v-model="category_name"
                            type="text"
                            class="form-control"
                            :readonly="true"
                        >
                    </div>
                    <div class="form-group">
                        <label for="keyword" id="keyword-label" class="mandatory-label">
                            {{ $root.text('Keyword', null, true) }} *
                        </label>
                        <input
                            v-model="keyword_suggestion.keyword"
                            type="text"
                            id="keyword"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('keyword')"
                            v-on:change="$root.unHighlightErrors('keyword')"
                        >
                    </div>
                    <div class="form-group">
                        <label for="supporting-keywords">
                            {{ $root.text('Supporting keywords', null, true) }}
                        </label>
                        <keywords-field
                            v-model="keyword_suggestion.supporting_keywords"
                            field_id="supporting-keywords"
                            field_class="form-control pb-2"
                            :readonly="updating"
                            :keyword_placeholder="$root.text('Separate keywords with , then click +', null, true)"
                            :add_current_keyword="updating"
                            v-on:change="$root.unHighlightErrors('supporting-keywords')"
                        ></keywords-field>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!updated" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="submit" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-check mr-1"></i> {{ $root.text('Add', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Add', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                category_name: '',
                list_component: null,
                keyword_suggestion: {}
            }
        },
        mounted () {
            //
        },
        methods: {
            resetForm: function (category, listComponent = null) {
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.category_name = category.name;
                this.list_component = listComponent;
                this.keyword_suggestion = {
                    keyword_category_id: category.id
                };
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                setTimeout(function () {
                    $('#keyword').focus();
                }, 500);
            },
            onSubmit: function (delay = true) {
                this.errors = false;
                this.updating = true;
                var vm = this;
                if (delay) {
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 200);

                    return null;
                }
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const data = this.keyword_suggestion;
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/keyword-suggestion';

                axios({
                    method: 'post',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.updated = 'Success';
                    if (response.data.message) {
                        vm.updated = response.data.message;
                        vm.list_component.getKeywordSuggestions();
                    }

                    setTimeout(function () {
                        $('#keyword-suggestion-add-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#keyword-suggestion-add-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    vm.errors += value.join('<br>') + '<br>';
                                    $('#' + key + '-label').addClass('text-danger');
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            }
        }
    }
</script>
