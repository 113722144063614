<template>
    <div :id="field_id" :class="field_class" style="height: auto">
        <!--<div v-if="(items.length > 0)">-->
            <div class="card mb-0" style="box-shadow: unset">
                <div v-if="!no_header" class="card-header px-3 py-1 bg-light text-dark font-weight-normal">
                    {{ $root.text('Keywords added', null, true) }}
                </div>
                <div class="card-body pt-2 pb-0 px-0">
                    <span v-if="(items.length > 0)">
                        <span v-for="item in items" :key="item.id" class="contact-links">
                            <span v-if="item.heading" class="mb-2" style="display: block">{{ detected_heading }}</span>
                            <span
                                :class="'badge badge-soft-' + (item.detected ? 'primary' : 'secondary')
                                    + ' contact-links font-size-12 mr-2 mb-2'"
                                :style="'display: inline-block; white-space: nowrap; position: relative' 
                                    + ((item.detected || (hasDetected() !== false))
                                    ? '; padding-left: 20px' : '')"
                            >
                                <span v-if="item.detected" class="keyword-icon" :key="'id' + item.id">
                                    <i class="fa-thin fa-microchip-ai"></i>
                                </span>
                                <span
                                    v-if="!item.detected && (hasDetected() !== false)"
                                    class="keyword-icon font-size-14"
                                    style="left: 3px"
                                     :key="'i' + item.id"
                                >
                                    <i class="fa-light fa-user"></i>
                                </span>
                                {{ item.text }}
                                <a
                                    v-if="!readonly"
                                    href="#"
                                    v-b-tooltip.hover
                                    :title="$root.text('Remove', null, true)"
                                    @click.prevent="removeKeyword(item)"
                                >
                                    <i class="far fa-times"></i>
                                </a>
                                <span v-if="readonly">
                                    <i class="far fa-times"></i>
                                </span>
                            </span>
                        </span>
                    </span>
                    <div v-if="((items.length == 0) && placeholder)" class="text-muted mb-2">{{ placeholder }}</div>
                </div>
            </div>
        <!--</div>-->

        <form v-on:submit.prevent="addKeyword(true)" class="mt-2">
            <div class="input-group">
                <input
                    v-model="keyword"
                    type="text"
                    class="form-control"
                    :id="'keyword-input-' + field_id"
                    :placeholder="keywordPlaceholder()"
                    v-on:focus="keywordPlaceholder(true)"
                    v-on:blur="keywordPlaceholder(true)"
                    :aria-label="keyword_placeholder"
                    :readonly="readonly"
                >
                <div class="input-group-append">
                    <button
                        class="btn btn-secondary big-icon"
                        :id="'keyword-add-button-' + field_id"
                        type="submit"
                        :disabled="(readonly || !keyword)"
                    >
                        <i class="fal fa-plus"></i>
                    </button>
                </div>
            </div>
            <b-tooltip
                v-if="keyword"
                :target="'keyword-add-button-' + field_id"
                triggers="hover"
            >
                {{ $root.text('Add to list', null, true) }}
            </b-tooltip>
        </form>

        <div v-if="(keyword_categories.length > 0)" class="form-control mt-2" style="height: auto">
            <div @click="toggleAssistant()">
                <span class="use-keyword-assistant">{{ $root.text('Use keyword assistant', null, true) }}</span>
                <span class="float-right" style="position: relative; top: 1px; left: -1px">
                    <span v-if="!is_assistant_visible">
                        <i class="fas fa-chevron-down"></i>
                    </span>
                    <strong v-else>
                        <span>
                            <i class="fas fa-chevron-up"></i>
                        </span>
                    </strong>
                </span>
            </div>

            <!--<div :id="'keyword-assistant_' + this.field_id" class="mt-2 mb-2" style="display: none">-->
            <div v-if="is_assistant_visible" class="mt-2 mb-2">
                <div class="card mb-0">
                    <div class="card-header px-3 py-1 bg-light text-dark font-weight-normal">
                        {{ $root.text('Categories', null, true) }}
                    </div>
                    <div class="card-body px-2 px-3">
                        <div class="row">
                            <div
                                class="col"
                                style="max-height: 220px; overflow-y: auto; border-right: 1px solid #eff2f7"
                            >
                                <div
                                    v-for="category in keyword_categories"
                                    :key="category.id"
                                    :class="keywordCategoryClass(category)"
                                    @click="assistant_category = category"
                                    style="padding-top: 2px; padding-bottom: 2px"
                                >
                                    {{ category.name }}
                                    <span class="chevron-right ml-2">
                                        <i class="far fa-chevron-right"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="col" style="max-height: 220px; overflow-y: auto">
                                <div v-if="assistant_category">
                                    <div
                                        v-for="keyword_suggestion in assistant_category.keyword_suggestions"
                                        :key="keyword_suggestion.id"
                                        style="padding-top: 2px; padding-bottom: 2px"
                                    >
                                        {{ keyword_suggestion.keyword }}
                                        <span
                                            v-if="(isKeywordAdded(keyword_suggestion.keyword) === false)"
                                            class="float-right ml-2"
                                        >
                                            <button
                                                type="button"
                                                class="btn btn-secondary btn-sm pt-0 pb-0"
                                                @click="assistantAddKeyword(keyword_suggestion)"
                                                :disabled="readonly"
                                            >
                                                <i class="far fa-plus"></i>
                                            </button>
                                        </span>
                                        <span v-else>
                                            <span class="float-right ml-2">
                                                <button
                                                    type="button"
                                                    class="btn btn-light btn-sm pt-0 pb-0"
                                                    v-b-tooltip.hover.left
                                                    :title="$root.text('Remove from list', null, true)"
                                                    @click="assistantAddKeyword(keyword_suggestion)"
                                                    :disabled="readonly"
                                                >
                                                    <i class="far fa-check"></i>
                                                </button>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'value',
            'field_id',
            'field_class',
            'readonly',
            'placeholder',
            'keyword_placeholder',
            'add_current_keyword',
            'keyword_categories',
            'no_header',
            'detected_heading'
        ],
        data() {
            return {
                items: [],
                keyword: '',
                is_assistant_visible: false,
                assistant_category: null
            };
        },
        mounted () {
            this.resetKeywords(this.value);
        },
        watch: {
            value: function (val) {
                this.resetKeywords(val);
            },
            add_current_keyword: function(val) {
                if (val) {
                    this.addKeyword();
                }
            }/*,
            is_assistant_visible: function (val) {
                if (val) {
                    $('#keyword-assistant_' + this.field_id).slideDown(350);

                    return null;
                }
                $('#keyword-assistant_' + this.field_id).slideUp(350);
            }*/
        },
        methods: {
            resetKeywords: function (keywordsInput) {
                this.items = [];
                if (keywordsInput) {
                    const keywords = keywordsInput.split(',');
                    let detectedFound = false;
                    keywords.forEach((text, index) => {
                        if (text.trim() != '') {
                            if (text.indexOf('[detected]') == 0) {
                                const addHeading = (this.detected_heading && !detectedFound);
                                this.items.push({
                                    id: (index + 1),
                                    text: text.substring(10),
                                    detected: true,
                                    heading: addHeading 
                                });
                                detectedFound = true;
                            } else {
                                this.items.push({
                                    id: (index + 1),
                                    text: text.trim()
                                });
                            }
                        }
                    });
                }
            },
            addKeyword: function (focusInput = false) {
                if (this.keyword != '') {
                    let keywords = this.keyword.split(',');
                    keywords.forEach((text) => {
                        if (text.trim() != '') {
                            let id = this.getNextId(text.trim());
                            if (id) {
                                let firstDetectedIndex = this.hasDetected();
                                if (firstDetectedIndex !== false) {
                                    this.items.splice(firstDetectedIndex, 0, {
                                        id: id,
                                        text: text.trim()
                                    });
                                } else {
                                    this.items.push({
                                        id: id,
                                        text: text.trim()
                                    });
                                }
                            }
                        }
                    });
                    this.$emit('input', this.getKeywords());
                    this.$emit('change');
                    this.keyword = '';
                }
                if (focusInput) {
                    $('#keyword-input-' + this.field_id).focus();
                }
            },
            getNextId: function (text) {
                let maxId = 0;
                this.items.forEach((item) => {
                    if (item.text == text) {
                        maxId = null;
                    }
                    if ((maxId !== null) && (item.id > maxId)) {
                        maxId = item.id;
                    }
                });
                if (maxId === null) {
                    return null;
                }

                return (maxId + 1);
            },
            removeKeyword: function (itemToRemove) {
                let itemIndex = -1;
                this.items.forEach((item, index) => {
                    if (item == itemToRemove) {
                        itemIndex = index;
                    }
                });
                if (itemIndex > -1) {
                    this.items.splice(itemIndex, 1);
                    this.$emit('input', this.getKeywords());
                    this.$emit('change');
                }
            },
            getKeywords: function () {
                let keywords = [];
                this.items.forEach((item) => {
                    let keyword = item.text;
                    if (item.detected) {
                        keyword = '[detected]' + keyword;
                    }
                    keywords.push(keyword);
                });

                return keywords.join(',');
            },
            keywordPlaceholder: function (update = false) {
                const focused = $('#keyword-input-' + this.field_id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#keyword-input-' + this.field_id).attr('placeholder', null);
                    }

                    return null;
                }
                const text = (this.keyword_placeholder || this.$root.text('Enter keyword(s)', null, true));
                if (update) {
                    $('#keyword-input-' + this.field_id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            toggleAssistant: function () {
                this.is_assistant_visible = !this.is_assistant_visible;
            },
            keywordCategoryClass: function (category) {
                if (category == this.assistant_category) {
                    return 'keyword-category active pl-2 pr-2';
                }

                return 'keyword-category pl-2 pr-4';
            },
            isKeywordAdded: function (keyword) {
                if (!keyword) {
                    return false;
                }
                let itemIndex = -1;
                this.items.forEach((item, index) => {
                    if (item.text == keyword) {
                        itemIndex = index;
                    }
                });

                return (itemIndex > -1) ? itemIndex : false;
            },
            assistantAddKeyword: function (item) {
                const added = (this.isKeywordAdded(item.keyword) !== false);
                if (added) {
                    this.removeSingleKeyword(item.keyword);
                } else {
                    this.addSingleKeyword(item.keyword);
                }
                const supportingKeywords = item.supporting_keywords ? item.supporting_keywords.split(',') : [];
                supportingKeywords.forEach((keyword, index) => {
                    if (added) {
                        this.removeSingleKeyword(keyword);
                    } else {
                        this.addSingleKeyword(keyword);
                    }
                });
            },
            addSingleKeyword: function(keyword) {
                this.keyword = keyword;
                this.addKeyword();
            },
            removeSingleKeyword: function(keyword) {
                let itemToRemove = null;
                this.items.forEach((item) => {
                    if (item.text == keyword) {
                        itemToRemove = item;
                    }
                });
                if (itemToRemove) {
                    this.removeKeyword(itemToRemove);
                }
            },
            hasDetected: function () {
                if (this.items.length == 0) {
                    return false;
                }
                let found = false;
                this.items.forEach((item, index) => {
                    if ((found === false) && item.detected) {
                        found = index;
                    }
                });

                return found;
            }
        }
    }
</script>
<style scoped>
    .keyword-category {
        cursor: pointer;
        border-bottom: 1px solid #fff;
    }

    .keyword-category.active, .keyword-category:hover {
        background-color: #eff2f7;
    }

    .keyword-category .chevron-right {
        float: right;
        display: none;
    }

    .keyword-category.active .chevron-right {
        display: block;
        position: relative;
        top: 1px;
    }

    .use-keyword-assistant {
        color: #74788d;
    }

    .keyword-icon {
        font-size: 16px;
        line-height: 16px;
        position: absolute;
        top: 1px;
        left: 1px;
        height: 19px;
    }
</style>
