import Vue from 'vue';
import VueCookie from 'vue-cookie';
import VueTheMask from 'vue-the-mask';
import VueColor from 'vue-color';
import { BootstrapVue } from "bootstrap-vue";
import vClickOutside from "v-click-outside";
import { VueReCaptcha } from 'vue-recaptcha-v3';

window.Vue = Vue;

Vue.use(BootstrapVue);
Vue.use(vClickOutside);
Vue.use(VueCookie);
Vue.use(VueTheMask);
var Chrome = VueColor.Chrome;

const reCaptchaSiteKey = $('#app').attr('data-recaptcha-site-key') || null;
if (reCaptchaSiteKey) {
	Vue.use(VueReCaptcha, { siteKey: reCaptchaSiteKey });
}

Vue.component('language-select', require('./components/LanguageSelect.vue').default);
Vue.component('loading', require('./components/Loading.vue').default);
Vue.component('welcome-panel', require('./components/home/WelcomePanel.vue').default);
Vue.component('flyte-panel', require('./components/home/FlytePanel.vue').default);
Vue.component('home-panel', require('./components/home/HomePanel.vue').default);
Vue.component('home-top-nav', require('./components/home/HomeTopNav.vue').default);
Vue.component('home-panel-edit-form', require('./components/home/HomePanelEditForm.vue').default);
Vue.component('forgotten-password', require('./components/ForgottenPassword.vue').default);
Vue.component('email-support', require('./components/EmailSupport.vue').default);
Vue.component('main-nav', require('./components/main-nav/MainNav.vue').default);
Vue.component('main-nav-small', require('./components/main-nav/MainNavSmall.vue').default);
Vue.component('search-dropdown-content', require('./components/main-nav/SearchDropdownContent.vue').default);
Vue.component('change-password', require('./components/ChangePassword.vue').default);
Vue.component('register', require('./components/Register.vue').default);
Vue.component('terms-and-conditions', require('./components/TermsAndConditions.vue').default);
Vue.component('site-footer', require('./components/SiteFooter.vue').default);
Vue.component('edit-content', require('./components/settings/EditContent.vue').default);
Vue.component('add-something', require('./components/AddSomething.vue').default);
Vue.component('assets-top-nav', require('./components/assets/AssetsTopNav.vue').default);
Vue.component('asset-filters', require('./components/assets/AssetFilters.vue').default);
Vue.component('asset-preview', require('./components/assets/AssetPreview.vue').default);
Vue.component('asset-add-form', require('./components/assets/AssetAddForm.vue').default);
Vue.component('asset-edit-form', require('./components/assets/AssetEditForm.vue').default);
Vue.component('asset-replace-form', require('./components/assets/AssetReplaceForm.vue').default);
Vue.component('asset-archive', require('./components/assets/AssetArchive.vue').default);
Vue.component('asset-de-archive', require('./components/assets/AssetDeArchive.vue').default);
Vue.component('breadcrumbs', require('./components/Breadcrumbs.vue').default);
Vue.component('message', require('./components/Message.vue').default);
Vue.component('multiple-uploads-tooltip', require('./components/assets/MultipleUploadsTooltip.vue').default);
Vue.component('related-assets-tooltip', require('./components/assets/RelatedAssetsTooltip.vue').default);
Vue.component('reverse-image-search-tooltip', require('./components/assets/ReverseImageSearchTooltip.vue').default);
Vue.component('confirm-delete', require('./components/ConfirmDelete.vue').default);
Vue.component('settings-top-nav', require('./components/settings/SettingsTopNav.vue').default);
Vue.component('users-top-nav', require('./components/users/UsersTopNav.vue').default);
Vue.component('user-filters', require('./components/users/UserFilters.vue').default);
Vue.component('user-edit-form', require('./components/users/UserEditForm.vue').default);
Vue.component('decline-user', require('./components/users/DeclineUser.vue').default);
Vue.component('reset-user', require('./components/users/ResetUser.vue').default);
Vue.component('cancel-invite', require('./components/users/CancelInvite.vue').default);
Vue.component('asset-types-top-nav', require('./components/assets/AssetTypesTopNav.vue').default);
Vue.component('asset-type-add-form', require('./components/assets/AssetTypeAddForm.vue').default);
Vue.component('asset-type-edit-form', require('./components/assets/AssetTypeEditForm.vue').default);
Vue.component('asset-type-delete', require('./components/assets/AssetTypeDelete.vue').default);
Vue.component('folders-top-nav', require('./components/assets/FoldersTopNav.vue').default);
Vue.component('folder-add-form', require('./components/assets/FolderAddForm.vue').default);
Vue.component('folder-edit-form', require('./components/assets/FolderEditForm.vue').default);
Vue.component('folder-delete', require('./components/assets/FolderDelete.vue').default);
Vue.component('folder-archive', require('./components/assets/FolderArchive.vue').default);
Vue.component('folder-de-archive', require('./components/assets/FolderDeArchive.vue').default);
Vue.component('subfolder-add-form', require('./components/assets/SubfolderAddForm.vue').default);
Vue.component('subfolder-edit-form', require('./components/assets/SubfolderEditForm.vue').default);
Vue.component('subfolders', require('./components/assets/Subfolders.vue').default);
Vue.component('subfolder-delete', require('./components/assets/SubfolderDelete.vue').default);
Vue.component('subfolder-archive', require('./components/assets/SubfolderArchive.vue').default);
Vue.component('subfolder-de-archive', require('./components/assets/SubfolderDeArchive.vue').default);
Vue.component('keywords-field', require('./components/assets/KeywordsField.vue').default);
Vue.component('keywords-field-with-assistant', require('./components/assets/KeywordsFieldWithAssistant.vue').default);
Vue.component('searchable-option-list', require('./components/SearchableOptionList.vue').default);
Vue.component('plain-option-list', require('./components/PlainOptionList.vue').default);
Vue.component('custom-field-edit-form', require('./components/assets/CustomFieldEditForm.vue').default);
Vue.component('keyword-category-add-form', require('./components/assets/KeywordCategoryAddForm.vue').default);
Vue.component('keyword-category-edit-form', require('./components/assets/KeywordCategoryEditForm.vue').default);
Vue.component('keyword-suggestion-add-form', require('./components/assets/KeywordSuggestionAddForm.vue').default);
Vue.component('keyword-suggestion-edit-form', require('./components/assets/KeywordSuggestionEditForm.vue').default);
Vue.component('keyword-suggestions', require('./components/assets/KeywordSuggestions.vue').default);
Vue.component('language-add-form', require('./components/settings/LanguageAddForm.vue').default);
Vue.component('language-edit-form', require('./components/settings/LanguageEditForm.vue').default);
Vue.component('user-group-add-form', require('./components/users/UserGroupAddForm.vue').default);
Vue.component('user-group-edit-form', require('./components/users/UserGroupEditForm.vue').default);
Vue.component('user-group-users', require('./components/users/UserGroupUsers.vue').default);
Vue.component('user-group-user-add-form', require('./components/users/UserGroupUserAddForm.vue').default);
Vue.component('asset-details', require('./components/assets/AssetDetails.vue').default);
Vue.component('asset-selection', require('./components/assets/AssetSelection.vue').default);
Vue.component('add-to-collection', require('./components/collections/AddToCollection.vue').default);
Vue.component('collections-top-nav', require('./components/collections/CollectionsTopNav.vue').default);
Vue.component('collection-sub-nav', require('./components/collections/CollectionSubNav.vue').default);
Vue.component('collection-filters', require('./components/collections/CollectionFilters.vue').default);
Vue.component('collection-preview', require('./components/collections/CollectionPreview.vue').default);
Vue.component('collection-add-form', require('./components/collections/CollectionAddForm.vue').default);
Vue.component('collection-bulk-edit', require('./components/collections/CollectionBulkEdit.vue').default);
Vue.component('collection-download', require('./components/collections/CollectionDownload.vue').default);
Vue.component('collection-share', require('./components/collections/CollectionShare.vue').default);
Vue.component(
	'collection-linked-file-add-form',
	require('./components/collections/CollectionLinkedFileAddForm.vue').default
);
Vue.component(
	'collection-linked-file-preview',
	require('./components/collections/CollectionLinkedFilePreview.vue').default
);
Vue.component(
	'collection-linked-file-details',
	require('./components/collections/CollectionLinkedFileDetails.vue').default
);
Vue.component('collaborator-add-form', require('./components/collections/CollaboratorAddForm.vue').default);
Vue.component('collaborate', require('./components/collections/Collaborate.vue').default);
Vue.component('collaborate-comment', require('./components/collections/CollaborateComment.vue').default);
Vue.component('user-add-form', require('./components/users/UserAddForm.vue').default);
Vue.component('user-activity', require('./components/users/UserActivity.vue').default);
Vue.component('user-activity-item', require('./components/users/UserActivityItem.vue').default);
Vue.component('resend-expired-invites', require('./components/users/ResendExpiredInvites.vue').default);
Vue.component('asset-previous-versions', require('./components/assets/AssetPreviousVersions.vue').default);
Vue.component('asset-linked-files', require('./components/assets/AssetLinkedFiles.vue').default);
Vue.component('asset-linked-file-add-form', require('./components/assets/AssetLinkedFileAddForm.vue').default);
Vue.component('asset-approval-request-form', require('./components/assets/AssetApprovalRequestForm.vue').default);
Vue.component('asset-approval-filters', require('./components/assets/AssetApprovalFilters.vue').default);
Vue.component('asset-approval-details', require('./components/assets/AssetApprovalDetails.vue').default);
Vue.component('decline-asset-approval-request', require('./components/assets/DeclineAssetApprovalRequest.vue').default);
Vue.component('alert-add-form', require('./components/settings/AlertAddForm.vue').default);
Vue.component('alert-edit-form', require('./components/settings/AlertEditForm.vue').default);
Vue.component('asset-review-details', require('./components/assets/AssetReviewDetails.vue').default);
Vue.component('asset-reviews-bulk-edit', require('./components/assets/AssetReviewsBulkEdit.vue').default);
Vue.component('asset-download', require('./components/assets/AssetDownload.vue').default);
Vue.component('asset-metadata', require('./components/assets/AssetMetadata.vue').default);
Vue.component('asset-share', require('./components/assets/AssetShare.vue').default);
Vue.component('filter-share', require('./components/assets/FilterShare.vue').default);
Vue.component(
	'detected-label-exclusion-add-form',
	require('./components/assets/DetectedLabelExclusionAddForm.vue').default
);
Vue.component('shared-top-nav', require('./components/SharedTopNav.vue').default);
Vue.component(
	'pre-approved-email-domain-add-form',
	require('./components/users/PreApprovedEmailDomainAddForm.vue').default
);
Vue.component(
	'pre-approved-email-domain-edit-form',
	require('./components/users/PreApprovedEmailDomainEditForm.vue').default
);
Vue.component('image-editor', require('./components/ImageEditor.vue').default);
Vue.component('select-item', require('./components/SelectItem.vue').default);
Vue.component('select-item-asset-preview', require('./components/SelectItemAssetPreview.vue').default);
Vue.component('my-account-top-nav', require('./components/my-account/MyAccountTopNav.vue').default);
Vue.component('my-account-edit', require('./components/my-account/MyAccountEdit.vue').default);
Vue.component('my-account-upload-details', require('./components/my-account/MyAccountUploadDetails.vue').default);
Vue.component('my-account-download-details', require('./components/my-account/MyAccountDownloadDetails.vue').default);
Vue.component(
	'my-account-asset-approval-details',
	require('./components/my-account/MyAccountAssetApprovalDetails.vue').default
);
Vue.component(
	'my-account-collection-shared-details',
	require('./components/my-account/MyAccountCollectionSharedDetails.vue').default
);
Vue.component('flyte-sent-details', require('./components/FlyteSentDetails.vue').default);
Vue.component('request-deletion', require('./components/my-account/RequestDeletion.vue').default);
Vue.component('quick-notifications', require('./components/notifications/QuickNotifications.vue').default);
Vue.component('quick-notification-item', require('./components/notifications/QuickNotificationItem.vue').default);
Vue.component('notification-item', require('./components/notifications/NotificationItem.vue').default);
Vue.component('notifications-top-nav', require('./components/notifications/NotificationsTopNav.vue').default);
Vue.component('flyte-form', require('./components/flyte/FlyteForm.vue').default);
Vue.component('flyte-file-preview', require('./components/flyte/FlyteFilePreview.vue').default);
Vue.component('flyte-download', require('./components/flyte/FlyteDownload.vue').default);
Vue.component('bar-chart', require('./components/BarChart.vue').default);
Vue.component('doughnut-chart', require('./components/DoughnutChart.vue').default);
Vue.component('apex-stroked-gauge-chart', require('./components/ApexStrokedGaugeChart.vue').default);
Vue.component('apex-spline-area-chart', require('./components/ApexSplineAreaChart.vue').default);
Vue.component('report-filters', require('./components/settings/ReportFilters.vue').default);
Vue.component('report-details', require('./components/settings/ReportDetails.vue').default);
Vue.component('pod-codes-top-nav', require('./components/pod-codes/PodCodesTopNav.vue').default);
Vue.component('pod-code-filters', require('./components/pod-codes/PodCodeFilters.vue').default);
Vue.component('pod-code-add-form', require('./components/pod-codes/PodCodeAddForm.vue').default);
Vue.component('pod-code-edit-form', require('./components/pod-codes/PodCodeEditForm.vue').default);
Vue.component('template-add-form', require('./components/templates/TemplateAddForm.vue').default);
Vue.component('manage-field-order', require('./components/templates/ManageFieldOrder.vue').default);
Vue.component('manage-images', require('./components/templates/ManageImages.vue').default);
Vue.component('manage-image-preview', require('./components/templates/ManageImagePreview.vue').default);
Vue.component('template-image-upload', require('./components/templates/TemplateImageUpload.vue').default);
Vue.component('template-options', require('./components/templates/TemplateOptions.vue').default);
Vue.component('artwork-editor', require('./components/templates/ArtworkEditor.vue').default);
Vue.component('template-field', require('./components/templates/TemplateField.vue').default);
Vue.component('template-table-fields', require('./components/templates/TemplateTableFields.vue').default);
Vue.component('template-images', require('./components/templates/TemplateImages.vue').default);
Vue.component('template-image-preview', require('./components/templates/TemplateImagePreview.vue').default);
Vue.component('artwork-download', require('./components/templates/ArtworkDownload.vue').default);
Vue.component('artwork-save', require('./components/templates/ArtworkSave.vue').default);
Vue.component('saved-artworks', require('./components/templates/SavedArtworks.vue').default);
Vue.component('saved-artwork-preview', require('./components/templates/SavedArtworkPreview.vue').default);
Vue.component('artwork-view', require('./components/templates/ArtworkView.vue').default);
Vue.component('resourcepage', require('./components/templates/Resourcepage.vue').default);
Vue.component('print-orders-top-nav', require('./components/print-orders/PrintOrdersTopNav.vue').default);
/*Vue.component('print-order-supplier-add-form', require('./components/print-orders/PrintOrderSupplierAddForm.vue')
	.default);*/
Vue.component('print-order-supplier-edit-form', require('./components/print-orders/PrintOrderSupplierEditForm.vue')
	.default);
Vue.component('price-panel-type-add-form', require('./components/print-orders/PricePanelTypeAddForm.vue').default);
Vue.component('price-panel-type-edit-form', require('./components/print-orders/PricePanelTypeEditForm.vue').default);
Vue.component('price-panel-add-form', require('./components/print-orders/PricePanelAddForm.vue').default);
Vue.component('price-panel-edit-form', require('./components/print-orders/PricePanelEditForm.vue').default);
Vue.component('price-panel-view', require('./components/print-orders/PricePanelView.vue').default);
Vue.component('price-panel-view-assets', require('./components/print-orders/PricePanelViewAssets.vue').default);
Vue.component('price-panels', require('./components/print-orders/PricePanels.vue').default);
Vue.component('price-panel-form-items', require('./components/print-orders/PricePanelFormItems.vue').default);
Vue.component('profile-field-edit-form', require('./components/templates/ProfileFieldEditForm.vue').default);
Vue.component('profile-groups-top-nav', require('./components/templates/ProfileGroupsTopNav.vue').default);
Vue.component('profile-group-add-form', require('./components/templates/ProfileGroupAddForm.vue').default);
Vue.component('profile-group-edit-form', require('./components/templates/ProfileGroupEditForm.vue').default);
Vue.component('profile-group-filters', require('./components/templates/ProfileGroupFilters.vue').default);
Vue.component('profile-edit-form', require('./components/templates/ProfileEditForm.vue').default);
Vue.component('profile-user-edit-form', require('./components/templates/ProfileUserEditForm.vue').default);
Vue.component('profile-image-field', require('./components/templates/ProfileImageField.vue').default);
Vue.component('saved-artworks-bar', require('./components/templates/SavedArtworksBar.vue').default);
Vue.component('saved-artwork-bar-preview', require('./components/templates/SavedArtworkBarPreview.vue').default);
Vue.component('print-order-form', require('./components/print-orders/PrintOrderForm.vue').default);
Vue.component('print-order-filters', require('./components/print-orders/PrintOrderFilters.vue').default);
Vue.component('print-order-details', require('./components/print-orders/PrintOrderDetails.vue').default);
Vue.component('template-replace-form', require('./components/templates/TemplateReplaceForm.vue').default);
Vue.component('artwork-approval-request-form', require('./components/templates/ArtworkApprovalRequestForm.vue').default);
Vue.component('artwork-approval-filters', require('./components/templates/ArtworkApprovalFilters.vue').default);
Vue.component('artwork-approval-details', require('./components/templates/ArtworkApprovalDetails.vue').default);
Vue.component('artwork-share', require('./components/templates/ArtworkShare.vue').default);
Vue.component('shared-artwork-preview', require('./components/templates/SharedArtworkPreview.vue').default);
Vue.component('template-archive', require('./components/templates/TemplateArchive.vue').default);
Vue.component('template-de-archive', require('./components/templates/TemplateDeArchive.vue').default);
Vue.component(
	'print-order-copy-recipient-add-form',
	require('./components/print-orders/PrintOrderCopyRecipientAddForm.vue').default
);
Vue.component('template-image-editor', require('./components/templates/TemplateImageEditor.vue').default);
Vue.component(
	'decline-artwork-approval-request',
	require('./components/templates/DeclineArtworkApprovalRequest.vue').default
);
Vue.component('artwork-action-details', require('./components/templates/ArtworkActionDetails.vue').default);
Vue.component('decline-print-order', require('./components/print-orders/DeclinePrintOrder.vue').default);
Vue.component('chase-print-order', require('./components/print-orders/ChasePrintOrder.vue').default);
Vue.component(
	'price-panel-edit-modal-wrapper',
	require('./components/print-orders/PricePanelEditModalWrapper.vue').default
);
Vue.component('artwork-history', require('./components/templates/ArtworkHistory.vue').default);
Vue.component('artwork-history-item', require('./components/templates/ArtworkHistoryItem.vue').default);
Vue.component('artwork-history-details', require('./components/templates/ArtworkHistoryDetails.vue').default);
Vue.component(
	'profile-group-stats-with-chart',
	require('./components/templates/ProfileGroupStatsWithChart.vue').default
);
Vue.component('mega-menu-info', require('./components/users/MegaMenuInfo.vue').default);
Vue.component('imitate-user', require('./components/users/ImitateUser.vue').default);
Vue.component('proofs-top-nav', require('./components/proofs/ProofsTopNav.vue').default);
Vue.component('proof-add-form', require('./components/proofs/ProofAddForm.vue').default);
Vue.component('proof-user-add-form', require('./components/proofs/ProofUserAddForm.vue').default);
Vue.component('proof-filters', require('./components/proofs/ProofFilters.vue').default);
Vue.component('proof-preview', require('./components/proofs/ProofPreview.vue').default);
Vue.component('proof-folder-preview', require('./components/proofs/ProofFolderPreview.vue').default);
Vue.component('proof-team', require('./components/proofs/ProofTeam.vue').default);
Vue.component('proof-comment', require('./components/proofs/ProofComment.vue').default);
Vue.component('proof-return', require('./components/proofs/ProofReturn.vue').default);
Vue.component('proof-team-edit', require('./components/proofs/ProofTeamEdit.vue').default);
Vue.component('proof-history', require('./components/proofs/ProofHistory.vue').default);
Vue.component('proof-change-folder', require('./components/proofs/ProofChangeFolder.vue').default);
Vue.component('proof-change-leader-alerts', require('./components/proofs/ProofChangeLeaderAlerts.vue').default);
Vue.component('proof-change-review-alerts', require('./components/proofs/ProofChangeReviewAlerts.vue').default);
Vue.component('proof-change-target-dates', require('./components/proofs/ProofChangeTargetDates.vue').default);
Vue.component('proof-next-version', require('./components/proofs/ProofNextVersion.vue').default);
Vue.component('proof-share', require('./components/proofs/ProofShare.vue').default);
Vue.component('proof-email-team', require('./components/proofs/ProofEmailTeam.vue').default);
Vue.component('proof-leader-actions', require('./components/proofs/ProofLeaderActions.vue').default);
Vue.component('shared-proof-preview', require('./components/proofs/SharedProofPreview.vue').default);
Vue.component('proof-skip-review', require('./components/proofs/ProofSkipReview.vue').default);
Vue.component('proof-linked-file-upload', require('./components/proofs/ProofLinkedFileUpload.vue').default);
Vue.component('artwork-preview-zoom-tooltip', require('./components/templates/ArtworkPreviewZoomTooltip.vue').default);
Vue.component('asset-download-history', require('./components/assets/AssetDownloadHistory.vue').default);
Vue.component('reverse-image-search', require('./components/assets/ReverseImageSearch.vue').default);
Vue.component('related-assets', require('./components/assets/RelatedAssets.vue').default);
Vue.component('related-asset-preview', require('./components/assets/RelatedAssetPreview.vue').default);
Vue.component(
	'non-shareable-asset-image-preview',
	require('./components/collections/NonShareableAssetImagePreview.vue').default
);

Vue.component('password-strength', {
	template: `
		<div
			style="margin-top: 5px;
			position: relative;
			height: 13px;
			border: 1px solid #ced4da;
			background-color: #f8f9fa;
			border-radius: 0.25rem"
		>
			<div :style="indicatorStyle()"></div>
			<div
				style="position: absolute;
				top: 1px;
				right: 5px;
				font-size: 0.7em;
				font-weight: bold;
				color: #000;
				line-height: 100%"
			>{{ text }}</div>
		</div>`,
	props: ['password', 'label_not_strong_enough', 'label_ok'],
	data () {
		return {
			score: 0,
			text: ''
		}
	},
	mounted () {
		this.checked = this.value;
	},
	watch: {
		password: function (val) {
			this.$emit('input', this.getScore(val));
		}
	},
	methods: {
		getScore: function (password) {
			this.score = this.lengthScore(password);
			const upper = this.charCount(password, 'upper');
			const lower = this.charCount(password, 'lower');
			const number = this.charCount(password, 'number');
			if (upper > 0) {
				this.score += (upper > 1) ? 2 : 1;
			}
			if (lower > 0) {
				this.score += (lower > 1) ? 2 : 1;
			}
			if (number > 0) {
				this.score += (number > 1) ? 2 : 1;
			}
			if (password.length > (upper + lower + number)) {
				this.score++;
			}
			if (password.length > (upper + lower + number + 1)) {
				this.score++;
			}

			this.text = '';
			if (password) {
				this.text = this.label_not_strong_enough || 'Not strong enough';
				if (this.score >= 7) {
					this.text = this.label_ok || 'OK';
				}
			}

			return this.score;
		},
		lengthScore: function (password) {
			if (password.length > 11) {
				return 2;
			}
			if (password.length > 5) {
				return 1;
			}

			return 0;
		},
		charCount: function (password, type) {
			const uppers = 'QWERTYUIOPASDFGHJKLZXCVBNM';
			const lowers = 'qwertyuiopasdfghjklzxcvbnm';
			const numbers = '1234567890';
			var count = 0;
			if (password.length > 0) {
				for (var n = 0; n < password.length; n++) {
					if ((type == 'upper') && (uppers.indexOf(password.substr(n, 1)) > -1)) {
						count++;
					}
					if ((type == 'lower') && (lowers.indexOf(password.substr(n, 1)) > -1)) {
						count++;
					}
					if ((type == 'number') && (numbers.indexOf(password.substr(n, 1)) > -1)) {
						count++;
					}
				}
			}

			return count;
		},
		indicatorStyle: function() {
			var style = 'height: 11px; border-radius: 0.25rem; ';
			if (this.password && (this.score == 0)) {
				style += 'width: 5%; ';
			} else {
				style += 'width: ' + (this.score * 10) + '%; ';
			}
			if (this.score < 3) {
				style += 'background-color: #aa0000';
			}
			if ((this.score >= 3) && (this.score < 7)) {
				style += 'background-color: #ff8000';
			}
			if (this.score >= 7) {
				style += 'background-color: #00aa00';
			}

			return style;
		}
	}
});

Vue.component('tickbox', {
	template: `
		<div>
			<span v-if="!checked">
				<span @click="checked = true">
					<i class="far fa-square tickbox"></i>
				</span>
			</span>
			<span v-else>
				<strong @click="checked = false">
					<i class="far fa-check-square tickbox"></i>
				</strong>
			</span>
			<input
				type="checkbox"
				:id="field"
				:checked="checked"
				@change="checked = !checked"
				style="display: none"
			>
			<label :for="field">{{ label }}</label>
		</div>`,
	props: ['field', 'value', 'label'],
	data () {
		return {
			checked: false
		}
	},
	mounted () {
		this.checked = this.value;
	},
	watch: {
		checked: function (val) {
			this.$emit('input', val);
		}
	}
});

Vue.component('colorpicker', {
	components: {
		'chrome-picker': Chrome
	},
	template: `
		<div class="input-group color-picker" ref="colorpicker">
			<input type="text" class="form-control" v-model="colorValue" @focus="showPicker()" @input="updateFromInput" />
			<div class="input-group-append color-picker-container">
				<span class="input-group-text">
					<span class="current-color" :style="'background-color: ' + colorValue" @click="togglePicker()"></span>
				</span>
				<chrome-picker :value="colors" @input="updateFromPicker" v-if="displayPicker" />
			</div>
		</div>`,
	props: ['color'],
	data () {
		return {
			colors: {
				hex: '#000000',
			},
			colorValue: '',
			displayPicker: false,
		}
	},
	mounted () {
		this.setColor(this.color || '#000000');
	},
	methods: {
		setColor(color) {
			this.updateColors(color);
			this.colorValue = color;
		},
		updateColors(color) {
			if(color.slice(0, 1) == '#') {
				this.colors = {
					hex: color
				};
			}
			else if(color.slice(0, 4) == 'rgba') {
				var rgba = color.replace(/^rgba?\(|\s+|\)$/g,'').split(','),
					hex = '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1);
				this.colors = {
					hex: hex,
					a: rgba[3],
				}
			}
		},
		showPicker() {
			document.addEventListener('click', this.documentClick);
			this.displayPicker = true;
		},
		hidePicker() {
			document.removeEventListener('click', this.documentClick);
			this.displayPicker = false;
		},
		togglePicker() {
			this.displayPicker ? this.hidePicker() : this.showPicker();
		},
		updateFromInput() {
			this.updateColors(this.colorValue);
		},
		updateFromPicker(color) {
			this.colors = color;
			if(color.rgba.a == 1) {
				this.colorValue = color.hex;
			}
			else {
				this.colorValue = 'rgba(' + color.rgba.r + ', ' + color.rgba.g + ', ' + color.rgba.b + ', ' + color.rgba.a + ')';
			}
		},
		documentClick(e) {
			var el = this.$refs.colorpicker,
				target = e.target;
			if(el !== target && !el.contains(target)) {
				this.hidePicker()
			}
		}
	},
	watch: {
		colorValue(val) {
			if(val) {
				this.updateColors(val);
				this.$emit('input', val);
			}
		}
	}
});
