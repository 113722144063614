<template>
    <div>
        <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

        <div class="row">
            <div class="col-xl-2 col-lg-3 col-sm-4 mb-3">
                <div class="bg-light text-center" style="padding: 1px">
                    <img class="img-fluid" :src="image_src" :alt="details ? details.asset.title : 'preview'">
                </div>
            </div>
            <div class="col">
                <h5>
                    <span class="mr-3">{{ $root.ucfirst($root.contents.asset_title) }} No. {{ data.asset_id }}</span>
                    <span v-if="details">{{ details.asset.title }}</span>
                </h5>
                <hr class="mt-0">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="mb-0">
                                {{ $root.text(
                                    'Who will see this {resource}?',
                                    { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                                    true
                                ) }}
                            </label>
                            <div>{{ data.audience_types }}</div>
                        </div>
                        <div v-if="data.usage_notes" class="form-group">
                            <label class="mb-0">{{ $root.text('Brief details', null, true) }}</label>
                            <div>{{ data.usage_notes }}</div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="mb-0">{{ $root.text('How will it be used?', null, true) }}</label>
                            <div>{{ data.usage_specify }}</div>
                        </div>
                        <div class="form-group">
                            <label class="mb-0">{{ $root.text('Usage date', null, true) }}</label>
                            <div>{{ usage_start_date_formatted ? usage_start_date_formatted : '...' }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="details" class="form-group">{{ details.approval_info }}</div>
            </div>
        </div>
        <span v-if="(!data.approved && !data.declined)">
            <span v-if="!updating">
                <button
                    :key="'approve-button'"
                    type="button"
                    class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                    @click.prevent="approveAsset()"
                >
                    <i class="far fa-check mr-1"></i> {{ $root.text('Approve', null, true) }}
                </button>
            </span>
            <span v-else>
                <button
                    :key="'approve-button-spinning'"
                    type="button"
                    class="btn btn-primary mr-sm-2 mb-2 mb-sm-0"
                >
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Approve', null, true) }}</span>
                </button>
            </span>
        </span>

        <button
            v-if="!data.declined"
            :key="'decline-button'"
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            :disabled="updating"
            @click.prevent="declineAsset()"
        >
            <i class="far fa-ban mr-1"></i> {{ $root.text('Decline', null, true) }}
        </button>
        <button
            v-if="(!data.approved && !data.declined)"
            :key="'request-info-button'"
            type="button"
            class="btn btn-secondary mr-sm-2 mb-2 mb-sm-0"
            :disabled="(updating || !details)"
            @click.prevent="requestInfo()"
        >
            <i class="far fa-bullhorn mr-1"></i> {{ $root.text('Request info', null, true) }}
        </button>
        
        <button
            v-if="$root.hasPermission('assets_view')"
            :key="'view-asset-button'"
            type="button"
            class="btn btn-secondary"
            :disabled="(!asset_details || updating)"
            @click.prevent="viewAsset()"
        >
            <i class="far fa-eye mr-1"></i>
            {{ $root.text(
                'view {resource}',
                { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                true
            ) }}
        </button>
    </div>
</template>

<script>
    export default {
        props: ['data', 'details'],
        data () {
            return {
                errors: false,
                updating: false,
                usage_start_date_formatted: '',
                get_date_time_formatted_response: null,
                image_src: '/images/file-icon.png',
                asset_details: null
            }
        },
        mounted () {
            this.$root.getDateTimeFormatted(this, 'usage_start_date', this.data.usage_start_date, 'j M Y');
            if (this.details) {
                this.getPreviewImage();
            }
            if (this.$root.hasPermission('assets_view')) {
                this.getAssetDetails();
            }
        },
        watch: {
            get_date_time_formatted_response: function (val) {
                if (val) {
                    if (val.status == 200) {
                        if (val.id == 'usage_start_date') {
                            this.usage_start_date_formatted = val.date_time_formatted;
                        }
                    }
                }
            },
            details: function (val) {
                if (val) {
                    this.getPreviewImage();
                }
            }
        },
        methods: {
            getPreviewImage: function () {
                /**
                 * Send request to API
                 */
                const size = (this.details.asset.file_extension == 'svg') ? 'svg' : 'lowres';
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource/' + this.data.asset_id + '/preview/' + size;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.image_src) {
                        vm.image_src = response.data.image_src;
                    }
                })
                .catch(function (error) {
                    // Show any errors?
                });
            },
            requestInfo: function () {
                window.location.href='mailto:' + this.details.email + '?Subject=Approval%20information%20request';
            },
            viewAsset: function () {
                if (!this.asset_details) {
                    return null;
                }
                this.$parent.view_asset = this.asset_details;
            },
            approveAsset: function () {
                this.updating = true;
                /**
                 * Send approve asset request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-access-request/' + this.data.id + '/approve';
                const data = {
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                }
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: vm.$root.text(
                            '1 {resource} approved',
                            { resource: vm.$root.text(vm.$root.contents.asset_title, null, false, 'asset_title') }
                        ),
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');
                    vm.$parent.removeApproval(vm.data.id);

                    // close after 4 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 4000);

                    // Clear notification.
                    vm.$root.clearNotification('admin', 0, 'resource_access_request', vm.data.id);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = error.response.data.error;
                    } else {
                        vm.errors = 'Something went wrong, please try again';
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            declineAsset: function () {
                const component = this.$root.findComponent(this.$parent, 'decline-asset-approval-request');
                if (component) {
                    const data = {
                        asset_approval_id: this.data.id,
                        name: this.details.name,
                        asset_title: this.details.asset.title
                    }
                    component.resetForm(data);
                    setTimeout(function() {
                        $('#decline-asset-approval-request-modal').modal('show');
                    }, 200);
                }
            },
            getAssetDetails: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.data.asset_id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_details = response.data.resource;
                })
                .catch(function (error) {
                    //
                });
            }
        }
    }
</script>
<style scoped>
    img {
        max-height: 200px;
    }

    @media (max-width: 575px) {
        .btn {
            display: block;
            width: 100%;
        }
    }
</style>
