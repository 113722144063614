<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="artwork-save-modal-label">{{ $root.text('Save', null, true) }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <button
                    class="btn btn-primary btn-block"
                    type="button"
                    data-dismiss="modal"
                    @click="saveAsNew()"
                >
                    <i class="far fa-save mr-1"></i> {{ $root.text('Save as new version', null, true) }}
                </button>
            </div>
            <div class="form-group">
                <button
                    class="btn btn-primary btn-block"
                    type="button"
                    data-dismiss="modal"
                    @click="updateExisting()"
                >
                    <i class="far fa-save mr-1"></i> {{ $root.text('Update existing version', null, true) }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted () {
            //
        },
        methods: {
            saveAsNew: function () {
                this.$parent.artwork.id = null;
                this.$parent.processing_artwork = 'Saving';
                this.$parent.createArtwork(true);
            },
            updateExisting: function () {
                this.$parent.processing_artwork = this.$parent.artwork.finished ? 'Finish for output' : 'Saving';
                this.$parent.createArtwork();
            }
        }
    }
</script>
