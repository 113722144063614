<template>
    <div>
        <div v-if="updated" class="alert alert-success" role="alert">
            <div class="row">
                <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                <div class="col" v-html="updated"></div>
            </div>
        </div>

        <form v-else v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger form-errors" role="alert"></div>

            <div class="form-group">
                <label for="asset-title" id="asset-title-label" class="mandatory-label">
                    {{ $root.text('Title', null, true) }} *
                </label>
                <input
                    v-model="item.title"
                    type="text"
                    id="asset-title"
                    class="form-control mandatory-field"
                    :readonly="updating"
                    :placeholder="placeholder('asset-title')"
                    v-on:focus="placeholder('asset-title', true)"
                    v-on:blur="placeholder('asset-title', true)"
                    v-on:keyup="onFieldChange('asset-title')"
                    v-on:change="onFieldChange('asset-title')"
                >
            </div>

            <div v-if="$root.client.show_asset_description" class="form-group">
                <label for="asset-description" id="asset-description-label" class="mandatory-label">
                    {{ $root.text($root.contents.description_title, null, true, 'description_title') }}
                    <span v-if="$root.client.asset_description_required">*</span>
                </label>
                <input
                    v-model="item.description"
                    type="text"
                    id="asset-description"
                    class="form-control mandatory-field"
                    :readonly="updating"
                    :placeholder="placeholder('asset-description')"
                    v-on:focus="placeholder('asset-description', true)"
                    v-on:blur="placeholder('asset-description', true)"
                    v-on:keyup="onFieldChange('asset-description')"
                    v-on:change="onFieldChange('asset-description')"
                >
            </div>

            <div class="form-group">
                <span
                    class="float-right contact-links font-size-18"
                    id="asset-keywords-tooltip"
                    style="position: relative; top: -3px"
                >
                    <i class="fal fa-question-circle"></i>
                </span>
                <b-tooltip
                    target="asset-keywords-tooltip"
                    triggers="hover focus"
                >
                    {{ $root.text('Please note that words you enter into the title and other fields will '
                        + 'already be counted as keywords.', null, true) }}
                    <br><br>
                    {{ $root.text('Please add additional keywords here to help more accurate '
                        + 'searching for users.', null, true) }}
                </b-tooltip>
                <label for="asset-keywords" id="asset-keywords-label" class="mandatory-label">
                    {{ $root.text('Keywords', null, true) }} *
                </label>
                <keywords-field-with-assistant
                    v-model="item.all_keywords"
                    :value="item.all_keywords"
                    field_id="asset-keywords"
                    field_class="form-control mandatory-field"
                    :readonly="updating"
                    :placeholder="$root.text('No keywords added', null, true)"
                    :keyword_placeholder="$root.text('Add keywords manually - separate with commas', null, true)"
                    :add_current_keyword="updating"
                    :keyword_categories="keyword_categories"
                    v-on:change="onFieldChange('asset-keywords')"
                    :no_header="true"
                ></keywords-field-with-assistant>
            </div>

            <div class="form-group">
                <label for="asset-asset_type_id" id="asset-asset_type_id-label" class="mandatory-label">
                    {{ $root.text(
                        '{resource} type',
                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                        true
                    ) }} *
                </label>
                <searchable-option-list
                    v-model="item.asset_type_id"
                    :value="item.asset_type_id"
                    :options="getAssetTypeOptions()"
                    field_id="asset-asset_type_id"
                    field_class="mandatory-field"
                    :readonly="updating"
                    v-on:change="onFieldChange('asset-asset_type_id')"
                ></searchable-option-list>
            </div>

            <div v-if="!item.template" class="form-group">
                <label>
                    {{ $root.text(
                        '{resource} requires approval before download',
                        { resource: $root.text($root.contents.asset_title, null, false, 'asset_title') },
                        true
                    ) }} 
                </label>
                <div class="form-control" style="height: auto">
                    <div class="mr-4" style="display: inline-block">
                        <div class="custom-control custom-radio">
                            <input
                                v-model="item.restricted"
                                type="radio"
                                id="asset-restricted-0"
                                class="custom-control-input"
                                :value="0"
                                :disabled="updating"
                                @click="onFieldChange('asset-restricted')"
                            >
                            <label class="custom-control-label" for="asset-restricted-0">
                                {{ $root.text('No', null, true) }}
                            </label>
                        </div>
                    </div>
                    <div style="display: inline-block">
                        <div class="custom-control custom-radio">
                            <input
                                v-model="item.restricted"
                                type="radio"
                                id="asset-restricted-1"
                                class="custom-control-input"
                                :value="1"
                                :disabled="updating"
                                @click="onFieldChange('asset-restricted')"
                            >
                            <label class="custom-control-label" for="asset-restricted-1">
                                {{ $root.text('Yes', null, true) }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="asset-folder_id" id="asset-folder_id-label" class="mandatory-label">
                    {{ $root.text($root.contents.folder_title, null, true, 'folder_title') }} *
                </label>
                <searchable-option-list
                    v-model="item.folder_id"
                    :value="item.folder_id"
                    :options="getFolderOptions()"
                    field_id="asset-folder_id"
                    field_class="mandatory-field"
                    :readonly="updating"
                    v-on:change="onFieldChange('asset-folder_id')"
                ></searchable-option-list>
            </div>

            <div v-if="hasSubfolders(item.folder_id)" class="form-group">
                <label for="asset-subfolders" id="asset-subfolders-label">
                    {{ $root.text($root.contents.subfolders_title, null, true, 'subfolders_title') }}
                </label>
                <searchable-option-list
                    v-model="item.subfolders"
                    :value="item.subfolders"
                    :multiple="true"
                    :options="getSubfolderOptions(item.folder_id)"
                    field_id="asset-subfolders"
                    field_class="form-control pb-2"
                    :readonly="updating"
                    :placeholder="$root.text('You can choose more than one', null, true)"
                    v-on:change="onFieldChange('asset-subfolders')"
                ></searchable-option-list>
            </div>

            <div v-for="cf in custom_fields" :key="cf.id">
                <div v-if="hasCustomField(item.folder_id, cf)" class="form-group">
                    <label :for="'asset-cf' + cf.id" :id="'asset-cf' + cf.id + '-label'" class="mandatory-label">
                        {{ cf.name }} <span v-if="cf.required">*</span>
                    </label>
                    <input
                        v-if="(cf.type == 'text')"
                        v-model="item.custom_fields[cf.id]"
                        type="text"
                        class="form-control mandatory-field"
                        :id="'asset-cf' + cf.id"
                        :readonly="updating"
                        v-on:keyup="onFieldChange('asset-cf' + cf.id)"
                        v-on:change="onFieldChange('asset-cf' + cf.id)"
                    >
                    <textarea
                        v-if="(cf.type == 'textarea')"
                        v-model="item.custom_fields[cf.id]"
                        class="form-control mandatory-field"
                        :id="'asset-cf' + cf.id"
                        rows="4"
                        :readonly="updating"
                        v-on:keyup="onFieldChange('asset-cf' + cf.id)"
                        v-on:change="onFieldChange('asset-cf' + cf.id)"
                    ></textarea>
                    <searchable-option-list
                        v-if="(cf.type == 'multiselect')"
                        v-model="item.custom_fields[cf.id]"
                        :value="(item.custom_fields[cf.id] || [])"
                        :multiple="true"
                        :options="getCustomFieldOptions(cf)"
                        :field_id="'asset-cf' + cf.id"
                        field_class="form-control mandatory-field pb-2"
                        :readonly="updating"
                        v-on:change="onFieldChange('asset-cf' + cf.id)"
                    ></searchable-option-list>
                    <searchable-option-list
                        v-if="(cf.type == 'singleselect')"
                        v-model="item.custom_fields[cf.id]"
                        :value="item.custom_fields[cf.id]"
                        :options="getCustomFieldOptions(cf)"
                        :field_id="'asset-cf' + cf.id"
                        field_class="mandatory-field"
                        :readonly="updating"
                        v-on:change="onFieldChange('asset-cf' + cf.id)"
                    ></searchable-option-list>
                </div>
            </div>

            <div v-if="(user_groups.length > 0)" class="form-group">
                <label for="asset-user_groups" id="asset-user_groups-label" class="mandatory-label">
                    {{ $root.text('User group(s)', null, true) }}
                    <span v-if="isUserGroupRequired()">*</span>
                </label>
                <searchable-option-list
                    :key="'user_groups_' + ($root.current_language || 0)"
                    v-model="item.user_groups"
                    :value="item.user_groups"
                    :multiple="true"
                    :options="getUserGroupOptions()"
                    field_id="asset-user_groups"
                    field_class="form-control mandatory-field pb-2"
                    :readonly="updating"
                    :placeholder="$root.text('You can choose more than one', null, true)"
                    v-on:change="onFieldChange('asset-user_groups')"
                ></searchable-option-list>
            </div>

            <div v-if="item.template" class="form-group">
                <label for="template-specification">{{ $root.text('Specification', null, true) }}</label>
                <textarea
                    v-model="item.template.specification"
                    id="template-specification"
                    class="form-control"
                    rows="4"
                    :readonly="updating"
                    v-on:keyup="onFieldChange('template-specification')"
                    v-on:change="onFieldChange('template-specification')"
                ></textarea>
            </div>

            <div class="form-group">
                <label for="asset-notes">{{ $root.text('Notes', null, true) }}</label>
                <textarea
                    v-model="item.notes"
                    id="asset-notes"
                    class="form-control"
                    rows="4"
                    :readonly="updating"
                    :placeholder="placeholder('asset-notes')"
                    v-on:focus="placeholder('asset-notes', true)"
                    v-on:blur="placeholder('asset-notes', true)"
                    v-on:keyup="onFieldChange('asset-notes')"
                    v-on:change="onFieldChange('asset-notes')"
                ></textarea>
            </div>

            <div class="form-group">
                <label for="asset-review_date">{{ $root.text('Review date (optional)', null, true) }}</label>
                <div class="input-group">
                    <b-form-datepicker
                        v-model="item.review_date"
                        id="asset-review_date"
                        class="form-control"
                        start-weekday="1"
                        locale="en-GB"
                        :label-no-date-selected="$root.text('No date selected', null, true)"
                        :min="today()"
                        :hideHeader="true"
                        :date-format-options="{ 'day': '2-digit', 'month': '2-digit', 'year': 'numeric' }"
                        :disabled="updating"
                        v-on:input="onReviewDateUpdated"
                    ></b-form-datepicker>
                    <div class="input-group-append">
                        <button
                            class="btn btn-light dropdown-toggle date-presets-button"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            :disabled="(updating_review_date || updating)"
                        >
                            {{ $root.text('Preset periods', null, true) }}
                            <i
                                class="fas fa-chevron-down d-none d-xl-inline-block"
                                style="font-size: 9px; position: relative; top: -1px; left: 2px"
                            ></i>
                        </button>
                        <div class="dropdown-menu">
                            <a
                                v-for="preset in getReviewDatePresets()"
                                :key="preset.month"
                                class="dropdown-item"
                                href="#"
                                @click.prevent="setDate('review_date', preset.month)"
                            >{{ preset.text }}</a>
                        </div>
                    </div>
                    <button
                        v-if="(item.review_date && !updating)"
                        type="button"
                        class="btn btn-link clear-keyword-review-date"
                        @click.prevent="clearReviewDate()"
                    >
                        <i class="far fa-times"></i>
                    </button>
                </div>
            </div>

            <div v-if="item.template" class="form-group">
                <label for="template-approval-N" id="template-approval-label" class="mandatory-label">
                    {{ $root.text('Activate artwork approval?', null, true) }} *
                </label>
                <div
                    id="template-approval"
                    class="form-control mandatory-field"
                    style="height: auto"
                >
                    <div class="mr-4" style="display: inline-block">
                        <div class="custom-control custom-radio">
                            <input
                                :key="'approvalN'"
                                v-model="item.template.approval"
                                type="radio"
                                id="template-approval-N"
                                class="custom-control-input"
                                value="N"
                                :disabled="updating"
                                @click="onFieldChange('template-approval')"
                            >
                            <label class="custom-control-label" for="template-approval-N">
                                {{ $root.text('No', null, true) }}
                            </label>
                        </div>
                    </div>
                    <div style="display: inline-block">
                        <div class="custom-control custom-radio">
                            <input
                                :key="'approvalY'"
                                v-model="item.template.approval"
                                type="radio"
                                id="template-approval-Y"
                                class="custom-control-input"
                                value="Y"
                                :disabled="updating"
                                @click="onFieldChange('template-approval')"
                            >
                            <label class="custom-control-label" for="template-approval-Y">
                                {{ $root.text('Yes', null, true) }}
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label
                    for="output_options"
                    id="output_options-label"
                    class="mandatory-label"
                >
                    {{ $root.text('Output options', null, true) }}
                    <span v-if="item.template">*</span>
                </label>
                <searchable-option-list
                    :key="'output_options'"
                    v-model="output_options"
                    :multiple="true"
                    :value="getOutputOptionValues()"
                    :options="getOutputOptions()"
                    field_id="output_options"
                    field_class="form-control pb-2 mandatory-field"
                    :placeholder="$root.text(item.template ? 'You can choose more than one' : 'None', null, true)"
                    :readonly="updating"
                    v-on:change="onOutputOptionsChange()"
                ></searchable-option-list>
            </div>

            <div v-if="showPricePanelFields()" class="form-group">
                <label
                    id="asset-price_panels-label"
                    class="mandatory-label"
                >
                    {{ $root.text('Price panel(s)', null, true) }} *
                </label>
                <div
                    id="asset-price_panels"
                    class="form-control pb-0 mandatory-field"
                    style="height: auto"
                >
                    <div v-if="item.price_panels.length > 0" class="form-group">
                        <div
                            v-for="price_panel in item.price_panels"
                            :key="price_panel.id"
                            style="padding-top: 2px; padding-bottom: 2px"
                        >
                            {{ price_panel.title }}
                            <span class="float-right ml-2">
                                <button
                                    type="button"
                                    class="btn btn-secondary btn-sm"
                                    v-b-tooltip.hover.left
                                    :title="$root.text('Remove', null, true)"
                                    @click="removePricePanel(price_panel.id)"
                                    :disabled="updating"
                                    style="padding-top: 1px; padding-bottom: 1px"
                                >
                                    <i class="far fa-trash-alt"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{ $root.text('Add price panel', null, true) }}</label>
                        <searchable-option-list
                            :key="'price_panel_type_id'"
                            v-model="item.price_panel_type_id"
                            :value="item.price_panel_type_id"
                            :options="price_panel_types"
                            field_id="asset-price_panel_type_id"
                            :search_placeholder="$root.text('Select price panel type', null, true)"
                            :readonly="updating"
                            v-on:change="onFieldChange('asset-price_panel_type_id')"
                        ></searchable-option-list>
                    </div>
                    <div v-if="item.price_panel_type_id" class="form-group">
                        <searchable-option-list
                            :key="'price_panel_id'"
                            v-model="item.price_panel_id"
                            :multiple="true"
                            :value="getPricePanelIds()"
                            :options="getPricePanelOptions(item.price_panel_type_id)"
                            field_id="asset-price_panel_id"
                            :search_placeholder="$root.text('Select price panel to add', null, true)"
                            :readonly="updating"
                            v-on:change="onFieldChange('asset-price_panel_id')"
                        ></searchable-option-list>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <button
                    v-if="!updating"
                    type="submit"
                    class="btn btn-primary mr-2"
                    :disabled="updating"
                >
                    <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                </button>
                <span v-else>
                    <button type="button" class="btn btn-primary mr-2">
                        <i class="fal fa-spinner-third fa-spin mr-1"></i>
                        <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                    </button>
                </span>
                <button type="button" class="btn btn-light" @click="onCancel()">
                    <i class="far fa-times mr-1"></i> {{ $root.text('Cancel', null, true) }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['data', 'template'],
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                item: {},
                asset_types: [],
                folders: [],
                user_groups: [],
                custom_fields: [],
                updating_review_date: false,
                keyword_categories: [],
                output_options: [],
                price_panel_types: []
            }
        },
        mounted () {
            //this.getCustomFields();
            this.updateData(this.data, this.template);
            this.getAssetTypes();
            this.getFolders();
            this.getUserGroups();
            this.getKeywordSuggestions();
        },
        watch: {
            data: function (val) {
                this.updateData(val, this.template);
            },
            template: function (val) {
                this.updateData(this.data, val);
            }
        },
        methods: {
            updateData: function (data, template) {
                if (!data) {
                    this.item = {};

                    return null;
                }
                let temp = {
                    template: null
                };
                for (const [key, value] of Object.entries(data)) {
                    if (key == 'custom_fields') {
                        let customFields = {};
                        value.forEach((customField) => {
                            customFields[customField.id] =
                                ((customField.type == 'singleselect') || (customField.type == 'multiselect'))
                                ? this.getCustomFieldOptionIndexes(customField)
                                : customField.value;
                        });
                        temp[key] = customFields;
                    } else {
                        temp[key] = value;
                    }
                }

                let allKeywords = [];
                let keywords = [];
                if (data.keywords) {
                    keywords = data.keywords.split(',');
                    keywords.forEach((item) => {
                        allKeywords.push(item);
                    });
                }
                if (this.$root.client.assets_use_detected_labels && data.detected_labels) {
                    keywords = data.detected_labels.split(',');
                    keywords.forEach((item) => {
                        allKeywords.push('[detected]' + item);
                    });
                }
                temp.all_keywords = allKeywords.join(',');

                if (template) {
                    temp.template = {};
                    for (const [key, value] of Object.entries(template)) {
                        temp.template[key] = value;
                    }
                }
                temp.price_panel_type_id = null;
                if (this.$root.isFeatured('printing')) {
                    this.getPricePanels();
                /*} else {
                    temp.price_panel_id = null;*/
                }
                if (temp.user_groups.length == 0) {
                    temp.user_groups = [-1];
                }
                this.item = temp;
                this.output_options = this.getOutputOptionValues();
                this.errors = false;
                this.updating = false;
                this.updated = false;
                this.getCustomFields();
            },
            onSubmit: function (delay = true, validated = false) {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                var vm = this;
                if (delay) {
                    setTimeout(function () {
                        vm.onSubmit(false);
                    }, 200);

                    return null;
                }
                /**
                 * Send request to API
                 */
                let data = {};
                const updateFields = [
                    'title',
                    'description',
                    'keywords',
                    'asset_type_id',
                    'folder_id',
                    'notes',
                    'review_date',
                    'user_groups',
                    'custom_fields',
                    'subfolders',
                    'restricted',
                    'downloadable',
                    'detected_labels'
                ];
                for (const [key, value] of Object.entries(this.item)) {
                    if (updateFields.indexOf(key) > -1) {
                        switch (key) {
                            case 'custom_fields':
                                data[key] = this.getCustomFieldsValue(value);
                                break;
                            default:
                                data[key] = value;
                        }
                    }
                }
                if (this.isImageAsset()) {
                    data.web_image = this.item.web_image;
                }
                if (this.$root.isFeatured('printing')) {
                    if (!this.item.template) {
                        data.print_order = this.item.print_order;
                        if (this.item.print_order) {
                            data.price_panels = this.getPricePanelsValue(this.item.price_panels);
                        }
                    } else if (this.item.template.output_order) {
                        data.price_panels = this.getPricePanelsValue(this.item.price_panels);
                    }
                }

                let url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resource/' + this.item.id;
                if (this.item.template && !validated) {
                    url += '/validate/1';
                }

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.message == 'Validation OK.') {
                        const templateErrors = vm.validateTemplateFormFields();
                        if (templateErrors) {
                            vm.updating = false;
                            vm.handleFormFieldErrors(null, templateErrors);

                            return null;
                        }
                        vm.$parent.details_changed = false;
                        vm.onSubmit(false, true);
                        vm.updateTemplate();

                        return null;
                    }
                    if (vm.item.template) {
                        return null;
                    }
                    vm.updating = false;
                    vm.updated = vm.$root.text(
                        '{resource} updated',
                        { resource: vm.$root.text(vm.$root.contents.asset_title, null, false, 'asset_title') },
                        true
                    );
                    vm.$root.folders_changed = true;

                    // Close after 2 seconds
                    setTimeout(function () {
                        vm.onCancel();
                        // Reload assets
                        vm.resetAsset();
                    }, 2000);
                })
                .catch(function (error) {
                    vm.updating = false;
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            vm.$root.doLogout();

                            return null;
                        }
                        if (error.response.data.error) {
                            vm.handleFormFieldErrors(error.response.data, vm.validateTemplateFormFields());
                        }
                    }
                });
            },
            validateTemplateFormFields: function () {
                if (!this.item.template) {
                    return null;
                }
                let errorFields = {};
                let hasErrors = false;
                if (this.item.template.approval === null) {
                    errorFields.approval = this.$root.text(
                        'The {fld} field is required.',
                        { fld: this.$root.text('artwork approval') },
                        true
                    );
                    hasErrors = true;
                }
                if (
                    !this.item.template.output_order
                    && !this.item.template.output_desktop
                    && !this.item.template.output_download
                    && !this.item.template.output_email
                    && !this.item.template.output_image_only
                    && !this.item.template.output_view_only
                ) {
                    errorFields.output_options = this.$root.text(
                        'The {fld} field is required.',
                        { fld: this.$root.text('output options') },
                        true
                    );
                    hasErrors = true;
                }
                if (this.item.template.output_order && (this.item.price_panels.length == 0)) {
                    errorFields.price_panels = this.$root.text(
                        'The {fld} field is required.',
                        { fld: this.$root.text('price panel') },
                        true
                    );
                    hasErrors = true;
                }

                return hasErrors ? errorFields : null;
            },
            handleFormFieldErrors: function (assetErrors, templateErrors) {
                this.errors = '';
                if (assetErrors) {
                    if (typeof assetErrors.error === 'string') {
                        this.errors = assetErrors.error;
                    } else if (typeof assetErrors.error === 'object') {
                        let errorHtml = null;
                        for (const [key, value] of Object.entries(assetErrors.error)) {
                            errorHtml = value.join('<br>') + '<br>';
                            this.errors += errorHtml.replace(/folder id/g, this.$root.contents.folder_title)
                                .replace(/asset type id/g, this.$root.contents.asset_title + ' type')
                                .replace(/description/g, this.$root.contents.description_title)
                                .replace(/price panel id/g, 'price panel');
                            if (key == 'price_panel_id') {
                                $('#asset-price_panels-label').addClass('text-danger');
                                $('#asset-price_panels').addClass('field-error');
                            } else {
                                $('#asset-' + key + '-label').addClass('text-danger');
                                $('#asset-' + key).addClass('field-error');
                            }
                        }
                    }
                }
                if (templateErrors) {
                    for (const [key, value] of Object.entries(templateErrors)) {
                        this.errors += value + '<br>';
                        if (key == 'price_panels') {
                            $('#asset-' + key + '-label').addClass('text-danger');
                            $('#asset-' + key).addClass('field-error');
                        } else {
                            $('#template-' + key + '-label').addClass('text-danger');
                            $('#template-' + key).addClass('field-error');
                        }
                    }
                }
                $('#asset-edit').animate({ scrollTop: 0 }, 700);
            },
            updateTemplate: function () {
                /**
                 * Send request to API
                 */
                const data = {
                    specification: this.item.template.specification,
                    approval: this.item.template.approval,
                    /*stock_item: this.item.template.stock_item,
                    stock_level: this.item.template.stock_level,
                    stock_code: this.item.template.stock_code,*/
                    output_order: this.item.template.output_order,
                    output_desktop: this.item.template.output_desktop,
                    output_download: this.item.template.output_download,
                    output_email: this.item.template.output_email,
                    output_image_only: this.item.template.output_image_only,
                    output_view_only: this.item.template.output_view_only
                };
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/template/'
                    + this.item.template.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: data,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.$root.ucfirst(
                        vm.item.template ? vm.$root.contents.template_title : vm.$root.contents.asset_title
                    ) + ' updated';
                    vm.$root.folders_changed = true;

                    // Close after 2 seconds
                    setTimeout(function () {
                        vm.onCancel();
                        // Reload assets
                        vm.resetAsset();
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            onCancel: function () {
                this.$parent.editing = false;
                this.$parent.details_changed = false;
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'asset-title':
                        text = this.$root.text(
                            'Give the {resource} a title',
                            { resource: this.$root.text(this.$root.contents.asset_title, null, false, 'asset_title') },
                            true
                        );
                        break;
                    case 'asset-description':
                        text = (this.$root.contents.description_title == 'description')
                            ? this.$root.text('Any other details/description?', null, true) : '';
                        break;
                    case 'asset-notes':
                        text = this.$root.text(
                            'Any notes like captions, photographer, special instructions etc?',
                            null,
                            true
                        );
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            getAssetTypes: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/resource-types/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.asset_types = response.data.resource_types;
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                });
            },
            getAssetTypeOptions: function () {
                if (this.asset_types.length == 0) {
                    return [];
                }
                let options = [];
                this.asset_types.forEach((item, index) => {
                    if ((item.id == this.item.asset_type_id) || item.active) {
                        options.push(item);
                    }
                });

                return options;
            },
            getFolders: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getFolderOptions: function () {
                if (this.folders.length == 0) {
                    return [];
                }
                let options = [];
                this.folders.forEach((item) => {
                    if ((item.id == this.item.folder_id) || this.item.archived || !item.archived) {
                        let name = item.name;
                        if (item.archived) {
                            name += ' (archived)';
                        }
                        options.push({ id: item.id, name: name });
                    }
                });

                return options;
            },
            hasSubfolders: function (folderId) {
                if ((this.folders.length == 0) || !folderId) {
                    return false;
                }
                let itemIndex = -1;
                this.folders.forEach((item, index) => {
                    if (item.id == folderId) {
                        itemIndex = index;
                    }
                });
                if (itemIndex < 0) {
                    return false;
                }
                if (!this.folders[itemIndex].subfolders) {
                    return false;
                }

                return (this.folders[itemIndex].subfolders.length > 0);
            },
            getSubfolderOptions: function (folderId) {
                if ((this.folders.length == 0) || !folderId) {
                    return null;
                }
                if (!this.item.subfolders) {
                    this.item.subfolders = [];
                }
                let options = [];
                this.folders.forEach((folderItem) => {
                    if (folderItem.id == folderId) {
                        if (folderItem.subfolders) {
                            folderItem.subfolders.forEach((item) => {
                                if ((this.item.subfolders.indexOf(item.id) > -1) || this.item.archived || !item.archived) {
                                    let name = item.name;
                                    if (item.archived) {
                                        name += ' (archived)';
                                    }
                                    options.push({ id: item.id, name: name });
                                }
                            });
                        }
                    }
                });
                if (options.length < 0) {
                    return null;
                }

                return options;
            },
            getUserGroups: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/user-groups/!private';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.user_groups = response.data.user_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getUserGroupOptions: function () {
                let options = [{
                    id: -1,
                    name: '<strong>' + this.$root.text('All users', null, true) + '</strong>'
                }];
                if (this.user_groups) {
                    this.user_groups.forEach((item) => {
                        options.push({
                            id: item.id,
                            name: item.name
                        });
                    });
                }

                return options;
            },
            isUserGroupRequired: function () {
                if (!this.$root.client.asset_user_group_required) {
                    return false;
                }

                return (this.user_groups.length > 0);
            },
            today: function () {
                return new Date();
            },
            setDate: function (dateField, monthsToAdd) {
                this.updating_review_date = true;
                const newDate = new Date();
                newDate.setMonth(newDate.getMonth() + monthsToAdd);
                const month = ('0' + (newDate.getMonth() + 1)).slice(-2);
                const day = ('0' + newDate.getDate()).slice(-2);
                this.item[dateField] = newDate.getFullYear() + '-' + month + '-' + day;
                this.updating_review_date = false;
                this.onFieldChange('asset-review_date');
            },
            getCustomFields: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/custom-fields';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    let customFields = [];
                    response.data.custom_fields.forEach((item) => {
                        if ((!this.data.template && (item.area != 'T')) || (this.data.template && (item.area != 'A'))) {
                            customFields.push(item);
                        }
                    });
                    vm.custom_fields = customFields;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            hasCustomField: function (folderId, customField) {
                if (!folderId) {
                    return false;
                }
                if (!customField.folders) {
                    return true;
                }
                if (customField.folders.length == 0) {
                    return true;
                }

                return (customField.folders.indexOf(folderId) > -1);
            },
            getCustomFieldOptions: function (customField) {
                const customFieldOptions = (customField.options || []);
                let options = [];
                customFieldOptions.forEach((option, index) => {
                    options.push({
                        id: index,
                        name: option.text,
                        value: option.value
                    });
                });

                return options;
            },
            getCustomFieldOptionIndexes: function (customField) {
                if (!customField.value && (customField.value != 0)) {
                    return (customField.type == 'multiselect') ? [] : null;
                }
                const options = this.getCustomFieldOptions(customField);
                if (customField.type == 'multiselect') {
                    const valueTrimmed = customField.value.substr(1, (customField.value.length - 2));
                    const values = valueTrimmed.split('][');
                    let indexes = [];
                    values.forEach((value) => {
                        options.forEach((option, index) => {
                            if (value == option.value) {
                                indexes.push(index);
                            }
                        });
                    });

                    return indexes;
                }
                let optionIndex = null;
                options.forEach((option, index) => {
                    if (customField.value == option.value) {
                        optionIndex = index;
                    }
                });

                return optionIndex;
            },
            getCustomFieldsValue: function (customFields) {
                if (!customFields) {
                    return null;
                }
                let customFieldsValue = {};
                let customFieldValue = null;
                let customFieldOptions = [];
                for (const [key, value] of Object.entries(customFields)) {
                    this.custom_fields.forEach((item, index) => {
                        if (key == item.id) {
                            customFieldValue = value;
                            if (item.type == 'multiselect') {
                                customFieldValue = [];
                                if (value.length > 0) {
                                    customFieldOptions = (item.options || []);
                                    customFieldOptions.forEach((option, optionIndex) => {
                                        if (value.indexOf(optionIndex) > -1) {
                                            customFieldValue.push(option.value);
                                        }
                                    });
                                }
                            }
                            if (item.type == 'singleselect') {
                                customFieldOptions = (item.options || []);
                                customFieldOptions.forEach((option, optionIndex) => {
                                    if (value == optionIndex) {
                                        customFieldValue = option.value;
                                    }
                                });
                            }
                            customFieldsValue[key] = customFieldValue;
                        }
                    });
                }

                return customFieldsValue;
            },
            resetAsset: function () {
                if (this.$root.selected_assets.indexOf(this.item.id) > -1) {
                    const component = this.$root.findComponent(this.$parent.$parent, 'asset-selection');
                    if (component) {
                        component.reloadAssets();
                    }
                }
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/resources/in::'
                    + this.item.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    if (response.data.resources.data[0]) {
                        vm.$parent.setAsset(response.data.resources.data[0]);
                        vm.$parent.reload_assets_on_close = true;
                    }
                })
                .catch(function (error) {
                    vm.$parent.reload_assets_on_close = true;
                    vm.$parent.close();
                });
            },
            clearReviewDate: function () {
                this.updating_review_date = true;
                this.item.review_date = null;
                this.onFieldChange('asset-review_date');
                var vm = this;
                setTimeout(function () {
                    vm.updating_review_date = false;
                }, 200);
            },
            getKeywordSuggestions: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/keyword-suggestions';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.keyword_categories = [];
                    response.data.keyword_categories.forEach((item, index) => {
                        if (item.keyword_suggestions) {
                            if (item.keyword_suggestions.length > 0) {
                                vm.keyword_categories.push(item);
                            }
                        }
                    });
                })
                .catch(function (error) {
                    //
                });
            },
            onOutputOptionsChange: function () {
                this.onFieldChange('output_options');
                if (!this.item.template) {
                    if (this.output_options.indexOf(2) > -1) {
                        this.item.downloadable = false;
                        this.item.print_order = false;
                        this.item.web_image = true;
                        this.output_options = [2];
                    } else {
                        this.item.downloadable = (this.output_options.indexOf(0) > -1);
                        this.item.print_order = (this.output_options.indexOf(1) > -1);
                        this.item.web_image = false;
                    }
                    if (!this.item.print_order) {
                        this.item.price_panel_type_id = null;
                        this.item.price_panel_id = null;
                    }

                    return null;
                }
                if (this.output_options.indexOf(4) > -1) {
                    this.item.template.output_order = false;
                    this.item.template.output_desktop = false;
                    this.item.template.output_download = false;
                    this.item.template.output_email = false;
                    this.item.template.output_image_only = true;
                    this.item.template.output_view_only = false;
                    this.output_options = [4];
                } else if (this.output_options.indexOf(5) > -1) {
                    this.item.template.output_order = false;
                    this.item.template.output_desktop = false;
                    this.item.template.output_download = false;
                    this.item.template.output_email = false;
                    this.item.template.output_image_only = false;
                    this.item.template.output_view_only = true;
                    this.output_options = [5];
                } else {
                    this.item.template.output_order = (this.output_options.indexOf(0) > -1);
                    this.item.template.output_desktop = (this.output_options.indexOf(1) > -1);
                    this.item.template.output_download = (this.output_options.indexOf(2) > -1);
                    this.item.template.output_email = (this.output_options.indexOf(3) > -1);
                    this.item.template.output_image_only = false;
                    this.item.template.output_view_only = false;
                }
                if (!this.item.template.output_order) {
                    this.item.price_panel_type_id = null;
                    this.item.price_panel_id = null;
                }
            },
            getOutputOptions: function () {
                if (!this.item.template) {
                    return [
                        {
                            id: 0,
                            name: this.$root.text('Download', null, true),
                            disabled: (this.output_options.indexOf(2) > -1)
                        },
                        {
                            id: 1,
                            name: this.$root.text('Order item', null, true),
                            disabled: (
                                !this.$root.isFeatured('printing')
                                || (this.output_options.indexOf(2) > -1)
                            )
                        },
                        {
                            id: 2,
                            name: this.$root.text('Web image', null, true),
                            disabled: !this.isImageAsset()
                        }
                    ];
                }
                return [
                    {
                        id: 0,
                        name: this.$root.text('Order item', null, true),
                        disabled: (
                            !this.$root.isFeatured('printing')
                            || (this.output_options.indexOf(4) > -1)
                            || (this.output_options.indexOf(5) > -1)
                        )
                    },
                    {
                        id: 1,
                        name: this.$root.text('Desktop print', null, true),
                        disabled: ((this.output_options.indexOf(4) > -1) || (this.output_options.indexOf(5) > -1))
                    },
                    {
                        id: 2,
                        name: this.$root.text('Download', null, true),
                        disabled: ((this.output_options.indexOf(4) > -1) || (this.output_options.indexOf(5) > -1))
                    },
                    {
                        id: 3,
                        name: this.$root.text('Email', null, true),
                        disabled: ((this.output_options.indexOf(4) > -1) || (this.output_options.indexOf(5) > -1))
                    },
                    {
                        id: 4,
                        name: this.$root.text('Web image', null, true),
                        disabled: (this.output_options.indexOf(5) > -1)
                    },
                    {
                        id: 5,
                        name: this.$root.text('View only', null, true),
                        disabled: (this.output_options.indexOf(4) > -1)
                    }
                ];
            },
            getOutputOptionValues: function () {
                if (!this.item.template) {
                    if (this.item.web_image) {
                        return [2];
                    }

                    let values = [];
                    if (this.item.downloadable) {
                        values.push(0);
                    }
                    if (this.item.print_order) {
                        values.push(1);
                    }

                    return values;
                }
                if (this.item.template.output_image_only) {
                    return [4];
                }
                if (this.item.template.output_view_only) {
                    return [5];
                }

                let values = [];
                if (this.item.template.output_order) {
                    values.push(0);
                }
                if (this.item.template.output_desktop) {
                    values.push(1);
                }
                if (this.item.template.output_download) {
                    values.push(2);
                }
                if (this.item.template.output_email) {
                    values.push(3);
                }

                return values;
            },
            onPrintOrderChange: function () {
                this.item.price_panel_type_id = null;
                this.item.price_panel_id = null;
            },
            getPricePanels: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/price-panels';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.price_panel_types = response.data.price_panel_types;
                    let pricePanels = [];
                    response.data.price_panel_types.forEach((type) => {
                        type.price_panels.forEach((item) => {
                            if (vm.item.price_panels.indexOf(item.id) > -1) {
                                pricePanels.push({ id: item.id, title: item.title });
                            }
                        });
                    });
                    vm.item.price_panels = pricePanels;
                    vm.item.price_panel_type_id = null;
                    vm.item.price_panel_id = null;
                })
                .catch(function (error) {
                    //
                });
            },
            getPricePanelOptions: function (panelTypeId) {
                let options = [];
                this.price_panel_types.forEach((type) => {
                    if (type.id == panelTypeId) {
                        type.price_panels.forEach((item) => {
                            options.push({
                                id: item.id,
                                name: item.title
                            });
                        });
                    }
                });

                return options;
            },
            showPricePanelFields: function () {
                if (!this.item.template && this.item.print_order) {
                    return true;
                }
                if (this.item.template && (this.getOutputOptionValues().indexOf(0) > -1)) {
                    return true;
                }

                return false;
            },
            isImageAsset: function () {
                if (!this.item) {
                    return false;
                }
                if (this.item.template) {
                    return false;
                }
                const imageTypes = ['image/jpeg', 'image/pjpeg', 'image/png', 'application/pdf'];

                return (imageTypes.indexOf(this.item.file_type) > -1);
            },
            onFieldChange: function (field, delay = true) {
                var vm = this;
                if (delay) {
                    var cField = field;
                    setTimeout(function () {
                        vm.onFieldChange(cField, false);
                    }, 100);

                    return null;
                }

                if (field == 'asset-folder_id') {
                    this.item.subfolders = null;
                }

                if (field == 'asset-price_panel_type_id') {
                    this.item.price_panel_id = this.getPricePanelIds();
                }

                if (field == 'asset-price_panel_id') {
                    const pricePanelIds = this.getPricePanelsValue(this.item.price_panels);
                    this.item.price_panel_id.forEach((item) => {
                        if (pricePanelIds.indexOf(item) < 0) {
                            this.item.price_panels.push({ id: item, title: this.getPricePanelTitle(item) });
                        }
                    });
                    let pricePanelsToRemove = [];
                    this.getPricePanelOptions(this.item.price_panel_type_id).forEach((item) => {
                        if ((pricePanelIds.indexOf(item.id) > -1) && (this.item.price_panel_id.indexOf(item.id) < 0)) {
                            pricePanelsToRemove.push(item.id);
                        }
                    });
                    pricePanelsToRemove.forEach((item) => {
                        this.removePricePanel(item);
                    });

                    field = 'asset-price_panels';
                }

                if (field == 'asset-keywords') {
                    let keywords = [];
                    let detectedLabels = [];
                    const allKeywords = this.item.all_keywords.split(',');
                    allKeywords.forEach((item) => {
                        if (item.indexOf('[detected]') == 0) {
                            detectedLabels.push(item.substring(10));
                        } else {
                            keywords.push(item);
                        }
                    });
                    this.item.keywords = keywords.join(',');
                    if (this.$root.client.assets_use_detected_labels) {
                        this.item.detected_labels = detectedLabels.join(',');
                    }
                }

                this.$root.unHighlightErrors(field);
                this.$parent.details_changed = true;
            },
            onReviewDateUpdated: function (date) {
                this.onFieldChange('asset-review_date');
            },
            getPricePanelsValue: function (pricePanels) {
                let pricePanelIds = [];
                pricePanels.forEach((item) => {
                    pricePanelIds.push(item.id);
                });

                return pricePanelIds;
            },
            getPricePanelTitle: function (panelId) {
                let title = '';
                this.price_panel_types.forEach((type) => {
                    type.price_panels.forEach((item) => {
                        if (item.id == panelId) {
                            title = item.title;
                        }
                    });
                });

                return title;
            },
            getPricePanelIds: function () {
                if (!this.item.price_panel_type_id) {
                    return [];
                }
                const pricePanelIds = this.getPricePanelsValue(this.item.price_panels);
                const pricePanels = this.getPricePanelOptions(this.item.price_panel_type_id);
                let ids = [];
                pricePanels.forEach((item) => {
                    if (pricePanelIds.indexOf(item.id) > -1) {
                        ids.push(item.id);
                    }
                });

                return ids;
            },
            removePricePanel: function (panelId) {
                let itemIndex = -1;
                this.item.price_panels.forEach((item, index) => {
                    if (item.id == panelId) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.item.price_panels.splice(itemIndex, 1);
                }

                let pricePanelIds = [];
                this.item.price_panel_id.forEach((item) => {
                    if (item != panelId) {
                        pricePanelIds.push(item);
                    }
                });
                this.item.price_panel_id = pricePanelIds;
            },
            getReviewDatePresets: function () {
                const presets = [
                    {
                        month: 0,
                        text: this.$root.text('Today', null, true)
                    },
                    {
                        month: 1,
                        text: this.$root.text('In a month', null, true)
                    },
                    {
                        month: 6,
                        text: this.$root.text('In 6 months', null, true)
                    },
                    {
                        month: 12,
                        text: this.$root.text('In a year', null, true)
                    },
                    {
                        month: 24,
                        text: this.$root.text('In 2 years', null, true)
                    },
                    {
                        month: 36,
                        text: this.$root.text('In 3 years', null, true)
                    },
                    {
                        month: 60,
                        text: this.$root.text('In 5 years', null, true)
                    },
                ];

                return presets;
            }
        }
    }
</script>
<style scoped>
    .clear-keyword-review-date {
        position: absolute;
        top: 2px;
        left: 120px;
    }
</style>
