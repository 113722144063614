var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"subfolder-add-form-modal-label"}},[_vm._v("\n            "+_vm._s(_vm.$root.text(
                'Add {subfolder}',
                { subfolder: _vm.$root.text(_vm.$root.contents.subfolder_title, null, false, 'subfolder_title') },
                true
            ))+"\n        ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.updated)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col",domProps:{"innerHTML":_vm._s(_vm.updated)}})])]):_c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('button',{staticClass:"d-none",attrs:{"type":"submit"}}),_vm._v(" "),(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v(_vm._s(_vm.$root.text(_vm.$root.contents.folder_title, null, true, 'folder_title')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.folder_name),expression:"folder_name"}],staticClass:"form-control",attrs:{"type":"text","readonly":true},domProps:{"value":(_vm.folder_name)},on:{"input":function($event){if($event.target.composing)return;_vm.folder_name=$event.target.value}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mandatory-label",attrs:{"for":"subfolder-name","id":"subfolder-name-label"}},[_vm._v("\n                        "+_vm._s(_vm.$root.text(
                            '{subfolder} name',
                            {
                                subfolder: _vm.$root.text(
                                    _vm.$root.contents.subfolder_title,
                                    null,
                                    false,
                                    'subfolder_title'
                                )
                            },
                            true
                        ))+" *\n                    ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subfolder.name),expression:"subfolder.name"}],staticClass:"form-control mandatory-field",attrs:{"type":"text","id":"subfolder-name","readonly":_vm.updating},domProps:{"value":(_vm.subfolder.name)},on:{"keyup":function($event){return _vm.$root.unHighlightErrors('subfolder-name')},"change":function($event){return _vm.$root.unHighlightErrors('subfolder-name')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.subfolder, "name", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subfolder.related_assets),expression:"subfolder.related_assets"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"subfolder-related_assets","disabled":_vm.updating},domProps:{"checked":Array.isArray(_vm.subfolder.related_assets)?_vm._i(_vm.subfolder.related_assets,null)>-1:(_vm.subfolder.related_assets)},on:{"change":function($event){var $$a=_vm.subfolder.related_assets,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.subfolder, "related_assets", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.subfolder, "related_assets", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.subfolder, "related_assets", $$c)}}}}),_vm._v(" "),_c('label',{staticClass:"custom-control-label",attrs:{"for":"subfolder-related_assets"}},[_vm._v("\n                            "+_vm._s(_vm.$root.text('Related {resources} field',
                                { resources: _vm.$root.text(_vm.$root.contents.asset_title + 's', null, false,
                                'assets_title') }, true))+"\n                        ")])])])])])]),_vm._v(" "),(!_vm.updated)?_c('div',{staticClass:"modal-footer"},[(!_vm.updating)?_c('span',[_c('span',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"},on:{"click":function($event){return _vm.onSubmit()}}},[_c('i',{staticClass:"far fa-check mr-1"}),_vm._v(" "+_vm._s(_vm.$root.text('Add', null, true))+"\n                ")])])]):_c('span',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fal fa-spinner-third fa-spin mr-1"}),_vm._v(" "),_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v(_vm._s(_vm.$root.text('Add', null, true)))])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1",staticStyle:{"max-width":"30px"}},[_c('i',{staticClass:"far fa-check"})])
}]

export { render, staticRenderFns }