<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-md-4 col-sm-6">
                <form v-on:submit.prevent="onSubmit()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="form-group">
                        <label
                            :for="'name' + keyword_category.id"
                            :id="'name-label' + keyword_category.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Category name', null, true) }} *
                        </label>
                        <input
                            v-model="keyword_category.name"
                            type="text"
                            :id="'name' + keyword_category.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('name', keyword_category.id)"
                            v-on:change="$root.unHighlightErrors('name', keyword_category.id)"
                        >
                    </div>

                    <span v-if="!updating">
                        <button
                            type="submit"
                            class="btn btn-primary mr-2"
                            :disabled="updating"
                        >
                            <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                        </button>
                    </span>
                    <span v-else>
                        <button type="button" class="btn btn-primary mr-2">
                            <i class="fal fa-spinner-third fa-spin mr-1"></i>
                            <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                        </button>
                    </span>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        :disabled="updating"
                        @click.prevent="deleteKeywordCategory()"
                    >
                        <i class="far fa-trash-alt mr-1"></i> {{ $root.text('Delete', null, true) }}
                    </button>
                </form>
            </div>
            <div class="col-lg-9 col-md-8 col-sm-6">
                <label>{{ $root.text('Keyword suggestions', null, true) }}</label>

                <div v-if="keywords_loading"><loading :align="'left'"></loading></div>

                <div
                    v-if="keywords_errors"
                    v-html="keywords_errors"
                    class="alert alert-danger"
                    role="alert"
                ></div>

                <div v-if="(!keywords_errors && !keywords_loading)">
                    <div
                        v-if="!hasKeywordSuggestions()"
                        class="alert alert-warning"
                        role="alert"
                    >
                        {{ $root.text('There are no keywords in this category', null, true) }}
                    </div>
                    <keyword-suggestions
                        v-if="hasKeywordSuggestions()"
                        :ks_items="keyword_category.keyword_suggestions"
                    ></keyword-suggestions>

                    <div class="text-right">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            data-toggle="modal"
                            data-target="#keyword-suggestion-add-form-modal"
                            @click.prevent="resetKeywordSuggestionAddForm()"
                        >
                            <i class="far fa-plus mr-1"></i> {{ $root.text('Add keyword', null, true) }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                errors: false,
                updating: false,
                keyword_category: {},
                keywords_loading: false,
                keywords_errors: false
            }
        },
        mounted () {
            if (this.data) {
                this.keyword_category = this.data;
            }
        },
        watch: {
            data: function (val) {
                this.keyword_category = val;
            }
        },
        methods: {
            onSubmit: function () {
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.errors = false;
                this.updating = true;
                /**
                 * Send update keyword category request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id + '/keyword-category/'
                    + this.keyword_category.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: vm.keyword_category,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    // show confirmation message
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('Update keyword category', null, true),
                        text: response.data.message
                    };
                    $('#message-modal').modal('show');

                    // close after 2 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (error.response.data.error) {
                        vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                        if (typeof error.response.data.error === 'string') {
                            vm.errors = error.response.data.error;
                        } else if (typeof error.response.data.error === 'object') {
                            vm.errors = '';
                            for (const [key, value] of Object.entries(error.response.data.error)) {
                                vm.errors += value.join('<br>') + '<br>';
                                $('#' + key + '-label' + vm.keyword_category.id).addClass('text-danger');
                                $('#' + key + vm.keyword_category.id).addClass('field-error');
                            }
                        }
                    }
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            getKeywordSuggestions: function () {
                this.keywords_errors = false;
                this.keywords_loading = true;

                /**
                 * Send get keyword suggestions request to API
                 */
                const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                    + '/keyword-suggestions/kc::' + this.keyword_category.id;
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                })
                .then(response => {
                    vm.keyword_category.keyword_suggestions = response.data.keyword_categories
                        ? response.data.keyword_categories[0].keyword_suggestions : [];
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    vm.keywords_errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response.data.error) {
                        vm.keywords_errors = error.response.data.error;
                    }
                })
                .finally(() => {
                    vm.keywords_loading = false;
                });
            },
            resetKeywordSuggestionAddForm: function () {
                const component = this.$root.findComponent(this.$parent, 'keyword-suggestion-add-form');
                if (component) {
                    component.resetForm(this.keyword_category, this);
                }
            },
            hasKeywordSuggestions: function () {
                if (!this.keyword_category.keyword_suggestions) {
                    return false;
                }

                return (this.keyword_category.keyword_suggestions.length > 0);
            },
            deleteKeywordCategory: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.loading = true;

                    /**
                     * Send delete keyword category request to API
                     */
                    const url = this.$root.$data.app.api_url + '/' + this.$root.$data.app.client_id
                        + '/keyword-category/' + this.keyword_category.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.$data.token }
                    })
                    .then(response => {
                        vm.$parent.getKeywordSuggestions();
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            vm.errors = error.response.data.error;
                        }
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: this.$root.text('Delete keyword category', null, true),
                    text: this.$root.text('Are you sure you want to delete: {name}?',
                        { name: this.keyword_category.name }, true) + '<br><br>'
                        + this.$root.text('Note - all keyword suggestions within this category will be deleted as well.',
                        null, true),
                    component: this,
                    action: 'delete-category'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteKeywordCategory(true);
            }
        }
    }
</script>
