<template>
    <div class="imitate-panel p-2">
        <div
            class="float-right ml-2"
            v-b-tooltip.hover
            :title="$root.text('Stop imitating', null, true)"
            @click="$root.stopImitating()"
            style="cursor: pointer"
        >
            <i class="far fa-times font-size-16"></i>
        </div>
        <span
            v-html="$root.text('Imitating: {name}',
                { name: '<strong>' + $root.user.first_name + ' ' + $root.user.last_name + '</strong>' }, true)"
        ></span>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        methods: {
            //
        }
    }
</script>
<style scoped>
    .imitate-panel {
        position: fixed;
        top: 0;
        left: 0;
        width: 300px;
        background-color: #212529;
        color: white;
        z-index: 9999;
        border-bottom-right-radius: 0.25rem;
        box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
        overflow-wrap: anywhere
    }
</style>
