var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"collection-add-form-modal-label"}},[_vm._v("\n            "+_vm._s(_vm.duplicate
                ? _vm.$root.text('Duplicate collection: {collection}', { collection: _vm.duplicate.name }, true)
                : _vm.$root.text('Add collection', null, true))+"\n        ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.updated)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('div',{staticClass:"row"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col",domProps:{"innerHTML":_vm._s(_vm.updated)}})])]):_c('div',[(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),((_vm.$root.hasPermission('assets_access') || _vm.$root.hasPermission('templates_view')))?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mandatory-label",attrs:{"for":"collection-name","id":"collection-name-label"}},[_vm._v("\n                        "+_vm._s(_vm.$root.text(_vm.duplicate ? 'Enter a name for the duplicate collection' : 'Name', null,
                            true))+" *\n                    ")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.collection.name),expression:"collection.name"}],staticClass:"form-control mandatory-field",attrs:{"type":"text","id":"collection-name","readonly":_vm.updating},domProps:{"value":(_vm.collection.name)},on:{"keyup":function($event){return _vm.$root.unHighlightErrors('collection-name')},"change":function($event){return _vm.$root.unHighlightErrors('collection-name')},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.collection, "name", $event.target.value)}}})])]):_vm._e()])]),_vm._v(" "),(((_vm.$root.hasPermission('assets_access') || _vm.$root.hasPermission('templates_view')) && !_vm.updated))?_c('div',{staticClass:"modal-footer"},[(!_vm.updating)?_c('span',[_c('span',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.onSubmit()}}},[(_vm.duplicate)?_c('span',[_c('i',{staticClass:"far fa-copy mr-1"}),_vm._v("\n                        "+_vm._s(_vm.$root.text('Duplicate', null, true))+"\n                    ")]):_c('span',[_vm._m(2),_vm._v("\n                        "+_vm._s(_vm.$root.text('Add', null, true))+"\n                    ")])])])]):_c('span',[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_c('i',{staticClass:"fal fa-spinner-third fa-spin mr-1"}),_vm._v(" "),_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v("\n                    "+_vm._s(_vm.$root.text(_vm.duplicate ? 'Duplicate' : 'Add', null, true))+"\n                ")])])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-1",staticStyle:{"max-width":"30px"}},[_c('i',{staticClass:"far fa-check"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('i',{staticClass:"far fa-check mr-1"})])
}]

export { render, staticRenderFns }