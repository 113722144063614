<template>
    <div class="topnav page-topnav" style="padding: 0 12px; max-height: none; overflow-y: visible">
        <div class="container-fluid">
            <nav class="navbar navbar-light navbar-expand topnav-menu">
                <div class="navbar-collapse align-items-center justify-content-between">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <h6>{{ $root.text('Notifications', null, true) }}</h6>
                        </li>
                    </ul>
                    <ul class="navbar-nav">
                        <li class="nav-item" style="height: 36.5px">
                            <div style="position: absolute; height: 36.5px; right: 0">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    @click.prevent="clearNotifications()"
                                    :disabled="(notifications.user.length == 0)"
                                >
                                    {{ $root.text('Remove all', null, true) }}
                                </button>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['notifications'],
        data () {
            return {
                //
            }
        },
        mounted () {
            //
        },
        methods: {
            clearNotifications: function () {
                this.$parent.clearNotification('user', 0);
            }
        }
    }
</script>
