<template>
    <div>
        <form v-on:submit.prevent="onSubmit()">
            <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="form-group">
                        <label
                            :for="'company' + supplier.id"
                            :id="'company-label' + supplier.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Company', null, true) }} *
                        </label>
                        <input
                            v-model="supplier.company"
                            type="text"
                            :id="'company' + supplier.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('company', supplier.id)"
                            v-on:change="$root.unHighlightErrors('company', supplier.id)"
                        >
                    </div>
                    <div class="form-group">
                        <label :for="'location' + supplier.id">
                            {{ $root.text('Location', null, true) }}
                        </label>
                        <input
                            v-model="supplier.location"
                            type="text"
                            :id="'location' + supplier.id"
                            class="form-control"
                            :readonly="updating"
                        >
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="form-group">
                        <label
                            :for="'telephone' + supplier.id"
                            :id="'telephone-label' + supplier.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Telephone', null, true) }} *
                        </label>
                        <input
                            v-model="supplier.telephone"
                            type="text"
                            :id="'telephone' + supplier.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('telephone', supplier.id)"
                            v-on:change="$root.unHighlightErrors('telephone', supplier.id)"
                        >
                    </div>
                    <div class="form-group">
                        <label :for="'email' + supplier.id" :id="'email-label' + supplier.id" class="mandatory-label">
                            {{ $root.text('Email', null, true) }} *
                        </label>
                        <input
                            v-model="supplier.email"
                            type="email"
                            :id="'email' + supplier.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('email', supplier.id)"
                            v-on:change="$root.unHighlightErrors('email', supplier.id)"
                        >
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class="form-group">
                        <label
                            :for="'first_name' + supplier.id"
                            :id="'name-label' + supplier.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Contact name', null, true) }} *
                        </label>
                        <div class="input-group">
                            <input
                                v-model="supplier.first_name"
                                type="text"
                                :id="'first_name' + supplier.id"
                                class="form-control mandatory-field"
                                :readonly="updating"
                                :placeholder="placeholder('first_name')"
                                v-on:focus="placeholder('first_name', true)"
                                v-on:blur="placeholder('first_name', true)"
                                v-on:keyup="unHighlightNameErrors()"
                                v-on:change="unHighlightNameErrors()"
                            >
                            <input
                                v-model="supplier.last_name"
                                type="text"
                                :id="'last_name' + supplier.id"
                                class="form-control mandatory-field"
                                :readonly="updating"
                                :placeholder="placeholder('last_name')"
                                v-on:focus="placeholder('last_name', true)"
                                v-on:blur="placeholder('last_name', true)"
                                v-on:keyup="unHighlightNameErrors()"
                                v-on:change="unHighlightNameErrors()"
                            >
                        </div>
                    </div>
                    <div class="form-group">
                        <label
                            :for="'can_update_status' + supplier.id + '-N'"
                            :id="'can_update_status-label' + supplier.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Allow status updates?', null, true) }} *
                        </label>
                        <div
                            :id="'can_update_status' + supplier.id"
                            class="form-control mandatory-field"
                            style="height: auto"
                        >
                            <div class="mr-4" style="display: inline-block">
                                <div class="custom-control custom-radio">
                                    <input
                                        :key="'updatesN'"
                                        v-model="supplier.can_update_status"
                                        type="radio"
                                        :id="'can_update_status' + supplier.id + '-N'"
                                        class="custom-control-input"
                                        :value="0"
                                        :disabled="updating"
                                        @click="$root.unHighlightErrors('can_update_status', supplier.id)"
                                    >
                                    <label class="custom-control-label" :for="'can_update_status' + supplier.id + '-N'">
                                        {{ $root.text('No', null, true) }}
                                    </label>
                                </div>
                            </div>
                            <div style="display: inline-block">
                                <div class="custom-control custom-radio">
                                    <input
                                        :key="'updatesY'"
                                        v-model="supplier.can_update_status"
                                        type="radio"
                                        :id="'can_update_status' + supplier.id + '-Y'"
                                        class="custom-control-input"
                                        :value="1"
                                        :disabled="updating"
                                        @click="$root.unHighlightErrors('can_update_status', supplier.id)"
                                    >
                                    <label class="custom-control-label" :for="'can_update_status' + supplier.id + '-Y'">
                                        {{ $root.text('Yes', null, true) }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span v-if="!updating">
                <button
                    type="submit"
                    class="btn btn-primary mr-2"
                    :disabled="updating"
                >
                    <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                </button>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary mr-2">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                </button>
            </span>
            <button
                type="button"
                class="btn btn-secondary"
                :disabled="updating"
                @click.prevent="deleteSupplier()"
            >
                <i class="far fa-trash-alt mr-1"></i> {{ $root.text('Delete', null, true) }}
            </button>
        </form>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                errors: false,
                updating: false,
                supplier: {},
            }
        },
        mounted () {
            if (this.data) {
                this.updateData(this.data);
            }
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            updateData: function(data) {
                let newData = {};
                for (const [key, value] of Object.entries(data)) {
                    newData[key] = value;
                }
                this.supplier = newData;
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;

                /**
                 * Send update request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-supplier/'
                    + this.supplier.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: this.supplier,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.$parent.getSuppliers();
                    // show confirmation message
                    vm.$parent.$parent.message = {
                        type: 'success',
                        title: vm.$root.text('Update supplier', null, true),
                        text: vm.$root.text('{name} updated.', { name: vm.supplier.company })
                    };
                    $('#message-modal').modal('show');

                    // close after 2 seconds automatically
                    setTimeout(function() {
                        $('#message-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    if (error.response.status == 401) {
                        // missing/lost API token - logout user
                        vm.$root.doLogout();
                    }
                    if (typeof error.response.data.error === 'object') {
                        vm.errors = '';
                        let errorHtml = null;
                        for (const [key, value] of Object.entries(error.response.data.error)) {
                            errorHtml = value.join('<br>') + '<br>';
                            vm.errors += errorHtml.replace(/currency/g, 'assigned to currency')
                                .replace(/profile id/g, 'assigned to profile');
                            if ((key == 'first_name') || (key == 'last_name')) {
                                $('#name-label' + vm.supplier.id).addClass('text-danger');
                            } else {
                                $('#' + key + '-label' + vm.supplier.id).addClass('text-danger');
                            }
                            $('#' + key + vm.supplier.id).addClass('field-error');
                        }

                        return null;
                    }
                    vm.errors = error.response.data.error
                        || vm.$root.text('Something went wrong, please try again', null, true);
                })
                .finally(() => {
                    vm.updating = false;
                });
            },
            deleteSupplier: function (confirmed = false) {
                if (confirmed) {
                    this.errors = false;
                    this.$parent.items = [];
                    this.$parent.loading = true;

                    /**
                     * Send delete request to API
                     */
                    const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/print-order-supplier/'
                        + this.supplier.id;
                    var vm = this;

                    axios({
                        method: 'delete',
                        url: url,
                        headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                    })
                    .then(response => {
                        //
                    })
                    .catch(function (error) {
                        if (error.response.status == 401) {
                            // missing/lost API token - logout user
                            vm.$root.doLogout();
                        }
                        vm.errors = error.response.data.error
                            || vm.$root.text('Something went wrong, please try again', null, true);
                    })
                    .finally(() => {
                        vm.$parent.getSuppliers();
                    });

                    return null;
                }

                if (this.updating) {
                    return null;
                }

                this.$parent.$parent.confirm_delete = {
                    title: this.$root.text('Delete supplier', null, true),
                    text: this.$root.text('Are you sure you want to delete supplier: {name}?',
                        { name: this.supplier.company }, true) + '<br><br>'
                        + this.$root.text("Please note the contact's user account will also be deleted.", null, true),
                    component: this,
                    action: 'delete-supplier'
                };
                $('#confirm-delete-modal').modal('show');
            },
            confirmDelete: function (action) {
                this.deleteSupplier(true);
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field + this.supplier.id).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field + this.supplier.id).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'first_name':
                        text = this.$root.text('First name', null, true);
                        break;
                    case 'last_name':
                        text = this.$root.text('Last name', null, true);
                        break;
                }
                if (update) {
                    $('#' + field + this.supplier.id).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            unHighlightNameErrors: function () {
                if (this.supplier.first_name) {
                    $('#first_name' + this.supplier.id).removeClass('field-error');
                }
                if (this.supplier.last_name) {
                    $('#last_name' + this.supplier.id).removeClass('field-error');
                }
                if (this.supplier.first_name && this.supplier.last_name) {
                    $('#name-label' + this.supplier.id).removeClass('text-danger');
                }
            }
        }
    }
</script>
