var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"asset-download-history-modal-label"}},[_vm._v("\n            "+_vm._s(_vm.$root.text('Download history', null, true))+"\n        ")]),_vm._v(" "),_vm._m(0)]),_vm._v(" "),_c('div',{staticClass:"modal-body"},[(_vm.loading)?_c('loading'):_vm._e(),_vm._v(" "),(_vm.errors)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"},domProps:{"innerHTML":_vm._s(_vm.errors)}}):_vm._e(),_vm._v(" "),(!_vm.loading && !_vm.errors)?_c('div',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-centered table-hover"},[_c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_vm._v(_vm._s(_vm.$root.text('User', null, true)))]),_vm._v(" "),(_vm.$root.isFeatured('profiles'))?_c('div',{staticClass:"col-5"},[_vm._v("\n                                        "+_vm._s(_vm.$root.text(
                                            _vm.$root.contents.profile_group_title,
                                            null,
                                            true,
                                            'profile_group_title'
                                        ))+"\n                                    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-3"},[_vm._v(_vm._s(_vm.$root.text('Downloaded on', null, true)))])])])])]),_vm._v(" "),_c('tbody',_vm._l((_vm.items),function(item){return _c('tr',{key:item.id},[_c('td',{attrs:{"scope":"row"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col",staticStyle:{"padding-top":"1px"}},[_vm._v("\n                                        "+_vm._s(item.first_name + ' ' + item.last_name)+"\n                                    ")]),_vm._v(" "),(_vm.$root.isFeatured('profiles'))?_c('div',{staticClass:"col-5"},[_vm._v("\n                                        "+_vm._s(item.profile_group_name)+"\n                                    ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-3"},[_vm._v("\n                                        "+_vm._s(item.created_at_formatted)+"\n                                    ")])])])])}),0)])])]):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
}]

export { render, staticRenderFns }