<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="email-support-modal-label">
                <span v-if="(sent && (subject == 'marketing'))">
                    {{ $root.text('Your marketing request has been sent', null, true) }}
                </span>
                <span v-else>
                    <span v-if="first_name">
                        {{ $root.text('Hello {first_name}, how can we help you?', { first_name: first_name }, true) }}
                    </span>
                    <span v-else>
                        {{ $root.text('Hello, how can we help you?', null, true) }}
                    </span>
                </span>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose()">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="sent" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="sent"></div>
                </div>
            </div>
            <div v-else>
                <form v-on:submit.prevent="reCaptcha()">
                    <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label for="subject" id="subject-label" class="mandatory-label">
                                    {{ $root.text('Area of support', null, true) }} *
                                </label>
                                <searchable-option-list
                                    :key="'area-of-support-' + ($root.current_language || 0)"
                                    v-model="subject"
                                    :value="subject"
                                    :options="getSubjectOptions()"
                                    :field_id="'subject'"
                                    field_class="mandatory-field"
                                    :readonly="sending"
                                    :placeholder="$root.text('Select area of support', null, true)"
                                    v-on:change="$root.unHighlightErrors('subject')"
                                ></searchable-option-list>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label
                                    for="support-name"
                                    id="support-name-label"
                                    class="mandatory-label"
                                >
                                    {{ $root.text('Enter your name', null, true) }} *
                                </label>
                                <input
                                    v-model="name"
                                    type="text"
                                    id="support-name"
                                    class="form-control"
                                    :readonly="((user_id > 0) || sending)"
                                    v-on:keyup="$root.unHighlightErrors('support-name')"
                                    v-on:change="$root.unHighlightErrors('support-name')"
                                >
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="form-group">
                                <label
                                    for="support-email"
                                    id="support-email-label"
                                    class="mandatory-label"
                                >
                                    {{ $root.text('Enter your email address', null, true) }} *
                                </label>
                                <input
                                    v-model="email"
                                    type="email"
                                    id="support-email"
                                    class="form-control"
                                    :readonly="((user_id > 0) || sending)"
                                    v-on:keyup="$root.unHighlightErrors('support-email')"
                                    v-on:change="$root.unHighlightErrors('support-email')"
                                >
                            </div>
                        </div>
                    </div>
                    <div id="message-field" class="form-group">
                        <textarea
                            v-model="message"
                            id="message"
                            class="form-control"
                            :readonly="sending"
                            style="height: 200px"
                            :placeholder="placeholder('message')"
                            v-on:focus="placeholder('message', true)"
                            v-on:blur="placeholder('message', true)"
                            v-on:keyup="$root.unHighlightErrors('message')"
                            v-on:change="$root.unHighlightErrors('message')"
                        ></textarea>
                    </div>
                    <div v-if="$root.app.marketing_request_form" id="marketing-request-fields">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label
                                        for="support-profile_group_id"
                                        id="support-profile_group_id-label"
                                        class="mandatory-label"
                                    >
                                        {{ $root.text(
                                            $root.contents.profile_group_title,
                                            null,
                                            true,
                                            'profile_group_title'
                                        ) }}
                                    </label>
                                    <searchable-option-list
                                        :key="'profile-group-' + ($root.current_language || 0)"
                                        v-model="profile_group_id"
                                        :value="profile_group_id"
                                        :options="profile_groups"
                                        field_id="support-profile_group_id"
                                        field_class="mandatory-field"
                                        :search_placeholder="$root.text(
                                            'Select a {profile group}',
                                            {
                                                'profile group': $root.text(
                                                    $root.contents.profile_group_title,
                                                    null,
                                                    false,
                                                    'profile_group_title'
                                                )
                                            },
                                            true
                                        )"
                                        :readonly="sending"
                                    ></searchable-option-list>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label
                                        for="support-authorised_by"
                                        id="support-authorised_by-label"
                                        class="mandatory-label"
                                    >{{ $root.text('Request authorised by', null, true) }}</label>
                                    <input
                                        v-model="authorised_by"
                                        type="text"
                                        id="support-authorised_by"
                                        class="form-control mandatory-field"
                                        :readonly="sending"
                                        :placeholder="placeholder('support-authorised_by')"
                                        v-on:focus="placeholder('support-authorised_by', true)"
                                        v-on:blur="placeholder('support-authorised_by', true)"
                                    >
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label
                                        for="support-priority"
                                        id="support-priority-label"
                                        class="mandatory-label"
                                    >{{ $root.text('Priority', null, true) }}</label>
                                    <searchable-option-list
                                        :key="'priority-' + ($root.current_language || 0)"
                                        v-model="priority"
                                        :value="priority"
                                        :options="getPriorityOptions()"
                                        field_id="support-priority"
                                        field_class="mandatory-field"
                                        :search_placeholder="$root.text('Select a priority', null, true)"
                                        :readonly="sending"
                                    ></searchable-option-list>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label
                                for="support-product"
                                id="support-product-label"
                                class="mandatory-label"
                            >{{ $root.text('Product', null, true) }}</label>
                            <textarea
                                v-model="product"
                                id="support-product"
                                class="form-control"
                                :readonly="sending"
                                rows="2"
                                :placeholder="placeholder('support-product')"
                                v-on:focus="placeholder('support-product', true)"
                                v-on:blur="placeholder('support-product', true)"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <label
                                for="support-marketing_solution"
                                id="support-marketing_solution-label"
                                class="mandatory-label"
                            >{{ $root.text('Proposed marketing solution', null, true) }}</label>
                            <textarea
                                v-model="marketing_solution"
                                id="support-marketing_solution"
                                class="form-control"
                                :readonly="sending"
                                rows="2"
                                :placeholder="placeholder('support-marketing_solution')"
                                v-on:focus="placeholder('support-marketing_solution', true)"
                                v-on:blur="placeholder('support-marketing_solution', true)"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <label
                                for="support-marketing_objective"
                                id="support-marketing_objective-label"
                                class="mandatory-label"
                            >{{ $root.text('Marketing objective', null, true) }}</label>
                            <textarea
                                v-model="marketing_objective"
                                id="support-marketing_objective"
                                class="form-control"
                                :readonly="sending"
                                rows="2"
                                :placeholder="placeholder('support-marketing_objective')"
                                v-on:focus="placeholder('support-marketing_objective', true)"
                                v-on:blur="placeholder('support-marketing_objective', true)"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <label
                                for="support-audience"
                                id="support-audience-label"
                                class="mandatory-label"
                            >{{ $root.text('Audience', null, true) }}</label>
                            <textarea
                                v-model="audience"
                                id="support-audience"
                                class="form-control"
                                :readonly="sending"
                                rows="2"
                                :placeholder="placeholder('support-audience')"
                                v-on:focus="placeholder('support-audience', true)"
                                v-on:blur="placeholder('support-audience', true)"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <label
                                for="support-proposed_headlines"
                                id="support-proposed_headlines-label"
                                class="mandatory-label"
                            >{{ $root.text('Proposed headlines, copy or messaging', null, true) }}</label>
                            <textarea
                                v-model="proposed_headlines"
                                id="support-proposed_headlines"
                                class="form-control"
                                :readonly="sending"
                                rows="2"
                                :placeholder="placeholder('support-proposed_headlines')"
                                v-on:focus="placeholder('support-proposed_headlines', true)"
                                v-on:blur="placeholder('support-proposed_headlines', true)"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <label
                                for="support-mandatories_exclusions"
                                id="support-mandatories_exclusions-label"
                                class="mandatory-label"
                            >{{ $root.text('Mandatories / exclusions', null, true) }}</label>
                            <textarea
                                v-model="mandatories_exclusions"
                                id="support-mandatories_exclusions"
                                class="form-control"
                                :readonly="sending"
                                rows="2"
                                :placeholder="placeholder('support-mandatories_exclusions')"
                                v-on:focus="placeholder('support-mandatories_exclusions', true)"
                                v-on:blur="placeholder('support-mandatories_exclusions', true)"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <label
                                for="support-timings"
                                id="support-timings-label"
                                class="mandatory-label"
                            >{{ $root.text('Timings', null, true) }}</label>
                            <textarea
                                v-model="timings"
                                id="support-timings"
                                class="form-control"
                                :readonly="sending"
                                rows="2"
                                :placeholder="placeholder('support-timings')"
                                v-on:focus="placeholder('support-timings', true)"
                                v-on:blur="placeholder('support-timings', true)"
                            ></textarea>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="!sent" class="modal-footer">
            <span v-if="!sending">
                <span>
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="reCaptcha()"
                    >
                        <i class="far fa-paper-plane mr-1"></i> {{ $root.text('Submit', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Submit', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                errors: false,
                sending: false,
                sent: false,
                subject: '',
                open_modal_on_close: null,
                user_id: null,
                first_name: null,
                name: '',
                email: '',
                message: '',
                profile_group_id: null,
                authorised_by: null,
                priority: null,
                product: null,
                marketing_solution: null,
                marketing_objective: null,
                audience: null,
                proposed_headlines: null,
                mandatories_exclusions: null,
                timings: null,
                profile_groups: [],
                show_recaptcha_badge_interval: null
            }
        },
        mounted () {
            if (this.$root.app.marketing_request_form && this.$root.isLoggedIn()) {
                this.getProfiles();
            }
        },
        watch: {
            subject: function (val) {
                if (!this.$root.app.marketing_request_form) {
                    return null;
                }
                if (val == 'marketing') {
                    $('#message-field').fadeOut(350, function () {
                        $('#marketing-request-fields').fadeIn(350);
                    });

                    return null;
                }
                $('#marketing-request-fields').fadeOut(350, function () {
                    $('#message-field').fadeIn(350);
                });
            }
        },
        methods: {
            resetForm: function (subject = '', message = '', reOpenModal = null) {
                this.errors = false;
                this.sending = false;
                this.sent = false;
                this.subject = subject;
                this.message = message;
                this.open_modal_on_close = reOpenModal;
                if (this.$root.user) {
                    this.user_id = this.$root.user.id ? this.$root.user.id : 0;
                    this.first_name = this.$root.user.first_name || null;
                    this.name = this.$root.user.first_name + ' ' + this.$root.user.last_name;
                    this.email = this.$root.user.email;
                } else {
                    this.user_id = 0;
                    this.first_name = null;
                    this.name = '';
                    this.email = '';
                }
                this.profile_group_id = null;
                this.authorised_by = null;
                this.priority = null;
                this.product = null;
                this.marketing_solution = null;
                this.marketing_objective = null;
                this.audience = null;
                this.proposed_headlines = null;
                this.mandatories_exclusions = null;
                this.timings = null;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                if (this.$root.isPublicRoute() && this.$root.hasReCaptcha()) {
                    //this.$recaptchaInstance.showBadge();
                    var vm = this;
                    this.show_recaptcha_badge_interval = setInterval(function () {
                        if (vm.$recaptchaInstance) {
                            vm.$recaptchaInstance.showBadge();
                            clearInterval(vm.show_recaptcha_badge_interval);
                        }
                    }, 500);
                }
            },
            reCaptcha: async function () {
                if (!this.$root.isPublicRoute() || !this.$root.hasReCaptcha()) {
                    this.onSubmit();

                    return null;
                }
                // (optional) Wait until recaptcha has been loaded.
                await this.$recaptchaLoaded();
                
                // Execute reCAPTCHA with action "register".
                const token = await this.$recaptcha('register');
                
                // Do stuff with the received token.
                this.onSubmit(token);
            },
            onSubmit: function (reCaptchaToken = null) {
                this.sending = true;
                this.errors = false;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                let data = {
                    subject: this.subject,
                    message: this.message,
                    user_id: this.user_id,
                    name: this.name,
                    email: this.email,
                    app_info: {
                        app_url: this.$root.app.app_url
                    }
                };
                if (this.$root.isPublicRoute() && this.$root.hasReCaptcha()) {
                    data.recaptcha_token = reCaptchaToken;
                }

                if (this.subject == 'marketing') {
                    data.profile_group_id = this.profile_group_id;
                    data.authorised_by = this.authorised_by;
                    data.priority = this.priority;
                    data.product = this.product;
                    data.marketing_solution = this.marketing_solution;
                    data.marketing_objective = this.marketing_objective;
                    data.audience = this.audience;
                    data.proposed_headlines = this.proposed_headlines;
                    data.mandatories_exclusions = this.mandatories_exclusions;
                    data.timings = this.timings;
                } else {
                    data.message = this.message;
                }

                const path = '/' + this.$root.app.client_id + '/support';
                const url = this.$root.app.api_url + path;
                var vm = this;

                axios({
                    method: 'post',
                    url: url,
                    data: data
                })
                .then(response => {
                    if (vm.subject == 'marketing') {
                        vm.sent = vm.$root.text('We will respond as soon as possible.', null, true);

                        return null;
                    }
                    vm.sent = 'Success';
                    if (response.data.ticket_id) {
                        vm.sent = vm.$root.text(
                            'Your message has been sent. Support ticket #{x} has been created for this request.',
                            { x: response.data.ticket_id },
                            true
                        );
                    }
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error;
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                for (let [key, value] of Object.entries(error.response.data.error)) {
                                    if (key == 'name') {
                                        key = 'support-name';
                                    }
                                    if (key == 'email') {
                                        key = 'support-email';
                                    }
                                    if (key == 'subject') {
                                        vm.errors += vm.$root.text('Please select an area of support', null, true)
                                            + '<br>';
                                    } else {
                                        vm.errors += value.join('<br>') + '<br>';
                                    }
                                    $('#' + key + '-label').addClass('text-danger');
                                    $('#' + key).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.sending = false
                );
            },
            onClose: function () {
                if (this.$root.isPublicRoute()) {
                    this.$root.hideReCaptchaBadge();
                }
                if (this.open_modal_on_close) {
                    $('#' + this.open_modal_on_close + '-modal').modal('show');
                }
            },
            placeholder: function (field, update = false) {
                const focused = $('#' + field).is(':focus');
                if (focused) {
                    if (update) {
                        $('#' + field).attr('placeholder', null);
                    }

                    return null;
                }
                let text = null;
                switch (field) {
                    case 'message':
                        text = this.$root.text('Please give brief details of what you need help with', null, true)
                            + ' *';
                        break;
                    case 'support-authorised_by':
                        text = this.$root.text('Who has authorised this request', null, true);
                        break;
                    case 'support-product':
                        text = this.$root.text('Please state the product you would like support with', null, true);
                        break;
                    case 'support-marketing_solution':
                        text = this.$root.text('How do you think marketing can deliver benefit. Any specific channels '
                            + '(website, social media) or assets (digital form, A4 poster) e.g. send an email '
                            + 'to existing members with partners, create a webpage sign up experience.', null, true);
                        break;
                    case 'support-marketing_objective':
                        text = this.$root.text('Role that marketing has to play. e.g. raise awareness, drive '
                            + 'engagement, increase sales.', null, true);
                        break;
                    case 'support-audience':
                        text = this.$root.text('Detail about who we need to target. e.g. what’s the demographic '
                            + 'profile, prospect or existing, where will we find them, what are their motivations '
                            + 'we can influence.', null, true);
                        break;
                    case 'support-proposed_headlines':
                        text = this.$root.text('Detail about what needs to be on the materials. e.g. is there a certain '
                            + 'message that needs to be used.', null, true);
                        break;
                    case 'support-mandatories_exclusions':
                        text = this.$root.text("Any do's or don't we must ensure. e.g. don't include Castle Royle as "
                            + 'there an no partner members.', null, true);
                        break;
                    case 'support-timings':
                        text = this.$root.text('Any specific seasonal or customer events. Dates for when marketing '
                            + 'activity needs to land.', null, true);
                        break;
                }
                if (update) {
                    $('#' + field).attr('placeholder', text);

                    return null;
                }

                return text;
            },
            getProfiles: function () {
                /**
                 * Send get profile groups request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-groups/!pagination';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.profile_groups = response.data.profile_groups;
                })
                .catch(function (error) {
                    // Do anything?
                });
            },
            getSubjectOptions: function () {
                if (this.subject) {
                    const optionValue = (this.subject == 'access')
                        ? this.$root.text('Access to system', null, true)
                        : this.$root.ucfirst(this.subject);

                    return [
                        {
                            id: this.subject,
                            name: optionValue
                        }
                    ];
                }
                if (!this.$root.isLoggedIn()) {
                    this.subject = 'access';

                    return [
                        {
                            id: 'access',
                            name: this.$root.text('Access to system', null, true)
                        }
                    ];
                }
                let options = [
                    {
                        id: 'resources',
                        name: this.$root.text(this.$root.contents.asset_title + 's', null, true, 'assets_title')
                    },
                    {
                        id: 'upload',
                        name: this.$root.text('Upload', null, true)
                    },
                    {
                        id: 'templates',
                        name: this.$root.text(this.$root.contents.template_title + 's', null, true, 'templates_title')
                    }
                ];
                if (this.$root.isFeatured('proofs')) {
                    options.push({
                        id: 'proofs',
                        name: this.$root.text('Proofs', null, true)
                    });
                }
                options.push({
                    id: 'users',
                    name: this.$root.text('Users', null, true)
                });
                if (this.$root.app.marketing_request_form) {
                    options.push({
                        id: 'marketing',
                        name: this.$root.text('Marketing request', null, true)
                    });
                }
                if (this.$root.getAvailableLanguages().length > 1) {
                    options.push({
                        id: 'translation',
                        name: this.$root.text('Translation issue', null, true)
                    });
                }
                options.push({
                    id: 'other',
                    name: this.$root.text('Other', null, true)
                });

                return options;
            },
            getPriorityOptions: function () {
                const options = [
                    {
                        id: 'Low',
                        name: this.$root.text('Low', null, true)
                    },
                    {
                        id: 'Medium',
                        name: this.$root.text('Medium', null, true)
                    },
                    {
                        id: 'High',
                        name: this.$root.text('High', null, true)
                    }
                ];

                return options;
            }
        }
    }
</script>
<style scoped>
    #marketing-request-fields {
        display: none;
    }
</style>
