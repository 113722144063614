<template>
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="profile-group-add-form-modal-label">
                {{ $root.text(
                    'Edit {profile group}',
                    {
                        'profile group': $root.text(
                            $root.contents.profile_group_title,
                            null,
                            false,
                            'profile_group_title'
                        )
                    },
                    true
                ) }}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
            <div v-if="updated" class="alert alert-success" role="alert">
                <div class="row">
                    <div class="col-1" style="max-width: 30px"><i class="far fa-check"></i></div>
                    <div class="col" v-html="updated"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="errors" v-html="errors" class="alert alert-danger" role="alert"></div>

                <form v-if="(canUpdate() && data.id)" v-on:submit.prevent="onSubmit()">
                    <div class="form-group">
                        <label
                            :for="'profile-group-name' + data.id"
                            :id="'profile-group-name-label' + data.id"
                            class="mandatory-label"
                        >
                            {{ $root.text(
                                '{profile group} name',
                                {
                                    'profile group': $root.text(
                                        $root.contents.profile_group_title,
                                        null,
                                        false,
                                        'profile_group_title'
                                    )
                                },
                                true
                            ) }} *
                        </label>
                        <input
                            v-model="profile_group.name"
                            type="text"
                            :id="'profile-group-name' + data.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('profile-group-name', data.id)"
                            v-on:change="$root.unHighlightErrors('profile-group-name', data.id)"
                        >
                    </div>
                    <div v-if="$root.client.show_profile_group_description" class="form-group">
                        <label
                            :for="'profile-group-description' + data.id"
                            :id="'profile-group-description-label' + data.id"
                            class="mandatory-label"
                        >
                            {{ $root.text(
                                $root.contents.profile_group_description_title,
                                null,
                                true,
                                'profile_group_description_title'
                            ) }}
                            <span v-if="$root.client.profile_group_description_required">*</span>
                        </label>
                        <input
                            v-model="profile_group.description"
                            type="text"
                            :id="'profile-group-description' + data.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('profile-group-description', data.id)"
                            v-on:change="$root.unHighlightErrors('profile-group-description', data.id)"
                        >
                    </div>
                    <div v-if="$root.client.show_profile_group_subfolder_id" class="form-group">
                        <label
                            :for="'profile-group-folder_id' + data.id"
                            :id="'profile-group-folder_id-label' + data.id"
                            class="mandatory-label"
                        >
                            {{ $root.text($root.contents.folder_title, null, true, 'folder_title') }}
                            <span v-if="$root.client.profile_group_subfolder_id_required">*</span>
                        </label>
                        <searchable-option-list
                            :key="'profile-group-folder-' + ($root.current_language || 0)"
                            v-model="profile_group.folder_id"
                            :value="profile_group.folder_id"
                            :options="getFolderOptions()"
                            :field_id="'profile-group-folder_id' + data.id"
                            field_class="mandatory-field"
                            :search_placeholder="$root.client.profile_group_subfolder_id_required
                                ? null : $root.text('None', null, true)"
                            :readonly="updating"
                            v-on:change="onFolderChange()"
                        ></searchable-option-list>
                    </div>
                    <div v-if="$root.client.show_profile_group_subfolder_id" class="form-group">
                        <label
                            :for="'profile-group-subfolder_id' + data.id"
                            :id="'profile-group-subfolder_id-label' + data.id"
                            class="mandatory-label"
                        >
                            {{ $root.text($root.contents.subfolder_title, null, true, 'subfolder_title') }}
                            <span v-if="$root.client.profile_group_subfolder_id_required">*</span>
                        </label>
                        <searchable-option-list
                            :key="'profile-group-subfolder-' + ($root.current_language || 0)"
                            v-model="profile_group.subfolder_id"
                            :value="profile_group.subfolder_id"
                            :options="getSubfolderOptions()"
                            :field_id="'profile-group-subfolder_id' + data.id"
                            field_class="mandatory-field"
                            :search_placeholder="$root.client.profile_group_subfolder_id_required
                                ? null : $root.text('None', null, true)"
                            :readonly="updating || !profile_group.folder_id"
                            v-on:change="$root.unHighlightErrors('profile-group-subfolder_id', data.id)"
                        ></searchable-option-list>
                    </div>
                    <div v-if="$root.client.print_ordering_approval" class="form-group">
                        <label :for="'profile-group-print_approver' + data.id">
                            {{ $root.text(
                                $root.contents.financial_approver_title,
                                null,
                                true,
                                'financial_approver_title'
                            ) }}
                        </label>
                        <searchable-option-list
                            v-model="profile_group.print_approver"
                            :value="profile_group.print_approver"
                            :options="getApproverOptions()"
                            :field_id="'profile-group-print_approver' + data.id"
                            :readonly="updating"
                        ></searchable-option-list>
                    </div>
                    <div v-if="$root.client.show_profile_group_account_number" class="form-group">
                        <label
                            :for="'profile-group-account_number' + data.id"
                            :id="'profile-group-account_number-label' + data.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Account number', null, true) }}
                            <span v-if="$root.client.profile_group_account_number_required">*</span>
                        </label>
                        <input
                            v-model="profile_group.account_number"
                            type="text"
                            :id="'profile-group-account_number' + data.id"
                            class="form-control mandatory-field"
                            :readonly="updating"
                            v-on:keyup="$root.unHighlightErrors('profile-group-account_number', data.id)"
                            v-on:change="$root.unHighlightErrors('profile-group-account_number', data.id)"
                        >
                    </div>
                    <div v-if="$root.client.show_profile_group_consultant" class="form-group">
                        <label
                            :for="'profile-group-consultant' + data.id"
                            :id="'profile-group-consultant-label' + data.id"
                            class="mandatory-label"
                        >
                            {{ $root.text('Consultant', null, true) }}
                            <span v-if="$root.client.profile_group_consultant_required">*</span>
                        </label>
                        <searchable-option-list
                            v-model="profile_group.consultant"
                            :value="profile_group.consultant"
                            :options="getConsultantOptions()"
                            :field_id="'profile-group-consultant' + data.id"
                            field_class="mandatory-field"
                            :readonly="updating"
                            v-on:change="$root.unHighlightErrors('profile-group-consultant', data.id)"
                        ></searchable-option-list>
                    </div>
                    <div class="form-group">
                        <label :for="'profile-group-notes' + data.id">{{ $root.text('Notes', null, true) }}</label>
                        <textarea
                            v-model="profile_group.notes"
                            type="text"
                            :id="'profile-group-notes' + data.id"
                            class="form-control"
                            rows="5"
                            :readonly="updating"
                        ></textarea>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="(canUpdate() && !updated && data.id)" class="modal-footer">
            <span v-if="!updating">
                <span>
                    <button type="button" class="btn btn-primary" @click="onSubmit()">
                        <i class="far fa-save mr-1"></i> {{ $root.text('Save', null, true) }}
                    </button>
                </span>
            </span>
            <span v-else>
                <button type="button" class="btn btn-primary">
                    <i class="fal fa-spinner-third fa-spin mr-1"></i>
                    <span style="opacity: 0.5">{{ $root.text('Save', null, true) }}</span>
                </button>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['data'],
        data () {
            return {
                errors: false,
                updating: false,
                updated: false,
                profile_group: {},
                folders: [],
                approvers: [],
                consultants: []
            }
        },
        mounted () {
            if (this.$root.client.print_ordering_approval) {
                this.getApprovers();
            }
            if (this.$root.client.show_profile_group_consultant) {
                this.getConsultants();
            }
            if (this.data) {
                this.updateData(this.data);
            }
            if (this.$root.client.show_profile_group_subfolder_id) {
                this.getFolders();
            }
        },
        watch: {
            data: function (val) {
                if (val) {
                    this.updateData(val);
                }
            }
        },
        methods: {
            resetForm: function () {
                this.errors = false;
                this.updated = false;
                this.updateData(this.data);
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');
                this.getFolders();
            },
            canUpdate: function () {
                if (this.$root.hasPermission('profiles_groups_edit')) {
                    return true;
                }

                return (this.$root.is_own_profile_group && this.$root.hasPermission('profiles_own_group_edit'));
            },
            updateData: function (data) {
                if (!this.canUpdate()) {
                    this.errors = this.$root.text('Sorry, you do not have access to this function.', null, true);

                    return false;
                }
                let newData = {
                    folder_id: null
                };
                for (const [key, value] of Object.entries(data)) {
                    newData[key] = value;
                }
                this.profile_group = newData;
                if (this.$root.client.show_profile_group_subfolder_id) {
                    this.checkSubfolder();
                }
            },
            onSubmit: function () {
                this.errors = false;
                this.updating = true;
                $('.mandatory-label').removeClass('text-danger');
                $('.mandatory-field').removeClass('field-error');

                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id + '/profile-group/' + this.data.id;
                var vm = this;

                axios({
                    method: 'put',
                    url: url,
                    data: this.profile_group,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.updated = vm.$root.text(
                        '{profile group} updated.',
                        {
                            'profile group': vm.$root.text(
                                vm.$root.contents.profile_group_title,
                                null,
                                false,
                                'profile_group_title'
                            )
                        },
                        true
                    );
                    vm.$parent.getProfileGroup();

                    vm.$root.profiles_changed = true;

                    setTimeout(function () {
                        $('#profile-group-edit-form-modal').modal('hide');
                    }, 2000);
                })
                .catch(function (error) {
                    vm.errors = vm.$root.text('Something went wrong, please try again', null, true);
                    if (error.response) {
                        if (error.response.status == 401) {
                            // missing/lost API token - close modal and logout user
                            $('#profile-group-edit-form-modal').modal('hide');
                            vm.$root.doLogout();
                        }
                        if (error.response.data.error) {
                            if (typeof error.response.data.error === 'string') {
                                vm.errors = error.response.data.error
                                    .replace(/profile groups/g, vm.$root.contents.profile_groups_title)
                                    .replace(/profile group/g, vm.$root.contents.profile_group_title);
                            } else if (typeof error.response.data.error === 'object') {
                                vm.errors = '';
                                let errorHtml = null;
                                for (const [key, value] of Object.entries(error.response.data.error)) {
                                    if ((key == 'subfolder_id') && !vm.profile_group.folder_id) {
                                        vm.errors += 'The ' + vm.$root.contents.folder_title + ' field is required.<br>';
                                        $('#profile-group-folder_id-label' + vm.data.id).addClass('text-danger');
                                        $('#profile-group-folder_id' + vm.data.id).addClass('field-error');
                                    }
                                    errorHtml = value.join('<br>') + '<br>';
                                    vm.errors += errorHtml.replace(
                                        /subfolder id/g, vm.$root.contents.subfolder_title
                                    )
                                    .replace(
                                        /description/g, vm.$root.contents.profile_group_description_title
                                    );
                                    $('#profile-group-' + key + '-label' + vm.data.id).addClass('text-danger');
                                    $('#profile-group-' + key + vm.data.id).addClass('field-error');
                                }
                            }
                        }
                    }
                })
                .finally(() =>
                    vm.updating = false
                );
            },
            getFolders: function () {
                if (!this.$root.client) {
                    return null;
                }
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/folders/!pagination|sb::name_asc';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.folders = response.data.folders;
                    vm.checkSubfolder();
                })
                .catch(function (error) {
                    //
                });
            },
            checkSubfolder: function () {
                if (!this.profile_group.subfolder_id) {
                    return null;
                }
                if (this.folders.length == 0) {
                    return null;
                }
                let found = null;
                this.folders.forEach((folder) => {
                    folder.subfolders.forEach((item) => {
                        if (item.id == this.profile_group.subfolder_id) {
                            found = folder;
                        }
                    });
                });

                if (found) {
                    this.profile_group.folder_id = found.id;

                    return null;
                }
                this.profile_group.subfolder_id = null;
            },
            getFolderOptions: function () {
                let options = [];
                if (!this.$root.client.profile_group_subfolder_id_required) {
                    options.push({
                        id: null,
                        name: 'None'
                    });
                }
                this.folders.forEach((folder) => {
                    let subfolders = [];
                    folder.subfolders.forEach((item) => {
                        if (!item.archived || (item.id == this.profile_group.subfolder_id)) {
                            subfolders.push(item);
                        }
                    });
                    if (subfolders.length > 0) {
                        options.push(folder);
                    }
                });

                return options;
            },
            getSubfolderOptions: function () {
                let options = [];
                if (!this.$root.client.profile_group_subfolder_id_required) {
                    options.push({
                        id: null,
                        name: 'None'
                    });
                }
                this.folders.forEach((folder) => {
                    if (folder.id == this.profile_group.folder_id) {
                        folder.subfolders.forEach((item) => {
                            if (!item.archived || (item.id == this.profile_group.subfolder_id)) {
                                options.push(item);
                            }
                        });
                    }
                });

                return options;
            },
            onFolderChange: function () {
                this.profile_group.subfolder_id = null;
                this.$root.unHighlightErrors('profile-group-folder_id', this.data.id);
            },
            getApprovers: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|financial-approver';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.approvers = response.data.users;
                })
                .catch(function (error) {
                    //
                });
            },
            getConsultants: function () {
                /**
                 * Send request to API
                 */
                const url = this.$root.app.api_url + '/' + this.$root.app.client_id
                    + '/users/!export|!pagination|profile-group-consultant';
                var vm = this;

                axios({
                    url: url,
                    headers: { 'Authorization': 'Bearer ' + vm.$root.token }
                })
                .then(response => {
                    vm.consultants = response.data.users;
                })
                .catch(function (error) {
                    //
                });
            },
            getApproverOptions: function () {
                let options = [];
                if (this.approvers) {
                    this.approvers.forEach((item) => {
                        if ((!item.deleted && item.approved) || (item.id == this.profile_group.print_approver)) {
                            options.push({
                                id: item.id,
                                name: item.first_name + ' ' + item.last_name
                            });
                        }
                    });
                }

                return options;
            },
            getConsultantOptions: function () {
                let options = [];
                if (this.consultants) {
                    this.consultants.forEach((item) => {
                        if ((!item.deleted && item.approved) || (item.id == this.profile_group.consultant)) {
                            options.push({
                                id: item.id,
                                name: item.first_name + ' ' + item.last_name
                            });
                        }
                    });
                }

                return options;
            }
        }
    }
</script>
