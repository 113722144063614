import { render, staticRenderFns } from "./ArtworkView.vue?vue&type=template&id=79d11c03&scoped=true"
import script from "./ArtworkView.vue?vue&type=script&lang=js"
export * from "./ArtworkView.vue?vue&type=script&lang=js"
import style0 from "./ArtworkView.vue?vue&type=style&index=0&id=79d11c03&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d11c03",
  null
  
)

export default component.exports